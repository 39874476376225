import {
  ActionItem,
  Actions,
  LabelAction
} from "components/shared/CustomComponentsStyle";
import {
  BodyBlock,
  DateContainer,
  DescriptionContainer,
  DescriptionSpan,
  NumberOfItem,
  TitleContainer
} from "../../StageBlocksStyle";
import { Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import { cancel, edit } from "../../../../../assets/icons/icons";

import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import { LabelH1 } from "components/shared/CustomComponents";
import { TextField } from "components/shared/CustomInputs";
import { isDateDisabledForEditing } from "../../../../../helpers/sharedHelpers";

export const DefaultStage = ({
  kind,
  position,
  deleteItem,
  description,
  name,
  kindItem,
  isAssociating,
  railsAttributesToAssocation,
  setRailsAttributesToAssocation,
  id,
  requestShowStage,
  isEditingAssociation,
  periodeRealization,
  testPeriodeRealizationUntil
}) => {
  const [selfAttributes, setSelfAttributes] = useState({});
  const registerValuesAndThrow = (value, name) => {
    setSelfAttributes({ ...selfAttributes, [name]: value });
    setRailsAttributesToAssocation({
      ...railsAttributesToAssocation,
      [id]: {
        ...selfAttributes,
        ...railsAttributesToAssocation[id],
        [name]: value
      }
    });
  };

  const renderActions = () => {
    return (
      <Col lg={2}>
        <Actions>
          <ActionItem onClick={() => deleteItem(id)}>
            {cancel()}
            <LabelAction>
              <FormattedMessage id="sharedItems.remove" />
            </LabelAction>
          </ActionItem>
        </Actions>
      </Col>
    );
  };

  return (
    <BodyBlock>
      <Row>
        <Col lg={9}>
          <TitleContainer>
            <NumberOfItem style={{ marginLeft: "unset" }}>
              <LabelH1>{position}</LabelH1>
            </NumberOfItem>
            <LabelH1>{name}</LabelH1>
          </TitleContainer>
        </Col>
        {renderActions()}
      </Row>
      <Row className={"align-items-md-flex-start"}>
        <Col lg={{ span: 3, offset: 1 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.kindTrainner" />
            </LabelAction>
            <DescriptionSpan>{kindItem}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
        <Col lg={{ span: 8 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.description" />
            </LabelAction>
            <DescriptionSpan>{description}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
      </Row>
      {isAssociating && (
        <Row style={{ alignItems: "center", marginTop: "30px" }}>
          <Col
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexFlow: "column"
            }}
            lg={{ span: 4, offset: 1 }}
          >
            <LabelAction>
              <FormattedMessage id="inputs.periodeRealization" />
            </LabelAction>
            <DateContainer>
              <InputMask
                alwaysShowMask
                mask="99/99/9999"
                width="130px"
                disabled={isDateDisabledForEditing(
                  isEditingAssociation,
                  periodeRealization
                )}
                defaultValue={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id].periodeRealization
                    : null
                }
                registerValuesAndThrow
                onChange={e =>
                  registerValuesAndThrow(e.target.value, "periodeRealization")
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    disabled={isDateDisabledForEditing(
                      isEditingAssociation,
                      periodeRealization
                    )}
                  />
                )}
              </InputMask>
              <LabelAction
                ml="15px"
                mr="15px"
                fz="16px"
                style={{ alignSelf: "center" }}
              >
                <FormattedMessage id="sharedItems.until" />
              </LabelAction>
              <InputMask
                alwaysShowMask
                mask="99/99/9999"
                width="130px"

                defaultValue={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id]
                        .testPeriodeRealizationUntil
                    : null
                }
                onChange={e =>
                  registerValuesAndThrow(
                    e.target.value,
                    "testPeriodeRealizationUntil"
                  )
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    />
                )}
              </InputMask>
            </DateContainer>
          </Col>
        </Row>
      )}
    </BodyBlock>
  );
};
export default DefaultStage;
