import * as urls from "utils/contants/urls";

import { Body, DashBoardBody, Row } from "./DashBoardTalentsStyle";
import React, { Fragment, PureComponent } from "react";
import { Route, Switch } from "react-router";

import DashBoardTalentsMain from "../DashBoardTalentsMain";
import Candidate from "../Candidates/Candidate";
import CheckOut from "../Candidates/Checkout";
import { ConnectedRouter } from "connected-react-router";
import DefaultStages from "../Stages/DefaultStages";
import Header from "components/Layout/header/Header";
import JobList from "../Vacancy/Table/JobList";
import Jobs from "../Vacancy/VacancyCreate";
import ListCandidates from "../Candidates/ListCandidates";
import ListVacancyCandidates from "../Candidates/ListVacancyCandidates";
import Menu from "../Menu/Menu";
import MessageHandler from "components/MessageHandler";
import NewRails from "../Rails/NewRails";
import NewStage from "../Stages/StageAboutYou";
import Rails from "../Rails/List";
import RailsAssociation from "../Rails/RailsAssociation";
import StageList from "../Stages/StagesList";
import StageTest from "../Stages/StageTest";
import StageContract from "../Stages/StageContract";
import Vacancy from "../Vacancy/VacancyShow";
import { connect } from "react-redux";
import Profiles from "../../Manager/Profiles";
import BankOfTalents from "../BankOfTalents/CandidatesInBankOfTalentsList";
import BankOfTalentsEdit from "../BankOfTalents/EditCandiatesInBankOfTalents";
class DashBoardTalents extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <Fragment>
        <Header location={history} />
        <DashBoardBody>
          <Row>
            <Menu />
            <Body>
              <ConnectedRouter history={history}>
                <Switch>
                  <Route exact path={urls.TALENTS_AEC} component={DashBoardTalentsMain} />
                  <Route exact path={urls.VACANCY} component={JobList} />
                  <Route exact path={urls.VACANCY_NEW} component={Jobs} />
                  <Route exact path={`${urls.VACANCY_SHOW}:id`} component={Vacancy} />
                  <Route exact path={`${urls.VACANCY_EDITAR}:id`}   component={Jobs} />
                  <Route exact path={urls.STAGES} component={StageList} />
                  <Route exact path={`${urls.STAGE_REGISTRATION_DATA_SHOW}/:id`}   component={NewStage} />
                  <Route exact path={`${urls.STAGE_REGISTRATION_DATA_NEW}`}   component={NewStage} />
                  <Route exact path={urls.STAGE_POSITIONS}   component={NewStage} />
                  <Route exact path={urls.STAGES_TESTS} component={StageTest} />
                  <Route exact path={`${urls.STAGES_TESTS}/:id`}   component={StageTest} />
                  <Route exact path={urls.STAGES_TRAINNER}   component={DefaultStages} />
                  <Route exact path={`${urls.STAGES_TRAINNER}/:id`}   component={DefaultStages} />
                  <Route exact path={urls.STAGES_MEDIC_EXAME}   component={DefaultStages} />
                  <Route exact path={`${urls.STAGES_MEDIC_EXAME}/:id`}   component={DefaultStages} />
                  <Route exact path={urls.STAGES_ADMITION}   component={DefaultStages} />
                  <Route exact path={`${urls.STAGES_ADMITION}/:id`}   component={DefaultStages} />
                  <Route exact path={urls.STAGES_AMBIENTATION}   component={DefaultStages} />
                  <Route exact path={`${urls.STAGES_AMBIENTATION}/:id`}   component={DefaultStages} />
                  <Route exact path={urls.STAGES_FEEDBACK}   component={DefaultStages} />
                  <Route exact path={`${urls.STAGES_FEEDBACK}/:id`}   component={DefaultStages} />
                  <Route exact path={urls.STAGES_ASSAY}   component={DefaultStages} />
                  <Route exact path={`${urls.STAGES_ASSAY}/:id`}   component={DefaultStages} />
                  <Route exact path={urls.STAGES_CONTRACT}   component={StageContract} />
                  <Route exact path={urls.RAILS} component={Rails} />
                  <Route exact path={urls.RAILS_NEW} component={NewRails} />
                  <Route exact path={`${urls.RAILS_SHOW}/:id`}   component={NewRails} />
                  <Route exact path={`${urls.RAILS_DETAILS_ASSOCIATION}/:id`}   component={RailsAssociation} />
                  <Route exact path={`${urls.RAILS_CREATE_ASSOCIATION}/:id`}   component={NewRails} />
                  <Route exact path={`${urls.VACANCY_CANDIDATES}`}   component={ListVacancyCandidates} />
                  <Route exact path={`${urls.CANDIDATES_FROM_VACANCY}/:id`}   component={ListCandidates} />
                  <Route exact path={`${urls.SHOW_CANDIDATE}/:id`}   component={Candidate} />
                  <Route exact path={`${urls.APPROVED_CANDIDATES}/:id`}   component={CheckOut} />
                  <Route exact path={`${urls.DECLINED_CANDIDATES}/:id`}   component={CheckOut} />
                  <Route exact path={`${urls.BANKOFTALENTS_CANDIDATES}/:id`}   component={CheckOut} />
                  <Route exact path={`${urls.STAGES_PRE_ADMITION}`}   component={DefaultStages} />
                  <Route exact path={`${urls.STAGES_PRE_ADMITION}/:id`}   component={DefaultStages} />
                  <Route exact path={urls.MANAGERS} component={Profiles} />
                  <Route exact path={`${urls.MANAGERS_PROFILESEDITION}/:id`}   component={Profiles} />
                  <Route exact path={`${urls.MANAGERS_PROFILES}/:id`}   component={Profiles} />
                  <Route exact path={`${urls.BANKOFTALENTS}`}   component={BankOfTalents} />
                  <Route exact path={`${urls.BANKOFTALENTS_EDIT}/:id`}   component={BankOfTalentsEdit} />
                </Switch>
              </ConnectedRouter>
            </Body>
          </Row>
        </DashBoardBody>
        <MessageHandler />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  location: state.history
});
export default connect(mapStateToProps)(DashBoardTalents);
