import { Body, LabelActualRail, LabelInfo, LabelItem, List } from "./StageBlockListStyle";
import { Col, Row } from "react-bootstrap";

import { ButtonSecondary } from "components/shared/CustomInputs";
import CardStage from "../CardStage";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import React from "react";

export default function StageBlockList({
  rail,
  showDescription,
  requestAssociationRailToVacancy,
  actualRail,
  publishedVacancy
}) {
  return (
    <Body>
      <React.Fragment>
        <Row
          style={{
            paddingLeft: "26px",
            paddingTop: "36px",
            paddingRight: "20px"
          }}
        >
          {actualRail && (
            <Col lg={7}>
              <LabelActualRail>
                <FormattedMessage id={"sharedItems.actualRail"} />
              </LabelActualRail>
            </Col>
          )}
          {rail.createBy && (
            <Col lg={4}>
              <LabelItem>
                <FormattedMessage id={"sharedItems.createdBy"} />
              </LabelItem>
              <LabelInfo LabelItem>{rail.createBy}</LabelInfo>
            </Col>
          )}
          {rail.createdAt && (
            <Col lg={{ span: 3 }}>
              <LabelItem>
                <FormattedMessage id="sharedItems.at" />
              </LabelItem>

              <LabelInfo>{rail.createdAt}</LabelInfo>
            </Col>
          )}
          <Col lg={{ span: 2, offset: 2 }}>
            <ButtonSecondary
              disabled={publishedVacancy}
              onClick={() => requestAssociationRailToVacancy()}
              width="180px"
              name={<FormattedMessage id={!actualRail ? "sharedItems.useRails" : "sharedItems.editRails"} />}
            />
          </Col>
        </Row>
      </React.Fragment>
      <Row>
        <Col lg={12}>
          <List>
            {rail.stages.map((stage, index) => {
              return (
                <CardStage
                  showDescription={showDescription}
                  position={index + 1}
                  key={stage.id}
                  {...stage}
                />
              );
            })}
          </List>
        </Col>
      </Row>
    </Body>
  );
}
StageBlockList.propTypes = {
  stage: PropTypes.arrayOf({
    kind: "",
    level: "",
    avaliation: "",
    questionTime: ""
  })
};

StageBlockList.defaultProps = {
  stage: []
};
