import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  enviarDocsEmployeesRequest: ['payload'],
  enviarDocsEmployeesSuccess: ['stages', 'isLoading'],
  enviarDocsEmployeesFail: ['isLoading', 'error'],


  candidateAdmFilesRequest: ['payload'],
  candidateAdmFilesSuccess: ['stages', 'isLoading'],
  candidateAdmFilesFail: ['isLoading', 'error'],


  candidateSetStatusFileRequest: ['payload'],
  candidateSetStatusFileSuccess: ['stages', 'isLoading'],
  candidateSetStatusFileFail: ['isLoading', 'error'],


  candidateSetStatusStageRequest: ['payload'],
  candidateSetStatusStageSuccess: ['stages', 'isLoading'],
  candidateSetStatusStageFail: ['isLoading', 'error'],


  candidateIncludeNewDocRequest: ['payload'],
  candidateIncludeNewDocSuccess: ['stages', 'isLoading'],
  candidateIncludeNewDocFail: ['isLoading', 'error'],


  releaseStageForCandidatesRequest: ['payload'],
  releaseStageForCandidatesSuccess: ['stages', 'isLoading'],
  releaseStageForCandidatesFail: ['isLoading', 'error'],

});

const INITIAL_STATE = {
  candidates: {
    candidatos: [],
    candidatosPorVaga: []
  },
  hasMore: true,
  page: 1
};


const enviarDocsEmployeesRequest = state => ({ ...state, isLoading: true });
const enviarDocsEmployeesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const enviarDocsEmployeesFail = (state, error) => ({ ...state, isLoading: false, error });



const candidateAdmFilesRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const candidateAdmFilesSuccess = (state, payload) => ({
  ...payload,
  isLoading: false
});
const candidateAdmFilesFail = (state, error) => ({ ...state, isLoading: false, error });



const candidateSetStatusFileRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const candidateSetStatusFileSuccess = (state, payload) => ({
  ...payload,
  isLoading: false
});
const candidateSetStatusFileFail = (state, error) => ({ ...state, isLoading: false, error });




const candidateSetStatusStageRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const candidateSetStatusStageSuccess = (state, payload) => ({
  ...payload,
  isLoading: false
});
const candidateSetStatusStageFail = (state, error) => ({ ...state, isLoading: false, error });



const candidateIncludeNewDocRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const candidateIncludeNewDocSuccess = (state, payload) => ({
  ...payload,
  isLoading: false
});
const candidateIncludeNewDocFail = (state, error) => ({ ...state, isLoading: false, error });


const releaseStageForCandidatesRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const releaseStageForCandidatesSuccess = (state, payload) => ({ ...payload, isLoading: false });
const releaseStageForCandidatesFail = (state, error) => ({ ...state, isLoading: false, error });



export default createReducer(INITIAL_STATE, {
  [Types.ENVIAR_DOCS_EMPLOYEES_REQUEST]: enviarDocsEmployeesRequest,
  [Types.ENVIAR_DOCS_EMPLOYEES_SUCCESS]: enviarDocsEmployeesSuccess,
  [Types.ENVIAR_DOCS_EMPLOYEES_FAIL]: enviarDocsEmployeesFail,


  [Types.CANDIDATE_ADM_FILES_REQUEST]: candidateAdmFilesRequest,
  [Types.CANDIDATE_ADM_FILES_SUCCESS]: candidateAdmFilesSuccess,
  [Types.CANDIDATE_ADM_FILES_FAIL]: candidateAdmFilesFail,


  [Types.CANDIDATE_SET_STATUS_FILE_REQUEST]: candidateSetStatusFileRequest,
  [Types.CANDIDATE_SET_STATUS_FILE_SUCCESS]: candidateSetStatusFileSuccess,
  [Types.CANDIDATE_SET_STATUS_FILE_FAIL]: candidateSetStatusFileFail,


  [Types.CANDIDATE_SET_STATUS_STAGE_REQUEST]: candidateSetStatusStageRequest,
  [Types.CANDIDATE_SET_STATUS_STAGE_SUCCESS]: candidateSetStatusStageSuccess,
  [Types.CANDIDATE_SET_STATUS_STAGE_FAIL]: candidateSetStatusStageFail,


  [Types.CANDIDATE_INCLUDE_NEW_DOC_REQUEST]: candidateIncludeNewDocRequest,
  [Types.CANDIDATE_INCLUDE_NEW_DOC_SUCCESS]: candidateIncludeNewDocSuccess,
  [Types.CANDIDATE_INCLUDE_NEW_DOC_FAIL]: candidateIncludeNewDocFail,


  [Types.RELEASE_STAGE_FOR_CANDIDATES_REQUEST]: releaseStageForCandidatesRequest,
  [Types.RELEASE_STAGE_FOR_CANDIDATES_SUCCESS]: releaseStageForCandidatesSuccess,
  [Types.RELEASE_STAGE_FOR_CANDIDATES_FAIL]: releaseStageForCandidatesFail,

});
