import React from 'react';

import './dashlabel.scss';

export const DashTextLabel = ({data}) => {
    return (
        <div className="dashtext-label">
            <div className="text" style={{'--title': data?.customStyle?.color}}>
                { data?.data || 'No text' }
            </div>
        </div>
    )
}