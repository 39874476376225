import "moment/locale/pt-br";

import * as routes from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";
import {
  buildCode,
  buildDate,
  buildErroHandleItems,
  buildPagination,
  buildTimeFromSeconds,
  buildDateStringWithHour,
  isValidDate,
} from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/candidates";
import { history } from "../../utils/routes";
import { kindStages } from "../../locale/index";
import moment from "moment";
import requestAPI from "../../helpers/requestHelpers";
import { GET, POST, PUT } from "../../utils/contants/verbs";
import { Types as AlertTypes } from "../ducks/alert";

import { UPDATE_PERSONAL_INFORMATION } from "../../utils/contants/urlsApi";

moment.locale("pt-BR");

export const calcAge = (birth) => {
  if (birth) {
    const date = moment().diff(birth, "years");
    return `${date} anos`;
  }
  return null;
};

export const formatterDateBrazilian = (value) => {
  if (value) return moment(value).format("L");
  return " - ";
};

export const formatterDateJustDate = (date) => {
  if (date.length == 19) {
    const onlyDate = date.split("T");
    const dateItems = onlyDate.length
      ? onlyDate[0].split("-")
      : date.split("-");
    const day = dateItems[2];
    const month = dateItems[1];
    const year = dateItems[0];
    return `${year}-${month}-${day}`;
  }
  return null;
};

function requestCandidadetsList(params, pagina) {
  return API.put(
    `${urlsApi.VANCANCY_CANDIDATES}?NumeroPagina=${pagina}`,
    params
  )
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* getVacanciesToCandidates({ filter }) {
  try {
    const body = {
      codigo: filter?.code,
      idArea: (filter && filter.area) || undefined,
      idCargo: (filter && filter.job) || undefined,
      criadoEmDataInicio: (filter && filter.dateStart) || undefined,
      criadoEmDataFinal: (filter && filter.dateEnd) || undefined,
      idUsuarioQueCriou: (filter && filter.createdBy) || undefined,
      idGestor: (filter && filter.manager) || undefined,
      status: (filter && filter.status) || undefined,
      idSite: (filter && filter.site) || undefined,
    };
    const { lista, paginacao } = yield requestCandidadetsList(
      body,
      (filter && filter.NumeroPagina) || 1
    );
    const stateVacanciesList = yield select(getStateVacanciesList);
    const vacancies = lista.map((vacancy) => ({
      id: vacancy.id,
      code: buildCode(vacancy.dataCriacao, "VA", vacancy.id),
      job: vacancy.cargo,
      area: vacancy.area,
      manager: vacancy.gestor,
      status: vacancy.status,
      createdBy: vacancy.usuarioQueCriou,
      createdAt: moment(vacancy.dataCriacao).format("L"),
    }));
    const pagination = buildPagination(
      paginacao,
      filter,
      vacancies,
      stateVacanciesList
    );
    yield put({
      type: Types.VACANCY_CANDIDATES_REQUEST_SUCCESS,
      vacancies,
      pagination,
      isLoading: false,
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.VACANCY_CANDIDATES_REQUEST_FAIL, error });
  }
}

function requestCandidadetsFromVacancyList(body) {
  return API.put(
    `${urlsApi.CANDIDATES_FROM_VANCANCY}?NumeroPagina=${body.page}`,
    body
  )
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

const filterCandidates = (payload) => {
  const body = {
    idVaga: payload.vacancyId,
    idEtapa: payload.stageId,
    codigo: payload.code,
    cpfs: payload.cpfList,
    matriculas: payload.enrollmentList,
    idCandidato: payload.candidateId,
    nome: payload.name,
    aplicouEmDataInicial:
      payload.applyStartDate != null ? buildDate(payload.applyStartDate) : null,
    aplicouEmDataFinal:
      payload.applyEndDate != null ? buildDate(payload.applyEndDate) : null,
    dataAgendamento:
      payload.schedulingDate != null ? buildDate(payload.schedulingDate) : null,
    resultadoMinimo:
      payload.minimumResult != null
        ? parseInt(payload.minimumResult.replace("%", "")) / 100
        : null,
    status: payload.kindCandidate,
    statusEtapa: payload.stageStatus,
    page: payload.page,
    EmBancoTalentos: payload.inBankOfTalent,
    agendamentos: payload.schedules,
  };

  return body;
};

function* getCandidatesFromVacancy({ payload }) {
  try {
    const actualPage =
      payload && payload.firstPage ? 1 : yield select(getActualPage);
    const nextPage = actualPage + 1;
    const stateCandidatesList = yield select(getStateCandidatesList);

    const {
      candidatos,
      totalCandidatos,
      totalCandidatosAprovados,
      totalCandidatosEmProcessoAndamento,
      totalCandidatosReprovados,
      totalCandidatosBancoTalentos,
      totalCandidatosRemovidos,
      totalAgendamentos,
      vagasDisponiveis,
      nomeCargo,
      etapas,
      etapaSelecionada,
    } = yield requestCandidadetsFromVacancyList(filterCandidates(payload));

    const candidates = candidatos.listaCandidatos.map((candidate) => {
      return {
        id: candidate.id,
        code: buildCode(candidate.dataCriacao, "CA", candidate.id),
        name: candidate.nome,
        urlAvatar: candidate.urlAvatar,
        currentStage: candidate.etapa,
        result: candidate.result ? `${candidate.resultado}%` : " - ",
        resultStatus: candidate.status,
        status: candidate.status,
        applyedAt: moment(candidate.aplicouEm).format("L"),
        bankofTalents: candidate.emBancoTalentos,
        cpf: candidate.cpf,
        enrollment: candidate.matricula ? candidate.matricula : " - ",
        scheduled: candidate.possuiAgendamento,
        scheduleTime: candidate.horaDataAgendamento
          ? candidate.horaDataAgendamento
          : " - ",
        hourStart: candidate.horaInicioAgendamento,
        hourEnd: candidate.horaFimAgendamento,
        date: candidate.dataAgendamento,
        placeOfInterview: candidate.localAgendado,
        note: candidate.observacao,
      };
    });

    const stages = etapas.map((e) => ({
      id: e.id,
      name: `${e.nome}`,
      date: e.dataCriacao,
      createdBy: e.usuarioQueCriou,
      type: e.tipoEtapa,
    }));

    const selectedStageFilter = {
      id: etapaSelecionada.id,
      name: `${etapaSelecionada.nome}`,
      date: etapaSelecionada.dataCriacao,
      dateStart: etapaSelecionada.dataInicialRealizacao,
      dateEnd: etapaSelecionada.dataFinalRealizacao,
      createdBy: etapaSelecionada.usuarioQueCriou,
      type: etapaSelecionada.tipoEtapa,
    };

    const vacancy = {
      candidatesNumber: candidatos.paginacao.quantidade,
      allNumberOfCandidates: totalCandidatos,
      vacancies: vagasDisponiveis || 0,
      approvedCandidates: totalCandidatosAprovados,
      candidatesInProgress: totalCandidatosEmProcessoAndamento,
      disapproved: totalCandidatosReprovados,
      schedulesQuantity: totalAgendamentos,
      bankOfTalentsCandidates: totalCandidatosBancoTalentos,
      totalCandidatesRemovedVacancy: totalCandidatosRemovidos,
      name: nomeCargo,
      stages,
      selectedStageFilter,
    };
    const pagination = buildPagination(
      candidatos.paginacao,
      { NumeroPagina: nextPage },
      candidates,
      stateCandidatesList
    );

    const page = pagination.currentPage;

    let newStateCandidateList = candidates;
    const stateCandidateList = yield select(getStateCandidatesList);
    if (!payload.firstPage)
      newStateCandidateList = stateCandidateList.concat(candidates);

    const hasMore = newStateCandidateList.length < totalCandidatos;

    yield put({
      type: Types.VACANCY_CANDIDATES_REQUEST_SUCCESS,
      vacancy,
      candidates: newStateCandidateList,
      isLoading: false,
      pagination,
      page,
      hasMore,
    });
  } catch (error) {
    yield put({ type: Types.CANDIDATES_FROM_FAIL, error });
  }
}

function* managerCandidatesAction({
  candidates,
  kindOfAction,
  vacancyId,
  stageId,
  stageType,
}) {
  try {
    yield put({
      type: Types.CANDIDATES_MANAGER_SUCCESS,
      candidates,
      kindOfAction,
      vacancyId,
      stageId,
      stageType,
    });
    switch (parseInt(kindOfAction)) {
      case 0:
        return history.push(`${routes.APPROVED_CANDIDATES}/${vacancyId}`);
      case 1:
        return history.push(`${routes.DECLINED_CANDIDATES}/${vacancyId}`);
      case 4:
        return history.push(`${routes.BANKOFTALENTS_CANDIDATES}/${vacancyId}`);
      case 5:
        return history.push(`${routes.APPROVED_CANDIDATES}/${vacancyId}`);

      default:
        return history.push(`${routes.APPROVED_CANDIDATES}/${vacancyId}`);
    }
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.VACANCY_CANDIDATES_REQUEST_FAIL, error });
  }
}

function requestCandidateActions(body) {
  return API.post(`${urlsApi.CANDIDATES_ACTION}`, body)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* managerDispatchCandidatesAction({
  candidates,
  kindOfAction,
  vacancyId,
  enabledSendToCandidates,
  feedback,
  stageId,
}) {
  try {
    const body = {
      acaoCandidato: kindOfAction,
      candidatos: candidates.map((e) => e.id),
      feedback,
      permitirResposta: enabledSendToCandidates,
      idVaga: vacancyId,
      idEtapa: stageId,
    };
    yield requestCandidateActions(body);
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_DISPATCH_MANAGER_FAIL, errors });
  } finally {
    if (parseInt(kindOfAction) === 7) history.go(0);
    else history.push(`${routes.VACANCY_CANDIDATES}/${vacancyId}`);
  }
}
function getCandidate(candidateId, vacancyId) {
  return API.get(`${urlsApi.CANDIDATE_SHOW}/${candidateId}?IdVaga=${vacancyId}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}
function* requestCandidate({ candidateId, vacancyId }) {
  try {
    const response = yield getCandidate(candidateId, vacancyId);
    const candidate = {
      id: response.idCandidato,
      code: buildCode(response.dataCriacaoVaga, "CA", response.idCandidato),
      name: response.candidato.nome,
      birthday: moment(response.candidato.dataNascimento).format("L"),
      rg: response.candidato.rg,
      phone1: response.candidato.telefone1,
      phone2: response.candidato.telefone2,
      cpf: response.candidato.cpf,
      email: response.candidato.email,
      job: response.cargo.funcao,
      area: response.cargo.area.descricao,
      manager: response.gestor,
      stageStatus: response.etapa,
      vacancyId: buildCode(response.dataCriacaoVaga, "VA", vacancyId),
      address: {
        neighborhood: response.candidato.endereco.bairro,
        zipCode: response.candidato.endereco.cep,
        city: response.candidato.endereco.cidade,
        attachment: response.candidato.endereco.complemento,
        state: response.candidato.endereco.estado,
        street: response.candidato.endereco.logradouro,
        nationality: response.candidato.nacionalidade.nome,
        number: response.candidato.endereco.numero,
      },
      stages: response.etapas.map((e) => ({
        id: e.id,
        avaliation: e.avaliacao,
        createdAt: e.dataCriacao,
        description: e.descricao,
        levelTest: e.nivelTeste,
        name: e.nome,
        porcRightQuestions: e.porcentagemAcerto,
        howManyRightQuestions: e.quantidadeAcertos,
        howManyQuestions: e.quantidadePerguntas,
        finalDate: e.realizacaoDataFinal,
        startDate: moment(e.realizacaoDataInicial).format("L"),
        status: e.status,
        spendTimeInSeconds: buildTimeFromSeconds(e.tempoGastoTesteSegundos),
        spendQuestionsInSeconds: e.tempoPerguntasSegundos,
        timeTest: e.tempoTesteSegundos,
        kindStage: e.tipoEtapa,
        kindTest: e.tipoTeste,
        createdBy: e.usuarioQueCriou,
      })),
    };
    yield put({
      type: Types.CANDIDATE_SHOW_SUCCESS,
      candidate,
      isLoading: false,
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATE_SHOW_FAIL, error });
  }
}
function* requestSaveScheduleInterview({
  candidates,
  kindOfAction,
  vacancyId,
  stageId,
}) {
  try {
    const body = {
      IdVaga: vacancyId,
      idEtapa: stageId,
      acaoCandidato: kindOfAction,
      feedback: "",
      candidatos: candidates.map((e) => e.id),
      agendamento: candidates.map((candidate) => ({
        idCandidato: candidate.id,
        dataHorarioInicial: buildDateStringWithHour(
          candidate.date,
          candidate.hourStart
        ),
        dataHorarioFinal: buildDateStringWithHour(
          candidate.date,
          candidate.hourEnd
        ),
        idLocal: candidate.placeOfInterview,
        observacao: candidate.note,
      })),
    };
    yield requestCandidateActions(body, vacancyId);
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_SCHEDULE_ENTERVIEW_FAIL, error });
  }
}

function requestExportCandidates(body) {
  return API.put(`${urlsApi.EXPORT_CANDIDATES}`, body, {
    responseType: "blob",
  })
    .then((res) => {
      const blob = new Blob([res.data], { type: "application/excel" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dados_candidatos.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      throw error;
    });
}
function requestAddCandidate(body) {
  return API.post(`${urlsApi.ADD_CANDIDATE}`, body)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function requestCreateCandidate(body) {
  return API.put(`${urlsApi.CREATE_CANDIDATE}`, body)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* exportCandidates({ candidates, vacancyId }) {
  try {
    const body = {
      ListaIdCandidatos: candidates.map((e) => e.id),
      VagaId: vacancyId,
    };
    yield requestExportCandidates(body);
    yield put({ type: Types.CANDIDATES_EXPORT_SUCCESS });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_EXPORT_FAIL, error });
  }
}

function* addCandidate({ candidate, vacancyId }) {
  try {
    const body = {
      Justificativa: candidate.justification,
      VagaId: vacancyId,
      Matricula: candidate.matricula,
      Cpf: candidate.cpf,
    };
    const payload = { vacancyId: vacancyId, page: 1 };

    yield requestAddCandidate(body);
    yield getCandidatesFromVacancy({ payload });
    yield put({ type: Types.CANDIDATES_ADD_CANDIDATE_SUCCESS });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_ADD_CANDIDATE_FAIL, error });
  }
}

function* createCandidate({ candidate }) {
  try {
    const body = {
      Nome: candidate.nome,
      Cpf: candidate.cpf,
      Cep: candidate.cep,
      Rua: candidate.rua,
      Bairro: candidate.bairro,
      Numero: candidate.numero,
      Email: candidate.email,
      Cargo: candidate.cargo,
      Matricula: candidate.matricula,
      Admissao: candidate.dataAdmissao,
      Salario: typeof candidate.salario !== "number" ? parseFloat(
        candidate.salario.replace("R$", "").replace(",", "."), 0
      ) : 0,
      Telefone: candidate.telefone,
      ColigadaCandidato: candidate.coligadaCandidato,
      SaldoRefeicao: typeof candidate.saldoRefeicao !== "number" ? parseFloat(
        candidate.saldoRefeicao.replace("R$", "").replace(",", "."), 0
      ) : 0,
      ValeTransporte: candidate.valeTransporte,
      codSecaoLocalTrabalho: candidate.codSecaoLocalTrabalho,
      ProjetoId: candidate.ProjetoId,
    };

    yield requestCreateCandidate(body);
    yield put({ type: Types.CANDIDATES_CREATE_CANDIDATE_SUCCESS });
    yield put({
      type: MessageTypes.MESSAGE_PUBLICATION,
      messages: [{ type: "info", message: "Candidato criado com sucesso." }],
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_CREATE_CANDIDATE_FAIL, error });
  }
}
function requestCreateCandidateBulk(body) {
  return API.put(`${urlsApi.CREATE_CANDIDATE_BULK}`, body)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* createCandidateBulk({ candidatesBulk }) {
  try {
    const body = candidatesBulk;

    const listBulk = yield requestCreateCandidateBulk(body);
    yield put({
      type: Types.CANDIDATES_CREATE_CANDIDATE_BULK_SUCCESS,
      candidatesBulk: listBulk,
    });
    yield put({
      type: MessageTypes.MESSAGE_PUBLICATION,
      messages: [{ type: "info", message: "Candidato criado com sucesso." }],
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_CREATE_CANDIDATE_BULK_FAIL, error });
  }
}

function requestGetCandidatesList(body) {
  return API.put(`${urlsApi.PLUG_GET_CANDIDATES_LIST}`, body)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* getCandidatesList({ payload }) {
  try {
    const buildFilter = { ...payload };

    const { lista, paginacao } = yield requestGetCandidatesList(buildFilter);
    const stateRailsList = yield select(getStateCandidatesList);

    const candidatesList = lista.map((item) => ({
      admConcluiuAdmissao: item.admConcluiuAdmissao,
      canidatosEmEtapaId: item.canidatosEmEtapaId,
      coligadaCandidato: item.coligadaCandidato,
      cpf: item.cpf,
      dataConclusaoAdmissao: item.dataConclusaoAdmissao,
      dataNascimento: item.dataNascimento,
      email: item.email,
      id: item.id,
      nacionalidade: item.nacionalidade,
      nome: item.nome,
    }));

    const paginacaoNumber = { ...paginacao, page: buildFilter.numeroPagina };

    const pagination = buildPagination(
      paginacaoNumber,
      buildFilter,
      candidatesList,
      stateRailsList,
      20
    );

    yield put({
      type: Types.CANDIDATES_GET_CANDIDATES_LIST_SUCCESS,
      candidates: candidatesList,
      pagination,
    });

    const messages = [{ type: "info", message: "Sucesso ao resgatar as informações." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });

  } catch (error) {
    console.log("error ", error);
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_GET_CANDIDATES_LIST_FAIL, error });
  }
}

function* getInfoCandidateById({ payload }) {
  try {
    const user = yield requestAPI({
      verb: GET,
      endPoint: urlsApi.GET_PERSONAL_INFORMATION,
      data: null,
      id: payload.id,
    });
    const candidate = {
      id: user.id,
      name: user.nome,
      cpf: user.cpf,
      matricula: user.matricula,
      dataAdmissao: user.dataAdmissao ? formatterDateJustDate(user.dataAdmissao) : null,
      phone1: user.telefone1,
      email: user.email,
      coligadaCandidato: user.coligadaCandidato,
      refeicao: user.refeicao,
      cargo: user.cargo,
      salario: user.salario || null,
      valeTransporte: user.valeTransporte,
      address: {
        place: user.endereco.logradouro,
        zipCode: user.endereco.cep,
        number: user.endereco.numero,
        complement: user.endereco.complemento,
        neighborhood: user.endereco.bairro,
      }
    };
    yield put({
      type: Types.INFORMACOES_CANDIDATO_REQUEST_SUCCESS,
      candidate,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_REQUEST_FAIL,
    });
  }
}

function* requestUpdateCandidateProfile({ payload }) {
  try {
    const user = {
      id: payload.id,
      nome: payload.name ? payload.name : null,
      matricula: payload.matricula ? payload.matricula : null,
      dataAdmissao: payload.dataAdmissao ? payload.dataAdmissao : null,
      telefone1: payload.phone1 ? payload.phone1 : null,
      email: payload.email ? payload.email : null,
      coligadaCandidato: payload.coligadaCandidato ? payload.coligadaCandidato : null,
      refeicao: payload.refeicao ? payload.refeicao : null,
      cargo: payload.cargo ? payload.cargo : null,
      salario: payload.salario ? payload.salario : null,
      valeTransporte: payload.valeTransporte ? payload.valeTransporte : null,
      endereco: {
        logradouro: payload.address.place,
        cep: payload.address.zipCode,
        numero: payload.address.number,
        bairro: payload.address.neighborhood,
      },
    };
    yield requestAPI({
      verb: PUT,
      endPoint: UPDATE_PERSONAL_INFORMATION,
      data: user,
    });
    yield put({ type: Types.CANDIDATE_PROFILE_UPDATE_SUCCESS });
    const messages = [
      { type: "info", message: "Informações pessoais alteradas com sucesso." },
    ];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });

    // yield getInfoCandidateById({id: payload.id});
  } catch (error) {
    const messages = buildErroHandleItems(error.response, error.message);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATE_PROFILE_UPDATE_FAIL, error });
  }
}
function* requestAdmissionCandidate({ payload }) {
  try {
    yield requestAPI({
      verb: PUT,
      endPoint: urlsApi.ADMISSION_CANDIDATES_INFORMATION,
      data: null,
      id: payload.id,
    });
    yield put({ type: Types.CANDIDATE_ADMISSION_SUCCESS });
    const messages = [
      { type: "info", message: "Candidato Importado com Sucesso" },
    ];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  } catch (error) {
    const messages = buildErroHandleItems(error.response, error.message);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATE_ADMISSION_FAIL, error });
  }
}

function* requestGetProfile() {
  try {
    const user = yield requestAPI({
      verb: GET,
      endPoint: urlsApi.GET_PERSONAL_INFORMATION,
      data: null,
    });
    const candidate = {
      name: user.nome,
      nomeQueGostariaDeSerChamado: user.nomeQueGostariaDeSerChamado,
      birth: user.dataNascimento && calcAge(user.dataNascimento),
      datebirth: formatterDateBrazilian(user.dataNascimento),
      pis: user.pis,
      bank: user.banco,
      agency: user.agencia,
      count: user.conta,
      alternativeCount: user.contaAlternativa,
      countType: user.tipoConta,
      alternativeCountType: user.tipoContaAlternativa,
      alternativeBank: user.bancoAlternativo,
      alternativeAgency: user.agenciaAlternativa,
      motherName: user.nomeDaMae,
      motherBirth: user.dataNascimentoMae,
      fatherName: user.nomeDoPai,
      fatherBirth: user.dataNascimentoPai,
      spouseInformation: user.conjugeOuCompanheiro,
      spouseGenre: user.sexoConjuge,
      spouseName: user.nomeDoConjuge,
      spouseBirth: user.dataNascimentoConjuge
        ? formatterDateBrazilian(user.dataNascimentoConjuge)
        : null,
      formationQualification: user.formacaoQualificacao,
      colorRace: user.corRaca,
      homeCity: user.cidadeNatal ? user.cidadeNatal.id : null,
      homeState: user.cidadeNatal ? user.cidadeNatal.estado.id : null,
      spouseCpf: user.cpfConjuge,
      spouseIrrf: user.conjugeDependenteIRRF ? 1 : 0,
      nationality:
        user.paisNacionalidade !== undefined && user.paisNacionalidade !== null
          ? user.paisNacionalidade.id
          : null,
      ctpsIssuanceState: user.estadoEmissorCTPS_Id
        ? user.estadoEmissorCTPS_Id
        : null,
      issuingEntity: user.orgaoEmissorRG ? user.orgaoEmissorRG : null,
      issuingStateRG: user.estadoEmissorRG ? user.estadoEmissorRG.id : null,
      dateEmition: user.dataEmissaoRG
        ? formatterDateBrazilian(user.dataEmissaoRG)
        : null,
      genre:
        user.genero !== undefined && user.genero !== null ? user.genero : null,
      genreDescription:
        user.descricaoGenero !== undefined && user.descricaoGenero !== null
          ? user.descricaoGenero
          : null,
      disability: user.possuiDeficiencia ? 1 : 0,
      typeDisability: user.tipoDeficiencia ? user.tipoDeficiencia : null,
      emailAlternative: user.emailAlternativo ? user.emailAlternativo : null,
      maritalStatus:
        user.estadoCivil == undefined && user.estadoCivil == null
          ? null
          : user.estadoCivil,
      level:
        user.idEscolaridade == undefined && user.idEscolaridade == null
          ? null
          : user.idEscolaridade,
      idCidadePreferenciaTrabalho:
        user.idCidadePreferenciaTrabalho == undefined &&
          user.idCidadePreferenciaTrabalho == null
          ? null
          : user.idCidadePreferenciaTrabalho,
      email: user.email,
      address: {
        place: user.endereco.logradouro,
        zipCode: user.endereco.cep,
        number: user.endereco.numero,
        complement: user.endereco.complemento,
        neighborhood: user.endereco.bairro,
        city: user.endereco.cidade ? user.endereco.cidade.id : null,
        state: user.endereco.cidade ? user.endereco.cidade.estado.id : null,
        country: user.endereco.cidade
          ? user.endereco.cidade.estado.pais.id
          : null,
        typeNeighborhood: user.endereco.tipoBairro
          ? user.endereco.tipoBairro.id
          : null,
        typeStreet: user.endereco.tipoLogradouro
          ? user.endereco.tipoLogradouro.id
          : null,
      },
      rg: user.rg,
      phone1: user.telefone1,
      phone2: user.telefone2,
      cpf: user.cpf,
      doYouHaveAnyRelativesWhoPublicFunction: user.possuiFamiliarCargoPublico,
      namePublicParent: user.nomeFamiliarCargoPolitico
        ? user.nomeFamiliarCargoPolitico
        : null,
      officePublicParent: user.cargoFamiliarCargoPolitico
        ? user.cargoFamiliarCargoPolitico
        : null,
      doYouHaveAnyRelativesWhoWorkHere: user.possuiParentesTrabalhandoNaAec,
      nameParentWorkHere: user.nomeParenteTrabalhandoNaAec
        ? user.nomeParenteTrabalhandoNaAec
        : null,
      officeParentWorkHere: user.cargoParenteTrabalhandoNaAec
        ? user.cargoParenteTrabalhandoNaAec
        : null,
      dependents:
        user.dependentes == null
          ? []
          : user.dependentes.map((dependent, index) => ({
            ...dependent,
            idx: index,
            dataNascimento: dependent.dataNascimento
              ? formatterDateBrazilian(dependent.dataNascimento)
              : null,
          })),
      dataAdmissao: user.dataAdmissao
        ? formatterDateJustDate(user.dataAdmissao)
        : null,
      endContractData: user.dataFinalContrato
        ? formatterDateJustDate(user.dataFinalContrato)
        : null,
      dataCadastroPis: user.dataCadastroPis
        ? formatterDateBrazilian(user.dataCadastroPis)
        : null,
      codTipoFuncionario: user.codTipoFuncionario
        ? user.codTipoFuncionario
        : null,
      codFuncao: user.codFuncao ? user.codFuncao : null,
      funcao: user.funcao ? user.funcao : null,
      codSecao: user.secao ? user.secao : null,
      pisParaFgts: user.pisParaFgts ? user.pisParaFgts : null,
      salario: user.salario ? user.salario : null,
      tomador: user.tomador ? user.tomador : null,
      motivoAdmissao: user.motivoAdmissao,
      coligadaCandidato: user.coligadaCandidato,
      typeContract: user.tipoContrato,
      daysFirstContract: user.primeiroDiaContrato,
      daysSecondContract: user.SegundoDiaContrato,
      jorney: user.jornada,
      timeJorney: user.horario,
      contractPartialRegime: user.contratoRegimeParcial,
      typeRegimeJorney: user.tipoDeRegimeJornada,
      situation: user.situacaoAZ,
      fixCode: user.codigoFixo ? 1 : 0,
      codeSEFIP: user.codigoSEFIP,
      codeSindicato: user.codigoSindicato,
      pointRecord: user.registraPonto,
      registrationIntegration: user.integracaoCadastro,
      registrationHierarchy: user.hierarquiaCadastro,
      dentalPlan: user.planoOdontologico,
      lifeInsurance: user.seguroVida,
      medicalAssistance: user.assistenciaMedica,
      medicalAssistanceBradesco: user.assistenciaMedicaBradesco,
      medicalAssistanceUnimed: user.assistenciaMedicaUnimed,
      dentalPlanSulamerica: user.planoOdontologicoSulamerica,
      snack: user.refeicao,
      food: user.alimentacao,
      ctpsIssuanceState: user.estadoEmissorCTPS_Id
        ? user.estadoEmissorCTPS_Id.id
        : null,
      // // numberOfChildren: user.quantidadeDeFilhos ? user.quantidadeDeFilhos : 0,
      // numberOfChildren: user.dependentes == null ? user.dependent.length : 0,
      // idsDependentesRemover: []
    };
    yield put({
      type: Types.CANDIDATE_GET_PROFILE_SUCCESS,
      ...candidate,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.CANDIDATE_REGISTER_VACANCY_FAIL,
    });
  }
}

function* requestCandidateSimpleInformations() {
  try {
    const {
      nome,
      nomeCompleto,
      avatar,
      forcaPerfil,
      dadosNecessariosPreenchidos,
      preAdmissao,
      etapaPendente,
      idVagaAtual,
    } = yield requestAPI({
      verb: GET,
      endPoint: urlsApi.GET_USER_INFORMATION,
      data: null,
    });
    localStorage.setItem("name", nome);
    localStorage.setItem("fullName", nomeCompleto);
    const { preAdmissaoEstaCompleto, candidatoPodeAlterarDadosPreAdmissao } =
      preAdmissao;

    const possuiEtapaPendente = etapaPendente.possuiEtapaPendente;
    const tipoEtapa = etapaPendente.tipoEtapa;
    const etapaPendenteId = etapaPendente.id;

    yield put({
      type: Types.CANDIDATE_SIMPLE_SUCCESS,
      forcaPerfil,
      avatar,
      preAdmissaoEstaCompleto,
      candidatoPodeAlterarDadosPreAdmissao,
      possuiEtapaPendente,
      tipoEtapa,
      etapaPendenteId,
      idVagaAtual,
    });

    if (!dadosNecessariosPreenchidos) {
      yield put({ type: Types.CANDIDATE_REQUIRE_FIELDS });
    } else {
      yield put({ type: Types.CANDIDATE_UNREQUIRE_FIELDS });
    }
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATE_SIMPLE_FAIL, error });
  }
}

function getInfoCandidateRmTomador(coligada) {
  return API.get(urlsApi.GET_TOMADOR_RM(coligada))
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* requestCandidateRmTomador({ payload }) {
  try {
    const tomador = yield getInfoCandidateRmTomador(payload.coligadaCandidato);

    yield put({
      type: Types.INFORMACOES_CANDIDATO_RM_TOMADOR_REQUEST_SUCCESS,
      tomador,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_RM_TOMADOR_REQUEST_FAIL,
      error,
    });
  }
}

function getInfoCandidateRmTipoFuncionario() {
  return API.get(urlsApi.GET_TIPO_FUNCIONARIO_RM)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* requestCandidateRmTipoFuncionario() {
  try {
    const tipoFuncionario = yield getInfoCandidateRmTipoFuncionario();

    yield put({
      type: Types.INFORMACOES_CANDIDATO_RM_TIPO_FUNCIONARIO_REQUEST_SUCCESS,
      tipoFuncionario,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_RM_TIPO_FUNCIONARIO_REQUEST_FAIL,
      error,
    });
  }
}

function getInfoCandidateRmSecao(coligada) {
  return API.get(urlsApi.GET_SECAO_RM(coligada))
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* requestCandidateRmSecao({ payload }) {
  try {
    const codSecao = yield getInfoCandidateRmSecao(payload.coligadaCandidato);

    yield put({
      type: Types.INFORMACOES_CANDIDATO_RM_SECAO_REQUEST_SUCCESS,
      codSecao,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_RM_SECAO_REQUEST_FAIL,
      error,
    });
  }
}

function getInfoCandidateRmFuncaoByColigada(coligada) {
  return API.get(`${urlsApi.GET_FUNCAO_BY_COLIGADA_RM(coligada)}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* requestCandidateRmFuncaoByColigada({ payload }) {
  try {
    const funcao = yield getInfoCandidateRmFuncaoByColigada(
      payload.coligadaCandidato
    );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_RM_FUNCAO_BY_COLIGADA_REQUEST_SUCCESS,
      funcao,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_RM_FUNCAO_BY_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function getInfoCandidateColigadaPerfil() {
  return API.get(urlsApi.GET_COLIGADA_RM_PERFIL)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* requestCandidateColigadaPerfil() {
  try {
    const coligadaCandidato = yield getInfoCandidateColigadaPerfil();

    yield put({
      type: Types.INFORMACOES_CANDIDATO_COLIGADA_PERFIL_REQUEST_SUCCESS,
      coligadaCandidato,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_COLIGADA_PERFIL_REQUEST_FAIL,
      error,
    });
  }
}

function getInfoCandidateColigada() {
  return API.get(urlsApi.GET_COLIGADA_RM)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* requestCandidateColigada() {
  try {
    const coligadaCandidato = yield getInfoCandidateColigada();

    yield put({
      type: Types.INFORMACOES_CANDIDATO_COLIGADA_REQUEST_SUCCESS,
      coligadaCandidato,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function getInfoCandidateMotivoAdmissao(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_MOTIVO_ADMISSAO_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function* requestCandidateMotivoAdmissao({ payload }) {
  try {
    const motivoAdmissao = yield getInfoCandidateMotivoAdmissao(
      payload.coligadaCandidato
    );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_MOTIVO_ADMISSAO_POR_COLIGADA_REQUEST_SUCCESS,
      motivoAdmissao,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_MOTIVO_ADMISSAO_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function getInfoCandidateSindicato(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_SINDICATO_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidateHorario(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_HORARIO_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidatePlanoDental(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_DENTAL_PLAN_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidateSeguro(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_SEGURO_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidateAssistenciaMedica(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_MEDIC_ASSIST_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidateAssistenciaMedicaBradesco(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_MEDIC_ASSIST_BRADESCO_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidateAssistenciaMedicaUnimed(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_MEDIC_ASSIST_UNIMED_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidatePlanoDentalSulamerica(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_DENTAL_PLAN_SULAMERICA_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidateAlimentacao(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_ALIMENTACAO_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function getInfoCandidateRefeicao(coligada) {
  if (coligada) {
    return API.get(urlsApi.GET_REFEICAO_BY_COLIGADA_RM(coligada))
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

function requestPasswordReset(body) {
  return API.post(`${urlsApi.RELEASE_PASSWORD_RESET}`, body.candidates, {
    responseType: "application/json",
  }).catch((error) => {
    throw error;
  });
}
function* requestCandidateSindicato({ payload }) {
  try {
    const codeSindicato = yield getInfoCandidateSindicato(
      payload.coligadaCandidato
    );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_SINDICATO_POR_COLIGADA_REQUEST_SUCCESS,
      codeSindicato,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_SINDICATO_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function* requestCandidateHorario({ payload }) {
  try {
    const horarios = yield getInfoCandidateHorario(payload.coligadaCandidato);

    yield put({
      type: Types.INFORMACOES_CANDIDATO_HORARIO_POR_COLIGADA_REQUEST_SUCCESS,
      horarios,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_HORARIO_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function* requestCandidatePlanoDental({ payload }) {
  try {
    const planoDental = yield getInfoCandidatePlanoDental(
      payload.coligadaCandidato
    );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_POR_COLIGADA_REQUEST_SUCCESS,
      planoDental,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function* requestCandidateSeguro({ payload }) {
  try {
    const segurosDeVida = yield getInfoCandidateSeguro(
      payload.coligadaCandidato
    );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_SEGURO_POR_COLIGADA_REQUEST_SUCCESS,
      segurosDeVida,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_SEGURO_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function* requestCandidateAssistenciaMedica({ payload }) {
  try {
    const assistenciaMedica = yield getInfoCandidateAssistenciaMedica(
      payload.coligadaCandidato
    );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_POR_COLIGADA_REQUEST_SUCCESS,
      assistenciaMedica,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function* requestCandidateAssistenciaMedicaBradesco({ payload }) {
  try {
    const assistenciaMedicaBradesco =
      yield getInfoCandidateAssistenciaMedicaBradesco(
        payload.coligadaCandidato
      );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_BRADESCO_POR_COLIGADA_REQUEST_SUCCESS,
      assistenciaMedicaBradesco,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_BRADESCO_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function* requestUpdateDocument({ payload }) {
  try {
    let formImg = new FormData();
    formImg.append("documento", payload.file);
    formImg.append("idDocument", payload.idDocument);
    formImg.append("idCandidato", payload.idCandidato);
    formImg.append("dataDocumentoRH", payload.dataDocumentoRH);

    yield requestAPI({
      verb: PUT,
      endPoint: urlsApi.UPLOAD_DOCUMENT,
      data: formImg,
    });

    yield put({
      type: Types.CANDIDATE_DOCUMENT_UPDATE_SUCCESS});
    const messages = [{ type: "success", message: "Documentos enviados com sucesso!" }];
    console.log("msg deu bom", messages)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    console.log("msg deu ruim", messages, "erro", error)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.CANDIDATE_DOCUMENT_UPDATE_FAIL,
      messages,
    });
  }
}

// function convertDocuments(documents) {
//   return {
//     isResend: documents.reenvio,
//     canFinish: documents.podeConcluir,
//     documentsList: documents.documentosList.map((e) => ({
//       idDocument: e.id,
//       Name: e.nome,
//       Descricao: e.descricao,
//       Status: e.status,
//       DescricaoErro: e.descricaoErro,
//       Obrigatorio: e.obrigatorio,
//       DocumentosEnviados: e.documentosEnviados.map((d) => {
//         return {
//           id: d.id,
//           instrucao: d.instrucao,
//           nome: d.nome,
//           status: d.status,
//         };
//       }),
//     })),
//   };
// }

function* requestCandidateAssistenciaMedicaUnimed({ payload }) {
  try {
    const assistenciaMedicaUnimed =
      yield getInfoCandidateAssistenciaMedicaUnimed(payload.coligadaCandidato);

    yield put({
      type: Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_UNIMED_POR_COLIGADA_REQUEST_SUCCESS,
      assistenciaMedicaUnimed,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_UNIMED_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function* requestCandidatePlanoDentalSulamerica({ payload }) {
  try {
    const planoDentalSulamerica = yield getInfoCandidatePlanoDentalSulamerica(
      payload.coligadaCandidato
    );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_SULAMERICA_POR_COLIGADA_REQUEST_SUCCESS,
      planoDentalSulamerica,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_SULAMERICA_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function* requestCandidateAlimentacao({ payload }) {
  try {
    const alimentacao = yield getInfoCandidateAlimentacao(
      payload.coligadaCandidato
    );

    yield put({
      type: Types.INFORMACOES_CANDIDATO_ALIMENTACAO_POR_COLIGADA_REQUEST_SUCCESS,
      alimentacao,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_ALIMENTACAO_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function requestCandidateInformationRmSecaoList(coligada) {
  return API.get(urlsApi.GET_PROJETO_CANDIDATO(coligada))
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* candidateInformationRmSecaoList({ payload }) {
  try {
    const informationSecao = yield requestCandidateInformationRmSecaoList(
      payload
    );
    yield put({
      type: Types.CANDIDATE_INFORMATION_RM_SECAO_LIST_SUCCESS,
      secaoInfo: informationSecao,
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({
      type: Types.CANDIDATE_INFORMATION_RM_SECAO_LIST_FAIL,
      messages: error,
    });
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
  }
}

function* requestCandidateRefeicao({ payload }) {
  try {
    const refeicao = yield getInfoCandidateRefeicao(payload.coligadaCandidato);

    yield put({
      type: Types.INFORMACOES_CANDIDATO_REFEICAO_POR_COLIGADA_REQUEST_SUCCESS,
      refeicao,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_REFEICAO_POR_COLIGADA_REQUEST_FAIL,
      error,
    });
  }
}

function getInfoCandidateHierarquiaCadastro() {
  return API.get(urlsApi.GET_HIERARQUIA_CADASTRO_RM)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* requestCandidateHierarquiaCadastro() {
  try {
    const registrationHierarchy = yield getInfoCandidateHierarquiaCadastro();

    yield put({
      type: Types.INFORMACOES_CANDIDATO_HIERARQUIA_CADASTRO_FLUIG_REQUEST_SUCCESS,
      registrationHierarchy,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({
      type: Types.INFORMACOES_CANDIDATO_HIERARQUIA_CADASTRO_FLUIG_REQUEST_FAIL,
      error,
    });
  }
}

function* passwordReset(payload) {
  try {
    yield requestPasswordReset(payload);
    yield put({ type: Types.CANDIDATES_PASSWORD_RESET_SUCCESS });
    const messages = [
      { type: "info", message: "Sucesso ao resetar as senhas dos candidatos." },
    ];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_PASSWORD_RESET_FAIL, error });
  }
}

function requestReleaseCancellationForCandidates(payload) {
  return API.put(`${urlsApi.RELEASE_CANCELLATION_FOR_CANDIDATES}`, {
    ...payload,
  })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* releaseCancellationForCandidates({ payload }) {
  try {
    yield requestReleaseCancellationForCandidates(payload);
    yield put({
      type: Types.RELEASE_CANCELLATION_FOR_CANDIDATES_SUCCESS,
    });
    const messages = [
      { type: "info", message: "Sucesso ao destivar candidatos." },
    ];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({
      type: Types.RELEASE_CANCELLATION_FOR_CANDIDATES_FAIL,
      messages: error,
    });
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
  }
}

const getStateVacanciesList = (state) => state.candidates.vacancies;
const getStateCandidatesList = (state) => state.candidates.candidates;
const getActualPage = (state) => state.candidates?.page;

export function* watcherSaga() {
  yield takeLatest(Types.VACANCY_CANDIDATES_REQUEST, getVacanciesToCandidates);
  yield takeLatest(Types.CANDIDATES_FROM_REQUEST, getCandidatesFromVacancy);
  yield takeLatest(Types.CANDIDATES_MANAGER_REQUEST, managerCandidatesAction);
  yield takeLatest(
    Types.CANDIDATES_DISPATCH_MANAGER_REQUEST,
    managerDispatchCandidatesAction
  );
  yield takeLatest(Types.CANDIDATE_SHOW_REQUEST, requestCandidate);
  yield takeLatest(
    Types.CANDIDATES_SCHEDULE_ENTERVIEW_REQUEST,
    requestSaveScheduleInterview
  );
  yield takeLatest(Types.CANDIDATES_EXPORT_REQUEST, exportCandidates);
  yield takeLatest(Types.CANDIDATES_ADD_CANDIDATE_REQUEST, addCandidate);
  yield takeLatest(Types.CANDIDATES_CREATE_CANDIDATE_REQUEST, createCandidate);
  yield takeLatest(
    Types.CANDIDATES_CREATE_CANDIDATE_BULK_REQUEST,
    createCandidateBulk
  );

  yield takeLatest(
    Types.CANDIDATES_GET_CANDIDATES_LIST_REQUEST,
    getCandidatesList
  );

  yield takeLatest(Types.INFORMACOES_CANDIDATO_REQUEST, getInfoCandidateById);

  yield takeLatest(
    Types.CANDIDATE_PROFILE_UPDATE_REQUEST,
    requestUpdateCandidateProfile
  );
  yield takeLatest(
    Types.CANDIDATE_ADMISSION_REQUEST,
    requestAdmissionCandidate
  );

  yield takeLatest(
    Types.CANDIDATE_SIMPLE_REQUEST,
    requestCandidateSimpleInformations
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_RM_TOMADOR_REQUEST,
    requestCandidateRmTomador
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_RM_TIPO_FUNCIONARIO_REQUEST,
    requestCandidateRmTipoFuncionario
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_RM_SECAO_REQUEST,
    requestCandidateRmSecao
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_RM_FUNCAO_BY_COLIGADA_REQUEST,
    requestCandidateRmFuncaoByColigada
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_COLIGADA_REQUEST,
    requestCandidateColigada
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_COLIGADA_PERFIL_REQUEST,
    requestCandidateColigadaPerfil
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_MOTIVO_ADMISSAO_POR_COLIGADA_REQUEST,
    requestCandidateMotivoAdmissao
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_SINDICATO_POR_COLIGADA_REQUEST,
    requestCandidateSindicato
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_HORARIO_POR_COLIGADA_REQUEST,
    requestCandidateHorario
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_POR_COLIGADA_REQUEST,
    requestCandidatePlanoDental
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_SEGURO_POR_COLIGADA_REQUEST,
    requestCandidateSeguro
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_POR_COLIGADA_REQUEST,
    requestCandidateAssistenciaMedica
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_BRADESCO_POR_COLIGADA_REQUEST,
    requestCandidateAssistenciaMedicaBradesco
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_UNIMED_POR_COLIGADA_REQUEST,
    requestCandidateAssistenciaMedicaUnimed
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_SULAMERICA_POR_COLIGADA_REQUEST,
    requestCandidatePlanoDentalSulamerica
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_REFEICAO_POR_COLIGADA_REQUEST,
    requestCandidateRefeicao
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_ALIMENTACAO_POR_COLIGADA_REQUEST,
    requestCandidateAlimentacao
  );

  yield takeLatest(
    Types.INFORMACOES_CANDIDATO_HIERARQUIA_CADASTRO_FLUIG_REQUEST,
    requestCandidateHierarquiaCadastro
  );

  yield takeLatest(Types.CANDIDATES_PASSWORD_RESET_REQUEST, passwordReset);

  yield takeLatest(
    Types.RELEASE_CANCELLATION_FOR_CANDIDATES_REQUEST,
    releaseCancellationForCandidates
  );
  yield takeLatest(
    Types.CANDIDATE_INFORMATION_RM_SECAO_LIST_REQUEST,
    candidateInformationRmSecaoList
  );

  yield takeLatest(
    Types.CANDIDATE_DOCUMENT_UPDATE_REQUEST,
    requestUpdateDocument
  );
}
