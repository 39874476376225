import * as routes from "utils/contants/urls";
import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { view, order as orderIcon } from "assets/icons/icons";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import BreadCrumb from "components/Layout/breadcrumb";
import {
  MultSelectCreatable,
  TextField,
  DatePicker,
} from "components/shared/CustomInputs";
import { Collapse } from "components/shared/CustomComponents";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Creators as messageAction } from "store/ducks/messages";
import { Creators as contractsAction } from "store/ducks/contracts";
import { Creators as userBenefits } from "store/ducks/userBenefits";
import { Creators as otherPayments } from "store/ducks/otherPayments";

import { Body, CustomButtonSecondary } from "./ListContractsCandidatesStyle";
import { orderList } from "helpers/sharedHelpers";
import Pagination from "components/Pagination";

const ListPaymentsCandidates = ({
  candidates,
  isLoading,
  pagination,
  messagePublication,
  requestInputValues,
  hasMore,
  getPayments,
  PagamentosList,
  userBenefitsFiltered,
  requestExportPaymentsInformations,
}) => {
  useEffect(() => {
    userBenefitsFiltered({
      nomeFuncionario: "",
      cpfList: [],
      mesCompetencia: "",
      firstPage: true,
      NumeroPagina: 1,
    });
  }, []);

  const [clearFilter, setClearFilter] = useState(false);
  const [order, setOrder] = useState(undefined);
  const [formValues, setFormValues] = useState({
    cpfs: [],
    mesCompetencia: "",
    nomeFuncionario: "",
  });
  const [erroMessage, setErroMessage] = useState({ message: "" });
  const AplicaFiltro = (form, firstPage) => {
    userBenefitsFiltered({
      nomeFuncionario: form.nomeFuncionario,
      cpfList: form.cpfs,
      mesCompetencia: form.mesCompetencia,
      firstPage: firstPage,
      NumeroPagina: form.NumeroPagina,
    });

    setFormValues(form);
  };

  const loadMore = () => {
    setClearFilter(false);

    AplicaFiltro(formValues, false);
  };

  const renderIcon = (key) => {
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` },
    });
  };

  const orderBy = (orderBy, list) => {
    let response;

    switch (orderBy) {
      case "cpf":
        response = orderList("cpf", order, list);
        break;
      case "motivo":
        response = orderList("motivo", order, list);
        break;
      case "pagamento":
        response = orderList("pagamento", order, list);
        break;
      case "nome":
        response = orderList("nome", order, list);
        break;
      case "mesCompetencia":
        response = orderList("mesCompetencia", order, list);
        break;
      default:
        response = { list, order: undefined };
        break;
    }
    setOrder(response.order);
  };

  const Filtrar = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, true);
  };

  const LimparFiltro = () => {
    setClearFilter(true);
    localStorage.removeItem("filter_ListContractsCandidates");
    setFormValues({ cpfs: [], mesCompetencia: "", nomeFuncionario: "" });
    AplicaFiltro({ cpfs: [], mesCompetencia: "", nomeFuncionario: "" }, true);
  };

  const TrataMultiInsert = (Insert) => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];

    Insert.map((item) => {
      let conc = item.value.trim().split(/[\s,]+/);
      conc.map((filtItem) => {
        let concFiltered = filtItem.replace(/\D/g, "");
        if (concFiltered.length === 11) {
          result.push(concFiltered);
        } else {
          messages.push({
            message: `CPF ${filtItem} precisa ter 11 dígitos. Não será considerado no filtro.`,
            type: "warning",
          });
        }
      });
    });
    messagePublication(messages);
    return result;
  };

  const requestPage = (filter) => {
    userBenefitsFiltered({ NumeroPagina: filter.NumeroPagina });
  };

  const redirect = (url) => {
    window.location.href = url;
  };

  const exportarPagamentos = (form) => {
    requestExportPaymentsInformations({
      nome: form.nome,
      cpfs: form.cpfs,
      competencia: form.competencia,
      numeroPagina: form.numeroPagina,
    });
  };

  const renderTableItems = (PagamentosList) =>
    PagamentosList?.map((beneficio) => (
      <Link
        onClick={() => {
          redirect(`${routes.PAYMENTS_CANDIDATE}/${beneficio.id}`);
        }}
      >
        <TableRow>
          <TableColumn ta="center">{beneficio.cpf}</TableColumn>
          <TableColumn ta="center">
            <Label>{beneficio.nome}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{beneficio.mesCompetencia.toString().split("T")[0]}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{beneficio.motivo}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{`${"R$ "}` + beneficio.pagamento}</Label>
          </TableColumn>
          <TableColumn ta="right" action>
            <Actions>
              <ActionItem>
                {view()}
                <LabelAction>
                  <FormattedMessage id="sharedItems.view" />
                </LabelAction>
              </ActionItem>
            </Actions>
          </TableColumn>
        </TableRow>
      </Link>
    ));

  const renderCandidatesTable = (PagamentosList) => {
    return (
      <>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead
                onClick={() => orderBy("cpf", PagamentosList)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.id" />
                </LabelHeader>
                {renderIcon("cpf")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("nome", PagamentosList)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.name" />
                </LabelHeader>
                {renderIcon("nome")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("mesCompetencia", PagamentosList)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.competenceMonth" />
                </LabelHeader>
                {renderIcon("mesCompetencia")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("motivo", PagamentosList)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.paymentType" />
                </LabelHeader>
                {renderIcon("ausencias")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("pagamento", PagamentosList)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.paymentValue" />
                </LabelHeader>
                {renderIcon("diasUteis")}
              </TableHead>
              <TableHead style={{ textAlign: "center" }}>
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.export" />
                </LabelHeader>
              </TableHead>
            </TableRow>
            {renderTableItems(PagamentosList)}
          </TableBody>
        </Table>
        <Pagination getItemsPage={requestPage} {...pagination} pageSize={20} />
      </>
    );
  };

  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <BreadCrumb title={<FormattedMessage id="manager.selectPayment" />} />
      <Body>
        <Row>
          <Col md={4}>
            <TextField
              label={<FormattedMessage id="benefitsCandidateList.name" />}
              value={formValues.nomeFuncionario || ""}
              onChange={(e) => {
                const { value } = e.target;
                setFormValues({
                  ...formValues,
                  nomeFuncionario: value,
                });
              }}
            />
          </Col>
          <Col md={4}>
            <MultSelectCreatable
              key={5}
              name={<FormattedMessage id="plugCandidatesList.cpf" />}
              label={<FormattedMessage id="plugCandidatesList.cpf" />}
              isMulti
              options={formValues.cpfs}
              value={
                formValues.cpfs
                  ? formValues.cpfs.map((e) => ({
                      label: e,
                      value: e,
                    }))
                  : []
              }
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  cpfs: e ? TrataMultiInsert(e) : [],
                })
              }
            />
          </Col>
          <Col md={4}>
            <DatePicker
              style={{ boxShadow: "#A5A5A5" }}
              width="223px"
              type="month"
              value={formValues.mesCompetencia}
              label={
                <FormattedMessage id="benefitsCandidateList.competenceMonth" />
              }
              name={
                <FormattedMessage id="benefitsCandidateList.competenceMonth" />
              }
              max="2099-12-31"
              min="2023-01-01"
              onChange={(e) => {
                const year = e.target.value.split("-")[0];
                if (year === "" || parseInt(year) > 2099) return;

                setFormValues({
                  ...formValues,
                  mesCompetencia: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => Filtrar()}
              name={<FormattedMessage id="sharedItems.filter" />}
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => LimparFiltro()}
              name={<FormattedMessage id="sharedItems.clearFilter" />}
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => exportarPagamentos(formValues)}
              name={<FormattedMessage id="sharedItems.exportPayments" />}
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => redirect(`${routes.PAYMENTS_MANEGER}`)}
              name={<FormattedMessage id="sharedItems.registerPayments" />}
            />
          </Col>
        </Col>

        {PagamentosList && renderCandidatesTable(PagamentosList)}
      </Body>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userBenefitsFiltered: (filter) =>
    dispatch(otherPayments.filtrarPagamento(filter)),

  candidateFiles: (id) => dispatch(contractsAction.candidateFilesRequest(id)),
  setStatusFileRequest: (payload) =>
    dispatch(contractsAction.candidateSetStatusFileRequest(payload)),
  requestInputValues: (inputs) =>
    dispatch(formInputsActions.formInputsRequest(inputs)),
  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),

  requestExportPaymentsInformations: (payload) =>
    dispatch(otherPayments.candidatesExportPaymentsInformationRequest(payload)),

  getPayments: () => dispatch(otherPayments.getUserPayments()),
});

const mapStateToProps = (state) => ({
  isLoading: state.otherPayments.isLoading,
  candidates: state.contracts.candidates,
  pagination: state.otherPayments.pagination,
  quantidadeItens: state.contracts.quantidadeItens,
  PagamentosList: state.otherPayments.PagamentosList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPaymentsCandidates);

ListPaymentsCandidates.propTypes = {
  candidates: PropTypes.func,
  page: PropTypes.any,
  hasMore: PropTypes.any,
  quantidadeItens: PropTypes.any,
  PagamentosList: PropTypes.any,
};

ListPaymentsCandidates.defaultProps = {
  candidates: () => {},
};
