import { put, select, takeLatest } from 'redux-saga/effects'

import { Types } from '../ducks/alert'

function* setAlerts({ messages }) {
    try {
        const alerts = messages;
        yield put({ type: Types.SET_ALERTS_SUCCESS, alerts })
    } catch (error) {
        yield put({ type: Types.SET_ALERTS_FAIL, error })
    }
}

function* popAlerts() {
    try {
        let alerts = select(getAlerts);
        alerts.shift();
        yield put({ type: Types.POP_ALERTS_SUCCESS, alerts })
    } catch (error) {
        yield put({ type: Types.POP_ALERTS_FAIL, error })
    }
}

const getAlerts = (state) => state.alerts.alerts;

export function* watchSaga() {
    yield takeLatest(Types.SET_ALERTS_REQUEST, setAlerts);
    yield takeLatest(Types.POP_ALERTS_REQUEST, popAlerts);
}
