export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};
const theme = {
  colors: {
    // Main color
    defaultColor: "#6C48C5",
    defaultColorHover: "#C68FE6",

    titleColor: "#1230AE",
    colorLabelDefault: "#858585",
    whiteDefault: "white",
    redDefault: "red",
    errorColor: "#F17090",
    labelSecondary: "#d2d2d2",
    gray: "#FBFBFB",
    softGray: "#005394",
    darkGray: "#4a4a4a",
    ligthGray: "#cfcfcf",
    inputTextColor: "#bababa",
    borderRadius: "#dcdcdc",
    cardColor: "#f8f8f8",
    iconColor: "#818181",
    softGreen: "#8bc63f",
    spanColor: "#9b9b9b",
    orangeColor: "#edce60",
  },
  font: {
    fontSizeDefault: "20px",
  },
  size: {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
  },
  defineColor: (color) => {
    switch (color) {
      case "gray":
        return theme.colors.gray;
      case "darkGray":
        return theme.colors.darkGray;
      case "softGray":
        return theme.colors.softGray;
      case "softGreen":
        return theme.colors.softGreen;
      case "default":
        return theme.colors.defaultColor;
      case "errorColor":
        return theme.colors.errorColor;
      default:
        return theme.colors.titleColor;
    }
  },
  aecColors: {},
  margin: {
    marginBody: "1.875em",
    internalForm: "1.250em",
  },
  words: {
    nameMark: "Vinci",
    fullNameMark: "Vinci Energies",
  },
};

export default theme;
