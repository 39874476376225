import React from 'react';

import './dashtitle.scss';

export const DashTextTitle = ({data}) => {
    return (
        <div className="dashtext-title" >
            <div className="text" style={{'--title': data?.customStyle?.color}}>
                { data?.data || 'No Title' }
            </div>
        </div>
    )
}