import {
  CheckboxContainer,
  HiddenCheckbox,
  HiddenRadio,
  Icon,
  Input,
  InputRow,
  InputRowCheckBox,
  LabelInput,
  RadioIcon,
  SearchInput,
  StyledCheckbox,
  StyledRadio,
  TextAreaStyle,
  customStylesSelect,
} from "./CustomInputsStyle";
import React, { useEffect, useState } from "react";

import { GIF_TRACADO_TEXTO_REDACAO } from "../../../utils/contants/urlsApi";

import { Col } from "react-bootstrap";

import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import theme from "../Theme";

export const TextField = (props) => {
  return (
    <InputRow mb={props.mb} mt={props.mt}>
      <LabelInput>{props.label}</LabelInput>
      <Input {...props} />
    </InputRow>
  );
};

export const RedactionField = (props) => {
  const [userText, setUserText] = useState("");
  const [textVal, setFormValues] = useState({
    T1: "",
    T2: "",
    T3: "",
    palavrasDigitadas: 0,
    limitePalavras: 300,
    minPalavras: 250,
    textoPersistido: "",
    imgcursor: <img src={GIF_TRACADO_TEXTO_REDACAO}></img>,
    digitovalido: true,
    ultimoLetra: false,
  });
  const [exibitionText, setExibitionText] = useState("");
  const handleUserKeyPress = (event) => {
    const { key, keyCode } = event;
    if (
      event.key.length > 1 &&
      event.key !== "Backspace" &&
      event.key !== "Space" &&
      event.key !== "Enter"
    ) {
      return;
    }

    if (
      key === "." ||
      key === "," ||
      key === ";" ||
      key === ":" ||
      key === "(" ||
      key === ")" ||
      key === " " ||
      key === "Space"
    ) {
      textVal.digitovalido = false;
    } else {
      textVal.digitovalido = true;
    }

    if (key === "Backspace") {
      Deleta();
    } else if (textVal.palavrasDigitadas < textVal.limitePalavras) {
      processaDigitos(key);
    }

    setUserText(`${userText}${key}`);

    setExibitionText(
      textVal.textoPersistido +
        textVal.T1 +
        textVal.T2 +
        textVal.T3 +
        textVal.imgcursor
    );
  };

  function AjustaTexto(texto) {
    if (texto === " " || texto === "Space") {
      textVal.ultimoLetra = false;
      return " ";
    }

    if (
      texto !== "Escape" &&
      texto !== "Esc" &&
      texto !== "Enter" &&
      texto !== "Backspace" &&
      texto !== "Shift" &&
      texto !== "Dead" &&
      texto !== "<" &&
      texto !== ">"
    ) {
      textVal.ultimoLetra = true;
      return texto;
    }

    if (texto === "Enter") {
      textVal.ultimoLetra = false;
      return "\n";
    }
  }

  function AjustaEscopo() {
    textVal.textoPersistido += textVal.T1;
    textVal.T1 = textVal.T2;
    textVal.T2 = textVal.T3;
    textVal.T3 = "";
  }

  function Deleta() {
    if (textVal.T3 != "") {
      if (
        textVal.T3.substring(textVal.T3.length - 5, textVal.T3.length) ==
          "&nbsp" ||
        textVal.T3.substring(textVal.T3.length - 5, textVal.T3.length) ==
          "<br/>"
      ) {
        textVal.T3 = textVal.T3.substring(0, textVal.T3.length - 5);
      } else {
        textVal.T3 = textVal.T3.substring(0, textVal.T3.length - 1);
      }

      if (textVal.T3 == "") {
        textVal.palavrasDigitadas--;
      }
    } else if (textVal.T2 != "") {
      if (
        textVal.T2.substring(textVal.T2.length - 5, textVal.T2.length) ==
          "&nbsp" ||
        textVal.T2.substring(textVal.T2.length - 5, textVal.T2.length) ==
          "<br/>"
      ) {
        textVal.T2 = textVal.T2.substring(0, textVal.T2.length - 5);
      } else {
        textVal.T2 = textVal.T2.substring(0, textVal.T2.length - 1);
      }
      if (textVal.T2 == "") {
        textVal.palavrasDigitadas--;
      }
    } else if (textVal.T1 != "") {
      if (
        textVal.T1.substring(textVal.T1.length - 5, textVal.T1.length) ==
          "&nbsp" ||
        textVal.T1.substring(textVal.T1.length - 5, textVal.T1.length) ==
          "<br/>"
      ) {
        textVal.T1 = textVal.T1.substring(0, textVal.T1.length - 5);
      } else {
        textVal.T1 = textVal.T1.substring(0, textVal.T1.length - 1);
      }

      if (textVal.T1 == "") {
        textVal.palavrasDigitadas--;
      }
    }
  }

  function processaDigitos(key) {
    if (!textVal.ultimoLetra) {
      if (
        textVal.T1 === "" ||
        (textVal.T1 !== "" && textVal.T2 === "" && !textVal.digitovalido)
      ) {
        textVal.T1 += AjustaTexto(key);
      } else if (
        textVal.T2 === "" ||
        (textVal.T2 !== "" && textVal.T3 === "" && !textVal.digitovalido)
      ) {
        textVal.T2 += AjustaTexto(key);
      } else {
        if (textVal.T3 === "" || (textVal.T3 !== "" && !textVal.digitovalido)) {
          textVal.T3 += AjustaTexto(key);
        } else {
          AjustaEscopo();

          textVal.T3 += AjustaTexto(key);
        }
      }
    } else {
      if (!textVal.digitovalido) {
        textVal.palavrasDigitadas++;

        props.onChange({
          text: textVal.textoPersistido,
          palavrasDigitadas: textVal.palavrasDigitadas,
          textConclusao:
            textVal.textoPersistido + textVal.T1 + textVal.T2 + textVal.T3,
        });
      }

      if (textVal.T3 !== "") {
        textVal.T3 += AjustaTexto(key);
      } else if (textVal.T2 !== "") {
        textVal.T2 += AjustaTexto(key);
      } else {
        textVal.T1 += AjustaTexto(key);
      }
    }
  }

  useEffect(() => {
    textVal.textoPersistido = props.PreviewRedaction;
    textVal.palavrasDigitadas = props.PalavrasDigitadas;
    props.onChange({
      text: textVal.textoPersistido,
      palavrasDigitadas: textVal.palavrasDigitadas,
      textConclusao:
        textVal.textoPersistido + textVal.T1 + textVal.T2 + textVal.T3,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  });

  return (
    <InputRow mb={props.mb} mt={props.mt} id="">
      <article
        style={{
          lineBreak: "anywhere",
          margin: "18px",
          minHeight: "381px",
          whiteSpace: "pre-wrap",
        }}
      >
        <span style={{ color: "#808080b3" }}>{textVal.textoPersistido} </span>
        {textVal.T1 != "" && (
          <strong>
            {" "}
            {textVal.T1}
            {textVal.T2}
            {textVal.T3}{" "}
          </strong>
        )}
        {textVal.imgcursor}
      </article>
      <div
        style={
          textVal.limitePalavras - textVal.palavrasDigitadas > 30
            ? { fontSize: "small", color: "#74a239" }
            : { fontSize: "small", color: "#d02525" }
        }
      >
        Palavras restantes: {textVal.limitePalavras - textVal.palavrasDigitadas}
      </div>
    </InputRow>
  );
};

export const TextAreaField = (props) => {
  return (
    <InputRow mb={props.mb} mt={props.mt}>
      <LabelInput>{props.label}</LabelInput>
      <TextAreaStyle multi rows={50} {...props} />
    </InputRow>
  );
};

export const CheckBox = ({ ...props }) => {
  const [checked, setChecked] = useState(props.checked);
  const callAction = (e) => {
    setChecked(!checked);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  useEffect(() => {}, [props.selectedOption]);

  return (
    <CheckboxContainer
      mt={props.mt}
      mb={props.mb}
      style={{ width: "100%", marginBottom: "5px", marginTop: "0px" }}
    >
      <InputRowCheckBox>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <StyledCheckbox
            group={props.group}
            defaultChecked={props.defaultChecked}
            checked={checked}
            disabled={props.disabled}
            radius={props.radius}
            type="checkbox"
          >
            <HiddenCheckbox
              onClick={(e) => callAction(e)}
              value={props.value}
              group={props.group}
              checked={checked}
              disabled={props.disabled}
              type="checkbox"
            />
            <Icon>
              <polyline points="15 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        </Col>
        <Col
          xs={11}
          sm={11}
          md={11}
          lg={11}
          xl={11}
          style={{ paddingLeft: "0px", marginLeft: "15px" }}
        >
          <LabelInput size={props.size} noMarginBottom>
            {props.label}
          </LabelInput>
        </Col>
      </InputRowCheckBox>
    </CheckboxContainer>
  );
};

export const SelectField = (props) => {
  return (
    <InputRow mb={props.mb} mr={props.mr} ml={props.ml} width={props.width}>
      <LabelInput ml={props.ml}>{props.label}</LabelInput>
      <CreatableSelect
        placeholder={props.placeholder ? props.placeholder : ""}
        styles={customStylesSelect}
        isDisabled={props.isDisabled}
        formatCreateLabel={(value) => `Criar...  ${value}`}
        {...props}
      />
    </InputRow>
  );
};

export const SelectAutoComplete = (props) => {
  return (
    <InputRow mb={props.mb} mr={props.mr} ml={props.ml} width={props.width}>
      <LabelInput ml={props.ml}>{props.label}</LabelInput>
      <AsyncSelect
        isLoading={props.isLoadingAutocomplete}
        placeholder={props.placeholder ? props.placeholder : ""}
        loadOptions={props.promiseOptions}
        {...props}
      />
    </InputRow>
  );
};

export const MultSelect = (props) => {
  const [error, setError] = useState("");
  const setShowError = () => {
    if (props.validations && (!props.value || !props.value.length)) {
      const errors = props.validations.map((e) => e(props.value));
      setError(errors);
      if (props.formErr && (!props.value || !props.value.length)) {
        props.formValidators(() =>
          props.validations.map((e) => () => e(props.value))
        );
        const { formErr } = props;
        formErr[props.name] = errors;
        props.setFormErros(formErr);
      }
    } else if (props.value) {
      const { formErr } = props;
      try {
        delete formErr[props.name];
        props.setFormErros(formErr);
      } catch (error) {}
    }
  };
  useEffect(() => {
    setShowError();
  }, [props.formErr, props.value]);
  return (
    <InputRow mr={props.mr} ml={props.ml} width={props.width}>
      {props.label && <LabelInput ml={props.ml}>{props.label}</LabelInput>}
      <Select placeholder="Selecionar" styles={customStylesSelect} {...props} />
    </InputRow>
  );
};

export const SelectInput = (props) => {
  return (
    <InputRow mb={props.mb} mr={props.mr} ml={props.ml} width={props.width}>
      <LabelInput ml={props.ml}>{props.label}</LabelInput>
      <Select
        placeholder={props.placeholder ? props.placeholder : ""}
        styles={{...customStylesSelect, menuPortal: base => ({ ...base, zIndex: 9999 })}}
        isDisabled={props.isDisabled}
        {...props}
        menuPortalTarget={document.body}
      />
      {/*props.options.map(([value, text]) => (
        <option selected={props.selected === value} value={value}>
          {text}
        </option>
      ))}
      </Select>*/}
    </InputRow>
  );
};

export const RadioInput = (props) => {
  const callAction = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };
  const renderIcon = () => {
    let opacity = 0;
    if (props.selectedOption == props.value) {
      opacity = 1;
    }
    return <RadioIcon disabled={props.disabled} opacity={opacity} />;
  };
  return (
    <CheckboxContainer mt={props.mt} mb={props.mb}>
      <InputRowCheckBox>
        <Col
          xs={props.colCheckBox}
          sm={props.colCheckBox}
          md={props.colCheckBox}
          lg={props.colCheckBox}
          xl={props.colCheckBox}
          style={{ padding: "0px" }}
        >
          <StyledRadio
            group={props.group}
            disabled={props.disabled}
            type="radio"
            checked={props.selectedOption === props.value}
          >
            <HiddenRadio
              value={props.value}
              disabled={props.disabled}
              onChange={(e) => callAction(e)}
              checked={props.selectedOption === props.value}
            />
            {renderIcon()}
          </StyledRadio>
        </Col>
        <Col
          xs={props.colLabel}
          sm={props.colLabel}
          md={props.colLabel}
          lg={props.colLabel}
          xl={props.colLabel}
          style={{ padding: "0px" }}
        >
          <LabelInput
            size={props.size}
            color={
              props.selectedOption !== props.value ? props.color : "darkGreen"
            }
            style={
              props.selectedOption === props.value ? { fontWeight: "bold" } : {}
            }
            noMarginBottom
          >
            {props.label}
          </LabelInput>
        </Col>
      </InputRowCheckBox>
    </CheckboxContainer>
  );
};

export const SearchBox = (props) => {
  return (
    <InputRow {...props.container}>
      <SearchInput {...props} onChange={props.onChange} />
    </InputRow>
  );
};

export const SearchSelect = (props) => {
  return (
    <InputRow {...props.container}>
      <Select
        isLoading={props.isLoadingAutocomplete}
        placeholder={props.placeholder ? props.placeholder : ""}
        loadOptions={props.promiseOptions}
        {...props}
        options={props.options}
        styles={{
          control: (base, state) => ({
            ...base,
            backgroundColor: `${theme.colors.white}`,
            borderRadius: "5px",
          }),
          input: (base, state) => ({
            ...base,
            width: props.width,
            height: props.height,
          }),
          container: (base, state) => ({
            ...base,
            width: props.width,
            backdropFilter: "blur(4px)",
            fontFamily: "Ubuntu",
            fontSize: "16px",
            zIndex: 1000,
          }),
          menu: (base, state) => ({
            ...base,
            backgroundColor: `${theme.colors.white}`,
          }),
          option: (base, state) => ({
            ...base,
            fontFamily: "Ubuntu",
            fontSize: "16px",
          }),
          menuList: (base, state) => ({
            ...base,
            backgroundColor: `${theme.colors.white}`,
          }),
        }}
        onBlur={(e) => e.preventDefault()}
      />
    </InputRow>
  );
};
