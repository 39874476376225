import React from 'react';

import './dashuidivisor.scss';

export const DashUIDivisor = ({data}) => {
    return (
        <div className="dashui-divisor">
            <div className={`bar ${data.customStyle.position}`}>
                <div className="label" style={{'--title': data?.customStyle?.color, "--bordersize": `${data?.customStyle?.bordersize}px`, "--bordercolor": `${data?.customStyle?.bordercolor}`, "--bordertype": `${data?.customStyle?.bordertype}`}}>
                    <div className="text">
                        {data?.title}
                    </div>
                </div>
            </div>
        </div>
    )
}