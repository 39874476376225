import styled from "styled-components";
import theme from "components/shared/Theme";

export const IconPcd = styled.img`
  width: 20px;
  height: 20px;
`;

export const RowActionsTable = styled.div`
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const RowHeaderTable = styled.div`
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoxInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const InfoVacancy = styled(BoxInfo)``;
export const CandidatesInfo = styled.div``;
export const VacancyInfo = styled.div``;

export const SpanDivisor = styled.span`
  font-family: Arial;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-top: -3px;
  color: #9d9d9d;
  margin-left: 19px;
  margin-right: 19px;
  opacity: 0.5;
`;

export const VacancySpan = styled.span`
  height: 27px;
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8bc63f;
  padding-left: 10px;
  padding-right: 10px;
  :hover {
    cursor: pointer;
  }
`;
export const VacancyOpensSpan = styled(VacancySpan)`
  color: ${theme.colors.softGray};
`;

export const ImagAvatar = styled.img`
  border-radius: 150px;
  width: 40px;
  border: 1px solid #fff;
  height: 40px;
  box-shadow: 1px 1px 3px #c3c3c3;
  transition: all 0.2s;
`;
export const ContantImg = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

export const SelectRow = styled.div`
  display: flex;
  width: 100%;
  margin: 1em 0em;
  flex-flow: row wrap;
`;

export const SelectsContainer = styled.div`
  margin: 0em 1em 0em 0em;
`;

export const ButtonContainer = styled.div`
  margin: 0em 1em 0.5em 0em;
  align-self: flex-end;
`;
