import React, { useRef, useEffect } from 'react';

import Peer from 'peerjs';


import '../signature.scss';

export const SignatureRemote = (props) => {

    const canvas = useRef();
    let context;
    let isDraw = false;

    const signature = `remote-${props.match.params.token}`;
    let peer = null;
    let conn = null;

    peer = new Peer(signature);

    peer.on('open', id => {
        console.log('peer-id-', id);
        console.log('peer-id', peer.id);

        join(props.match.params.token);
    });

    peer.on('connection', c => {
        if(conn){
            c.on('open', () => {
                c.send('Client Connect. Connection close.');
                c.close();
            });
            return;
        }

        conn = c;
        
        conn.on('data', data => {
            console.log('data received');
            console.log('Menssage:', data);
        });

        conn.on('close', ()=>{
            console.log('Connection reset');
            conn = null;
        });

    });

    peer.on('disconnected', () => {
        console.log('disconnected');
        peer.reconnect();
    });

    peer.on('close', () => {
        console.log('Connection close. Destroyed.');
    });

    peer.on('close', err => {
        console.log('Error', err);
    });

    const send = message =>{
        if(conn && conn.open){
            conn.send(message);
        }else{
            console.log('Connection is close');
        }
    }

    const end = () =>{
        conn.close();
    }

    const join = (token) => {
        if(conn){
            conn.close();
        }

        conn = peer.connect(token, {reliable: true});

        conn.on('open', () => {
            console.log('Connectec to:', peer.id);
        });

        conn.on('data', data => {
            console.log('Menssage:', data);
        });

        conn.on('close', ()=>{
            console.log('Connection closed');
        });
    }
    

    const sendSignature = () => {
        if(conn && conn.open){
            //conn.send('teste');
            const data = canvas.current.toDataURL();
            //console.log(data);

            const signature = {
                data: data,
                width: canvas.current.width, 
                height: canvas.current.height
            }
            conn.send(signature);

        }else{
            console.log('No connection');
        }
    }

    const setDraw = (e) => {
        isDraw = (/mousedown|touchstart/gi).test(e.type);
        if(isDraw){
            context = canvas.current.getContext('2d');
            const x  = Math.round((e.clientX || e.touches[0].clientX ) - canvas.current.getBoundingClientRect().left);
            const y  = Math.round((e.clientY  || e.touches[0].clientY) - canvas.current.getBoundingClientRect().top);
            context.moveTo(x, y);
            context.beginPath();
        }else{
            context.closePath();
        }
    }

    const clearCanvas = () => {
        context = canvas.current.getContext('2d');
        context.clearRect(0, 0, canvas.current.width, canvas.current.height);
    }   

    const draw = (e) => {
        const x  = Math.round((e.clientX || e.touches[0].clientX ) - canvas.current.getBoundingClientRect().left);
        const y  = Math.round((e.clientY  || e.touches[0].clientY) - canvas.current.getBoundingClientRect().top);
        if(isDraw){
            context = canvas.current.getContext('2d');
            context.lineTo(x, y);
            context.stroke();
        }

        //console.log(x, y);
        
    }

    useEffect(()=>{
        canvas.current.addEventListener('mousedown', setDraw);
        canvas.current.addEventListener('touchstart', setDraw);
        canvas.current.addEventListener('mouseup', setDraw);
        canvas.current.addEventListener('touchend', setDraw);
        canvas.current.addEventListener('mousemove', draw);
        canvas.current.addEventListener('touchmove', draw);

        const size = canvas.current.parentNode.getBoundingClientRect();
        canvas.current.width = size.width ;
        canvas.current.height = size.height;

        context = canvas.current.getContext('2d');
        context.lineWidth = 1;

    }, []);

    return (
        <div className="signature-area">
            assinatura 
            <button onClick={()=>{clearCanvas()}}>Limpar</button>
            <button onClick={()=>{sendSignature()}}>Salvar</button>
            <button onClick={()=>{join(props.match.params.token)}}>Conectar</button>
            <div className="area">
                <canvas ref={canvas}></canvas>
            </div>
        </div>
    )
}