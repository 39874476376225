import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  height: 100vh
  background-color: rgb(233,228,240);

  background: -moz-linear-gradient(90deg, rgba(233,228,240,1) 0%, rgba(212,212,212,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(233,228,240,1) 0%, rgba(212,212,212,1) 100%);
  background: linear-gradient(90deg, rgba(233,228,240,1) 0%, rgba(212,212,212,1) 100%);

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e9e4f0",endColorstr="#d4d4d4",GradientType=1);
`;
