import * as urls from "utils/contants/urls";
import { ItemMenu, LabelItem, MenuBody } from "./MenuStyle";
import React, { useState, useEffect } from "react";
import {
  managers,
  home
} from "assets/icons/icons";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { push } from "connected-react-router";

const Menu = ({
  goTo,
}) => {
  const [selected, setSelected] = useState({
    users: false,
    home: false,
    modules: false
  });

  useEffect(() => {
    updateRouter({
      users: true,
      home: false,
      modules: false
    })
  }, [])
  const updateRouter = newFocus => {
    setSelected({ ...newFocus });
    if (newFocus.home) goTo(urls.USERS);
    if (newFocus.users) goTo(urls.USERS);
    if (newFocus.modules) goTo(urls.APP);
  };

  return (
    <MenuBody>
      <ItemMenu
        selected={selected.users}
        onClick={() =>
          updateRouter({
            users: true,
            home: false,
          })
        }
      >
        {managers()}
        <LabelItem>
          <FormattedMessage id="menuManagerBar.users" />
        </LabelItem>
      </ItemMenu>

      <ItemMenu
        selected={selected.modules}
        onClick={() =>
          updateRouter({
            users: false,
            home: false,
            modules: true
          })
        }
      >
        {home()}
        <LabelItem>
          <FormattedMessage id="menuManagerBar.modules" />
        </LabelItem>
      </ItemMenu>
    </MenuBody>
  );
};

const MapStateToProps = state => ({});
const MapDispatchToProps = dispatch => ({
  goTo: route => dispatch(push(route)),
});

export default connect(MapStateToProps, MapDispatchToProps)(Menu);
