import { Body, LabelAnimation, LoadContainer } from "./LoadStyle";

import { LoadAnimation } from "../../../assets/icons/icons";
import PropTypes from "prop-types";
import React from "react";

export default function Load(props) {
  const { isLoading, insideComponent } = props;
  return (
    <Body isLoading={isLoading} insideComponent={insideComponent}>
      <LoadContainer>
        <LoadAnimation />
        <LabelAnimation>Aguarde...</LabelAnimation>
      </LoadContainer>
    </Body>
  );
}

Load.propTypes = {
  isLoading: PropTypes.bool,
  insideComponent: PropTypes.bool
};

Load.defaultProps = {
  isLoading: false,
  insideComponent: false
};
