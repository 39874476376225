import styled from 'styled-components';
import theme from '../../shared/Theme';

export const Body = styled.div`
  border-radius: 5px;
  border: solid 1px #cfcfcf;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-family: Arial;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.titleColor};
`;
