import styled from "styled-components";
import theme from "../../shared/Theme";

export const DisclaimerQuestion = styled.span`
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-left: ${props => props.ml};
  color: ${theme.colors.darkGray};
  margin-bottom: 30px;
  opacity: 0.9;
`;

export const ParagraphQuestion = styled.p`
  font-family: Arial;
  font-size: 14px;
  opacity: 0.9;
  color: ${theme.colors.darkGray};
`;

export const Info = styled(ParagraphQuestion)`
  color: ${theme.colors.softGray};
`;

export const OptionsRows = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
  align-items: center;
  margin-top: ${props => (props.mt ? props.mt : "20px")};
`;

export const ActionRows = styled.div`
  margin-top: 53px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  margin-bottom: 28px;
`;

export const BoxImages = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex;
  flex-wrap: wrap;
  flex-flow: column;
`;

export const BoxActionImage = styled.div``;

export const SelectedImage = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${theme.colors.defaultColor};
  border-radius: 23px;
  position: absolute;
  margin-top: -127px;
  margin-left: 44px;
  padding: 2px;
`;

export const RowImage = styled.div`
  display: flex;
  img {
    width: 50px;
    height: 50px;
    margin-right: 30px;
    :hover {
      cursor: pointer;
    }
  }
`;
