import * as urls from "utils/contants/urls";

import { YesOrNoDialog } from "components/shared/CustomComponents";
import {
  FilterRegisterLabel,
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
  ActionItem,
  Actions,
  LabelAction,

} from "components/shared/CustomComponentsStyle";


import {
  ButtonDefault,
  CheckBox,
  SelectFieldInput,
  ButtonSecondary
} from "components/shared/CustomInputs";

import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import React, { PureComponent } from "react";
import {
  cancel,
  edit,
  order as orderIcon,

} from "../../../../assets/icons/icons";


import { FormattedMessage } from "react-intl";

import Load from "components/Layout/Load/Load";

import PropTypes from "prop-types";
import Tab from "components/Tab";
import { ToolTip } from "components/shared/CustomComponents";
import { connect } from "react-redux";
import { Creators as managerActions } from "store/ducks/manager";
import { Input } from "../../../../components/shared/CustomInputsStyle";
import { FlexAction, SubmitRow } from "./ProfilesStyle";

const setProfileToUser = (props, idNewPerfil, userId) => {

  const { setProfilerToUser } = props;
  setProfilerToUser({ idNewPerfil, userId });
}


const setFormErros = idintervalo => {

}

const renderTip = status => {
  switch (status) {
    case 0:
      return (
        <ToolTip
          id="enabled"
          message={<FormattedMessage id="vacancyList.statusEnabledVacancy" />}
        />
      );
    case 1:
      return (
        <ToolTip
          id="awaiting"
          message={<FormattedMessage id="vacancyList.statuswaitingVacancy" />}
        />
      );
    case 2:
      return (
        <ToolTip
          id="canceled"
          message={<FormattedMessage id="vacancyList.statusCanceledVacancy" />}
        />
      );
    default:
  }
};
const selecionado = false;

const redirect = (url) => {
  window.location.href = url;
};



const renderTableUsers = (prop, itens, profilers, showDialogYesOrNo) => {
  
  const getProfileNameById = (profileId) => {
    const profile = profilers.find(profiler => profiler.id === profileId);
    return profile ? profile.nome : ''; 
  };

  return itens.map(user => (
    <TableRow ta="left" key={user.id}>
      <TableColumn>
        <Label>{user.id}</Label>
      </TableColumn>
      <TableColumn>
        <Label>{user.nome}</Label>
      </TableColumn>
      <TableColumn>
        <span>{getProfileNameById(user.perfil)}</span> 
      </TableColumn>
      <TableColumn>
        <CheckBox
          checked={user.podeAcessarPlug}
          onChange={() => enablePlug(prop, user.id, !user.podeAcessarPlug)}
          type="checkbox"
        />
      </TableColumn>
      {localStorage.getItem("tipoPerfilUsuario") == 1 && (
        <TableColumn>
          <CheckBox
            checked={user.podeAcessarTalentos}
            onChange={() => enableTalentos(prop, user.id, !user.podeAcessarTalentos)}
            type="checkbox"
          />
        </TableColumn>
      )}
      <TableColumn>
        <CheckBox
          checked={user.podeEditarPerfil}
          onChange={() => enableManager(prop, user.id, !user.podeEditarPerfil)}
          type="checkbox"
        />
      </TableColumn>
      <TableRow style={{ display: "Flex", justifyContent: "space-evenly" }}>
        <TableColumn style={{ padding: "15px" }}>
          <Actions>
            <ActionItem>
              <Link to={`${urls.USER_DETAIL}/${user.id}`}>
                {edit()}
                <LabelAction>
                  <FormattedMessage id="manager.edit" />
                </LabelAction>
              </Link>
            </ActionItem>
          </Actions>
        </TableColumn>
        <TableColumn style={{ padding: "15px" }}>
          <Actions>
            <ActionItem onClick={() => showDialogYesOrNo(true, user.id)}>
              {cancel()}
              <LabelAction>
                <FormattedMessage id="manager.deletar" />
              </LabelAction>
            </ActionItem>
          </Actions>
        </TableColumn>
      </TableRow>
    </TableRow>
  ));
};

  const renderTableProfiles = (props, itens) =>
  itens.map(profiler => (
    <TableRow ta="left" key={profiler.id} onClick={() => getProfilePermissions(props, profiler.id)}>


      <TableColumn onClick={() => getProfilePermissions(props, profiler.id)}>
        <Label>{profiler.id}</Label>
      </TableColumn>
      <TableColumn onClick={() => getProfilePermissions(props, profiler.id)}>
        <Label>{profiler.nome}</Label>
      </TableColumn>

    </TableRow>
  ));

const getProfilePermissions = (props, idprofile) => {
  const { getProfilePermissionsList } = props;
  getProfilePermissionsList(idprofile);
}

const enableProfilePermission = (props, id, enabled) => {
  const { setProfilePermission } = props;
  setProfilePermission({ id, enabled });
};

const enableStages = (props, id, enabled) => {
  const { setStageEnable } = props;
  setStageEnable({ id, enabled });
};
const enablePlug = (props, id, enabled) => {
  const { setPlugEnable } = props;
  setPlugEnable({ id, enabled });
};
const enableTalentos = (props, id, enabled) => {
  const { setTalentosEnable } = props;
  setTalentosEnable({ id, enabled });
};
const enableManager = (props, id, enabled) => {
  const { setManagerEnable } = props;
  setManagerEnable({ id, enabled });
};
const deletarUsuario = (props, id) => {
  const { deleteUser } = props;
  deleteUser(id, {});
};
const registerNewProfile = (props, name) => {
  const { createProfile } = props;
  createProfile({ name });
};



function renderTableProfilesPermissions(props, PermissionsProfile) {


  if (PermissionsProfile != undefined) {
    return PermissionsProfile.map(profilerPermission => (
      <TableRow ta="left" key={profilerPermission.id}>

        <TableColumn>

          <CheckBox
            type="checkbox"
            checked={profilerPermission.podeGerenciarLocal}
            onChange={() =>
              enableProfilePermission(props, profilerPermission.idPermissaoSite, !profilerPermission.podeGerenciarLocal)}
          />

        </TableColumn>
        <TableColumn>
          <Label>{profilerPermission.idPermissaoSite}</Label>
        </TableColumn>
        <TableColumn style={{ float: 'left', marginLeft: '80px' }}>
          <Label>{profilerPermission.nomeUnidade}</Label>
        </TableColumn>

      </TableRow>
    ));
  } else {
    return;
  }
};

function renderRowPermissionStage(props, idPermission, PodeEditarEtapas) {

  if (PodeEditarEtapas != undefined) {
    return <TableRow >
      <TableColumn >

          <CheckBox
            type="checkbox"
            checked={PodeEditarEtapas}
          onChange={() => enableStages(props, idPermission, !PodeEditarEtapas)}
          />

        </TableColumn>

      <TableColumn >
          <Label>Habilitar edição de etapas</Label>
        </TableColumn>
    </TableRow>;
  }
  return;
}

class ProfileList extends PureComponent {
  constructor() {
    super();
    this.state = {
      profiles: [],
      profilepermissionsSelected: {},
      users: [],
      typing: false,
      focus: 0,
      inputNewProfile: '',
      showDialogYesOrNo: false,
      idDeletar: 0
    }
    this.changeYesOrNoDialog = this.changeYesOrNoDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  requestVacanciesAndSetTab = filter => {
    if (filter.valor == 1) {
      this.setState({ focus: parseInt(filter.valor), filter: filter });
      const { getProfilesList } = this.props;
      getProfilesList();
    }
    else {
      this.setState({ focus: parseInt(filter.valor), filter: filter });
      const { getUsersList } = this.props;
      getUsersList();
    }
  };
  changeYesOrNoDialog(show, id) {
    this.setState({ showDialogYesOrNo: show, idDeletar: id });
  }
  componentDidMount() {
    const { getProfilesList } = this.props;
    getProfilesList();
    const { getUsersList } = this.props;
    getUsersList();
    var candidatoPerfil = false;
    candidatoPerfil = localStorage.getItem("tipoPerfilUsuario") == 1 ? true : false;
    this.setState({
      columns: [
        {
          name: <FormattedMessage id="manager.administradores" />,
          focus: this.state.focus === 0,
          action: this.requestVacanciesAndSetTab,
          filter: { campo: "status", valor: "0", NumeroPagina: 1 }
        },
        candidatoPerfil && {
          name: <FormattedMessage id="manager.perfis" />,
          focus: this.state.focus === 1,
          action: this.requestVacanciesAndSetTab,
          filter: { campo: "status", valor: "1", NumeroPagina: 1 },
        }
      ].filter(Boolean),
      profilepermissionsSelected:{
        permissaoUnidadeList: []
      }
    });

  }

  static getDerivedStateFromProps(nextProps, state) {
    const profiles = nextProps.profiles.profiles;

    if (nextProps.profiles.profiles != undefined) {
      const profiles = nextProps.profiles.profiles;

      if (state.profiles !== profiles) {
        return { ...state, profiles: profiles };
      }
    }
    if (nextProps.profiles.users != undefined && nextProps.profiles.isLoading == false) {
      const users = nextProps.profiles.users;
      if (state.users !== users) {

        return { ...state, users: users };

      }
    }

    if (nextProps.profiles.profilepermissionsSelected != undefined && nextProps.profiles.isLoading == false) {
      const profilepermissionsSelected = nextProps.profiles.profilepermissionsSelected;
      if (state.profilepermissionsSelected !== profilepermissionsSelected) {

        return { ...state, profilepermissionsSelected: profilepermissionsSelected };
      }

    } else
      return null;

  }

  renderIcon(key) {
    const { order } = this.state;
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` }
    });
  }

  handleChange(event) {
    this.setState({ inputNewProfile: event.target.value });
  }

  render() {


    const { profiles } = this.props;
    if (profiles.isLoading) return <Load isLoading={profiles.isLoading} />;



    if (this.state.focus == 0) {

      return (

        <React.Fragment>
          <Tab columns={this.state.columns}>
            <React.Fragment>
              <SubmitRow className="row">
                <Col lg={3} style={{ padding: "5px" }}>
                  <FlexAction>
                    <Link
                      style={{
                        width: "100%",
                      }}
                      to={`${urls.USER_NEW}`}
                    >
                      <ButtonSecondary
                        name={
                          <FormattedMessage id="manager.createUser" />}
                      />
                    </Link>
                  </FlexAction>
                </Col>
              </SubmitRow>
            </React.Fragment>
            <Table >
              <TableBody>
                <TableRow noHover noBorder>
                  <TableHead>
                    <LabelHeader>
                      <FormattedMessage id="manager.id" />
                    </LabelHeader>
                  </TableHead>
                  <TableHead>
                    <LabelHeader>
                      <FormattedMessage id="manager.nomeAdmin" />
                    </LabelHeader>
                  </TableHead>
                  <TableHead >
                    <LabelHeader>
                      <FormattedMessage id="manager.Perfil" />
                    </LabelHeader>
                  </TableHead>
                  <TableHead >
                    <LabelHeader>
                      <FormattedMessage id="manager.acessoPlug" />
                    </LabelHeader>
                  </TableHead>
                  {localStorage.getItem("tipoPerfilUsuario") == 1 &&(
                    <TableHead >
                      <LabelHeader>
                        <FormattedMessage id="manager.acessoTalentos" />
                      </LabelHeader>
                    </TableHead>
                  )}
                  <TableHead >
                    <LabelHeader>
                      <FormattedMessage id="manager.administrator" />
                    </LabelHeader>
                  </TableHead>
                  <TableHead>
                    <LabelHeader>
                      <FormattedMessage id="manager.actions" />
                    </LabelHeader>
                  </TableHead>
                </TableRow>
                {renderTableUsers(this.props, this.state.users, this.state.profiles, this.changeYesOrNoDialog)}
              </TableBody>
              <YesOrNoDialog
                show={this.state.showDialogYesOrNo}
                cancelAction={() => {
                  this.setState({ showDialogYesOrNo: false });
                  this.setState({ idDeletar: 0 })
                }}
                confirmAction={() => {
                  {
                    deletarUsuario(this.props, this.state.idDeletar);
                    this.setState({ showDialogYesOrNo: false });
                  }
                }

                }
                question={<FormattedMessage id="manager.realmenteDesejaDeletar" />}
              />
            </Table>
          </Tab>

        </React.Fragment>

      );
    }
    else {
      return (
        <React.Fragment>

          <Tab columns={this.state.columns}>
            <div className="row" style={{ alignItems: 'normal' }}>
              <div className="col-md-6">


                <FilterRegisterLabel fz="14px"><FormattedMessage id="manager.cadastroperfil" /></FilterRegisterLabel>
                <Table>
                  <TableBody>
                    <TableRow noHover noBorder>
                    </TableRow>
                    <TableRow >

                      <TableHead  >
                        <LabelHeader>
                          <FormattedMessage id="manager.id" />
                        </LabelHeader>

                      </TableHead>

                      <TableHead  >
                        <LabelHeader >
                          <FormattedMessage id="manager.nomeprofile" />
                        </LabelHeader>
                      </TableHead>
                    </TableRow>
                    {renderTableProfiles(this.props, this.state.profiles)}

                    <TableRow ta="left"  >
                      <TableColumn >
                        <ButtonDefault

                          name={<FormattedMessage id="manager.btnincluirperfil" />}
                          width="120px"
                          onClick={() => registerNewProfile(this.props, this.state.inputNewProfile)}
                          disabled={!this.state.inputNewProfile}
                        />


                      </TableColumn>
                      <TableColumn >
                        <Input type="text" value={this.state.inputNewProfile} onChange={this.handleChange} />

                      </TableColumn>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className="col-md-6" style={this.state.profilepermissionsSelected.id !== undefined ? {} : { display: 'none' }}>

                <FilterRegisterLabel fz="14px"><FormattedMessage id="manager.permissoesperfil" />{this.state.profilepermissionsSelected.nome}</FilterRegisterLabel>

                <Table style={{ marginBottom: '34px' }} >
                  <TableBody>
                    {renderRowPermissionStage(this.props, this.state.profilepermissionsSelected.id, this.state.profilepermissionsSelected.podeEditarEtapas)}
                  </TableBody>
                </Table>
                <FilterRegisterLabel fz="14px"><FormattedMessage id="manager.unidades" /></FilterRegisterLabel>
                <Table >
                  <TableBody>
                    <TableRow noHover noBorder>
                      <TableHead>
                        <LabelHeader>
                          <FormattedMessage id="manager.autorizado" />
                        </LabelHeader>
                      </TableHead>
                      <TableHead>
                        <LabelHeader>
                          <FormattedMessage id="manager.id" />
                        </LabelHeader>
                      </TableHead>
                      <TableHead >
                        <LabelHeader>
                          <FormattedMessage id="manager.unidade" />
                        </LabelHeader>
                      </TableHead>

                    </TableRow>
                    {renderTableProfilesPermissions(this.props, this.state.profilepermissionsSelected.permissaoUnidadeList)}

                  </TableBody>
                </Table>
              </div>
            </div>
          </Tab>

        </React.Fragment>
      );
    }
  }
}


const mapDispatchToProps = dispatch => ({
  getProfilesList: payload => dispatch(managerActions.profilesRequest(payload)),
  getUsersList: payload => dispatch(managerActions.profilesUsersRequest(payload)),
  setProfilerToUser: (idPerfil, IdUser, payload) => dispatch(managerActions.profilesUserUpdateRequest(idPerfil, IdUser, payload)),
  getProfilePermissionsList: (id, payload) => dispatch(managerActions.profilesPermissionsRequest(id, payload)),
  setProfilePermission: (id, enabled, payload) => dispatch(managerActions.profilesEditPermissionsRequest(id, enabled, payload)),
  createProfile: (nome, payload) => dispatch(managerActions.profilesCreateRequest(nome, payload)),
  setStageEnable: (id, enabled, payload) => dispatch(managerActions.profilesEnableStageRequest(id, enabled, payload)),
  setPlugEnable: (id, enabled, payload) => dispatch(managerActions.profilesEnablePlugRequest(id, enabled, payload)),
  setTalentosEnable: (id, enabled, payload) => dispatch(managerActions.profilesEnableTalentosRequest(id, enabled, payload)),
  deleteUser: (id) => dispatch(managerActions.userDeleteRequest(id)),
  setManagerEnable: (id, enabled, payload) => dispatch(managerActions.profilesEnableManagerRequest(id, enabled, payload)),
});
const mapStateToProps = state => ({
  profiles: state.profiles,
  users: state.users
});

ProfileList.propTypes = {
  profiles: PropTypes.object,
  users: PropTypes.object,
  getProfilesList: PropTypes.func,
  setProfilerToUser: PropTypes.func,
  getProfilePermissionsList: PropTypes.func,
  createProfile: PropTypes.func,
  setProfilePermission: PropTypes.func,
  setStageEnable: PropTypes.func
};

ProfileList.defaultProps = {
  profiles: {},
  users: {},
  getProfilesList: () => { },
  getUsersList: () => { },
  setProfilerToUser: () => { },
  getProfilePermissionsList: () => { },
  setProfilePermission: () => { },
  createProfile: () => { },
  setStageEnable: () => { }


};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileList);
