import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ButtonSecondary, ButtonDefault } from "components/shared/CustomInputs";
import { LabelH1 } from "components/shared/CustomComponents";
import PropTypes from "prop-types";
import {
  DisclaimerQuestion,
  Image,
  BoxImages,
  BoxActionImage,
  SelectedImage,
  Circle
} from "./DialogBannerStyle";

export default function DialogBanner({
  show,
  action,
  closeDialog,
  openPreview,
  banners
}) {
  const [selectedImage, setSelectImage] = useState();
  return (
    <Modal size="lg" show={show}>
      <Modal.Header>
        <LabelH1 ml="15px" fz="24px">
          <FormattedMessage id="sharedItems.publishVacancyBanner" />
        </LabelH1>
      </Modal.Header>
      <Modal.Body>
        <DisclaimerQuestion>
          <FormattedMessage id="sharedItems.selectImage" />
        </DisclaimerQuestion>
        <BoxImages>
          {banners &&
            banners.map((item, index) => {
              return (
                <BoxActionImage onClick={() => setSelectImage(item)}>
                  <Image src={item.url} />
                  <SelectedImage>
                    <Circle selected={selectedImage === item} />
                  </SelectedImage>
                </BoxActionImage>
              );
            })}
        </BoxImages>
      </Modal.Body>
      <Modal.Footer>
        <ButtonSecondary
          name={<FormattedMessage id="sharedItems.cancel" />}
          onClick={() => closeDialog()}
          width="156px"
        />
        <ButtonDefault
          name={<FormattedMessage id="sharedItems.generatePreview" />}
          onClick={() => openPreview(selectedImage)}
          disabled={selectedImage === undefined}
          width="250px"
        />
      </Modal.Footer>
    </Modal>
  );
}
DialogBanner.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func,
  closeDialog: PropTypes.func
};

DialogBanner.defaultProps = {
  show: false,
  action: () => {},
  closeDialog: () => {}
};
