import { all } from "redux-saga/effects";
import * as AuthSaga from "./authSaga";
import * as JobsSaga from "./jobsSaga";
import * as VacancySaga from "./vacancySaga";
import * as StageSaga from "./stageSaga";
import * as StagePositionSaga from "./stagePositionSaga";
import * as FormInputsSaga from "./formInputsSaga";
import * as QuestionSaga from "./questionSaga";
import * as RailsSaga from "./railsSaga";
import * as AssociationSaga from "./vacancyAssociationSaga";
import * as CandidatesSaga from "./candidatesSaga";
import * as MessagesSaga from "./messageSaga";
import * as ManagerSaga from "./managerSaga";
import * as DocumentsSaga from "./DocumentsSaga";
import * as EmployeeSaga from "./employeeSaga";
import * as ContractSaga from "./contractsSaga";
import * as CourseAndFormationSaga from "./courseAndFormationSaga";
import * as BankOfTalentsSaga from "./bankOfTalentsSaga";
import * as TypeNeighborhoodSaga from "./typeNeighborhoodSaga";
import * as TypeStreet from "./typeStreetSaga";
import * as assinaturaAdministrativoSaga from "./assinaturaAdministrativoSaga"
import * as AlertSaga from "./alertSaga";
import * as ContractDocumentsSaga from "./contractDocumentsSaga";
import * as TestSaga from "./testSaga";
import * as DoubtsSaga from "./DoubtsSaga";
import * as EnviarDocumentsSaga from "./EnviarDocumentsSaga";
import * as userBenefitsSaga from "./userBenefitsSaga";
import * as otherPaymentsSaga from "./otherPaymentsSaga";

function* Sagas() {
  yield all([
    AuthSaga.watcherSaga(),
    JobsSaga.watcherSaga(),
    VacancySaga.watcherSaga(),
    StageSaga.watcherSaga(),
    StagePositionSaga.watcherSaga(),
    FormInputsSaga.watcherSaga(),
    QuestionSaga.watcherSaga(),
    RailsSaga.watcherSaga(),
    AssociationSaga.watcherSaga(),
    CandidatesSaga.watcherSaga(),
    MessagesSaga.watcherSaga(),
    ManagerSaga.watcherSaga(),
    DocumentsSaga.watcherSaga(),
    EmployeeSaga.watcherSaga(),
    ContractSaga.watcherSaga(),
    BankOfTalentsSaga.watcherSaga(),
    CourseAndFormationSaga.watcherSaga(),
    TypeNeighborhoodSaga.watcherSaga(),
    TypeStreet.watcherSaga(),
    assinaturaAdministrativoSaga.watcherSaga(),
    AlertSaga.watchSaga(),
    ContractDocumentsSaga.watcherSaga(),
    TestSaga.watchSaga(),
    DoubtsSaga.watcherSaga(),
    EnviarDocumentsSaga.watcherSaga(),
    userBenefitsSaga.watcherSaga(),
    otherPaymentsSaga.watcherSaga()
  ]);
}

export default Sagas;
