import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  associationRequest: ['idVacancy', 'idRail', 'stages', 'isLoading'],
  associationSuccess: ['rail', 'vacancy', 'stages', 'isLoading'],
  associationFail: ['isLoading', 'error', 'isLoading'],

  setIsAssociatingRequest: ['isAssociating'],
  setIsAssociatingSuccess: ['isAssociating', 'isEditing'],
  setIsAssociatingFail: ['error']

});
const INITIAL_STATE = {
  idVacancy: '',
  idRail: '',
  isLoading: true,
  isAssociating: false,
  stages: undefined,
  isEditing: false,
};

const request = () => ({ ...INITIAL_STATE, isLoading: true });
const success = (state, payload) => ({ ...state, ...payload, isLoading: false });
const fail = (state, error) => ({ ...state, isLoading: false, ...error });

const setIsAssociatingRequest = (state) => ({ ...state })
const setIsAssociatingSuccess = (state, value) => ({ ...state, ...value, isEditing: false })
const setIsAssociatingFail = (state) => ({ ...state })

export default createReducer(INITIAL_STATE, {
  [Types.ASSOCIATION_REQUEST]: request,
  [Types.ASSOCIATION_SUCCESS]: success,
  [Types.ASSOCIATION_FAIL]: fail,

  [Types.SET_IS_ASSOCIATING_REQUEST]: setIsAssociatingRequest,
  [Types.SET_IS_ASSOCIATING_SUCCESS]: setIsAssociatingSuccess,
  [Types.SET_IS_ASSOCIATING_FAIL]: setIsAssociatingFail,
});
