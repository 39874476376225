import React, { useEffect } from "react";

import NewStage from "components/NewStage";
import PropTypes from "prop-types";
import { STAGES } from "utils/contants/urls"
import { connect } from "react-redux";
import { push } from "connected-react-router"
import { Creators as questions } from "store/ducks/question";
import { Creators as stagePosition } from "store/ducks/stagePositions";
import { Creators as stages } from "store/ducks/stages";

const StageCreation = props => {
  const { requestStagesPositions, stage, match, requestShowStage } = props;

  useEffect(() => {
    const { params } = match;
    if (params.id) {
      requestShowStage(params.id);
    }
    requestStagesPositions({ page: 1 });
  }, []);
  return <NewStage {...props} />;
};

const mapDispatchToProps = dispatch => ({
  requestStagesPositions: (filters) => dispatch(stagePosition.stagePositionRequest(filters)),
  registerStagesPositions: roles =>
    dispatch(stagePosition.stagePositionRegisterRequest(roles)),
  requestCreateStage: payload => dispatch(stages.stageCreateRequest(payload)),
  requestCreateQuestion: payload =>
    dispatch(questions.questionCreateRequest(payload)),
  requestUpdateQuestion: payload =>
    dispatch(questions.questionTestUpdateRequest(payload)),
  requestDeleteQuestion: payload =>
    dispatch(questions.questionTestDeleteRequest(payload)),
  updateStage: payload => dispatch(stages.stageUpdateRequest(payload)),
  requestShowStage: id => dispatch(stages.stageShowRequest(id)),
  goBackPreviousPage: () => dispatch(push(STAGES)),
  requestDeleteQuestionBlock: (payload) => dispatch(stages.stageDeleteQuestionBlockRequest(payload)),
  publishStage: id => dispatch(stages.stagePublishRequest(id)),
});
const mapStateToProps = state => ({
  positions: state.stagesPosition,
  stageId: state.stages.stageId,
  stage: state.stages.stage,
  location: state.router.location,
  pagination: state.stagesPosition.pagination,
});
StageCreation.propTypes = {
  requestStagesPositions: PropTypes.func
};
StageCreation.defaultProps = {
  requestStagesPositions: () => {}
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StageCreation);
