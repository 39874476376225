import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  questionCreateRequest: ['payload'],
  questionCreateSuccess: ['stages', 'isLoading'],
  questionCreateFail: ['isLoading', 'error'],

  questionTestCreateRequest: ['payload', 'testeId'],
  questionTestCreateSuccess: ['isLoading'],
  questionTestCreateFail: ['isLoading', 'error'],

  questionTestUpdateRequest: ['payload', 'questionId'],
  questionTestUpdateSuccess: ['isLoading'],
  questionTestUpdateFail: ['isLoading', 'error'],

  questionTestDeleteRequest: ['id', 'stageId'],
  questionTestDeleteRequestSuccess: ['id'],
  questionTestDeleteRequestFail: ['id'],
});

const INITIAL_STATE = {
  question: { id: '', text: '' },
  isLoading: false,
};

const request = state => ({ ...INITIAL_STATE, isLoading: true });
const success = (state, stages) => ({ ...state, ...stages, isLoading: false });
const fail = (state, error) => ({ ...state, isLoading: false, error });

const requestQuestionTest = state => ({ ...INITIAL_STATE, isLoading: true });
const successQuestionTest = (state, questions) => ({ ...state, ...questions, isLoading: false });
const failQuestionTest = (state, error) => ({ ...state, isLoading: false, error });

const questionTestUpdateRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const questionTestUpdateSuccess = (state, questions) => ({
  ...state,
  ...questions,
  isLoading: false,
});
const questionTestUpdateFail = (state, error) => ({ ...state, isLoading: false, error });

const questionTestDeleteRequest = state => ({ ...state, isLoading: true });
const questionTestDeleteRequestSuccess = (state) => ({ ...state, isLoading: false });
const questionTestDeleteRequestFail = (state, error) => ({ ...state, isLoading: false, error });

export default createReducer(INITIAL_STATE, {
  [Types.QUESTION_CREATE_REQUEST]: request,
  [Types.QUESTION_CREATE_SUCCESS]: success,
  [Types.QUESTION_CREATE_FAIL]: fail,

  [Types.QUESTION_TEST_CREATE_REQUEST]: requestQuestionTest,
  [Types.QUESTION_TEST_CREATE_SUCCESS]: successQuestionTest,
  [Types.QUESTION_TEST_CREATE_FAIL]: failQuestionTest,

  [Types.QUESTION_TEST_UPDATE_REQUEST]: questionTestUpdateRequest,
  [Types.QUESTION_TEST_UPDATE_SUCCESS]: questionTestUpdateSuccess,
  [Types.QUESTION_TEST_UPDATE_FAIL]: questionTestUpdateFail,

  [Types.QUESTION_TEST_DELETE_REQUEST]: questionTestDeleteRequest,
  [Types.QUESTION_TEST_DELETE_REQUEST_SUCCESS]: questionTestDeleteRequestSuccess,
  [Types.QUESTION_TEST_DELETE_REQUEST_FAIL]: questionTestDeleteRequestFail,
});
