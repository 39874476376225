export const APP = "/Modulos";
export const TALENTS_AEC = "/talentos";
export const RIGHT_CHOICE = "/escolhacerta";
export const TRAINNER_AEC = "/treinamento";
export const ONBOARD_AEC = "/onboard";
export const PLUG_AEC = "/plug";
export const MANAGER_AEC = "/configuracoes";
export const NEW_PASSWORD = "/NewPassword";

export const DASHBOARDTALENTS = "/DashBoardTalents";
export const DASHBOARDPLUG = "/DashBoardPlug";
export const DASHBOARDMANAGER = "/DashBoardManager";


export const VACANCY_NEW = "/talentos/vagas/nova";
export const VACANCY = "/talentos/vagas";
export const VACANCY_SHOW = "/talentos/vagas/show/";
export const VACANCY_EDITAR = "/talentos/vagas/editar/";

export const STAGES = "/talentos/etapas";
export const STAGE_REGISTRATION_DATA_NEW = "/talentos/etapas/dados-cadastrais";
export const STAGE_REGISTRATION_DATA_SHOW =
  "/talentos/etapas/dados-cadastrais";
export const STAGE_POSITIONS = "/talentos/etapas/cargos/";

export const STAGES_NEW_TEST = "/talentos/etapas/testes";
export const STAGES_TESTS = "/talentos/etapas/teste";

export const STAGES_TRAINNER = "/talentos/etapas/treinamento";
export const STAGES_NEW_TRAINNER = "/talentos/etapas/treinamento";

export const STAGES_ASSAY = "/talentos/etapas/analise";
export const STAGES_NEW_ASSAY = "/talentos/etapas/analise";

export const STAGES_MEDIC_EXAME = "/talentos/etapas/exame-medico";
export const STAGES_NEW_MEDIC_EXAME = "/talentos/etapas/exame-medico";

export const STAGES_PRE_ADMITION = "/talentos/etapas/pre-admissao";

export const STAGES_ADMITION = "/talentos/etapas/admissao";
export const STAGES_NEW_ADMITION = "/talentos/etapas/admissao";

export const STAGES_FEEDBACK = "/talentos/etapas/feedback";
export const STAGES_NEW_FEEDBACK = "/talentos/etapas/feedback";

export const STAGES_CONTRACT = "/talentos/etapas/contrato";
export const STAGES_NEW_CONTRACT = "/talentos/etapas/contrato";

export const STAGES_AMBIENTATION = "/talentos/etapas/ambientacao";
export const STAGES_NEW_AMBIENTATION = "/talentos/etapas/ambientacao";

export const RAILS = "/talentos/trilhas";
export const RAILS_SHOW = "/talentos/trilhas/editar";
export const RAILS_NEW = "/talentos/trilhas/nova";
export const RAILS_DETAILS_ASSOCIATION = "/talentos/vagas/trilha/detalhes";
export const RAILS_CREATE_ASSOCIATION = "/talentos/vagas/trilha/associar";

export const VACANCY_CANDIDATES = "/talentos/vagas/candidatos";
export const CANDIDATES_FROM_VACANCY = "/talentos/vagas/candidatos";
export const SHOW_CANDIDATE = "/talentos/candidato";
export const APPROVED_CANDIDATES = "/talentos/candidato/checkout/aprovacao";
export const DECLINED_CANDIDATES = "/talentos/candidato/checkout/reprovacao";
export const BANKOFTALENTS_CANDIDATES = "/talentos/candidato/checkout/bancodetalentos";
export const PREVIEW = "/talentos/vagas/preview";

export const MANAGERS = "/talentos/administradores";
export const MANAGERS_PROFILES = "/talentos/administradores/perfis";
export const MANAGERS_PROFILESEDITION = "/talentos/administradores/edicaoperfis";


export const BANKOFTALENTS = "/talentos/bancotalentos";
export const BANKOFTALENTS_EDIT = "/talentos/bancotalentos/editar";

export const PREVIEW_PAGE = "https://portaldocandidatohml.vinci-energies.com.br/vagas/";
///PLUG/////
export const DOCUMENTS_CANDIDATES = "/plug/documents/ListDocumentsCandidates";
export const DOCUMENTS_LIST = "/plug/documents/ListDocuments";
export const DOUBTS_LIST = "/plug/doubts/ListDoubtsCandidates";
export const UPLOAD_DOCUMENTS = "/plug/EnviarDocuments/uploadDocuments";
export const DOUBTS_CANDIDATES = "/plug/doubts/ListDoubts";
export const UPLOAD_DOCUMENTS_SIGNATURE = "/plug/EnviarDocuments/ploadDocumentsSignature";


//export const DOCUMENTS_CANDIDATES_MANAGER = "/plug/documents/ListDocumentsCandidatesManager";
//export const DOCUMENTS_LIST_MANAGER = "/plug/documents/ListDocumentsManager";
export const DOUBTS_LIST_MANAGER = "/plug/doubtsManager/ListDoubtsCandidatesManager";
//export const UPLOAD_DOCUMENTS_MANAGER = "/plug/EnviarDocuments/uploadDocumentsManager";
//export const DOUBTS_CANDIDATES_MANAGER = "/plug/doubtsManager/ListDoubtsManager";

export const PLUG_CANDIDATES_LIST = "/plug/candidates/ListCandidates";
export const PLUG_NEW_CANDIDATE = "/plug/candidates/NewCandidate";
export const PLUG_EDIT_CANDIDATE = "/plug/candidates/EditCandidate";

export const CONTRACTS_LIST = "/plug/contracts/ListContractsCandidates";
export const CONTRACT_CANDIDATE = "/plug/contracts/DetailContractCandidate";
export const CONTRACT_MANAGER = "/plug/contracts/ManagerCandidatesContract";

export const PAYMENTS_LIST = "/plug/Payments/ListPaymentsCandidates";
export const PAYMENTS_MANEGER = "/plug/Payments/ManagerPaymentContract";
export const PAYMENTS_CANDIDATE = "/plug/Payments/DetailPaymentCandidate";
//MANAGER
export const USERS = "/configuracoes/usuarios";
export const USER_NEW = "/configuracoes/usuarios/novo";
export const USER_DETAIL = "/configuracoes/usuario";


//ASSINATURA_PENDENTE
export const PLUG_ASSINATURA_CANDIDATES_LIST = "/plug/AssinaturaPendente/ListAssinaturaPendente";
export const PLUG_DIGITAL_SIGNATURE_DOCUMENTS = "/plug/AssinaturaPendente/DigitalSignatureDocuments";


//UPLOAD
export const ENVIAR_DOCUMENTS_CANDIDATES = "/plug/EnviarDocuments/EnviarDocumentsCandidates";