import * as routes from "utils/contants/urls";

import { Creators as messageAction } from "store/ducks/messages";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import { Creators as bankOfTalentsActions } from "store/ducks/bankOfTalents";

import {
  ButtonSecondary,
  CheckBox,
  MultSelectCreatable, MultSelect, SelectFieldInput, TextField 
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import { Filter, Collapse } from "components/shared/CustomComponents";
import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow

} from "components/shared/CustomComponentsStyle";

import CandidatesInBankOfTalentsEdit from "../EditCandiatesInBankOfTalents/CandidatesInBankOfTalentsEdit";


import { TipoVagaSelecao } from "utils/contants/enums";
import InputMask from "react-input-mask";

import React, { useEffect, useState, useRef } from "react";
import {
  StatusVacancy,
  candidatesActions,
  candidatesApproved,
  candidatesRefused,
  kindStages,
  pendingCandidateAction,
  renderNumberOfSelected,
  schedulingCandidateAction
} from "locale";
import {  view } from "assets/icons/icons";

import { Body } from "./CandidatesInBankOfTalentsListStyle";
import { Link } from "react-router-dom";

import BreadCrumb from "components/Layout/breadcrumb";
import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import Pagination from "components/Pagination";
import PdcIcon from "assets/icons/iconpcd.svg";
import PropTypes from "prop-types";
import Tab from "components/Tab";

import { connect } from "react-redux";
import { getStageStatusName } from "helpers/sharedHelpers";


const RowColorStage = (status) => {

  let color;
  switch (status) {
    case "Em análise":
      color = "#f44336";
      break;
    case "Aprovado":
      color = "rgb(95 227 100)";
      break;
    case "Desistente":
      color = "#86868666";
      break;
    case "Aguardando admissão":
      color = "#723172";
      break;
    default:
      color = "#ffeb3b";
      break;
  }


  return ({
    height: '18px',
    width: '18px',
    borderRadius: '50%',
    float: 'left',
    display: 'inline-block', backgroundColor: color
  });


}

const redirect = (url) => {
  window.location.href = url;
}

const renderTableItems = (candidates, situacao, startModal) =>
  candidates.map(candidate => (
    <Link onClick={()=> startModal(candidate.idCandidatoNaVaga, candidate.cpf, candidate.idVaga, candidate.nome)}>
      <TableRow>
        <TableColumn ta="left" >
          <span class="dot" style={RowColorStage(candidate.statusCandidatoNaEtapa)}></span>
          <Label >{candidate.cargo}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.site}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{candidate.tipoSelecao}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{candidate.idVaga}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.nomeResponsavel}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{candidate.dataInclusao}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{candidate.diasNoBanco}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{candidate.dataVencimento}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{candidate.cpf}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.nome}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{candidate.chapa}</Label>
        </TableColumn>
        <TableColumn ta="left" >
          <Label>{candidate.justificativaBancoTalentos}</Label>
        </TableColumn>
        <TableColumn ta="left" style={situacao === 1 ?{display: "none" }:{}}>
          <Label>{candidate.robbysonStatus}</Label>
        </TableColumn>

        <TableColumn ta="center" style={situacao === 0 ?{display: "none" }:{}}>
          <Label>{candidate.motivoRemocaoBanco || ''}</Label>
        </TableColumn>
        <TableColumn ta="center" style={situacao === 0 ?{display: "none" }:{}}>
          <Label>{candidate.dataRemocaoBancoTalentos || ''}</Label>
        </TableColumn>

        <TableColumn ta="right" action>
          <Actions flexPosition="flex-start">
            <ActionItem onClick={()=> startModal(candidate.idCandidatoNaVaga)}>
              {view()}
              <LabelAction>
                <FormattedMessage id="sharedItems.viewEdit" />
              </LabelAction>
            </ActionItem>
          </Actions>
        </TableColumn>
      </TableRow>
    </Link>
  ));



const renderCandidatesTable = (candidatesList, situacao,startModal) => {
  return (
    <Table>
      <TableBody>
        <TableRow noBorder>
          <TableHead ta="left">
            <LabelHeader>
            CARGO DESEJADO
            </LabelHeader>

          </TableHead>
          <TableHead ta="left">
            <LabelHeader>
            SITE
            </LabelHeader>
          </TableHead>

          <TableHead ta="center">
            <LabelHeader>
            TIPO
            </LabelHeader>

          </TableHead>

          <TableHead ta="center">
            <LabelHeader>
            ID VAGA
            </LabelHeader>

          </TableHead>

          <TableHead ta="left">
            <LabelHeader>
            RESPONSÁVEL
            </LabelHeader>
          </TableHead>
          <TableHead ta="center">
            <LabelHeader>
            DATA INCLUSÃO
            </LabelHeader>

          </TableHead>
          <TableHead ta="center">
            <LabelHeader>
            DIAS NO BANCO
            </LabelHeader>
          </TableHead>

          <TableHead ta="center">
            <LabelHeader>
            VENCIMENTO
            </LabelHeader>
          </TableHead>


          <TableHead ta="center">
            <LabelHeader>
            CPF
            </LabelHeader>
          </TableHead>


          <TableHead ta="left">
            <LabelHeader>
            NOME
            </LabelHeader>
          </TableHead>

          <TableHead ta="center">
            <LabelHeader>
            CHAPA
            </LabelHeader>
          </TableHead>


          <TableHead ta="left">
            <LabelHeader>
            MOTIVO NÃO PROM/CONTR
            </LabelHeader>
          </TableHead>


          <TableHead ta="left" style={situacao === 1 ?{display: "none" }:{}}>
            <LabelHeader>
            ROBBYSON

            </LabelHeader>
          </TableHead>

          <TableHead ta="left" style={situacao === 0 ?{display: "none" }:{}}>
            <LabelHeader>
            MOTIVO REMOÇÃO DO BANCO

            </LabelHeader>
          </TableHead>

          <TableHead ta="left" style={situacao === 0 ?{display: "none" }:{}}>
            <LabelHeader>
            DATA REMOÇÃO DO BANCO

            </LabelHeader>
          </TableHead>

        </TableRow>
        {renderTableItems(candidatesList.candidatos, situacao,startModal)}
      </TableBody>
    </Table>

  )
};


const renderCandidatesByGroupTable = candidates =>
  candidates.candidatosPorVaga.map(candidate => (
    <Collapse
      typeStatus={0}
      position={0}
      title={"Vaga " + candidate.idVaga + " - " + candidate.nomeVaga}
    >
      <Table>

        <TableBody>
          <TableRow noBorder>
            <TableHead>
              <LabelHeader>
                <FormattedMessage id="documentsCandidateList.cpf" />
              </LabelHeader>

            </TableHead>
            <TableHead ta="left">
              <LabelHeader>
                <FormattedMessage id="documentsCandidateList.nomeCandidato" />
              </LabelHeader>

            </TableHead>
            <TableHead ta="left">
              <LabelHeader>
                <FormattedMessage id="documentsCandidateList.documentosEnviados" />
              </LabelHeader>

            </TableHead>
            <TableHead ta="left">
              <LabelHeader>
                <FormattedMessage id="documentsCandidateList.status" />
              </LabelHeader>

            </TableHead>
            <TableHead ta="left">
              <LabelHeader ta="center">
                <FormattedMessage id="documentsCandidateList.analisar" />
              </LabelHeader>

            </TableHead>

          </TableRow>
          {renderTableItems(candidate.candidatesByVacancyList)}
        </TableBody>
      </Table>
    </Collapse>

  ));


  const saveEditCandidateBankOfTalent = infoAboutCandidateBank => {
  
  };

export const CandidatesInBankOfTalentsList = ({
  isLoading,
  candidates,
  requestInputValues,
  getCandidates,
  placesOfWork,
  eligibleJobs,
  administrator,
  jobs,
  location,
  messagePublication,
  hasMore,
  setAction

  
}) => {
  const [agruparPorVaga, setAgruparPorVaga] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [formValues, setFormValues] = useState({ cpfs: [], idVagas: [], idLocations: [], idAdminsList:[], situacao:0, chapa:'',tipoSelecao:1 });
  const [erroMessage, setErroMessage] = useState({ message: "" });
  const [showModal, setshowModal] = useState(false);
  const [tabSelect, setTabSelected] = useState(0);
  const [idCandVacancySelected, setidCandVacancySelected] = useState("");
  const [candSelected, setCandSelected] = useState({idVaga:"", cpf:"", nome:""});

  const filterTab = useRef([]);

  const loadMore = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, false);
  };

  const AplicaFiltro = (form, firstPage,tabSelect) => {
   if(tabSelect != undefined)
   {
        localStorage.setItem("filter_ListBankOfTalentsCandidates", JSON.stringify(form));
        getCandidates({chapa:form.chapa,tipoSelecaoVaga:form.tipoSelecao, cpfList: form.cpfs, idVagasList: form.idVagas, idLocaisList: form.idLocations, cargoList: form.idCargoList, dataInclusaoBanco: form.dataInclusaoBanco,idAdminsList: form.idAdminsList, firstPage: firstPage, situacao:tabSelect });
        setFormValues(form);
   }

  };
  useEffect(() => {
    
    //filterTab.current[tabSelect] = JSON.stringify(formValues);
    
    requestInputValues(["placeOfWork", "eligibleJobs", "administrator"]);
  }, []);

  const doAction = (option,justificativa) => {
    setAction({ idCandidatoVaga: idCandVacancySelected, acaoAlteracaoStatus: option, justificativa: justificativa});
    AplicaFiltro(formValues, true,tabSelect);

  };

  const startModal = (idcandidatevacancy, cpf, idVaga,nome) => {
    setidCandVacancySelected(idcandidatevacancy);
    setshowModal(true);
    setCandSelected({cpf:cpf,idVaga: idVaga, nome:nome})
  };
  
  
  const Filtrar = (situacao) => {

    setClearFilter(false);
    AplicaFiltro(formValues, true,situacao);
  }
  const getLocationValues = () => {

    if (formValues.idLocations) {
      return placesOfWork.filter(function (item) {
        return formValues.idLocations.indexOf(item.id) !== -1;
      }).map(e => ({
        label: e.nome,
        value: e.id
      }));
    }

    return [];
  }
  const getCargoValues = () => {

    if (formValues.idCargoList) {


      return eligibleJobs.filter(function (item) {
        return formValues.idCargoList.indexOf(item.id) !== -1;
      }).map(e => ({
        label: e.funcao,
        value: e.id
      }));
    }

    return [];
  }

  const getAdminValues = () => {

    if (formValues.idAdminsList) 
    {
      
      return administrator.filter(function (item) {
        return formValues.idAdminsList.indexOf(item.id) !== -1;
      }).map(e => ({
        label: e.nome,
        value: e.id
      }));
      };
    

    return [];
  }

  const LimparFiltro = () => {
    setClearFilter(true);
    setFormValues({});
    AplicaFiltro({}, true);
  }

  useEffect(() => {

    //LimparFiltro(true);
    //console.log('#', filterTab.current);
    //let filter_storaged = JSON.parse(localStorage.getItem("filter_ListBankOfTalentsCandidates"));

    let filter_storaged = filterTab.current[tabSelect] ? JSON.parse(filterTab.current[tabSelect]) : null;

    if (filter_storaged != null) {
      filter_storaged.quantidadeItens = candidates.candidatos ? candidates.candidatos.length : 20;
      // localStorage.setItem("filter_ListBankOfTalentsCandidates", JSON.stringify(filter_storaged));
      setFormValues(filter_storaged);

    } else {
      setFormValues({ cpfs: [], idVagasList: [], idVagas: [], idLocaisList: [],idAdminsList:[], cargoList: [], dataInclusaoBanco: null, chapa:'' , tipoSelecao:1});
    }

  }, [candidates]);
  const TrataMultiInsert = Insert => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];
    Insert.map(item => {
      let conc = item.value.split(/[\s,]+/);
      conc.map(filtItem => {
        if (filtItem.length == 11) {
          result.push(filtItem);
        } else {
          messages.push({ message: 'cpf ' + filtItem + ' com menos de 11 digitos.Não será considerado no filtro.', type: "warning" });

        }
      });
    });
    messagePublication(messages);
    return result;
  };

    if (isLoading) return <Load isLoading={isLoading} />;
    return (
      <React.Fragment>
        <BreadCrumb
          location={location}
          title={"Banco de talentos"}
        />
        <Body>


          <Row style={{ marginBottom: "10px", display:"flex", justifyContent:"flex-end" }}>
            <Col md={2} >
              <MultSelectCreatable
                key={5}
                name={<FormattedMessage id="documentsCandidateList.filterCpf" />}
                label={<FormattedMessage id="documentsCandidateList.filterCpf" />}
                isMulti
                options={formValues.cpfs}
                value={
                  formValues.cpfs ?
                    formValues.cpfs.map(e => ({
                      label: e,
                      value: e
                    })) : []
                }
                onChange={e =>
                  setFormValues({
                    ...formValues,
                    cpfs: e
                      ? TrataMultiInsert(e)
                      : []
                  })
                }
              />
            </Col>
            <Col md={2} >

            <TextField
                value={formValues.chapa}
                name={"Chapa"}
                onChange={e =>
                  setFormValues({ ...formValues, chapa: e.target.value })
                }
                label ="Chapa"
                />

            </Col>
  
            <Col md={2}>
                <SelectFieldInput style = {{color:"hsl(0,0%,50%)"}}
              items={[
                { name: "Interno", id: 0 },
                { name: "Externo", id: 1 }
              ]}
              label={"Tipo seleção"}
              defaultValue={formValues.tipoSelecao}
              onChange={e =>
                setFormValues({ ...formValues, tipoSelecao: e.target.value })
              }
            />
                              
              
              </Col>      
            <Col md={2} >
              <MultSelectCreatable
  
                name={<FormattedMessage id="documentsCandidateList.filterIdVaga" />}
                label={<FormattedMessage id="documentsCandidateList.filterIdVaga" />}
                isMulti
                options={formValues.idVagas ?
                  formValues.idVagas.map(e => ({
                    label: e,
                    value: e
                  })) : []}
                value={
                  formValues.idVagas ?
                    formValues.idVagas.map(e => ({
                      label: e,
                      value: e
                    })) : []
                }
                onChange={e =>
                  setFormValues({
                    ...formValues,
                    idVagas: e
                      ? e.map(k => {
                        return k.value;
                      })
                      : []
                  })
                }
              />
            </Col>
            <Col md={2} >
              <MultSelect
  
                name={<FormattedMessage id="documentsCandidateList.filterLocation" />}
  
                options={placesOfWork.map(e => ({
                  label: e.nome,
                  value: e.id
                }))}
                label={<FormattedMessage id="documentsCandidateList.filterLocation" />}
                isMulti
                value={getLocationValues()}
                onChange={e =>
                  setFormValues({
                    ...formValues,
                    idLocations: e
                      ? e.map(k => {
                        return k.value;
                      })
                      : []
                  })
                }
              />
  
            </Col>
  
            <Col md={2} >
              <InputMask  
                mask="99/99/9999"
                width="170px"
                defaultValue={formValues.dataInclusaoBanco}
                value={formValues.dataInclusaoBanco}
                label ="Data inicio atividade"
                onChange={e =>
                  e.target.value ?
                    setFormValues({
                      ...formValues,
                      dataInclusaoBanco: e.target.value
                    }) : null
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                  />
                )}
              </InputMask>
            </Col>
  
            <Col md={2} >
              <MultSelect
  
                name={<FormattedMessage id="bankOfTalents.job" />}
                options={eligibleJobs.map(e => ({
                  label: e.funcao,
                  value: e.id
                }))}
                label={<FormattedMessage id="bankOfTalents.job" />}
                isMulti
                value={getCargoValues()}
                onChange={e =>
                  setFormValues({
                    ...formValues,
                    idCargoList: e
                      ? e.map(k => {
                        return k.value;
                      })
                      : []
                  })
                }
              />
  
            </Col>
            <Col md={2} >
              <MultSelect
  
                name={<FormattedMessage id="bankOfTalents.admin" />}
                options={administrator.map(e => ({
                  label: e.nome,
                  value: e.id
                }))}
                label={<FormattedMessage id="bankOfTalents.admin" />}
                isMulti
                value={getAdminValues()}
                onChange={e =>
                  setFormValues({
                    ...formValues,
                    idAdminsList: e
                      ? e.map(k => {
                        return k.value;
                      })
                      : []
                  })
                }
              />
  
            </Col>
            <Col style={{display:"flex", justifyContent:"flex-end", marginRight:"inherit"}}>
            <Col md={3} style={{ marginTop: '15px' }}>
              <ButtonSecondary
                onClick={() => Filtrar(tabSelect)}
                name={(<FormattedMessage id="sharedItems.search" />)}
  
              />
            </Col>
            </Col>
            
          </Row>
          <Tab
        registerTableSelect={e => setTabSelected(e)}
        columns={[
          {
            name: "Ativos",
            position: 0,
            focus: tabSelect === 0,
            filter: { campo: "status", valor: "0", NumeroPagina: 1 },
            action: () => { filterTab.current[tabSelect] = JSON.stringify(formValues); setTabSelected(0); Filtrar(0); LimparFiltro(true); } 

            },  

          {
            name:  "Removidos",
            focus: tabSelect === 1,
            action: () => { filterTab.current[tabSelect] = JSON.stringify(formValues); setTabSelected(1); Filtrar(1); LimparFiltro(true);}

            ,  
            position: 1,
            filter: { campo: "status", valor: "4", NumeroPagina: 1 }
          }
          
        ]}
      >
         {isLoading ? (<Load isLoading={isLoading} />  ) 
         : ((candidates.candidatos &&  renderCandidatesTable(candidates,tabSelect,startModal))) }

  
  
     { /*    <Pagination
          getItemsPage={e => requestFilterItems({ page: e.NumeroPagina })}
          {...pagination}
     />*/}
      </Tab>
      <CandidatesInBankOfTalentsEdit show={showModal} situacao={tabSelect}  doAction={doAction} closeDialog={()=>{ setshowModal(false) }}  dadosCandidato={candSelected} />
        </Body>
      </React.Fragment>
    );
};
const mapDispatchToProps = dispatch => ({
  getCandidates: payload =>    dispatch(bankOfTalentsActions.candidatesBankOfTalentsRequest(payload)),
  requestInputValues: (inputs) => dispatch(formInputsActions.formInputsRequest(inputs)),
  messagePublication: messages => dispatch(messageAction.messagePublication(messages)),
  setAction: payload =>    dispatch(bankOfTalentsActions.changeStatusCandidate(payload)),
});
const mapStateToProps = state => ({
  placesOfWork: state.formInputs.placeOfWork,
  isLoading: state.bankOfTalents.isLoading,
  eligibleJobs: state.formInputs.eligibleJobs,
  administrator: state.formInputs.administrator,
  jobs: state.formInputs.eligibleJobs,
  candidates: state.bankOfTalents.candidates,
  pagination: state.bankOfTalents.pagination,
  location: state.router.location
});
export default connect(mapStateToProps, mapDispatchToProps)(CandidatesInBankOfTalentsList);

CandidatesInBankOfTalentsList.defaultProps = {
  getCandidates: PropTypes.func,
  isLoading: PropTypes.bool,
  candidates: PropTypes.arrayOf({}),
  vacancy: PropTypes.arrayOf({}),
  match: PropTypes.objectOf({}),
  requestManagerCandidates: PropTypes.func,
  rail: []
};

CandidatesInBankOfTalentsList.defaultProps = {
  getCandidates: () => {},
  requestManagerCandidates: () => {},
  isLoading: false,
  candidates: [],
  vacancy: [],
  match: {}
};
