import styled from 'styled-components';

export const BoxActions = styled.div`
  display: flex;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }
`;
export const IconAction = styled.div`
  margin-left: 20px;
`;

export const FlexButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
