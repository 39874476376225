import {
  ButtonDefault,
  ButtonSecondary,
  CheckBox,
  SelectFieldInput,
  TextField,
} from "components/shared/CustomInputs";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "components/Layout/breadcrumb";
import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import { LabelH1 } from "components/shared/CustomComponents";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Creators as vacancyCandidatesActions } from "store/ducks/candidates";
import Load from "components/Layout/Load";
import {
  dateToArray,
  buildDateStringWithHour,
  buildDate,
  buildDateString
} from "../../../helpers/sharedHelpers";
import { kindStages } from "../../../locale";
import { order } from "../../../assets/icons/icons";
import { Body, RowActions } from "./CheckoutItemsStyle";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

const ics = require("ics");

export function CheckoutItemsCalendar({
  getVacanciesFromCandidates,
  candidatesSelecteds,
  kindOfAction,
  vacancyId,
  vacancy,
  placesOfWork,
  requestSaveSchedule,
  stageId,
  stageType,
  goBack,
  messagePublication,
  isLoading,
  vacancyObj,
}) {
  const [showDialogConfirm, setshowDialogConfirm] = useState(false);
  const [checkedSameValues, setCheckedSameValues] = useState(false);

  const [contextCandidates, setContextCandidates] = useState([]);
  const [valuesToIcs, setValuesToIcs] = useState({});

  const MEDIC_EXAM = kindStages[4].id;

  const registerValuesToICS = (position, value, key) => {
    const dupValues = [...valuesToIcs];
    dupValues[position][key] = value;
    setValuesToIcs(dupValues);

    const dupValuesCandidates = [...contextCandidates];
    dupValuesCandidates[position][key] = value;
    setContextCandidates(dupValuesCandidates);
  };

  const setSameValueToAll = () => {
    if (valuesToIcs.length) {
      const firstEvent = valuesToIcs[0];
      const setedValues = valuesToIcs.map((currentItem) => ({
        ...currentItem,
        hourStart: firstEvent.hourStart,
        hourEnd: firstEvent.hourEnd,
        placeOfInterview: firstEvent.placeOfInterview,
        date: firstEvent.date,
        note: firstEvent.note,
      }));
      setContextCandidates(setedValues);
      setValuesToIcs(setedValues);
      setCheckedSameValues(!checkedSameValues);
    }
  };

  const buildIcsFile = () => {
    const parsedEvents = valuesToIcs.map((event) => ({
      title: `Entrevista ${event.name}`,
      start: dateToArray(event.date, event.hourStart, event.hourEnd),
      duration: { hours: 1, minutes: 30 },
    }));
    const { error, value } = ics.createEvents(parsedEvents);
    const blob = new Blob([value], { type: "text/plan" });
    const csvURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "agenda.ics");
    tempLink.click();
  };

  const validateSchedules = (schedules) => {
    try {
      const stage = vacancyObj.associetedRail.stages.filter(
        (x) => x.kindStage == stageType
      )[0];
      const errors = [];
      const currentDateTime = new Date();

      schedules.forEach((schedule) => {
        const scheduleStart = new Date(
          buildDateStringWithHour(schedule.date, schedule.hourStart)
        );
        const scheduleEnd = new Date(
          buildDateStringWithHour(schedule.date, schedule.hourEnd)
        );

        const scheduleDate = buildDate(schedule.date);

        const selectedStageDateStart = buildDate(stage.periodeRealization);
        const selectedStageDateEnd = buildDate(stage.testPeriodeRealizationUntil);

        const dateIsValid = moment(scheduleDate).isValid();

        if (!dateIsValid)
          errors.push(`${schedule.name}: data é inválida`);

        if (!schedule.hourStart || schedule.hourStart === "" || (!moment(scheduleStart).isValid() && dateIsValid))
          errors.push(`${schedule.name}: horário inicial é inválida`);

        if (!schedule.hourEnd || schedule.hourEnd === "" || (!moment(scheduleEnd).isValid() && dateIsValid))
          errors.push(`${schedule.name}: horário final é inválido`);

        if (scheduleStart < currentDateTime)
          errors.push(`${schedule.name}: horário de início não pode ser anterior a data e hora atuais`);

        if (scheduleStart > scheduleEnd)
          errors.push(`${schedule.name}: horário de início não pode ser posterior ao de fim`);

        const dataAgendamentoMenorQueDataRealizacaoDaEtapa =
          scheduleDate < selectedStageDateStart;
        const dataAgendamentoMAiorQueDataRealizacaoDaEtapa =
          scheduleDate > selectedStageDateEnd;
        if (
          dataAgendamentoMenorQueDataRealizacaoDaEtapa ||
          dataAgendamentoMAiorQueDataRealizacaoDaEtapa
        )
          errors.push(
            `${schedule.name}: horário agendado deve estar dentro do periodo de realização da etapa: ${buildDateString(selectedStageDateStart)} - ${buildDateString(selectedStageDateEnd)}`
          );
      });

      const filtered = errors.filter((e, index) => {
        return errors.indexOf(e) === index;
      });

      return filtered.map((e) => ({
        type: "warning",
        message: e,
      }));
    } catch (error) {
      return [{ type: "warning", message: "faixa de horário inválida" }];
    }
  };

  const handleSaveSchedule = () => {
    const errors = validateSchedules(valuesToIcs);
    if (errors.length) {
      const messages = errors;
      messagePublication(messages);
    } else {
      requestSaveSchedule(valuesToIcs, vacancyId, kindOfAction, stageId);
    }
  };

  useEffect(() => {
    if (candidatesSelecteds.length) {
      setContextCandidates(candidatesSelecteds);
      const calendarObj = candidatesSelecteds.map((e) => ({
        name: e.name,
        id: e.id,
        hourStart: e.hourStart,
        hourEnd: e.hourEnd,
        date: e.date,
        placeOfInterview: e.placeOfInterview,
        note: e.note,
      }));
      setValuesToIcs([...calendarObj]);
    } else {
      return <Redirect to="home" />;
    }
  }, [candidatesSelecteds]);

  const renderTableItems = (candidatesValues) =>
    candidatesValues.map((candidate, index) => (
      <TableRow key={candidate.id}>
        <TableColumn ta="left">
          <Label>{candidate.name}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <InputMask
            onChange={(e) => registerValuesToICS(index, e.target.value, "date")}
            width="140px"
            value={candidate.date}
            alwaysShowMask
            mask="99/99/9999"
          >
            {(inputProps) => <TextField {...inputProps} />}
          </InputMask>
        </TableColumn>
        <TableColumn ta="left">
          <InputMask
            onChange={(e) =>
              registerValuesToICS(index, e.target.value, "hourStart")
            }
            value={candidate.hourStart}
            width="140px"
            alwaysShowMask
            mask="99:99"
          >
            {(inputProps) => <TextField {...inputProps} />}
          </InputMask>
        </TableColumn>
        <TableColumn ta="left">
          <InputMask
            onChange={(e) =>
              registerValuesToICS(index, e.target.value, "hourEnd")
            }
            value={candidate.hourEnd}
            width="140px"
            alwaysShowMask
            mask="99:99"
          >
            {(inputProps) => <TextField {...inputProps} />}
          </InputMask>
        </TableColumn>
        <TableColumn ta="left">
          <SelectFieldInput
            onChange={(e) =>
              registerValuesToICS(index, e.target.value, "placeOfInterview")
            }
            value={candidate.placeOfInterview}
            items={placesOfWork}
            width="100%"
          />
        </TableColumn>
        <TableColumn ta="left">
          <TextField
            onChange={(e) => registerValuesToICS(index, e.target.value, "note")}
            value={candidate.note}
            width="100%"
          />
        </TableColumn>
      </TableRow>
    ));
  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb />
      <RowActions>
        <LabelH1>
          <FormattedMessage
            id={
              stageType == MEDIC_EXAM
                ? "sharedItems.medicExame"
                : "sharedItems.interviewCandidates"
            }
          />
        </LabelH1>
        <div>
          <ButtonSecondary
            onClick={() => {
              setshowDialogConfirm(!showDialogConfirm);
              goBack();
            }}
            width="133px"
            mr="18px"
            name={<FormattedMessage id="sharedItems.cancel" />}
          />
          <ButtonSecondary
            onClick={() => buildIcsFile()}
            width="133px"
            mr="18px"
            name={<FormattedMessage id="sharedItems.icsFile" />}
          />
          <ButtonDefault
            onClick={() => handleSaveSchedule()}
            name={<FormattedMessage id="sharedItems.scheduleCandidates" />}
            width="223px"
          />
        </div>
      </RowActions>
      <Body>
        <RowActions>
          <CheckBox
            onChange={() => setSameValueToAll()}
            checked={checkedSameValues}
            label={<FormattedMessage id="sharedItems.applyToAllSameTime" />}
          />
        </RowActions>
        <Table>
          <TableBody>
            <TableRow noHover noBorder>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.name" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="inputs.date" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="inputs.startHour" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="inputs.endHour" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage
                    id={
                      stageType == MEDIC_EXAM
                        ? "inputs.placeOfExam"
                        : "inputs.placeOfInterview"
                    }
                  />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="inputs.note" />
                </LabelHeader>
                {order()}
              </TableHead>
            </TableRow>
            {renderTableItems(contextCandidates)}
          </TableBody>
        </Table>
      </Body>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getVacanciesFromCandidates: (payload) =>
    dispatch(vacancyCandidatesActions.candidatesFromRequest(payload)),
});

const mapStateToProps = (state) => ({
  vacancy: state.candidates.vacancy,
  state,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutItemsCalendar);

CheckoutItemsCalendar.defaultProps = {
  getVacanciesFromCandidates: PropTypes.func,
  candidates: PropTypes.arrayOf({}),
  vacancy: PropTypes.arrayOf({}),
};

CheckoutItemsCalendar.defaultProps = {
  getVacanciesFromCandidates: () => {},
  candidates: [],
  vacancy: [],
};
