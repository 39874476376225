import { CardBody, ContractBody } from "./styles";
//import Header from "../../../../../components/Layout/HeaderCandidato/Header";
import { Creators as testActions } from "../../../../../store/ducks/test";
import {
  ButtonDefault,
  ButtonSecondary,
  Title,
  ButtonRed,
} from "../../../../../components/shared/CustomComponentsCandidato/CustomComponents";
import { Col, Container, Modal, Row, ListGroup } from "react-bootstrap";
import React, { useRef, useEffect, useState } from "react";
import theme from "../../../../../components/shared/Theme";
import { FormattedMessage } from "react-intl";
import { TextAreaField } from "../../../../../components/shared/CustomInputsCandidato/CustomInputs";
import { connect } from "react-redux";
import { Creators as alertAction } from "../../../../../store/ducks/alert";
import { Creators as documentsActions } from "../../../../../store/ducks/contractDocuments";
import { Creators as assinaturaAdministrativoActions } from "../../../../../store/ducks/assinaturaAdministrativo";
import { Creators as signInUser } from "../../../../../store/ducks/auth";

import isAuthenticated from "../../../../../utils/isAuthenticated";

import { HASH_KEY } from "../../../../../utils/contants/Keys";
import * as jwt from "jsonwebtoken";
import { Signature } from "../../../../../components/Signature/Signature";
import { SMBForm } from "./SMBForm";
import Load from "../../../../../components/Layout/Load";
import axios from "axios";
import { id } from "@hapi/joi/lib/base";
import * as urls from "../../../../../utils/contants/urls";

const DigitalSignatureDocuments = ({
  user,
  auth,
  stage,
  vacancy,
  isLoading,
  getContractDocument,
  dependents,
  healthAndDentalPlanValues,
  document,
  finishStageDigitalDocumentAction,
  contractDocumentUpload,
  hasError,
  sendAlert,
  match,
  dataSubscribers,
  getAssinaturaAdministrativo,
  getAssinaturaAdministrativoSuccess,
  state,
  redirectAssinaturaAdministrativo
}) => {
  const [mobile, setMobileView] = useState(false);
  const updateResize = () => {
    if (window.innerWidth < 640) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    const { params } = match;
    if (params.id) {

    getAssinaturaAdministrativo(params.id)
    }
  }, []);

    const healdGetContractDocument = () =>{
   
      getContractDocument({
        idVaga:dataSubscribers.vagaId, 
        idEtapa:dataSubscribers.canidatosEmEtapaId, 
        idCandidato:dataSubscribers.candidatoId
      });
    }

  const finishAddDivergencia = () => {
    setShowDialog(false);
    finishStage(dataSubscribers.vagaId, dataSubscribers.candidatoId, formDivergenciaValues.divergencia, true);
  };
  const [formValues, setFormValues] = useState({
    watchedVideo: stage && stage.assistiuVideoOrientacao ? stage.assistiuVideoOrientacao : false,
    divergencia: "",
  });
    const [formDivergenciaValues, setFormDivergenciaValues] = useState({
    divergencia: "",
  });
  
  const [step, setStep] = useState("step1");
  /*const dependentes = [
    {
      "id": 0,
      "nome": "ADENALDO CARLOS DE ALMEIDA",
      "aderencia": 0
    },
    {
      "id": 1,
      "nome": "MARIA EDILENE DA SILVA",
      "aderencia": 0
    }
  ]*/

  const [formSMBValues, setFormSMBValues] = useState({
    aderenciaTitularPlanoSaude: null,
    aderenciaTitularPlanoOdontologico: null,
    dataCasamento: null,
    aderenciaBeneficiariosPlanoSaude: [],
    aderenciaBeneficiariosPlanoOdontologico: [],
  });

  useEffect(() => {
    window.addEventListener("resize", updateResize);
    updateResize();
    //getDependentData();
    //getHealthAndDentalPlanValues({ idVaga: vacancy.id });
  }, []);

  const [lastValidTime, setLastValidTime] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (player != null && !formValues.watchedVideo) {
        if (parseInt(player.getCurrentTime()) > lastValidTime + 2) {
          player.seekTo(lastValidTime);
        }
        setLastValidTime(parseInt(player.getCurrentTime()));
      }
    }, 100);

    return () => clearInterval(intervalId);
  });

  const uploadDoc = (e, idDocument) => {
    contractDocumentUpload({
      file: e,
      idDocument: idDocument,
      stageId: dataSubscribers.candidatoId,
    }); // enviar as demais informações
    e = null;
  };
  const [contractDocumentoUploaded, setContractDocumentoUploaded] =
    useState(false);
  const [player, setPlayer] = useState(null);

  const [documentList, setdocumentList] = useState([]);

  const [dependentsList, setDependentsList] = useState([]);
  const [dataSubscribersSelect, setDataSubscribersSelect] = useState()

  const onReady = (event) => {
    setPlayer(event.target);
  };

  const downloadPdfEvent = () => {
    getContractDocument();
//    setIsDownloadPdf(true);
    deleteContractDocumentoUploaded();
  };

  useEffect(() => {
    if (document != null) {
      setdocumentList(document);
    }
  }, [document]);

  useEffect(() => {
    if (dependents != []) {
      setDependentsList(dependents.dependentes);
      setFormSMBValues((prevFormSMBValues) => {
        return {
          ...prevFormSMBValues,
          aderenciaBeneficiariosPlanoSaude: dependents.dependentes.map(
            (dependente) => ({ ...dependente, aderencia: null })
          ),
          aderenciaBeneficiariosPlanoOdontologico: dependents.dependentes.map(
            (dependente) => ({
              ...dependente,
              aderencia: null,
              nomeDaMae: null,
            })
          ),
        };
      });
    }
  }, [dependents]);

  useEffect(() => {
    const { params } = match;
    if (params.id) {

      getAssinaturaAdministrativoSuccess(params.id)
      setDataSubscribersSelect(dataSubscribers)
    }
  }, []);

 
  useEffect(() => {

    if(dataSubscribers !== null  &&  dataSubscribers !== undefined && Object.keys(dataSubscribers).length > 0){

      getContractDocument({
        idVaga:dataSubscribers.vagaId, 
        idEtapa:dataSubscribers.etapaId, 
        idCandidato:dataSubscribers.candidatoId
      }); // alterar para executar qnd passar a pagina
    }
  }, [dataSubscribers])
  
  useEffect(() => {
    if (hasError) deleteContractDocumentoUploaded();
  }, [hasError]);

  const deleteContractDocumentoUploaded = () => {
    setContractDocumentoUploaded(false);
    //SetNameUploadedDocumento('');
  };
  
  const renderContracts = (itens) => {
    return (
      <>
        {itens.map((contract, id) => {
          return (
            <Row
              key={`contract-ref-${id}`}
              className="contract-row"
              style={{ margin: "10px 10px 75px 10px", border: "1px solid #dfdfdf" }}
            >
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <ContractBody>
                  <body
                    dangerouslySetInnerHTML={{
                      __html: contract.modeloContratoTermo
                        ? contract.modeloContratoTermo
                        : "",
                    }}
                  ></body>
                </ContractBody>
              </Col>
            </Row>
          );
        })}
      </>
    );
  };

  const sigCanvas = useRef(null);

  const updateLocalStorage = () => {
    const vacancyStageLocalStage = localStorage.getItem("vacancyStage");
    const dataJwt = jwt.verify(vacancyStageLocalStage, HASH_KEY);
    dataJwt.selectedStage.assistiuVideoOrientacao = true;
    const hashJwt = jwt.sign(dataJwt, HASH_KEY);
    localStorage.setItem("vacancyStage", hashJwt);
  };

  const [ipCandidato, setIpCandidato] = useState();

  const getIPClient = async () => {
    let res = await axios.get("https://geolocation-db.com/json/");
    setIpCandidato(res.data.IPv4);
      };

  useEffect(() => {
    getIPClient();
  }, []);

  const finishStage = (
    idVacancy,
    idStage,
    divergenciaForm,
    possuiDivergencia
  ) => {
    let file;

    if (!documentList.exigeAssinatura || possuiDivergencia) {
      finishStageDigitalDocumentAction({
        idVacancy: idVacancy,
        idStage: dataSubscribers.etapaId,
        id: document.id,
        divergencia: divergenciaForm,
        possuiDivergencia: possuiDivergencia,
        assinatura: "",
        ipCandidato: ipCandidato,
        candidatoId:dataSubscribers.candidatoId
      });
    } else if (sigCanvas.current.isEmpty()) {
      return;
    } else {
      file = sigCanvas.current.getImage();
      finishStageDigitalDocumentAction({
        idVacancy: idVacancy,
        idStage: dataSubscribers.etapaId,
        id: document.id,
        divergencia: divergenciaForm,
        possuiDivergencia: possuiDivergencia,
        assinatura: file,
        aderenciaSMB: formSMBValues,
        ipCandidato: ipCandidato,
        candidatoId:dataSubscribers.candidatoId
      });
    }
  };

  const skipStep1 = () => {
    setStep("step2");

    const dataFormSmb = {
      dataCasamento: new Date(),
      aderenciaTitularPlanoSaude: 0,
      aderenciaTitularPlanoOdontologico: 0,
      aderenciaBeneficiariosPlanoSaude: [],
      aderenciaBeneficiariosPlanoOdontologico: [],
    };

    setFormSMBValues(dataFormSmb);

    getContractDocument({
          idVaga:dataSubscribers.vagaId, 
          idEtapa:dataSubscribers.etapaId, 
          idCandidato:dataSubscribers.candidatoId,
      aderenciaSMB: {
        ...dataFormSmb
      }
    });
  };

  const addDivergencia = () => {
    setShowDialog(true);
  };

  const renderStep1 = () => {
    let widthYt = mobile ? window.innerWidth - 90 : 640;
    const opts = {
      height: "390",
      width: widthYt,
    };

    return (
      <React.Fragment>
        <ListGroup.Item>
      
          {renderSMBForm()}
        </ListGroup.Item>
      </React.Fragment>
    );
  };

  const renderStep2 = () => {
    let widthYt = mobile ? window.innerWidth - 90 : 640;
    const opts = {
      height: "390",
      width: widthYt,
    };

    return (
      <React.Fragment>
        <ListGroup.Item>
          <CardBody style={{ marginTop: "10px", marginBottom: "20px" }}>
            <h4>
              <FormattedMessage id="myProfile.digitalSignature.step2" />
            </h4>
          </CardBody>
          <CardBody style={{ marginTop: "10px", marginBottom: "20px" }}>
            <h6>
              {" "}
              <FormattedMessage id="myProfile.digitalSignature.descriptionStep2" />
            </h6>
          </CardBody>
          <CardBody style={{ marginTop: "10px", marginBottom: "20px" }}>
            {documentList &&
              documentList.contratoTermoAssinadoList &&
              renderContracts(documentList.contratoTermoAssinadoList)}
          </CardBody>
        </ListGroup.Item>

        <ListGroup.Item>{renderStep3()}</ListGroup.Item>
      </React.Fragment>
    );
  };

  const renderStep3 = () => {
    return (
      <React.Fragment>
        <CardBody style={{ marginTop: "10px", marginBottom: "20px" }}>
          {/* documentList && documentList.exigeAssinatura ? ( <h4>Passo 3</h4> ):( <h4>Passo 2</h4> ) */}
          <h4>
            <FormattedMessage id="myProfile.digitalSignature.step3" />
          </h4>
        </CardBody>
        <CardBody style={{ marginTop: "10px", marginBottom: "20px" }}>
          <h6>
            {" "}
            <FormattedMessage id="myProfile.digitalSignature.descriptionStep3" />
          </h6>
          {/* <SignatureCanvas  penColor='black'   ref={sigCanvas}  canvasProps={{width: 350, height: 200, className:'sigPad' }} /> */}
          <Signature ref={sigCanvas} />
        </CardBody>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <ButtonDefault
              isLoading={isLoading}
              mt={"30px"}
              onClick={() => finishStage(dataSubscribers.vagaId, dataSubscribers.candidatoId)}
              name={"Concordar e Assinar"}
            />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <ButtonRed
              isLoading={isLoading}
              mt={"30px"}
              onClick={() => addDivergencia()}
              name={"Relatar divergências no contrato."}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const renderSMBForm = () => {
    return (
      <SMBForm
        isLoading={isLoading}
        localTrabalho={healthAndDentalPlanValues.localDeTrabalho}
        dependentsList={dependentsList}
        formSMBValues={formSMBValues}
        setFormSMBValues={setFormSMBValues}
       // getContractDocument={healdGetContractDocument}
        idVaga={dataSubscribers.vagaId}
        idEtapa={dataSubscribers.canidatosEmEtapaId}
        setStep={setStep}
        healthAndDentalPlanValues={healthAndDentalPlanValues}
        sendAlert={sendAlert}
      />
    );
  };

  const [showDialog, setShowDialog] = useState(false);
  const renderItensUpload = () => {
    return (
      <ListGroup>
        {step === "step1" && healthAndDentalPlanValues.cargoNaoElegivel != undefined && !healthAndDentalPlanValues.cargoNaoElegivel && renderStep1()}

        {step === "step1" && healthAndDentalPlanValues.cargoNaoElegivel && skipStep1()}

        {documentList && documentList.exigeAssinatura && renderStep2()}

        <DialogInsertDivergencia
          setFormValues={setFormDivergenciaValues}
          formValues={formDivergenciaValues}
          show={showDialog}
          setShowDialog={setShowDialog}
          addItem={finishAddDivergencia}
        />
      </ListGroup>
    );
  };

  if (isLoading)
    return (
      <CardBody>
        <Load isLoading={isLoading} insideComponent />
      </CardBody>
    );

  return (
    <React.Fragment>
        <CardBody style={{ height: "100vh" }}>
          {renderItensUpload()}
        </CardBody>
    </React.Fragment>
  );
};
const DialogInsertDivergencia = ({
  show,
  setFormValues,
  formValues,
  addItem,
  setShowDialog,
  showDialog,
}) => {
  useEffect(() => {}, [formValues]);

  const closeModal = () => {
    setShowDialog(!show);
  };

  const handleClose = () => setShowDialog(false);
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Title
          size="1.500em"
          color="title"
          title={
            <FormattedMessage id="myProfile.digitalSignature.divergencias" />
          }
        />
      </Modal.Header>
      <Modal.Body>
        <Row style={{ marginTop: "10px" }}>
          <Col
            lg={12}
            style={{ marginTop: "10px", display: "flex", flexFlow: "column" }}
          >
            <TextAreaField
              onChange={(e) =>
                setFormValues({ ...formValues, divergencia: e.target.value })
              }
              value={formValues.divergencia}
              label={
                <FormattedMessage id="myProfile.digitalSignature.description" />
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col lg={{ size: 2, offset: 6 }}>
          <ButtonSecondary
            onClick={() => closeModal()}
            name={<FormattedMessage id="shared.cancel" />}
          />
        </Col>
        <Col lg={{ size: 2 }}>
          <ButtonDefault
            disabled={!formValues.divergencia}
            onClick={() => addItem()}
            name={
              <FormattedMessage id="myProfile.digitalSignature.sendDivergencias" />
            }
          />
        </Col>
      </Modal.Footer>
    </Modal>
  );
};
const mapDispatchToProps = (dispatch) => ({
  contractDocumentUpload: (payload) =>
    dispatch(documentsActions.contractDocumentUpdateRequest(payload)),
  finishStageDigitalDocumentAction: (payload) =>
    dispatch(documentsActions.finishStageDigitalDocument(payload)),
  getContractDocument: (payload) =>
    dispatch(documentsActions.getContractDocumentRequest(payload)), //dani
  sendAlert: (messages) => dispatch(alertAction.setAlertsRequest(messages)),
  getAssinaturaAdministrativo: (payload) => dispatch(documentsActions.listDataSubscribersRequest(payload)),
  getAssinaturaAdministrativoSuccess: (payload) => dispatch(documentsActions.listDataSubscribersSuccess(payload)),
  
  });

const mapStateToProps = (state) => ({
  hasError: state.contractDocuments.hasErro,
  document: state.contractDocuments.documents,
  dependents: state.contractDocuments.dependents,
  healthAndDentalPlanValues: state.contractDocuments.healthAndDentalPlanValues,
  isLoading: state.contractDocuments?.isLoading,
  signedDocument: state.contractDocuments.signedDocument,
  dataSubscribers: state.contractDocuments.dataSubscribers,
  user: state.auth, 
  state: state
});

DigitalSignatureDocuments.defaultProps = {
  document: {},
  dependents: {},
  healthAndDentalPlanValues: {},
  dataSubscribers:{},
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DigitalSignatureDocuments);
