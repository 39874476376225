import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getEducationLevel: [""],
  getEducationLevelSuccess: ["payload", "isLoading"],
  getEducationLevelFail: ["error", "isLoading"],
});

const INITIAL_STATE = {
  formations: [],
  educationLevel: [],
  isLoading: false,
  isLoadingSelectOptions: false
};

const getEducationLevel = state => ({
  ...state,
  isLoadingSelectOptions: true
});

const getEducationLevelSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingSelectOptions: false
});
const getEducationLevelFail = (state, error) => ({
  ...state,
  error,
  isLoadingSelectOptions: false
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_EDUCATION_LEVEL]: getEducationLevel,
  [Types.GET_EDUCATION_LEVEL_SUCCESS]: getEducationLevelSuccess,
  [Types.GET_EDUCATION_LEVEL_FAIL]: getEducationLevelFail,
});
