import {
  CheckBox,
  MultSelect,
  RichTextInput,
  SelectFieldInput,
  TextField
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";

import CurrencyFormat from "react-currency-format";
import FormGroup from "../group/FormGroup";
import React from "react";
import Schedules from "../Schedules";
import { required } from "../../../helpers/validations";
import {getJobDescription}  from "../../../helpers/sharedHelpers";

const ExternalBankOfTalents = ({
  inputsItems,
  formValues,
  setFormValues,
  setFormErros,
  formValidators,
  formErros,
  submited,
  schedules,
  setSchedulesItems,
  onSelectArea,
  intl,
}) => {
  return (
    <React.Fragment>
      <FormGroup
        formName={<FormattedMessage id="sharedItems.formVacancyInformation" />}
      >
        <Row>
          <Col lg={3}>
            <SelectFieldInput
              value={formValues.area}
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              name={intl.formatMessage({ id: "inputs.area" })}
              formErr={formErros}
              items={inputsItems.area}
              label={<FormattedMessage id="inputs.area" />}
              onChange={e => onSelectArea(e.target.value)}
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.job" })}
              items={inputsItems.jobs}
              value={formValues.job}
              label={<FormattedMessage id={getJobDescription(formValues.job, inputsItems.jobs)}> </FormattedMessage>}
              onChange={e =>
                setFormValues({ ...formValues, job: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <CurrencyFormat
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              fixedDecimalScale={true}
              name={intl.formatMessage({ id: "inputs.salary" })}
              validations={[required]}
              value={formValues.salary}
              label={<FormattedMessage id="inputs.salary" />}
              prefix="R$"
              allowNegative={false}
              onChange={e =>
                setFormValues({ ...formValues, salary: e.target.value })
              }
              customInput={TextField}
            />
          </Col>
          <Col lg={3}>
            <CheckBox
              type="checkbox"
              checked={formValues.showSalary}
              label={<FormattedMessage id="inputs.showSalary" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  showSalary: !formValues.showSalary
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.placeOfWork" })}
              items={inputsItems.placeOfWork}
              value={formValues.placeOfWork}
              label={<FormattedMessage id="inputs.placeOfWork" />}
              onChange={e =>
                setFormValues({ ...formValues, placeOfWork: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.journey" })}
              items={inputsItems.jorney}
              value={formValues.jorney}
              label={<FormattedMessage id="inputs.journey" />}
              onChange={e =>
                setFormValues({ ...formValues, jorney: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              DefaultOption={"Sem agrupamento"}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.vacancyGroup" })}
              items={inputsItems.vacancyGroup}
              value={formValues.vacancyGroup}
              label={<FormattedMessage id="inputs.vacancyGroup" />}
              onChange={e =>
                setFormValues({ ...formValues, vacancyGroup: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <CheckBox
              type="checkbox"
              checked={formValues.informeSalary}
              label={<FormattedMessage id="inputs.informeSalary" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  informeSalary: !formValues.informeSalary
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.vacancyReq" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.vacancyReq}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.vacancyReq" })}
              onChange={e => setFormValues({ ...formValues, vacancyReq: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.responsibility" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.responsibility}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.responsibility" })}
              onChange={e =>
                setFormValues({ ...formValues, responsibility: e })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.aboutWork" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.aboutWork}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.aboutWork" })}
              onChange={e => setFormValues({ ...formValues, aboutWork: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.skills" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.skills}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.skills" })}
              onChange={e => setFormValues({ ...formValues, skills: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.benefits" })}
              value={formValues.benefits}
              options={inputsItems.benefits.map(e => ({
                label: e.nome,
                value: e.id
              }))}
              label={<FormattedMessage id="inputs.benefits" />}
              isMulti
              onChange={e =>
                setFormValues({
                  ...formValues,
                  benefits: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup formName={<FormattedMessage id="sharedItems.formCandidate" />}>
        <Row>
          <Col lg={4}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.schooling" })}
              validations={[required]}
              items={inputsItems.schooling}
              value={formValues.schooling}
              label={<FormattedMessage id="inputs.schooling" />}
              onChange={e =>
                setFormValues({ ...formValues, schooling: e.target.value })
              }
            />
          </Col>
          <Col lg={4}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.areaFormation" })}
              value={formValues.areaFormation}
              options={inputsItems.areaFormation.map(e => ({
                label: e.descricao,
                value: e.id
              }))}
              label={<FormattedMessage id="inputs.areaFormation" />}
              isMulti
              onChange={e =>
                setFormValues({
                  ...formValues,
                  areaFormation: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
          <Col lg={4}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.competence" })}
              value={formValues.competence}
              options={inputsItems.competence.map(e => ({
                label: e.nome,
                value: e.id
              }))}
              isMulti
              label={<FormattedMessage id="inputs.competence" />}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  competence: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <CheckBox
              type="checkbox"
              checked={formValues.deficiency}
              label={<FormattedMessage id="inputs.deficiency" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  deficiency: !formValues.deficiency
                })
              }
            />
          </Col>
        </Row>
        <hr />
      </FormGroup>
      <FormGroup
        formName={<FormattedMessage id="sharedItems.formTimeOfWork" />}
      >
        <Schedules
          addSchudule={setSchedulesItems}
          formSchedules={schedules}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </FormGroup>
    </React.Fragment>
  );
};
export default injectIntl(ExternalBankOfTalents);
