import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  employeeSearchRequest: ["registerNumber"],
  employeeSearchSuccess: ["employee"],
  employeeSearchFail: ["error"],
});
const INITIAL_STATE = {
  employee: [],
  isLoading: true
};

const request = state => ({ ...state, isLoading: true });

const success = (state, payload) => ({
  employee: payload.employee,
  isLoading: false
});
const fail = (state, error) => ({ ...INITIAL_STATE, isLoading: false, error });

export default createReducer(INITIAL_STATE, {
  [Types.EMPLOYEE_SEARCH_REQUEST]: request,
  [Types.EMPLOYEE_SEARCH_SUCCESS]: success,
  [Types.EMPLOYEE_SEARCH_FAIL]: fail,
});
