import {
  ActionItem,
  Actions,
  LabelAction
} from "components/shared/CustomComponentsStyle";
import {
  BodyBlock,
  DateContainer,
  DescriptionContainer,
  DescriptionSpan,
  NumberOfItem,
  TitleContainer
} from "../../StageBlocksStyle";
import { Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import { SelectFieldInput, TextField } from "components/shared/CustomInputs";
import { cancel, edit } from "../../../../../assets/icons/icons";

import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import { LabelH1 } from "components/shared/CustomComponents";
import { isDateDisabledForEditing } from "../../../../../helpers/sharedHelpers";
import { kindStages } from "../../../../../locale";

const renderTitleBlock = kind => {
  switch (kind) {
    case 0:
      return <FormattedMessage id="stageList.data" />;
    case 3:
      return <FormattedMessage id="stageList.assay" />;
    case 5:
      return <FormattedMessage id="stageList.admition" />;
    case 4:
      return <FormattedMessage id="stageList.medic" />;
    case 6:
      return <FormattedMessage id="stageList.ambietation" />;
    case 7:
      return <FormattedMessage id="stageList.feedback" />;
    case 8:
      return <FormattedMessage id="stageList.preAdmition" />;
    default:
      return null;
  }
};

export const SimpleBlock = ({
  kind,
  position,
  deleteItem,
  description,
  isAssociating,
  setRailsAttributesToAssocation,
  railsAttributesToAssocation,
  id,
  requestShowStage,
  inputs,
  name,
  isEditingAssociation,
  periodeRealization,
  testPeriodeRealizationUntil,
  periodeAvaliation,
  periodeAvaliationUntil
}) => {
  const [selfAttributes, setSelfAttributes] = useState({});
  const registerValuesAndThrow = (value, name) => {
    setSelfAttributes({ ...selfAttributes, [name]: value });
    setRailsAttributesToAssocation({
      ...railsAttributesToAssocation,
      [id]: {
        ...selfAttributes,
        ...railsAttributesToAssocation[id],
        [name]: value
      }
    });
  };

  const renderActions = () => {
    return (
      <Col lg={2}>
        <Actions flexPosition={kind === "data" ? "flex-end" : "space-around"}>
          {kind !== "data" && (
            <ActionItem onClick={() => deleteItem(id)}>
              {cancel()}
              <LabelAction>
                <FormattedMessage id="sharedItems.remove" />
              </LabelAction>
            </ActionItem>
          )}
        </Actions>
      </Col>
    );
  };

  return (
    <BodyBlock>
      <Row>
        <Col lg={9}>
          <TitleContainer>
            <NumberOfItem style={{ marginLeft: "unset" }}>
              <LabelH1>{position}</LabelH1>
            </NumberOfItem>
            <LabelH1>{name}</LabelH1>
          </TitleContainer>
        </Col>
        {renderActions()}
      </Row>
      <Row style={{ alignItems: "flex-start" }}>
        <Col lg={{ span: 3, offset: 1 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="rails.kindStage" />
            </LabelAction>
            <DescriptionSpan>{renderTitleBlock(kind)}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
        <Col lg={{ span: 8 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.description" />
            </LabelAction>
            <DescriptionSpan>{description}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
      </Row>
      {isAssociating && (
        <Row style={{ alignItems: "center", marginTop: "30px" }}>
          <Col
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexFlow: "column"
            }}
            lg={{ span: 5, offset: 1 }}
          >
            <LabelAction>
              <FormattedMessage id="inputs.periodeRealization" />
            </LabelAction>
            <DateContainer>
              <InputMask
                alwaysShowMask
                mask="99/99/9999"
                width="130px"
                disabled={isDateDisabledForEditing(
                  isEditingAssociation,
                  periodeRealization
                )}
                defaultValue={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id].periodeRealization
                    : null
                }
                onChange={e =>
                  registerValuesAndThrow(e.target.value, "periodeRealization")
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    disabled={isDateDisabledForEditing(
                      isEditingAssociation,
                      periodeRealization
                    )}
                  />
                )}
              </InputMask>
              <LabelAction
                ml="15px"
                mr="15px"
                fz="16px"
                style={{ alignSelf: "center" }}
              >
                <FormattedMessage id="sharedItems.until" />
              </LabelAction>
              <InputMask
                alwaysShowMask
                mask="99/99/9999"
                width="130px"

                defaultValue={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id]
                        .testPeriodeRealizationUntil
                    : null
                }
                onChange={e =>
                  registerValuesAndThrow(
                    e.target.value,
                    "testPeriodeRealizationUntil"
                  )
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                  />
                )}
              </InputMask>
            </DateContainer>
          </Col>
          {kind === kindStages[4].id && isAssociating && (
            <Col
              style={{ display: "flex", alignItems: "center" }}
              lg={{ span: 4 }}
            >
              <SelectFieldInput
                items={inputs.placeOfMedicExame}
                mt="15px"
                disabled={isDateDisabledForEditing(
                  isEditingAssociation,
                  testPeriodeRealizationUntil
                )}
                value={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id].placeOfExame
                      ? railsAttributesToAssocation[id].placeOfExame
                      : null
                    : null
                }
                label={<FormattedMessage id="inputs.placeOfExam" />}
                onChange={e =>
                  registerValuesAndThrow(e.target.value, "placeOfExame")
                }
              />
            </Col>
          )}
          {kind === kindStages[3].id && isAssociating && (
            <Col
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexFlow: "column"
              }}
              lg={{ span: 4 }}
            >
              <LabelAction>
                <FormattedMessage id="inputs.periodeAvaliation" />
              </LabelAction>
              <DateContainer>
                <InputMask
                  alwaysShowMask
                  mask="99/99/9999"
                  width="130px"
                  disabled={isDateDisabledForEditing(
                    isEditingAssociation,
                    periodeAvaliation
                  )}
                  defaultValue={
                    railsAttributesToAssocation[id]
                      ? railsAttributesToAssocation[id].periodeAvaliation
                      : null
                  }
                  onChange={e =>
                    registerValuesAndThrow(e.target.value, "periodeAvaliation")
                  }
                >
                  {inputProps => (
                    <TextField
                      {...inputProps}
                      disabled={isDateDisabledForEditing(
                        isEditingAssociation,
                        periodeAvaliation
                      )}
                    />
                  )}
                </InputMask>
                <LabelAction
                  ml="15px"
                  mr="15px"
                  fz="16px"
                  style={{ alignSelf: "center" }}
                >
                  <FormattedMessage id="sharedItems.until" />
                </LabelAction>
                <InputMask
                  alwaysShowMask
                  mask="99/99/9999"
                  width="130px"
                  defaultValue={
                    railsAttributesToAssocation[id]
                      ? railsAttributesToAssocation[id].periodeAvaliationUntil
                      : null
                  }
                  onChange={e =>
                    registerValuesAndThrow(
                      e.target.value,
                      "periodeAvaliationUntil"
                    )
                  }
                >
                  {inputProps => (
                    <TextField
                      {...inputProps}

                    />
                  )}
                </InputMask>
              </DateContainer>
            </Col>
          )}
        </Row>
      )}
    </BodyBlock>
  );
};
export default SimpleBlock;
