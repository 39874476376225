import React, { useState } from "react";
import InputMask from "react-input-mask";
import {
  Col,
  Row,
} from "react-bootstrap";
import {
  CardBody,
  FormProcess,
} from "./styles";
import {
  ButtonDefault,
  LabelComponent,
} from "../../../../../components/shared/CustomComponentsCandidato/CustomComponents";
import {
  RadioInput,
  TextField,
} from "../../../../../components/shared/CustomInputsCandidato/CustomInputs";
import { YesAndNo } from "../../../../../utils/contants/levels";
import {
  isValidDate,
  isValidEmitionDate,
  buildDate
} from "../../../../../helpers/sharedHelpers";

export const SMBForm = (props) => {

  const isValidForm = () => {
    let errorList = [];

    // if (
    //   props.formSMBValues.aderenciaTitularPlanoSaude === null ||
    //   props.formSMBValues.aderenciaTitularPlanoSaude === undefined
    // )
    //   errorList.push({
    //     type: "danger",
    //     message:
    //       "Deve ser informado. | Como beneficiário titular você deseja aderir ao Plano de Saúde?",
    //   });

    // if (
    //   props.formSMBValues.aderenciaTitularPlanoOdontologico === null ||
    //   props.formSMBValues.aderenciaTitularPlanoOdontologico === undefined
    // )
    //   errorList.push({
    //     type: "danger",
    //     message:
    //       "Deve ser informado. | Como beneficiário titular você deseja aderir ao Plano Odontolólico?",
    //   });

    if (
      props.formSMBValues.aderenciaTitularPlanoSaude === 1 &&
      props.dependentsList &&
      props.formSMBValues.aderenciaBeneficiariosPlanoSaude.some(
        (beneficiario) => beneficiario.aderencia === null
      )
    ) {
      let beneficiariosAderenciaNaoInformada =
        props.formSMBValues.aderenciaBeneficiariosPlanoSaude.filter(
          (beneficiario) => beneficiario.aderencia === null
        );
      beneficiariosAderenciaNaoInformada.forEach((beneficiario) => {
        errorList.push({
          type: "danger",
          message: `Deve ser informado. | Gostaria de incluir o dependente, ${beneficiario.nome}, como beneficiario no plano de saúde?`,
        });
      });
    }

    if (
      props.formSMBValues.aderenciaTitularPlanoOdontologico === 1 &&
      props.dependentsList &&
      props.formSMBValues.aderenciaBeneficiariosPlanoOdontologico.some(
        (beneficiario) => beneficiario.aderencia === null
      )
    ) {
      let beneficiariosAderenciaNaoInformada =
        props.formSMBValues.aderenciaBeneficiariosPlanoOdontologico.filter(
          (beneficiario) => beneficiario.aderencia === null
        );
      beneficiariosAderenciaNaoInformada.forEach((beneficiario) => {
        errorList.push({
          type: "danger",
          message: `Deve ser informado. | Gostaria de incluir o dependente, ${beneficiario.nome}, no plano de odontológico?`,
        });
      });
    }

    if (
      (props.formSMBValues.aderenciaTitularPlanoSaude === 1 ||
        props.formSMBValues.aderenciaTitularPlanoOdontologico === 1) &&
      props.dependentsList &&
      props.formSMBValues.aderenciaBeneficiariosPlanoOdontologico.some(
        (beneficiario) => beneficiario.nomeDaMae === null
      )
    ) {
      let beneficiariosAderenciaNaoInformada =
        props.formSMBValues.aderenciaBeneficiariosPlanoOdontologico.filter(
          (beneficiario) => beneficiario.nomeDaMae === null
        );
      beneficiariosAderenciaNaoInformada.forEach((beneficiario) => {
        errorList.push({
          type: "danger",
          message: `Deve ser informado. | Nome da mãe do dependente, ${beneficiario.nome}`,
        });
      });
    }

    if (
      (props.formSMBValues.aderenciaTitularPlanoSaude === 1 ||
        props.formSMBValues.aderenciaTitularPlanoOdontologico === 1) &&
      props.formSMBValues.aderenciaBeneficiariosPlanoSaude.find(
        (dependent) => dependent.parentesco === "Cônjuge"
      ) &&
      props.formSMBValues.dataCasamento === null
    ) {
      errorList.push({
        type: "danger",
        message: `Deve ser informado. | Data de casamento`,
      });
    } else if (
      (props.formSMBValues.aderenciaTitularPlanoSaude === 1 ||
        props.formSMBValues.aderenciaTitularPlanoOdontologico === 1) &&
      props.formSMBValues.aderenciaBeneficiariosPlanoSaude.find(
        (dependent) => dependent.parentesco === "Cônjuge"
      )
    ) {
      if (isValidDate(props.formSMBValues.dataCasamento)) {
        if (!isValidEmitionDate(props.formSMBValues.dataCasamento)) {
          errorList.push({
            type: "danger",
            message: "Data de casamento não pode ser futura.",
          });
        }
      } else {
        errorList.push({
          type: "danger",
          message: "Data de casamento inválida.",
        });
      }
    }

    if (errorList.length > 0) {
      props.sendAlert(errorList);
      return false;
    }

    return true;
  };

  const handleGoToSignature = () => {
    if (!isValidForm()) return;

    let novaAderenciaBeneficiariosPlanoOdontologico =
      props.formSMBValues.aderenciaBeneficiariosPlanoOdontologico;
    props.formSMBValues.aderenciaBeneficiariosPlanoOdontologico.forEach(
      (beneficiario, index) => {
        if (beneficiario.aderencia === null)
          novaAderenciaBeneficiariosPlanoOdontologico[index].aderencia = 0;

        if (beneficiario.nomeDaMae === null)
          novaAderenciaBeneficiariosPlanoOdontologico[index].nomeDaMae = "";
      }
    );

    let novaAderenciaBeneficiariosPlanoSaude =
      props.formSMBValues.aderenciaBeneficiariosPlanoSaude;
    props.formSMBValues.aderenciaBeneficiariosPlanoSaude.forEach(
      (beneficiario, index) => {
        if (beneficiario.aderencia === null)
          novaAderenciaBeneficiariosPlanoSaude[index].aderencia = 0;

        if (beneficiario.nomeDaMae === null)
          novaAderenciaBeneficiariosPlanoSaude[index].nomeDaMae = "";
      }
    );

    props.setFormSMBValues((prevformSMBValues) => {
      return {
        ...prevformSMBValues,
        aderenciaBeneficiariosPlanoOdontologico:
          novaAderenciaBeneficiariosPlanoOdontologico,
        aderenciaBeneficiariosPlanoSaude: novaAderenciaBeneficiariosPlanoSaude,
      };
    });

    let valorData =
      props.formSMBValues.dataCasamento !== null
        ? props.formSMBValues.dataCasamento
        : 0;

    let novaDataCasamento = buildDate(valorData);

    props.setFormSMBValues((prevFormSMBValues) => {
      return { ...prevFormSMBValues, dataCasamento: novaDataCasamento };
    });
    props.setStep("step2");
    props.getContractDocument({
      idVaga: props.idVaga,
      idEtapa: props.idEtapa,
      aderenciaSMB: {
        ...props.formSMBValues,
        dataCasamento: novaDataCasamento,
      },
    });
  };

  return (
    <>
      <SMBPlanValues
        healthAndDentalPlanValues={props.healthAndDentalPlanValues}
      />
      <FormProcess>
        <Row>
          <Col style={{ padding: "0" }}>


            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <LabelComponent
                  size={20}
                 text="Clique no botão abaixo “Ir para assinatura” para visualizar os Termos e o Contrato de Trabalho e faça a Assinatura Digital."
               />
              </Col>
            </Row>
            {/* <Row
              className="healthPlan"
              style={{
                padding: "10px",
                margin: "0 0 15px 0",
                border: "1px solid rgba(0,0,0,.125)",
              }}
            >
              <Col>
                
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <LabelComponent
                      size={13}
                      text="Como beneficiário titular você deseja aderir ao Plano de Saúde?"
                    />
                  </Col>
                  <Col>
                    <Row>
                      {YesAndNo.map((option, index) => {
                        return (
                          <Col
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            style={{ padding: "0px" }}
                            key={index}
                          >
                            <RadioInput
                              label={option.label}
                              value={option.value}
                              selectedOption={
                                props.formSMBValues.aderenciaTitularPlanoSaude
                              }
                              checked={false}
                              mb={"1em"}
                              onChange={(e) => {
                                let value = e.target.value;
                                props.setFormSMBValues((prevformSMBValues) => {
                                  return {
                                    ...prevformSMBValues,
                                    aderenciaTitularPlanoSaude: Number(value),
                                  };
                                });
                              }}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>

                {props.formSMBValues.aderenciaTitularPlanoSaude === 1 &&
                  props.dependentsList &&
                  props.dependentsList.map((dependente, indexDependente) => (
                    <Row
                      key={`healthPlanDependent-${indexDependente}`}
                      style={{ borderTop: "1px solid #dfdfdf" }}
                    >
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <LabelComponent
                          size={13}
                          text={`Gostaria de incluir o dependente, ${dependente.nome}, como beneficiario no plano de saúde?`}
                        />
                      </Col>
                      <Col>
                        <AdherenceDependentsHealthPlan
                          dependentId={dependente.id}
                          formSMBValues={props.formSMBValues}
                          setFormSMBValues={props.setFormSMBValues}
                        />
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row> */}

            {/* <Row
              className="dentalPlan"
              style={{ padding: "10px", border: "1px solid rgba(0,0,0,.125)" }}
            >
              <Col>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <LabelComponent
                      size={13}
                      text="Como beneficiário titular você deseja aderir ao Plano Odontolólico?"
                    />
                  </Col>
                  <Col>
                    <Row>
                      {YesAndNo.map((option, index) => {
                        return (
                          <Col
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            style={{ padding: "0px" }}
                            key={index}
                          >
                            <RadioInput
                              label={option.label}
                              value={option.value}
                              selectedOption={
                                props.formSMBValues
                                  .aderenciaTitularPlanoOdontologico
                              }
                              checked={false}
                              mb={"1em"}
                              onChange={(e) => {
                                let value = e.target.value;
                                props.setFormSMBValues((prevformSMBValues) => {
                                  return {
                                    ...prevformSMBValues,
                                    aderenciaTitularPlanoOdontologico:
                                      Number(value),
                                  };
                                });
                              }}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                {props.formSMBValues.aderenciaTitularPlanoOdontologico == 1 &&
                  props.dependentsList &&
                  props.dependentsList.map((dependente, indexDependente) => (
                    <>
                      <Row
                        key={`dentalPlan-${indexDependente}`}
                        style={{ borderTop: "1px solid #dfdfdf" }}
                      >
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <LabelComponent
                            size={13}
                            text={`Gostaria de incluir o dependente, ${dependente.nome}, como beneficiario no plano de odontológico?`}
                          />
                        </Col>
                        <Col>
                          <AdherenceDependentsDentalPlan
                            dependentId={dependente.id}
                            formSMBValues={props.formSMBValues}
                            setFormSMBValues={props.setFormSMBValues}
                          />
                        </Col>
                      </Row>
                    </>
                  ))}
              </Col>
            </Row> */}

            {(props.formSMBValues.aderenciaTitularPlanoSaude === 1 ||
              props.formSMBValues.aderenciaTitularPlanoOdontologico === 1) &&
              props.formSMBValues.aderenciaBeneficiariosPlanoSaude.find(
                (dependent) => dependent.parentesco === "Cônjuge"
              ) && (
                <WeddingDate
                  formSMBValues={props.formSMBValues}
                  setFormSMBValues={props.setFormSMBValues}
                />
              )}

            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <ButtonDefault
                  isLoading={props.isLoading}
                  mt={"30px"}
                  onClick={() => handleGoToSignature()}
                  name={"Ir para assinatura"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </FormProcess>
    </>
  );
};

const AdherenceDependentsHealthPlan = (props) => {
  const [
    aderenciaBeneficiariosPlanoSaude,
    setAderenciaBeneficiariosPlanoSaude,
  ] = useState(props.formSMBValues.aderenciaBeneficiariosPlanoSaude);

  return (
    <>
      <Row>
        {YesAndNo.map((option, index) => {
          return (
            <Col
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{ padding: "0px" }}
              key={index}
            >
              <RadioInput
                label={option.label}
                value={option.value}
                selectedOption={
                  aderenciaBeneficiariosPlanoSaude.find(
                    (dependent) => dependent.id === props.dependentId
                  ).aderencia
                }
                checked={false}
                mb={"1em"}
                onChange={(e) => {
                  setAderenciaBeneficiariosPlanoSaude(
                    (prevAderenciaBeneficiariosPlanoSaude) => {
                      let objIndex =
                        prevAderenciaBeneficiariosPlanoSaude.findIndex(
                          (dependent) => dependent.id === props.dependentId
                        );
                      prevAderenciaBeneficiariosPlanoSaude[objIndex].aderencia =
                        e.target.value;
                      return prevAderenciaBeneficiariosPlanoSaude;
                    }
                  );
                  props.setFormSMBValues((prevformSMBValues) => {
                    return {
                      ...prevformSMBValues,
                      aderenciaBeneficiariosPlanoSaude:
                        aderenciaBeneficiariosPlanoSaude,
                    };
                  });
                }}
              />
            </Col>
          );
        })}
      </Row>

      {
        <DependentMother
          dependente={aderenciaBeneficiariosPlanoSaude.find(
            (dependent) => dependent.id === props.dependentId
          )}
          setFormSMBValues={props.setFormSMBValues}
          formSMBValues={props.formSMBValues}
          dependentIndex={aderenciaBeneficiariosPlanoSaude.findIndex(
            (dependent) => dependent.id === props.dependentId
          )}
        />
      }
    </>
  );
};

const AdherenceDependentsDentalPlan = (props) => {
  const [
    aderenciaBeneficiariosPlanoOdontologico,
    setAderenciaBeneficiariosPlanoOdontologico,
  ] = useState(props.formSMBValues.aderenciaBeneficiariosPlanoOdontologico);

  return (
    <>
      <Row>
        {YesAndNo.map((option, index) => {
          return (
            <Col
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{ padding: "0px" }}
              key={index}
            >
              <RadioInput
                label={option.label}
                value={option.value}
                selectedOption={
                  aderenciaBeneficiariosPlanoOdontologico.find(
                    (dependent) => dependent.id === props.dependentId
                  ).aderencia
                }
                checked={false}
                mb={"1em"}
                onChange={(e) => {
                  setAderenciaBeneficiariosPlanoOdontologico(
                    (prevAderenciaBeneficiariosPlanoOdontologico) => {
                      let objIndex =
                        prevAderenciaBeneficiariosPlanoOdontologico.findIndex(
                          (dependent) => dependent.id === props.dependentId
                        );
                      prevAderenciaBeneficiariosPlanoOdontologico[
                        objIndex
                      ].aderencia = e.target.value;
                      return prevAderenciaBeneficiariosPlanoOdontologico;
                    }
                  );
                  props.setFormSMBValues((prevformSMBValues) => {
                    return {
                      ...prevformSMBValues,
                      aderenciaBeneficiariosPlanoOdontologico:
                        aderenciaBeneficiariosPlanoOdontologico,
                    };
                  });
                }}
              />
            </Col>
          );
        })}
      </Row>

      {
        <DependentMother
          dependente={aderenciaBeneficiariosPlanoOdontologico.find(
            (dependent) => dependent.id === props.dependentId
          )}
          setFormSMBValues={props.setFormSMBValues}
          formSMBValues={props.formSMBValues}
          dependentIndex={aderenciaBeneficiariosPlanoOdontologico.findIndex(
            (dependent) => dependent.id === props.dependentId
          )}
        />
      }
    </>
  );
};

const DependentMother = (props) => {
  const [
    aderenciaBeneficiariosPlanoOdontologico,
    setAderenciaBeneficiariosPlanoOdontologico,
  ] = useState(props.formSMBValues.aderenciaBeneficiariosPlanoOdontologico);

  const [
    aderenciaBeneficiariosPlanoSaude,
    setAderenciaBeneficiariosPlanoSaude,
  ] = useState(props.formSMBValues.aderenciaBeneficiariosPlanoSaude);

  const handlerOnChange = (e) => {
    setAderenciaBeneficiariosPlanoSaude(
      (prevAderenciaBeneficiariosPlanoSaude) => {
        prevAderenciaBeneficiariosPlanoSaude[props.dependentIndex].nomeDaMae =
          e.target.value;
        return prevAderenciaBeneficiariosPlanoSaude;
      }
    );

    setAderenciaBeneficiariosPlanoOdontologico(
      (prevAderenciaBeneficiariosPlanoOdontologico) => {
        prevAderenciaBeneficiariosPlanoOdontologico[
          props.dependentIndex
        ].nomeDaMae = e.target.value;
        return prevAderenciaBeneficiariosPlanoOdontologico;
      }
    );

    props.setFormSMBValues((prevformSMBValues) => {
      return {
        ...prevformSMBValues,
        aderenciaBeneficiariosPlanoSaude: aderenciaBeneficiariosPlanoSaude,
        aderenciaBeneficiariosPlanoOdontologico:
          aderenciaBeneficiariosPlanoOdontologico,
      };
    });
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ padding: "0" }}>
        <TextField
          disabled={false}
          onChange={(e) => handlerOnChange(e)}
          mb="1"
          value={
            aderenciaBeneficiariosPlanoOdontologico[props.dependentIndex]
              .nomeDaMae || ""
          }
          label={`Nome da mãe do dependente, ${props.dependente.nome}:`}
        />
      </Col>
    </Row>
  );
};

const WeddingDate = (props) => {
  return (
    <Row
      style={{
        padding: "10px",
        marginTop: "15px",
        border: "1px solid rgba(0, 0, 0, 0.125)",
      }}
    >
      <Col lg={3}>
        <InputMask
          mask="99/99/9999"
          type="text"
          value={props.formSMBValues.dataCasamento || ""}
          mb="1"
          label="Data de casamento:"
          onChange={(e) => {
            let novaDataCasamento = e.target.value;
            props.setFormSMBValues((prevFormSMBValues) => {
              return { ...prevFormSMBValues, dataCasamento: novaDataCasamento };
            });
          }}
          alwaysShowMask
        >
          {(inputProps) => <TextField {...inputProps} />}
        </InputMask>
      </Col>
    </Row>
  );
};

const SMBPlanValues = (props) => {
  return (
    <React.Fragment>
      {/* <CardBody
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          padding: "10px",
          border: "1px solid #ff00003b",
        }}
      >
        <p>
          <strong>Plano:</strong> {props.healthAndDentalPlanValues.nomeDoPlano}
        </p>
        <p>
          <strong>Valor plano de saúde para o titular:</strong>{" "}
          {props.healthAndDentalPlanValues.valorPlanoSaudeTitular}
        </p>
        <p>
          <strong>Valores plano de saúde para dependentes:</strong>
        </p>

        {props.healthAndDentalPlanValues.valoresPlanoSaudeDependentes && (
          <ul>
            {props.healthAndDentalPlanValues.valoresPlanoSaudeDependentes.map(
              (regraValor, index) => (
                <li key={index}>
                  {regraValor.regra != "" && regraValor.regra + ": "}
                  {regraValor.valor}
                </li>
              )
            )}
          </ul>
        )}

        <p>
          <strong>Valor plano odontológico para o titular:</strong>{" "}
          {props.healthAndDentalPlanValues.valorPlanoOdontologicoTitular}
        </p>
        <p>
          <strong>Valores plano odontológico para dependentes:</strong>{" "}
          {props.healthAndDentalPlanValues.valoresPlanoOdontologicoDependentes}
        </p>
      </CardBody> */}
    </React.Fragment>
  );
};
