import {
  ButtonDefault,
  ButtonSecondary,
  SelectFieldInput,
  TextField
} from "../CustomInputs";
import {
  FilterItemRow,
  FilterRegisterLabel,
  RowActionsFilter
} from "../CustomComponentsStyle";

import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import React from "react";

export const FilterVacancyOptions = ({
  filterOptions,
  setFiltersOptions,
  clearValues,
  applyFilter,
  filterItems
}) => {
  return (
    <React.Fragment>
      <FilterItemRow flexPosition="flex-start">
        <TextField
          onChange={e =>
            setFiltersOptions({ ...filterOptions, code: e.target.value })
          }
          value={filterOptions.code}
          mr="21px"
          label={<FormattedMessage id="sharedItems.code" />}
          width="76px"
        />
        <SelectFieldInput
          mr="21px"
          width="132px"
          items={filterItems.area}
          onChange={e =>
            setFiltersOptions({
              ...filterOptions,
              area: e.target.value,
              areaName: filterItems.area.find(k => k.id == e.target.value)
                .descricao
            })
          }
          value={filterOptions.area}
          label={<FormattedMessage id="inputs.area" />}
        />
        <SelectFieldInput
          mr="21px"
          width="223px"
          items={filterItems.job}
          onChange={e =>
            setFiltersOptions({
              ...filterOptions,
              job: e.target.value,
              jobName: filterItems.job.find(k => k.id == e.target.value).funcao
            })
          }
          value={filterOptions.job}
          label={<FormattedMessage id="inputs.job" />}
        />
        <InputMask
          mr="21px"
          label={<FormattedMessage id="sharedItems.periode" />}
          value={filterOptions.periode}
          onChange={e =>
            setFiltersOptions({ ...filterOptions, periode: e.target.value })
          }
          width="100px"
          mask="99/99/9999"
        >
          {inputProps => <TextField {...inputProps} />}
        </InputMask>

        <FilterRegisterLabel style={{ marginTop: "20px" }}>
          {<FormattedMessage id="sharedItems.until" />}
        </FilterRegisterLabel>
        <InputMask
          mr="21px"
          ml="15px"
          value={filterOptions.periodeEnd}
          onChange={e =>
            setFiltersOptions({ ...filterOptions, periodeEnd: e.target.value })
          }
          width="100px"
          mask="99/99/9999"
          mt="20px"
        >
          {inputProps => <TextField {...inputProps} />}
        </InputMask>
      </FilterItemRow>
      <FilterItemRow flexPosition="flex-start">
        <SelectFieldInput
          mr="21px"
          width="250px"
          value={filterOptions.createdBy}
          onChange={e =>
            setFiltersOptions({ ...filterOptions, createdBy: e.target.value })
          }
          label={<FormattedMessage id="sharedItems.createdBy" />}
        />
        <SelectFieldInput
          mr="21px"
          width="250px"
          value={filterOptions.manager}
          onChange={e =>
            setFiltersOptions({ ...filterOptions, manager: e.target.value })
          }
          label={<FormattedMessage id="sharedItems.manager" />}
        />
        <SelectFieldInput
          mr="21px"
          value={filterOptions.status}
          onChange={e =>
            setFiltersOptions({ ...filterOptions, status: e.target.value })
          }
          width="258px"
          label={<FormattedMessage id="sharedItems.status" />}
        />
      </FilterItemRow>
      <RowActionsFilter>
        <SelectFieldInput
          mr="21px"
          width="258px"
          value={filterOptions.site}
          onChange={e =>
            setFiltersOptions({ ...filterOptions, site: e.target.value })
          }
          label={<FormattedMessage id="sharedItems.site" />}
        />
        <div style={{ marginTop: "15px" }}>
          <ButtonSecondary
            onClick={() => clearValues()}
            name={<FormattedMessage id="sharedItems.clear" />}
            width="120px"
          />
          <ButtonDefault
            onClick={() => applyFilter()}
            ml="20px"
            name={<FormattedMessage id="sharedItems.apply" />}
            width="164px"
          />
        </div>
      </RowActionsFilter>
    </React.Fragment>
  );
};
