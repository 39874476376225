import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { Creators as managerActions } from "store/ducks/manager";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import Load from "components/Layout/Load";
import {
  ButtonDefault,
  MultSelect,
  SelectInput,
} from "components/shared/CustomInputs";
import { connect } from "react-redux";
import BreadCrumb from "components/Layout/breadcrumb";
import { Body } from "./UserDetailStyle.js";
import { Creators as candidateActions } from "../../../../store/ducks/candidates.js";

const UserDetail = ({
  match,
  getProfilesList,
  profilesList,
  location,
  getUsersId,
  currentUser,
  requestInputValues,
  changeUser,
  isLoading,
  requestRmSecaoListAll,
  informationRmSecaoListRequest,
  informacoesCandidatoColigadaRequest,
  coligadaCandidato,
}) => {
  const [formValues, setFormValues] = useState({
    profileId: 0,
    nome: "",
    placeOfWork: [],
    value: "",
    label: "",
    codSecao: "",
    projetosIds: [],
    coligadaCandidato: "",
  });

  const [filteredColigadaOptions, setFilteredColigadaOptions] = useState([]);
  const infoCurrentUser = useRef();
  const currentColigada = useRef(formValues.coligadaCandidato);
  const { params } = match;
  const [codSecaoOptions, setCodSecaoOptions] = useState([]);
  const [profilesListOptions, setProfilesListOptions] = useState([]);

  useEffect(() => {
    informacoesCandidatoColigadaRequest();
    getProfilesList();
    getUsersId(params.id);
  }, []);

  useEffect(() => {
    if (profilesList) {
      const options = profilesList.map((e) => ({
        value: e.id,
        label: e.nome,
      }));
      setProfilesListOptions(options);
    }
  }, [profilesList]);

  useEffect(() => {
    if (requestRmSecaoListAll && requestRmSecaoListAll.length > 0) {
      const options = requestRmSecaoListAll.map((e) => ({
        value: e.id,
        label: e.nome,
      }));
      setCodSecaoOptions(options);
    }
  }, [requestRmSecaoListAll]);

  useEffect(() => {
    requestInputValues(["projetosIds"]);
    if (currentUser) infoCurrentUser.current = currentUser;

      if (currentUser) {
        setFormValues((prevState) => ({
          ...prevState,
          profileId: currentUser.perfilAcesso_Id,
          nome: currentUser.nome,
          projetosIds: currentUser.projetoId || [],
          coligadaCandidato: currentUser.coligadaId.map((item) => parseInt(item, 10)) || []
        }));
      }
    }, [currentUser]);


  useEffect(() => {
    console.log("lista das coligadas referente ao admin", formValues.coligadaCandidato)
    if (formValues.coligadaCandidato) {
      informationRmSecaoListRequest(formValues.coligadaCandidato);
    }
  }, [formValues.coligadaCandidato]);


  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={
          <FormattedMessage
            id={"Alterando Usuario: " + infoCurrentUser.current?.nome}
          />
        }
      />
      <Body>
        <Row>
          <Col md={4}>
            <SelectInput
              options={profilesListOptions}
              value={
                profilesListOptions?.find(
                  (e) => e.value === formValues.profileId
                ) || ""
              }
              onChange={(e) => {
                const newProfileId = e.value;

                setFormValues({
                  ...formValues,
                  profileId: newProfileId,
                  coligadaCandidato: [],
                  projetosIds: [],
                });
              }}
              label={"Perfil"}
            />
          </Col>
          <Col lg={4}>
            <MultSelect
              mb="1"
              value={coligadaCandidato.filter((e) =>
                formValues.coligadaCandidato.includes(e.id)
              )
              .map((e) => ({
                value: e.id,
                label: e.nome,
              }))}
              isMulti
              options={coligadaCandidato.map((e) => ({
                value: e.id,
                label: e.nome,
              }))}
              label={<FormattedMessage id="sharedItems.operation" />}
              onChange={(selectedOptions) => {
                const newValues = selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : [];

                if (formValues.coligadaCandidato.length > newValues.length) {
                  setFormValues((prevState) => ({
                    ...prevState,
                    coligadaCandidato: newValues,
                    projetosIds: [],
                  }));
                } else {
                  setFormValues({
                    ...formValues,
                    coligadaCandidato: newValues,
                  });
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} style={{ paddingTop: "15px" }}>
            <ButtonDefault
              onClick={() => {
                changeUser({
                  user_Id: params.id,
                  perfilAcesso_Id: formValues.profileId,
                  projetoId: formValues.projetosIds,
                  coligadaId: formValues.coligadaCandidato,
                });
              }}
              name={"Salvar"}
              width="223px"
            />
          </Col>
        </Row>
      </Body>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  profilesList: state.profiles.profiles,
  currentUser: state.profiles.currentUser,
  placesOfWork: state.formInputs.placeOfWork,
  isLoading: state.profiles.isLoading,
  candidate: state.candidates.candidate,
  requestRmSecaoListAll: state.profiles.informationSecao,
  coligadaCandidato: state.candidates.coligadaCandidato,
});

const mapDispatchToProps = (dispatch) => ({
  requestInputValues: (inputs) =>
    dispatch(formInputsActions.formInputsRequest(inputs)),
  getProfilesList: (payload) =>
    dispatch(managerActions.profilesRequest(payload)),
  getUsersId: (id) => dispatch(managerActions.requestUsersId(id)),
  changeUser: (params) => dispatch(managerActions.changeUserRequest(params)),
  informationRmSecaoListRequest: (payload) =>
    dispatch(managerActions.informationRmSecaoListRequest(payload)),
  informacoesCandidatoColigadaRequest: () =>
    dispatch(candidateActions.informacoesCandidatoColigadaPerfilRequest()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UserDetail));
