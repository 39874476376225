import styled from 'styled-components';
import theme from '../../shared/Theme';

export const Body = styled.div`
  padding: 20px;
`;
export const CardBody = styled.div`
  border-radius: 8px;
  border: solid 1px ${theme.colors.darkGray};
  height: 140px;
`;

export const RowCard = styled.div`
  align-items: center;
  height: 100%;
  padding: 20px;
`;

export const Icon = styled.div`
  margin-top: -20px;
`;

export const IconLabel = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top:5px;
  color: ${theme.colors.softGray};
`;

export const IconBox = styled.div`
  width:100%;
  display:flex;
  flex-flow:column;
  align-items:center;
  &:hover{
    cursor: pointer;
  }
`;
