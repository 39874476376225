import React from 'react';
import { Modal } from 'react-bootstrap';
import { LabelH1 } from 'components/shared/CustomComponents';
import { ButtonSecondary } from 'components/shared/CustomInputs';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  RowBodyHeader, BoxItem, SpanValue, LabelItem,
} from './DialogDescriptioStageStyle';

const labels = {
  test: <FormattedMessage id="rails.trainnerKind" />,
  medic: <FormattedMessage id="rails.exameKind" />,
  trainner: <FormattedMessage id="rails.trainner" />,
  assay: <FormattedMessage id="rails.trainner" />,
  admition: <FormattedMessage id="rails.admition" />,
  ambientation: <FormattedMessage id="rails.ambientationKind" />,
  feedBack: <FormattedMessage id="rails.feedBack" />,
};

export default function DialogDescriptionStage({
  show,
  kindItem,
  name,
  description,
  closeDialog,
  kindValue,
}) {
  return (
    <Modal size="xl" show={show}>
      <Modal.Header>
        <LabelH1 ml="30px">
          <FormattedMessage id="rails.stageDetails" />
        </LabelH1>
      </Modal.Header>
      <Modal.Body>
        <RowBodyHeader>
          <BoxItem>
            <LabelItem>
              <FormattedMessage id="sharedItems.name" />
            </LabelItem>
            <SpanValue>{name}</SpanValue>
          </BoxItem>
          {kindValue && (
            <BoxItem>
              <LabelItem>{labels[kindItem]}</LabelItem>
              <SpanValue>{kindValue}</SpanValue>
            </BoxItem>
          )}
        </RowBodyHeader>
        <RowBodyHeader>{description && <SpanValue>{description}</SpanValue>}</RowBodyHeader>
        <RowBodyHeader center>
          <ButtonSecondary
            onClick={() => closeDialog()}
            width="180px"
            name={<FormattedMessage id="sharedItems.close" />}
          />
        </RowBodyHeader>
      </Modal.Body>
    </Modal>
  );
}

DialogDescriptionStage.propTypes = {
  show: PropTypes.bool,
  kindItem: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  closeDialog: PropTypes.func,
};

DialogDescriptionStage.defaultProps = {
  show: false,
  kindItem: '',
  name: '',
  description: '',
  closeDialog: '',
};
