import {
  CheckBox,
  MultSelect,
  RichTextInput,
  SelectFieldInput,
  TextField
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";

import CurrencyFormat from "react-currency-format";
import FormGroup from "../group/FormGroup";
import InputMask from "react-input-mask";
import React, { useEffect, useState } from "react";
import Schedules from "../Schedules";
import { required } from "../../../helpers/validations";
import { getJobDescription } from "../../../helpers/sharedHelpers";

const ExternalExistingVacancy = ({
  inputsItems,
  formValues,
  setFormValues,
  setFormErros,
  getManagerByName,
  formValidators,
  formErros,
  submited,
  setSchedulesItems,
  schedules,
  requestResultCenterByAffiliate,
  onSelectArea,
  intl
}) => {
  const [manager, setSearchManager] = useState({name:'', id:''});

  const searchManagerName = e => {

    const delayDebounceFn = setTimeout(() => {
      if (e.length>=4) {
        getManagerByName({name:e, id:''});
      }
    }, 800);



     return e;

  };


  return (
    <React.Fragment>
      <FormGroup
        formName={<FormattedMessage id="sharedItems.formVacancyInformation" />}
      >
        <Row>
          <Col lg={3}>
            <SelectFieldInput
              value={formValues.area}
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              name={intl.formatMessage({ id: "inputs.area" })}
              formErr={formErros}
              items={inputsItems.descricao}
              label={<FormattedMessage id="inputs.area" />}
              onChange={e => onSelectArea(e.target.value)}
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.job" })}
              items={inputsItems.jobs}
              value={formValues.job}
              label={<FormattedMessage id={getJobDescription(formValues.job, inputsItems.jobs)}> </FormattedMessage>}
              onChange={e =>
                setFormValues({ ...formValues, job: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <CurrencyFormat
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              name={intl.formatMessage({ id: "inputs.salary" })}
              validations={[required]}
              value={formValues.salary}
              label={<FormattedMessage id="inputs.salary" />}
              prefix="R$"
              allowNegative={false}
              onChange={e =>
                setFormValues({ ...formValues, salary: e.target.value })
              }
              customInput={TextField}
            />
          </Col>
          <Col lg={3} style={{ paddingTop: "16px" }}>
            <CheckBox
              type="checkbox"
              checked={formValues.showSalary}
              label={<FormattedMessage id="inputs.showSalary" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  showSalary: !formValues.showSalary
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <TextField
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.vacancys}
              name={intl.formatMessage({ id: "menuTalentBar.vacancy" })}
              label={<FormattedMessage id="menuTalentBar.vacancy" />}
              onChange={e =>
                setFormValues({ ...formValues, vacancys: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.placeOfWork" })}
              items={inputsItems.placeOfWork}
              value={formValues.placeOfWork}
              label={<FormattedMessage id="inputs.placeOfWork" />}
              onChange={e =>
                setFormValues({ ...formValues, placeOfWork: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.journey" })}
              items={inputsItems.jorney}
              value={formValues.jorney}
              label={<FormattedMessage id="inputs.journey" />}
              onChange={e =>
                setFormValues({ ...formValues, jorney: e.target.value })
              }
            />
          </Col>
          <Col lg={3} style={{ paddingTop: "16px" }}>
            <CheckBox
              type="checkbox"
              checked={formValues.informeSalary}
              label={<FormattedMessage id="inputs.informeSalary" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  informeSalary: !formValues.informeSalary
                })
              }
            />
          </Col>
          <Col lg={3}>
            <InputMask
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.startWork" })}
              value={formValues.startWork}
              validations={[required]}
              label={<FormattedMessage id="inputs.startWork" />}
              onChange={e =>
                setFormValues({ ...formValues, startWork: e.target.value })
              }
              mask="99/99/9999"
            >
              {inputProps => <TextField {...inputProps} />}
            </InputMask>
          </Col>
          <Col lg={3} style={{ paddingTop: "16px" }}>
            <CheckBox
              type="checkbox"
              checked={formValues.confidentialVacancy}
              label={<FormattedMessage id="inputs.confidentialVacancy" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  confidentialVacancy: !formValues.confidentialVacancy
                })
              }

            />
          </Col>
          {formValues.area == "1" &&
            (
              <Col lg={3} style={{ paddingTop: "16px" }}>
                <TextField
                  setFormErros={setFormErros}
                  formValidators={formValidators}
                  touched={submited}
                  formErr={formErros}
                  value={formValues.numberSMP}
                  type="number"
                  name={intl.formatMessage({ id: "inputs.numberSMP" })}
                  label={<FormattedMessage id="inputs.numberSMP" />}
                  onChange={e =>
                    setFormValues({ ...formValues, numberSMP: e.target.value })
                  }
                />
              </Col>
            )}

        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.vacancyReq" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.vacancyReq}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.vacancyReq" })}
              onChange={e => setFormValues({ ...formValues, vacancyReq: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.responsibility" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.responsibility}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.responsibility" })}
              onChange={e =>
                setFormValues({ ...formValues, responsibility: e })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.aboutWork" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.aboutWork}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.aboutWork" })}
              onChange={e => setFormValues({ ...formValues, aboutWork: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.skills" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.skills}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.skills" })}
              onChange={e => setFormValues({ ...formValues, skills: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.benefits" })}
              value={formValues.benefits}
              options={inputsItems.benefits.map(e => ({
                label: e.nome,
                value: e.id
              }))}
              label={<FormattedMessage id="inputs.benefits" />}
              isMulti
              onChange={e =>
                setFormValues({
                  ...formValues,
                  benefits: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup formName={<FormattedMessage id="sharedItems.formCandidate" />}>
        <Row>
          <Col lg={4}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.schooling" })}
              validations={[required]}
              items={inputsItems.schooling}
              value={formValues.schooling}
              label={<FormattedMessage id="inputs.schooling" />}
              onChange={e =>
                setFormValues({ ...formValues, schooling: e.target.value })
              }
            />
          </Col>
          <Col lg={4}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.areaFormation" })}
              value={formValues.areaFormation}
              options={inputsItems.areaFormation.map(e => ({
                label: e.descricao,
                value: e.id
              }))}
              label={<FormattedMessage id="inputs.areaFormation" />}
              isMulti
              onChange={e =>
                setFormValues({
                  ...formValues,
                  areaFormation: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
          <Col lg={4}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.competence" })}
              value={formValues.competence}
              options={inputsItems.competence.map(e => ({
                label: e.nome,
                value: e.id
              }))}
              isMulti
              label={<FormattedMessage id="inputs.competence" />}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  competence: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.tecnicKnowledge" })}
              value={formValues.tecnicKnowledge}
              options={inputsItems.tecnicKnowledge.map(e => ({
                label: e.resumo,
                value: e.id
              }))}
              label={<FormattedMessage id="inputs.tecnicKnowledge" />}
              isMulti
              onChange={e =>
                setFormValues({
                  ...formValues,
                  tecnicKnowledge: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <CheckBox
              type="checkbox"
              checked={formValues.deficiency}
              label={<FormattedMessage id="inputs.deficiency" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  deficiency: !formValues.deficiency
                })
              }
            />
          </Col>
        </Row>
        <hr />
      </FormGroup>
      <FormGroup
        formName={<FormattedMessage id="sharedItems.formTimeOfWork" />}
      >
        <Schedules
          addSchudule={setSchedulesItems}
          formSchedules={schedules}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </FormGroup>
      <FormGroup formName={<FormattedMessage id="sharedItems.formInternal" />}>
        <Row>
          <Col lg={3}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.affiliate" })}
              validations={[required]}
              items={inputsItems.affiliate}
              value={formValues.affiliate}
              label={<FormattedMessage id="inputs.affiliate" />}
              onChange={e => {
                setFormValues({ ...formValues, affiliate: e.target.value });
                requestResultCenterByAffiliate(e.target.value);
              }}
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.resultCenter" })}
              validations={[required]}
              items={inputsItems.resultCenter}
              value={formValues.resultCenter}
              label={<FormattedMessage id="inputs.resultCenter" />}
              onChange={e =>
                setFormValues({ ...formValues, resultCenter: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.section" })}
              validations={[required]}
              items={inputsItems.section}
              value={formValues.section}
              label={<FormattedMessage id="inputs.section" />}
              onChange={e =>
                setFormValues({ ...formValues, section: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.taker" })}
              validations={[required]}
              items={inputsItems.taker}
              value={formValues.taker}
              label={<FormattedMessage id="inputs.taker" />}
              onChange={e =>
                setFormValues({ ...formValues, taker: e.target.value })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={10}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              onInputChange={e =>searchManagerName(e)}
              name={intl.formatMessage({ id: "inputs.managerName" })}
              defaultValue={formValues.managerName}
              options={inputsItems.managerName}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  managerName: e.value,
                  managerCode: e.value,
                })
              }
              label={<FormattedMessage id="inputs.managerName" />}
            />
          </Col>
          <Col lg={2}>
            <TextField
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              disabled
              name={intl.formatMessage({ id: "inputs.managerCode" })}
              label={<FormattedMessage id="inputs.managerCode" />}
              value={formValues.managerCode}
              type="number"
              onChange={e =>
                setFormValues({ ...formValues, managerCode: e.target.value })
              }
            />
          </Col>
        </Row>
      </FormGroup>
    </React.Fragment>
  );
};
ExternalExistingVacancy.propTypes = {};

ExternalExistingVacancy.defaultProps = {};
export default injectIntl(ExternalExistingVacancy);
