import styled from "styled-components";
import theme from "../shared/Theme";
import Background from "../../assets/backgroud/Backgroud.vinci.jpg";
export const FormStyle = styled.div`
border-radius: 5px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px 0px;
background-color: white;
max-width: 350px;
min-width: 350px;
padding: 30px;
height: 326px;

`;
export const CodeRow = styled.div`
  display: ${props => (props.flex ? props.flex : "flex")};
  align-items: center;
  justify-content: ${props => (props.flexEnd ? "flex-end" : "space-between")};
  width: 100%;
  margin-top: 1em;
`;
export const FormGroupStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  width: 100%;
`;

export const LabelBottom = styled.div`
  font-family: Arial;
  font-size: 16px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  &:hover {
    cursor: pointer;
  }
  color: ${theme.colors.defaultColor};
  text-decoration: none;
  margin-bottom: 20px;
`;
export const LabelFormHeader = styled.h3`
  font-family: Arial;
  font-size: 24px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.titleColor};
  margin-bottom: 20px;
`;
export const Label = styled.div`
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.colors.colorLabelDefault};
  margin-bottom: 20px;
`;
export const FormRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  width: 100%;
`;

export const BodyBackground = styled.div`
display: flex;
align-items: center;
flex-flow: column;
justify-content: center;
height: 100vh;
background-image: url(${Background});
background-position: center;
background-repeat: no-repeat;`;


export const RowImage = styled.div`
  display: flex;
  justify-content: center;
`;
export const ImageLogin = styled.img`
  object-fit: contain;
  height: 123px;
  width: 160px;
  margin-bottom: 30px;
  margin-top: -100px;
`;
export const ImageLoginMargin = styled.img`
  object-fit: contain;
  height: 123px;
  width: 160px;
  margin-bottom: 30px;
  margin-top: -60px;
`;
export const InputRow = styled.div`
  width: 100%;
`;
