import { Col, Container, Row } from "react-bootstrap";
import {
  CustomFieldSearch,
  CustomFieldSelectSearch
} from "../../shared/CustomInputs";
import React, { useEffect, useState } from "react";

import { filterIcon } from "../../../assets/icons/icons";
import { injectIntl } from "react-intl";

const FilterStage = ({ intl, options, kindFilter, onChangeFilter, name }) => {
  const [search, setSearch] = useState("");
  const [type, setType] = useState();
  const [level, setLevel] = useState();

  useEffect(() => {
    setSearch("");
    setType(null);
    setLevel(null);
  }, [kindFilter]);

  const onChangeSearch = e => {
    setSearch(e.target.value);
    onChangeFilter && onChangeFilter({ search: e.target.value, type, level });
  };

  const onChangeType = e => {
    if (Number(e.target.value)) {
      setType(e.target.value);
      onChangeFilter && onChangeFilter({ search, type: e.target.value, level });
    } else {
      setType(null);
      onChangeFilter && onChangeFilter({ search, type: null, level });
    }
  };

  const onChangeLevel = e => {
    if (Number(e.target.value)) {
      setLevel(e.target.value);
      onChangeFilter && onChangeFilter({ search, type, level: e.target.value });
    } else {
      setLevel(null);
      onChangeFilter && onChangeFilter({ search, type, level: null });
    }
  };

  const getTypeOptions = () => {
    switch (kindFilter) {
      case "testeInfo":
        return options && options[kindFilter]
          ? options[kindFilter].tipoTeste
          : [];

      case "trainnerInfo":
        return options && options[kindFilter]
          ? options[kindFilter].tipoTreinamento
          : [];

      case "assayInfo":
        return options && options[kindFilter]
          ? options[kindFilter].tipoAnalise
          : [];

      case "medicExameInfo":
        return options && options[kindFilter]
          ? options[kindFilter].tipoAnalise
          : [];

      case "ambientationInfo":
        return options && options[kindFilter]
          ? options[kindFilter].tipoAnalise
          : [];

      default:
        return [];
    }
  };

  const renderTypeSelect = () => {
    if (kindFilter) {
      const items = getTypeOptions();
      return (
        <CustomFieldSelectSearch
          value={type}
          items={items}
          onChange={e => onChangeType(e)}
          width="209"
          placeholder={intl.formatMessage({
            id: "sharedItems.name"
          })}
        />
      );
    }
    return null;
  };

  const renderLevelSelect = () => {
    if (kindFilter == "testeInfo") {
      const items =
        options && options[kindFilter] ? options[kindFilter].nivelTeste : [];
      return (
        <CustomFieldSelectSearch
          value={level}
          items={items}
          onChange={e => onChangeLevel(e)}
          width="209"
          placeholder={intl.formatMessage({
            id: "sharedItems.level"
          })}
        />
      );
    }
    return null;
  };

  return (
    <Container fluid>
      <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
        <div style={{ marginTop: "5px", marginLeft: "33px" }}>
          {filterIcon()}
        </div>
        <Col lg={3}>
          <CustomFieldSearch
            value={search}
            onChange={e => onChangeSearch(e)}
            width="209"
            placeholder={intl.formatMessage({
              id: name ? name : "sharedItems.name"
            })}
          />
        </Col>
        <Col lg={4}>{renderTypeSelect()}</Col>
        <Col lg={3}>{renderLevelSelect()}</Col>
      </Row>
    </Container>
  );
};

export default injectIntl(FilterStage);
