import {
  BoxImages,
  DisclaimerQuestion,
  Info,
  RowImage
} from "./DialogSharedStyle";
import {
  ButtonDefault,
  ButtonSecondary,
  TextField
} from "components/shared/CustomInputs";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import React, { useState } from "react";

import Facebook from "../../../assets/images/facebook.png";
import { FormattedMessage } from "react-intl";
import { LabelH1 } from "components/shared/CustomComponents";
import LinkEdin from "../../../assets/images/linkedin.png";
import { Modal } from "react-bootstrap";
import { PREVIEW_PAGE } from "../../../utils/contants/urls";
import PropTypes from "prop-types";
import Twitter from "../../../assets/images/twitter.png";
import Whatsapp from "../../../assets/images/whatsapp.png";

export default function DialogShared({
  show,
  closeDialog,
  openPreview,
  slug
}) {
  const [selectedImage, setSelectImage] = useState();
  return (
    <Modal size="lg" show={show}>
      <Modal.Header>
        <LabelH1 ml="15px" fz="24px">
          <FormattedMessage id="vacancyCreate.sharedVacancy" />
        </LabelH1>
      </Modal.Header>
      <Modal.Body>
        <DisclaimerQuestion>
          <FormattedMessage id="vacancyCreate.sharedDisclaimer" />
        </DisclaimerQuestion>
        <TextField
          label={<FormattedMessage id="vacancyCreate.linkVacancy" />}
          value={`${PREVIEW_PAGE}${slug || ""}`}
        />
        <BoxImages>
          <Info>
            <FormattedMessage id="vacancyCreate.socialMedia" />
          </Info>
          <RowImage>
            <FacebookShareButton url={`${PREVIEW_PAGE}${slug}`}>
              <img src={Facebook} />
            </FacebookShareButton>
            <WhatsappShareButton url={`${PREVIEW_PAGE}${slug}`}>
              <img src={Whatsapp} />
            </WhatsappShareButton>
            <TwitterShareButton url={`${PREVIEW_PAGE}${slug}`}>
              <img src={Twitter} />
            </TwitterShareButton>
            <LinkedinShareButton url={`${PREVIEW_PAGE}${slug}`}>
              <img src={LinkEdin} />
            </LinkedinShareButton>
          </RowImage>
        </BoxImages>
      </Modal.Body>
      <Modal.Footer>
        <ButtonDefault
          name={<FormattedMessage id="sharedItems.close" />}
          onClick={() => openPreview(selectedImage)}
          width="150px"
        />
      </Modal.Footer>
    </Modal>
  );
}
DialogShared.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func,
  closeDialog: PropTypes.func
};

DialogShared.defaultProps = {
  show: false,
  action: () => {},
  closeDialog: () => {}
};
