import * as urls from "utils/contants/urls";

import { Icon } from "react-icons-kit";
import { barChart } from "react-icons-kit/fa/barChart";
import { ic_content_paste_outline } from "react-icons-kit/md/ic_content_paste_outline";
import { ic_supervisor_account } from "react-icons-kit/md/ic_supervisor_account";
import { ic_topic_outline } from "react-icons-kit/md/ic_topic_outline";
import { ic_work_twotone } from "react-icons-kit/md/ic_work_twotone";
import { ic_send_outline } from "react-icons-kit/md/ic_send_outline";
import { ticket } from "react-icons-kit/icomoon/ticket";
import { creditCard } from "react-icons-kit/icomoon/creditCard";

import { Creators as authActions } from "store/ducks/auth";

import { home } from "assets/icons/icons";
import React, { useEffect, useState } from "react";
import { ItemMenu, LabelItem, MenuBody } from "./MenuStyle";

import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Creators as railsAction } from "store/ducks/rails";
import {
  INITIAL_STATE as STAGE_INITIAL_STATE,
  Creators as stageAction,
} from "store/ducks/stages";
import { Creators as vacancyAction } from "store/ducks/vacancy";
import { Creators as vacancyAssociationAction } from "store/ducks/vacancyAssociation";
import theme from "../../../../components/shared/Theme";
import { ic_question_answer_outline } from "react-icons-kit/md/ic_question_answer_outline";
import { ic_announcement_outline } from "react-icons-kit/md/ic_announcement_outline";

const Menu = ({ goTo, onRequestAuth, getUserAuthData, userInformations }) => {
  const [selected, setSelected] = useState({
    dashboard: true,
    home: false,
    vacancy: true,
    steps: false,
    candidates: false,
    rails: false,
    managers: false,
    modules: false,
    contracts: false,
    pendingSignature: false,
    doubts: false,
    doubtsManager: false,
    enviarDocumnts: false,
    benefits: false,
    payments: false,
  });

  const updateRouter = (newFocus) => {
    setSelected({ ...newFocus });
    if (newFocus.dashboard) goTo(urls.PLUG_AEC);
    if (newFocus.modules) goTo(urls.APP);
    if (newFocus.candidates) goTo(urls.PLUG_CANDIDATES_LIST);
    if (newFocus.documents) goTo(urls.DOCUMENTS_CANDIDATES);
    if (newFocus.contracts) goTo(urls.CONTRACTS_LIST);
    if (newFocus.pendingSignature) goTo(urls.PLUG_ASSINATURA_CANDIDATES_LIST);

    if (newFocus.doubts) goTo(urls.DOUBTS_LIST);
    if (newFocus.doubtsManager) goTo(urls.DOUBTS_LIST_MANAGER);
    if (newFocus.enviarDocumnts) goTo(urls.ENVIAR_DOCUMENTS_CANDIDATES);
    if (newFocus.benefits) goTo(urls.CONTRACTS_LIST);
    if (newFocus.payments) goTo(urls.PAYMENTS_LIST);
  };

  const [perfilUsuario, setPerfilUsuario] = useState(0);

  useEffect(() => {
    getUserAuthData();

    var permission = localStorage.getItem("tipoPerfilUsuario");
    if(permission != null){
      setPerfilUsuario(permission);
    }

  }, []);

  return (
    <MenuBody>
      <ItemMenu
        selected={selected.dashboard}
        onClick={() =>
          updateRouter({
            dashboard: true,
            home: false,
            vacancy: false,
            steps: false,
            candidates: false,
            rails: false,
            managers: false,
            modules: false,
            pendingSignature: false,
            enviarDocumnts: false,
            benefits: false,
            payments: false,
            doubtsManager: false,
          })
        }
      >
        <Icon
          style={{
            color: theme.colors.defaultColor,
          }}
          size="1.5em"
          icon={barChart}
        />
        <LabelItem>
          <FormattedMessage id="menuPlugBar.dashboard" />
        </LabelItem>
      </ItemMenu>

      <ItemMenu
        selected={selected.candidates}
        onClick={() =>
          updateRouter({
            candidates: true,
          })
        }
      >
        <Icon
          size={32}
          icon={ic_supervisor_account}
          style={{
            color: theme.colors.defaultColor,
          }}
        />
        <LabelItem>
          <FormattedMessage id="menuPlugBar.candidates" />
        </LabelItem>
      </ItemMenu>

      <ItemMenu
        selected={selected.enviarDocumnts}
        onClick={() =>
          updateRouter({
            enviarDocumnts: true,
          })
        }
      >
        <Icon
          style={{
            color: theme.colors.defaultColor ? theme.colors.defaultColor : "",
          }}
          size="1.5em"
          icon={ic_send_outline}
        />
        <LabelItem>
          <FormattedMessage id="menuPlugBar.enviarDocumentos" />
        </LabelItem>
      </ItemMenu>

      <ItemMenu
        selected={selected.benefits}
        onClick={() =>
          updateRouter({
            benefits: true,
          })
        }
      >
        <Icon
          size={30}
          icon={ticket}
          style={{
            color: theme.colors.defaultColor,
          }}
        />
        <LabelItem>
          <FormattedMessage id="menuPlugBar.benefits" />
        </LabelItem>
      </ItemMenu>

      <ItemMenu
        selected={selected.payments}
        onClick={() =>
          updateRouter({
            payments: true,
          })
        }
      >
        <Icon
          size={30}
          icon={creditCard}
          style={{
            color: theme.colors.defaultColor,
          }}
        />
        <LabelItem>
          <FormattedMessage id="menuPlugBar.payments" />
        </LabelItem>
      </ItemMenu>

      <ItemMenu
        selected={selected.doubts}
        onClick={() =>
          updateRouter({
            doubts: true,
          })
        }
      >
        <Icon
          size={30}
          icon={ic_question_answer_outline}
          style={{
            color: theme.colors.defaultColor,
          }}
        />
        <LabelItem>
          <FormattedMessage id="menuPlugBar.doubts" />
        </LabelItem>
      </ItemMenu>

      {
        perfilUsuario == 1 ? (
          <ItemMenu
            selected={selected.doubtsManager}
            onClick={() =>
              updateRouter({
                doubtsManager: true,
              })
            }
          >
            <Icon
              size={30}
              icon={ic_announcement_outline}
              style={{
                color: theme.colors.defaultColor,
              }}
            />
            <LabelItem>
              <FormattedMessage id="menuPlugBar.doubtsManager" />
            </LabelItem>
          </ItemMenu>
        ) : ("")
      }

      <ItemMenu
        selected={selected.modules}
        onClick={() =>
          updateRouter({
            modules: true,
          })
        }
      >
        {home()}
        <LabelItem>
          <FormattedMessage id="menuManagerBar.modules" />
        </LabelItem>
      </ItemMenu>
    </MenuBody>
  );
};

const MapStateToProps = (state) => ({});
const MapDispatchToProps = (dispatch) => ({
  goTo: (route) => dispatch(push(route)),
  setIsAssociatingRequest: (value) =>
    dispatch(vacancyAssociationAction.setIsAssociatingRequest(value)),
  clearVacancyForm: () => dispatch(vacancyAction.jobStartForm()),
  resetSelectedStage: () =>
    dispatch(stageAction.stageShowSuccess(STAGE_INITIAL_STATE.stage)),
  resetStageId: () => dispatch(stageAction.stageCreateSuccess(undefined)),
  requestCleanRailsState: () => dispatch(railsAction.requestCleanRailsState()),
  requestCleanStageState: () => dispatch(stageAction.requestCleanStageState()),

  getUserAuthData: () => dispatch(authActions.getUserAuthData()),
});

const mapStateToProps = (state) => ({
  isLoading: state.login.isLoading,
  userInformations: state.login.userInformations,
});

export default connect(mapStateToProps, MapDispatchToProps)(Menu);
