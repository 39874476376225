import styled from 'styled-components';
import theme from 'components/shared/Theme';

export const Body = styled.div`
  background-color: ${theme.colors.whiteDefault};
  box-shadow: 0 0 5px 0 rgba(209, 209, 209, 0.5);
  border: solid 1px #ededed;
  border-radius: 5px;
  padding: 30px;
`;

export const RowHeader = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

export const RowCheckItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  align-items: center;
`;

export const ActionsItems = styled.div`
  align-items: flex-end;
  display: flex;
`;

export const Icon = styled.div`
  margin-left: 20px;
`;
