import * as urls from "utils/contants/urls";

import Header from "components/Layout/header/Header";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router";
import React, { Fragment, PureComponent } from "react";
import MessageHandler from "components/MessageHandler";
import { connect } from "react-redux";
import { Body, DashBoardBody, Row } from "./DashBoardPlugStyle";

import DashBoardPlugMain from "../DashBoardPlugMain";

import ListCandidates from "../Candidates/ListCandidates";
import NewCandidate from "../Candidates/NewCandidate";
import ListDocumentsCandidates from "../Documents/ListDocumentsCandidates";
import EditCandidate from "../Candidates/EditCandidate";
import ListDocuments from "../Documents/ListDocuments";

import ListBenefitsCandidates from "../Benefits/ListBenefitsCandidates/ListBenefitsCandidates";
import DetailBenefitsCandidate from "../Benefits/DetailBenefitsCandidate/DetailBenefitsCandidate";
import ManagerCandidatesBenefits from "..//Benefits/ManagerCandidatesBenefits/ManagerCandidatesBenefits";
import UploadDocuments from "../EnviarDocuments/ListUploadDocuments";
import UploadDocumentsSignature from "../EnviarDocuments/UploadDocumentsSignature";
import ListAssinaturaPendente from "../AssinaturaPendente/ListAssinaturaPendente";
import DigitalSignatureDocuments from "../AssinaturaPendente/DigitalSignatureDocuments"
import ListDoubtsCandidates from "../Doubts/ListDoubtsCandidates/ListDoubtsCandidates";
import EnviarDocumentsCandidates from "../EnviarDocuments/EnviarDocumentsCandidates/EnviarDocumentsCandidates";

import ListPaymentsCandidates from "../Payments/ListPaymentsCandidates/ListPaymentsCandidates"
import DetailPaymentCandidate from "../Payments/DetailPaymentCandidate/DetailPaymentCandidate";
import ManagerPaymentsContract from "../Payments/ManagerPaymentContract/ManagerPaymentsContract";
import ListDoubtsCandidatesManager from "../DoubtsManager/ListDoubtsCandidatesManager/ListDoubtsCandidatesManager"

import Menu from "../Menu/Menu";
class DashBoardPlug extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <Fragment>
        <Header location={history} />
        <DashBoardBody>
          <Row>
            <Menu />
            <Body>
              <ConnectedRouter history={history}>
                <Switch>
                  <Route exact path={urls.PLUG_AEC} component={DashBoardPlugMain} />
                  <Route exact path={urls.DOUBTS_LIST} component={ListDoubtsCandidates} /> 
                  <Route exact path={urls.ENVIAR_DOCUMENTS_CANDIDATES} component={EnviarDocumentsCandidates} />
                  <Route exact path={urls.PLUG_NEW_CANDIDATE} component={NewCandidate} />
                  <Route exact path={urls.PLUG_CANDIDATES_LIST} component={ListCandidates} />
                  <Route exact path={urls.PAYMENTS_MANEGER} component={ManagerPaymentsContract} />
                  <Route exact path={`${urls.PAYMENTS_CANDIDATE}/:id`} component={DetailPaymentCandidate} />
                  <Route exact path={urls.PAYMENTS_LIST} component={ListPaymentsCandidates} />
                  <Route exact path={`${urls.PLUG_EDIT_CANDIDATE}/:id`} component={EditCandidate} />
                  <Route exact path={urls.DOCUMENTS_CANDIDATES} component={ListDocumentsCandidates} />
                  <Route exact path={urls.DOUBTS_LIST_MANAGER} component={ListDoubtsCandidatesManager} />
                  <Route exact path={`${urls.DOCUMENTS_LIST}/:id`} component={ListDocuments} />
                  <Route exact path={urls.CONTRACTS_LIST} component={ListBenefitsCandidates} />
                  <Route exact path={`${urls.CONTRACT_CANDIDATE}/:id`} component={DetailBenefitsCandidate} />
                  <Route exact path={`${urls.CONTRACT_MANAGER}`} component={ManagerCandidatesBenefits} />
                  <Route exact path={urls.PLUG_ASSINATURA_CANDIDATES_LIST} component={ListAssinaturaPendente} />
                  <Route exact path={`${urls.PLUG_DIGITAL_SIGNATURE_DOCUMENTS}/:id`} component={DigitalSignatureDocuments} />
                  <Route exact path={`${urls.UPLOAD_DOCUMENTS}/:id`}  component={UploadDocuments} /> 
                  <Route exact path={`${urls.UPLOAD_DOCUMENTS_SIGNATURE}/:id`}  component={UploadDocumentsSignature} />
                </Switch>
              </ConnectedRouter>
            </Body>
          </Row>
        </DashBoardBody>
        <MessageHandler />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  location: state.history
});
export default connect(mapStateToProps)(DashBoardPlug);
