import React, { useState, useRef, useEffect } from "react";

import { dashboardUrls } from "../../../../utils/contants/urlsApi";

import {
  MainFrameDash,
  NotificationArea,
  Header,
  Nav,
  ButtonNav,
  CardsArea,
  CardData,
  GenericBox,
} from "./DashBoardPlugMainStyle";

import DynamicDash from "../../../../components/DynamicDash";

import { months } from "../../../../locale/";

import Chart from "chart.js/auto";

import { Icon } from "react-icons-kit";
import { gear } from "react-icons-kit/fa/gear";

import { connect } from "react-redux";

import theme from "../../../../components/shared/Theme";

import { Button } from "../../../../components/shared/CustomInputs";

const DashBoardPlugMain = (props) => {
  const name = window.localStorage.getItem("name").split(" ")[0];

  let colorstemp = [];
  colorstemp.length = 100;
  colorstemp.fill(0);
  colorstemp = colorstemp.map((item) => {
    const color = "0123456789ABCDEF"
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("")
      .substring(0, 6);
    const rand = (frm, to) => {
      return ~~(Math.random() * (to - frm)) + frm;
    };
    return `rgba(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)}, 0.3)`;
    //return `rgba(${parseInt(color.slice(0, 2), 16)},${parseInt(color.slice(2, 4), 16)},${parseInt(color.slice(4, 6), 16)}, 0.3)`;
  });

  const defaultColors = useRef();
  defaultColors.current = defaultColors.current
    ? defaultColors.current
    : colorstemp;

  const colors = defaultColors.current;

  const [view, setView] = useState(0);
  const [dashBase, setDashBase] = useState(null);
  const [dashData, setDashData] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [notificationData, setNotificationData] = useState();

  const tabFilter = useRef(0);
  tabFilter.current = tabFilter.current === 0 ? 0 : tabFilter.current;

  const tabsView = useRef([
    {
      label: "Documentação",
      data: [
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--green)",
            icon: "ic_person_add_alt_1",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--red)",
            icon: "ic_person_remove",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--teal)",
            icon: "ic_thumb_up_alt",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--orange)",
            icon: "ic_event_note",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--yellow)",
            icon: "ic_sync_problem",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "chartcard",
          title: "Status dos documentos por site",
          style: {
            column: `merge-2`,
            row: "merge-row-0",
          },
          customStyle: {
            color: theme.colors.defaultColor,
            height: "auto",
          },
          data: "",
        },
      ],
    },
    {
      label: "Contratos",
      data: [
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--green)",
            icon: "ic_person_add_alt_1",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--warning)",
            icon: "ic_alarm",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--indigo)",
            icon: "ic_edit",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--purple)",
            icon: "ic_person_remove",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--orange)",
            icon: "ic_not_listed_location_outline",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--red)",
            icon: "ic_person_remove",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--teal)",
            icon: "ic_thumb_up_alt",
          },
          data: "-",
        },
        {
          id: new Date().getTime(),
          type: "chartcard",
          title: "Contratos por site",
          style: {
            column: `merge-2`,
            row: "merge-row-0",
          },
          customStyle: {
            color: theme.colors.defaultColor,
            height: "auto",
          },
          data: "",
        },
      ],
    },
  ]);

  const getNotificaton = (_data) => {
    let notification = null;

    if (_data)
      notification = (
        <div className={`${_data.icon}`}>
          <div className="icon"></div>
          <div className="content">{_data.content}</div>
          <div className="action">
            {_data.action ? (
              <div className="button-notification">{_data.action.name}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      );

    if (notification) {
      setNotificationData(notification);
    }
  };

  const alterView = (_data) => {
    setView(_data);
  };

  const getDataDash = (_json) => {
    if (!_json) {
      setDashData({
        data: [],
        base: _json,
        date: _json?.dateTimeExpiration ? _json.dateTimeExpiration : null,
        lastupdate: _json?.tempoRestanteCacheMinutos
          ? ~~_json?.tempoRestanteCacheMinutos
          : null,
      });
      return [];
    }

    const json =
      filterList?.length > 0
        ? _json[filterList[tabFilter.current]?.value]
        : _json;

    let result = tabsView.current[~~view].data;

    if (!result) {
      return [];
    }

    let objStatus = {};

    const colorTemp = [
      "rgba(34, 167, 240, 0.6)",
      "rgba(249, 179, 47, 0.6)",
      "rgba(262, 220, 129, 0.6)",
      "rgba(171, 105, 198, 0.6)",
      "rgba(55, 219, 208, 0.6)",
      "rgba(215, 60, 44, 0.6)",
      "rgba(165, 181, 182, 0.6)",
      "rgba(212, 162, 129, 0.6)",
      "rgba(227, 114, 75, 0.6)",
    ];

    switch (view) {
      case 0:
        result[0].data =
          json.totalCandidatosAdmitidos[0]?.totalRegistros?.toString() ||
          result[0].data;
        result[0].title =
          json.totalCandidatosAdmitidos[0]?.nome?.toString() || result[0].nome;
        result[1].data =
          json.totalCandidatosDesistentes[0]?.totalRegistros?.toString() ||
          result[1].data;
        result[1].title =
          json.totalCandidatosDesistentes[0]?.nome?.toString() ||
          result[1].nome;
        result[2].data =
          json.totalDocumentosAprovados[0]?.totalRegistros?.toString() ||
          result[2].data;
        result[2].title =
          json.totalDocumentosAprovados[0]?.nome?.toString() || result[2].nome;
        result[3].data =
          json.totalDocumentosPendenteDeAnalise[0]?.totalRegistros?.toString() ||
          result[3].data;
        result[3].title =
          json.totalDocumentosPendenteDeAnalise[0]?.nome?.toString() ||
          result[3].nome;
        result[4].data =
          json.totalDocumentosPendenteDeEnvio[0]?.totalRegistros?.toString() ||
          result[4].data;
        result[4].title =
          json.totalDocumentosPendenteDeEnvio[0]?.nome?.toString() ||
          result[4].nome;

        json.totalCandidatosPorSiteStatus.map((item) => {
          item.dadosTotaisStatusList.map((it) => {
            if (!objStatus[it.status]) {
              objStatus[it.status] = 0;
            }
            return it;
          });
          return item;
        });

        result[5].data = {
          type: "bar",
          data: {
            labels: [
              json.totalCandidatosAdmitidos[0]?.nome?.toString(),
              json.totalCandidatosDesistentes[0]?.nome?.toString(),
              json.totalDocumentosAprovados[0]?.nome?.toString(),
              json.totalDocumentosPendenteDeAnalise[0]?.nome?.toString(),
              json.totalDocumentosPendenteDeEnvio[0]?.nome?.toString(),
            ],
            datasets: [
              {
                label: "item",
                data: [
                  json.totalCandidatosAdmitidos[0]?.totalRegistros?.toString(),
                  json.totalCandidatosDesistentes[0]?.totalRegistros?.toString(),
                  json.totalDocumentosAprovados[0]?.totalRegistros?.toString(),
                  json.totalDocumentosPendenteDeAnalise[0]?.totalRegistros?.toString(),
                  json.totalDocumentosPendenteDeEnvio[0]?.totalRegistros?.toString(),
                ],
                backgroundColor: [
                  "rgb(40 167 69)",
                  "rgb(220 53 69)",
                  "rgb(32 201 151)",
                  "rgb(253 126 20)",
                  "rgb(255 193 7)",
                  "rgba(153, 102, 255, 0.7)",
                  "rgba(201, 203, 207, 0.7)",
                ],
              },
            ],
          },
          options: {
            indexAxis: "x",
            responsive: true,
            hoverOffset: 1,
            maintainAspectRatio: true,
            aspectRatio: 3.6,
            scales: {
              x: {
                beginAtZero: true,
                stacked: true,
                grid: {
                  offset: true,
                },
              },
              y: {
                stacked: true,
                beginAtZero: true, // Comece o eixo Y a partir de zero
                suggestedMax: 100, // Defina um valor máximo sugerido
                grid: {
                  offset: false,
                },
              },
            },
            plugins: {
              datalabels: {
                color: "#000",
                anchor: "center",
                offset: -20,
                align: "left",
                display: false,
              },
              legend: {
                display: false,
              },
            },
          },
        };

        break;
      case 1:
        result[0].data =
          json.totalDeContratosAprovados[0]?.totalRegistros?.toString() ||
          result[0].data;
        result[0].title =
          json.totalDeContratosAprovados[0]?.nome?.toString() || result[0].nome;
        result[1].data =
          json.totalDeContratosPendentesDeLiberacao[0]?.totalRegistros?.toString() ||
          result[1].data;
        result[1].title =
          json.totalDeContratosPendentesDeLiberacao[0]?.nome?.toString() ||
          result[1].nome;
        result[2].data =
          json.totalDeContratosAguardandoAssinatura[0]?.totalRegistros?.toString() ||
          result[2].data;
        result[2].title =
          json.totalDeContratosAguardandoAssinatura[0]?.nome?.toString() ||
          result[2].nome;
        result[3].data =
          json.totalDeCandidatosDispensados[0]?.totalRegistros?.toString() ||
          result[3].data;
        result[3].title =
          json.totalDeCandidatosDispensados[0]?.nome?.toString() ||
          result[3].nome;
        result[4].data =
          json.totalDeCandidatosDesistentes[0]?.totalRegistros?.toString() ||
          result[4].data;
        result[4].title =
          json.totalDeCandidatosDesistentes[0]?.nome?.toString() ||
          result[4].nome;
        result[5].data =
          json.totalDeContratosReprovados[0]?.totalRegistros?.toString() ||
          result[5].data;
        result[5].title =
          json.totalDeContratosReprovados[0]?.nome?.toString() ||
          result[5].nome;
        result[6].data =
          json.totalDeAdmissoesConcluidas[0]?.totalRegistros?.toString() ||
          result[6].data;
        result[6].title =
          json.totalDeAdmissoesConcluidas[0]?.nome?.toString() ||
          result[6].nome;

        json.totalCandidatosPorSiteStatus.map((item) => {
          item.dadosTotaisStatusList.map((it) => {
            if (!objStatus[it.status]) {
              objStatus[it.status] = 0;
            }
            return it;
          });
          return item;
        });

        result[7].data = {
          type: "bar",
          data: {
            labels: [
              json.totalDeContratosAprovados[0]?.nome?.toString(),
              json.totalDeContratosPendentesDeLiberacao[0]?.nome?.toString(),
              json.totalDeContratosAguardandoAssinatura[0]?.nome?.toString(),
              json.totalDeCandidatosDispensados[0]?.nome?.toString(),
              json.totalDeCandidatosDesistentes[0]?.nome?.toString(),
              json.totalDeContratosReprovados[0]?.nome?.toString(),
              json.totalDeAdmissoesConcluidas[0]?.nome?.toString(),
            ],
            datasets: [
              {
                label: "item",
                data: [
                  json.totalDeContratosAprovados[0]?.totalRegistros?.toString(),
                  json.totalDeContratosPendentesDeLiberacao[0]?.totalRegistros?.toString(),
                  json.totalDeContratosAguardandoAssinatura[0]?.totalRegistros?.toString(),
                  json.totalDeCandidatosDispensados[0]?.totalRegistros?.toString(),
                  json.totalDeCandidatosDesistentes[0]?.totalRegistros?.toString(),
                  json.totalDeContratosReprovados[0]?.totalRegistros?.toString(),
                  json.totalDeAdmissoesConcluidas[0]?.totalRegistros?.toString(),
                ],
                backgroundColor: [
                  "rgb(40 167 69)",
                  "rgb(255 193 7)",
                  "rgb(0 123 255)",
                  "rgb(102 16 242)",
                  "rgb(253 126 20)",
                  "rgb(220 53 69)",
                  "rgb(32 201 151)",
                  "rgba(201, 203, 207, 0.7)",
                ],
              },
            ],
          },
          options: {
            indexAxis: "x",
            responsive: true,
            hoverOffset: 1,
            maintainAspectRatio: true,
            aspectRatio: 3.6,
            scales: {
              x: {
                beginAtZero: true,
                stacked: true,
                grid: {
                  offset: true,
                },
              },
              y: {
                stacked: true,
                beginAtZero: true, // Comece o eixo Y a partir de zero
                suggestedMax: 100, // Defina um valor máximo sugerido
                grid: {
                  offset: false,
                },
              },
            },
            plugins: {
              datalabels: {
                color: "#000",
                anchor: "center",
                offset: -20,
                align: "left",
                display: false,
              },
              legend: {
                display: false,
              },
            },
          },
        };
        break;
      default:
        result = [];
    }

    setDashData({
      data: result,
      base: _json,
      date: _json?.dateTimeExpiration ? _json.dateTimeExpiration : null,
      lastupdate: _json?.tempoRestanteCacheMinutos
        ? ~~_json?.tempoRestanteCacheMinutos
        : null,
    });
  };

  const dataDashGetData = async () => {
    const url = [dashboardUrls.documents, dashboardUrls.contracts][~~view];

    const headers = new Headers();
    headers.append(
      "Authorization",
      `Basic ${window.localStorage.getItem("Authorization")}`
    );

    const params = {
      method: "GET",
      headers: headers,
    };

    let _data;
    let json;
    try {
      //throw true;
      _data = await fetch(url, params);
      json = await _data.json();
    } catch (e) {
      if (~~view === 0) {
        json = {
          mesCorrente: {
            tipoPeriodo: "Mês Atual",
            totalCandidatosAdmitidos: [
              {
                totalRegistros: 1354,
                nome: "Candidatos admitidos",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosAprovados: [
              {
                totalRegistros: 28908,
                nome: "Documentos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeAnalise: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de análise",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeEnvio: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de envio",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosDesistentes: [
              {
                totalRegistros: 2475,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          mesAnterior: {
            tipoPeriodo: "Mês Anterior",
            totalCandidatosAdmitidos: [
              {
                totalRegistros: 2577,
                nome: "Candidatos admitidos",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosAprovados: [
              {
                totalRegistros: 50864,
                nome: "Documentos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeAnalise: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de análise",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeEnvio: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de envio",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosDesistentes: [
              {
                totalRegistros: 2963,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          trimestre: {
            tipoPeriodo: "Trimestre",
            totalCandidatosAdmitidos: [
              {
                totalRegistros: 7016,
                nome: "Candidatos admitidos",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosAprovados: [
              {
                totalRegistros: 141218,
                nome: "Documentos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeAnalise: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de análise",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeEnvio: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de envio",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosDesistentes: [
              {
                totalRegistros: 8285,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          semestre: {
            tipoPeriodo: "Semestre",
            totalCandidatosAdmitidos: [
              {
                totalRegistros: 11709,
                nome: "Candidatos admitidos",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosAprovados: [
              {
                totalRegistros: 242308,
                nome: "Documentos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeAnalise: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de análise",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeEnvio: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de envio",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosDesistentes: [
              {
                totalRegistros: 13779,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 1673,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 313,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 1864,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 404,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 2175,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 2317,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 32224,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 637,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 1687,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 58,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 2470,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 2384,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 1054,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 473,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 78,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 1088,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 887,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 1320,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 180,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 160,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 5430,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 2937,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 3694,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 702,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 10,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 40,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 280,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 943,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 176,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 532,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 27,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 164,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 2360,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 2957,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 3323,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 660,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 466,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 25,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 148,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 425,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 21,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 347,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1386,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1796,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1663,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1075,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 1175,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 1535,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 1055,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 347,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 29,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 482,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 177,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 227,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 390,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 15,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 10,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 1683,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 480,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 386,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 326,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 24,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 415,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 83,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 137,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 23,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 7713,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 667,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 720,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 38,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 18,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 298,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 640,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 363,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 55,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 245,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 49,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 23,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 4,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 9,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 52,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 79,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 61,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 1,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 60,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 8,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 23,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 2,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 5,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 8,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 57,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 3,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 1,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 1,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          ano: {
            tipoPeriodo: "Ano",
            totalCandidatosAdmitidos: [
              {
                totalRegistros: 17406,
                nome: "Candidatos admitidos",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosAprovados: [
              {
                totalRegistros: 346158,
                nome: "Documentos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeAnalise: [
              {
                totalRegistros: 23006,
                nome: "Documentos pendentes de análise",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDocumentosPendenteDeEnvio: [
              {
                totalRegistros: 0,
                nome: "Documentos pendentes de envio",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosDesistentes: [
              {
                totalRegistros: 18024,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 1673,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 313,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 1864,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 404,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 2175,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 2317,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 32224,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 637,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 1687,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 58,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 2470,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 2384,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 1054,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 473,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 78,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 1088,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 887,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 1320,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 180,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 160,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 5430,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 2937,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 3694,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 702,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 10,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 40,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 280,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 943,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 176,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 532,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 27,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 164,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 2360,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 2957,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 3323,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 660,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 466,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 25,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 148,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 425,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 21,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 347,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1386,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1796,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1663,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1075,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 1175,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 1535,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 1055,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 347,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 29,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 482,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 177,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 227,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 390,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 15,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 10,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 1683,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 480,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 386,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 326,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 24,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 415,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 83,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 137,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 23,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 7713,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 667,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 720,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 38,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 18,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 298,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 640,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 363,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 55,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 245,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 49,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 23,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 4,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 9,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 52,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 79,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 61,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 1,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 60,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 8,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 23,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 2,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 5,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 1,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 8,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 57,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 3,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 1,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 1,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Não enviado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Em análise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Desistente",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          tempoRestanteCacheMinutos: 1439.9999710466666,
          dateTimeExpiration: "2021-12-21T15:44:23.2686632-03:00",
        };
      }

      if (~~view === 1) {
        json = {
          mesCorrente: {
            tipoPeriodo: "Mês Atual",
            totalDeContratosAprovados: [
              {
                totalRegistros: 736,
                nome: "Contratos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosPendentesDeLiberacao: [
              {
                totalRegistros: 116,
                nome: "Contratos pendetes de liberação",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosAguardandoAssinatura: [
              {
                totalRegistros: 23,
                nome: "Contratos aguardando assinatura",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosReprovados: [
              {
                totalRegistros: 0,
                nome: "Contratos reprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDispensados: [
              {
                totalRegistros: 32,
                nome: "Candidatos dispensados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDesistentes: [
              {
                totalRegistros: 2063,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          mesAnterior: {
            tipoPeriodo: "Mês Anterior",
            totalDeContratosAprovados: [
              {
                totalRegistros: 1398,
                nome: "Contratos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosPendentesDeLiberacao: [
              {
                totalRegistros: 47,
                nome: "Contratos pendetes de liberação",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosAguardandoAssinatura: [
              {
                totalRegistros: 41,
                nome: "Contratos aguardando assinatura",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosReprovados: [
              {
                totalRegistros: 0,
                nome: "Contratos reprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDispensados: [
              {
                totalRegistros: 60,
                nome: "Candidatos dispensados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDesistentes: [
              {
                totalRegistros: 2210,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          trimestre: {
            tipoPeriodo: "Trimestre",
            totalDeContratosAprovados: [
              {
                totalRegistros: 4656,
                nome: "Contratos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosPendentesDeLiberacao: [
              {
                totalRegistros: 174,
                nome: "Contratos pendetes de liberação",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosAguardandoAssinatura: [
              {
                totalRegistros: 153,
                nome: "Contratos aguardando assinatura",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosReprovados: [
              {
                totalRegistros: 0,
                nome: "Contratos reprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDispensados: [
              {
                totalRegistros: 173,
                nome: "Candidatos dispensados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDesistentes: [
              {
                totalRegistros: 6377,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          semestre: {
            tipoPeriodo: "Semestre",
            totalDeContratosAprovados: [
              {
                totalRegistros: 7602,
                nome: "Contratos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosPendentesDeLiberacao: [
              {
                totalRegistros: 264,
                nome: "Contratos pendetes de liberação",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosAguardandoAssinatura: [
              {
                totalRegistros: 228,
                nome: "Contratos aguardando assinatura",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosReprovados: [
              {
                totalRegistros: 15,
                nome: "Contratos reprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDispensados: [
              {
                totalRegistros: 257,
                nome: "Candidatos dispensados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDesistentes: [
              {
                totalRegistros: 9757,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 1696,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 1261,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 985,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 60,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 11,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 14,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 2071,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1254,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 87,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1662,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 39,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 24,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 5,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 118,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 73,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 351,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 6,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 1,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 2212,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 1846,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 1848,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 37,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 46,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 10,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 1,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 1958,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 2031,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 3000,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 71,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 46,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 4,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 3860,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 1238,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 64,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 8,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 9,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 1,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 587,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 1683,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 2733,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 13,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 19,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 4,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 1713,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 302,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 11,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 226,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 11,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 7,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 31710,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 1371,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 49,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 1833,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 76,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 4,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 3,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 253,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 668,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 7745,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 22,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 5,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 7,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 162,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 21,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 48,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 439,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 12,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 126,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 337,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 10,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 886,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 221,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 12,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 473,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 20,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 2,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 1143,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 345,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 206,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 9,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 2,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 646,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 286,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 160,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 11,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 2,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 2,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 3,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 110,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 10,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 1,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 2,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 28,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 6,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 8,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 1,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 6,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 988,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 100,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 174,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 6,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 1,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 1,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 5,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          ano: {
            tipoPeriodo: "Ano",
            totalDeContratosAprovados: [
              {
                totalRegistros: 7602,
                nome: "Contratos aprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosPendentesDeLiberacao: [
              {
                totalRegistros: 264,
                nome: "Contratos pendetes de liberação",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosAguardandoAssinatura: [
              {
                totalRegistros: 228,
                nome: "Contratos aguardando assinatura",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeContratosReprovados: [
              {
                totalRegistros: 15,
                nome: "Contratos reprovados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDispensados: [
              {
                totalRegistros: 257,
                nome: "Candidatos dispensados",
                mes: 12,
                ano: 2021,
              },
            ],
            totalDeCandidatosDesistentes: [
              {
                totalRegistros: 9757,
                nome: "Candidatos desistentes",
                mes: 12,
                ano: 2021,
              },
            ],
            totalCandidatosPorSiteStatus: [
              {
                dadosTotaisStatusList: [
                  {
                    site: "Mossoró",
                    totalRegistros: 1696,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 1261,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 985,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 60,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 11,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Mossoró",
                    totalRegistros: 14,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 2071,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1254,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 87,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 1662,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 39,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 24,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "João Pessoa - PB",
                    totalRegistros: 5,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 118,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 73,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 351,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 6,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo I - BH - MG",
                    totalRegistros: 1,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 2212,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 1846,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 1848,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 37,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 46,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 10,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Ceará - Juazeiro do Norte",
                    totalRegistros: 1,
                    status: "Divergência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 1958,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 2031,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 3000,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 71,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 46,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campina Grande - Paraíba",
                    totalRegistros: 4,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 3860,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 1238,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 64,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 8,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 9,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Edifício Paramount - SP",
                    totalRegistros: 1,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Arapiraca",
                    totalRegistros: 587,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 1683,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 2733,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 13,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 19,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Arapiraca",
                    totalRegistros: 4,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 1713,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 302,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 11,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 226,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 11,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Rio de Janeiro",
                    totalRegistros: 7,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 31710,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 1371,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 49,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 1833,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 76,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 4,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Orion - BH - MG",
                    totalRegistros: 3,
                    status: "Em Analise",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "GV1 - MG",
                    totalRegistros: 253,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 668,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 7745,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 22,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 5,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "GV1 - MG",
                    totalRegistros: 7,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 162,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 21,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Espírito Santo III - BH - MG",
                    totalRegistros: 48,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 439,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 12,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 126,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 337,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Estação - BH - MG",
                    totalRegistros: 10,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 886,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 221,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 12,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 473,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 20,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC1 e 2 - Montes Claros",
                    totalRegistros: 2,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 1143,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 345,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 206,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 9,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Vila Mariana - SP",
                    totalRegistros: 2,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 646,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 286,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 160,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 11,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 2,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "MOC3 - Montes Claros",
                    totalRegistros: 2,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 3,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 110,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 10,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Tatuapé - SP",
                    totalRegistros: 1,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Campinas - SP",
                    totalRegistros: 2,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 28,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 6,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 8,
                    status: "Dispensado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 1,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Campinas - SP",
                    totalRegistros: 6,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 988,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 100,
                    status: "Desistência",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 174,
                    status: "Aprovado",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 6,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                  {
                    site: "Silvio Romero Plaza Shopping - SP",
                    totalRegistros: 1,
                    status: "Contrato assinado",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Rochaverá Corporate Towers - SP",
                    totalRegistros: 1,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Pinheiros - SP",
                    totalRegistros: 5,
                    status: "Pendente de liberação",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Bonfiglioli - SP",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
              {
                dadosTotaisStatusList: [
                  {
                    site: "Tupis - BH - MG",
                    totalRegistros: 0,
                    status: "Aguardando assinatura",
                    mes: 12,
                    ano: 2021,
                  },
                ],
              },
            ],
          },
          tempoRestanteCacheMinutos: 1439.99998833,
          dateTimeExpiration: "2021-12-21T15:45:50.1270704-03:00",
        };
      }

      json = null;
    }

    if (json) {
      let dataFilter = Object.keys(json).filter((item) =>
        /(mes|trimestre|semestre|ano)/.test(item)
      );
      const map_filter = dataFilter.map((item) => ({
        label: json[item].tipoPeriodo,
        value: item,
      }));
      setFilterList(map_filter);
      setDashBase(json);
    } else {
      setDashData({
        data: [],
        base: [],
        date: json?.dateTimeExpiration ? json.dateTimeExpiration : null,
        lastupdate: json?.tempoRestanteCacheMinutos
          ? ~~json?.tempoRestanteCacheMinutos
          : null,
      });
    }
  };

  const alterFilterTab = (_data) => {
    tabFilter.current = _data.id;
    getDataDash(dashData.base);
  };

  useEffect(() => {
    getDataDash(dashBase);
  }, [dashBase]);

  useEffect(() => {
    tabFilter.current = 0;
    setDashData(null);
    setFilterList([]);
    dataDashGetData();
  }, [view]);

  useEffect(() => {
    // getNotificaton({icon:'success', content:<>Você possui <strong>03 avaliações em atraso!</strong></>, action:{name:'Visualizar'}});
    // getNotificaton({icon:'warning', content:<>Você possui <strong>03 avaliações em atraso!</strong></>, action:{name:'Visualizar'}});
    // getNotificaton({icon:'danger', content:<>Você possui <strong>03 avaliações em atraso!</strong></>, action:{name:'Visualizar'}});

    dataDashGetData();
  }, []);

  return (
    <MainFrameDash key={`filter-${tabFilter.current}`}>
      <Header>
        Bem vindo, <strong>{name}</strong>
      </Header>
      <NotificationArea style={{ display: notificationData ? "flex" : "none" }}>
        {notificationData}
      </NotificationArea>
      <Nav>
        {tabsView.current.map((item, _id) => {
          return (
            <ButtonNav
              onClick={() => alterView(_id)}
              className={`${~~view === _id ? "active" : ""}`}
              style={{ minWidth: "100px" }}
            >
              {item.label}
            </ButtonNav>
          );
        })}
      </Nav>
      <section className="area-section">
        <div className="filter">
          {filterList?.length > 0 ? (
            <div className="filter-title">
              <strong>Filtro</strong>:
            </div>
          ) : (
            ""
          )}
          {filterList.map((item, id) => {
            return (
              <>
                <Button
                  onClick={() => alterFilterTab({ ...item, id })}
                  type={~~tabFilter.current === id ? "" : "secondary"}
                  name={item.label}
                  width="143px"
                ></Button>
              </>
            );
          })}
        </div>
        <DynamicDash
          data={dashData?.data}
          key={`tab-${~~view}-${dashData?.lastupdate}`}
          date={dashData?.date}
          time={dashData?.lastupdate}
          onUpdateData={dataDashGetData}
        />
      </section>
    </MainFrameDash>
  );
};

export default connect()(DashBoardPlugMain);
