import styled from 'styled-components';
import theme from '../../shared/Theme';

const defineColor = (status) => {
  if (status === 0) return theme.colors.errorColor;
  if (status === 1) return theme.colors.softGreen;
  if (status === 2) return theme.colors.titleColor;
  if (status === 3) return theme.colors.softGray;
  if (status === 4) return theme.colors.orangeColor;
};
export const DotStage = styled.div`
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: ${props => defineColor(props.approved)};
  border-radius: 50px;
  text-align: center;
  padding-top: 10px;
  z-index: 2;
`;

const renderGradient = (previous, next) => {
  if (previous && next) return 'linear-gradient(to right, #8bc63f, #8bc63f)';
  if (previous && !next) return 'linear-gradient(to right, #8bc63f,  #f17090)';
  if (!previous && !next) return 'linear-gradient(to right, #f17090 , #f17090)';
  if (!previous && next) return 'linear-gradient(to right, #f17090 , #8bc63f)';
};

export const LineStatus = styled.div`
  height: 2px;
  background-image: ${props => renderGradient(props.approved, props.nextStatus)};
  width: 70px;
`;

export const BoxStageStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const DotBox = styled.div`
  display: flex;
  flex-flow: column;
  cursor: pointer;
`;

export const StageName = styled.span`
  height: 15px;
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b;
  width: 100%;
  position: absolute;
  margin-top: 50px;
  margin-left: -10px;
`;

export const RowTimeLine = styled.div`
  display: flex;
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const LabelNumber = styled.span`
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;
