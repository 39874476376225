import React from 'react';

import './listcard.scss';

export const ListCard = ({data}) => {

    const ref = new Date().getTime();

    if(!data?.data){
        data.data = [
            'Item  1',
            'Item  2',
            'Item  3',
            'Item  4',
            'Item  5'
        ]
    }

    return (
        <div className="dash-card-listcard">
            <div className="box">
                <div className="title" style={{'--title': data?.customStyle?.color}} >
                    {data?.title || 'Título'}
                </div>
                {data?.subtitle ? <div style={{'--title': data?.customStyle?.color}} className='subtitle'>{data.subtitle}</div> : <div style={{'--title': data?.customStyle?.color}} className='subtitle'></div>}
                <div className="content">
                    {
                        data?.data.map((item, id) => <div dangerouslySetInnerHTML={{__html: item}} key={`item-${id}-${ref}`}></div>)
                    }
                </div>
            </div>
        </div>
    )
}