import styled from "styled-components";
import theme from "../../../components/shared/Theme";

export const HeaderBody = styled.div`
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(208, 208, 208, 0.4);
  margin-bottom: 0;
  z-index: 2;
  border-bottom: 1px solid #e2e2e2;
`;

export const HeaderItem = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
`;

export const DisplayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserItems = styled.div`
  display: flex;
  margin-right: 56px;
  align-items: center;
`;

export const DashboardLink = styled.div`
  fill: ${theme.colors.defaultColor};
  g {
    transition: all 0.2s;
  }
  &:hover {
    cursor: pointer;
    g {
      fill: ${theme.colors.defaultColorHover};
    }
  }
`;
