import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  TextField,
  ButtonSecondary,
  ButtonDefault,
  CheckBox,
} from 'components/shared/CustomInputs';
import PropTypes from 'prop-types';
import {
  HeaderModal,
  SpanQuestion,
  BodyModal,
  SpanDisclaimer,
  FlexItems,
  ActionsFlex,
} from './ModalEditionStyle';

export default function ModalEdition(props) {
  const { show } = props;
  const { name } = props;
  const { actionModalSave } = props;
  const [showDialog, setShowDialog] = useState(show);
  const [override, setOverride] = useState(true);
  const [save, setSave] = useState(false);
  const setOptionsValues = () => {
    setOverride(!override);
    setSave(!save);
  };
  return (
    <Modal show={showDialog}>
      <HeaderModal>
        <SpanQuestion>Salvar edição</SpanQuestion>
      </HeaderModal>
      <BodyModal>
        <FlexItems>
          <SpanDisclaimer>
            {`Você está salvando uma etapa editada. Deseja salvar como uma nova etapa? Ou sobrescrever
            a etapa ${name} ?`}
          </SpanDisclaimer>
          <ActionsFlex>
            <CheckBox onChange={() => setOptionsValues()} checked={override} radius />
            <SpanDisclaimer>Desejo sobrescrever a etapa anterior.</SpanDisclaimer>
          </ActionsFlex>
          <ActionsFlex>
            <CheckBox onChange={() => setOptionsValues()} checked={save} radius />
            <SpanDisclaimer>Desejo salvar uma nova etapa com as modificações.</SpanDisclaimer>
          </ActionsFlex>
          <TextField label="Nome da nova etapa" />
          <ActionsFlex>
            <ButtonDefault
              ml
              onClick={() => setShowDialog(!showDialog)}
              setShowDialog
              name="CANCELAR"
            />
            <ButtonSecondary ml onClick={() => actionModalSave()} name="SALVAR" />
          </ActionsFlex>
        </FlexItems>
      </BodyModal>
    </Modal>
  );
}
ModalEdition.propTypes = {
  show: PropTypes.bool,
  actionModalSave: PropTypes.func,
  name: PropTypes.string,
};
ModalEdition.defaultProps = {
  show: false,
  actionModalSave: () => {},
  name: '',
};
