import * as urls from "utils/contants/urls";
import theme from "../../../../../components/shared/Theme.js";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Creators as messageAction } from "store/ducks/messages";
import { Col, Row, Button } from "react-bootstrap";
import { Body } from "./ManagerCandidatesContractStyle.js";
import InputMask from "react-input-mask";

import {
  SelectFieldInput,
  ButtonDefault,
  MultSelectCreatable,
  TextField,
  DatePicker,
} from "components/shared/CustomInputs";

import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as contractsAction } from "store/ducks/contracts";
import { Creators as userBenefits } from "store/ducks/userBenefits";
import { required } from "../../../../../helpers/validations.js";

const ManagerCandidatesBenefits = ({
  filesCandidateSelected,
  isLoading,
  location,
  messagePublication,
  requestTypeContracts,
  redirectToContracts,
  inviteCandidates,
  changeContractRequest,
  kitsContratosList,
  log,
  requestAdtive,
  adtiveTypesList,
  createUserBenefits,
}) => {
  const [formErros, setFormErros] = useState([]);
  const [formValidators, setFormValidators] = useState();
  const [submited, setSubmited] = useState(false);
  const [showDialogYesOrNo, setShowDialogYesOrNo] = useState(0);
  const [dataNecessaryCompleted, setDataNecessaryCompleted] = useState();
  const [formValues, setFormValues] = useState({
    cpf: "",
    valeTransporte: [],
    valeRefeicao: "",
    diasUteis: "",
    ausencias: "",
    mesCompetencia: "",
  });
  const [erroMessage, setErroMessage] = useState({ message: "" });
  const [logProcess, setlogProcess] = useState([]);
  const token = localStorage.getItem("Authorization");

  useEffect(() => {
    isLoading = false;
    requestTypeContracts();
  }, []);

  useEffect(() => {
    setlogProcess(log);
  }, [log]);

  useEffect(() => {
    if (formValues.kitContratos) requestAdtive({ id: formValues.kitContratos });
  }, [formValues.kitContratos]);

  const addValeTransporte = () => {
    let vale = formValues.valeTransporte;
    vale.push({
      idx: formValues.valeTransporte.length,
      nome: "",
      passagens: "",
      valor: "",
    });

    setFormValues({
      ...formValues,
      valeTransporte: vale,
    });
  };

  const removeValeTransporte = (transportadorasIdx) => {
    let transportadoraId = 0;

    const TransportadoraIndex = formValues.valeTransporte.findIndex(
      (transportadora) => transportadora.idx === transportadorasIdx
    );
    transportadoraId = formValues.valeTransporte[transportadorasIdx].idx;

    let listaValeTransporte = formValues.valeTransporte;
    listaValeTransporte.splice(TransportadoraIndex, 1);

    listaValeTransporte = listaValeTransporte.map((transportadora, index) => ({
      ...transportadora,
      idx: index,
    }));

    setFormValues({
      ...formValues,
      valeTransporte: listaValeTransporte,
    });
  };

  const includeNewContract = () => {
    createUserBenefits({
      cpf: formValues.cpf,
      valeTransporte: formValues.valeTransporte,
      valeRefeicao: formValues.valeRefeicao,
      diasUteis: formValues.diasUteis,
      ausencias: formValues.ausencias,
      mesCompetencia: formValues.mesCompetencia,
    });
  };

  const LimparCampos = () => {
    console.log("limpar");
    setFormValues({
      cpf: "",
      valeTransporte: [],
      valeRefeicao: "",
      diasUteis: "",
      ausencias: "",
      mesCompetencia: "",
    });
  };

  const changeContract = (e, idCandidateEmEtapa) => {
    changeContractRequest({ file: e, idCandidateEmEtapa: idCandidateEmEtapa });
    e = null;
  };

  const canShowDialog = (idDoc) => {
    return showDialogYesOrNo == idDoc;
  };

  const setColorStatus = (status) => {
    switch (status) {
      case 2:
        return { fontWeight: "bold", color: "#d01616" };
      case 3:
        return { fontWeight: "bold", color: "#32a34c" };
      default:
        return {};
    }
  };

  const [exibirReprovados, setExibirReprovados] = useState(false);

  const TrataMultiInsert = (Insert) => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];
    Insert.map((item) => {
      let conc = item.value.split(/[\s,]+/);
      conc.map((filtItem) => {
        if (filtItem.length == 11) {
          result.push(filtItem);
        } else {
          messages.push({
            message: `cpf ${filtItem} com menos de 11 digitos.Não será considerado no filtro.`,
            type: "warning",
          });
        }
      });
    });
    messagePublication(messages);
    return result;
  };

  const renderFilesCandidatesTable = (items) => {
    return (
      <div>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead ta="left" style={{ width: "50%" }}>
                <LabelHeader>Cpf</LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>Detalhes</LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>Status do processo</LabelHeader>
              </TableHead>
            </TableRow>
            {items && renderTableFilesItems(items)}
          </TableBody>
        </Table>
      </div>
    );
  };

  const renderTableFilesItems = (items) =>
    items.map((log) => (
      <TableRow>
        <TableColumn ta="left">
          <Label>{log.cpf}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{log.descricao}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{log.status}</Label>
        </TableColumn>
      </TableRow>
    ));

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <h3 style={{ color: theme.colors.defaultColor }}>Cadastrar Beneficios</h3>

      <Body>
        <Row>
          <Col md="4">
            <ButtonDefault
              onClick={() => redirectToContracts()}
              width="230px"
              mr="10px"
              name={"Voltar"}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Usuário
            </h3>
            <br />
            <InputMask
              mask="999.999.999-99"
              placeholder="000.000.000-00"
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              maskChar={null}
              alwaysShowMask
              validations={[required]}
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  cpf: event.target.value,
                })
              }
              value={formValues.cpf}
            >
              {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
          </Col>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Vale Refeição
            </h3>
            <br />
            <TextField
              placeholder="R$ ..."
              pattern="[0-9]*,[0-9]{2}"
              min={0.0}
              step={0.01}
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.valeRefeicao}
              name="refeição"
              onChange={(e) => {
                let money = e.target.value;
                money = money.replace(/^[\D]{2}/gi, "");
                money = money.replace(",", ".");
                if (/\.[0-9]{3,}/gi.test(money))
                  money = parseFloat(money).toFixed(2);
                money = `${money}`;
                setFormValues({ ...formValues, valeRefeicao: money });
              }}
            />
          </Col>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Mês de Competência
            </h3>
            <br />
            <DatePicker
              style={{ boxShadow: "#A5A5A5" }}
              width="223px"
              value={formValues.mesCompetencia}
              max="2099-12-31"
              min="2023-01-01"
              onChange={(e) => {
                const year = e.target.value.split("-")[0];
                if (year === "" || parseInt(year) > 2099) return;

                setFormValues({
                  ...formValues,
                  mesCompetencia: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Dias úteis
            </h3>
            <br />
            <InputMask
              mask="99"
              placeholder="22"
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              alwaysShowMask
              validations={[required]}
              onChange={(event) => {
                let dias = event.target.value;
                dias = dias.toString().replace("_", "").trim();
                if (parseInt(dias) > 0 && parseInt(dias) < 30) {
                  setFormValues({
                    ...formValues,
                    diasUteis: dias,
                  });
                }
              }}
              value={formValues.diasUteis}
            >
              {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
          </Col>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Ausências
            </h3>
            <br />
            <InputMask
              mask="99"
              placeholder="22"
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              alwaysShowMask
              validations={[required]}
              onChange={(event) => {
                let dias = event.target.value;
                dias = dias.toString().replace("_", "").trim();

                if (parseInt(dias) > 0 && parseInt(dias) < 30) {
                  setFormValues({
                    ...formValues,
                    ausencias: dias,
                  });
                }
              }}
              value={formValues.ausencias}
            >
              {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <>
              <h3
                style={{ color: theme.colors.defaultColor, fontWeight: "400" }}
              >
                Vale Transporte
              </h3>
              <ButtonDefault
                name={<FormattedMessage id="sharedItems.addTransport" />}
                onClick={() => addValeTransporte()}
                width="100%"
                isLoading={false}
              />

              {formValues.valeTransporte.length > 0 &&
                formValues.valeTransporte.map((dependent, index) => {
                  return (
                    <ValeTransporteFields
                      formValues={formValues}
                      setFormValues={setFormValues}
                      transportadorasIdx={index}
                      key={index}
                      requiredFields={!dataNecessaryCompleted}
                      removeValeTransporte={removeValeTransporte}
                    />
                  );
                })}
            </>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="4">
            <ButtonDefault
              name={"Liberar"}
              disabled={
                formValues.cpf == 0 ||
                (formValues.valeRefeicao < 1 &&
                  formValues.valeTransporte < 1) ||
                formValues.mesCompetencia == "" ||
                formValues.diasUteis == ""
              }
              mr="18px"
              isLoading={isLoading}
              onClick={() => includeNewContract()}
            />
          </Col>

          <Col md="4">
            <Button
              variant="danger"
              style={{ width: "100%" }}
              onClick={(e) => {
                LimparCampos();
              }}
            >
              Limpar
            </Button>
          </Col>
        </Row>
      </Body>
    </React.Fragment>
  );
};

const ValeTransporteFields = ({
  formValues,
  setFormValues,
  transportadorasIdx,
  key,
  requiredFields,
  removeValeTransporte,
}) => {
  const [transportadoras, setTransportadoras] = useState(
    formValues.valeTransporte
  );

  const updateFormValues = (transportadoraId) => {
    setFormValues({ ...formValues, valeTransporte: transportadoras });
  };

  useEffect(() => {
    setFormValues({
      ...formValues,
      numberOfTickets: formValues.valeTransporte.length,
    });
  }, [formValues.valeTransporte.length]);

  return (
    <Row
      md={12}
      style={{
        border: `1px solid ${theme.colors.ligthGray}`,
        margin: "15px 1px",
        padding: "10px",
      }}
    >
      <Row>
        <Col md={6}>
          <TextField
            min={0.0}
            step={0.01}
            label={<FormattedMessage id="sharedItems.companyName" />}
            onChange={(e) => {
              let prevTransportadoras = formValues.valeTransporte;
              const transportadorasIndex = prevTransportadoras.findIndex(
                (transportadora) => transportadora.idx === transportadorasIdx
              );

              prevTransportadoras[transportadorasIndex].nome = e.target.value;
              setFormValues({
                ...formValues,
                valeTransporte: prevTransportadoras,
              });
            }}
            value={
              formValues.valeTransporte.find(
                (transportadora) => transportadora.idx === transportadorasIdx
              ).nome
            }
          />
        </Col>
        <Col md={6}>
          <TextField
            placeholder="R$ ..."
            pattern="[0-9]*,[0-9]{2}"
            min={0.0}
            step={0.5}
            label={<FormattedMessage id="sharedItems.ticketValue" />}
            onChange={(e) => {
              let prevTransportadoras = formValues.valeTransporte;
              const transportadorasIndex = prevTransportadoras.findIndex(
                (transportadora) => transportadora.idx === transportadorasIdx
              );

              prevTransportadoras[transportadorasIndex].valor = e.target.value;
              setFormValues({
                ...formValues,
                valeTransporte: prevTransportadoras,
              });
            }}
            value={
              formValues.valeTransporte.find(
                (transportadora) => transportadora.idx === transportadorasIdx
              )?.valor
            }
          />
        </Col>
        <Col md={6}>
          <TextField
            placeholder="1"
            pattern="[0-9]*,[0-9]{2}"
            min={0.0}
            step={1}
            label={<FormattedMessage id="sharedItems.amountOfTickets" />}
            onChange={(e) => {
              let prevTransportadoras = formValues.valeTransporte;
              const transportadorasIndex = prevTransportadoras.findIndex(
                (transportadora) => transportadora.idx === transportadorasIdx
              );

              prevTransportadoras[transportadorasIndex].passagens =
                e.target.value;
              setFormValues({
                ...formValues,
                valeTransporte: prevTransportadoras,
              });
            }}
            value={
              formValues.valeTransporte.find(
                (transportadora) => transportadora.idx === transportadorasIdx
              )?.passagens
            }
          />
        </Col>
        <Col md={6}>
          <Button
            variant="danger"
            style={{ marginTop: "18px" }}
            onClick={(e) => {
              let transportadoraId = removeValeTransporte(transportadorasIdx);
            }}
          >
            Remover
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

const mapDispatchToProps = (dispatch) => ({
  inviteCandidates: (payload) =>
    dispatch(contractsAction.candidateIncludeNewContractRequest(payload)),

  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),

  requestTypeContracts: () => dispatch(contractsAction.typeContractsRequest()),

  redirectToContracts: () => dispatch(push(`${urls.CONTRACTS_LIST}`)),

  requestAdtive: (payload) =>
    dispatch(contractsAction.adtiveTypesRequest(payload)),

  createUserBenefits: (payload) =>
    dispatch(userBenefits.createUserBenefits(payload)),
});

const mapStateToProps = (state) => {
  return {
    kitsContratosList: state.contracts.kitsContratosList,
    filesCandidateSelected: state.documents.candidatesFiles,
    log: state.contracts.logProcess,
    adtiveTypesList: state.contracts.adtiveTypesList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerCandidatesBenefits);

ManagerCandidatesBenefits.propTypes = {
  filesCandidateSelected: PropTypes.func,
};

ManagerCandidatesBenefits.defaultProps = {
  candidates: () => {},
  filesCandidateSelected: () => {},
};
