import "moment/locale/pt-br";

import * as routes from "../../utils/contants/urls";
import * as urls from "../../utils/contants/urlsApi";

import {
  buildErroHandleItems,
  buildPagination,
  buildTimeFromSeconds,
  convertTimeOfTest,
  convertTimeToMinutes,
  formatterMaskTime,
  secondsToMinutes
} from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/stages";
import { history } from "../../utils/routes";
import moment from "moment";

moment.locale("pt-BR");

function requestGetListStages(url, page) {
  return API.get(`${url}?NumeroPagina=${page || 1}`)
    .then(res => res.data)
    .catch(error => { });
}

function requestShowStages(id) {
  return API.get(`${urls.getStageShowApi}/${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

const redirectToForm = (kind, id) => {
  switch (kind) {
    case 0:
      return history.push(`${routes.STAGE_REGISTRATION_DATA_SHOW}/${id}`);
    case 1:
      return history.push(`${routes.STAGES_TESTS}/${id}`);
    case 2:
      return history.push(`${routes.STAGES_TRAINNER}/${id}`);
    case 3:
      return history.push(`${routes.STAGES_ASSAY}/${id}`);
    case 4:
      return history.push(`${routes.STAGES_MEDIC_EXAME}/${id}`);
    case 5:
      return history.push(`${routes.STAGES_ADMITION}/${id}`);
    case 6:
      return history.push(`${routes.STAGES_AMBIENTATION}/${id}`);
    case 7:
      return history.push(`${routes.STAGES_FEEDBACK}/${id}`);
    case 8:
      return history.push(`${routes.STAGES_PRE_ADMITION}/${id}`);
    case 10:
      return history.push(`${routes.STAGES_PRE_ADMITION}/${id}`);
    case 11:
      return history.push(`${routes.STAGES_PRE_ADMITION}/${id}`);
    default:
  }
};
function* getStage({ listKind, page }) {
  try {
    const stages = yield requestGetListStages(
      urls.stageListUrls[listKind],
      page
    );
    const stateStagesList = yield select(getStateStageList);
    const stagesParsed = stages.lista.map(k => ({
      name: k.nome,
      id: k.id,
      kind: k.tipo ? k.tipo : null,
      avaliation: k.avaliacao ? k.avaliacao.nome : null,
      level: k.nivel ? k.nivel : null,
      timeTestMinutes: k.tempoExameSegundos
        ? convertTimeOfTest(k.tempoExameSegundos)
        : 0,
      amountOfQuestions: k.quantidadePerguntas,
      kindTest: k.tipoTeste ? k.tipoTeste : null
    }));
    const itemsToPage = buildPagination(
      stages.paginacao,
      { NumeroPagina: page },
      stagesParsed,
      stateStagesList
    );
    yield put({
      type: Types.STAGE_SUCCESS,
      stages: stagesParsed,
      pagination: itemsToPage
    });
  } catch (error) {
    yield put({ type: Types.STAGE_FAIL, error });
  }
}

function* getStageShow({ payload }) {
  try {
    const stage = yield requestShowStages(payload?.id || payload);
    const stageParsed = {
      description: stage.descricao,
      name: stage.nome,
      positions:
        stage.cargos &&
        stage.cargos.map(job => ({
          mandatory: job.obrigatorio,
          id: job.id
        })),
      stageId: stage.id,
      kind: stage.etapaTipoTeste ? stage.etapaTipoTeste.id : null,
      avaliation: stage.etapaAvaliacao ? stage.etapaAvaliacao.id : null,
      testLevel: stage.etapaNivelTeste ? stage.etapaNivelTeste.id : null,
      testTime: buildTimeFromSeconds(stage.tempoTesteSegundos),
      optionSelected: renderOption(stage),
      testQuestions: stage.blocoPerguntasEtapaTeste.map(e => ({
        question: e.descricao,
        id: e.id,
        mandatory: e.respostaObrigatoria,
        answerNumber: e.respostaNumero,
        answerDate: moment(e.respostaData).format("L"),
        answers:
          e.perguntasSelecao &&
          e.perguntasSelecao.map(answer => ({
            id: answer.id,
            answer: answer.descricao,
            checked: answer.respostaCorreta,
            kind: e.tipoResposta
          })),
        weigth: e.peso.id,
        time: formatterMaskTime(e.tempo),
        kindAnswer: e.tipoResposta
      })),
      aboutYou:
        stage.blocoPerguntasEtapaDadosCadastrais &&
        stage.blocoPerguntasEtapaDadosCadastrais.sobreVoce.map(item => ({
          id: item.id,
          question: item.pergunta
        })),
      carrier:
        stage.blocoPerguntasEtapaDadosCadastrais &&
        stage.blocoPerguntasEtapaDadosCadastrais.carreira.map(item => ({
          id: item.id,
          question: item.pergunta
        })),
      formation:
        stage.blocoPerguntasEtapaDadosCadastrais &&
        stage.blocoPerguntasEtapaDadosCadastrais.formacao.map(item => ({
          id: item.id,
          question: item.pergunta
        })),
      goal:
        stage.blocoPerguntasEtapaDadosCadastrais &&
        stage.blocoPerguntasEtapaDadosCadastrais.objetivos.map(item => ({
          id: item.id,
          question: item.pergunta
        }))
    };
    redirectToForm(stage.tipoEtapa, stage.id);
    yield put({ type: Types.STAGE_SHOW_SUCCESS, stage: stageParsed });
  } catch (error) {
    yield put({ type: Types.STAGE_SHOW_FAIL, error });
  }
}

const renderOption = stage => {
  if (stage.etapaTipoTreinamento) return stage.etapaTipoTreinamento.id;
  if (stage.etapaAvaliacao) return stage.etapaAvaliacao.id;
  if (stage.etapaNivelTeste) return stage.etapaNivelTeste.id;
  if (stage.etapaTipoAmbientacao) return stage.etapaTipoAmbientacao.id;
  if (stage.etapaTipoTreinamento) return stage.etapaTipoTreinamento.id;
  if (stage.etapaTipoAnalise) return stage.etapaTipoAnalise.id;
  if (stage.etapaTipoExameMedico) return stage.etapaTipoExameMedico.id;
};

function requestCreateStage(payload) {
  return API.post(urls.stageCreateApi, payload)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function requestUpdateStage(payload) {
  return API.put(`${urls.stageCreateApi}/${payload.etapaId}`, payload)
    .then(res => res.data)
    .catch(error => { });
}

function* createStage({ payload }) {
  try {
    const bodyStage = {
      nome: payload.name,
      descricao: payload.description,
      tipoEtapa: parseInt(payload.formKind, 10),
      tempoTesteEmSegundos:
        payload.testTime && convertTimeToMinutes(payload.testTime),
      avaliacao: payload.avaliation,
      nivelTeste: payload.testLevel,
      tipoAnalise: payload.kind,
      tipoTreinamento: payload.kind,
      tipoAmbientacao: payload.kind,
      tipoExameMedico: payload.kind
    };
    bodyStage[defineKind(payload.formKind)] = parseInt(payload.kind);
    const response = yield requestCreateStage(bodyStage);
    yield put({ type: Types.STAGE_CREATE_SUCCESS, id: response.id });
    if (payload.formKind !== 1 && payload.formKind !== 0) {
      yield put({ type: Types.REQUEST_CLEAN_STAGE_STATE });
      history.push(routes.STAGES);
    }
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: messages });
    yield put({ type: Types.STAGE_CREATE_FAIL, error });
  }
}
const defineKind = kind => {
  switch (kind) {
    case 1:
      return "tipoTeste";
    case 2:
      return "tipoTreinamento";
    case 3:
      return "tipoAnalise";
    case 4:
      return "tipoExameMedico";
    case 6:
      return "tipoAmbientacao";
    default:
      return "tipoTreinamento";
  }
};
function requestInfosForms(url) {
  return API.get(url)
    .then(res => res.data)
    .catch(error => { });
}

function* getInfosForms({ formKind }) {
  try {
    let items = {};
    if (Array.isArray(formKind)) {
      for (let i = 0; i < formKind.length; i += 1) {
        items[formKind[i]] = yield requestInfosForms(
          urls.stageCreateInfosUrls[formKind[i]]
        );
      }
      yield put({ type: Types.STAGE_INFOS_SUCCESS, options: items });
    } else {
      items = yield requestInfosForms(urls.stageCreateInfosUrls[formKind]);
      const itemsObj = {
        kindTest: [],
        avaliation: [],
        levelTest: [],
        weight: []
      };
      if (Object.keys(items).length === 1) {
        const key = Object.keys(items)[0];
        yield put({ type: Types.STAGE_INFOS_SUCCESS, options: items[key] });
      } else {
        Object.keys(items).forEach((e, i) => {
          switch (i) {
            case 0:
              itemsObj.kindTest = items[e];
              break;
            case 1:
              itemsObj.avaliation = items[e];
              break;
            case 2:
              itemsObj.levelTest = items[e];
              break;
            case 3:
              itemsObj.weight = items[e];
              break;
            default:
              return [];
          }
        });
        yield put({ type: Types.STAGE_INFOS_SUCCESS, options: itemsObj });
      }
    }
  } catch (error) {
    yield put({ type: Types.STAGE_INFOS_FAIL, error });
  }
}
function deleteStageRequest(id) {
  return API.delete(`${urls.stageCreateApi}/${id}`)
    .then(res => res.data)
    .catch(error => { });
}
function* updateStage({ payload }) {
  try {
    const bodyStage = {
      nome: payload.name,
      descricao: payload.description,
      tipoEtapa: parseInt(payload.formKind, 10),
      tempoTesteEmSegundos:
        payload.testTime && convertTimeToMinutes(payload.testTime),
      avaliacao: payload.avaliation,
      nivelTeste: payload.testLevel,
      etapaId: payload.stageId
    };
    bodyStage[defineKind(payload.formKind)] = parseInt(
      payload.kind || payload.optionSelected
    );
    yield requestUpdateStage(bodyStage);
    yield put({ type: Types.REQUEST_CLEAN_STAGE_STATE });
    if (payload.hasRedirect && payload.hasRedirect.id) {
      switch (payload.hasRedirect.to) {
        case "rails":
          return history.push(`${routes.RAILS_SHOW}/${payload.hasRedirect.id}`);
        default:
          history.push(routes.STAGES);
      }
    } else {
      history.push(routes.STAGES);
    }
  } catch (error) {
    yield put({ type: Types.STAGE_CREATE_FAIL, error });
  }
}

function* deleteStage({ payload }) {
  try {
    yield deleteStageRequest(payload.stageId);
    yield getStage({ listKind: payload.filter });
    yield put({ type: Types.STAGE_DELETE_SUCCESS });
  } catch (error) {
    yield put({ type: Types.STAGE_DELETE_FAIL, error });
  }
}

function requestStagesByFilter(filter) {
  return API.put(`${urls.getStagesFilter}/`, filter)
    .then(res => res.data)
    .catch(error => { });
}

function* stagesByRailsFilters({ payload }) {
  try {
    const filter = {
      tipoEtapa: payload.kindStage,
      idTipoFiltro: payload.kindTest || payload.optionsStage,
      idTrilha: payload.id,
      numeroPagina: payload.NumeroPagina || 1
    };
    const { lista, paginacao } = yield requestStagesByFilter(filter);
    const stages = lista.map(item => ({
      avaliation: item.avaliacao ? item.avaliacao.nome : "",
      id: item.id,
      level: item.nivel ? item.nivel.nome : "",
      name: item.nome,
      timeExame: item.tempoExameSegundos
        ? convertTimeOfTest(item.tempoExameSegundos)
        : 0,
      amountOfQuestions: item.quantidadePerguntas,
      kindTesteItem: item.tipoTeste ? item.tipoTeste.nome : "",
      kindStage: payload.kindStage
    }));
    const itemsToPage = buildPagination(paginacao, payload, stages, yield select(getStateStagesList));
    yield put({
      type: Types.STAGE_BY_RAILS_FILTER_SUCCESS,
      stages,
      pagination: itemsToPage
    });
  } catch (error) {
    yield put({ type: Types.STAGE_BY_RAILS_FILTER_FAIL, error });
  }
}
const getStateStagesList = state => state.stages.stages;
function requestPublishStage(id) {
  return API.put(`${urls.FINISH_ESTAGE_TO_USE}/${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* publishStage(payload) {
  try {
    yield requestPublishStage(payload.id);
    yield put({ type: Types.STAGE_PUBLISH_SUCCESS });
    yield put({ type: Types.REQUEST_CLEAN_STAGE_STATE });
    history.push(routes.STAGES);
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: messages });
    yield put({ type: Types.STAGE_PUBLISH_FAIL, error });
  }
}

function requestDeleteQuestionBlock(stageId, typeBlock) {
  return API.delete(
    `${urls.DELETE_STAGE_QUESTION_BLOCK}/${stageId}?tipoBlocoPergunta=${typeBlock}`
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* deleteQuestionBlock({ payload }) {
  try {
    yield requestDeleteQuestionBlock(payload.stageId, payload.typeBlock);
    yield put({ type: Types.STAGE_DELETE_QUESTION_BLOCK_SUCCESS });
    payload.id = payload.stageId;
    yield put({ type: Types.STAGE_SHOW_REQUEST, payload });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: messages });
    yield put({ type: Types.STAGE_DELETE_QUESTION_BLOCK_FAIL, error });
  }
}

const getStateStageList = state => state.stages.stages;

export function* watcherSaga() {
  yield takeLatest(Types.STAGE_REQUEST, getStage);
  yield takeLatest(Types.STAGE_CREATE_REQUEST, createStage);
  yield takeLatest(Types.STAGE_INFOS_REQUEST, getInfosForms);
  yield takeLatest(Types.STAGE_SHOW_REQUEST, getStageShow);
  yield takeLatest(Types.STAGE_UPDATE_REQUEST, updateStage);
  yield takeLatest(Types.STAGE_DELETE_REQUEST, deleteStage);
  yield takeLatest(Types.STAGE_BY_RAILS_FILTER_REQUEST, stagesByRailsFilters);
  yield takeLatest(Types.STAGE_PUBLISH_REQUEST, publishStage);
  yield takeLatest(
    Types.STAGE_DELETE_QUESTION_BLOCK_REQUEST,
    deleteQuestionBlock
  );
}
