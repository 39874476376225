import {
  ButtonDefault,
  ButtonSecondary,
  MultSelect,
  SelectFieldInput,
  TextField,
  MultSelectCreatable
} from "../CustomInputs";
import { Col, Row } from "react-bootstrap";
import {
  FilterItemRow,
  FilterRegisterLabel,
  RowActionsFilter
} from "../CustomComponentsStyle";
import { StatusStages, kindStages } from "../../../locale/index";
import { buildDate, validateStringDate } from "../../../helpers/sharedHelpers";

import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import React from "react";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

export const FilterRailsOptions = ({
  filterOptions,
  setFiltersOptions,
  clearValues,
  applyFilter,
}) => {
  const TrataMultiInsertCpf = Insert => {
    //setErroMessage({ message: "" });
    let messages = [];
    let result = [];
    Insert.map(item => {
      let conc = item.value.split(/[\s,]+/);
      conc.map(filtItem => {
        if (filtItem.length == 11) {
          result.push(filtItem);
        } else {
          messages.push({ message: `cpf ${  filtItem  } com menos de 11 digitos. Não será considerado no filtro.`, type: "warning" });

        }
      });
    });
    //messagePublication(messages);
    return result;
  };
  const TrataMultiInsertEnrollment = Insert => {
    //setErroMessage({ message: "" });
    let messages = [];
    let result = [];
    Insert.map(item => {
      let conc = item.value.split(/[\s,]+/);
      conc.map(filtItem => {
        if (filtItem.length == 6) {
          result.push(filtItem);
        } else {
          messages.push({ message: 'matricula ' + filtItem + ' com menos de 6 digitos.Não será considerado no filtro.', type: "warning" });

        }
      });
    });
    //messagePublication(messages);
    return result;
  };
  return (
    <React.Fragment>
      <Row style={{ flexFlow: "row wrap" }}>
        <Col md={2}>
          <TextField
            onChange={e =>
              setFiltersOptions({ ...filterOptions, code: e.target.value })
            }
            value={filterOptions.code}
            label={<FormattedMessage id="sharedItems.code" />}
            width="100%"
            containerProps={{ width: "100%", margin: "unset" }}
          />
        </Col>
        <Col md={3}>
          <TextField
            onChange={e =>
              setFiltersOptions({ ...filterOptions, name: e.target.value })
            }
            value={filterOptions.name}
            label={<FormattedMessage id="sharedItems.name" />}
            width="100%"
            containerProps={{ width: "100%", margin: "unset" }}
          />
        </Col>
        <Col>
          <SelectFieldInput
            label={<FormattedMessage id="sharedItems.stats" />}
            value={filterOptions.status}
            items={StatusStages}
            onChange={e => {
              setFiltersOptions({
                ...filterOptions,
                stageStatus: e.target.value
              });
            }}
            width="100%"
          />
        </Col>
      </Row>
      <Row style={{ flexFlow: "row wrap" }}>
        <Col md={6}>
          <MultSelectCreatable
            key={5}
            name={<FormattedMessage id="sharedItems.CPF" />}
            label={<FormattedMessage id="sharedItems.CPF" />}
            isMulti
            options={filterOptions.cpfList}
            value={
                filterOptions.cpfList ?
                filterOptions.cpfList.map(e => ({
                    label: e,
                    value: e
                  })) : []
              }
            onChange={e =>
              setFiltersOptions({
                ...filterOptions,
                cpfList: e ? TrataMultiInsertCpf(e): []
              })
            }
          />
        </Col>
        <Col md={6}>
          <MultSelectCreatable
            key={5}
            name={<FormattedMessage id="sharedItems.enrollment" />}
            label={<FormattedMessage id="sharedItems.enrollment" />}
            isMulti
            options={filterOptions.enrollmentList}
            value={
                filterOptions.enrollmentList ?
                filterOptions.enrollmentList.map(e => ({
                    label: e,
                    value: e
                  })) : []
              }
            onChange={e =>
              setFiltersOptions({
                ...filterOptions,
                enrollmentList: e ? TrataMultiInsertEnrollment(e) : []
              })
            }
          />
        </Col>
      </Row>
      <Row style={{ flexFlow: "row wrap" }}>
        <Col md={3}>
          <InputMask
            mr="unset"
            ml="unset"
            label={<FormattedMessage id="sharedItems.startDateApplied" />}
            value={filterOptions.applyStartDate}
            onChange={e => {
              setFiltersOptions({
                ...filterOptions,
                range: { ...filterOptions.range, start: e.target.value },
                applyStartDate: e.target.value
              });
            }}
            width="100%"
            mask="99/99/9999"
            alwaysShowMask={false}
          >
            {inputProps => (
              <TextField containerProps={{ width: "100%" }} {...inputProps} />
            )}
          </InputMask>
        </Col>
        <Col md={3}>
          <InputMask
            mr="unset"
            ml="unset"
            label={<FormattedMessage id="sharedItems.endDateApplied" />}
            value={filterOptions.applyEndDate}
            onChange={e => {
              setFiltersOptions({
                ...filterOptions,
                range: { ...filterOptions.range, end: e.target.value },
                applyEndDate: e.target.value
              });
            }}
            width="100%"
            mask="99/99/9999"
            alwaysShowMask={false}
          >
            {inputProps => (
              <TextField containerProps={{ width: "100%" }} {...inputProps} />
            )}
          </InputMask>
        </Col>
        <Col md={3}>
          <InputMask
            mr="unset"
            ml="unset"
            label={<FormattedMessage id="sharedItems.schedulingDate" />}
            value={filterOptions.schedulingDate}
            onChange={e => {
              setFiltersOptions({
                ...filterOptions,
                schedulingDate: e.target.value
              });
            }}
            width="100%"
            mask="99/99/9999"
            alwaysShowMask={false}
          >
            {inputProps => (
              <TextField containerProps={{ width: "100%" }} {...inputProps} />
            )}
          </InputMask>
        </Col>
        <Col md={3}>
          <InputMask
            mr="unset"
            ml="unset"
            label={<FormattedMessage id="sharedItems.minimumResult" />}
            value={filterOptions.minimumResult}
            onChange={e => {
              setFiltersOptions({ ...filterOptions, minimumResult: e.target.value })
            }}
            width="100%"
            mask="99%"
            alwaysShowMask={false}
          >
            {inputProps => (
              <TextField containerProps={{ width: "100%" }} {...inputProps} />
            )}
          </InputMask>
        </Col>
      </Row>
      <RowActionsFilter>
        <div />
        <div style={{ marginTop: "15px" }}>
          <ButtonSecondary
            onClick={() => clearValues()}
            name={<FormattedMessage id="sharedItems.clear" />}
            width="120px"
          />
          <ButtonDefault
            ml="20px"
            name={<FormattedMessage id="sharedItems.apply" />}
            width="164px"
            onClick={() => applyFilter()}
          />
        </div>
      </RowActionsFilter>
    </React.Fragment>
  );
};
