import React, { PureComponent } from "react";

import VacancyForm from "components/VacancyForm";
import { connect } from "react-redux";
import { Creators as formInputAction } from "store/ducks/formInputs";
import { Creators as vacancyAction } from "store/ducks/vacancy";

class VacancyCreate extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      editing: false
    }
  }

  componentDidMount() {
    const { params } = this.props.match;
    const { showVaga } = this.props;
    const { getIputValues } = this.props;
    const { clearForm } = this.props;
    const {manager} = this.props;
    let inputsRequests = [
      "areaFormation",
      "schooling",
      "affiliate",
      "jorney",
      "tecnicKnowledge",
      "city",
      "area",
      "placeOfWork",
      "section",
      "level",
      "vacancyGroup"
    ];

    if (params.id) {
      showVaga(params.id, true);
      this.setState({ editing: true })
      inputsRequests = inputsRequests.concat(["resultCenter", "eligibleJobs"])
    } else {
      clearForm();
    }

    getIputValues(inputsRequests);
  }

  render() {
    const {
      vaga,
      inputs,
      saveVacancy,
      dispatch,
      updateVacancy
    } = this.props;
    return (
      <VacancyForm
        inputActions={dispatch}
        updateVacancy={updateVacancy}
        saveVacancy={saveVacancy}
        vaga={vaga}
        inputs={inputs}
        location={this.props.location}
        editing={this.state.editing}
      />
    );
  }
}
const mapDispatchToProps = dispatch => ({
  showVaga: (id, editing) =>
    dispatch(vacancyAction.jobShowRequest(id, editing)),
  saveVacancy: payload => dispatch(vacancyAction.jobCreateRequest(payload)),
  getIputValues: kind => dispatch(formInputAction.formInputsRequest(kind)),
  getResultCenter: idAf => dispatch(console.log(idAf)),
  updateVacancy: payload => dispatch(vacancyAction.jobUpdateRequest(payload)),
  clearForm: () => dispatch(vacancyAction.jobStartForm())
});
const mapStateToProps = state => ({
  vaga: state.vaga,
  route: state.route,
  inputs: state.formInputs,
  location: state.router.location
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VacancyCreate);
