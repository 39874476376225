import { DELETE, GET, POST, PUT } from "../../utils/contants/verbs";
import {
  GET_DEPENDENT_DATA,
  GET_HEALTH_AND_DENTAL_PLAN_VALUES,
  GET_CONTRACT_DOCUMENT,
  FINISH_STAGE_DIGITAL_DOCUMENT_API,
  UPLOAD_CONTRACT_DOCUMENT,
  LIST_DATA_SIGNATURES
} from "../../utils/contants/urlsApi";
import API from "../../utils/API";
import { CANDIDATE_VACANCY } from "../../utils/routes";
import { put, takeLatest } from "redux-saga/effects";
import {
  buildErroHandleItems
} from "../../helpers/sharedHelpers";
import { Types as AlertTypes } from "../ducks/alert";
import { Types } from "../ducks/contractDocuments";
import requestAPI from "../../helpers/requestHelpers";
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import { sleep } from "../../helpers/sharedHelpers";
import * as urls from "../../utils/contants/urls";

import { history } from "../../utils/routes";

function* getDependentData() {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: GET_DEPENDENT_DATA,
      data: null
    });
    const dependents = response;
    yield put({
      type: Types.GET_DEPENDENT_DATA_SUCCESS,
      dependents
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.GET_DEPENDENT_DATA_FAIL
    });
  }
}

function* getHealthAndDentalPlanValues({payload}) {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: GET_HEALTH_AND_DENTAL_PLAN_VALUES+"/"+payload.idVaga,
      data: null
    });
    const healthAndDentalPlanValues = response;
    yield put({
      type: Types.GET_HEALTH_AND_DENTAL_PLAN_VALUES_SUCCESS,
      healthAndDentalPlanValues
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.GET_HEALTH_AND_DENTAL_PLAN_VALUES_FAIL
    });
  }
}

function* getContractDocument({payload}) {
  try {

    const response = yield requestAPI({
      verb: PUT,
      endPoint: GET_CONTRACT_DOCUMENT,
      data: payload
    });
    const documents = response;

    yield put({
      type: Types.GET_CONTRACT_DOCUMENT_SUCCESS,
      documents
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.GET_CONTRACT_DOCUMENT_FAIL
    });
   
  }
}

function* finishStageDigitalDocument({ payload }) {
  
  try {

    const body = {
      vagaId: payload.idVacancy,
      etapaId: payload.idStage,
      idDocument: payload.id,
      divergencia: payload.divergencia,
      possuiDivergencia: payload.possuiDivergencia,
      assinatura:payload.assinatura,
      ipUsuario: payload.ipCandidato,
      aderenciaSMB: payload.aderenciaSMB,
      candidatoId:payload.candidatoId
    };


    const response = yield requestAPI({
      verb: PUT,
      endPoint: FINISH_STAGE_DIGITAL_DOCUMENT_API,
      data: body
    });

    
    const documentDigital = response;
    yield put({
      type: Types.FINISH_STAGE_DIGITAL_DOCUMENT_SUCCESS,
      documentDigital
    });
    
    history.push(`${urls.PLUG_ASSINATURA_CANDIDATES_LIST}`)
   
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({ type: Types.FINISH_STAGE_DIGITAL_DOCUMENT_FAIL, error });
    
  }
}

function* requestUpdateDocument({ payload }) {
  try {
    let formImg = new FormData();
    formImg.append("documento", payload.file);
    formImg.append("idDocument", payload.idDocument);
    const documents = yield requestAPI({
      verb: PUT,
      endPoint: UPLOAD_CONTRACT_DOCUMENT,
      data: formImg
    });

    let signedDocument = documents;

    yield put({
      type: Types.CONTRACT_DOCUMENT_UPDATE_SUCCESS,
      signedDocument
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.CONTRACT_DOCUMENT_UPDATE_FAIL,
      error
    });
  }
}

function* putListDataSubscribers({payload}) {
  try {
    const response = yield requestAPI({
      verb: PUT,
      endPoint: `${LIST_DATA_SIGNATURES}?candidatoEmEtapaId=${payload}`,
      data: null
    });
    const dataSubscribers = response;
   

    yield put({
      type: Types.LIST_DATA_SUBSCRIBERS_SUCCESS,
      dataSubscribers
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.LIST_DATA_SUBSCRIBERS_FAIL
    });
  }
}



export function* watcherSaga() {
  yield takeLatest(Types.GET_DEPENDENT_DATA_REQUEST, getDependentData);
  yield takeLatest(Types.GET_HEALTH_AND_DENTAL_PLAN_VALUES_REQUEST, getHealthAndDentalPlanValues);
  yield takeLatest(Types.GET_CONTRACT_DOCUMENT_REQUEST, getContractDocument);
  yield takeLatest(Types.FINISH_STAGE_DIGITAL_DOCUMENT, finishStageDigitalDocument);
  yield takeLatest(Types.CONTRACT_DOCUMENT_UPDATE_REQUEST, requestUpdateDocument);
  yield takeLatest(Types.LIST_DATA_SUBSCRIBERS_REQUEST, putListDataSubscribers);
}
