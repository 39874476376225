import { Link } from "react-router-dom";
import styled from "styled-components";
import order from "../../assets/icons/arrow.png";
import theme from "./Theme";

export const Input = styled.input`
  outline: none;
  height: 40px;
  border: 1px solid #d2d2d2;
  color: ${theme.colors.darkGray};
  font-weight: 400;
  border-radius: 3px;
  padding-left: 13px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-left:${(props) => (props.ml ? props.ml : "0px")}
  margin-right:${(props) => (props.mr ? props.mr : "0px")}

  &:hover {
    border-color: ${theme.colors.defaultColor};
  }
  &:focus {
    border-color: ${theme.colors.defaultColor};
  }
`;

export const TextArea = styled.textarea`
  outline: none;
  border: 1px solid #d2d2d2;
  color: ${theme.colors.darkGray};
  font-weight: 400;
  border-radius: 3px;
  width: 100%;
  min-height: 100px;
  padding-left: 13px;
  padding-top: 5px;
  &:hover {
    border-color: ${theme.colors.defaultColor};
  }
  &:focus {
    border-color: ${theme.colors.defaultColor};
  }
`;

export const SpanToolTip = styled.span`
  height: 15px;
  font-family: Arial;
  font-size: 13px;
  font-weight: ${(props) => (props.bold ? "bold" : "500")};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.darkGray};
  margin-top: 3px;
  margin-bottom: 3px;
`;
export const InputSearch = styled.input`
  outline: none;
  border: none;
  padding-left: 10px;
  opacity: 0.7;
  color: ${theme.colors.inputTextColor};
  background-position: 'center';
  height: 36px;
  border-bottom: 1px solid ${theme.colors.softGray};
  background-color:  transparent;
  width: ${(props) => props.width};
  margin-left: ${(props) => props.ml}
  margin-right: ${(props) => props.mr}
  text-align: start;
  padding-bottom: 5px;
  &:hover {
    border-color: ${theme.colors.defaultColor};
  }
  &:focus {
    opacity: 1;
    border-color: ${theme.colors.defaultColor};
  }
`;

export const InputSelectSearch = styled.select`
  outline: none;
  border: none;
  padding-left: 10px;
  opacity: 0.7;
  color: ${theme.colors.softGray};
  background-color: ${theme.colors.whiteDefault};
  background: url(${order}) no-repeat scroll 5px 5px;
  appearance: none;
  background-position: right 5% bottom 50%;
  height: 36px;
  border-bottom: 1px solid ${theme.colors.softGray};
  width: 100%;
  text-align: start;
  padding-bottom: 5px;

  &:hover {
    border-color: ${theme.colors.defaultColor};
  }
  &:focus {
    opacity: 1;
    border-color: ${theme.colors.defaultColor};
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: wrap;
  margin-bottom: 20px;
  flex-flow: column;
  margin-bottom: 5px;
  margin-top: 5px;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-right: ${(props) => props.mr};
  margin-left: ${(props) => props.ml};
`;

export const LabelInput = styled.label`
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.softGray};
  margin-bottom: ${(props) => props.noMarginBottom && "0px"};
  display: flex;
  flex-grow: 100;
  margin-left: ${(props) => props.ml};
`;

export const LabelCheckBox = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.softGray};
`;

export const ButtonDefaultStyle = styled.button`
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Arial", sans-serif;
  letter-spacing: -0.1px;
  font-weight: lighter;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s;
  border-radius: 3px;
  pointer-events: ${(props) => (!props.disabled ? "all" : "none")};
  background-color: ${(props) =>
    props.disabled ? "#e0e0e0" : theme.colors.defaultColor};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 40px;
  font-size: 14px;
  text-shadow: 0px 1px 2px #2d2d2d4a;
  box-shadow: 0px 1px 5px #2d2d2d4f;
  border: none;
  margin-left: ${(props) => (!props.ml ? "0px" : "20px")};
  margin-right: ${(props) => (!props.mr ? "0px" : "20px")};
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.defaultColorHover};
    text-shadow: none;
    box-shadow: none;
  }
  margin-left: ${(props) => (props.ml ? props.ml : "0px")};
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
  height: "36px";
`;

export const ButtonSecondaryStyle = styled.button`
  border: 1px solid ${theme.colors.defaultColor};
  background-color: transparent;
  opacity: ${(props) => (props.disabled ? "0.3" : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  border-radius: 3px;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 40px;
  margin-left: ${(props) => (!props.ml ? "0px" : "20px")};
  margin-right: ${(props) => (!props.mr ? "0px" : "20px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  padding: 5px;
  letter-spacing: -0.1px;
  color: ${theme.colors.defaultColor};
  text-transform: uppercase;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;
export const ErrosTextIputValidation = styled.label`
  color: ${theme.colors.errorColor};
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: 500;
`;

export const SelectStyle = styled.select`
  outline: none;
  border: 1px solid #e0e0e0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  text-align: start;
  border-radius: 3px;
  min-width: 100px;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-left: ${(props) => (props.ml ? props.ml : "0px")};
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.softGray};
  appearance: none;
  background: ${(props) =>
    props.disabled ? "" : ` url(${order}) no-repeat scroll 5px 5px`};
  background-position: right 5% bottom 50%;
  &:hover {
    border-color: ${theme.colors.defaultColor};
  }
  &:focus {
    border-color: ${theme.colors.defaultColor};
  }
`;

export const CheckBox = styled.input`
  display: inline-block;
  width: 20px;
  cursor: pointer;
  height: 20px;
  background: ${(props) =>
    !props.checked ? "white" : theme.colors.defaultColor};
  border: 1px solid ${theme.colors.defaultColor};
  border-radius: 10px;
  transition: all 150ms;
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #cfcfcf;
  &:focus,
  &:hover {
    text-decoration: none;

    color: ${theme.colors.defaultColor};
  }
`;

export const CheckBoxGroup = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
export const CheckBoxRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  align-items: center;
`;

export const GroupCheckBox = styled.div`
  display: flex;
  justify-content: start;
  flex-flow: column;
`;
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  height: 0px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
export const Icon = styled.svg`
  fill: none;
  stroke: white;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke-width: 3px;
  width: 20px;
  height: 20px;
`;

export const StyledCheckbox = styled.div`
  ${Icon} {
    visibility: ${(props) =>
      props.checked && !props.radius ? "visible" : "hidden"};
  }
  width: 23px;
  height: 23px;
  background: ${(props) =>
    props.checked ? theme.colors.defaultColor : "none"};
  border: 1px solid ${theme.colors.defaultColor};
  margin-right: 10px;
  border-radius: ${(props) => (props.radius ? "50px" : "3px")};
  transition: all 150ms;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const InputRowCheckBox = styled.div`
  display: flex;
  align-items: center;
`;

export const customStylesSelect = {
  container: (provided, state) => ({
    width: "100%",
    font: "arial",
    position: "relatove",
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    borderColor: "#004489",
    ...provided,
  }),
  control: (provided, state) => ({
    minHeight: "40px",
    placeholder: {
      fontSize: "14px",
      color: theme.colors.softGray,
    },
    ...provided,
    border: "1px solid #e0e0e0",
    "&:hover": {
      borderColor: theme.colors.defaultColor,
    },
  }),
  dropdownIndicator: (provided, state) => ({
    fill: theme.colors.defaultColor,
    svg: {
      fill: theme.colors.defaultColor,
      height: "15px",
    },
    ...provided,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  onFocus: (provided, state) => ({
    ...provided,
  }),
};

export const HiddenRadio = styled.input.attrs({ type: "radio" })`
  overflow: hidden;
  height: 20px;
  position: absolute;
  white-space: nowrap;
  width: 20px;
  margin: 1px;
  cursor: pointer;
  opacity: 0;
  z-index: 1000;
`;

export const RadioIcon = styled.span`
  position: absolute
  border-radius: 50%;
  background-color: ${(props) =>
    props.disabled ? theme.colors.ligthGray : theme.colors.defaultColor};
  min-height: 15px;
  min-width: 15px;
  margin: 3px;
  transition: opacity 150ms ease-in-out;
  opacity: ${(props) => (props.opacity ? props.opacity : 0)};
`;

export const StyledRadio = styled.div`
  width: 23px;
  height: 23px;
  border: 1px solid ${theme.colors.defaultColor};
  margin-right: 10px;
  margin-left: "-13px";
  border-radius: 50%;
  //display: ${(props) => (props.disabled ? "none" : "block")};
`;
