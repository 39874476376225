import "moment/locale/pt-br";

import * as urlsApi from "../../utils/contants/urlsApi";

import {
  buildErroHandleItems,
  buildDate
} from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";
import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/bankOfTalents";
import { history } from "../../utils/routes";
import { kindStages } from "../../locale/index";
import moment from "moment";

moment.locale("pt-BR");



function requestCandidadesBankOfTalentsList(body) {
  return API.put(
    `${urlsApi.REQUEST_CANDIDATES_BANK_OF_TALENTS}`,
    body
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

const filterCandidates = payload => {
  const body = {
    cpfList: payload.cpfList,
    idVagasList: payload.idVagasList,
    idLocaisList: payload.idLocaisList,
    dataInclusaoBanco: payload.dataInclusaoBanco ? buildDate(payload.dataInclusaoBanco):null,
    cargoList: payload.cargoList,
    idResponsavel: payload.idAdminsList,
    numeroPagina: payload.NumeroPagina,
    quantidadeItens: payload.quantidadeItens,
    situacao: payload.situacao,
    chapa: payload.chapa,
    tipoSelecao: payload.tipoSelecaoVaga
  };

  return body;
};

function* getCandidatesBankOfTalentsDocs({ payload }) {
  try {
    const candidateBankOfTalents = yield requestCandidadesBankOfTalentsList(filterCandidates(payload));


    const candidates =
    {
      candidatos: candidateBankOfTalents.map(e => ({
        cpf: e.cpf,
        idCandidatoNaVaga: e.id,
        idVaga: e.idVaga,
        nome: e.nome,
        nomeResponsavel: e.nomeResponsavel,
        diasNoBanco: e.diasNoBanco,
        dataInclusao: e.dataInclusaoBancoTalentos,
        dataVencimento: e.dataVencimento,
        chapa: e.chapa,
        justificativaBancoTalentos: e.justificativaBancoTalentos,
        robbysonStatus: e.robbysonStatus,
        cargo: e.cargoCandidato,
        site: e.site,
        tipoSelecao: e.tipoSelecaoVaga,
        motivoRemocaoBanco: e.motivoRemocaoBanco,
        dataRemocaoBancoTalentos: e.dataRemocaoBancoTalentos
      }))      
    };

    /*let candidatos;
    let candidatosPorVaga;
    const hasMore = payload.firstPage ? true : candidateVacancysDocs.candidatosNaEtapaList.length;
    const stateCandidateList = yield select(getStateCandidatesList);
    if (!payload.firstPage) {
      candidatos = stateCandidateList.candidatos.concat(candidates.candidatos);
      candidatosPorVaga = stateCandidateList.candidatosPorVaga.concat(candidates.candidatosPorVaga);
    }
    else {
      candidatos = candidates.candidatos;
      candidatosPorVaga = candidates.candidatosPorVaga;
    }
    const AllCandidates = { candidatos: candidatos, candidatosPorVaga: candidatosPorVaga }
    const nextPage = ++actualPage;*/


    yield put({
      type: Types.CANDIDATES_BANK_OF_TALENTS_SUCCESS,
      candidates: candidates

    });
  } catch (error) {
    yield put({ type: Types.CANDIDATES_BANK_OF_TALENTS_FAIL, error });
  }
}


function requestchangeStatusCandidate(payload) {
  return API.post(`${urlsApi.REQUEST_CHANGE_STATUS_CANDIDATES_BANK_OF_TALENTS}?idCandidatoVaga=${payload.idCandidatoVaga}&acaoAlteracaoStatus=${payload.acaoAlteracaoStatus}&justificativa=${payload.justificativa}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* changeStatusCandidate({ payload }) {
  try {
    yield requestchangeStatusCandidate(payload);

    
    yield put({
      type: Types.CHANGE_STATUS_CANDIDATE_SUCCESS

    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)

    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });

    yield put({ type: Types.CHANGE_STATUS_CANDIDATE_FAIL, error });
  }
}

export function* watcherSaga() 
{
  yield takeLatest(Types.CANDIDATES_BANK_OF_TALENTS_REQUEST, getCandidatesBankOfTalentsDocs);
  yield takeLatest(Types.CHANGE_STATUS_CANDIDATE, changeStatusCandidate);
  
}
