import PropTypes from "prop-types";
import React from "react";
import Avatar from "./avatar/Avatar";
import {
  DisplayHeader,
  HeaderBody,
  HeaderItem,
  UserItems,
} from "./HeaderStyle";
import { logoHeader } from "../../../assets/icons/icons";

function Header(props) {
  return (
    <HeaderBody>
      <DisplayHeader>
        <HeaderItem
          style={{
            width: "240px",
            textAlign: "center",
            margin: "0px 0px 0px 30px",
          }}
        >
          {logoHeader()}
        </HeaderItem>
        <HeaderItem>
          <UserItems>
            <Avatar />
          </UserItems>
        </HeaderItem>
      </DisplayHeader>
    </HeaderBody>
  );
}

Header.propTypes = {
  location: PropTypes.object,
};
Header.defaultProps = {
  location: {},
};
export default Header;
