import styled from "styled-components";
import theme from "../../shared/Theme";

export const DisclaimerQuestion = styled.span`
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-left: ${props => props.ml};
  color: ${theme.colors.darkGray};
  opacity: 0.9;
`;

export const ParagraphQuestion = styled.p`
  font-family: Arial;
  font-size: 14px;
  opacity: 0.9;
  color: ${theme.colors.darkGray};
`;

export const OptionsRows = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
  align-items: center;
  margin-top: ${props => (props.mt ? props.mt : "20px")};
`;

export const ActionRows = styled.div`
  margin-top: 53px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  margin-bottom: 28px;
`;

export const Image = styled.img`
  width: 150px;
  height: 100px;
  object-fit: contain;
  margin-left: 35px;
  margin-bottom: 35px;
  :hover {
    cursor: pointer;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const BoxImages = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex;
  flex-wrap: wrap;
`;

export const BoxActionImage = styled.div``;

export const SelectedImage = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${theme.colors.defaultColor};
  border-radius: 23px;
  position: absolute;
  margin-top: -127px;
  margin-left: 44px;
  padding: 2px;
`;

export const Circle = styled.div`
  background: ${props =>
    props.selected ? theme.colors.defaultColor : theme.colors.whiteDefault};
  width: 100%;
  height: 100%;
  border-radius: 14px;
`;
