import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  candidatesBankOfTalentsRequest: ['payload'],
  candidatesBankOfTalentsSuccess: ['stages', 'isLoading'],
  candidatesBankOfTalentsFail: ['isLoading', 'error'],

  changeStatusCandidate:['payload'],
  changeStatusCandidateSuccess: ['stages', 'isLoading'],
  changeStatusCandidateFail: ['isLoading', 'error']
});

const INITIAL_STATE = {
  candidates: {},
  isLoading: false,
  hasMore: true,
  page: 1
};


const candidatesBankOfTalentsRequest = state => ({ ...state, isLoading: true });
const candidatesBankOfTalentsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const candidatesBankOfTalentsFail = (state, error) => ({ ...state, isLoading: false, error });

const changeStatusCandidate = state => ({ ...state, isLoading: true });
const changeStatusCandidateSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const changeStatusCandidateFail = (state, error) => ({ ...state, isLoading: false, error });




export default createReducer(INITIAL_STATE, {
  [Types.CANDIDATES_BANK_OF_TALENTS_REQUEST]: candidatesBankOfTalentsRequest,
  [Types.CANDIDATES_BANK_OF_TALENTS_SUCCESS]: candidatesBankOfTalentsSuccess,
  [Types.CANDIDATES_BANK_OF_TALENTS_FAIL]: candidatesBankOfTalentsFail,

  [Types.CHANGE_STATUS_CANDIDATE]: changeStatusCandidate,
  [Types.CHANGE_STATUS_CANDIDATE_SUCCESS]: changeStatusCandidateSuccess,
  [Types.CHANGE_STATUS_CANDIDATE_FAIL]: changeStatusCandidateFail
});
