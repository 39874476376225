import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  allTypeNeighborhoodRequest: ["payload"],
  allTypeNeighborhoodRequestSuccess: ["payload", "isLoading"],
  allTypeNeighborhoodRequestFail: ["isLoading", "error"]
});

const INITIAL_STATE = {
  isLoading: true,
  optionsTypeNeighborhood: []
};

const allTypeNeighborhoodRequest = payload => ({
  ...payload
});
const allTypeNeighborhoodRequestSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const allTypeNeighborhoodRequestFail = state => ({
  ...state,
  isLoading: false
});



export default createReducer(INITIAL_STATE, {
  [Types.ALL_TYPE_NEIGHBORHOOD_REQUEST]: allTypeNeighborhoodRequest,
  [Types.ALL_TYPE_NEIGHBORHOOD_REQUEST_SUCCESS]: allTypeNeighborhoodRequestSuccess,
  [Types.ALL_TYPE_NEIGHBORHOOD_REQUEST_FAIL]: allTypeNeighborhoodRequestFail
});
