import styled from 'styled-components';
import theme from 'components/shared/Theme';

export const Body = styled.div`
    background-color: ${theme.colors.whiteDefault};
    padding: 20px;
    height:90%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 10px;
`;
