import React, { useState, createElement, useRef } from 'react';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, Modifier, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Icon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/fa/edit';
import { fileTextO } from 'react-icons-kit/fa/fileTextO';
import {remove} from 'react-icons-kit/fa/remove';

import Signature from '../../Signature';

import "./ContractForm.scss";

import {ButtonDefault,ButtonSecondary,SelectFieldInput,TextAreaField,TextField} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import BreadCrumb from "components/Layout/breadcrumb";

export const ContractForm = (props) => {

    const [contractText, setContractText] = useState(EditorState.createEmpty());
    const [showPreview, setShowPreview] = useState(false);
    const [contentPreview, setContentPreview] = useState('');

    const editorRef = useRef();

    const exportHtml = () => {
        const { editorState } = editorRef.current.props;
        let _html =  draftToHtml(convertToRaw(editorState.getCurrentContent()));
        _html = _html.replace(/(<a)/gi,'<span');
        _html = _html.replace(/(<\/a)/gi,'</span');
        console.log(_html);
        return _html;
      }

    const toolbar = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'list', 'textAlign', 'history'],
        inline: { inDropdown: false, options:['bold', 'italic', 'underline','superscript', 'subscript'] },
        list: { inDropdown: false },
        textAlign: { inDropdown: false },
        link: { inDropdown: false, options:[] },
        blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
          },
      }
    
    const customToolbar = () => {
        const buttons = [];

        const line = createElement('div', {title:'Assinatura', className:'rdw-option-wrapper', onClick:(e)=>{
            const { editorState } = editorRef.current.props;
            const blocksFromHTML = convertFromHTML(`<div>________________________________________________________________________</div>`);
            const { contentBlocks, entityMap } = blocksFromHTML;
            let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            contentState.getBlockMap()
            );
            setContractText(EditorState.push(editorState, contentState, 'insert-characters'));
        }}, <Icon icon={edit} />);
        buttons.push(line);

        const html = createElement('div', {title:'Visualizar', className:'rdw-option-wrapper', onClick:(e)=>{
            setContentPreview(exportHtml());
            setShowPreview(true);
        }}, <Icon icon={fileTextO} />);
        buttons.push(html);

        return buttons;
    }

    const mentions = {
        separator: ' ',
        trigger: '@',
        suggestions: [
            { text: 'CANDIDATO_NOME', value: '{candidato_nome}'},
            { text: 'CANDIDATO_TELEFONE', value: '{candidato_telefone}'},
            { text: 'CANDIDATO_CPF', value: '{candidato_cpf}'},
            { text: 'CANDIDATO_EMAIL', value: '{candidato_email}'},
            { text: 'CANDIDATO_CARTEIRA_TRABALHO', value: '{candidato_carteira_trabalho}'},
            { text: 'CANDIDATO_BAIRRO', value: '{candidato_bairro}'},
            { text: 'CANDIDATO_CIDADE', value: '{candidato_cidade}'},
            { text: 'CANDIDATO_ESTADO', value: '{candidato_estado}'},
            { text: 'EMPRESA_NOME', value: '{empresa_nome}'},
            { text: 'EMPRESA_BAIRRO', value: '{empresa_bairro}'},
            { text: 'EMPRESA_CIDADE', value: '{empresa_cidade}'},
            { text: 'EMPRESA_ESTADO', value: '{empresa_estado}'},
            { text: 'EMPRESA_CNPJ', value: '{empresa_cnpj}'},
            { text: 'VAGA_INICIO', value: '{vaga_inicio}'},
            { text: 'VAGA_FIM', value: '{vaga_fim}'},
            { text: 'DATA_ATUAL', value: '{data_atual}'},
        ],
      }

      

    const onChangeContract = (e) => {

        setContractText(e);
    }   

    return (
        <div className="contract-from">
            <Row>
                <Col lg={{ span: 5 }}>
                <BreadCrumb
                    location={props.location}
                    title={<FormattedMessage id="defaultStage.stagesNames.contract" />}
                />
                </Col>
                <Col lg={{ span: 3 }}>
                {/* <ButtonSecondary
                    onClick={() => setShowJobAssociated(true)}
                    disabled={stageId === undefined || !canEdit()}
                    name={<FormattedMessage id="newStageAboutYou.associationJob" />}
                /> */}
                </Col>
                <Col lg={2}>
                {/* <ButtonDefault
                    disabled={(!canEdit())}
                    onClick={() => saveTest()}
                    width={"100%"}
                    name={<FormattedMessage id="sharedItems.save" />}
                /> */}
                </Col>
                <Col lg={2}>
                {/* <ButtonDefault
                    disabled={!stageId || !canEdit()}
                    width={"100%"}
                    onClick={() => publishTest()}
                    name={<FormattedMessage id="sharedItems.finish" />}
                /> */}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="area-editor">
                        <Editor
                            ref={editorRef}
                            toolbar={toolbar}
                            toolbarCustomButtons={customToolbar()}
                            editorState={contractText}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onChangeContract}
                            mention={mentions}
                            localization={{
                                locale: 'pt',
                              }}
                        />
                        <div style={{display: (showPreview ? 'flex' : 'none')}} className="preview-html">
                            <div className="box" >
                                <header>
                                    <div className="title">
                                        Visualizar contrato
                                    </div>
                                    <div>
                                        <Icon onClick={()=>{ setContentPreview(''); setShowPreview(false); }} icon={remove} />
                                    </div>
                                </header>
                                <section dangerouslySetInnerHTML={{__html:contentPreview}}></section>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Signature />
                    </div>
                </Col>
            </Row>
        </div>
    )
}