import { createActions, createReducer } from 'reduxsauce';



export const { Types, Creators } = createActions({
  candidatesVacancysContractsRequest: ['payload'],
  candidatesVacancysContractsSuccess: ['state', 'isLoading'],
  candidatesVacancysContractsFail: ['isLoading', 'error'],


  candidateContractRequest: ['payload'],
  candidateFilesSuccess: ['stages', 'isLoading'],
  candidateFilesFail: ['isLoading', 'error'],

  adtiveTypesRequest: ['payload'],
  adtiveTypesSuccess: ['isLoading'],
  adtiveTypesFail: ['isLoading'],

  candidateSetStatusContractFileRequest: ['payload'],
  candidateSetStatusFileSuccess: ['stages', 'isLoading'],
  candidateSetStatusFileFail: ['isLoading', 'error'],


  candidateContractSetStatusStageRequest: ['payload'],
  candidateSetStatusStageSuccess: ['stages', 'isLoading'],
  candidateSetStatusStageFail: ['isLoading', 'error'],


  downloadContractFilesRequest: ['payload'],
  downloadContractFilesSuccess: ['stages', 'isLoading'],
  downloadContractFilesFail: ['isLoading', 'error'],

  candidateIncludeNewContractRequest: ['payload'],
  candidateIncludeNewContractSuccess: ['stages', 'isLoading'],
  candidateIncludeNewContractFail: ['isLoading', 'error'],

  candidateChangeKitContractRequest: ['payload'],
  candidateChangeKitContractSuccess: ['isLoading'],
  candidateChangeKitContractFail: ['isLoading', 'error'],

  candidateContractResendRequest: ["payload"],
  candidateContractResendSuccess: ["isLoading"],
  candidateContractResendFail: ["isLoading"],


  typeContractsRequest: ["payload"],
  typeContractsSuccess: ["isLoading"],
  typeContractsFail: ["isLoading"],

  candidateChangeContractRequest: ['payload'],
  candidateChangeContractSuccess: ['stages', 'isLoading'],
  candidateChangeContractFail: ['isLoading', 'error']
});



const INITIAL_STATE = {
  candidates: {
    candidatos: [],
    candidatosPorVaga: []
  },
  candidatesFiles: {
    nome: {},
    filesList: [],
    filesBaseList: [],
    filesContractList: []
  },
  adtiveTypes: [],
  isLoading: true,
  typesContract: [],
  hasMore: true,
  page: 1
};


const candidatesVacancysContractsRequest = state => ({ ...state, isLoading: true });
const candidatesVacancysContractsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const candidatesVacancysContractsFail = (state, error) => ({ ...state, isLoading: false, error });




const candidateContractRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const candidateFilesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const candidateFilesFail = (state, error) => ({ ...state, isLoading: false, error });

const adtiveTypesRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const adtiveTypesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const adtiveTypesFail = (state, error) => ({ ...state, isLoading: false, error });

const candidateSetStatusContractFileRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const candidateSetStatusFileSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const candidateSetStatusFileFail = (state, error) => ({ ...state, isLoading: false, error });




const candidateContractSetStatusStageRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const candidateSetStatusStageSuccess = (state, payload) => ({
  ...payload,
  isLoading: false
});
const candidateSetStatusStageFail = (state, error) => ({ ...state, isLoading: false, error });

const downloadContractFilesRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const downloadContractFilesSuccess = (state, payload) => ({
  ...payload,
  isLoading: false
});
const downloadContractFilesFail = (state, error) => ({ ...state, isLoading: false, error });



const candidateIncludeNewContractRequest = state => ({ ...state, isLoading: true });
const candidateIncludeNewContractSuccess = (state, payload) => ({
  ...payload,
  isLoading: false
});
const candidateIncludeNewContractFail = (state, error) => ({ ...state, isLoading: false, error });




const candidateChangeKitContractRequest = state => ({ ...INITIAL_STATE, isLoading: true });
const candidateChangeKitContractSuccess = (state, payload) => ({
  ...payload,
  isLoading: false
});
const candidateChangeKitContractFail = (state, error) => ({ ...state, isLoading: false, error });





const typeContractsRequest = state => ({ ...INITIAL_STATE, isLoading: true });

const typeContractsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const typeContractsFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});





const candidateContractResendRequest = state => ({
  ...state,
  isLoading: true
});

const candidateContractResendSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const candidateContractResendFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});



export default createReducer(INITIAL_STATE, {
  [Types.CANDIDATES_VACANCYS_CONTRACTS_REQUEST]: candidatesVacancysContractsRequest,
  [Types.CANDIDATES_VACANCYS_CONTRACTS_SUCCESS]: candidatesVacancysContractsSuccess,
  [Types.CANDIDATES_VACANCYS_CONTRACTS_FAIL]: candidatesVacancysContractsFail,

  [Types.CANDIDATE_CONTRACT_REQUEST]: candidateContractRequest,
  [Types.CANDIDATE_FILES_SUCCESS]: candidateFilesSuccess,
  [Types.CANDIDATE_FILES_FAIL]: candidateFilesFail,

  [Types.ADTIVE_TYPES_REQUEST]: adtiveTypesRequest,
  [Types.ADTIVE_TYPES_SUCCESS]: adtiveTypesSuccess,
  [Types.ADTIVE_TYPES_FAIL]: adtiveTypesFail,

  [Types.TYPE_CONTRACTS_REQUEST]: typeContractsRequest,
  [Types.TYPE_CONTRACTS_SUCCESS]: typeContractsSuccess,
  [Types.TYPE_CONTRACTS_FAIL]: typeContractsFail,



  [Types.CANDIDATE_SET_STATUS_CONTRACT_FILE_REQUEST]: candidateSetStatusContractFileRequest,
  [Types.CANDIDATE_SET_STATUS_FILE_SUCCESS]: candidateSetStatusFileSuccess,
  [Types.CANDIDATE_SET_STATUS_FILE_FAIL]: candidateSetStatusFileFail,


  [Types.CANDIDATE_CONTRACT_SET_STATUS_STAGE_REQUEST]: candidateContractSetStatusStageRequest,
  [Types.CANDIDATE_SET_STATUS_STAGE_SUCCESS]: candidateSetStatusStageSuccess,
  [Types.CANDIDATE_SET_STATUS_STAGE_FAIL]: candidateSetStatusStageFail,

  [Types.DOWNLOAD_CONTRACT_FILES_REQUEST]: downloadContractFilesRequest,
  [Types.DOWNLOAD_CONTRACT_FILES_SUCCESS]: downloadContractFilesSuccess,
  [Types.DOWNLOAD_CONTRACT_FILES_FAIL]: downloadContractFilesFail,

  [Types.CANDIDATE_INCLUDE_NEW_CONTRACT_REQUEST]: candidateIncludeNewContractRequest,
  [Types.CANDIDATE_INCLUDE_NEW_CONTRACT_SUCCESS]: candidateIncludeNewContractSuccess,
  [Types.CANDIDATE_INCLUDE_NEW_CONTRACT_FAIL]: candidateIncludeNewContractFail,


  [Types.CANDIDATE_CONTRACT_RESEND_REQUEST]: candidateContractResendRequest,
  [Types.CANDIDATE_CONTRACT_RESEND_SUCCESS]: candidateContractResendSuccess,
  [Types.CANDIDATE_CONTRACT_RESEND_FAIL]: candidateContractResendFail,


  [Types.CANDIDATE_CHANGE_KIT_CONTRACT_REQUEST]: candidateChangeKitContractRequest,
  [Types.CANDIDATE_CHANGE_KIT_CONTRACT_SUCCESS]: candidateChangeKitContractSuccess,
  [Types.CANDIDATE_CHANGE_KIT_CONTRACT_FAIL]: candidateChangeKitContractFail,


});
