import styled from 'styled-components';
import theme from '../../shared/Theme';

export const RowOptions = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  opacity: ${props => (!props.stageId ? '0.35' : 1)};
  pointer-events: ${props => (!props.stageId ? 'none' : 'auto')};
  align-items: center;
  margin-top: 60px;
  margin-bottom: 50px;
  background-color: ${theme.colors.gray};
  padding: 50px;
`;

export const Disclaimer = styled.span`
  width: 50%;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
`;
export const OptionsSpan = styled(Disclaimer)`
  width: 100%;
`;
export const OptionBox = styled.div`
  width: 150px;
  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: center;
  fill: red;
  &:hover {
    svg {
      g {
        stroke: ${theme.colors.titleColor};
      }
      circle {
        stroke: ${theme.colors.titleColor};
      }
      path {
        fill: ${theme.colors.titleColor};
      }
      cursor: pointer;
      transition: all 0.25s;
    }
    span {
      cursor: pointer;
      color: ${theme.colors.titleColor};
      transition: color 0.25s;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;
