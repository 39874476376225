import * as urls from "utils/contants/urls";
import * as Permissions from "utils/PerfilAcesso";
import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { STAGES } from "utils/contants/urls"
import StageDefault from "components/Stage/StageDefault";
import StagePosition from "components/StagePosition/";
import { connect } from "react-redux";
import { push } from "connected-react-router"
import { Creators as stagePosition } from "store/ducks/stagePositions";
import { Creators as stages } from "store/ducks/stages";

const DefaultStages = ({
  requestShowStage,
  location,
  requestInfosStage,
  match,
  stage,
  options,
  positions,
  requestStagesPositions,
  requestRegisterPositions,
  updateStage,
  requestCreateStage,
  goBackPreviousPage,
  pagination,
}) => {
  const [itemInfo, setItemInfo] = useState({
    name: <FormattedMessage id="defaultStage.stagesNames.test" />,
    kind: 1
  });


  const [showJobAssociation, setShowJobAssociation] = useState(false);
  useEffect(() => {
    switch (location.pathname.replace(/[//"][0-9]*[0-9]/g, "")) {
      case urls.STAGES || `${urls.STAGES}/`:
        setItemInfo({ name: "Nova Etapa", kind: 0 });
        break;
      case urls.STAGES_TESTS || `${urls.STAGES_TESTS}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.test" />,
          kind: 1
        });
        requestInfosStage("testeInfo");
        break;
      case urls.STAGES_TRAINNER || `${urls.STAGES_TRAINNER}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.trainner" />,
          kind: 2
        });
        requestInfosStage("trainnerInfo");
        break;
      case urls.STAGES_ASSAY || `${urls.STAGES_ASSAY}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.assay" />,
          kind: 3
        });
        requestInfosStage("assayInfo");
        break;
      case `${urls.STAGES_MEDIC_EXAME}` || `${urls.STAGES_MEDIC_EXAME}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.medicExame" />,
          kind: 4
        });
        requestInfosStage("medicExameInfo");
        break;
      case urls.STAGES_ADMITION || `${urls.STAGES_ADMITION}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.admition" />,
          kind: 5
        });
        break;
      case urls.STAGES_AMBIENTATION || `${urls.STAGES_AMBIENTATION}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.ambietation" />,
          kind: 6
        });
        requestInfosStage("ambientationInfo");
        break;
      case urls.STAGES_FEEDBACK || `${urls.STAGES_FEEDBACK}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.feedBack" />,
          kind: 7
        });
        break;
      case urls.STAGES_PRE_ADMITION || `${urls.STAGES_PRE_ADMITION}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.preAdmition" />,
          kind: 8
        });
        break;
        case urls.STAGES_CONTRACT || `${urls.STAGES_CONTRACT}/`:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.contract" />,
          kind: 9
        });
        break;
      default:
        setItemInfo({
          name: <FormattedMessage id="defaultStage.stagesNames.newEstage" />,
          kind: 1
        });
    }

    if (match.params && match.params.id) {
      requestShowStage(match.params.id);
    }
  
    requestStagesPositions({ page: 1 });
  }, []);

  if (showJobAssociation) {
    return (
      <StagePosition
        stageId={stage.stageId}
        registerStagesPositions={requestRegisterPositions}
        previousPosition={stage.positions}
        positions={positions}
        backCreate={() => setShowJobAssociation(!showJobAssociation)}
        requestStagesPositions={requestStagesPositions}
        pagination={pagination}
      />
    );
  }
  return (
    <StageDefault
      showJobAssociation={() => setShowJobAssociation(!showJobAssociation)}
      options={options}
      updateStage={updateStage}
      stage={stage}
      nameForm={itemInfo.name}
      formKind={itemInfo.kind}
      kind={itemInfo.kind}
      requestCreateStage={requestCreateStage}
      location={location}
      goBackPreviousPage={goBackPreviousPage}
      canEdit ={() => Permissions.canEditEtapa()}
    />
  );
};
const mapDispatchToProps = dispatch => ({
  requestStagesPositions: (filters) => dispatch(stagePosition.stagePositionRequest(filters)),
  requestRegisterPositions: roles =>
    dispatch(stagePosition.stagePositionRegisterRequest(roles)),
  requestCreateStage: payload => dispatch(stages.stageCreateRequest(payload)),
  requestInfosStage: formKind => dispatch(stages.stageInfosRequest(formKind)),
  updateStage: payload => dispatch(stages.stageUpdateRequest(payload)),
  publishStage: id => dispatch(stages.stagePublishRequest(id)),
  requestShowStage: id => dispatch(stages.stageShowRequest(id)),
  goBackPreviousPage: () => dispatch(push(STAGES)),
});
const mapStateToProps = state => ({
  positions: state.stagesPosition,
  options: state.stages.options,
  stage: state.stages.stage,
  isLoading: state.stages.isLoading,
  location: state.router.location,
  pagination: state.stagesPosition.pagination,
});
DefaultStages.propTypes = {
  requestStagesPositions: PropTypes.func,
  requestInfosStage: PropTypes.func,
  location: PropTypes.objectOf({
    pathname: "",
    hash: "",
    key: "",
    search: "",
    state: ""
  }),
  options: PropTypes.arrayOf({
    id: "",
    name: ""
  })
};
DefaultStages.defaultProps = {
  requestStagesPositions: () => {},
  requestInfosStage: () => {},
  stage: {},
  location: {
    pathname: "",
    hash: "",
    key: "",
    search: "",
    state: ""
  },
  options: []
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultStages);
