import * as urlsApi from "../../utils/contants/urlsApi";

import { buildErroHandleItems, buildPagination } from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/employee";

function getEmployeeSearchApi(params) {
  return API.get(
    `${urlsApi.EMPLOYEE_BY_REGISTER_NUMBER}?matricula=${params}`
  )
    .then(res => res.data)
    .catch(error => { throw error });
}

function* getEmployeeSearch({ registerNumber }) {
  try {
    if (registerNumber === '') {
      yield put({ type: Types.EMPLOYEE_SEARCH_SUCCESS, employee: {} });
    }
    else {
      const response = yield getEmployeeSearchApi(registerNumber);
      const funcionario = { ...response }
      yield put({ type: Types.EMPLOYEE_SEARCH_SUCCESS, employee: funcionario });
    }
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.EMPLOYEE_SEARCH_FAIL, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.EMPLOYEE_SEARCH_REQUEST, getEmployeeSearch);
}
