import React, { useEffect, useState, useRef } from "react";

import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { Body } from "./style";
import { Creators as messagesAction } from "../../store/ducks/messages";

const MessageHandler = ({ messages, resetMessage }) => {
  const [showMessage, setShowMessage] = useState(true);
  const [messagesToShow, setShowMessages] = useState([]);

  const timer = useRef();

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = null;
    setShowMessage(true);
    setShowMessages(messages);

    if (messages.length == 1 && messages[0].type == "")
      return;

    timer.current = setTimeout(() => {
      clearTimeout(timer.current);
      setShowMessage(false);
      resetMessage();
    }, 5000);

  }, [messages]);

  return (
    <Body>
      {showMessage &&
        messagesToShow &&
        messagesToShow.map((e, index) => {
          return <Alert variant={e.type} key={index}>{e.message}</Alert>;
        })}
    </Body>
  );
};

const mapDispatchToProps = dispatch => ({
  resetMessage: () => dispatch(messagesAction.messagePublication([{ type: "", message: ""}]))
});

const mapStateToProps = state => ({ messages: state.messages.messages });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageHandler);
