import React from "react";
import { Route, Switch, Redirect } from "react-router";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import isAuthenticated from "./isAuthenticated";
import * as urls from "./contants/urls";
import Login from "../containers/Auth/Login";
import Home from "../containers/Home";
import DashBoardTalents from "../containers/Modules/Talents/DashBoardTalents";
import DashBoardPlug from "../containers/Modules/Plug/DashBoardPlug";
import DashBoardManager from "../containers/Modules/Manager/DashBoardManager";
import ChangePassword from "../containers/Auth/ChangePassword";
import SignatureRemote from "../components/Signature/SignatureRemote";

export const history = createBrowserHistory();

export const MY_PROFILE = "/candidato/meu-perfil"; // Mova a declaração de MY_PROFILE para cima
export const CANDIDATE_HISTORY = "/candidato/meu-historico";
export const CANDIDATE_VACANCY = "/candidato/vaga";
export const LOGIN = "/login";
export const LOGOUT = "/logout";

export const PERSONAL_INFORMATION = `${MY_PROFILE}?informacao-pessoal`;
export const ABOUT_YOU = `${MY_PROFILE}?sobre-voce`;
export const AVAILABILITY = `${MY_PROFILE}?disponibilidade`;
export const FORMATION = `${MY_PROFILE}?formacao`;
export const CAREER = `${MY_PROFILE}?carreira`;
export const SKILLS = `${MY_PROFILE}?competencias`;
export const LANGUAGES = `${MY_PROFILE}?linguas`;
export const PRE_ADMISSION = `${MY_PROFILE}?pre-admissao`;
export const CERTIFICATIONS = `${MY_PROFILE}?premios-e-certificacoes`;
export const SOCIAL_MEDIA = `${MY_PROFILE}?redes-sociais`;
export const INTERESTS = `${MY_PROFILE}?interesses`;
export const EDIT_PASSWORD = `${MY_PROFILE}?editar-senha`;
const START_TEST = "/iniciar-teste";
export const TEST = "/teste";
export const PRIVATE_ROUTERS = [
  MY_PROFILE,
  PERSONAL_INFORMATION,
  ABOUT_YOU,
  FORMATION,
  CAREER,
  SKILLS,
  LANGUAGES,
  CERTIFICATIONS,
  SOCIAL_MEDIA,
  INTERESTS,
  EDIT_PASSWORD,
  CANDIDATE_VACANCY,
  LOGIN,
  START_TEST
];

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
    }
  />
);

const Routes = props => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path={`/Signature/:token?`} component={SignatureRemote} />
      <PrivateRoute exact path={urls.APP} component={Home} />
      <PrivateRoute path={urls.TALENTS_AEC} component={DashBoardTalents} />
      <PrivateRoute exact path={urls.NEW_PASSWORD} component={ChangePassword} />
      <PrivateRoute path={urls.PLUG_AEC} component={DashBoardPlug} />
      <PrivateRoute path={urls.MANAGER_AEC} component={DashBoardManager} />
    </Switch>
  </ConnectedRouter>
);

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.string,
  history: PropTypes.object
};

PrivateRoute.defaultProps = {
  component: PropTypes.func,
  history: PropTypes.object
};

export default Routes;
