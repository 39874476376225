import theme, { size } from '../../shared/Theme';

import styled from 'styled-components';

export const BodyBlock = styled.div`
  min-height: 186px;
  border-radius: 16px;
  border: solid 1px ${theme.colors.ligthGray};
  background-color: ${theme.colors.whiteDefault};
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px;
`;

export const NumberOfItem = styled.div`
  width: 37px;
  height: 37px;
  text-align: center;
  border: 2px solid ${theme.colors.titleColor};
  border-radius: 20px;
  margin-left: 11px;
  margin-right: 11px;
  &:hover {
    cursor: pointer;
  }
`;

export const DescriptionSpan = styled.span`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.colors.darkGray};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-flow: column;
  @media (max-width: ${size.tablet}) {
    margin: 5px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  @media (max-width: ${size.tablet}) {
    margin: 5px 0px 5px 0px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-flow: row;
  @media (max-width: ${size.mobileL}) {
    flex-flow: column;
  }
`;
