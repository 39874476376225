import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Amount,
  Body,
  Controls,
  Icons,
  LabelAmout,
  LabelPages,
  PageIcon,
  Pages,
} from "./PaginationStyle";
import {
  nextPage,
  previousPage,
  forwardTenPages,
  backTenPages,
} from "../../assets/icons/icons";

const calcNumberOfPages = (itens, sizeList = 10) => {
  const pages =
    itens % sizeList > 0
      ? parseInt(itens / sizeList + 1, 10)
      : parseInt(itens / sizeList, 10);

  return pages;
};

const renderPages = (itens, selected, sizeList) => {
  const pages = calcNumberOfPages(itens, sizeList);
  const icons = [];

  if (selected >= 3 && selected < pages) {
    icons.push(1);
    icons.push("...");
    icons.push(selected - 1);
    icons.push(selected);
    icons.push(selected + 1);
  } else if ((selected < 3 && pages > 3) || pages == 3) {
    icons.push(1);
    icons.push(2);
    icons.push(3);
  } else if (selected < 3 && pages < 3) {
    for (let i = 0; i < pages; i += 1) 
      icons.push(i + 1);
  }

  if (selected < pages - 1 && pages > 3) {
    icons.push("...");
    icons.push(pages);
  } else if (selected === pages && pages > 3) {
    icons.push(1);
    icons.push("...");
    icons.push(selected - 2);
    icons.push(selected - 1);
    icons.push(selected);
  }

  return icons;
};

const Pagination = (props) => {

  const [pageSelected, setPage] = useState(props.currentPage ? props.currentPage : 1);
  const { currentPage, sizeItems, sizeList, pageSize } = props;
  const { getItemsPage } = props;

  // console.log("currentPage: ", currentPage);
  // console.log("sizeItems: ", sizeItems);
  // console.log("sizeList: ", sizeList);

  // console.log("pageSize: ", pageSize);

  const requestSpecificPage = (page) => {

    const newPage = {
      campo: props.filter,
      valor: props.filterValue,
      NumeroPagina: page,
    };
    
    setPage(page);
    getItemsPage(newPage);

  };

  const requestNextPage = (page) => {
    if (page < calcNumberOfPages(sizeItems, sizeList)) {
      setPage(page + 1);
      const newPage = {
        campo: props.filter,
        valor: props.filterValue,
        NumeroPagina: page + 1,
      };
      getItemsPage(newPage);
    }
  };

  const requestPreviousPage = (page) => {

    if (page > 1) {
      setPage(page - 1);
      const newPage = {
        campo: props.filter,
        valor: props.filterValue,
        NumeroPagina: page - 1,
      };
      getItemsPage(newPage);
    }
  };

  const requestForwardTenPages = (items, page) => {
    const targetPage = page + 10;
    const numberOfPages = calcNumberOfPages(items);
    if (targetPage <= numberOfPages) {
      requestSpecificPage(targetPage);
    }
  };

  const requestBackTenPages = (page) => {
    const targetPage = page - 10;
    if (targetPage >= 1) {
      requestSpecificPage(targetPage);
    }
  };

  return (
    <Body>
      <Amount>
        <LabelAmout>Página</LabelAmout>
        <LabelPages>{currentPage}</LabelPages>
        <LabelAmout>-</LabelAmout>
        <LabelPages>{sizeList}</LabelPages>
        <LabelAmout>
          <span style={{ marginRight: "5px" }}>de</span>
          {sizeItems}
        </LabelAmout>
      </Amount>
      <Pages>
        {renderPages(sizeItems, pageSelected, pageSize).map((page, index) => (
          <PageIcon
            onClick={
              page !== "..." && page !== currentPage
                ? () => requestSpecificPage(page)
                : null
            }
            selected={page === currentPage && page !== "..."}
            hasAction={page !== "..."}
            key={`${page + index}-page`}
          >
            {page}
          </PageIcon>
        ))}
      </Pages>
      <Controls>
        <Icons onClick={() => requestPreviousPage(currentPage)}>
          {previousPage()}
        </Icons>
        {calcNumberOfPages(sizeItems) > 10 && (
          <>
            <Icons onClick={() => requestBackTenPages(currentPage)}>
              {backTenPages()}
            </Icons>
            <Icons
              onClick={() => requestForwardTenPages(sizeItems, currentPage)}
            >
              {forwardTenPages()}
            </Icons>
          </>
        )}
        <Icons onClick={() => requestNextPage(currentPage)}>{nextPage()}</Icons>
      </Controls>
    </Body>
  );
};


Pagination.propTypes = {
  currentPage: PropTypes.number,
  sizeItems: PropTypes.number,
  sizeList: PropTypes.number,
  getItemsPage: PropTypes.func,
  filter: PropTypes.string,
  filterValue: PropTypes.string,
};

Pagination.defaultProps = {
  currentPage: 1,
  sizeItems: 0,
  sizeList: 0,
  filter: "",
  filterValue: 0,
  getItemsPage: () => {},
};
export default Pagination;
