import React from 'react';

import { ToolTip } from 'components/shared/CustomComponents';
import {
  DotStage,
  LineStatus,
  BoxStageStatus,
  RowTimeLine,
  LabelNumber,
  DotBox,
} from './TimeLineStyle';

export default function TimeLine({ dots }) {
  return (
    <RowTimeLine>
      {dots.map((dot, index) => (
        <BoxStageStatus>
          <BoxStageStatus>
            <DotBox>
              <DotStage data-for={`tooltip-candidates-${index}`} data-tip="data-tip" {...dot}>
                <LabelNumber>{dot.position}</LabelNumber>
              </DotStage>
            </DotBox>
            {index + 1 !== dots.length && (
              <React.Fragment>
                <ToolTip id={`tooltip-candidates-${index}`} message={dot.name} />
                <LineStatus
                  approved={dot.approved}
                  nextStatus={dots[index + 1] && dots[index + 1].approved}
                />
              </React.Fragment>
            )}
          </BoxStageStatus>
        </BoxStageStatus>
      ))}
    </RowTimeLine>
  );
}
