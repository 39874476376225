import styled from 'styled-components';
import theme from '../../shared/Theme';

export const DisclaimerQuestion = styled.span`
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-left: ${props => props.ml};
  color: ${theme.colors.darkGray};
  opacity: 0.9;
`;

export const ParagraphQuestion = styled.p`
  font-family: Arial;
  font-size: 14px;
  opacity: 0.9;
  color: ${theme.colors.darkGray};
`;

export const OptionsRows = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
  align-items: center;
  margin-top: ${props => (props.mt ? props.mt : '20px')};
`;

export const ActionRows = styled.div`
  margin-top: 53px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  margin-bottom: 28px;
`;
