import {
  ButtonDefault,
  ButtonSecondary,
  CheckBox
} from "components/shared/CustomInputs";
import { Col, Modal, Row } from "react-bootstrap";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { LabelH1 } from "components/shared/CustomComponents";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import { order as orderIcon } from "../../../assets/icons/icons";
import { orderList } from "../../../helpers/sharedHelpers";

export default function DialogList({
  showDialog,
  kindStage,
  stages,
  stagesToAddInVacancy,
  setStagesToAddInVacancy,
  showDialogFilter,
  showDialogAndReturnFilter,
  closeDialog,
  pagination,
  insertStagesToRail,
  requestStagesByFilter,
  filter
}) {
  const [insertedItems, setInsertedItems] = useState(false);
  const [stageList, setStageList] = useState([]);
  const [order, setOrder] = useState(undefined);
  const [filterSaved, setFilterSaved] = useState({});

  useEffect(() => {
    if (filter.kindStage > 0)
      setFilterSaved(filter);
  }, [filter]);

  useEffect(() => {
    setStageList(stages);
  }, [stages]);


  const getStagesPage = (filters) => {
    requestStagesByFilter(Object.assign(filterSaved, { NumeroPagina: filters.NumeroPagina }));
  }
  const addItemToList = id => {
    if (stagesToAddInVacancy.includes(id)) {
      const dupItems = stagesToAddInVacancy.filter(e => e !== id);
      setStagesToAddInVacancy(dupItems);
      setInsertedItems(!insertedItems);
    } else {
      const dupItems = stagesToAddInVacancy;
      dupItems.push(id);
      setStagesToAddInVacancy(dupItems);
      setInsertedItems(!insertedItems);
    }
  };

  const orderBy = orderBy => {
    let response;
    switch (orderBy) {
      case "kind":
        response = orderList("kindTesteItem", order, stages);
        break;
      case "name":
        response = orderList("name", order, stages);
        break;
      case "level":
        response = orderList("level", order, stages);
        break;
      case "avaliation":
        response = orderList("avaliation", order, stages);
        break;
      case "timeTestMinutes":
        response = orderList("timeExame", order, stages);
        break;
      case "amountOfQuestions":
        response = orderList("amountOfQuestions", order, stages);
        break;
      default:
        response = { list: stages, order: undefined };
        break;
    }

    setStageList(response.list);
    setOrder(response.order);
  };

  const renderIcon = key => {
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` }
    });
  };

  const renderTableItens = items =>
    items.map(rail => (
      <TableRow key={rail.id}>
        <TableColumn ta="left">
          <CheckBox
            checked={stagesToAddInVacancy.includes(rail.id)}
            onChange={() => addItemToList(rail.id)}
          />
          <Label>{rail.name}</Label>
        </TableColumn>
        {rail.kindStage === 1 && (
          <React.Fragment>
            <TableColumn ta="left">
              <Label>{rail.level}</Label>
            </TableColumn>
            <TableColumn ta="left">
              <Label>{rail.avaliation}</Label>
            </TableColumn>
            <TableColumn ta="left">
              <Label>{rail.timeExame}</Label>
            </TableColumn>
            <TableColumn ta="left">
              <Label>{rail.amountOfQuestions}</Label>
            </TableColumn>
          </React.Fragment>
        )}
      </TableRow>
    ));
  return (
    <Modal size="xl" show={showDialog}>
      <Modal.Header>
        <Row>
          <Col>
            <LabelH1>
              <FormattedMessage id="rails.addStageTorailsList" />
            </LabelH1>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Table>
          {kindStage === 1 ? (
            <React.Fragment>
              <TableHead noBorderTop ta="left" onClick={() => orderBy("name")}>
                <LabelHeader ta="left">
                  {<FormattedMessage id="stageList.table.nameTest" />}
                </LabelHeader>
                {renderIcon("name")}
              </TableHead>
              <TableHead noBorderTop ta="left" onClick={() => orderBy("level")}>
                <LabelHeader ta="left">
                  {<FormattedMessage id="stageList.table.level" />}
                </LabelHeader>
                {renderIcon("level")}
              </TableHead>
              <TableHead
                noBorderTop
                ta="left"
                onClick={() => orderBy("avaliation")}
              >
                <LabelHeader ta="left">
                  {<FormattedMessage id="stageList.table.avaliation" />}
                </LabelHeader>
                {renderIcon("avaliation")}
              </TableHead>
              <TableHead
                noBorderTop
                ta="left"
                onClick={() => orderBy("timeTestMinutes")}
              >
                <LabelHeader ta="left">
                  {<FormattedMessage id="stageList.table.questionsTime" />}
                </LabelHeader>
                {renderIcon("timeExame")}
              </TableHead>
              <TableHead
                noBorderTop
                ta="left"
                onClick={() => orderBy("amountOfQuestions")}
              >
                <LabelHeader ta="left">
                  {<FormattedMessage id="stageList.table.amountOfQuestions" />}
                </LabelHeader>
                {renderIcon("amountOfQuestions")}
              </TableHead>
              <TableHead noBorderTop ta="left" />
            </React.Fragment>
          ) : (
              <TableHead noBorderTop ta="left" onClick={() => orderBy("name")}>
                <LabelHeader ta="left">
                  {<FormattedMessage id="stageList.table.title" />}
                </LabelHeader>
                {renderIcon("name")}
              </TableHead>
            )}
          <TableBody>{renderTableItens(stages)}</TableBody>
        </Table>

        <Pagination {...pagination} getItemsPage={(e) => getStagesPage(e)} />
        <Row>
          <Col lg={{ span: 2, offset: 6 }}>
            <ButtonSecondary
              onClick={() => showDialogFilter(false)}
              name={<FormattedMessage id="sharedItems.cancel" />}
            />
          </Col>
          <Col lg={{ span: 2 }}>
            <ButtonDefault
              onClick={() => showDialogAndReturnFilter()}
              name={<FormattedMessage id="sharedItems.back" />}
            />
          </Col>
          <Col lg={{ span: 2 }}>
            <ButtonDefault
              disabled={stagesToAddInVacancy.length === 0}
              onClick={() => insertStagesToRail(insertedItems)}
              name={<FormattedMessage id="sharedItems.add" />}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
DialogList.prototype = {
  stages: PropTypes.arrayOf({})
};

DialogList.defaultProps = {
  stages: []
};
