import styled from 'styled-components';
import theme from '../../shared/Theme';

export const Body = styled.div`
margin-top: 5px;
background-color: ${theme.colors.whiteDefault};
padding:30px
`;

export const BoxAction = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top: 30px;
`;
