import * as urlsApi from "../contants/urlsApi";

import { APP } from "../contants/urls"
import axios from "axios";
import { history } from "../../utils/routes"

const API = axios.create({
  withCredentials: true,
  baseURL: urlsApi.basePath,
  headers: {
    "Content-Type": "application/json",
  }
});

API.interceptors.request.use(function (config) {
  const token = localStorage.getItem("Authorization");
  if (token) {
    config.headers.Authorization = `Basic ${token}`
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

API.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const responseStatus = error.response.status;
    if (responseStatus == 401) { localStorage.clear(); history.push(APP); }
    throw error;
  }
);

export default API;
