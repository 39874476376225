import styled from 'styled-components';
import theme from '../../shared/Theme';

export const RowBodyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.center ? 'center' : ' space-between')};
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 40px;
`;

export const BoxItem = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const LabelItem = styled.label`
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.softGray};
`;
export const SpanValue = styled.span`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.colors.darkGray};
`;
