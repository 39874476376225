import {
  BoxAvatar,
  ImagAvatar,
  LabelAvatar,
  MenuBody,
  MenuItem,
} from "./AvatarStyle";
import React, { useState } from "react";

import { APP, NEW_PASSWORD } from "../../../../utils/contants/urls";
import { FormattedMessage } from "react-intl";
import { arrowDown } from "../../../../assets/icons/icons";
import { connect } from "react-redux";
import image from "../../../../assets/images/placeholder/avatar.png";
import { push } from "connected-react-router";

const Avatar = ({ redirect }) => {
  const [showOptions, setShowOptions] = useState(false);

  const logout = () => {
    localStorage.clear();
    redirect(APP);
  };
  const changePassword = () => {
    redirect(NEW_PASSWORD);
  };
  return (
    <BoxAvatar>
      <ImagAvatar src={image} />
      <LabelAvatar>{localStorage.getItem("name")}</LabelAvatar>
      <span
        onClick={(e) => {
          setShowOptions(!showOptions);
        }}
      >
        {arrowDown()}
      </span>
      {showOptions && (
        <MenuBody>
          <MenuItem onClick={(e) => changePassword()}>
            <FormattedMessage id="homeOptions.changePassword" />
          </MenuItem>
          <MenuItem onClick={(e) => logout()}>
            <FormattedMessage id="homeOptions.logout" />
          </MenuItem>
        </MenuBody>
      )}
    </BoxAvatar>
  );
};

const MapStateToProps = (state) => ({});

const MapDispatchToProps = (dispatch) => ({
  redirect: (route) => dispatch(push(route)),
});

export default connect(MapStateToProps, MapDispatchToProps)(Avatar);
