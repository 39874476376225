import styled from 'styled-components';

export const Body = styled.div`
  background-color: #fbfbfb;
  width: 100vw;
  min-width: calc(100vw - 242px);
  height: calc(100vh - 60px);
  padding: 20px 30px;
  overflow:hidden;
  overflow-y:auto;
`;
export const DashBoardBody = styled.div`
  background-color: #fbfbfb;
`;

export const Row = styled.div`
  display: flex;
`;
