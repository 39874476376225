import styled from "styled-components";
import theme from "components/shared/Theme";
import AvatarImg from "assets/images/placeholder/avatar.png";

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${props => (props.mt ? props.mt : "")};
  .row {
    width: 100%;
  }
`;
export const ImgCandidate = styled.div`
  border-radius: 50px;
  background-color: ${theme.colors.titleColor};
  background-image: url(${AvatarImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;
  border: 1px solid ${theme.colors.softGray};
`;

export const Box = styled.div`
  max-width: 70vw;
`;

export const BoxInfoCadidate = styled.div`
  color: ${theme.colors.titleColor};
`;
export const NameLabel = styled.div`
  font-weight: bold;
  font-size: 1.625em;
`;
export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const BoxInfo = styled.div`
  background-color: ${theme.colors.whiteDefault};
  min-height: 128px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px 15px 20px 15px;
`;

export const TableBoxInfo = styled.div`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  margin-bottom: 10px;
  display: block;
  text-align: left;
`;
export const TableHeaderBoxInfo = styled.div`
  display: inline-grid;
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: 45% repeat(3, minmax(auto, 19%));
    grid-template-rows: 1.5em;
  }
  width: 100%;
  font-size: 0.813em;
  color: ${theme.colors.softGray};
`;

export const TableBodyBoxInfo = styled.div`
  width: 100%;
  font-size: 1em;
  color: ${theme.colors.darkGray};
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: 45% repeat(3, minmax(auto, 19%));
    grid-template-rows: 40px;
  }
`;
export const ContentLegendStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.813em;
  margin-right: 15px;
`;
export const LegendStatus = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => {
    const status = props.status;
    if (status === "approved") {
      return theme.colors.softGreen;
    } else if (status === "reproved") {
      return theme.colors.errorColor;
    } else {
      return theme.colors.titleColor;
    }
  }};
  border-radius: 10px;
  margin-right: 6px;
`;
