import {
  CheckBox,
  MultSelect,
  RichTextInput,
  SelectFieldInput,
  TextField,
  ButtonSecondary
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { minValueMonth, required } from "../../../helpers/validations";

import CurrencyFormat from "react-currency-format";
import FormGroup from "../group/FormGroup";
import PerformanceIndicator from "../PerformanceIndicator";
import Schedules from "../Schedules";
import { getJobDescription, getMonthClosedRobbysonFormat, getLast3MonthsClosedFormat } from "../../../helpers/sharedHelpers";

const InternalBankOfTalents = ({
  inputsItems,
  formValues,
  setFormValues,
  setFormErros,
  formValidators,
  formErros,
  submited,
  schedules,
  setSchedulesItems,
  onSelectArea,
  editing,
  getIputValues,
  intl,
}) => {
  const [performanceIndicator, setPerformanceIndicator] = useState(false);
  const [eligibleJobsInput, setEligibleJobsInput] = useState("");

  useEffect(() => {
    if (editing) { setPerformanceIndicator(true) }
    else {
      getIputValues(["eligibleJobs"])
      setPerformanceIndicator(true);

      setFormValues({
        ...formValues,
        lastPromotion: 3,
        timeOfWork: 3,
        indicators:
          [
            { level: 1, indicator: 1, typeIndicator: 1, numberDays: 90 },
            { level: 1, indicator: 2, typeIndicator: 0, months: getLast3MonthsClosedFormat() },
            { level: 2, indicator: 3, typeIndicator: 0, months: getMonthClosedRobbysonFormat() }
          ]
      })
    }
  }, []);


  const handleInputEligibleJobsChange = (value, e) => {
    if (e.action === 'input-change') {
      setEligibleJobsInput(value);
    }
  };

  const setEligibleJobsValues = () => {
    const eligibleJobsToFilter = inputsItems.eligibleJobs.map(e => ({
      label: e.funcao,
      value: e.id
    }))

    const eligibleJobsFiltered = eligibleJobsToFilter.filter(job => job.label.toLowerCase().includes(eligibleJobsInput.toLowerCase()))

    setFormValues({
      ...formValues,
      eligibleJobs: eligibleJobsFiltered ? eligibleJobsFiltered.map(k => k) : []
    })
  };

  return (
    <React.Fragment>
      <FormGroup
        formName={<FormattedMessage id="sharedItems.formVacancyInformation" />}
      >
        <Row>
          <Col lg={3}>
            <SelectFieldInput
              value={formValues.area}
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              name={intl.formatMessage({ id: "inputs.area" })}
              formErr={formErros}
              items={inputsItems.area}
              label={<FormattedMessage id="inputs.area" />}
              onChange={e => onSelectArea(e.target.value)}
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.job" })}
              items={inputsItems.jobs}
              value={formValues.job}
              label={<FormattedMessage id={getJobDescription(formValues.job, inputsItems.jobs)}> </FormattedMessage>}
              onChange={e =>
                setFormValues({ ...formValues, job: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <CurrencyFormat
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              fixedDecimalScale={true}
              name={intl.formatMessage({ id: "inputs.salary" })}
              validations={[required]}
              value={formValues.salary}
              label={<FormattedMessage id="inputs.salary" />}
              prefix="R$"
              allowNegative={false}
              onChange={e =>
                setFormValues({ ...formValues, salary: e.target.value })
              }
              customInput={TextField}
            />
          </Col>
          <Col lg={3}>
            <CheckBox
              type="checkbox"
              checked={formValues.showSalary}
              label={<FormattedMessage id="inputs.showSalary" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  showSalary: !formValues.showSalary
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.placeOfWork" })}
              items={inputsItems.placeOfWork}
              value={formValues.placeOfWork}
              label={<FormattedMessage id="inputs.placeOfWork" />}
              onChange={e =>
                setFormValues({ ...formValues, placeOfWork: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.journey" })}
              items={inputsItems.jorney}
              value={formValues.jorney}
              label={<FormattedMessage id="inputs.journey" />}
              onChange={e =>
                setFormValues({ ...formValues, jorney: e.target.value })
              }
            />
          </Col>
          <Col lg={3}>
            <CheckBox
              type="checkbox"
              checked={formValues.informeSalary}
              label={<FormattedMessage id="inputs.informeSalary" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  informeSalary: !formValues.informeSalary
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.vacancyReq" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.vacancyReq}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.vacancyReq" })}
              onChange={e => setFormValues({ ...formValues, vacancyReq: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.responsibility" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.responsibility}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.responsibility" })}
              onChange={e =>
                setFormValues({ ...formValues, responsibility: e })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.aboutWork" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.aboutWork}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.aboutWork" })}
              onChange={e => setFormValues({ ...formValues, aboutWork: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RichTextInput
              label={<FormattedMessage id="inputs.skills" />}
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              value={formValues.skills}
              validations={[required]}
              name={intl.formatMessage({ id: "inputs.skills" })}
              onChange={e => setFormValues({ ...formValues, skills: e })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.benefits" })}
              value={formValues.benefits}
              validations={[required]}
              options={inputsItems.benefits.map(e => ({
                label: e.nome,
                value: e.id
              }))}
              label={<FormattedMessage id="inputs.benefits" />}
              isMulti
              onChange={e =>
                setFormValues({
                  ...formValues,
                  benefits: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup formName={<FormattedMessage id="sharedItems.formCandidate" />}>
        <Row>
          <Col lg={4}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.schooling" })}
              validations={[required]}
              items={inputsItems.schooling}
              value={formValues.schooling}
              label={<FormattedMessage id="inputs.schooling" />}
              onChange={e =>
                setFormValues({ ...formValues, schooling: e.target.value })
              }
            />
          </Col>
          <Col lg={4}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.areaFormation" })}
              validations={[required]}
              value={formValues.areaFormation}
              options={inputsItems.areaFormation.map(e => ({
                label: e.descricao,
                value: e.id
              }))}
              label={<FormattedMessage id="inputs.areaFormation" />}
              isMulti
              onChange={e =>
                setFormValues({
                  ...formValues,
                  areaFormation: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
          <Col lg={4}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.competence" })}
              validations={[required]}
              value={formValues.competence}
              options={inputsItems.competence.map(e => ({
                label: e.nome,
                value: e.id
              }))}
              isMulti
              label={<FormattedMessage id="inputs.competence" />}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  competence: e ? e.map(k => k) : []
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <TextField
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.timeOfWork" })}
              validations={[required, minValueMonth]}
              label={<FormattedMessage id="inputs.timeOfWork" />}
              value={formValues.timeOfWork}
              type="number"
              min={"0"}
              onChange={e =>
                setFormValues({ ...formValues, timeOfWork: e.target.value })
              }
            />
          </Col>
          <Col lg={4}>
            <TextField
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.lastPromotion" })}
              validations={[required, minValueMonth]}
              label={<FormattedMessage id="inputs.lastPromotion" />}
              value={formValues.lastPromotion}
              type="number"
              min={"0"}
              onChange={e =>
                setFormValues({ ...formValues, lastPromotion: e.target.value })
              }
            />
          </Col>
          <Col lg={4}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              validations={[required]}
              value={formValues.city}
              name={intl.formatMessage({ id: "inputs.city" })}
              options={inputsItems.city.map(e => ({
                label: e.nome,
                value: e.id
              }))}
              isMulti
              onChange={e =>
                setFormValues({ ...formValues, city: e ? e.map(k => k) : [] })
              }
              label={<FormattedMessage id="inputs.city" />}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <CheckBox
              type="checkbox"
              checked={formValues.deficiency}
              label={<FormattedMessage id="inputs.deficiency" />}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  deficiency: !formValues.deficiency
                })
              }
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <MultSelect
              setFormErros={setFormErros}
              formValidators={formValidators}
              touched={submited}
              formErr={formErros}
              name={intl.formatMessage({ id: "inputs.eligibleJobs" })}
              validations={[required]}
              value={formValues.eligibleJobs}
              options={inputsItems.eligibleJobs.map(e => ({
                label: e.funcao,
                value: e.id
              }))}
              isMulti
              onChange={e =>
                setFormValues({
                  ...formValues,
                  eligibleJobs: e ? e.map(k => k) : []
                })
              }
              closeMenuOnSelect={false}
              onInputChange={handleInputEligibleJobsChange}
              inputValue={eligibleJobsInput}
              label={<FormattedMessage id="inputs.eligibleJobs" />}
            />
          </Col>
          <Col>
            <ButtonSecondary
              disabled={eligibleJobsInput.length < 3}
              onClick={() => setEligibleJobsValues()}
              width="250px"
              name={<FormattedMessage id={"inputs.selectAllJobs"} />}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup
        formName={<FormattedMessage id="sharedItems.formTimeOfWork" />}
      >
        <Schedules
          addSchudule={setSchedulesItems}
          formSchedules={schedules}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </FormGroup>
      <FormGroup formName={<FormattedMessage id="sharedItems.formInternal" />}>
        <Row>
          <Col>
            <CheckBox
              type="checkbox"
              checked={performanceIndicator}
              label={<FormattedMessage id="sharedItems.performanceIndicator" />}
              onChange={() => setPerformanceIndicator(!performanceIndicator)}
            />
          </Col>
        </Row>
        {performanceIndicator && (
          <PerformanceIndicator
            inputsItems={inputsItems}
            formValues={formValues}
            setFormValues={setFormValues}
            setFormErros={setFormErros}
            formErr={formErros}
            name="indicadores"
            editing={editing}
          />
        )}
      </FormGroup>
    </React.Fragment>
  );
};
InternalBankOfTalents.propTypes = {};

InternalBankOfTalents.defaultProps = {};
export default injectIntl(InternalBankOfTalents);
