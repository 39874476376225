import * as jwt from "jsonwebtoken";
import React from 'react'; 
import { FormattedMessage } from "react-intl";
import {
  FINISH_STAGE_REGISTRATION_DATA,
  INITIAL_TEST,
  RESPONSE_REGISTRATION_DATA,
  FINISH_STAGE_UPLOAD_VACANCY,
  CHANGE_WATCH_VIDEO
} from "../../utils/contants/urlsApi";
import { POST, PUT } from "../../utils/contants/verbs";
import { put, takeLatest } from "redux-saga/effects";

import { Types as AlertTypes } from "../ducks/alert";
import { HASH_KEY } from "../../utils/contants/Keys";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { TEST, CANDIDATE_VACANCY } from "../../utils/routes";
import { Types } from "../ducks/test";
import { buildErroHandleItems } from "../../helpers/sharedHelpers";
import requestAPI from "../../helpers/requestHelpers";

function* initializeTest({ payload }) {
  const body = {
    vagaId: payload.idVacancy,
    etapaId: payload.idTrail
  };
  try {
    yield requestAPI({
      verb: POST,
      endPoint: INITIAL_TEST,
      data: body
    });
    const stageTest = {
      id: payload.vacancy.id,
      selectedStage: payload.vacancy.selectedStage
    };
    const hashJwt = jwt.sign(stageTest, HASH_KEY);
    yield put({ type: Types.INITIALIZE_TEST_REQUEST_SUCCESS, hashJwt });
    Router.push(`${TEST}/${hashJwt}`);
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({ type: Types.INITIALIZE_TEST_REQUEST_FAIL, error });
  }
}
function* changewatchVideo({ payload }) {
  try {
    const body = {
      vagaId: payload.idVacancy,
      etapaId: payload.idStage,
      visualizouVideo: payload.watchedVideo
    };
    yield requestAPI({
      verb: PUT,
      endPoint: CHANGE_WATCH_VIDEO,
      data: body
    });
    yield put({ type: Types.CHANGE_WATCH_VIDEO_SUCCESS });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({ type: Types.CHANGE_WATCH_VIDEO_FAIL, error });
  }
}

function* finishStageUpload({ payload }) {
  try {
    const body = {
      vagaId: payload.idVacancy,
      etapaId: payload.idStage
    };
    yield requestAPI({
      verb: PUT,
      endPoint: FINISH_STAGE_UPLOAD_VACANCY,
      data: body
    });


    Router.push(`${CANDIDATE_VACANCY}/${payload.idVacancy}`);

  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({ type: Types.INITIALIZE_TEST_REQUEST_FAIL, error });
  }
}


function* finishRegistrationData({ payload }) {
  try {
    const body = {
      vagaId: payload.idVacancy,
      etapaId: payload.idStage
    };
    yield requestAPI({
      verb: PUT,
      endPoint: FINISH_STAGE_UPLOAD_VACANCY,
      data: body
    });
    yield put({ type: Types.FINISH_STAGE_UPLOAD_SUCCESS });
    Router.back();
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({ type: Types.FINISH_STAGE_UPLOAD_FAIL, error });
  }
}




function* responseRegistrationData({ payload }) {
  const body = {
    vagaId: payload.idVacancy,
    perguntaId: payload.idQuestion,
    respostaAberta: payload.responseQuestion
  };
  try {
    yield requestAPI({
      verb: POST,
      endPoint: RESPONSE_REGISTRATION_DATA,
      data: body
    });
    const messages = [
      {
        type: "success",
        message: (
          <FormattedMessage id="trails.questionResponseRegistrationDataSuccess" />
        )
      }
    ];
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({ type: Types.RESPONSE_REGISTRATION_DATA_SUCCESS });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({ type: Types.RESPONSE_REGISTRATION_DATA_FAIL, error });
  }
}

export function* watchSaga() {
  yield takeLatest(Types.INITIALIZE_TEST_REQUEST, initializeTest);
  yield takeLatest(Types.RESPONSE_REGISTRATION_DATA, responseRegistrationData);
  yield takeLatest(Types.FINISH_REGISTRATION_DATA, finishRegistrationData);

  yield takeLatest(Types.FINISH_STAGE_UPLOAD, finishStageUpload);
  yield takeLatest(Types.CHANGE_WATCH_VIDEO_REQUEST, changewatchVideo);
}
