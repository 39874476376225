import * as routes from "utils/contants/urls";

import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import {
  order,
  statusFinishedFill,
  statusOkFill,
  view
} from "assets/icons/icons";

import { Body } from "./ListVacancyCandidatesStyle";
import BreadCrumb from "components/Layout/breadcrumb";
import { Filter } from "components/shared/CustomComponents";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import { Creators as candidatesActions } from "store/ducks/candidates";
import { connect } from "react-redux";
import { Creators as formInputAction } from "store/ducks/formInputs";

const renderTableItems = vancancies =>
  vancancies.map(vacancy => (
    <Link to={`${routes.CANDIDATES_FROM_VACANCY}/${vacancy.id}`}>
      <TableRow key={vacancy.id}>
        <TableColumn ta="center">
          <Actions ml="16px" flexPosition="flex-start">
            {vacancy.status === 2 ? statusOkFill() : statusFinishedFill()}
            <Label ml="10px">{vacancy.code}</Label>
          </Actions>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{vacancy.job}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{vacancy.area}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{vacancy.manager}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>
            <FormattedMessage
              id={
                vacancy.status === 2
                  ? "sharedItems.closeVacancy"
                  : "sharedItems.openVacancy"
              }
            />
          </Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{vacancy.createdBy}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{vacancy.createdAt}</Label>
        </TableColumn>
        <TableColumn action>
          <Actions flexPosition="flex-start">
            <ActionItem>
              {view()}
              <LabelAction>
                <FormattedMessage id="sharedItems.view" />
              </LabelAction>
            </ActionItem>
          </Actions>
        </TableColumn>
      </TableRow>
    </Link>
  ));
const ListVacancyCandidates = ({
  vancancies,
  requestVacancies,
  candidates,
  pagination,
  location,
  getIputValues,
  inputs
}) => {
  useEffect(() => {
    localStorage.removeItem("selectedStageId_ListCandidates");
    requestVacancies({ NumeroPagina: 1 });
    getIputValues(["job", "area"]);
  }, []);

  const [valuesToFilter, setValuesToFilter] = useState({});

  const requestVacanciesFilter = filter => {
    requestVacancies(filter);
    setValuesToFilter(filter);
  };
  const requestPageWithFilter = page => {
    const filter = Object.assign(valuesToFilter, page);
    requestVacancies(filter);
  };
  if (inputs.isLoading) return <Load isLoading={inputs.isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={<FormattedMessage id="menuTalentBar.selectVacancy" />}
      />
      <Body>
        <Filter
          numberOfItems={pagination && pagination.sizeItems}
          onFilter={e => requestVacanciesFilter(e)}
          filterItems={inputs}
          kindFilter="vacancy"
        />
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.code" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.job" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.area" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.manager" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.status" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.createdBy" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.createdAt" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.actions" />
                </LabelHeader>
                {order()}
              </TableHead>
            </TableRow>
            {renderTableItems(vancancies)}
          </TableBody>
        </Table>
        <Pagination
          getItemsPage={e => requestPageWithFilter(e)}
          {...pagination}
        />
      </Body>
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  requestVacancies: filter =>
    dispatch(candidatesActions.vacancyCandidatesRequest(filter)),
  getIputValues: kind => dispatch(formInputAction.formInputsRequest(kind))
});
const mapStateToProps = state => ({
  vancancies: state.candidates.vacancies,
  isLoadingVacancies: state.candidates.isLoading,
  candidates: state.candidates,
  pagination: state.candidates.pagination,
  inputs: state.formInputs
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListVacancyCandidates);

ListVacancyCandidates.propTypes = {
  vancancies: PropTypes.arrayOf([
    {
      id: PropTypes.string,
      job: PropTypes,
      area: PropTypes,
      manager: PropTypes,
      status: PropTypes,
      createdBy: PropTypes,
      createAt: PropTypes
    }
  ]),
  requestVacancies: PropTypes.func,
  candidates: PropTypes.arrayOf({})
};

ListVacancyCandidates.defaultProps = {
  vancancies: [],
  candidates: [],
  requestVacancies: () => {}
};
