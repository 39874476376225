import styled from 'styled-components';
import theme from '../../shared/Theme';

export const HeaderModal = styled.div`
  border-bottom: 1px solid ${theme.colors.ligthGray};
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 67px;
`;

export const SpanQuestion = styled.span`
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.titleColor};
`;

export const SpanDisclaimer = styled.span`
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.colors.colorLabelDefault};
`;

export const BodyModal = styled.div`
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-right: 15px;
`;

export const FlexItems = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
`;

export const ActionsFlex = styled.div`
  display: flex;
  justify-content: space-center;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  margin-bottom: 15px;
`;
