import {
  Body,
  DisclaimerText,
  ItemKind,
  NumberOfItem,
  RowHeaderCard,
  ViewRow
} from "./CardStageStyle";
import { SliceText, convertTimeOfTest } from "../../../helpers/sharedHelpers";

import { FormattedMessage } from "react-intl";
import { LabelH1 } from "components/shared/CustomComponents";
import PropTypes from "prop-types";
import React from "react";
import { kindStages } from "../../../locale/index";

const REGISTRATION = kindStages[0].id;
const TEST = kindStages[1].id;
const TRAINNER = kindStages[2].id;
const ANALYZE = kindStages[3].id;
const MEDIC = kindStages[4].id;
const ADMITION = kindStages[5].id;
const AMBIENTATION = kindStages[6].id;
const FEEDBACK = kindStages[7].id;
const PREADMITION = kindStages[8].id;

export const simpleItemCard = ({
  name,
  description,
  position,
  showDescription,
  kind,
  type
}) => {
  return (
    <Body
      onClick={() =>
        showDescription({
          show: true,
          name,
          kindItem: kind,
          description
        })
      }
    >
      <RowHeaderCard pd="10px">
        <NumberOfItem>
          <LabelH1 fz="14px">{position}</LabelH1>
        </NumberOfItem>
        <LabelH1 ml="12px" fz="16px">
          {name}
        </LabelH1>
      </RowHeaderCard>
      <RowHeaderCard>
        <ItemKind>
          <FormattedMessage id="rails.kindStage" />
        </ItemKind>
      </RowHeaderCard>
      <RowHeaderCard>
        <ItemKind fz="16px" color="dark">
          { type }
        </ItemKind>
      </RowHeaderCard>
      <RowHeaderCard>
        <ItemKind>
          <FormattedMessage id="sharedItems.description" />
        </ItemKind>
      </RowHeaderCard>
      <RowHeaderCard overflow={"auto"} height="99px" mt="5px">
        <DisclaimerText>{SliceText(description, 300)}</DisclaimerText>
      </RowHeaderCard>
      <ViewRow jc="flex-end" mt="5px">
        <LabelH1 action color="defaultColor" fz="14px">
          <FormattedMessage id="sharedItems.view" />
        </LabelH1>
      </ViewRow>
    </Body>
  );
};
export const simpleWithKind = ({
  name,
  description,
  position,
  showDescription,
  kind
}) => (
  <Body
    onClick={() =>
      showDescription({
        show: true,
        name,
        kindItem: kind,
        description
      })
    }
  >
    <RowHeaderCard pd="10px">
      <NumberOfItem>
        <LabelH1 fz="14px">{position}</LabelH1>
      </NumberOfItem>
      <LabelH1 ml="12px" fz="16px">
        {name}
      </LabelH1>
    </RowHeaderCard>
    <RowHeaderCard pd="10px">
      <ItemKind>{name}</ItemKind>
    </RowHeaderCard>
    <RowHeaderCard pd="10px">
      <ItemKind fz="16px" color="dark">
        Boas vindas
      </ItemKind>
    </RowHeaderCard>
    <RowHeaderCard height="100px" mt="10px">
      <DisclaimerText>
        <DisclaimerText>{SliceText(description, 200)}</DisclaimerText>
      </DisclaimerText>
    </RowHeaderCard>
    <ViewRow jc="flex-end" mt="5px">
      <LabelH1 action color="defaultColor" fz="14px">
        <FormattedMessage id="sharedItems.view" />
      </LabelH1>
    </ViewRow>
  </Body>
);

export const testCard = ({
  name,
  description,
  kindStage,
  position,
  level,
  timeOfTest,
  kind,
  howManyQuestions,
  avaliation,
  showDescription,
  type,
}) => {
  return (
    <Body
      onClick={() =>
        showDescription({
          show: true,
          name,
          kindItem: kind,
          kindValue: kind,
          description
        })
      }
    >
      <RowHeaderCard pd="10px">
        <NumberOfItem>
          <LabelH1 fz="14px">{position}</LabelH1>
        </NumberOfItem>
        <LabelH1 ml="12px" fz="16px">
          {name}
        </LabelH1>
      </RowHeaderCard>
      <RowHeaderCard jc="space-between">
        <ItemKind>
          <FormattedMessage id="rails.kindTest" />
        </ItemKind>
        <ItemKind>
          <FormattedMessage id="rails.level" />
        </ItemKind>
      </RowHeaderCard>
      <RowHeaderCard jc="space-between">
        <ItemKind fz="16px" color="dark">
          {kind}
        </ItemKind>
        <ItemKind fz="16px" color="dark">
          {level}
        </ItemKind>
      </RowHeaderCard>
      <RowHeaderCard mt="45px" jc="space-between">
        <ItemKind>
          <FormattedMessage id="rails.avaliation" />
        </ItemKind>
        <ItemKind>
          <FormattedMessage id="rails.questions" />
        </ItemKind>
      </RowHeaderCard>
      <RowHeaderCard jc="space-between">
        <ItemKind fz="16px" color="dark">
          {avaliation}
        </ItemKind>
        <ItemKind fz="16px" color="dark">
          {convertTimeOfTest(timeOfTest)}
        </ItemKind>
      </RowHeaderCard>
      <ViewRow jc="flex-end" mt="5px">
        <LabelH1 action color="defaultColor" fz="14px">
          <FormattedMessage id="sharedItems.view" />
        </LabelH1>
      </ViewRow>
    </Body>
  );
};

export default function CardStage({
  avaliation,
  description,
  level,
  howManyQuestions,
  timeOfTest,
  kind,
  kindStage,
  name,
  showDescription,
  position
}) {
  if (
    kindStage === PREADMITION ||
    kindStage === FEEDBACK ||
    kindStage === ADMITION ||
    kindStage === MEDIC ||
    kindStage === ANALYZE ||
    kindStage === TRAINNER ||
    kindStage === REGISTRATION
  ) {
    return simpleItemCard({
      name,
      kindStage,
      description,
      position,
      showDescription,
      type: <FormattedMessage id={`${kindStages[kindStage].label}`} />
    });
  }
  if (kindStage === AMBIENTATION) {
    return simpleWithKind({
      name,
      description,
      kindStage,
      position,
      showDescription,
      type: <FormattedMessage id={`${kindStages[kindStage].label}`} />
    });
  }
  if (kindStage === TEST) {
    return testCard({
      name,
      description,
      kindStage,
      position,
      level,
      timeOfTest,
      kind,
      howManyQuestions,
      avaliation,
      showDescription,
      type: <FormattedMessage id={`${kindStages[kindStage].label}`} />
    });
  }
  return null;
}

CardStage.propTypes = {
  kind: PropTypes.string,
  description: PropTypes.string,
  position: PropTypes.string,
  level: PropTypes.string,
  questionTime: PropTypes.string,
  kindTest: PropTypes.string,
  avaliation: PropTypes.string
};

CardStage.defaultProps = {
  kind: "",
  description: "",
  position: "",
  level: "",
  questionTime: "",
  kindTest: "",
  avaliation: ""
};
