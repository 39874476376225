import styled from 'styled-components';

export const Body = styled.div`
  background-color: #fbfbfb;
  width: 100vw;
  padding: 20px 30px;
  height: calc(100vh - 60px);
  overflow: hidden auto;
`;
export const DashBoardBody = styled.div`
  background-color: #fbfbfb;
`;

export const Row = styled.div`
  display: flex;
`;
