import * as urlsApi from "../../utils/contants/urlsApi";

import { put, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/formInputs";
import { Types as VacancyTypes } from "../ducks/vacancy";
import { buildErroHandleItems, orderObject} from "../../helpers/sharedHelpers";
import { GET } from "../../utils/contants/verbs";
import requestAPI from "../../helpers/requestHelpers";
import { FORM_INPUTS_URLS } from "../../utils/contants/urlsApi";


async function getEachInputInfos(url) {
  return await API.get(url)
    .then(res => res.data)
    .catch(error => { throw error });
}

function getAffiliateCenter(id) {
  return API.get(`${urlsApi.formInputFilliate}?IdColigada=${id}`)
    .then(res => res.data)
    .catch(error => {});
}

function getManagerByNameRequest(parameter) {
  var parameter = {Nome:parameter.name, Id : parameter.id};
  return API.put(`${urlsApi.formInputAutoComplete}`,
  parameter)

    .then(res => res.data)
    .catch(error => {});
}
function* getManagerByName({ name }) {
  try {
    const managerName = yield getManagerByNameRequest(name);
    yield put({
      type: Types.FORM_INPUTS_SEARCH_BY_NAME_SUCCESS,
      managerName,
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: messages });
    yield put({ type: Types.FORM_INPUTS_SEARCH_BY_NAME_FAIL, error });
  }
}

function* getAffiliateCenterItems({ idAffiliate }) {
  try {
    const items = yield getAffiliateCenter(idAffiliate);
    yield put({
      type: Types.FORM_INPUTS_AFFILIATE_SUCCESS,
      resultCenter: items
    });
  } catch (error) {
    yield put({ type: Types.FORM_INPUTS_AFFILIATE_FAIL, error });
  }
}

async function getItems(kinds, coligada) {
  const items = {};
  return Promise.all(
    kinds.map(async (kind) => {
      const item = await getEachInputInfos(
        urlsApi.formInputsUrls[kind],
        coligada
      );
      if (item.escolaridade) {
        items[kind] = item.escolaridade;
        items.benefits = item.beneficios;
        items.banners = item.banners;
        items.competence = item.competencia;
      } else {
        items[kind] = item;
      }
      return items;
    })).then(res => res[0])
    .catch(error => {});
  
} 


function* fillFormVacanyInputs({ payload }) {
  try {
    let kind;
    if (payload.coligada) kind = payload.kind;
    else kind = payload;

    const inputs = yield getItems(kind, payload.coligada);
    yield put({ type: Types.FORM_INPUTS_SUCCESS, ...inputs });
  
    
  

  } catch (message) {
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, message });
    yield put({ type: Types.FORM_INPUTS_FAIL, message });
  }
}

function* getReasons({ payload }) {
  try {
    let values = {};
    for (let item in payload) {
      const elements = yield requestAPI({
        verb: GET,
        endPoint: FORM_INPUTS_URLS[payload[item]],
        data: null
      });
      console.log("elements", elements)
      console.log("items", item)
      values[payload[item]] = elements.map(e => ({
        value: e.id,
        label: e.nome
      }));
    }
    console.log("values", values)
    yield put({ type: Types.FORM_INPUTS_COUNTRIES_SUCCESS, ...values });
  } catch (error) {
    yield put({ type: Types.FORM_INPUTS_COUNTRIES_FAIL, error });
  }
}

function requestGetJobsByArea(idArea) {
  return API.get(`${urlsApi.REQUEST_JOBS_FROM_AREA}?IdArea=${idArea}`)
    .then(res => res.data)
    .catch(error => {});
}

function* getJobsByArea({ idArea }) {
  try {
    const jobs = yield requestGetJobsByArea(idArea);
    yield put({ type: Types.FORM_INPUTS_REQUEST_JOB_FROM_AREA_SUCCESS, jobs });
  } catch (error) {
    yield put({ type: Types.FORM_INPUTS_REQUEST_JOB_FROM_AREA_FAIL, error });
  }
}

function requestPerformaceIndicator() {
  return API.get(`${urlsApi.PERFORMACE_INDICATOR}`)
    .then((response) => response.data)
  .catch((error) => { throw error })
};

function* performaceIndicatior() {
  try {
    const response = yield requestPerformaceIndicator();
    const indicator = response.map(value => {
      return {
        id: value.id ? value.id : null,
        name: value.nome ? value.nome : null
      };
    });
    yield put({
      type: Types.PERFORMACE_INDICATIOR_SUCCESS,
      indicatorsperformaceIndicatior: indicator
    });
  } catch (err) {
    const messages = buildErroHandleItems(err.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: messages });
    yield put({ type: Types.PERFORMACE_INDICATIOR_FAIL, err });
  }
};

function* getCitiesFromState({ payload }) {
  try {
    const elements = yield requestAPI({
      verb: GET,
      endPoint: FORM_INPUTS_URLS.citiesFromState,
      data: null,
      id: null,
      query: `?IdEstado=${payload.value}`
    });
    let cities = elements.map(e => ({
      value: e.id,
      label: e.nome
    }));
    yield put({ type: Types.FORM_CITIES_SUCCESS, cities });
  } catch (error) {
    yield put({ type: Types.FORM_CITIES_FAIL, error });
  }
}

function* getStatesFromCountry({ payload }) {
  try {
    const elements = yield requestAPI({
      verb: GET,
      endPoint: FORM_INPUTS_URLS.statesFromCountry,
      data: null,
      id: null,
      query: `?IdPais=${payload.value}`
    });
    let states = elements.map(e => ({
      value: e.id,
      label: e.nome
    }));
    yield put({ type: Types.FORM_STATES_SUCCESS, states });
  } catch (error) {
    yield put({ type: Types.FORM_STATES_FAIL, error });
  }
}


function* getWorkplaceRequest() {
  try {
    const result = yield requestAPI({
      verb: GET,
      endPoint: FORM_INPUTS_URLS.workplaces,
      data: null,
      id: null
    });
    const sorted = result.sort((a, b) => orderObject(a, b, "nome"));
    const workplaces = sorted.map(e => ({
      label: `${e.nome} (Unidades AeC)`,
      value: e.id
    }));
    yield put({ type: Types.FORM_WORKPLACE_SUCCESS, workplaces });
  } catch (error) {
    yield put({ type: Types.FORM_WORKPLACE_FAIL, error });
  }
}

function* getAddressCitiesFromState({ payload }) {
  try {
    const elements = yield requestAPI({
      verb: GET,
      endPoint: FORM_INPUTS_URLS.citiesFromState,
      data: null,
      id: null,
      query: `?IdEstado=${payload.value}`
    });
    let addressCities = elements.map(e => ({
      value: e.id,
      label: e.nome
    }));
    yield put({ type: Types.FORM_CITIES_ADDRESS_SUCCESS, addressCities });
  } catch (error) {
    console.log(error);
    yield put({ type: Types.FORM_CITIES_ADDRESS_FAIL, error });
  }
}

function* getAddressStatesFromCountry({ payload }) {
  try {
    const elements = yield requestAPI({
      verb: GET,
      endPoint: FORM_INPUTS_URLS.statesFromCountry,
      data: null,
      id: null,
      query: `?IdPais=${payload.value}`
    });
    let states = elements.map(e => ({
      value: e.id,
      label: e.nome
    }));
    yield put({ type: Types.FORM_STATES_ADDRESS_SUCCESS, states });
  } catch (error) {
    yield put({ type: Types.FORM_STATES_ADDRESS_FAIL, error });
  }
}

function* requestSearchAddressByZipCode({ payload }) {
  try {
    console.log('payload', payload)
    const addressData = yield requestAPI({
      verb: GET,
      endPoint: `${payload.zipCode}/json/`,
      data: null,
      apiInUse: 'viacep'
    });

    yield put({
      type: Types.SEARCH_ADDRESS_BY_ZIP_CODE_SUCCESS, addressData
    });

  } catch (error) {
    // const messages = buildErroHandleItems(error.response);
    // yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({ type: Types.SEARCH_ADDRESS_BY_ZIP_CODE_FAIL, error });
  }
};

export function* watcherSaga() {
  yield takeLatest(Types.FORM_INPUTS_REQUEST, fillFormVacanyInputs);
  yield takeLatest(
    Types.FORM_INPUTS_AFFILIATE_REQUEST,
    getAffiliateCenterItems
  );
  yield takeLatest(Types.FORM_INPUTS_COUNTRIES_REQUEST, getReasons);
  yield takeLatest(Types.FORM_INPUTS_SEARCH_BY_NAME_REQUEST, getManagerByName);
  yield takeLatest(Types.FORM_INPUTS_REQUEST_JOB_FROM_AREA, getJobsByArea);
  yield takeLatest(Types.PERFORMACE_INDICATIOR_REQUEST, performaceIndicatior);

  yield takeLatest(Types.FORM_CITIES_REQUEST, getCitiesFromState);
  yield takeLatest(Types.FORM_STATES_REQUEST, getStatesFromCountry);
  yield takeLatest(Types.FORM_WORKPLACE_REQUEST, getWorkplaceRequest);
  yield takeLatest(Types.FORM_CITIES_ADDRESS_REQUEST, getAddressCitiesFromState);
  yield takeLatest(Types.FORM_STATES_ADDRESS_REQUEST, getAddressStatesFromCountry);
  yield takeLatest(Types.SEARCH_ADDRESS_BY_ZIP_CODE_REQUEST, requestSearchAddressByZipCode);
}
