import {
  Body,
  Box,
  QuestionLabel,
  QuestionLabelDiscleimer,
  RowBoxAction
} from "./QuestionStyle";

import * as Permissions from "../../utils/PerfilAcesso";
import {
  ButtonDefault,
  ButtonSecondary,
  CheckBox,
  SelectFieldInput,
  TextField
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import EditItemBlock from "components/Question/EditItemBlock";
import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import MultAnswer from "./MultAnswer";
import RichTextEditor from "react-rte";
import UniqAnswer from "./UniqAnswer";
import { YesOrNoDialog } from "components/shared/CustomComponents";
import { required } from "../../helpers/validations";

export default function Question({
  position,
  testId,
  previousValues,
  requestCreateQuestion,
  questionId,
  requestUpdateQuestion,
  formOptions,
  deleteQuestionItem,
  requestDeleteQuestion,
}) {

  const [richTextState, setRichTextState] = useState(
    RichTextEditor.createValueFromString(
      (previousValues && previousValues.question) || "",
      "html"
    )
  );
  const [formErros, setFormErros] = useState([]);
  const [formValidators, setFormValidators] = useState();
  const [submited, setSubmited] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [simpleQuestion, setSimpleQuestion] = useState(null);
  const [weightOptions, setWeightOptions] = useState([]);

  const [formValues, setFormValues] = useState({
    answers: [],
    ...previousValues
  });

  useEffect(() => {
    if (formOptions && formOptions.weight) {
      const weigthOptions = formOptions.weight.map(e => ({
        id: e.id,
        name: e.valor
      }))
      setWeightOptions(weigthOptions)
    }
  }, [formOptions])

  useEffect(() => {
    setFormValues({ ...formValues, ...previousValues });
  }, [previousValues])

  const setTextValue = value => {
    setRichTextState(value);
    setFormValues({ ...formValues, question: value.toString("html") });
  };

  const saveQuestion = () => {
    setSubmited(true);
    if (previousValues) {
      requestUpdateQuestion(
        Object.assign(formValues, {
          kindAnsewr: formValues.kindAnswer,
          testId,
          id: previousValues.id
        })
      );
    } else {
      requestCreateQuestion(
        Object.assign(
          formValues,
          { kindAnsewr: formValues.kindAnswer || simpleQuestion },
          { testId }
        )
      );
    }
  };

  const insertComponetByKind = kindItem => {
    if (parseInt(kindItem, 10) === 0 || parseInt(kindItem, 10) === 1) {
      const answersValues = [];
      for (let i = 0; i < 2; i += 1) {
        answersValues.push({
          position: i,
          kind: kindItem,
          checked: false,
          answer: ""
        });
      }
      setSimpleQuestion(kindItem);
      setFormValues({
        ...formValues,
        answers: answersValues,
        kindAnswer: parseInt(kindItem, 10)
      });
    } else {
      setSimpleQuestion(kindItem);
      setFormValues({ 
        ...formValues,
        answers: [],
        kindAnswer: parseInt(kindItem, 10)
      })
    }
  };
  const insertMoreItem = kindItem => {
    if (parseInt(kindItem, 10) === 0) {
      const items = formValues.answers;
      items.push({
        position: formValues.answers.length,
        kind: 0,
        checked: false,
        answer: ""
      });
      setFormValues({ ...formValues, answers: items });
    }
    if (parseInt(kindItem, 10) === 1) {
      const items = formValues.answers;
      items.push({
        position: formValues.answers.length,
        kind: 1,
        checked: false,
        answer: ""
      });
      setFormValues({ ...formValues, answers: items });
    }
    if (parseInt(kindItem, 10) === 2) {
      const items = formValues.answers;
      items.push({
        position: formValues.answers.length,
        kind: 2,
        answer: ""
      });
      setFormValues({ ...formValues, answers: items });
    }
  };

  const onCloseEditForm = () => {
    if (!showEditForm) {
      deleteQuestionItem(--position)
    }
    else {
      setShowEditForm(false)
      setFormValues({ ...previousValues })
    }
  }

  const deleteQuestion = () => {
    requestDeleteQuestion(questionId, testId)
    deleteQuestionItem(--position)
  };

  if (questionId && !showEditForm) {
    return (
      <React.Fragment>
        <YesOrNoDialog
          confirmation={<FormattedMessage id="sharedItems.removeQuestion" />}
          question={<FormattedMessage id="sharedItems.removeQuestionTitle" />}
          show={showDialogDelete}
          confirmAction={deleteQuestion}
          cancelAction={() => setShowDialogDelete(!showDialogDelete)}
        />
        <EditItemBlock
          setShowDialogDelete={() => setShowDialogDelete(true)}
          position={position}
          {...formValues}
          setShowEditForm={() => setShowEditForm(!showEditForm)}
          ShowEditForm={() => Permissions.canEditEtapa()}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Box>
        <Body>
          <Row>
            <Col>
              <QuestionLabel>Pergunta</QuestionLabel>
              <QuestionLabel ml>{position}</QuestionLabel>
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }} className="align-items-end">
            <Col lg={3}>
              <QuestionLabelDiscleimer>
                Preencha o campo abaixo com a pergunta a ser feita.
              </QuestionLabelDiscleimer>
            </Col>
            <Col lg={2}>
              <InputMask
                mask="99:99:99"
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                formErr={formErros}
                formActions={formValidators}
                touched={submited}
                maskChar={null}
                alwaysShowMask
                validations={[required]}
                onChange={event =>
                  setFormValues({ ...formValues, time: event.target.value })
                }
                label={<FormattedMessage id="inputs.time" />}
                value={formValues.time}
              >
                {inputProps => <TextField {...inputProps} />}
              </InputMask>
            </Col>
            <Col lg={2}>
              <SelectFieldInput
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                formErr={formErros}
                formActions={formValidators}
                touched={submited}
                validations={[required]}
                placeholder="..."
                onChange={event =>
                  setFormValues({ ...formValues, weigth: event.target.value })
                }
                label="peso"
                value={formValues.weigth}
                items={weightOptions}
              />
            </Col>
            <Col lg={{ span: 2, offset: 1 }}>
              <ButtonSecondary
                onClick={() => onCloseEditForm()}
                name="DESCARTAR"
              />
            </Col>
            <Col lg={2}>
              <ButtonDefault onClick={() => saveQuestion()} name="SALVAR" />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <RichTextEditor
                editorStyle={{ height: "100px" }}
                onChange={value => setTextValue(value)}
                value={richTextState}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "-30px", marginTop: "10px" }}>
            <Col>
              <QuestionLabel>Resposta</QuestionLabel>
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }} className="align-items-end">
            <Col lg={3}>
              <QuestionLabelDiscleimer>
                <FormattedMessage id="question.selectCorrectAnsewr" />
              </QuestionLabelDiscleimer>
            </Col>
            <Col lg={2}>
              <SelectFieldInput
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                formErr={formErros}
                formActions={formValidators}
                touched={submited}
                validations={[required]}
                onChange={option => insertComponetByKind(option.target.value)}
                placeholder="..."
                label={<FormattedMessage id="question.typeSelect" />}
                value={formValues.kindAnswer}
                items={[
                  { id: 0, name: "Mult-seleção" },
                  { id: 1, name: "Seleção única" },
                  { id: 2, name: "Numérico" },
                  { id: 3, name: "Data" },
                  { id: 4, name: "Texto" },
                  { id: 5, name: "Redação" }
                ]}
              />
            </Col>
            {(parseInt(simpleQuestion, 10) === 2 ||
              parseInt(formValues.kindAnswer, 10) === 2) && (
              <React.Fragment>
                <Col lg={2}>
                  <TextField
                    onChange={e =>
                      setFormValues({
                        ...formValues,
                        answerNumber: e.target.value
                      })
                    }
                    value={formValues.answerNumber}
                    type="number"
                    label={<FormattedMessage id="question.correctAnsewr" />}
                  />
                </Col>
                <Col style={{ marginBottom: "15px" }} lg={2}>
                  <CheckBox
                    checked={formValues.mandatory}
                    onChange={() =>
                      setFormValues({
                        ...formValues,
                        mandatory: !formValues.mandatory
                      })
                    }
                    label={<FormattedMessage id="question.mandatory" />}
                  />
                </Col>
              </React.Fragment>
            )}
            {(parseInt(simpleQuestion, 10) === 3 ||
              parseInt(formValues.kindAnswer, 10) === 3) && (
              <Col lg={2}>
                <InputMask
                  alwaysShowMask
                  mask="99/99/9999"
                  onChange={e =>
                    setFormValues({ ...formValues, answerDate: e.target.value })
                  }
                  value={formValues.answerDate}
                  label={<FormattedMessage id="question.correctAnsewr" />}
                >
                  {inputProps => <TextField {...inputProps} />}
                </InputMask>
              </Col>
            )}
            {(parseInt(simpleQuestion, 10) === 4 ||
              parseInt(formValues.kindAnswer, 10) === 4) && (
              <Col style={{ marginBottom: "15px" }} lg={2}>
                <CheckBox
                  checked={formValues.answerText}
                  onChange={() =>
                    setFormValues({
                      ...formValues,
                      answerText: !formValues.answerText
                    })
                  }
                  label={<FormattedMessage id="question.mandatory" />}
                />
              </Col>
            )}
          </Row>
          <Row>
            {formValues.answers.map((answer, position) => {
              switch (parseInt(answer.kind, 10)) {
                case 0:
                  return (
                    <MultAnswer
                      setFormErros={setFormErros}
                      formValidators={setFormValidators}
                      formErr={formErros}
                      formActions={formValidators}
                      touched={submited}
                      validations={[required]}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      position={position}
                      {...answer}
                    />
                  );
                case 1:
                  return (
                    <UniqAnswer
                      setFormErros={setFormErros}
                      formValidators={setFormValidators}
                      formErr={formErros}
                      formActions={formValidators}
                      touched={submited}
                      validations={[required]}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      position={position}
                      {...answer}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Row>
          <RowBoxAction mt="10px;">
            <Col lg={4}>
              {formValues.answers.length > 0 &&
              formValues.answers.length < 60 ? (
                <ButtonSecondary
                  onClick={() => insertMoreItem(formValues.kindAnswer)}
                  mb="8px"
                  name="ADICIONAR RESPOSTA"
                />
              ) : null}
            </Col>
          </RowBoxAction>
        </Body>
      </Box>
    </React.Fragment>
  );
}
