import "moment/locale/pt-br";

import * as routes from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";

import { buildErroHandleItems, } from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";

import {
  buildPagination,
} from "../../helpers/sharedHelpers";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/contracts";
import moment from "moment";

moment.locale("pt-BR");



function requestCandidadesFromVacancyList(body) {
  return API.put(
    `${urlsApi.REQUEST_CANDIDATES_VACANCY_CONTRACT}`,
    body
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

const filterCandidates = payload => {
  const body = {
    nomeFuncionario: payload.nomeFuncionario,
    cpfList: payload.cpfList,
    idVagasList: payload.idVagasList,
    idLocaisList: payload.idLocaisList,
    idStatusList: payload.idStatusList,
    dataInicioAtividade: payload.dateInitActivity,
    numeroPagina: payload.NumeroPagina,
    quantidadeItens: payload.quantidadeItens
  };

  return body;
};


function* getCandidatesVacancyDocs({ payload }) {
  try {

   /*  let actualPage = payload && payload.firstPage ? 1 : yield select(getActualPage);
    let actualItens = payload && payload.recoveredFilter && payload.quantidadeItens ? payload.quantidadeItens : 20;
    payload.quantidadeItens = actualItens;
    payload.NumeroPagina = actualPage; */
    const {lista , paginacao} = yield requestCandidadesFromVacancyList(filterCandidates(payload));
    

    const candidates =

    {
      candidatos: lista.candidatosNaEtapaList.map(e => ({
        cpf: e.cpf,
        idCandidatoNaVaga: e.idCandidatoNaVaga,
        idVaga: e.idVaga,
        nome: e.nome,
        statusCandidatoNaEtapa: e.statusCandidatoNaEtapa,
        tituloVaga: e.tituloVaga,
        dataUltimaAtualizacao: e.dataAprovacao,
        dataFinalExecucao: e.dataFinalExecucao,
        codigoStatus: e.codigoStatus,
        dataInicialExecucao: e.dataInicialExecucao,
      })),
      candidatosPorVaga: lista.candidatosNaEtapaPorVagaList.map(e => ({
        idVaga: e.idVaga,
        nomeVaga: e.nomeVaga,
        candidatesByVacancyList: e.candidatosNaEtapaList.map(c => ({
          cpf: c.cpf,
          idCandidatoNaVaga: c.idCandidatoNaVaga,
          idVaga: c.idVaga,
          nome: c.nome,
          statusCandidatoNaEtapa: c.statusCandidatoNaEtapa,
          tituloVaga: c.tituloVaga,
          dataUltimaAtualizacao: e.dataUltimaAtualizacao
        }))
      }))
    };
    let candidatos;
    let candidatosPorVaga;
   // const hasMore = payload.firstPage ? true : lista.candidatosNaEtapaList.length;
    const stateCandidateList = yield select(getStateCandidatesList);
    /* if (!payload.firstPage) {
      candidatos = stateCandidateList.candidatos.concat(candidates.candidatos);
      candidatosPorVaga = stateCandidateList.candidatosPorVaga.concat(candidates.candidatosPorVaga);
    }
    else { */
      candidatos = candidates.candidatos;
      candidatosPorVaga = candidates.candidatosPorVaga;
  //  }
    const AllCandidates = { candidatos: candidatos, candidatosPorVaga: candidatosPorVaga }
   // const nextPage = ++actualPage;
   const paginacaoNumber = {...paginacao, page: payload.numeroPagina}
   console.log("paginacaoNumber", paginacaoNumber)
 const pagination = buildPagination(
   paginacaoNumber,
   payload,
   AllCandidates.candidatos,
   stateCandidateList,
   50,
 );
    yield put({
      type: Types.CANDIDATES_VACANCYS_CONTRACTS_SUCCESS,
      candidates: AllCandidates,
      //hasMore: hasMore,
      //page: nextPage,
      pagination
    });
  } catch (error) {
    console.log("Erro sagas",  error)
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATES_VACANCYS_CONTRACTS_FAIL, error });
  }
}
const getActualPage = state => state.contracts?.page;
const getStateCandidatesList = state => state.contracts?.candidates;
function requestCandidadeFilesList(id) {
  return API.get(
    `${urlsApi.REQUEST_CANDIDATES_CONTRACT_FILES}?idCandidatoEmEtapa=${id}`

  )
    .then(res => res.data)
    .catch(error => { throw error });
}

function requestDownloadContractList(id) {
  return API.post(
    `${urlsApi.REQUEST_CANDIDATE_DOWNLOAD_CONTRACT_FILES}?idCandidatoEmEtapa=${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/zip',
      },
      responseType: 'arraybuffer',
    }
  )
    .then(res => {
      const blob = new Blob([res.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "arquivo.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => { throw error });
}

function requestAdtiveList(id) {
  return API.get(
    `${urlsApi.REQUEST_ADTIVE_TYPES}?tipoAditivo=${id}`

  )
    .then(res => res.data)
    .catch(error => { throw error });
}
function requestTypesContract() {
  return API.get(
    `${urlsApi.REQUEST_TYPES_CONTRACT}`

  )
    .then(res => res.data)
    .catch(error => { throw error });
}

function requestUsersAdmin() {
  return API.get(
    `${urlsApi.REQUEST_USERS_ADMIN}`

  )
    .then(res => res.data)
    .catch(error => { throw error });
}


function* getTypesContract() {
  try {
    const typesContract = yield requestTypesContract();
    const usersAdmin = yield requestUsersAdmin();


    const kitsContratosList = typesContract.tipoContratos.map(e => ({
      id: e.id,
      name: e.nome
    }));

    const listUserAdmins =  usersAdmin.usuariosAdmin.map(e => ({
      id: e.id,
      personId: e.pessoaId,
      name: e.nome
    }));
    
    yield put({
      type: Types.TYPE_CONTRACTS_SUCCESS,
      kitsContratosList,
      listUserAdmins
    });

  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.TYPE_CONTRACTS_FAIL, error });
  }
}


function* getCandidateFiles({ payload }) {
  try {
    const candidateDocs = yield requestCandidadeFilesList(payload.id);



    const candidatesFiles = convertContract(candidateDocs);


    yield put({
      type: Types.CANDIDATE_FILES_SUCCESS,
      candidatesFiles

    });
  } catch (error) {
    yield put({ type: Types.CANDIDATE_FILES_FAIL, error });
  }
}

function* getDownloadContract({ payload }) {
  try {
    const candidateContract = yield requestDownloadContractList(payload.id);

    yield put({
      type: Types.DOWNLOAD_CONTRACT_FILES_SUCCESS,
      candidateContract
    });
  } catch (error) {
    yield put({ type: Types.DOWNLOAD_CONTRACT_FILES_FAIL, error });
  }
}

function* getAditiveType({ payload }) {
  try {
    const adtiveTypes = yield requestAdtiveList(payload.id);
    const adtiveTypesList = adtiveTypes.map(e => ({
      value: e.aditivo_Id,
      label: e.descricao
    }));


    yield put({
      type: Types.ADTIVE_TYPES_SUCCESS,
      adtiveTypesList
    });
    yield getTypesContract()
  } catch (error) {
    yield put({ type: Types.ADTIVE_TYPES_FAIL, error });
  }
}

function requestSetStatusFile(payload) {
  return API.put(
    `${urlsApi.SET_CANDIDATES_CONTRACT_STATUS}`,
    { IdDocumento: payload.idDocumento, IdCandidatoEmEtapa: payload.idCandidatoEmEtapa, Status: parseInt(payload.status), messageParaCandidato: payload.message }
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* setStatusContractFiles({ payload }) {
  try {
    const candidateDocs = yield requestSetStatusFile(payload);

    window.location.href = `${routes.CONTRACT_CANDIDATE}/${payload.idCandidatoEmEtapa}`;

  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATE_SET_STATUS_FILE_FAIL, error });
  }
}







function requestSetStatusStage(payload) {
  return API.get(
    `${urlsApi.CONTRACT_CONFIRM_STAGE}?idCandidatoEtapa=${payload.id}&dispensarCandidato=${payload.dispensar}&observacao=${payload.observacao}`
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* setStatusContractStage({ payload }) {
  try {
    const candidateDocs = yield requestSetStatusStage(payload);

    window.location.href = `${routes.CONTRACT_CANDIDATE}/${payload.id}`;

  } catch (error) {
    yield put({ type: Types.CANDIDATE_SET_STATUS_STAGE_FAIL, error });
  }
}



function convertContract(candidateDocs) {

  return {
    nome: candidateDocs.nome,
    idCandidatoEtapa: candidateDocs.idCandidatoEmEtapa,
    statusEtapa: candidateDocs.statusEtapa,
    nomeAdmLiberouKit: candidateDocs.nomeAdmLiberouKit,
    podeConfirmar: candidateDocs.podeConfirmar,
    podeEditar: candidateDocs.podeEditar,
    podeSubstituirOuInserirArquivoContrato: candidateDocs.podeSubstituirOuInserirArquivoContrato,
    idVaga: candidateDocs.idVaga,
    cpf: candidateDocs.cpf,
    podeReAnalisarEtapa: candidateDocs.podeReAnalisarEtapa,
    divergencia: candidateDocs.divergencia,
    kit: candidateDocs.kit,
    filesList: candidateDocs.contratos ? candidateDocs.contratos.map(e => ({
      Id: e.id,
      url_contratoAssinado: e.url_contratoAssinado,
      url_contrato: e.url_contrato,
      nome: e.nome,
      status: e.status,
      instrucao: e.descricao,
      divergencia: e.divergencia,

      type: e.tipo
    })) : [],
    filesBaseList: candidateDocs.documentosPossiveisDeEnvio ? candidateDocs.documentosPossiveisDeEnvio.map(e => ({
      Id: e.id,
      descricao: e.descricao,
      nome: e.nome,
      tipoDocumento: e.tipoDocumento
    })) : [],

    adminUsersList: candidateDocs.usuariosAdmin ? candidateDocs.usuariosAdmin.map(e => ({
      Id: e.id,
      name: e.nome,
      pessoa_Id: e.pessoa_Id
    })) : [],

  };
}


function requestChangeKitContToCandidate(payload) {
  let dataSend = { cpf: payload.cpf, candidatoPerfilAssinaturaDocumentos_Id: payload.tipoContrato, aditivo_Id: payload.aditivo_Id ,  AdministradorAssinaturaContrato_Id: payload.AdministradorAssinaturaContrato_Id };
  return API.put(
    `${urlsApi.CHANGE_KIT_CONTRACT}`, dataSend
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function requestNewContToCandidate(payload) {
  let dataSend = { cpfslist: payload.cpfs, kitContratosId: payload.kitContratos, aditivoIds: payload.aditivoIds };
  return API.put(
    `${urlsApi.SET_NEW_CONTRACT}`, dataSend
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}
function* setNewKitContractToCandidate({ payload }) {
  try {
    yield requestChangeKitContToCandidate(payload);
    window.location.href = `${routes.CONTRACT_CANDIDATE}/${payload.id}`;
    yield put({
      type: Types.CANDIDATE_CHANGE_KIT_CONTRACT_SUCCESS,
    });

  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATE_CHANGE_KIT_CONTRACT_FAIL, error });
  }
}

function* setNewContractToCandidate({ payload }) {
  try {
    const logProcess = yield requestNewContToCandidate(payload);

    yield put({
      type: Types.CANDIDATE_INCLUDE_NEW_CONTRACT_SUCCESS,
      logProcess
    });

  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATE_INCLUDE_NEW_CONTRACT_FAIL, error });
  }
}

function requestchangeContractCandidate(payload) {

  let formImg = new FormData();
  formImg.append("contratoFile", payload.file);
  formImg.append("idCandidateEmEtapa", payload.idCandidateEmEtapa);
  return API.put(`${urlsApi.CHANGE_CONTRACT}`, formImg)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* changeContractToCandidate({ payload }) {
  try {
    yield requestchangeContractCandidate(payload);
    yield put({
      type: Types.CANDIDATE_CHANGE_CONTRACT_SUCCESS,
    });
    payload.id = payload.idCandidateEmEtapa;
    yield getCandidateFiles({ payload: payload });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATE_CHANGE_CONTRACT_FAIL, error });
  }
}


function requestChangeContractWithError(payload) {

  let formImg = new FormData();
  formImg.append("novoContrato", payload.file);
  formImg.append("IdCandidatoEmEtapa", payload.idCandidatoEmEtapa);
  formImg.append("messageParaCandidato", payload.messageParaCandidato);

  return API.put(`${urlsApi.CHANCE_CONTRACT_WITH_ERROR}`, formImg)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}




function* ChangeContractWithError({ payload }) {
  try {
    yield requestChangeContractWithError(payload);



    window.location.href = `${routes.CONTRACT_CANDIDATE}/${payload.idCandidatoEmEtapa}`;


  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: Types.CANDIDATE_FILES_FAIL, error });
    yield put({
      type: Types.CANDIDATE_DOCUMENT_RESEND_FAIL,
      error
    });
  }
}


export function* watcherSaga() {
  yield takeLatest(Types.CANDIDATES_VACANCYS_CONTRACTS_REQUEST, getCandidatesVacancyDocs);
  yield takeLatest(Types.CANDIDATE_CONTRACT_REQUEST, getCandidateFiles);
  yield takeLatest(Types.DOWNLOAD_CONTRACT_FILES_REQUEST, getDownloadContract);
  yield takeLatest(Types.CANDIDATE_SET_STATUS_CONTRACT_FILE_REQUEST, setStatusContractFiles);
  yield takeLatest(Types.TYPE_CONTRACTS_REQUEST, getTypesContract);
  yield takeLatest(Types.CANDIDATE_INCLUDE_NEW_CONTRACT_REQUEST, setNewContractToCandidate);
  yield takeLatest(Types.CANDIDATE_CHANGE_KIT_CONTRACT_REQUEST, setNewKitContractToCandidate);


  yield takeLatest(Types.CANDIDATE_CONTRACT_SET_STATUS_STAGE_REQUEST, setStatusContractStage);



  yield takeLatest(Types.CANDIDATE_CONTRACT_RESEND_REQUEST, ChangeContractWithError);

  yield takeLatest(Types.CANDIDATE_CHANGE_CONTRACT_REQUEST, changeContractToCandidate);

  yield takeLatest(Types.ADTIVE_TYPES_REQUEST, getAditiveType);

}
