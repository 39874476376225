import {
  ActionItem,
  Actions,
  Label,
  LabelAction
} from "components/shared/CustomComponentsStyle";
import { Body, SpanContainer } from "./EditItemBlockStyle";
import { Col, Row } from "react-bootstrap";
import { LabelH1, Text } from "../../shared/CustomComponents";
import { cancel, edit } from "../../../assets/icons/icons";

import { FormattedMessage } from "react-intl";
import React from "react";

const defineLabelToKindResponse = kind => {
  switch (kind) {
    case 0:
      return <FormattedMessage id="inputs.multSelect" />;
    case 1:
      return <FormattedMessage id="inputs.uniqSelect" />;
    case 2:
      return <FormattedMessage id="inputs.number" />;
    case 3:
      return <FormattedMessage id="inputs.date" />;
    case 4:
      return <FormattedMessage id="inputs.text" />;
    case 5:
      return <FormattedMessage id="inputs.redaction" />;
    default:
      return "";
  }
};

export default function EditItemBlock({
  position,
  time,
  weigth,
  kindAnswer,
  question,
  setShowDialogDelete,
  setShowEditForm,
  id,
  ShowEditForm
}) {

  return (
    <Body>
      <Row>
        <Col className="d-flex" lg={3}>
          <LabelH1 weight="800" fz="16px">
            <FormattedMessage id="sharedItems.question" />
          </LabelH1>
          <LabelH1 weight="800" ml="5px" fz="16px">
            {position}
          </LabelH1>
        </Col>
        <Col lg={2}>
          <Label>
            <FormattedMessage id="inputs.time" />
            {":"}
          </Label>
          <Text color="darkGray">{time}</Text>
          <Text color="darkGray" ml={'5px'}>min</Text>
        </Col>
        <Col lg={2}>
          <Label>
            <FormattedMessage id="inputs.weight" />
            {":"}
          </Label>
          <Text color="darkGray">{weigth}</Text>
        </Col>
        <Col lg={4}>
          <SpanContainer>
            <Label>
              <FormattedMessage id="inputs.kindAnserw" />
              {":"}
            </Label>
            <Text color="darkGray">
              {defineLabelToKindResponse(kindAnswer)}
            </Text>
          </SpanContainer>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 2, offset: 10 }}>
        {ShowEditForm() && 
            (
          <Actions  flexPosition="flex-end">
 
            <ActionItem onClick={() => setShowEditForm()} mr="30px">
              {edit()}
              <LabelAction>
                <FormattedMessage id="sharedItems.edit" />
              </LabelAction>
            </ActionItem>
            <ActionItem  onClick={() => setShowDialogDelete(true)}>
              {cancel()}
              <LabelAction>
                <FormattedMessage id="sharedItems.delete" />
              </LabelAction>
            </ActionItem>
           
          </Actions>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={10}>
          <Label ta="left" dangerouslySetInnerHTML={{ __html: question }} />
        </Col>
      </Row>
    </Body>
  );
}
