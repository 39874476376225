import styled from "styled-components";

export const Body = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 30px;
`;
export const RowHeaderTable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Ballon = styled.span`
  height: 26px;
  border-radius: 15px;
  background-color: #ffffff;
  margin-right: 6px;
  color: #ffffff;
  text-align: center;
  margin-top: 0.5em;
  padding: 1em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  svg {
    fill: #ffffff;
  }
`;

export const BoxBallons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
