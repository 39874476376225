import styled from 'styled-components';
import theme from '../../shared/Theme';

export const HeaderRowTab = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const CircleIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 50px;
  text-align: center;
  border-style: solid;
  background: #fbfbfb;
  color: ${theme.colors.defaultColor};
`;
