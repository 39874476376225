import styled from "styled-components";
import theme from "../../components/shared/Theme";

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  height: 100vh;
  background: rgb(233, 228, 240);
  background: -moz-linear-gradient(
    90deg,
    rgba(233, 228, 240, 1) 0%,
    rgba(212, 212, 212, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(233, 228, 240, 1) 0%,
    rgba(212, 212, 212, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(233, 228, 240, 1) 0%,
    rgba(212, 212, 212, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e9e4f0",endColorstr="#d4d4d4",GradientType=1);
`;

export const CardsItens = styled.div`
  width: 100%;
  height: 274px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
`;

export const Card = styled.div`
  color: #cfcfcf;
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin-right: 5px;
  box-shadow: 5px 5px 10px #00000026;
  a {
    color: ${theme.colors.labelSecondary};
  }
  :hover {
    a {
      color: ${theme.colors.defaultColor};
      text-decoration: none;
    }
    color: ${theme.colors.defaultColor};

    svg {
      fill: ${theme.colors.defaultColor};
    }
    cursor: pointer;
  }
  border-radius: ${(props) => props.borderRadiusLeft && "5px 0 0 5px"};
  border-radius: ${(props) => props.borderRadiusRight && "0 5px 5px 0"};
`;

export const Icon = styled.svg`
  flex: none;
  width: 100%;
  fill: #cfcfcf;
  transition: all 0.3s;
`;

export const IconLink = styled.svg`
  flex: none;
  width: 20px;
  height: 20px;
  fill: #cfcfcf;
  margin-top: -5px;
  margin-left: 5px;
`;

export const LabelItem = styled.div`
  margin-top: 15px;
  font-family: Arial;
  font-size: 24px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  transition: all 0.2s;
  :hover {
    color: ${theme.colors.defaultColor};
  }
`;

export const RowItens = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
`;

export const LabelUser = styled.div`
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.titleColor};
  margin-bottom: 20px;
`;

export const LabelLogout = styled.div`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  transition: all 0.2s;
  color: ${theme.colors.defaultColor};
  &:hover {
    cursor: pointer;
    color: ${theme.colors.defaultColorHover};
  }
  &:hover g {
    stroke: ${theme.colors.defaultColorHover};
  }
  margin-bottom: 10px;
`;
