import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as routes from "utils/contants/urls";
import isAuthenticated from "utils/isAuthenticated";
import { email } from "../../helpers/validations";
import {
  ButtonDefault,
  ButtonSecondary,
  TextField,
} from "../shared/CustomInputs";
import {
  FormGroupStyle,
  FormRow,
  FormStyle,
  ImageLogin,
  Label,
  LabelFormHeader,
  RowImage,
} from "./FormStyle";

import { IconPassWord, IconUser, logoHeader } from "../../assets/icons/icons";

import {
  InputRow,
  TableColumnLogin,
} from "../../components/shared/CustomComponentsStyle";

const renderLoginItens = (loginValues, setLoginValues, submit) => (
  <>
    <React.Fragment>
      <LabelFormHeader>Login</LabelFormHeader>
      <TableColumnLogin style={{ borderRadius: "1px", width: "100%" }}>
        <InputRow>
          {IconUser()}
          <TextField
            validate={email}
            onChange={(e) =>
              setLoginValues({ ...loginValues, email: e.target.value })
            }
            placeholder="Email"
            type="email"
            name="email"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                submit(loginValues);
              }
            }}
            style={{
              borderRadius: "0 2px 2px 0",
              marginTop: "5px",
              height: "46px",
            }}
          />
        </InputRow>
      </TableColumnLogin>
      <TableColumnLogin style={{ borderRadius: "1px", width: "100%" }}>
        <InputRow>
          {IconPassWord()}
          <TextField
            placeholder="Senha"
            onChange={(e) =>
              setLoginValues({ ...loginValues, password: e.target.value })
            }
            type="password"
            name="password"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                submit(loginValues);
              }
            }}
            style={{
              borderRadius: "0 2px 2px 0",
              height: "46px",
              width: "100%",
              marginTop: "5px",
            }}
          />
        </InputRow>
      </TableColumnLogin>
    </React.Fragment>
  </>
);
const renderForgot = () => (
  <React.Fragment>
    <LabelFormHeader>
      <FormattedMessage id="login.password" />
    </LabelFormHeader>
    <Label>
      <FormattedMessage id="login.disclaimerForgot" />
    </Label>
    <TextField
      validate={email}
      label={<FormattedMessage id="sharedItems.email" />}
      type="email"
      name="email"
    />
  </React.Fragment>
);

const FormLogin = ({ submit }) => {
  const [forgotPassword, setforgotPassword] = useState(false);
  const [loginValues, setLoginValues] = useState({});

  useEffect(() => {
    if (isAuthenticated()) {
      window.location.href = `${routes.APP}`;
    }
  }, []);

  return (
    <React.Fragment>
      <RowImage>{logoHeader()}</RowImage>
      <FormStyle>
        <FormGroupStyle>
          {!forgotPassword &&
            renderLoginItens(loginValues, setLoginValues, submit)}
        </FormGroupStyle>
        <FormRow itens={forgotPassword ? 1 : 0}>
          {forgotPassword && (
            <ButtonSecondary
              mr
              onClick={() => setforgotPassword(!forgotPassword)}
              name={<FormattedMessage id="login.back" />}
            />
          )}

          <ButtonDefault
            onClick={() => submit(loginValues)}
            name={
              !forgotPassword ? (
                <FormattedMessage id="login.buttonLogin" />
              ) : (
                <FormattedMessage id="login.reset" />
              )
            }
          />
        </FormRow>
      </FormStyle>
    </React.Fragment>
  );
};
FormLogin.propTypes = {
  submit: PropTypes.func,
  forgotAction: PropTypes.func,
};

FormLogin.defaultProps = {
  submit: () => {},
  forgotAction: () => {},
};

export default FormLogin;
