import React, { createElement, useEffect, useState, useRef} from 'react';

import IconCard from './DashCards/IconCard';
import ListCard from './DashCards/ListCard';
import ChartCard from './DashCards/ChartCard';
import BarProgressCard from './DashCards/BarProgressCard';
import DashTextLabel from './DashText/DashLabel';
import DashTextTitle from './DashText/DashTitle';
import DashUIDivisor from './DashUI/DashUIDivisor';


import './dynamicdash.scss';

export const DynamicDash = (props) => {

    const dictionaryComponents = {
        iconcard: IconCard,
        listcard: ListCard,
        chartcard: ChartCard,
        barprogresscard: BarProgressCard,
        dashlabel: DashTextLabel,
        dashtitle: DashTextTitle,
        dashuidivisor: DashUIDivisor
    }

    const [time, setTime] = useState(0);
    const [timeFormat, setTimeFormat] = useState('');
    const [ref, setRef] = useState(0);
    const intervalUpdate = useRef(null);
    
    // [
    //     {type:'iconcard', style:'', data:{title: 124, content: 'Solicitações Realizadas', style:'success'}},
    //     {type:'iconcard', style:'', data:{title: 68, content: 'Solicitações Abertas', style:'warning'}},
    //     {type:'iconcard', style:'', data:{title: 26, content: 'Solicitações Atrasadas', style:'danger'}},
    //     {type:'listcard', style:'merge-1', data:{title:'Teste', subtitle:'sub', list:['item 1', 'item 2', 'item 3', 'item 10', 'item 20', 'item 30']}},
    //     {type:'listcard', style:'merge-row-1', data:{title:'Teste', subtitle:'sub', list:['item 10', 'item 20', 'item 30', 'item 10', 'item 20', 'item 30', 'item 10', 'item 20', 'item 30', 'item 10', 'item 20', 'item 30']}},
    //     {type:'iconcard', style:'merge-1', data:{title: 26, content: 'Solicitações Atrasadas', style:'danger'}}
    // ];

    const createDashItem = (_data) => {
        if(dictionaryComponents[_data.type]){
            // const comp = createElement(dictionaryComponents[_item.type], {data:_item.data});
            // return <div className={_item.style}>{comp}</div>;
            const temp = createElement(dictionaryComponents[_data.type], {data:_data});
            return <div className={`${Object.values(_data.style).join(' ')}`}>{temp}</div>;
        }
        return '';
    }

    useEffect(()=> {
        if(time && time >= 0 && !isNaN(props.time)){
            if(time >= 0){
                const temp = new Date();
                temp.setHours(0);
                temp.setMinutes(0);
                temp.setSeconds(time * 60);
                setTimeFormat(temp.toLocaleTimeString());

                intervalUpdate.current = setTimeout(()=>{
                    setTime(time - 1);
                    clearTimeout(intervalUpdate.current);
                }, 1000)
            }else{
                setTimeout(()=> props?.onUpdateData(), 0);
                setTime(props?.time);
                setRef(new Date().getTime());
            }
        }

    }, [time])

    useEffect(()=> {

        // if(props?.time && props?.onUpdateData){
        //     setTime(~~props?.time * 60);
        // }else{
        //     setTimeFormat('-');
        // }

    }, [props]);

    useEffect(()=>{
        setTimeFormat('-');
        //setTime(props?.time);
    },[])

    return (
      <div key={ref} className="dynamic-dash">
        {props?.time ? (
          <div className="lastupdate">
            Próxima atualização{" "}
            {props?.date
              ? `: ${new Date(props?.date).toLocaleDateString()} ${new Date(
                  props?.date
                ).toLocaleTimeString()} `
              : ""}
          </div>
        ) : (
          ""
        )}
        <div className="ui-grid">
          {props?.data ? (
            <>{props?.data.map((item) => createDashItem(item))}</>
          ) : (
            <div className="loading"></div>
          )}
        </div>
      </div>
    );
}