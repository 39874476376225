import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  authRequest: ["payload"],
  authSuccess: ["payload", "isLoading"],
  authFail: ["isLoading", "error"],

  authForgotRequest: ["payload"],
  authForgotSuccess: ["payload", "isLoading"],
  authForgotFail: ["isLoading", "error"],

  authRecoveryRequest: ["payload"],
  authRecoverySuccess: ["payload", "isLoading"],
  authRecoveryFail: ["isLoading", "error"],

  getUserAuthData: [""],
  getUserAuthDataSuccess: ["payload", "isLoading"],
  getUserAuthDataFail: ["isLoading", "error"]
});

const INITIAL_STATE = {
  auth: "",
  password: "",
  isLoading: true,
  userInformations: { nome: "administrador01" }
};

const request = state => ({ 
  ...INITIAL_STATE, 
  isLoading: true 
});
const success = (state, userInformations) => ({
  ...state,
  userInformations,
  isLoading: false,
});
const fail = (state, error) => ({ 
  ...state, 
  isLoading: false, 
  ...error 
});

const requestForgot = state => ({ ...INITIAL_STATE, isLoading: true });
const successForgot = (state, payload) => ({
  ...state,
  payload,
  isLoading: false
});
const failForgot = (state, error) => ({ ...state, isLoading: false, error });

const requestRecovery = state => ({ ...INITIAL_STATE, isLoading: true });
const successRecovery = (state, payload) => ({
  ...state,
  payload,
  isLoading: false
});
const failRecovery = (state, error) => ({ ...state, isLoading: false, error });


const getUserAuthData = (state) => ({ 
  ...INITIAL_STATE, 
  isLoading: true 
});

const getUserAuthDataSuccess = (state, userInformations) => ({
  ...state,
  userInformations,
  isLoading: false,
});

const getUserAuthDataFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});


export default createReducer(INITIAL_STATE, {
  [Types.AUTH_REQUEST]: request,
  [Types.AUTH_SUCCESS]: success,
  [Types.AUTH_FAIL]: fail,

  [Types.AUTH_FORGOT_REQUEST]: requestForgot,
  [Types.AUTH_FORGOT_SUCCESS]: successForgot,
  [Types.AUTH_FORGOT_FAIL]: failForgot,

  [Types.AUTH_RECOVERY_REQUEST]: requestRecovery,
  [Types.AUTH_RECOVERY_SUCCESS]: successRecovery,
  [Types.AUTH_RECOVERY_FAIL]: failRecovery,

  [Types.GET_USER_AUTH_DATA]: getUserAuthData,
  [Types.GET_USER_AUTH_DATA_SUCCESS]: getUserAuthDataSuccess,
  [Types.GET_USER_AUTH_DATA_FAIL]: getUserAuthDataFail,
});
