import { BoxIcon, RowMultAnswer } from './MultAnswerStyle';
import { CheckBox, TextField } from 'components/shared/CustomInputs';

import PropTypes from 'prop-types';
import React from 'react';
import { cancel } from '../../../assets/icons/icons';
import { required } from '../../../helpers/validations';

export default function MultAnswer({
  position, values, setFormValues, formValues,
}) {
  const setChecked = (kind) => {
    const values = formValues.answers;
    values[position].checked = !values[position].checked;
    setFormValues({ ...formValues, answers: values });
  };

  const setAnswers = (answer) => {
    const values = formValues.answers;
    values[position].answer = answer;
    setFormValues({ ...formValues, answers: values });
  };
  const deleteAnswer = (position) => {
    const deleteItem = formValues.answers;
    deleteItem.splice(position, 1);
    setFormValues({ ...formValues, answers: deleteItem });
  };
  return (
    <RowMultAnswer key={position}>
      <CheckBox onChange={() => setChecked()} checked={formValues.answers[position].checked} />
      <TextField
        width="100%"
        validations={[required]}
        value={formValues.answers[position].answer}
        onChange={event => setAnswers(event.target.value)}
        name={`${position}-answer`}
      />
      {formValues.answers.filter(e => e !== null).length > 1 && (
        <BoxIcon onClick={() => deleteAnswer(position)}>{cancel()}</BoxIcon>
      )}
    </RowMultAnswer>
  );
}

MultAnswer.propTypes = {
  deleteAnswer: PropTypes.func,
  position: PropTypes.number,
};

MultAnswer.defaultProps = {
  deleteAnswer: () => {},
  position: 0,
  values: {
    answers: [],
  },
};
