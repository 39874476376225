import styled from 'styled-components';
import theme from 'components/shared/Theme';

export const MenuBody = styled.div`
  float: left;
  width: 240px;
  min-height: 100vh;
  background: white;
  border-right: 1px solid #ececec;
`;

export const ItemMenu = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: baseline;
  width: 100%;
  height: 70px;
  transition: fill 0.25s;
  fill: ${props => (props.selected ? theme.colors.defaultColor : '#d2d2d2')};
  background-color: ${props => props.selected && '#fafafa'};
  border-left: ${props => (props.selected ? `4px solid ${theme.colors.defaultColor}` : `4px solid ${theme.colors.whiteDefault}`)};
  box-shadow: ${props => (props.selected ? `0px 0px 2px rgba(208,208,208,0.4) inset` : ``)};
  color: ${props => (props.selected ? `${theme.colors.defaultColorHover}` : ``)};
  width: 241px!important;
  max-width: none;
  border-top: 1px solid #fff;
  transition: all .2s;
  color:${theme.colors.softGray};
  svg {
    transition: all .2s;
  }
  :first-child {
    margin-top:-1px;
    border-top: ${props => (props.selected ? `1px solid #e2e2e2;` : `1px solid #ffffff;` )};
  }
  :hover {
    color: ${theme.colors.defaultColor};
    * {
      cursor:pointer;
    }
    svg {
      fill: ${theme.colors.defaultColor};
    }
    cursor:pointer;
  }
`;

export const LabelItem = styled.label`
  font-family: Arial;
  font-size: 16px;
  margin-left: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0px;
`;
