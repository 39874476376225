import {
  ButtonDefault,
  ButtonSecondary,
  TextAreaField,
} from "components/shared/CustomInputs";
import { FormattedMessage, injectIntl } from "react-intl";
import React, { useEffect, useState } from "react";

import { LabelH1 } from "components/shared/CustomComponents";
import { Modal } from "react-bootstrap";
import { BodyDialogInternal, RowBnts, SpanQuestion } from "./DialogConfimStyle";
import {
  candidatesActions,
  kindStages,
  pendingCandidateAction,
  bancoTalentosCandidateAction,
  contratarCandidateAction,
  returnForAnalysisAction,
} from "../../../locale";
import {
  questionApproveCandidate,
  questionPendingCandidate,
  questionReproveCandidate,
  questionRemoveCandidate,
  questionReturnForAnalysisCandidate,
} from "../../../locale/index";

const DialogConfirm = ({
  intl,
  numberOfCandidates,
  requestToRegisterActions,
  show,
  closeDialog,
  setFeedBackText,
  kindOfAction,
  stageType,
}) => {
  const [enabledSendToCandidates, setEnabledSendToCandidates] = useState(false);
  const [textFeedBack, setTextFeedBack] = useState("");
  const APPROVED = candidatesActions[0].id;
  const DECLINE = candidatesActions[1].id;
  const TO_REMOVE = candidatesActions[2].id;
  const PENDING = pendingCandidateAction.id;
  const BANKOFTALENTS = bancoTalentosCandidateAction.id;
  const HIRE = contratarCandidateAction.id;
  const RETURN_FOR_ANALYSIS = returnForAnalysisAction.id;

  const STAGE_FEEDBACK = kindStages[7].id;
  const STAGE_PRE_ADMISSION = kindStages[8].id;

  useEffect(() => {
    if (
      (stageType == STAGE_PRE_ADMISSION && kindOfAction == PENDING) ||
      stageType == STAGE_FEEDBACK
    ) {
      setEnabledSendToCandidates(true);
    }
  }, [stageType]);

  const renderTitle = () => {
    if (kindOfAction == APPROVED)
      return <FormattedMessage id="sharedItems.confirmApprove" />;

    if (kindOfAction == DECLINE)
      return <FormattedMessage id="sharedItems.declineApprove" />;

    if (kindOfAction == PENDING)
      return <FormattedMessage id="sharedItems.pendingApprove" />;

    if (kindOfAction == BANKOFTALENTS)
      return <FormattedMessage id="sharedItems.bankofTalents" />;

    if (kindOfAction == HIRE) return <FormattedMessage id="sharedItems.hire" />;

    if (kindOfAction == TO_REMOVE)
      return <FormattedMessage id="sharedItems.confirmRemoval" />;

    return <FormattedMessage id="sharedItems.empty" />;
  };

  const renderQuestion = () => {
    if (kindOfAction == APPROVED)
      return questionApproveCandidate(numberOfCandidates);

    if (kindOfAction == DECLINE)
      return questionReproveCandidate(numberOfCandidates);

    if (kindOfAction == PENDING)
      return questionPendingCandidate(numberOfCandidates);

    if (kindOfAction == TO_REMOVE)
      return questionRemoveCandidate(numberOfCandidates);

    if (kindOfAction == RETURN_FOR_ANALYSIS)
      return questionReturnForAnalysisCandidate(numberOfCandidates);

    return "";
  };

  const renderMessage = () => {
    if (stageType == STAGE_PRE_ADMISSION && kindOfAction == PENDING)
      return <FormattedMessage id="sharedItems.showfeedback" />;

    if (stageType == STAGE_FEEDBACK)
      return <FormattedMessage id="sharedItems.showfeedback" />;

    return <FormattedMessage id="sharedItems.notFeedback" />;
  };

  return (
    <Modal size="lg" show={show}>
      <Modal.Header>
        <LabelH1 fz="24px">{renderTitle()}</LabelH1>
      </Modal.Header>
      <Modal.Body>
        <BodyDialogInternal>
          <SpanQuestion>
            {renderQuestion()}
            {}
          </SpanQuestion>
          <TextAreaField
            placeholder={intl.formatMessage({
              id: "sharedItems.sendFeedBack",
            })}
            value={textFeedBack}
            onChange={(event) => {
              setFeedBackText(event.target.value);
              setTextFeedBack(event.target.value);
            }}
          />
          <p>{"Pelo menos 10 caracteres."}</p>
          <p>{renderMessage()}</p>
          <RowBnts>
            <ButtonSecondary
              name={<FormattedMessage id="sharedItems.no" />}
              onClick={() => closeDialog(false)}
              mr="20px"
              width="110px"
            />
            <ButtonDefault
              onClick={() => requestToRegisterActions(enabledSendToCandidates)}
              name={<FormattedMessage id="sharedItems.yes" />}
              width="110px"
              disabled={!textFeedBack || textFeedBack.length < 10}
            />
          </RowBnts>
        </BodyDialogInternal>
      </Modal.Body>
    </Modal>
  );
};
export default injectIntl(DialogConfirm);
