import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  top: 5%;
  right: 0;
  position: fixed;
  div {
    min-width: 350px;
  }
  text-align: center;
  margin-right: 5px;
  flex-flow: column;
`;
