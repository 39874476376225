import styled from "styled-components";
import theme from "../../shared/Theme";

export const Body = styled.div`
  border-radius: 3px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 19px;
`;

export const LineBox = styled.div`
  height: 250px;
  background-color: ${theme.colors.softGray};
  width: 1px;
`;

export const BoxStatus = styled.div`
  height: 200px;
  width: 250px;
  background-color: ${theme.colors.softGray};
`;

export const IconArrow = styled.div`
  transform: ${props =>
    props.showBodyInfos ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.4s;
`;

export const RowCollapse = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  padding-bottom: 11px;
  width: 100%;
  :hover {
    cursor: pointer;
  }
`;

export const TitleBox = styled.div`
  display: flex;
`;

export const Number = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 20px;
  border: solid 1.4px ${theme.colors.titleColor};
  text-align: center;
  padding-top: 3px;
  padding-right: 1px;
  margin-right: 20px;
  opacity: 0.8;
`;

export const BodyInfos = styled.div`
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  height: ${props => (props.showBodyInfos ? "250px" : "0px")};
  padding: ${props => (props.showBodyInfos ? "35px" : "0px")};
  transition: height 0.4s;
`;

export const RowInfos = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  margin-top: ${props => props.mt};
`;
export const BoxInfo = styled.div`
  display: flex;
  flex-flow: ${props => (props.row ? "row" : "column")};
  justify-content: space-between;
  margin-bottom ${props => (props.mb ? "140px" : "0px")}
`;
export const SpanItems = styled.span`
  height: 20px;
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.colorLabelDefault};
`;
export const SpanInfo = styled.span`
  height: 25px;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.colors.darkGray};
  position: ${props => (props.noAbsolute ? "" : "absolute")};
  margin-top: ${props => (props.noMt ? "0px" : "20px")};
`;

export const RowActions = styled.div`
  display: flex;
  flex-flow: ${props => (props.row ? "row" : "column")};
  margin-top: ${props => props.mt};
  width: ${props => props.width};
`;
export const BoxPosition = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const Divisor = styled.div`
  height: 0px;
  width: 2px;
  background-color: ${theme.colors.softGray};
  opacity: 0.5;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;
const defineColor = status => {
  if (status === 0) return theme.colors.errorColor;
  if (status === 1) return theme.colors.softGreen;
  if (status === 2) return theme.colors.titleColor;
  if (status === 3) return theme.colors.softGray;
  if (status === 4) return theme.colors.orangeColor;
};
export const StatusStageLabel = styled.div`
  font-family: Arial;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => defineColor(props.status)};
  margin-left: 10px;
`;
