import * as routes from "utils/contants/urls";

import { Body, LabelInfo, LabelItem } from "./RailsAssociationStyle";
import { ButtonDefault, ButtonSecondary } from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import BreadCrumb from "components/Layout/breadcrumb";
import DialogDescriptionStage from "components/Rails/DialogDescriptionStage";
import { FormattedMessage } from "react-intl";
import { LabelH1 } from "components/shared/CustomComponents";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import StageBlockList from "components/Rails/StageBlockList";
import { VACANCY_OPTIONS } from "locale/index";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import { Creators as rails } from "store/ducks/rails";
import { Creators as vacancyAction } from "store/ducks/vacancy";
import { Creators as vacancyAssociationAction } from "store/ducks/vacancyAssociation";

const RailsAssociation = ({
  railsWithStages,
  requestRailsWithStages,
  vacancy,
  showVaga,
  match,
  requestAssociationRailToVacancy,
  setIsAssociatingRequest,
  backPreviousPage
}) => {

  useEffect(() => {
    const { params } = match;
    if (!vacancy) { showVaga(params.id, true); }
  }, []);

  useEffect(() => {
    if (vacancy.job.id) requestRailsWithStages(vacancy.job.id);
  }, [vacancy]);

  const [stageObjectDialog, setstageObjectDialog] = useState({
    show: false,
    kindItem: "",
    description: "",
    name: ""
  });

  const cancelAssociating = () => {
    setIsAssociatingRequest(false);
    backPreviousPage();
  };

  if (rails.isLoading)
    return <Load isLoading={rails.isLoading || vacancy.isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb step="" />
      <Row style={{ marginBottom: "10px" }}>
        <Col lg={8}>
          <LabelH1>
            <FormattedMessage id="sharedItems.affiliateRailsDown" />
          </LabelH1>
        </Col>
        <Col lg={2}>
          <ButtonSecondary
            onClick={() => cancelAssociating()}
            name={<FormattedMessage id="sharedItems.cancel" />}
            width="100%"
            style={{ marginRight: "10px" }}
          />
        </Col>
        <Col style={{ display: "flex" }} lg={2}>
          <Link to={routes.RAILS_NEW}>
            <ButtonDefault
              width="164px"
              name={<FormattedMessage id="rails.newRails" />}
            />
          </Link>
        </Col>
      </Row>
      <Body>
        <Row>
          <Col>
            <LabelInfo>
              {railsWithStages.length ? (
                <FormattedMessage id="rails.existiRails" />
              ) : (
                <FormattedMessage id="rails.noRails" />
              )}
            </LabelInfo>
            <LabelInfo style={{ marginLeft: "3px" }}>
              {vacancy.job.funcao}
            </LabelInfo>
          </Col>
        </Row>
        <Row style={{ marginTop: "50px" }}>
          <Col lg={4}>
            <LabelItem>
              <FormattedMessage id="inputs.area" />
            </LabelItem>
          </Col>
          <Col lg={3}>
            <LabelItem>
              <FormattedMessage id="inputs.job" />
            </LabelItem>
          </Col>
          <Col lg={3}>
            <LabelItem>
              <FormattedMessage id="sharedItems.vacancy" />
            </LabelItem>
          </Col>
          <Col lg={2}>
            <LabelItem>
              <FormattedMessage id="sharedItems.selection" />
            </LabelItem>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <LabelInfo>{vacancy.area.descricao}</LabelInfo>
          </Col>
          <Col lg={3}>
            <LabelInfo>{vacancy.job.funcao}</LabelInfo>
          </Col>
          <Col lg={3}>
            <LabelInfo>
              {vacancy.kindVaga &&
                VACANCY_OPTIONS.find(e => e.kind === vacancy.kindVaga).name}
            </LabelInfo>
          </Col>
          <Col lg={2}>
            <LabelInfo>
              {vacancy.kindVaga &&
                VACANCY_OPTIONS.find(e => e.kind === vacancy.kindSelecao).name}
            </LabelInfo>
          </Col>
        </Row>
        { vacancy.associetedRail && (
          <Row>
            <Col lg={12}>
              <StageBlockList
                showDescription={values => setstageObjectDialog(values)}
                requestAssociationRailToVacancy={() =>
                  requestAssociationRailToVacancy(vacancy.id, vacancy.associetedRail.id, vacancy.associetedRail.stages)
                }
                rail={vacancy.associetedRail}
                actualRail={true}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={12}>
            {railsWithStages.map(rail => (
              <StageBlockList
                showDescription={values => setstageObjectDialog(values)}
                requestAssociationRailToVacancy={() =>
                  requestAssociationRailToVacancy(vacancy.id, rail.id)
                }
                publishedVacancy={vacancy.published}
                rail={rail}
              />
            ))}
          </Col>
        </Row>
      </Body>
      <DialogDescriptionStage
        closeDialog={() => setstageObjectDialog({ show: false })}
        {...stageObjectDialog}
        show={stageObjectDialog.show}
      />
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  requestRailsWithStages: id => dispatch(rails.requestRailsWithStages(id)),
  showVaga: (id, editing) =>
    dispatch(vacancyAction.jobShowRequest(id, editing)),
  requestAssociationRailToVacancy: (idVacancy, idRail, stages) =>
    dispatch(vacancyAssociationAction.associationRequest(idVacancy, idRail, stages)),
  setIsAssociatingRequest: value =>
    dispatch(vacancyAssociationAction.setIsAssociatingRequest(value)),

  backPreviousPage: () => dispatch(goBack())
});
const mapStateToProps = state => ({
  railsWithStages: state.rails.railsWithStages,
  vacancy: state.vaga
});

export default connect(mapStateToProps, mapDispatchToProps)(RailsAssociation);

RailsAssociation.propTypes = {
  railsWithStages: PropTypes.arrayOf({
    area: "",
    job: "",
    vacancy: {},
    selection: "",
    createdBy: "",
    createdAt: "",
    stages: [
      {
        kind: "",
        level: "",
        avaliation: "",
        questionTime: ""
      }
    ]
  }),
  requestRailsWithStages: PropTypes.func,
  vacancy: PropTypes.object
};

RailsAssociation.defaultProps = {
  railsWithStages: [],
  vacancy: {
    job: {
      description: ""
    }
  },
  requestRailsWithStages: () => {}
};
