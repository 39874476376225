import * as urls from "../../utils/contants/urlsApi";

import { put, select, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types } from "../ducks/stagePositions";
import { buildPagination } from "../../helpers/sharedHelpers";
import { Types as stageTypes } from "../ducks/stages";

function getListRoles(url) {
  return API.get(url)
    .then(res => res.data)
    .catch(error => {});
}

function* getStagePosition({ filters }) {
  try {
    const { lista, paginacao } = yield getListRoles(`${urls.roleListApi}/?NumeroPagina=${filters.page}`);
    const positionList = yield select(getStatePositionList);
    const stagesPosition = lista.map(k => ({
      function: k.funcao,
      area: k.area.descricao,
      id: k.id
    }));
    const pagination = buildPagination(paginacao, { NumeroPagina: filters.page }, stagesPosition, positionList)
    yield put({ type: Types.STAGE_POSITION_SUCCESS, stagesPosition, pagination });
  } catch (error) {
    yield put({ type: Types.STAGE_POSITION_FAIL, error });
  }
}

function registerRoles(body) {
  return API.post(urls.questionRegisterPositionToStage, body)
    .then(res => res.data)
    .catch(error => {});
}

function* registerRolesToStage({ roles }) {
  try {
    const body = {
      cargos: roles.items.map(e => ({
        idCargo: e.id,
        obrigatorio: e.mandatory
      })),
      etapaId: roles.stageId
    };
    yield registerRoles(body);
    yield put({
      type: stageTypes.STAGE_SHOW_REQUEST,
      payload: { id: roles.stageId }
    });
    yield put({
      type: Types.STAGE_POSITION_REQUEST
    });
  } catch (error) {
    yield put({ type: Types.STAGE_POSITION_REGISTER_FAIL, error });
  }
}

const getStatePositionList = state => state.stagesPosition.stagesPosition;

export function* watcherSaga() {
  yield takeLatest(Types.STAGE_POSITION_REQUEST, getStagePosition);
  yield takeLatest(Types.STAGE_POSITION_REGISTER_REQUEST, registerRolesToStage);
}
