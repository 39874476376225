import styled from "styled-components";
import theme from "../../shared/Theme";

export const Body = styled.div`
  width: 292px;
  height: 260px;
  border-radius: 8px;
  background-color: #f3f3f3;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.8;
  margin-bottom: 5px;
  padding: 20px;
  display: flex;
  flex-flow: column;
  :hover {
    background-color: ${theme.colors.cardColor};
    cursor: pointer;
  }
`;
export const NumberOfItem = styled.div`
  width: 19px;
  height: 19px;
  text-align: center;
  border: 2px solid ${theme.colors.titleColor};
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const RowHeaderCard = styled.div`
  display: flex;
  margin-top: ${props => (props.mt ? props.mt : "0px")};
  justify-content: ${props => (props.jc ? props.jc : "flex-start")};
  height: ${props => (props.height ? props.height : "auto")};
  overflow: ${props => (props.overflow ? props.overflow : "initial")};
`;

export const DisclaimerText = styled.div`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.darkGray};
`;

export const ItemKind = styled.div`
  font-size: ${props => (props.fz ? props.fz : "13px")};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px;
  color: ${props =>
    props.color ? theme.colors.darkGray : theme.colors.softGray};
  margin-bottom: 5px;
`;

export const ViewRow = styled.div`
  display: flex;
  margin-top: ${props => (props.mt ? props.mt : "0px")};
  justify-content: flex-end;
  height: ${props => (props.height ? props.height : "auto")};
  overflow: ${props => (props.overflow ? props.overflow : "initial")};
  flex: 1;
  align-items: flex-end;
`;
