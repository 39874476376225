import * as urls from "utils/contants/urls";

import { Body, DashBoardBody, Row } from "./DashBoardManagerStyle";
import React, { Fragment, PureComponent } from "react";
import { Route, Switch } from "react-router";


import { ConnectedRouter } from "connected-react-router";
import Header from "components/Layout/header/Header";
import Menu from "../Menu/Menu";
import MessageHandler from "components/MessageHandler";
import { connect } from "react-redux";
import Users from "../Users/Users";
import UserDetail from "../UserDetail/UserDetail";
import Profiles from "../Profiles";
class DashBoardManager extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <Fragment>
        <Header location={history} />
        <DashBoardBody>
          <Row>
            <Menu />
            <Body>
              <ConnectedRouter history={history}>
                <Switch>
                  <Route exact path={urls.USERS} component={Profiles} />
                  <Route exact path={urls.USER_NEW} component={Users} />
                  <Route
                    exact
                    path={`${urls.USER_DETAIL}/:id`}
                    component={UserDetail}
                  />
                </Switch>
              </ConnectedRouter>
            </Body>
          </Row>
        </DashBoardBody>
        <MessageHandler />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  location: state.history
});
export default connect(mapStateToProps)(DashBoardManager);
