export function minLength(value) {
  let error;
  if (!value) {
    error = "Campo requerido";
  } else if (value.length < 6) {
    error = "Tamanho minimo inválido";
  }
  return error;
}
export const stageStatus = [
  { name: "Aguardando", id: 0 },
  { name: "Em Analise", id: 1 },
  { name: "Reprovado", id: 2 },
  { name: "Aprovado", id: 3 },
  { name: "Cancelado", id: 4 },
  { name: "Não iniciado", id: 5 },
  { name: "Pendenciado", id: 6 },
  { name: "Concluído", id: 7 }
];
export const validPassword = (value) => {
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  )
  return regex.test(value);;
};
export const number = value =>
  value && isNaN(Number(value)) ? "Deve ser um número" : undefined;
export const required = value => {
  if (Array.isArray(value)) {
    if (value.length) return null;
    return "Selecione uma opção";
  }
  return value ? null : "Campo deve ser informado";
};
export const isAtimer = value => {
  if (value) {
    const timer = value.split(":");

    if (timer.length !== 3) {
      return "Tempo inválido";
    }
    return null;
  }

  return "Tempo inválido";
};

export const minValue = min => value =>
  value && value < 0 ? `Pontuação deve ser maior ou igual a ${min}` : undefined;
export const minValueMonth = value =>
  value > 12 ? `Valor deve ser menor que ${12}` : undefined;

export const minValue0 = minValue(0);
export const minValue1 = minValue(1);
export const minValue12 = minValueMonth(12);

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "E-mail inválido"
    : undefined;

export const tooYoung = value =>
  value && value < 13
    ? "You do not meet the minimum age requirement!"
    : undefined;
export const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? "Really? You still use AOL for your email?"
    : undefined;

export const alphaNumeric = value =>
  value && /[^a-zA-Z\s]/i.test(value)
    ? "Use somente caracteres alfanuméricos"
    : undefined;

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;

export const validDate = value =>
  value && /^(0?[1-9]|[12][0-9]|3[01])(0?[1-9]|1[012])\d{4}$/.test(value)
    ? "Data do aniversário inválida"
    : undefined;

export const validaPIS = (pis) => {
  
  if (pis === undefined || pis === null || pis === "") return false;

  if (pis.length != 11) return false;

  const [N1, N2, N3, N4, N5, N6, N7, N8, N9, N10, D] = pis.split("");

  const X1 = 3 * N1; 
  const X2 = 2 * N2; 
  const X3 = 9 * N3; 
  const X4 = 8 * N4; 
  const X5 = 7 * N5; 
  const X6 = 6 * N6; 
  const X7 = 5 * N7; 
  const X8 = 4 * N8; 
  const X9 = 3 * N9; 
  const X10 = 2 * N10; 

  const soma = X1 + X2 + X3 + X4 + X5 + X6 + X7 + X8 + X9 + X10;
  const resto = soma % 11;

  if (resto < 2)
    return D == 0;

  const digitoVerificador = 11 - resto;

  return digitoVerificador == D;
}
