import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  jobShowRequest: ["id", "editing"],
  jobShowSuccess: ["vaga", "isLoading"],
  jobShowFail: ["isLoading", "error"],

  jobCreateRequest: ["payload", "editing"],
  jobCreateSuccess: ["vaga", "isLoading"],
  jobCreateFail: ["isLoading", "error"],

  jobUpdateRequest: ["payload"],
  jobUpdateSuccess: ["isLoading"],
  jobUpdateFail: ["isLoading", "error"],

  jobCreatePreviewRequest: ["id", "banner"],
  jobCreatePreviewSuccess: ["isLoading"],
  jobCreatePreviewFail: ["isLoading", "error"],
  jobResetPreview: [],

  jobPublishRequest: ["id", "idBanner"],
  jobPublishSuccess: ["isLoading"],
  jobPublishFail: ["isLoading", "error"],

  jobStartForm: [],
  jobStartFormSuccess: [],
  jobStartFormFail: [],

  requestEditAssociationAttributesRails: ['payload'],
  requestEditAssociationAttributesRailsSuccess: ['isLoading'],
  requestEditAssociationAttributesRailsFail: ['isLoading', 'error'],
});
const INITIAL_STATE = {
  aboutWork: "",
  area: { id: null },
  areaFormation: [],
  competence: [],
  candidatos: [],
  benefits: [],
  city: [],
  code: 0,
  eligibleJobs: [],
  fluxStatus: "",
  id: undefined,
  showSalary: false,
  informeSalary: false,
  informeSchedules: false,
  deficiency: false,
  job: { id: null },
  jobName: "",
  jornada: "",
  jorney: "",
  placeOfWork: "",
  responsibility: "",
  salary: 0,
  schooling: "",
  skills: "",
  status: "enabled",
  timeOfWork: "",
  vacancyReq: "",
  vacancyTitle: "",
  lastPromotion: "",
  vacancys: 0,
  startWork: "",
  affiliate: 0,
  resultCenter: 0,
  section: 0,
  taker: 0,
  managerName: 0,
  preview: {},
  indicators: [],
  associetedRail: undefined,
  vacancyGroup: "",
  isLoading: true,
};

const request = state => ({ ...INITIAL_STATE, isLoading: true });
const success = (state, payload) => ({
  ...payload,
  isLoading: false
});
const fail = (state, error) => ({ ...state, isLoading: false, error });

const requestCreate = (state, payload) => ({
  ...state,
  ...payload.payload,
  isLoading: true
});
const successCreate = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const failCreate = (state, error) => ({ ...state, isLoading: false, error });

const requestUpdate = state => ({ ...state, isLoading: true });
const successUpdate = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const failUpdate = (state, error) => ({ ...state, isLoading: false, error });

const jobCreatePreviewRequest = state => ({
  ...INITIAL_STATE,
  isLoading: true,
  ...state
});
const jobCreatePreviewSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const jobCreatePreviewFail = (state, error) => ({
  ...state,
  isLoading: false,
  error
});
const jobResetPreview = state => ({
  ...state,
  preview: {},
  isLoading: false
});

const jobPublishRequest = state => ({
  ...INITIAL_STATE,
  isLoading: true,
  ...state
});
const jobPublishSuccess = state => ({
  ...state,
  published: true,
  isLoading: false
});
const jobPublishFail = (state, error) => ({
  ...state,
  isLoading: false,
  error
});

const jobStartForm = () => ({
  ...INITIAL_STATE,
  isLoading: true
});
const jobStartFormSuccess = () => ({
  ...INITIAL_STATE,
  isLoading: false
});
const jobStartFormFail = (state, error) => ({
  isLoading: false,
  error
});

const requestEditAssociationAttributesRails = state => ({ ...state, isLoading: true });
const requestEditAssociationAttributesRailsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const requestEditAssociationAttributesRailsFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

export default createReducer(INITIAL_STATE, {

  [Types.JOB_SHOW_REQUEST]: request,
  [Types.JOB_SHOW_SUCCESS]: success,
  [Types.JOB_SHOW_FAIL]: fail,

  [Types.JOB_CREATE_REQUEST]: requestCreate,
  [Types.JOB_CREATE_SUCCESS]: successCreate,
  [Types.JOB_CREATE_FAIL]: failCreate,

  [Types.JOB_UPDATE_REQUEST]: requestUpdate,
  [Types.JOB_UPDATE_SUCCESS]: successUpdate,
  [Types.JOB_UPDATE_FAIL]: failUpdate,

  [Types.JOB_CREATE_PREVIEW_REQUEST]: jobCreatePreviewRequest,
  [Types.JOB_CREATE_PREVIEW_SUCCESS]: jobCreatePreviewSuccess,
  [Types.JOB_CREATE_PREVIEW_FAIL]: jobCreatePreviewFail,
  [Types.JOB_RESET_PREVIEW]: jobResetPreview,

  [Types.JOB_PUBLISH_REQUEST]: jobPublishRequest,
  [Types.JOB_PUBLISH_SUCCESS]: jobPublishSuccess,
  [Types.JOB_PUBLISH_FAIL]: jobPublishFail,

  [Types.JOB_START_FORM]: jobStartForm,
  [Types.JOB_START_FORM_SUCCESS]: jobStartFormSuccess,
  [Types.JOB_START_FORM_FAIL]: jobStartFormFail,

  [Types.REQUEST_EDIT_ASSOCIATION_ATTRIBUTES_RAILS]: requestEditAssociationAttributesRails,
  [Types.REQUEST_EDIT_ASSOCIATION_ATTRIBUTES_RAILS_SUCCESS]: requestEditAssociationAttributesRailsSuccess,
  [Types.REQUEST_EDIT_ASSOCIATION_ATTRIBUTES_RAILS_FAIL]: requestEditAssociationAttributesRailsFail,
});
