import { colorByStageStatus, colorBoderByStageStatus, colorTextByStageStatus } from "../../../helpers/sharedHelpers";
import styled from "styled-components";
import theme from "../../shared/Theme";

export const BoxTimeLine = styled.div`
  display: flex;
  flex-flow: column;
  @media screen and (max-width: ${theme.size.tablet}) {
    margin-top: 1em;
    overflow-y: auto;
  }
`;

export const DotStage = styled.div`
  width: 37px;
  height: 37px;
  display: flex;
  justify-items: center;
  color: ${props => colorTextByStageStatus(props.stageStatus)};
  background-color:${props => colorByStageStatus(props.stageStatus)};
  border-radius: 50px;
  text-align: center;
  border: ${props => {
    return `3px solid ${colorBoderByStageStatus(props.stageStatus)}`;
  }};
`;

const renderGradient = status => {
  return `linear-gradient(to right, ${colorBoderByStageStatus(
    status
  )} , ${colorBoderByStageStatus(status)})`;
};

export const LineStatus = styled.div`
  height: 2px;
  background-image: ${props =>
    props.status != 0
      ? renderGradient(props.status)
      : `linear-gradient(to right, ${theme.colors.titleColor}, ${theme.colors.titleColor})`};
  width: 17px;
`;

export const BoxStageStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const DotBox = styled.div`
  display: flex;
  flex-flow: column;
  cursor: pointer;
`;

export const StageName = styled.span`
  height: 15px;
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b;
  width: 100%;
  position: absolute;
  margin-top: 50px;
  margin-left: -10px;
`;

export const RowTimeLine = styled.div`
  display: flex;
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const LabelNumber = styled.span`
  font-size: 24px;
  padding-top:-1px;
  height: 15px;
  margin-left:9px;
  border-radius: 100px;
  background-color: ${props => colorByStageStatus(props.status)};
  color: ${props => colorTextByStageStatus(props.status)};
`;

export const RowDots = styled.div`
  display: flex;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const DotSubtitleContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  justify-content: flex-start;
  padding-top: 1em;
`;

export const DotSubtitle = styled.div`
  display: flex;
  flex-flow: row;
  padding: 0em 1em 1em 0em;
`;

export const DotSubtitleBubble = styled.span`
  display: flex;
  flex-flow: row;
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  border: ${props => {
    return `1px solid ${props.backgroundColor == theme.colors.white ? theme.colors.titleColor : props.backgroundColor}`;
  }};
  width: 1em;
  height: 1em;
`;

export const DotSubtitleLabel = styled.span`
  display: flex;
  flex-flow: row;
  font-size: 13px;
  line-height: 15px;
  margin-left: 6px;
  color: ${theme.colors.darkGray};
`;
