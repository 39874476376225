import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({

  getDependentDataRequest: ["payload"],
  getDependentDataSuccess: ["payload", "isLoading"],
  getDependentDataFail: ["isLoading", "error"],

  getHealthAndDentalPlanValuesRequest: ["payload"],
  getHealthAndDentalPlanValuesSuccess: ["payload", "isLoading"],
  getHealthAndDentalPlanValuesFail: ["isLoading", "error"],

  getContractDocumentRequest: ["payload"],
  getContractDocumentSuccess: ["payload", "isLoading"],
  getContractDocumentFail: ["isLoading", "error"],

  finishStageDigitalDocument: ["payload"],
  finishStageDigitalDocumentSuccess: ["payload", "isLoading"],
  finishStageDigitalDocumentFail: ["error", "isLoading"],

  contractDocumentUpdateRequest: ["payload"],
  contractDocumentUpdateSuccess: ["isLoading"],
  contractDocumentUpdateFail: ["isLoading"],

  listDataSubscribersRequest: ['payload'],
  listDataSubscribersSuccess: ["payload"],
  listDataSubscribersFail: ['error', 'isLoading'],

});

const getDependentDataRequest = state => ({
  ...state,
  isLoading: true
});

const getDependentDataSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const getDependentDataFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
  hasErro: true
});


const getHealthAndDentalPlanValuesRequest = state => ({
  ...state,
  isLoading: true
});

const getHealthAndDentalPlanValuesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const getHealthAndDentalPlanValuesFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
  hasErro: true
});



const contractDocumentUpdateRequest = state => ({
  ...state,
  isLoading: true
});

const contractDocumentUpdateSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const contractDocumentUpdateFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
  hasErro: true
});

const INITIAL_STATE = {
  isLoading: true,
  document: '',
  dependents: '',
  healthAndDentalPlanValues: '',
  dataSubscribers:'',
};

const getContractDocumentRequest = (payload) => ({
  ...INITIAL_STATE,
  isLoading: true,
  ...payload
});
const getContractDocumentSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const getContractDocumentFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});


export const finishStageDigitalDocument = state => ({
  ...state,
  isLoading: true
});

export const finishStageDigitalDocumentSuccess = (state, payload) => ({
  ...state,
  test: payload,
  isLoading: false
});

export const finishStageDigitalDocumentFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});


export const listDataSubscribersRequest = state => ({
  ...state,
  isLoading: true
});

export const listDataSubscribersSuccess = (state, payload = null) => (
  {
  ...state,
  dataSubscribers: payload?.dataSubscribers,
  isLoading: false  
  });

export const listDataSubscribersFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});
export default createReducer(INITIAL_STATE, {

  [Types.GET_DEPENDENT_DATA_REQUEST]: getDependentDataRequest,
  [Types.GET_DEPENDENT_DATA_SUCCESS]: getDependentDataSuccess,
  [Types.GET_DEPENDENT_DATA_FAIL]: getDependentDataFail,

  [Types.GET_HEALTH_AND_DENTAL_PLAN_VALUES_REQUEST]: getHealthAndDentalPlanValuesRequest,
  [Types.GET_HEALTH_AND_DENTAL_PLAN_VALUES_SUCCESS]: getHealthAndDentalPlanValuesSuccess,
  [Types.GET_HEALTH_AND_DENTAL_PLAN_VALUES_FAIL]: getHealthAndDentalPlanValuesFail,

  [Types.GET_CONTRACT_DOCUMENT_REQUEST]: getContractDocumentRequest,
  [Types.GET_CONTRACT_DOCUMENT_SUCCESS]: getContractDocumentSuccess,
  [Types.GET_CONTRACT_DOCUMENT_FAIL]: getContractDocumentFail,

  [Types.FINISH_STAGE_DIGITAL_DOCUMENT]: finishStageDigitalDocument,
  [Types.FINISH_STAGE_DIGITAL_DOCUMENT_SUCCESS]: finishStageDigitalDocumentSuccess,
  [Types.FINISH_STAGE_DIGITAL_DOCUMENT_FAIL]: finishStageDigitalDocumentFail,

  [Types.CONTRACT_DOCUMENT_UPDATE_REQUEST]: contractDocumentUpdateRequest,
  [Types.CONTRACT_DOCUMENT_UPDATE_SUCCESS]: contractDocumentUpdateSuccess,
  [Types.CONTRACT_DOCUMENT_UPDATE_FAIL]: contractDocumentUpdateFail,

  [Types.LIST_DATA_SUBSCRIBERS_REQUEST]: listDataSubscribersRequest,
  [Types.LIST_DATA_SUBSCRIBERS_SUCCESS]: listDataSubscribersSuccess,
  [Types.LIST_DATA_SUBSCRIBERS_FAIL]: listDataSubscribersFail,

});
