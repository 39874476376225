import {
  ActionsItems,
  Body,
  Icon,
  RowCheckItem,
  RowHeader
} from "./StagePositionStyle";
import {
  ButtonDefault,
  ButtonSecondary,
  CheckBox,
  SwitchInput
} from "components/shared/CustomInputs";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import { filterIcon, order } from "../../assets/icons/icons";

import BreadCrumb from "components/Layout/breadcrumb";
import { Col } from "react-bootstrap";
import FilterStage from "../Forms/FilterStage"
import { FormattedMessage } from "react-intl";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";

const renderTableItens = (itens, action) =>
  itens.map(job => (
    <TableRow key={job.id}>
      <TableColumn first>
        <RowCheckItem>
          <CheckBox
            checked={job.selected}
            onChange={() => action(job.id, "area")}
          />
          <Label>{job.function}</Label>
        </RowCheckItem>
      </TableColumn>
      <Label>{job.area}</Label>
      <TableColumn>
        <SwitchInput
          checked={job.mandatory}
          onChange={() => action(job.id, "mandatory")}
        />
      </TableColumn>
    </TableRow>
  ));

const StagePosition = ({
  positions,
  stageId,
  pagination,
  registerStagesPositions,
  backCreate,
  previousPosition,
  options,
  requestStagesPositions
}) => {
  const [itemMandatory, setMandatory] = useState(positions.stagesPosition);
  const [selectedAll, setSelectAll] = useState(false);
  const [filters, setFilters] = useState({ search: "", type: "", level: "" });

  const changeTableStage = (id, action) => {
    const update = itemMandatory.map(item => {
      const editedItem = item;
      if (editedItem.id === id) {
        if (action === "mandatory") {
          editedItem.mandatory = !item.mandatory;
          if (!editedItem.selected && editedItem.mandatory) {
            editedItem.selected = true;
          }
        } else {
          editedItem.selected = !item.selected;
        }
      }
      return editedItem;
    });
    setMandatory(update);
    if (
      update.filter(e => e.mandatory === true).length === itemMandatory.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const changeAllTable = () => {
    setSelectAll(!selectedAll);
    const updateAll = itemMandatory.map(item => {
      const editedItem = item;
      editedItem.selected = !selectedAll;
      return editedItem;
    });
    setMandatory(updateAll);
  };

  const registerRolesAndBack = () => {
    registerStagesPositions({
      items: itemMandatory.filter(e => e.selected),
      stageId
    });
    backCreate(true);
  };

  useEffect(() => {
    const previousSelected = itemMandatory.map(e => {
      const item = previousPosition.find(k => k.id === e.id);
      if (item) {
        return {
          id: e.id,
          mandatory: item.mandatory,
          selected: true,
          function: e.function,
          area: e.area
        };
      }
      return e;
    });

    setMandatory(previousSelected);

    if (
      previousSelected.filter(e => e.mandatory === true).length ===
      previousSelected.length
    ) {
      setSelectAll(true);
    }
  }, [previousPosition, positions]);

  const onChangeFilters = (filters) => {
    setFilters(filters);
  }

  const filterJobs = () => {
    const { search } = filters;
    return itemMandatory.filter((e) => {
      return e.function.toLowerCase().includes(search.toLowerCase()) || e.area.toLowerCase().includes(search.toLowerCase());
    })
  }

  const getPositionPage = (filters) => {
    requestStagesPositions({ page: filters.NumeroPagina });
  }

  return (
    <React.Fragment>
      <RowHeader>
        <BreadCrumb />
        <ActionsItems>
          <ButtonSecondary
            onClick={() => backCreate(true)}
            width="250px"
            mr="25px"
            name={<FormattedMessage id="sharedItems.cancel" />}
          />
          <ButtonDefault
            onClick={() => registerRolesAndBack()}
            width="250px"
            name={<FormattedMessage id="newStageAboutYou.associationJob" />}
          />
        </ActionsItems>
      </RowHeader>
      <Body>
        <RowHeader className="row">
          <Col lg={{ span: 4 }}>
            <FilterStage
              kindFilter={""}
              onChangeFilter={onChangeFilters}
              options={options}
              name={"inputs.jobAndArea"}
            />
          </Col>
          <Col lg={{ span: 2, offset: 4 }}>
            <Label>
              <FormattedMessage id="newStageAboutYou.mandatory" />
            </Label>
          </Col>
          <Col lg={{ span: 1 }}>
            <SwitchInput
              checked={selectedAll}
              onChange={() => changeAllTable()}
            />
          </Col>
        </RowHeader>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead first ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.role" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.area" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.mandatory" />
                </LabelHeader>
                {order()}
              </TableHead>
            </TableRow>
            {renderTableItens(filterJobs(), changeTableStage)}
          </TableBody>
        </Table>
        <Pagination getItemsPage={(e) => getPositionPage(e)} {...pagination} />
      </Body>
    </React.Fragment>
  );
};

StagePosition.propTypes = {
  stagesPosition: PropTypes.arrayOf({
    id: 0,
    function: "",
    area: ""
  }),
  pagination: PropTypes.objectOf({
    currentPage: 1,
    lastPage: 1,
    sizeItens: 0
  }),
  requestStagesPositions: PropTypes.func,
  backCreate: PropTypes.func,
  positions: PropTypes.objectOf({
    isLoading: PropTypes.bool,
    stagesPosition: PropTypes.array,
    type: PropTypes.string
  }),
  requestRegisterPositions: PropTypes.func
};

StagePosition.defaultProps = {
  stagesPosition: [],
  pagination: {
    currentPage: 1,
    lastPage: 11,
    sizeItens: 256
  },
  requestStagesPositions: () => {},
  backCreate: () => {},
  requestRegisterPositions: () => {},
  positions: {
    isLoading: false,
    stagesPosition: [],
    type: ""
  }
};

export default StagePosition;
