import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  stagePositionRequest: ['filters'],
  stagePositionSuccess: ['payload'],
  stagePositionFail: ['isLoading', 'error'],

  stagePositionRegisterRequest: ['roles', 'idStage'],
  stagePositionRegisterSuccess: ['isLoading'],
  stagePositionRegisterFail: ['isLoading', 'error'],
});
const INITIAL_STATE = {
  stagesPosition: [],
  isLoading: false,
};

const request = state => ({ ...state, isLoading: true });
const success = (state, payload) => ({ ...state, ...payload, isLoading: false });
const fail = (state, error) => ({ ...INITIAL_STATE, isLoading: false, error });

const requestRegister = state => ({ ...INITIAL_STATE, isLoading: true });
const successRegister = (state, stagesPosition) => ({
  ...state,
  ...stagesPosition,
  isLoading: false,
});
const failRegister = (state, error) => ({ ...state, isLoading: false, error });

export default createReducer(INITIAL_STATE, {
  [Types.STAGE_POSITION_REQUEST]: request,
  [Types.STAGE_POSITION_SUCCESS]: success,
  [Types.STAGE_POSITION_FAIL]: fail,

  [Types.STAGE_POSITION_REGISTER_REQUEST]: requestRegister,
  [Types.STAGE_POSITION_REGISTER_SUCCESS]: successRegister,
  [Types.STAGE_POSITION_REGISTER_FAIL]: failRegister,
});
