import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { LabelH1 } from 'components/shared/CustomComponents';
import { CheckBox, ButtonDefault, ButtonSecondary } from 'components/shared/CustomInputs';
import PropTypes from 'prop-types';
import {
  DisclaimerQuestion,
  OptionsRows,
  ParagraphQuestion,
  ActionRows,
} from './DialogBankOfTalentsStyle';

export default function DialogBankOfTalents({ show, action, closeDialog }) {
  const [selectOption, setSelectOption] = useState({ kind: '' });
  return (
    <Modal show={show}>
      <Modal.Header>
        <LabelH1 ml="15px" fz="24px">
          <FormattedMessage id="vacancyCreate.useBankOfTalents" />
        </LabelH1>
      </Modal.Header>
      <OptionsRows mt="15px">
        <ParagraphQuestion>
          <FormattedMessage id="vacancyCreate.questionBankOfTalents" />
        </ParagraphQuestion>
      </OptionsRows>

      <OptionsRows>
        <CheckBox
          checked={selectOption.kind === 'YesAndShare'}
          onChange={() => setSelectOption({ kind: 'YesAndShare' })}
          radius
        />
        <DisclaimerQuestion>
          <FormattedMessage id="vacancyCreate.yesDiv" />
        </DisclaimerQuestion>
      </OptionsRows>

      <OptionsRows>
        <CheckBox
          checked={selectOption.kind === 'YesAndNotShare'}
          onChange={() => setSelectOption({ kind: 'YesAndNotShare' })}
          radius
        />
        <DisclaimerQuestion>
          <FormattedMessage id="vacancyCreate.noDiv" />
        </DisclaimerQuestion>
      </OptionsRows>

      <OptionsRows>
        <CheckBox
          checked={selectOption.kind === 'No'}
          onChange={() => setSelectOption({ kind: 'No' })}
          radius
        />
        <DisclaimerQuestion noUpperCase>
          <FormattedMessage id="sharedItems.no" />
        </DisclaimerQuestion>
      </OptionsRows>

      <ActionRows>
        <ButtonSecondary
          onClick={() => closeDialog()}
          mr
          name={<FormattedMessage id="sharedItems.cancel" />}
          width="180px"
        />
        <ButtonDefault
          onClick={() => action(selectOption.kind)}
          name={<FormattedMessage id="sharedItems.next" />}
          width="180px"
        />
      </ActionRows>
    </Modal>
  );
}
DialogBankOfTalents.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func,
  closeDialog: PropTypes.func,
};

DialogBankOfTalents.defaultProps = {
  show: false,
  action: () => {},
  closeDialog: () => {},
};
