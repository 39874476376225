import React, { useState } from 'react';
import { LabelH1 } from 'components/shared/CustomComponents';
import { ButtonSecondary } from 'components/shared/CustomInputs';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  arrowColapse,
  declined,
  evaluate,
  approved,
  awaitAValiation,
  manualAprovation,
} from '../../../assets/icons/icons';
import {
  Body,
  RowCollapse,
  TitleBox,
  Number,
  BodyInfos,
  SpanItems,
  BoxInfo,
  SpanInfo,
  RowActions,
  IconArrow,
  StatusStageLabel,
  Divisor,
} from './SimpleStageCardStyle';

const renderTitleStage = (kindOfStage, option) => {
  switch (kindOfStage) {
    case 2:
      return !option ? (
        <FormattedMessage id="rails.trainner" />
      ) : (
        <FormattedMessage id="rails.trainnerKind" />
      );
    case 3:
      return <FormattedMessage id="rails.assay" />;
    case 4:
      return !option ? (
        <FormattedMessage id="rails.medic" />
      ) : (
        <FormattedMessage id="rails.exameKind" />
      );
    case 5:
      return <FormattedMessage id="rails.admition" />;
    case 6:
      return !option ? (
        <FormattedMessage id="rails.ambientation" />
      ) : (
        <FormattedMessage id="rails.ambientationKind" />
      );
    default:
      return <FormattedMessage id="sharedItems.stagesTitle.test" />;
  }
};

export default function SimpleStageCard({ stage, position }) {
  const [showBodyInfos, setShowBodyInfos] = useState(false);
  return (
    <Body>
      <RowCollapse onClick={() => setShowBodyInfos(!showBodyInfos)}>
        <TitleBox>
          <Number>
            <LabelH1 fz="16px">{position}</LabelH1>
          </Number>
          <LabelH1 fz="20px">
            {renderTitleStage(stage.kindStage)}
            {':'}
            {stage.name}
          </LabelH1>
        </TitleBox>
        <IconArrow showBodyInfos={showBodyInfos}>{arrowColapse()}</IconArrow>
      </RowCollapse>
      <BodyInfos showBodyInfos={showBodyInfos}>
        {showBodyInfos && (
          <React.Fragment>
            <Row>
              <Col lg="7">
                <BoxInfo>
                  <SpanItems>{renderTitleStage(stage.kindStage, true)}</SpanItems>
                  <SpanInfo>{stage.kindTest}</SpanInfo>
                </BoxInfo>
              </Col>
              <Col lg="1" style={{ display: 'flex', justifyContent: 'center' }}>
                <Divisor />
              </Col>
              <Col style={{ display: 'flex', flexFlow: 'column' }} lg="2">
                <BoxInfo mt="20px">
                  <RowActions row>
                    {stage.status === 0 && declined()}
                    {stage.status === 1 && approved()}
                    {stage.status === 2 && evaluate()}
                    {stage.status === 3 && awaitAValiation()}
                    {stage.status === 4 && manualAprovation()}
                    <StatusStageLabel status={stage.status}>
                      {stage.status === 0 && <FormattedMessage id="sharedItems.evaluateReproved" />}
                      {stage.status === 1 && <FormattedMessage id="sharedItems.evaluateApproved" />}
                      {stage.status === 2 && <FormattedMessage id="sharedItems.evaluate" />}
                      {stage.status === 3 && <FormattedMessage id="sharedItems.evaluatewait" />}
                      {stage.status === 4 && (
                        <FormattedMessage id="sharedItems.manualEvaluatewait" />
                      )}
                    </StatusStageLabel>
                  </RowActions>
                  <RowActions mt="20px">
                    <SpanItems>
                      <FormattedMessage id="sharedItems.dateOfTest" />
                    </SpanItems>
                    <SpanInfo>{stage.startDate}</SpanInfo>
                  </RowActions>
                  <RowActions mt="30px">
                    <ButtonSecondary
                      name={<FormattedMessage id="sharedItems.evaluateportfolio" />}
                      width="200px"
                    />
                  </RowActions>
                </BoxInfo>
              </Col>
            </Row>
            <Row style={{ marginTop: '-100px' }}>
              <Col style={{ display: 'flex', flexFlow: 'column' }} lg="7">
                <SpanItems>
                  <FormattedMessage id="sharedItems.description" />
                </SpanItems>
                <SpanInfo>{stage.description}</SpanInfo>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </BodyInfos>
    </Body>
  );
}
