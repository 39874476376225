import React, { useState } from "react";
import { LabelH1 } from "components/shared/CustomComponents";
import { ButtonSecondary } from "components/shared/CustomInputs";
import { Row, Col } from "react-bootstrap";

import { FormattedMessage } from "react-intl";
import {
  approved,
  arrowColapse,
  declined,
  evaluate,
  awaitAValiation,
  manualAprovation
} from "../../../assets/icons/icons";
import {
  Body,
  RowCollapse,
  TitleBox,
  Number,
  BodyInfos,
  SpanItems,
  BoxInfo,
  SpanInfo,
  RowActions,
  IconArrow,
  StatusStageLabel,
  Divisor
} from "./TestCardStyle";

export default function TestCard({ stage }) {
  const [showBodyInfos, setShowBodyInfos] = useState(false);
  return (
    <Body>
      <RowCollapse onClick={() => setShowBodyInfos(!showBodyInfos)}>
        <TitleBox>
          <Number>
            <LabelH1 fz="16px">2</LabelH1>
          </Number>
          <LabelH1 fz="20px">
            <FormattedMessage id="sharedItems.stagesTitle.test" />
          </LabelH1>
        </TitleBox>
        <IconArrow showBodyInfos={showBodyInfos}>{arrowColapse()}</IconArrow>
      </RowCollapse>
      <BodyInfos showBodyInfos={showBodyInfos}>
        {showBodyInfos && (
          <React.Fragment>
            <Row>
              <Col
                style={{ display: "flex", justifyContent: "space-between" }}
                lg="7"
              >
                <BoxInfo>
                  <SpanItems>
                    <FormattedMessage id="rails.kindTest" />
                  </SpanItems>
                  <SpanInfo>{stage.kindTest}</SpanInfo>
                </BoxInfo>
                <BoxInfo>
                  <SpanItems>
                    <FormattedMessage id="rails.avaliation" />
                  </SpanItems>
                  <SpanInfo>{stage.avaliation}</SpanInfo>
                </BoxInfo>
                <BoxInfo>
                  <SpanItems>
                    <FormattedMessage id="rails.levelTest" />
                  </SpanItems>
                  <SpanInfo>{stage.levelTest}</SpanInfo>
                </BoxInfo>
                <BoxInfo>
                  <SpanItems>
                    <FormattedMessage id="rails.timeToTest" />
                  </SpanItems>
                  <SpanInfo>{stage.timeTest}</SpanInfo>
                </BoxInfo>
                <BoxInfo>
                  <SpanItems>
                    <FormattedMessage id="rails.timeToQuetionTest" />
                  </SpanItems>
                  <SpanInfo>
                    {stage.howManyQuestions}
                    {"/"}
                    {stage.spendQuestionsInSeconds}
                  </SpanInfo>
                </BoxInfo>
              </Col>
              <Col style={{ display: "flex", justifyContent: "center" }} lg="1">
                <Divisor />
              </Col>
              <Col lg="2">
                <BoxInfo mb>
                  <RowActions row>
                    {stage.status === 0 && declined()}
                    {stage.status === 1 && approved()}
                    {stage.status === 2 && evaluate()}
                    {stage.status === 3 && awaitAValiation()}
                    {stage.status === 4 && manualAprovation()}
                    <StatusStageLabel status={stage.status}>
                      {stage.status === 0 && (
                        <FormattedMessage id="sharedItems.evaluateReproved" />
                      )}
                      {stage.status === 1 && (
                        <FormattedMessage id="sharedItems.evaluateApproved" />
                      )}
                      {stage.status === 2 && (
                        <FormattedMessage id="sharedItems.evaluate" />
                      )}
                      {stage.status === 3 && (
                        <FormattedMessage id="sharedItems.evaluatewait" />
                      )}
                      {stage.status === 4 && (
                        <FormattedMessage id="sharedItems.manualEvaluatewait" />
                      )}
                    </StatusStageLabel>
                  </RowActions>
                  <RowActions mt="30px">
                    <SpanItems>
                      <FormattedMessage id="rails.spendTimeInTest" />
                    </SpanItems>
                    <SpanInfo>226 min / 30 min</SpanInfo>
                  </RowActions>
                  <RowActions mt="50px">
                    <ButtonSecondary
                      width="180px"
                      name={<FormattedMessage id="sharedItems.evalueteTest" />}
                    />
                  </RowActions>
                </BoxInfo>
              </Col>
              <Col lg="2">
                <BoxInfo mb>
                  <RowActions row>
                    <SpanItems>
                      <FormattedMessage id="sharedItems.dateOfTest" />
                    </SpanItems>
                    <SpanInfo>{stage.startDate}</SpanInfo>
                  </RowActions>
                  <RowActions mt="30px">
                    <SpanItems>
                      <FormattedMessage id="sharedItems.result" />
                    </SpanItems>
                    <SpanInfo>
                      {stage.porcRightQuestions}
                      {`   (${stage.howManyRightQuestions} Acertos) `}
                    </SpanInfo>
                  </RowActions>
                </BoxInfo>
              </Col>
            </Row>
            <Row style={{ marginTop: "-250px" }}>
              <Col style={{ display: "flex", flexFlow: "column" }} lg="7">
                <SpanItems>
                  <FormattedMessage id="sharedItems.description" />
                </SpanItems>
                <SpanInfo>{stage.description}</SpanInfo>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </BodyInfos>
    </Body>
  );
}
