import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  requestRails: [],
  requestRailsSuccess: ['rails', 'isLoading'],
  requestRailsFail: ['isLoading', 'error'],

  requestRailsWithStages: ['id'],
  requestRailsWithStagesSuccess: ['rails', 'isLoading'],
  requestRailsWithStagesFail: ['isLoading', 'error'],

  requestCreateRails: ['payload'],
  requestCreateRailsSuccess: ['rails', 'isLoading'],
  requestCreateRailsFail: ['isLoading', 'error'],

  requestAddStageToRails: ['payload', 'id'],
  requestAddStageToRailsSuccess: ['rails', 'isLoading'],
  requestAddStageToRailsFail: ['isLoading', 'error'],

  requestListRails: ['filters'],
  requestListRailsSuccess: ['payload'],
  requestListRailsFail: ['isLoading', 'error'],

  requestShowRails: ['id'],
  requestShowRailsSuccess: ['rail', 'isLoading'],
  requestShowRailsFail: ['isLoading', 'error'],

  requestDisableRails: ['id'],
  requestDisableSuccess: ['rail', 'isLoading'],
  requestDisableFail: ['isLoading', 'error'],

  requestAssociationAttributesRails: ['payload'],
  requestAssociationAttributesRailsSuccess: ['rail', 'isLoading'],
  requestAssociationAttributesRailsFail: ['isLoading', 'error'],

  requestDeleteStageInRails: ['idStage', 'idRail'],
  requestDeleteStageInRailsSuccess: ['isLoading'],
  requestDeleteStageInRailsFail: ['isLoading', 'error'],

  requestEditRails: ['payload'],
  requestEditRailsSuccess: [''],
  requestEditRailsFail: ['error'],

  requestCleanRailsState: [''],
});

const INITIAL_STATE = {
  rails: [],
  isLoading: false,
  railsWithStages: [],
};

const request = () => ({ ...INITIAL_STATE, isLoading: true });
const success = (state, stages) => ({ ...state, ...stages, isLoading: false });
const fail = (state, error) => ({ ...state, isLoading: false, error });

const requestRailsWithStage = () => ({ ...INITIAL_STATE, isLoading: true });
const successRailsWithStage = (state, railsWithStages) => ({
  ...state,
  ...railsWithStages,
  isLoading: false,
});
const failRailsWithStage = (state, error) => ({ ...state, isLoading: false, error });

const requestCreateRail = () => ({ ...INITIAL_STATE, isLoading: true });
const requestCreateRailSuccess = (state, rail) => ({ ...state, ...rail, isLoading: false });
const requestCreateRailFail = (state, error) => ({ ...state, isLoading: false, error });

const requestAddStageToRail = (state) => ({ ...state, isLoading: true });
const requestAddStageToRailSuccess = (state, rails) => ({ ...state, ...rails, isLoading: false });
const requestAddStageToRailFail = (state, error) => ({ ...state, isLoading: false, error });

const requestListRails = (state) => ({ ...state, isLoading: true });
const requestListRailsSuccess = (state, payload) => ({ ...state, ...payload, isLoading: false });
const requestListRailsFail = (state, error) => ({ ...state, isLoading: false, error });

const requestShowRails = (state) => ({ ...state, isLoading: true });
const requestShowSuccess = (state, rails) => ({ ...state, ...rails, isLoading: false });
const requestShowFail = (state, error) => ({ ...state, isLoading: false, error });

const requestDisableRails = state => ({ ...state, isLoading: true });
const requestDisableSuccess = (state, rails) => ({ ...state, ...rails, isLoading: false });
const requestDisableFail = (state, error) => ({ ...state, isLoading: false, error });

const requestAssociationAttributesRails = state => ({ ...state, isLoading: true });
const requestAssociationAttributesRailsSuccess = (state, rails) => ({
  ...state,
  ...rails,
  isLoading: false,
});
const requestAssociationAttributesRailsFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const requestDeleteStageInRails = state => ({ ...state, isLoading: true });
const requestDeleteStageInRailsSuccess = (state, rails) => ({
  ...state,
  ...rails,
  isLoading: false,
});
const requestDeleteStageInRailsFail = (state, error) => ({ ...state, isLoading: false, error });

const requestEditRails = (state) => ({ ...state, isLoading: true });
const requestEditRailsSuccess = (state) => ({ ...state, isLoading: false });
const requestEditRailsFail = (state, error) => ({ ...state, isLoading: false, error });

const requestCleanRailsState = () => ({ ...INITIAL_STATE, isLoading: false });

export default createReducer(INITIAL_STATE, {
  [Types.REQUEST_RAILS]: request,
  [Types.REQUEST_RAILS_SUCCESS]: success,
  [Types.REQUEST_RAILS_FAIL]: fail,

  [Types.REQUEST_RAILS_WITH_STAGES]: requestRailsWithStage,
  [Types.REQUEST_RAILS_WITH_STAGES_SUCCESS]: successRailsWithStage,
  [Types.REQUEST_RAILS_WITH_STAGES_FAIL]: failRailsWithStage,

  [Types.REQUEST_CREATE_RAILS]: requestCreateRail,
  [Types.REQUEST_CREATE_RAILS_SUCCESS]: requestCreateRailSuccess,
  [Types.REQUEST_CREATE_RAILS_FAIL]: requestCreateRailFail,

  [Types.REQUEST_ADD_STAGE_TO_RAILS]: requestAddStageToRail,
  [Types.REQUEST_ADD_STAGE_TO_RAILS_SUCCESS]: requestAddStageToRailSuccess,
  [Types.REQUEST_ADD_STAGE_TO_RAILS_FAIL]: requestAddStageToRailFail,

  [Types.REQUEST_LIST_RAILS]: requestListRails,
  [Types.REQUEST_LIST_RAILS_SUCCESS]: requestListRailsSuccess,
  [Types.REQUEST_LIST_RAILS_FAIL]: requestListRailsFail,

  [Types.REQUEST_SHOW_RAILS]: requestShowRails,
  [Types.REQUEST_SHOW_RAILS_SUCCESS]: requestShowSuccess,
  [Types.REQUEST_SHOW_RAILS_FAIL]: requestShowFail,

  [Types.REQUEST_DISABLE_RAILS]: requestDisableRails,
  [Types.REQUEST_DISABLE_SUCCESS]: requestDisableSuccess,
  [Types.REQUEST_DISABLE_FAIL]: requestDisableFail,

  [Types.REQUEST_ASSOCIATION_ATTRIBUTES_RAILS]: requestAssociationAttributesRails,
  [Types.REQUEST_ASSOCIATION_ATTRIBUTES_RAILS_SUCCESS]: requestAssociationAttributesRailsSuccess,
  [Types.REQUEST_ASSOCIATION_ATTRIBUTES_RAILS_FAIL]: requestAssociationAttributesRailsFail,

  [Types.REQUEST_DELETE_STAGE_IN_RAILS]: requestDeleteStageInRails,
  [Types.REQUEST_DELETE_STAGE_IN_RAILS_SUCCESS]: requestDeleteStageInRailsSuccess,
  [Types.REQUEST_DELETE_STAGE_IN_RAILS_FAIL]: requestDeleteStageInRailsFail,

  [Types.REQUEST_EDIT_RAILS]: requestEditRails,
  [Types.REQUEST_EDIT_RAILS_SUCCESS]: requestEditRailsSuccess,
  [Types.REQUEST_EDIT_RAILS_FAIL]: requestEditRailsFail,

  [Types.REQUEST_CLEAN_RAILS_STATE]: requestCleanRailsState,
});
