import { put, takeLatest, select, call } from "redux-saga/effects";
import * as urlsApi from "../../utils/contants/urlsApi";
import API from "../../utils/API";
import { Types } from "../ducks/otherPayments";
import { buildErroHandleItems } from "../../helpers/sharedHelpers";
import { Types as MessageTypes } from "../ducks/messages";

import { buildPagination } from "../../helpers/sharedHelpers";

var getStateCandidatesList = (state) => state.otherPayments.PagamentosList;

function* getUserPayments() {
  try {

    const response = yield API.get(`${urlsApi.GET_USER_PAYMENTS}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    const PagamentosList = response.lista.map((beneficios, index) => {
      return {
        id: beneficios.id,
        cpf: beneficios.cpf,
        nome: beneficios.nome,
        mesCompetencia: converterDataParaBrasileiro(beneficios.mesCompetencia),
        motivo: beneficios.motivo == 1 ? "Comissão" : "Variável",
        pagamento: beneficios.pagamento,
      };
    });
    yield put({
      type: Types.GET_USER_PAYMENTS_SUCCESS,
      PagamentosList,
    });
  } catch (error) {
    yield put({ type: Types.GET_USER_PAYMENTS_FAIL, error });

    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}
function* getUserPaymentsFiltered(filters) {
  try {
    const response = yield API.put(
      `${urlsApi.GET_USER_PAYMENTS_FILTERED_BY_ID}`,
      filters.filter
    )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    const Pagamento = response.lista.map((beneficios, index) => {
      return {
        id: beneficios.id,
        cpf: beneficios.cpf,
        nome: beneficios.nome,
        mesCompetencia: converterDataParaBrasileiro(beneficios.mesCompetencia),
        pagamento: beneficios.pagamento,
        motivo: beneficios.motivo == 1 ? "Comissão" : "Variável",
      };
    });

    yield put({
      type: Types.GET_USER_PAYMENTS_SUCCESS,
      Pagamento,
    });

  } catch (error) {
    yield put({ type: Types.GET_USER_PAYMENTS_FILTERED_FAIL, error });

    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}

function* atualizarUserPaymentsById(benefits) {
  var Benefits = benefits.benefit.Beneficio;
  var payload = {
    Id: Benefits.id,
    Motivo: Benefits.motivo,
    pagamento: Benefits.pagamento,
  };

  try {
    const response = yield API.put(
      `${urlsApi.UPDATE_USER_PAYMENTS_BY_ID}`,
      payload
    )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    var ItemAtualizado = response.ItemAtualizado;

    yield put({
      type: Types.ATUALIZAR_USER_PAYMENTS_BY_ID_SUCCESS,
      ItemAtualizado,
    });
    const messages = [{ type: "info", message: "Pagamento atualizado com sucesso!" }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
    const filters = {
      filter: {
        id: Benefits.id,
      },
    };

    yield call(getUserPaymentsFiltered, filters);
  } catch (error) {
    yield put({ type: Types.ATUALIZAR_USER_PAYMENTS_BY_ID_FAIL, error });


    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}

function* inativarUserBenefitsById(benefits) {
  var payload = {
    id: benefits.benefit.id,
    cpf: benefits.benefit.cpf,
    candidatoId: benefits.benefit.candidato_Id,
  };

  try {
    const response = yield API.put(
      `${urlsApi.REMOVE_USER_PAYMENTS_BY_ID}`,
      payload
    )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    var ItemInativado = response.ItemInativado;

    yield put({
      type: Types.INATIVAR_USER_BENEFITS_BY_ID_SUCCESS,
      ItemInativado,
    });

    const messages = [{ type: "info", message: "Item inativado com sucesso!" }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });

  } catch (error) {
    yield put({ type: Types.INATIVAR_USER_BENEFITS_BY_ID_FAIL, error });

    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}

function* createUserPayments(benefits) {
  const payload = {
    Cpf: benefits.payload.cpf,
    MesCompetencia: benefits.payload.mesCompetencia,
    Motivo: benefits.payload.motivo,
    Pagamento: typeof benefits.payload.valor !== "number" ? parseFloat(
      benefits.payload.valor.replace("R$", "").replace(",", "."), 0
    ) : 0,
  }

  try {
    const response = yield API.post(`${urlsApi.CREATE_USER_PAYMENTS}`, payload)
      .then((res) => res.data)
      .catch((error) => {
        throw error
      });

    var criouBeneficio = response.pagamentoRegistrado;

    yield put({
      type: Types.CREATE_USER_PAYMENTS_SUCCESS,
      criouBeneficio,
    });
    const messages = [{ type: "info", message: "Pagamento criado com sucesso!" }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });

  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CREATE_USER_PAYMENTS_FAIL, error });
  }
}


function* filtrarPagamento(filters) {
  try {

    var payload = {
      Nome: filters.payload.nomeFuncionario || "",
      Cpfs: filters.payload.cpfList || [],
      Competencia: filters.payload.mesCompetencia || "",
      NumeroPagina: filters.payload.NumeroPagina || 1,
    };

    const { lista, paginacao } = yield API.put(`${urlsApi.GET_USER_PAYMENTS_FILTERED}`, payload)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    const stateBeneficios = yield select(getStateCandidatesList);

    const PagamentosList = lista.map((beneficios, index) => {
      return {
        id: beneficios.id,
        cpf: beneficios.cpf,
        nome: beneficios.nome,
        mesCompetencia: converterDataParaBrasileiro(beneficios.mesCompetencia),
        pagamento: beneficios.pagamento,
        motivo: beneficios.motivo == 1 ? "Comissão" : "Variável",
      };
    });

    const paginacaoNumber = {
      ...paginacao,
      page: filters.payload.NumeroPagina,
    };

    const pagination = buildPagination(
      paginacaoNumber,
      filters.payload,
      PagamentosList,
      stateBeneficios,
      20
    );

    yield put({
      type: Types.FILTRAR_PAGAMENTO_SUCCESS,
      PagamentosList,
      pagination
    });
  } catch (error) {
    yield put({ type: Types.FILTRAR_PAGAMENTO_FAIL, error });

    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}


function requestExportPaymentsInformations(body) {
  return API.put(`${urlsApi.EXPORT_PAYMENTS_INFORMATION}`, body, {
    responseType: "blob"
  })
    .then(res => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dados_candidatos.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => {
      throw error;
    });
}

function* exportPaymentsInformations(payload) {
  try {
    const filtros = payload.candidates;
    const numeroPagina = filtros.NumeroPagina || 1;
    const filter = {
      nome: filtros.nome || "",
      cpfs: filtros.cpfs || [],
      competencia: filtros.Competencia || "",
      numeroPagina: numeroPagina,
    };

    yield requestExportPaymentsInformations(filter);
    yield put({ type: Types.CANDIDATES_EXPORT_PAYMENTS_INFORMATION_SUCCESS });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_EXPORT_FAIL, error });
  }
}

function ValidaEntrada(filters) {
  try {
    if (!filters.payload.nomeFuncionario) filters.payload.nomeFuncionario = "";

    if (!filters.payload.cpfList) filters.payload.cpfList = [];

    if (!filters.payload.NumeroPagina) filters.payload.NumeroPagina = 1;

    if (!filters.payload.mesCompetencia) filters.payload.mesCompetencia = "";
  } catch (err) {
    console.log(err);
  }

  return filters;
}

function converterDataParaBrasileiro(dataISO) {
  const dataInicial = dataISO.toString().split("T")[0];
  const [ano, mes, dia] = dataInicial.toString().split("-");
  return `${dia}/${mes}/${ano}`;
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_USER_PAYMENTS, getUserPayments);

  yield takeLatest(Types.GET_USER_PAYMENTS_FILTERED, getUserPaymentsFiltered);
  yield takeLatest(Types.ATUALIZAR_USER_PAYMENTS_BY_ID, atualizarUserPaymentsById);
  yield takeLatest(Types.INATIVAR_USER_BENEFITS_BY_ID, inativarUserBenefitsById);
  yield takeLatest(Types.CREATE_USER_PAYMENTS, createUserPayments);

  yield takeLatest(Types.FILTRAR_PAGAMENTO, filtrarPagamento);

  yield takeLatest(Types.CANDIDATES_EXPORT_PAYMENTS_INFORMATION_REQUEST, exportPaymentsInformations);

}
