import * as urls from "utils/contants/urls";
import * as Permissions from "utils/PerfilAcesso";

import { Icon } from 'react-icons-kit';
import {barChart} from 'react-icons-kit/fa/barChart';

import { ItemMenu, LabelItem, MenuBody } from "./MenuStyle";
import React, { useState } from "react";
import {
  dashboard,
  candidates,
  home,
  rails,
  steps,
  vaga,
  managers
} from "assets/icons/icons";

import { FormattedMessage } from "react-intl";
import { INITIAL_STATE as STAGE_INITIAL_STATE } from "store/ducks/stages";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Creators as railsAction } from "store/ducks/rails";
import { Creators as stageAction } from "store/ducks/stages";
import { Creators as vacancyAction } from "store/ducks/vacancy";
import { Creators as vacancyAssociationAction } from "store/ducks/vacancyAssociation";

const Menu = ({
  goTo,
  setIsAssociatingRequest,
  clearVacancyForm,
  resetSelectedStage,
  resetStageId,
  requestCleanRailsState,
  requestCleanStageState
}) => {
  const [selected, setSelected] = useState({
    dashboard: true,
    home: false,
    vacancy: false,
    steps: false,
    candidates: false,
    rails: false,
    modules: false,
    bankoftalents:false
  });
  const updateRouter = newFocus => {
    setSelected({ ...newFocus });
    if (newFocus.dashboard) goTo(urls.TALENTS_AEC);
    if (newFocus.home) goTo(urls.VACANCY);
    if (newFocus.vacancy) goTo(urls.VACANCY);
    if (newFocus.steps) goTo(urls.STAGES);
    if (newFocus.rails) goTo(urls.RAILS);
    if (newFocus.candidates) goTo(urls.VACANCY_CANDIDATES);

    if (newFocus.bankoftalents) goTo(urls.BANKOFTALENTS);
    if (newFocus.modules) goTo(urls.APP);
    setIsAssociatingRequest(false);
    clearVacancyForm();
    requestCleanStageState();
    requestCleanRailsState();
    resetSelectedStage();
    resetStageId();
  };

  return (
    <MenuBody>
      <ItemMenu
        selected={selected.dashboard}
        onClick={() =>
          updateRouter({
            dashboard: true,
            home: false,
            vacancy: false,
            steps: false,
            candidates: false,
            rails: false,
            managers: false,
            modules: false
          })
        }
      >
        <Icon style={{color:selected.dashboard ? '#004489' : ''}} size="1.5em" icon={barChart} />
        <LabelItem>
          <FormattedMessage id="menuTalentBar.dashboard" />
        </LabelItem>
      </ItemMenu>
      <ItemMenu
        selected={selected.vacancy}
        onClick={() =>
          updateRouter({
            dashboard: false,
            home: false,
            vacancy: true,
            steps: false,
            candidates: false,
            rails: false,
            managers: false,
            modules: false,
            bankoftalents:false
          })
        }
      >
        {vaga()}
        <LabelItem>
          <FormattedMessage id="menuTalentBar.vacancy" />
        </LabelItem>
      </ItemMenu>
      <ItemMenu
        selected={selected.rails}
        onClick={() =>
          updateRouter({
            dashboard: false,
            home: false,
            vacancy: false,
            steps: false,
            candidates: false,
            rails: true,
            managers: false,
            modules: false,
            bankoftalents:false
          })
        }
      >
        {rails()}
        <LabelItem>
          <FormattedMessage id="menuTalentBar.rails" />
        </LabelItem>
      </ItemMenu>
      <ItemMenu
        selected={selected.steps}
        onClick={() =>
          updateRouter({
            dashboard: false,
            home: false,
            vacancy: false,
            steps: true,
            candidates: false,
            managers: false,
            modules: false,
            bankoftalents:false
          })
        }
      >
        {steps()}
        <LabelItem>
          <FormattedMessage id="menuTalentBar.stage" />
        </LabelItem>
      </ItemMenu>
      <ItemMenu
        selected={selected.candidates}
        onClick={() =>
          updateRouter({
            dashboard: false,
            home: false,
            vacancy: false,
            steps: false,
            candidates: true,
            managers: false,
            modules: false,
            bankoftalents:false

          })
        }
      >
        {candidates()}
        <LabelItem>
          <FormattedMessage id="menuTalentBar.candidates" />
        </LabelItem>
      </ItemMenu>

      <ItemMenu
        selected={selected.bankoftalents}
        onClick={() =>
          updateRouter({
            home: false,
            vacancy: false,
            steps: false,
            candidates: false,
            managers: false,
            bankoftalents:true
          })}
      >
        {home()}
        <LabelItem>
          <FormattedMessage id="menuBankOfTalentsBar.BankOfTalents" />
        </LabelItem>
      </ItemMenu>


      <ItemMenu
        selected={selected.modules}
        onClick={() =>
          updateRouter({
            modules: true
          })
        }
      >
        {home()}
        <LabelItem>
          <FormattedMessage id="menuManagerBar.modules" />
        </LabelItem>
      </ItemMenu>

    </MenuBody>
  );
};

const MapStateToProps = state => ({});
const MapDispatchToProps = dispatch => ({
  goTo: route => dispatch(push(route)),
  setIsAssociatingRequest: value =>
    dispatch(vacancyAssociationAction.setIsAssociatingRequest(value)),
  clearVacancyForm: () => dispatch(vacancyAction.jobStartForm()),
  resetSelectedStage: () =>
    dispatch(stageAction.stageShowSuccess(STAGE_INITIAL_STATE.stage)),
  resetStageId: () => dispatch(stageAction.stageCreateSuccess(undefined)),
  requestCleanRailsState: () => dispatch(railsAction.requestCleanRailsState()),
  requestCleanStageState: () => dispatch(stageAction.requestCleanStageState())
});

export default connect(MapStateToProps, MapDispatchToProps)(Menu);
