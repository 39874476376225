import * as routes from "../../utils/contants/urls";

import { put, takeLatest } from "redux-saga/effects";

import { Types } from "../ducks/vacancyAssociation";
import { history } from "../../utils/routes";

function* getShowRails({ idVacancy, idRail, stages }) {
  try {
    yield put({
      type: Types.ASSOCIATION_SUCCESS,
      idVacancy,
      idRail,
      isAssociating: true,
      stages: stages ? stages : undefined,
      isEditing: stages ? true : false,
    });
    history.push(`${routes.RAILS_CREATE_ASSOCIATION}/${idRail}`);
  } catch (error) {}
}

function* setIsAssociating({ isAssociating }) {
  try {
    yield put({ type: Types.SET_IS_ASSOCIATING_SUCCESS, isAssociating });
  } catch (error) {
    yield put({ type: Types.SET_IS_ASSOCIATING_FAIL, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.ASSOCIATION_REQUEST, getShowRails);
  yield takeLatest(Types.SET_IS_ASSOCIATING_REQUEST, setIsAssociating);
}
