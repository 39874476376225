import styled from "styled-components";
import theme from "../Theme";

export const Input = styled.input`
  outline: none;
  height: 40px;
  border: 1px solid #d2d2d2;
  color: ${theme.colors.darkGray};
  font-weight: 400;
  border-radius: 3px;
  padding-left: 13px;
  margin-top: ${props => (props.mt ? props.mt : "0px")};
  width: ${props => (props.width ? props.width : "100%")};
  margin-left:${props => (props.ml ? props.ml : "0px")}
  margin-right:${props => (props.mr ? props.mr : "0px")}
  &:hover {
    border-color: ${theme.colors.defaultColor};
  }
  &:focus {
    border-color: ${theme.colors.defaultColor};
  }
`;

export const TextAreaStyle = styled.textarea`
  outline: none;
  border: 1px solid #d2d2d2;
  color: ${theme.colors.darkGray};
  height: 8em;
  font-weight: 400;
  border-radius: 3px;
  padding-left: 13px;
  padding-top:10;
  margin-top: ${props => (props.mt ? props.mt : "0px")};
  width: ${props => (props.width ? props.width : "100%")};
  margin-left:${props => (props.ml ? props.ml : "0px")}
  margin-right:${props => (props.mr ? props.mr : "0px")}
  &:hover {
    border-color: ${theme.colors.defaultColor};
  }
  &:focus {
    border-color: ${theme.colors.defaultColor};
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: wrap;
  flex-flow: column;
  ${props => (props.mb ? `margin-bottom: ${props.mb}em` : "")};
  ${props => (props.mt ? `margin-top:${props.mt}em` : "")};
  ${props => (props.mr ? `margin-right:${props.mr}em` : "")};
  ${props => (props.ml ? `margin-left:${props.ml}em` : "")};
  ${props => (props.margin ? `margin: ${props.margin}` : "")};
  width: ${props => (props.width ? props.width : "100%")};
  .css-2b097c-container {
    width: 100%;
  }
  @media screen and (min-width: ${theme.size.mobileS}) and (max-width: ${theme
      .size.mobileL}) {
    width: auto;
  }
`;
export const LabelInput = styled.label`
  font-size: ${props => (props.size ? props.size : "0.875em")};
  color: ${props => (props.color ? props.color : theme.colors.softGray)};
  margin-bottom: ${props => props.noMarginBottom && "0px"};
  display: flex;
  flex-grow: 100;
  margin-left: ${props => props.ml};
`;

export const ButtonDefaultStyle = styled.button`
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Arial", sans-serif;
  letter-spacing: -0.1px;
  font-weight: lighter;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s;
  border-radius: 3px;
  pointer-events: ${props => (!props.disabled ? "all" : "none")};
  background-color: ${props =>
    props.disabled ? "#e0e0e0" : theme.colors.defaultColor};
  width: ${props => (props.width ? props.width : "100%")};
  height: 40px;
  font-size: 14px;
  text-shadow: 0px 1px 2px #2d2d2d4a;
  box-shadow: 0px 1px 5px #2d2d2d4f;
  border: none;
  margin-left: ${props => (!props.ml ? "0px" : "20px")};
  margin-right: ${props => (!props.mr ? "0px" : "20px")};
  margin-top: ${props => (props.mt ? props.mt : "20px")};
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.defaultColorHover};
    text-shadow: none;
    box-shadow: none;
  }
  height: "36px";
`;

export const ButtonSecondaryStyle = styled.button`
  border: 1px solid ${theme.colors.defaultColor};
  background-color: transparent;
  opacity: ${props => (props.disabled ? "0.3" : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  border-radius: 3px;
  width: ${props => (props.width ? props.width : "100%")};
  height: 40px;
  margin-left: ${props => (!props.ml ? "0px" : "20px")};
  margin-right: ${props => (!props.mr ? "0px" : "20px")};
  margin-bottom: ${props => (props.mb ? props.mb : "")};
  margin-top: ${props => (props.mt ? props.mt : "")};
  height: 36px;
  padding: 5px;
  letter-spacing: -0.1px;
  color: ${theme.colors.defaultColor};
  text-transform: uppercase;
  font-weight: 600;
  &:hover {
  }
`;
export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
`;
export const HiddenCheckbox = styled.input`
  min-height: 20px;
  margin: unset;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  min-width: 23px;
`;
export const Icon = styled.svg`
  fill: none;
  stroke: white;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke-width: 3px;
  width: 20px;
  height: 20px;
`;
export const InputRowCheckBox = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledCheckbox = styled.div`
  ${Icon} {
    visibility: ${props =>
      props.checked && !props.radius ? "visible" : "hidden"};
  }
  width: 23px;
  height: 23px;
  background: ${props => (props.checked ? theme.colors.defaultColor : "none")};
  border: 1px solid ${theme.colors.defaultColor};
  margin-right: -17px;
  border-radius: ${props => (props.radius ? "50px" : "3px")};
  transition: all 150ms;
  display: ${props => (props.disabled ? "none" : "block")};
`;

export const customStylesSelect = {
  container: (provided, state) => ({
    width: "100%",
    font: "arial",
    menu: provided => ({ ...provided, zIndex: 9999 }),
    borderColor: "#004489",
    ...provided
  }),
  control: (provided, state) => ({
    minHeight: "40px",
    placeholder: {
      fontSize: "14px",
      color: theme.colors.softGray
    },
    ...provided,
    border: "1px solid #e0e0e0",
    "&:hover": {
      borderColor: theme.colors.defaultColor
    }
  }),
  dropdownIndicator: (provided, state) => ({
    fill: theme.colors.defaultColor,
    svg: {
      fill: theme.colors.defaultColor,
      height: "15px"
    },
    ...provided
  }),
  indicatorSeparator: base => ({
    ...base,
    display: "none"
  }),
  onFocus: (provided, state) => ({
    ...provided
  })
};

export const HiddenRadio = styled.input.attrs({ type: "radio" })`
  overflow: hidden;
  height: 20px;
  position: absolute;
  white-space: nowrap;
  width: 20px;
  margin: 1px;
  cursor: pointer;
  opacity: 0;
  z-index: 1000;
`;

export const RadioIcon = styled.span`
  position: absolute
  border-radius: 50%;
  background-color: ${props =>
    props.disabled ? theme.colors.ligthGray : theme.colors.defaultColor};
  min-height: 15px;
  min-width: 15px;
  margin: 3px;
  transition: opacity 150ms ease-in-out;
  opacity: ${props => (props.opacity ? props.opacity : 0)};
`;

export const StyledRadio = styled.div`
  width: 23px;
  height: 23px;
  border: 1px solid ${theme.colors.defaultColor};
  margin-right: 10px;
  margin-Left: "-13px";
  border-radius: 50%;
  //display: ${props => (props.disabled ? "none" : "block")};
`;

export const SearchInput = styled.input`
  @import url("https://fonts.googleapis.com/css?family=Ubuntu");
  height: 48px;
  width: 360px;
  max-width: 320px;
  border-radius: 4px;
  border: none;
  margin-top: ${props => (props.mt ? props.mt : "0px")};
  margin-bottom: ${props => (props.mb ? props.mb : "0px")};
  margin-left: ${props => (props.ml ? props.ml : "0px")};
  margin-right: ${props => (props.mr ? props.mr : "0px")};
  backdrop-filter: blur(4px);
  background-color: ${theme.colors.white};
  padding: 2px 8px;
  color: #485466;
  font-family: Ubuntu;
  font-size: 16px;
  line-height: 18px;
`;
