import styled from 'styled-components';
import theme from 'components/shared/Theme';

export const Body = styled.div`
  background-color: ${theme.colors.whiteDefault};
  padding: 30px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;

export const LabelInfo = styled.span`
  font-family: Arial;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.darkGray};
`;

export const LabelItem = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.softGray};
`;
