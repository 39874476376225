import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  jobsRequest: ["payload"],
  jobsSuccess: ["jobs", "isLoading"],
  jobsFail: [],

  jobsSearchRequest: ["term"],
  jobsSearchSuccess: ["jobs"],
  jobsSearchFail: ["error"],

  jobsDeleteRequest: ["id", "payload"],
  jobsDeleteRequestSuccess: [],
  JobsDeleteRequestFail: ["error"],
});
const INITIAL_STATE = {
  jobs: [],
  isLoading: true
};

const request = state => ({ ...state, isLoading: true });
const success = (state, payload) => ({
  pagination: payload.pagination,
  jobs: payload.jobs,
  isLoading: false
});
const fail = (state, error) => ({ ...INITIAL_STATE, isLoading: false, error });

const jobsDeleteRequest = (state) => ({
  ...state,
  isLoading: true,
})

const jobsDeleteRequestSuccess = (state) => ({
  ...state,
  isLoading: false,
})

const jobsDeleteRequestFail = (state, error) => ({
  ...state,
  isLoading: false,
  error
})

export default createReducer(INITIAL_STATE, {
  [Types.JOBS_REQUEST]: request,
  [Types.JOBS_SUCCESS]: success,
  [Types.JOBS_FAIL]: fail,

  [Types.JOBS_SEARCH_REQUEST]: request,
  [Types.JOBS_SEARCH_SUCCESS]: success,
  [Types.JOBS_SEARCH_FAIL]: fail,

  [Types.JOBS_DELETE_REQUEST]: jobsDeleteRequest,
  [Types.JOBS_DELETE_REQUEST_SUCCESS]: jobsDeleteRequestSuccess,
  [Types.JOBS_DELETE_REQUEST_FAIL]: jobsDeleteRequestFail,
});
