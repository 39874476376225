import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ButtonSecondary, ButtonDefault, SelectFieldInput, TextField } from "components/shared/CustomInputs";
import { LabelH1 } from "components/shared/CustomComponents";
import { Col, Row } from "react-bootstrap";

import PropTypes from "prop-types";
import {
  DisclaimerQuestion
} from "./NewCandidateDialogStyle";

export default function NewCandidateDialog({
  show,
  action,
  closeDialog,
}) {
  const tipoFiltro = () => {
    return [
      { name: 'CPF', value: 'CPF' },
      { name: 'Matricula', value: 'Matricula' }];
  }

  const cpfFormatado = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  const disabledSave = () => {
    if (typeSelected == "CPF" && !formValues.cpf || !formValues.justification)
      return true;

    if (typeSelected == "Matricula" && !formValues.matricula || !formValues.justification)
      return true;

    return false;
  }

  const [typeSelected, setTypeSelected] = useState("CPF");
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues({});
  }, [show]);

  return (
    <Modal size="lg" show={show}>
      <Modal.Header>
        <LabelH1 ml="15px" fz="24px">
          <FormattedMessage id="NewCandidatesVacancy.addCandidate" />
        </LabelH1>
      </Modal.Header>
      <Modal.Body>
        <DisclaimerQuestion>
          <FormattedMessage id="NewCandidatesVacancy.title" />
        </DisclaimerQuestion>
        <Row style={{ marginTop: "20px" }}>
          <Col md={12}>
            <SelectFieldInput
              label={<FormattedMessage id={"NewCandidatesVacancy.type"}> </FormattedMessage>}
              items={tipoFiltro()}
              value={typeSelected}
              onChange={e =>
                setTypeSelected(e.target.value)
              }
            />
          </Col>

          {((typeSelected === "CPF") &&
            <Col md={12}>
              <TextField
                value={formValues.cpf}
                name={2}
                maxLength={14}
                onChange={e =>
                  setFormValues({ ...formValues, cpf: cpfFormatado(e.target.value) })
                }
                label={<FormattedMessage id="NewCandidatesVacancy.CPF" />}
              />
            </Col>
          )}

          {((typeSelected === "Matricula") &&
            <Col md={12}>
              <TextField
                value={formValues.matricula}
                name={2}
                onChange={e =>
                  setFormValues({ ...formValues, matricula: e.target.value })
                }
                label={<FormattedMessage id="NewCandidatesVacancy.matricula" />} />
            </Col>)}
          <Col>
            <TextField
              value={formValues.justification}
              name={2}
              onChange={e =>
                setFormValues({ ...formValues, justification: e.target.value })
              }
              label={<FormattedMessage id="NewCandidatesVacancy.justification" />}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonSecondary
          name={<FormattedMessage id="sharedItems.cancel" />}
          onClick={() => closeDialog()}
          width="156px"
        />
        <ButtonDefault
          name={<FormattedMessage id="NewCandidatesVacancy.add" />}
          width="250px"
          disabled={disabledSave()}
          onClick={() => action(formValues.cpf, formValues.justification, formValues.matricula)}
        />
      </Modal.Footer>
    </Modal>
  );
}
NewCandidateDialog.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func,
  closeDialog: PropTypes.func
};

NewCandidateDialog.defaultProps = {
  show: false,
  action: () => { },
  closeDialog: () => { }
};
