import {
  ActionItem,
  Actions,
  LabelAction
} from "components/shared/CustomComponentsStyle";
import {
  BodyBlock,
  DateContainer,
  DescriptionContainer,
  DescriptionSpan,
  NumberOfItem,
  TitleContainer
} from "../../StageBlocksStyle";
import { CheckBox, TextField } from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import { cancel, edit } from "../../../../../assets/icons/icons";

import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import { LabelH1 } from "components/shared/CustomComponents";
import { isDateDisabledForEditing } from "../../../../../helpers/sharedHelpers";
import { required } from "../../../../../helpers/validations";

export const TestStage = ({
  kind,
  name,
  position,
  deleteItem,
  description,
  kindTest,
  levelTest,
  evaliation,
  time,
  questionAndTime,
  isAssociating,
  setRailsAttributesToAssocation,
  railsAttributesToAssocation,
  id,
  requestShowStage,
  isEditingAssociation,
  periodeRealization,
  testPeriodeRealizationUntil,
  periodeAvaliation,
  periodeAvaliationUntil
}) => {
  const [selfAttributes, setSelfAttributes] = useState({});

  const registerValuesAndThrow = (value, name) => {
    setSelfAttributes({ ...selfAttributes, [name]: value });
    setRailsAttributesToAssocation({
      ...railsAttributesToAssocation,
      [id]: {
        ...selfAttributes,
        ...railsAttributesToAssocation[id],
        [name]: value
      }
    });
  };

  const renderActions = () => {
    return (
      <Col lg={2}>
        <Actions>
          <ActionItem onClick={() => deleteItem(id)}>
            {cancel()}
            <LabelAction>
              <FormattedMessage id="sharedItems.remove" />
            </LabelAction>
          </ActionItem>
        </Actions>
      </Col>
    );
  };

  const renderPercentAprovation = () => {
    return (
      <>
        <Col className={"align-items-md-flex-end"} lg={{ span: 2, offset: 1 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="inputs.aprovation" />
            </LabelAction>
            <InputMask
              mask="99%"
              width="60px"
              disabled={isDateDisabledForEditing(
                isEditingAssociation,
                periodeAvaliationUntil
              )}
              validations={[required]}
              defaultValue={
                railsAttributesToAssocation[id]
                  ? railsAttributesToAssocation[id].percentAprovation
                  : null
              }
              name="percentAprovation"
              onChange={e =>
                registerValuesAndThrow(e.target.value, "percentAprovation")
              }
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  disabled={isDateDisabledForEditing(
                    isEditingAssociation,
                    periodeAvaliationUntil
                  )}
                />
              )}
            </InputMask>
          </DescriptionContainer>
        </Col>
        <Col>
          <Row>
            <DescriptionContainer>
              <LabelAction ml={"unset"} ta={"left"}>
                <FormattedMessage id="inputs.showSieveApproval" />
              </LabelAction>
              <CheckBox
                onChange={() => {
                    const dafaultShowSieveApproval = railsAttributesToAssocation[id]
                      ? !railsAttributesToAssocation[id].showSieveApproval
                      : false;
                    registerValuesAndThrow(dafaultShowSieveApproval, "showSieveApproval")
                  }
                }
                checked={
                  railsAttributesToAssocation[id]
                  ? railsAttributesToAssocation[id].showSieveApproval
                  : false
                }
              />
            </DescriptionContainer>
          </Row>
          <Row>
            <DescriptionContainer>
              <LabelAction ml={"unset"} ta={"left"}>
                <FormattedMessage id="inputs.showCandidateScore" />
              </LabelAction>
              <CheckBox
                onChange={() => {
                  const dafaultShowCandidateScore = railsAttributesToAssocation[id]
                    ? !railsAttributesToAssocation[id].showCandidateScore
                    : false;
                    registerValuesAndThrow(dafaultShowCandidateScore, "showCandidateScore")
                  }
                }
                checked={
                  railsAttributesToAssocation[id]
                  ? railsAttributesToAssocation[id].showCandidateScore
                  : false
                }
              />
            </DescriptionContainer>
          </Row>
        </Col>
      </>
    );
  };

  return (
    <BodyBlock>
      <Row>
        <Col lg={9}>
          <TitleContainer>
            <NumberOfItem style={{ marginLeft: "unset" }}>
              <LabelH1>{position}</LabelH1>
            </NumberOfItem>
            <LabelH1>{name}</LabelH1>
          </TitleContainer>
        </Col>
        {renderActions()}
      </Row>
      <Row
        className={"align-items-md-flex-start"}
        style={{ marginTop: "15px" }}
      >
        <Col lg={{ span: 3, offset: 1 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.kindTest" />
            </LabelAction>
            <DescriptionSpan>{kindTest}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
        <Col lg={{ span: 2 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.avaliation" />
            </LabelAction>
            <DescriptionSpan>{evaliation}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
        <Col lg={{ span: 2 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.level" />
            </LabelAction>
            <DescriptionSpan>{levelTest}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
        <Col lg={{ span: 2 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.timeTest" />
            </LabelAction>
            <DescriptionSpan>{time}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
        <Col lg={{ span: 2 }}>
          <DescriptionContainer>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.mediumQuestionTime" />
            </LabelAction>
            <DescriptionSpan>{questionAndTime}</DescriptionSpan>
          </DescriptionContainer>
        </Col>
        <Col lg={{ span: 7, offset: 1 }}>
          <DescriptionContainer style={{ marginTop: "10px" }}>
            <LabelAction ml={"unset"} ta={"left"}>
              <FormattedMessage id="sharedItems.description" />
            </LabelAction>
            <DescriptionSpan dangerouslySetInnerHTML={{ __html: description.toString("html") }}></DescriptionSpan>
          </DescriptionContainer>
        </Col>
        {renderPercentAprovation()}
      </Row>
      {isAssociating && (
        <Row style={{ marginTop: "30px" }}>
          <Col
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexFlow: "column"
            }}
            lg={{ span: 5, offset: 1 }}
          >
            <LabelAction>
              <FormattedMessage id="inputs.periodeRealization" />
            </LabelAction>
            <DateContainer>
              <InputMask
                alwaysShowMask
                mask="99/99/9999"
                width="130px"
                disabled={isDateDisabledForEditing(
                  isEditingAssociation,
                  periodeRealization
                )}
                defaultValue={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id].periodeRealization
                    : null
                }
                onChange={e =>
                  registerValuesAndThrow(e.target.value, "periodeRealization")
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    disabled={isDateDisabledForEditing(
                      isEditingAssociation,
                      periodeRealization
                    )}
                  />
                )}
              </InputMask>
              <LabelAction
                ml="15px"
                mr="15px"
                fz="16px"
                style={{ alignSelf: "center" }}
              >
                <FormattedMessage id="sharedItems.until" />
              </LabelAction>
              <InputMask
                alwaysShowMask
                mask="99/99/9999"
                width="130px"
                disabled={isDateDisabledForEditing(
                  false,
                  testPeriodeRealizationUntil
                )}
                defaultValue={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id]
                      .testPeriodeRealizationUntil
                    : null
                }
                onChange={e =>
                  registerValuesAndThrow(
                    e.target.value,
                    "testPeriodeRealizationUntil"
                  )
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    disabled={isDateDisabledForEditing(
                      false,
                      testPeriodeRealizationUntil
                    )}
                  />
                )}
              </InputMask>
            </DateContainer>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexFlow: "column"
            }}
            lg={{ span: 4 }}
          >
            <LabelAction>
              <FormattedMessage id="inputs.periodeAvaliation" />
            </LabelAction>
            <DateContainer>
              <InputMask
                alwaysShowMask
                mask="99/99/9999"
                width="130px"
                disabled={isDateDisabledForEditing(
                  false,
                  periodeAvaliation
                )}
                defaultValue={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id].periodeAvaliation
                    : null
                }
                onChange={e =>
                  registerValuesAndThrow(e.target.value, "periodeAvaliation")
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    disabled={isDateDisabledForEditing(
                      false,
                      periodeAvaliation
                    )}
                  />
                )}
              </InputMask>
              <LabelAction
                ml="15px"
                mr="15px"
                fz="16px"
                style={{ alignSelf: "center" }}
              >
                <FormattedMessage id="sharedItems.until" />
              </LabelAction>
              <InputMask
                alwaysShowMask
                mask="99/99/9999"
                width="130px"
                disabled={isDateDisabledForEditing(
                  false,
                  periodeAvaliationUntil
                )}
                defaultValue={
                  railsAttributesToAssocation[id]
                    ? railsAttributesToAssocation[id].periodeAvaliationUntil
                    : null
                }
                onChange={e =>
                  registerValuesAndThrow(
                    e.target.value,
                    "periodeAvaliationUntil"
                  )
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    disabled={isDateDisabledForEditing(
                      false,
                      periodeAvaliationUntil
                    )}
                  />
                )}
              </InputMask>
            </DateContainer>
          </Col>
        </Row>
      )}
    </BodyBlock>
  );
};

export default TestStage;
