import React, {useState, useRef, useEffect } from 'react';

import {dashboardUrls} from '../../../../utils/contants/urlsApi';

import {MainFrameDash, NotificationArea, Header, Nav, ButtonNav} from './DashBoardTalentsMainStyle';

import DynamicDash from '../../../../components/DynamicDash';

import { contratarCandidateAction, months } from '../../../../locale/';

import Chart from 'chart.js/auto';

import { Icon } from 'react-icons-kit';
import {gear} from 'react-icons-kit/fa/gear';

import { connect } from 'react-redux';

import theme from "../../../../components/shared/Theme";


const DashBoardTalentsMain = (props) => {

    const name = window.localStorage.getItem('name').split(' ')[0];

    let colorstemp = [];
    colorstemp.length = 100;
    colorstemp.fill(0);
    colorstemp = colorstemp.map(item => {
        const color = '0123456789ABCDEF'.split('').sort(()=> Math.random() - 0.5 ).join('').substring(0, 6); 
        const rand = (frm, to) => {
          return ~~(Math.random() * (to - frm)) + frm;
      }
      return `rgba(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)}, 0.3)`;
        //return `rgba(${parseInt(color.slice(0, 2), 16)},${parseInt(color.slice(2, 4), 16)},${parseInt(color.slice(4, 6), 16)}, 0.3)`;
    });

    const defaultColors = useRef();
    defaultColors.current = defaultColors.current ? defaultColors.current : colorstemp;

    const colors = defaultColors.current;

    const [view, setView] = useState(0);
    const [dashBase, setDashBase] = useState(null);
    const [dashData, setDashData] = useState({data: null, timeupdate: null});
    const [notificationData, setNotificationData] = useState();
    const [filterList, setFilterList] = useState([]);

    const tabFilter = useRef(0);
    tabFilter.current = tabFilter.current === 0 ? 0 : tabFilter.current;

    const tabsView = useRef([
        {
            label:"Vagas", 
            data:[
                {
                    id: new Date().getTime(),
                    type: 'dashtitle',
                    title:'Total vagas', 
                    style:{
                        column:`merge-2`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color: theme.colors.defaultColor
                    },
                    data: 'Visão geral'
                },
                {
                    id: new Date().getTime(),
                    type: 'chartcard',
                    title:'Resumo', 
                    style:{
                        column:`merge-0`,
                        row: 'merge-row-1'
                    },
                    customStyle:{
                        color: theme.colors.defaultColor
                    },
                    data: ''
                },
                {
                    id: new Date().getTime(),
                    type: 'iconcard',
                    title:'Processos criados', 
                    style:{
                        column:`merge-0`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color:'var(--green)',
                        icon: 'ic_wc'
                    },
                    data: 20
                },
                {
                    id: new Date().getTime(),
                    type: 'iconcard',
                    title:'Processos publicados', 
                    style:{
                        column:`merge-0`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color:'var(--teal)',
                        icon: 'ic_fact_check_outline'
                    },
                    data: 20
                },
                {
                    id: new Date().getTime(),
                    type: 'iconcard',
                    title:'Processos em andamento', 
                    style:{
                        column:`merge-0`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color:'var(--cyan)',
                        icon:'ic_watch_later'
                    },
                    data: 20
                },
                {
                    id: new Date().getTime(),
                    type: 'iconcard',
                    title:'Processos finalizadas', 
                    style:{
                        column:`merge-0`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color:'var(--primary)',
                        icon:'ic_work_off'
                    },
                    data: 20
                },
                {
                    id: new Date().getTime(),
                    type: 'barprogresscard',
                    title:'Tipos de seleção de vagas', 
                    style:{
                        column:`merge-2`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color: theme.colors.defaultColor
                    },
                    data: [
                        {color:'rgba(40, 167, 69, 0.5)', label:'Interna', value:10},
                        {color:'rgba(255, 193, 7, 0.5)', label:'Externa', value:8}
                    ]
                },
                {
                    id: new Date().getTime(),
                    type: 'dashtitle',
                    title:'Visão Geral', 
                    style:{
                        column:`merge-2`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color: theme.colors.defaultColor
                    },
                    data: 'Visão Geral'
                },
                {
                    id: new Date().getTime(),
                    type: 'chartcard',
                    title:'Visão cidades', 
                    style:{
                        column:`merge-2`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color: theme.colors.defaultColor,
                        height: 'auto'
                    },
                    data: ''
                },
                {
                  id: new Date().getTime(),
                  type: 'chartcard',
                  title:'Visão sites', 
                  style:{
                      column:`merge-2`,
                      row: 'merge-row-0'
                  },
                  customStyle:{
                      color: theme.colors.defaultColor,
                      height: 'auto'
                  },
                  data: ''
              },
                {
                    id: new Date().getTime(),
                    type: 'dashtitle',
                    title:'Cargos frequêntes - Top 10', 
                    style:{
                        column:`merge-2`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color: theme.colors.defaultColor
                    },
                    data: 'Cargos frequêntes - Top 10'
                },
                {
                    id: new Date().getTime(),
                    type: 'listcard',
                    title:'Cargo/ Quantidade',
                    style:{
                        column:`merge-1`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color: theme.colors.defaultColor
                    },
                    data: ''
                },
                {
                    id: new Date().getTime(),
                    type: 'chartcard',
                    title:'Resumo', 
                    style:{
                        column:`merge-0`,
                        row: 'merge-row-0'
                    },
                    customStyle:{
                        color:theme.colors.defaultColor,
                        height: 'auto'
                    },
                    data: ''
                },
            ]
        },
        {label:"Candidatos", data:[
            {
                id: new Date().getTime(),
                type: 'dashtitle',
                title:'Candidatos vagas', 
                style:{
                    column:`merge-2`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: 'Status candidatos vagas'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'Publicada', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:'var(--green)',
                    icon: 'ic_person_add'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'Em andamento', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:'var(--yellow)',
                    icon: 'ic_person'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'Finalizada', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:'var(--orange)',
                    icon: 'ic_person_remove'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'Não avaliados', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:'var(--purple)',
                    icon: 'ic_alarm'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'Desistentes', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor,
                    icon: 'ic_insert_drive_file_outline'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'Banco a expirar em 30 dias', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:'var(--red)',
                    icon: 'ic_alarm_off'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:'var(--primary)',
                    icon: 'ic_group'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'listcard',
                title:'Candidatos em branco por site',
                style:{
                    column:`merge-2`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: []
            },
            {
                id: new Date().getTime(),
                type: 'listcard',
                title:'Vagas com mais candidatos',
                style:{
                    column:`merge-2`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: []
            }
        ]},
        {label:"Banco de talentos", data:[
            {
                id: new Date().getTime(),
                type: 'dashtitle',
                title:'Candidatos em banco de talentos', 
                style:{
                    column:`merge-2`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: 'Candidatos em banco de talentos'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'Gerais', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:'var(--green)',
                    icon: 'ic_wc'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'iconcard',
                title:'Vencem nos próximos 30 dias', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:'var(--yellow)',
                    icon: 'ic_alarm'
                },
                data: '-'
            },
            {
                id: new Date().getTime(),
                type: 'dashtitle',
                title:'Cargos frequêntes - Top 10', 
                style:{
                    column:`merge-2`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: 'Cargos frequêntes - Top 10'
            },
            {
                id: new Date().getTime(),
                type: 'listcard',
                title:'Cargo/ Quantidade',
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: ''
            },
            {
                id: new Date().getTime(),
                type: 'chartcard',
                title:'Resumo', 
                style:{
                    column:`merge-1`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor,
                    height: 'auto'
                },
                data: ''
            },
            {
                id: new Date().getTime(),
                type: 'listcard',
                title:'Incluídos por mês',
                style:{
                    column:`merge-1`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: ''
            },
            {
                id: new Date().getTime(),
                type: 'chartcard',
                title:'Resumo', 
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor,
                    height: 'auto'
                },
                data: ''
            },
            {
                id: new Date().getTime(),
                type: 'listcard',
                title:'Removidos do banco por mês',
                style:{
                    column:`merge-0`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: ''
            },
            {
                id: new Date().getTime(),
                type: 'chartcard',
                title:'Resumo', 
                style:{
                    column:`merge-1`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor,
                    height: 'auto'
                },
                data: ''
            },
            {
                id: new Date().getTime(),
                type: 'listcard',
                title:'Candidatos em banco de talentos por site',
                style:{
                    column:`merge-2`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: ''
            },
            {
                id: new Date().getTime(),
                type: 'listcard',
                title:'Candidatos removidos por motivo',
                style:{
                    column:`merge-2`,
                    row: 'merge-row-0'
                },
                customStyle:{
                    color:theme.colors.defaultColor
                },
                data: ''
            },
        ]}
    ])

    const getNotificaton = (_data) => {

        let notification = null;

        if(_data)
         notification = <div className={`${_data.icon}`}>
             <div className="icon"></div>
                <div className="content">{_data.content}</div>
                <div className="action">
                    {
                        _data.action ? 
                            <div className="button-notification">
                                {_data.action.name}
                            </div>
                        :
                        ''
                    }
                </div>
            </div>;


        if(notification){
            setNotificationData(notification);
        }

    }

    const alterView = (_data) => {
        setView(_data);
    }

    const getDataDash = (_json) => {
            
            if(!_json){
                setDashData({data: [], base:_json, date: (_json?.dateTimeExpiration ? _json.dateTimeExpiration : null), lastupdate: (_json?.tempoRestanteCacheMinutos ? ~~_json?.tempoRestanteCacheMinutos: null)});
                return [];
            }

            const json = (filterList?.length > 0 ? _json[filterList[tabFilter.current]?.value] : _json);  

            let result = tabsView.current[~~view].data;

            if(!result){
                return [];
            }

            switch(view){
                case 0:
                    result[1].data = {
                        type: 'bar',
                        data: {
                            labels:['Geral', 'Publicadas', 'Em Andamento', 'Finalizadas'],
                            datasets: [{
                                label: 'Geral',
                                data: [json.totalVagasList[0].geral, json.totalVagasList[0].publicadas, json.totalVagasList[0].emAndamento, json.totalVagasList[0].finalizadas],
                                backgroundColor: ['#28a745','#20c997','#17a2b8','#007bff'],
                                borderColor: ['#28a745','#20c997','#17a2b8','#007bff'],
                                borderWidth: 3
                            }
                        ]
                        },
                        options: {
                            plugins:{
                              datalabels: {
                                color: '#000',
                                anchor: 'end',
                                offset: -20,
                                align:'bottom'
                              },
                                legend:{
                                    display: false
                                }
                            }
                        }
                    };
                    result[2].data = json.totalVagasList[0].geral.toString();
                    result[3].data = json.totalVagasList[0].publicadas.toString();
                    result[4].data = json.totalVagasList[0].emAndamento.toString();
                    result[5].data = json.totalVagasList[0].finalizadas.toString();
                    result[6].data[0].value = json.tiposSelecao.vagasInternas.toString();
                    result[6].data[1].value = json.tiposSelecao.vagasExterna.toString();

                    const sites = [...new Set(json.vagasPorSite.map(item => item.cidade))];
                    const sites_data = sites.map(item =>{
                        return json.vagasPorSite.reduce((c, n)=>{
                            if(item === n.cidade)
                                return c + ~~n.totalRegistros;
                            
                            return c;
                        },0)
                    })

                    const sites_data_total = sites.map((item, id) =>{
                      return json.vagasPorSite.filter((c)=>{
                            return item === c.cidade;
                      }).map(item => `${item.site} = ${item.totalRegistros}`).join(' - ') + ` Total = ${sites_data[id]}`;
                  })

                  //console.log(sites_data_total);


              // Reduzir altura

              result[8].customStyle.maxHeight = `${sites.length * 42}px`;
              result[8].customStyle.minHeight = `100px`;

              result[8].data = {
                        type: 'bar',
                        data: {
                            labels: sites,
                            datasets: [{
                              label: 'Total',
                              data: sites_data,
                              backgroundColor: colors,
                              borderColor: colors,
                              borderWidth: 3,
                              
                            }]
                        },
                        options: {
                            indexAxis: 'y',
                            aspectRatio: true,
                            responsive: true,
                            scales:{
                              y: {
                                beginAtZero: true,
                                },
                                x: {
                                    beginAtZero: true,
                                }
                            },
                            plugins:{
                              datalabels: {
                                color: '#000',
                                anchor: 'end',
                                offset: -20,
                                align:'left'
                              },  
                              tooltip:{
                                callbacks:{ 
                                   footer: (e) => {
                                     return json.vagasPorSite.filter((c)=>{
                                      return e[0].label === c.cidade;
                                    }).map(item => `${item.site} = ${item.totalRegistros}`).join('\n');
                                  }
                                }
                              },
                              legend:{
                                  display: false
                              }
                            }
                        }
                    };

                    
                    const sites_nome = [...new Set(json.vagasPorSite.map(item => item.site))];
                    const cidades_nome = [...new Set(json.vagasPorSite.map(item => item.cidade))];
                    const cidades_cores = cidades_nome.map((item, id) => colors[id]);
                    const sites_data_sites = sites_nome.map((item, id) =>{
                        return json.vagasPorSite.reduce((c, n)=>{
                          if(item === n.site){
                            return c + ~~n.totalRegistros;
                          }
                          
                          return c;
                        },0)
                      })
                    
                      const color_temp = [];

                      sites_nome.map((item, id) =>{
                        let corId = null;
                        json.vagasPorSite.map(it =>{
                          if( item === it.site){
                            cidades_nome.map((c, i)=> {
                              if(c === it.cidade)
                                corId = i;
                              return c;
                            })
                          }
                        });
                        color_temp.push(cidades_cores[corId]);
                        return item;
                      });
                      

                    result[9].customStyle.maxHeight = `${sites_nome.length * 42}px`;
                    result[9].customStyle.minHeight = `100px`;

                    result[9].data = {
                      type: 'bar',
                      data: {
                          labels: sites_nome,
                          datasets: [{
                            label: 'Total',
                            data: sites_data_sites, 
                            backgroundColor: color_temp,
                            borderColor: color_temp,
                            borderWidth: 3,
                            
                          }]
                      },
                      options: {
                          indexAxis: 'y',
                          aspectRatio: true,
                          responsive: true,
                          scales:{
                            y: {
                              beginAtZero: true,
                              },
                              x: {
                                  beginAtZero: true,
                              }
                          },
                          plugins:{ 
                            datalabels: {
                              color: '#000',
                                anchor: 'end',
                                offset: -20,
                                align:'left'
                            },
                            tooltip:{
                              callbacks:{ 
                                 footer: (e) => {
                                   return json.vagasPorSite.filter((c)=>{
                                    return e[0].label === c.cidade;
                                  }).map(item => `${item.site} = ${item.totalRegistros}`).join('\n');
                                }
                              }
                            },
                            legend:{
                                display: false
                            }
                          }
                      }
                  };
                    
                    const pie_labels = [...new Set(json.cargosMaisFrequentes.map(item => item.area))];
                    const pie_data = pie_labels.map(item => {
                        return json.cargosMaisFrequentes.reduce((c, n) => { 
                            if(n.area === item)
                            return c + n.totalRegistros
                            
                            return c;
                        }, 0)
                    })

                    result[11].data = json.cargosMaisFrequentes.map((item, id) => `<div style="display:grid; grid-template-columns: 1fr 50px 20px; align-items:center;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div><div style="background-color: ${item.area === pie_labels[0] ? colors[0] : colors[1]}; height:10px; width:10px;margin: 0 auto;"></div></div>`);
                    
                    result[12].data = {
                        type: 'pie',
                        data: {
                            labels: pie_labels,
                            datasets: [{
                                label: pie_labels,
                                data: pie_data,
                                backgroundColor: json.cargosMaisFrequentes.map((item, id) => colors[id]),
                                borderColor: json.cargosMaisFrequentes.map((item, id) => colors[id]),
                                borderWidth: 3
                            }
                        ]
                        },
                        options: {
                            plugins:{
                                legend:{
                                    display: false
                                }
                            }
                        }
                    };

                    // sites.map((item, id)=> {
                    //   const _val = {
                    //       id: new Date().getTime(),
                    //       type: 'barprogresscard',
                    //       title: item, 
                    //       style:{
                    //           column:`merge-2`,
                    //           row: 'merge-row-0'
                    //       },
                    //       customStyle:{
                    //           color:'#17B3D2'
                    //       },
                    //       data: (t[id]?.label || [])?.map((d, i) => {
                    //         return ({color:colors[i], label:d, value:t[id].data[i]})
                              
                    //       })
                    //   }
                    //   _val.data = [];
                    //   result.splice(8 + id, 0, _val);
                    //   return item;
                    // })

                    // console.dir(result);

                    // result[11].data = json.cargosMaisFrequentes_trimestre.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`);
                    // result[12].data = json.cargosMaisFrequentes_semestre.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`);
                    // result[13].data = json.cargosMaisFrequentes_ano.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`);
                break;
                case 1:
                    result[1].data = (json.vagasStatusAbertaFechada?.filter(item => /publicada/gi.test(item.nome))[0]?.totalRegistros || 0).toString();
                    result[1].title = 'Candidatos em vagas Publicadas';
                    result[2].data = (json.vagasStatusAbertaFechada?.filter(item => /andamento/gi.test(item.nome))[0]?.totalRegistros || 0).toString();
                    result[2].title = 'Candidatos em vagas Em Andamento';
                    result[3].data = json?.totalCandidatosEmVagasFinalizadas[0]?.totalRegistros?.toString() || result[0].data;
                    result[3].title = json?.totalCandidatosEmVagasFinalizadas[0]?.nome?.toString();
                    result[4].data =  json?.totalCandidatosNaoAvaliados[0]?.totalRegistros?.toString() || result[0].data;
                    result[4].title = 'Candidatos em vagas Finalizadas não Avaliados';
                    result[5].data = json?.totalCandidatosEmBanco[0]?.totalRegistros?.toString();
                    result[5].title = 'Candidatos Desistentes';
                    result[6].data = json?.totalCandidatosPrestesPerder[0]?.totalRegistros?.toString() || result[0].data;
                    result[7].data = json?.totalCandidatosInscritosNaPlataforma[0]?.totalRegistros?.toString() || result[0].data;
                    result[7].title = json?.totalCandidatosInscritosNaPlataforma[0]?.nome?.toString();
                    // result[8].data = json?.totalCandidatosEmVagasFinalizadas[0]?.totalRegistros?.toString() || result[0].data;
                    // result[8].title = json?.totalCandidatosEmVagasFinalizadas[0]?.nome?.toString();
                    

                    let candidatosSites = [...new Set(json.vagasComCandidatosEmBancoPorCidadeSite.map(item => item.cidade))];
                    candidatosSites = candidatosSites.map(item =>({
                      cidade: item,
                      total:json.vagasComCandidatosEmBancoPorCidadeSite.reduce((c, n)=>{
                              if(item === n.cidade)
                                  return c + ~~n.totalRegistros;
                              
                              return c;
                          },0),
                      sites:json.vagasComCandidatosEmBancoPorCidadeSite.filter((c)=>{
                        return item === c.cidade
                        }).map(el => `<li>${el.site} = ${el.totalRegistros}</li>`)
                    }));

                    //console.log(candidatosSites);

                    result[8].data = json.vagasComCandidatosEmBancoPorCidadeSite?.length > 0 ? candidatosSites.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div><details><summary>${item.cidade}</summary><ul>${item.sites.join('')}</ul></details></div> <div style="text-align:center;">   ${item.total}</div></div>`) : ['<div>Não há informações cadastradas</div>'];
                    result[8].title = "Candidatos por Cidade/ Site";
                    result[9].data = json.vagasComMaisCandidatos?.length > 0 ? json.vagasComMaisCandidatos.map(item => `<div style="display:grid; grid-template-columns:  1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`) : ['<div>Não há informações cadastradas</div>'];
                    
                break;
                case 2:
                    result[1].data = json?.totalCandidatosEmBancoTalentosList[0]?.totalRegistros?.toString() || result[0].data;
                    result[2].data = json?.totalCandidatosComBancoVencerProx30Dias[0]?.totalRegistros?.toString() || result[1].data;
                    result[3].data = ' ';
                    result[4].data = json?.totalCandidatoIncluidosEmBancoDeTalentosPorMes?.length > 0 ?   json.totalCandidatoIncluidosEmBancoDeTalentosPorMes.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`) : ['<div>Não há informações cadastradas</div>'];
                    result[4].title = 'Candidatos incluídos';
                    result[5].data = {
                        type: 'line',
                        data: {
                            labels: json.totalCandidatoIncluidosEmBancoDeTalentosPorMes.map(item => item.nome),
                            datasets: [{
                                data: json.totalCandidatoIncluidosEmBancoDeTalentosPorMes.map(item => ~~item.totalRegistros),
                                backgroundColor: json.totalCandidatoIncluidosEmBancoDeTalentosPorMes.map((item, id) => colors[id]),
                                borderColor: json.totalCandidatoIncluidosEmBancoDeTalentosPorMes.map((item, id) => colors[id]),
                                borderWidth: 3
                            }
                        ]
                        },
                        options: {
                            scales: {
                                y: {
                                    beginAtZero: true
                                }
                            },
                            plugins:{
                              datalabels:{
                                color: '#000',
                                anchor: 'end',
                                offset: -20,
                                align:'bottom'
                              },
                              legend:{
                                  display: false
                              }
                            }

                        }
                    };

                    const donuts_labels = [...new Set(json.candidatosEmBancoTalentosPorCargo.map(item => item.area))];
                    const donuts_data = donuts_labels.map(item => {
                        return json.candidatosEmBancoTalentosPorCargo.reduce((c, n) => { 
                            if(n.area === item)
                            return c + n.totalRegistros
                            
                            return c;
                        }, 0)
                    })
                    
                    result[6].data = json.candidatosEmBancoTalentosPorCargo.map((item, id) => `<div style="display:grid; grid-template-columns: 1fr 50px 20px; align-items:center;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div><div style="background-color: ${item.area === donuts_labels[0] ? colors[0] : colors[1]}; height:10px; width:10px;margin: 0 auto;"></div></div>`);
                    result[6].title = 'Candidatos em banco de talentos por cargo';
                    

                    result[7].data = {
                        type: 'doughnut',
                        data: {
                            labels: json.candidatosEmBancoTalentosPorCargo.map(item => item.nome),
                            datasets: [{
                                data: donuts_data,
                                backgroundColor: json.candidatosEmBancoTalentosPorCargo.map((item, id) => colors[id]),
                                borderColor: json.candidatosEmBancoTalentosPorCargo.map((item, id) => colors[id]),
                                borderWidth: 3
                            }
                        ]
                        },
                        options: {
                            plugins:{
                                legend:{
                                    display: false
                                }
                            }
                        }
                    };
                    result[8].data = json?.candidatosRemovidosBancoTalentosPorMes?.length > 0 ?   json.candidatosRemovidosBancoTalentosPorMes.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`) : ['<div>Não há informações cadastradas</div>'];
                    result[9].data = {
                        type: 'line',
                        data: {
                            labels: json.candidatosRemovidosBancoTalentosPorMes.map(item => item.nome),
                            datasets: [{
                                data: json.candidatosRemovidosBancoTalentosPorMes.map(item => ~~item.totalRegistros),
                                backgroundColor: json.candidatosRemovidosBancoTalentosPorMes.map((item, id) => colors[id]),
                                borderColor: json.candidatosRemovidosBancoTalentosPorMes.map((item, id) => colors[id]),
                                borderWidth: 3
                            }
                        ]
                        },
                        options: {
                            scales: {
                              y: {
                                    beginAtZero: true
                                },
                              x: {
                                  beginAtZero: true
                              },
                            },
                            plugins:{
                              datalabels:{
                                color: '#000',
                                anchor: 'center',
                                offset: -25,
                                align: 'bottom',
                                clamp: true
                              },
                                legend:{
                                    display: false
                                }
                            }

                        }
                    };
                    //result[10].data = json?.candidatosEmBancoTalentosPorCargo?.length > 0 ?   json.candidatosEmBancoTalentosPorCargo.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`) : ['<div>Não há informações cadastradas</div>'];
                    result[10].data = json?.candidatosEmBancoTalentosPorSite?.length > 0 ?   json.candidatosEmBancoTalentosPorSite.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`) : ['<div>Não há informações cadastradas</div>'];
                    result[11].data = json?.candidatosRemovidosBancoTalentosPorMotivo?.length > 0 ?   json.candidatosRemovidosBancoTalentosPorMotivo.map(item => `<div style="display:grid; grid-template-columns: 1fr 50px;"><div>${item.nome}</div> <div style="text-align:center;">   ${item.totalRegistros}</div></div>`) : ['<div>Não há informações cadastradas</div>'];
                break;
                default:
                    result = [];

            }

            setDashData({data: result, base:_json, date: (_json?.dateTimeExpiration ? _json.dateTimeExpiration : null), lastupdate: (_json?.tempoRestanteCacheMinutos ? ~~_json?.tempoRestanteCacheMinutos: null)});
    }

    const dataDashGetData = async () => {
        const url = [
                dashboardUrls.vacancy,
                dashboardUrls.candidates,
                dashboardUrls.talents
            ][~~view];

            const headers = new Headers();
            headers.append('Authorization',`Basic ${window.localStorage.getItem('Authorization')}`);
    
            const params = {
                method:'GET',
                headers: headers
            };
    
            let _data;
            let json; 
            try{
                //throw true;
                
                _data = await fetch(url, params);
                json = await _data.json();
            }catch(e){
                json = null;

                if(~~view === 0){
                    json = {
                        "mesCorrente": {
                          "tipoPeriodo": "Mês Atual",
                          "totalVagasList": [
                            {
                              "geral": 0,
                              "publicadas": 0,
                              "emAndamento": 0,
                              "finalizadas": 0
                            }
                          ],
                          "tiposSelecao": {
                            "vagasInternas": 0,
                            "vagasExterna": 0,
                            "mes": 0,
                            "ano": 0
                          },
                          "cargosMaisFrequentes": [],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 1,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "12353 - DD .",
                              "area": "ADMINISTRATIVO",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasPorSite": [],
                          "vagasPorArea": [],
                          "totalVagasEmProcessosSeletivos": 0
                        },
                        "mesAnterior": {
                          "tipoPeriodo": "Mês Anterior",
                          "totalVagasList": [
                            {
                              "geral": 0,
                              "publicadas": 0,
                              "emAndamento": 0,
                              "finalizadas": 0
                            }
                          ],
                          "tiposSelecao": {
                            "vagasInternas": 1,
                            "vagasExterna": 0,
                            "mes": 12,
                            "ano": 2021
                          },
                          "cargosMaisFrequentes": [
                            {
                              "totalRegistros": 1,
                              "nome": "ANALISTA ADM DE PESSOAL PLENO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 2,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2,
                              "nome": "127 - Atendente de Telemarketing",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2,
                              "nome": "12353 - DD .",
                              "area": "ADMINISTRATIVO",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2,
                              "nome": "161 - Atendente de telemarketing RJ",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "219 - Atendente de telemarketing MSS",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "439 - Atendente de Telemarketing Arapiraca",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "218 - Atendente de telemarketing CG",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "115 - Atendente de telemarketing MOC",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasPorSite": [
                            {
                              "totalRegistros": 1,
                              "cidade": "Belo Horizonte",
                              "site": "Espírito Santo III - BH - MG",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "vagasPorArea": [
                            {
                              "totalRegistros": 1,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "totalVagasEmProcessosSeletivos": 1
                        },
                        "trimestre": {
                          "tipoPeriodo": "Trimestre",
                          "totalVagasList": [
                            {
                              "geral": 192,
                              "publicadas": 66,
                              "emAndamento": 48,
                              "finalizadas": 78
                            }
                          ],
                          "tiposSelecao": {
                            "vagasInternas": 56,
                            "vagasExterna": 136,
                            "mes": 12,
                            "ano": 2021
                          },
                          "cargosMaisFrequentes": [
                            {
                              "totalRegistros": 31,
                              "nome": "ATENDENTE",
                              "area": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 11,
                              "nome": "SUPERVISOR DE OPERACAO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 10,
                              "nome": "AUXILIAR DE PLANEJAMENTO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 10,
                              "nome": "ESTAGIARIO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 9,
                              "nome": "INSTRUTOR DE TREINAMENTO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 7,
                              "nome": "ATENDENTE I",
                              "area": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 6,
                              "nome": "MONITOR DE QUALIDADE",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 5,
                              "nome": "AGENTE DE QUALIDADE",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 3,
                              "nome": "COORDENADOR DE OPERACAO PLENO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 3,
                              "nome": "AUXILIAR DE SERVICOS GERAIS",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 3576,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 3340,
                              "nome": "197 - Atendente de Telemarketing SP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2542,
                              "nome": "127 - Atendente de Telemarketing",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1294,
                              "nome": "218 - Atendente de telemarketing CG",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1136,
                              "nome": "220 - Atendente de telemarketing JP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 691,
                              "nome": `2206 - ATENDENTE ${theme.words.nameMark} SÃO PAULO`,
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 667,
                              "nome": "161 - Atendente de telemarketing RJ",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 584,
                              "nome": "439 - Atendente de Telemarketing Arapiraca",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 570,
                              "nome": "219 - Atendente de telemarketing MSS",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 549,
                              "nome": "115 - Atendente de telemarketing MOC",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasPorSite": [
                            {
                              "totalRegistros": 76,
                              "cidade": "Belo Horizonte",
                              "site": "Estação - BH - MG",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 53,
                              "cidade": "São Paulo",
                              "site": "Edifício Paramount - SP",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 12,
                              "cidade": "João Pessoa",
                              "site": "João Pessoa - PB",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 10,
                              "cidade": "Campina Grande",
                              "site": "Campina Grande - Paraíba",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 9,
                              "cidade": "Rio de Janeiro",
                              "site": "Rio de Janeiro",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 9,
                              "cidade": "Juazeiro do Norte",
                              "site": "Ceará - Juazeiro do Norte",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 8,
                              "cidade": "Governador Valadares",
                              "site": "GV1 - MG",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 7,
                              "cidade": "Montes Claros",
                              "site": "MOC3 - Montes Claros",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 7,
                              "cidade": "Mossoró",
                              "site": "Mossoró",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 1,
                              "cidade": "Arapiraca",
                              "site": "Arapiraca",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "vagasPorArea": [
                            {
                              "totalRegistros": 36,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 36,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 19,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 17,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 12,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 10,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 10,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 9,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 9,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 8,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 7,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 4,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 4,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 4,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 3,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 2,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "totalVagasEmProcessosSeletivos": 2771
                        },
                        "semestre": {
                          "tipoPeriodo": "Semestre",
                          "totalVagasList": [
                            {
                              "geral": 957,
                              "publicadas": 87,
                              "emAndamento": 60,
                              "finalizadas": 810
                            }
                          ],
                          "tiposSelecao": {
                            "vagasInternas": 280,
                            "vagasExterna": 677,
                            "mes": 12,
                            "ano": 2021
                          },
                          "cargosMaisFrequentes": [
                            {
                              "totalRegistros": 128,
                              "nome": "ATENDENTE",
                              "area": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 57,
                              "nome": "SUPERVISOR DE OPERACAO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 47,
                              "nome": "ATENDENTE I",
                              "area": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 39,
                              "nome": "ESTAGIARIO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 37,
                              "nome": "INSTRUTOR DE TREINAMENTO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 36,
                              "nome": "AUXILIAR DE PLANEJAMENTO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 31,
                              "nome": "APRENDIZ DE SERVICOS ADMINISTRATIVOS",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 26,
                              "nome": "ANALISTA DE PLANEJAMENTO PLENO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 25,
                              "nome": "MONITOR DE QUALIDADE",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 20,
                              "nome": "TECNICO DE SUPORTE JUNIOR",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 42318,
                              "nome": "197 - Atendente de Telemarketing SP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 11901,
                              "nome": "127 - Atendente de Telemarketing",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 11522,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 10931,
                              "nome": "161 - Atendente de telemarketing RJ",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 10048,
                              "nome": "220 - Atendente de telemarketing JP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 9286,
                              "nome": "218 - Atendente de telemarketing CG",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 5276,
                              "nome": "115 - Atendente de telemarketing MOC",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 3078,
                              "nome": "219 - Atendente de telemarketing MSS",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2243,
                              "nome": "439 - Atendente de Telemarketing Arapiraca",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1551,
                              "nome": "442 - Atendente de Telemarketing GV",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasPorSite": [
                            {
                              "totalRegistros": 333,
                              "cidade": "São Paulo",
                              "site": "Edifício Paramount - SP",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 313,
                              "cidade": "Belo Horizonte",
                              "site": "Espírito Santo I - BH - MG",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 86,
                              "cidade": "Campina Grande",
                              "site": "Campina Grande - Paraíba",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 53,
                              "cidade": "João Pessoa",
                              "site": "João Pessoa - PB",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 37,
                              "cidade": "Montes Claros",
                              "site": "MOC1 e 2 - Montes Claros",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 37,
                              "cidade": "Rio de Janeiro",
                              "site": "Rio de Janeiro",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 36,
                              "cidade": "Juazeiro do Norte",
                              "site": "Ceará - Juazeiro do Norte",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 25,
                              "cidade": "Mossoró",
                              "site": "Mossoró",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 23,
                              "cidade": "Governador Valadares",
                              "site": "GV1 - MG",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 14,
                              "cidade": "Arapiraca",
                              "site": "Arapiraca",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "vagasPorArea": [
                            {
                              "totalRegistros": 236,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 157,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 86,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 68,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 68,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 53,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 49,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 37,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 36,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 28,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 25,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 23,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 22,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 20,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 15,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 14,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 8,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 7,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 4,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "totalVagasEmProcessosSeletivos": 7139
                        },
                        "ano": {
                          "tipoPeriodo": "Ano",
                          "totalVagasList": [
                            {
                              "geral": 2049,
                              "publicadas": 103,
                              "emAndamento": 67,
                              "finalizadas": 1879
                            }
                          ],
                          "tiposSelecao": {
                            "vagasInternas": 616,
                            "vagasExterna": 1433,
                            "mes": 12,
                            "ano": 2021
                          },
                          "cargosMaisFrequentes": [
                            {
                              "totalRegistros": 422,
                              "nome": "ATENDENTE",
                              "area": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 129,
                              "nome": "SUPERVISOR DE OPERACAO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 111,
                              "nome": "ATENDENTE I",
                              "area": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 68,
                              "nome": "INSTRUTOR DE TREINAMENTO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 61,
                              "nome": "ESTAGIARIO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 57,
                              "nome": "AUXILIAR DE PLANEJAMENTO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 57,
                              "nome": "MONITOR DE QUALIDADE",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 47,
                              "nome": "APRENDIZ DE SERVICOS ADMINISTRATIVOS",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 40,
                              "nome": "ANALISTA DE PLANEJAMENTO PLENO",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 37,
                              "nome": "TECNICO DE SUPORTE JUNIOR",
                              "area": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 65559,
                              "nome": "197 - Atendente de Telemarketing SP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 29406,
                              "nome": "127 - Atendente de Telemarketing",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 19835,
                              "nome": "218 - Atendente de telemarketing CG",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 18198,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 17862,
                              "nome": "161 - Atendente de telemarketing RJ",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 17731,
                              "nome": "220 - Atendente de telemarketing JP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 8737,
                              "nome": "219 - Atendente de telemarketing MSS",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 8722,
                              "nome": "439 - Atendente de Telemarketing Arapiraca",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 7955,
                              "nome": "115 - Atendente de telemarketing MOC",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 3683,
                              "nome": "442 - Atendente de Telemarketing GV",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasPorSite": [
                            {
                              "totalRegistros": 695,
                              "cidade": "Belo Horizonte",
                              "site": "Espírito Santo I - BH - MG",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 1000,
                              "cidade": "Belo Horizonte",
                              "site": "Espírito Santo II - BH - MG",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 575,
                              "cidade": "São Paulo",
                              "site": "Edifício Paramount - SP",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 238,
                              "cidade": "Campina Grande",
                              "site": "Campina Grande - Paraíba",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 127,
                              "cidade": "João Pessoa",
                              "site": "João Pessoa - PB",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 94,
                              "cidade": "Juazeiro do Norte",
                              "site": "Ceará - Juazeiro do Norte",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 80,
                              "cidade": "Rio de Janeiro",
                              "site": "Rio de Janeiro",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 79,
                              "cidade": "Montes Claros",
                              "site": "MOC3 - Montes Claros",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 66,
                              "cidade": "Governador Valadares",
                              "site": "GV1 - MG",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 48,
                              "cidade": "Arapiraca",
                              "site": "Arapiraca",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 47,
                              "cidade": "Mossoró",
                              "site": "Mossoró",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "vagasPorArea": [
                            {
                              "totalRegistros": 408,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 373,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 238,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 164,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 127,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 123,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 94,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 88,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 80,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 66,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 48,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 47,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 47,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 35,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 32,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 28,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 22,
                              "nome": "ATENDENTE",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 18,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 7,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            },
                            {
                              "totalRegistros": 4,
                              "nome": "ADMINISTRATIVO",
                              "mes": 0,
                              "ano": 0
                            }
                          ],
                          "totalVagasEmProcessosSeletivos": 22233
                        },
                        "candidatosNaoAvaliadosEmVagasFechadas": [
                          {
                            "totalRegistros": 1401,
                            "nome": "984 - ATENDENTE TELEMARKETING - ARAPIRACA CLARO BRASIL",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 1256,
                            "nome": "787 - ATENDENTE DE TELEMARKETING MSS",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 1246,
                            "nome": "1008 - ATENDENTE TELEMARKETING - JUAZEIRO DO NORTE",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 1017,
                            "nome": "1445 - AGENTE ESPECIALISTA - BANCO ORIGINAL",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 972,
                            "nome": "1399 - PAGBANK - SP",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 907,
                            "nome": "1258 - ATENDENTE TELEMARKETING - SKILLS JUNHO",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 890,
                            "nome": "1959 - AGENTE ESPECIALISTA - SEGMENTO BANCÁRIO",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 709,
                            "nome": "782 - ATENDENTE C6 CHAT PADRÃO CG ",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 676,
                            "nome": "899 - ATENDENTE",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 669,
                            "nome": "570 - ATENDENTE - VIVO TELEVENDAS",
                            "mes": 12,
                            "ano": 2021
                          }
                        ],
                        "tempoRestanteCacheMinutos": 1439.9999893833333,
                        "dateTimeExpiration": "2021-12-11T13:36:53.8194488-03:00"
                      }
                }

                if(~~view === 1){
                    json = {
                        "mesCorrente": {
                          "tipoPeriodo": "Mês Atual",
                          "vagasStatusAbertaFechada": [],
                          "candidatosNaoAvaliados": [],
                          "vagasComCandidatosEmBancoPorCidadeSite": [],
                          "totalCandidatosEmVagasFinalizadas": [
                            {
                                "totalRegistros": 0,
                                "nome": "Candidatos em vagas Finalizadas",
                                "mes": 0,
                                "ano": 0
                            }
                        ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 1,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "12353 - DD .",
                              "area": "ADMINISTRATIVO",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmBanco": [
                            {
                              "totalRegistros": 0,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosPrestesPerder": [
                            {
                              "totalRegistros": 2,
                              "nome": "Banco a expirar em 30 dias",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosInscritosNaPlataforma": [
                            {
                              "totalRegistros": 2,
                              "nome": "Total Candidatos Inscritos na Plataforma",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosNaoAvaliados": [
                            {
                              "totalRegistros": 0,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ]
                        },
                        "mesAnterior": {
                          "tipoPeriodo": "Mês Anterior",
                          "vagasStatusAbertaFechada": [
                            {
                              "totalRegistros": 2,
                              "nome": "Publicada",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmVagasFinalizadas": [
                            {
                                "totalRegistros": 0,
                                "nome": "Candidatos em vagas Finalizadas",
                                "mes": 0,
                                "ano": 0
                            }
                        ],
                          "candidatosNaoAvaliados": [],
                          "vagasComCandidatosEmBancoPorCidadeSite": [
                            {
                              "totalRegistros": 1,
                              "cidade": "Arapiraca",
                              "site": "17 - Arapiraca",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 2,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2,
                              "nome": "127 - Atendente de Telemarketing",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2,
                              "nome": "12353 - DD .",
                              "area": "ADMINISTRATIVO",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2,
                              "nome": "161 - Atendente de telemarketing RJ",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "219 - Atendente de telemarketing MSS",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "439 - Atendente de Telemarketing Arapiraca",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "218 - Atendente de telemarketing CG",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1,
                              "nome": "115 - Atendente de telemarketing MOC",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmBanco": [
                            {
                              "totalRegistros": 1,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosPrestesPerder": [
                            {
                              "totalRegistros": 2,
                              "nome": "Banco a expirar em 30 dias",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosInscritosNaPlataforma": [
                            {
                              "totalRegistros": 5,
                              "nome": "Total Candidatos Inscritos na Plataforma",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosNaoAvaliados": [
                            {
                              "totalRegistros": 0,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ]
                        },
                        "trimestre": {
                          "tipoPeriodo": "Trimestre",
                          "vagasStatusAbertaFechada": [
                            {
                              "totalRegistros": 67,
                              "nome": "Publicada",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 48,
                              "nome": "EmAndamento",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 78,
                              "nome": "Finalizada",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmVagasFinalizadas": [
                            {
                                "totalRegistros": 0,
                                "nome": "Candidatos em vagas Finalizadas",
                                "mes": 0,
                                "ano": 0
                            }
                        ],
                          "candidatosNaoAvaliados": [
                            {
                              "totalRegistros": 1401,
                              "nome": "984 - ATENDENTE TELEMARKETING - ARAPIRACA CLARO BRASIL",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1256,
                              "nome": "787 - ATENDENTE DE TELEMARKETING MSS",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1246,
                              "nome": "1008 - ATENDENTE TELEMARKETING - JUAZEIRO DO NORTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1017,
                              "nome": "1445 - AGENTE ESPECIALISTA - BANCO ORIGINAL",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 972,
                              "nome": "1399 - PAGBANK - SP",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 907,
                              "nome": "1258 - ATENDENTE TELEMARKETING - SKILLS JUNHO",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 890,
                              "nome": "1959 - AGENTE ESPECIALISTA - SEGMENTO BANCÁRIO",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 709,
                              "nome": "782 - ATENDENTE C6 CHAT PADRÃO CG ",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 676,
                              "nome": "899 - ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 669,
                              "nome": "570 - ATENDENTE - VIVO TELEVENDAS",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasComCandidatosEmBancoPorCidadeSite": [
                            {
                              "totalRegistros": 964,
                              "cidade": "São Paulo",
                              "site": "14 - Edifício Paramount - SP",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 919,
                              "cidade": "Belo Horizonte",
                              "site": "2 - Orion - BH - MG",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 646,
                              "cidade": "João Pessoa",
                              "site": "10 - João Pessoa - PB",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 541,
                              "cidade": "Campina Grande",
                              "site": "9 - Campina Grande - Paraíba",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 312,
                              "cidade": "Mossoró",
                              "site": "16 - Mossoró",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 268,
                              "cidade": "Rio de Janeiro",
                              "site": "15 - Rio de Janeiro",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 264,
                              "cidade": "Juazeiro do Norte",
                              "site": "1 - Ceará - Juazeiro do Norte",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 263,
                              "cidade": "Arapiraca",
                              "site": "17 - Arapiraca",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 245,
                              "cidade": "Montes Claros",
                              "site": "7 - MOC1 e 2 - Montes Claros",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 21,
                              "cidade": "Governador Valadares",
                              "site": "6 - GV1 - MG",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 3576,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 3340,
                              "nome": "197 - Atendente de Telemarketing SP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2542,
                              "nome": "127 - Atendente de Telemarketing",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1294,
                              "nome": "218 - Atendente de telemarketing CG",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1136,
                              "nome": "220 - Atendente de telemarketing JP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 691,
                              "nome": `2206 - ATENDENTE ${theme.words.nameMark} SÃO PAULO`,
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 667,
                              "nome": "161 - Atendente de telemarketing RJ",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 584,
                              "nome": "439 - Atendente de Telemarketing Arapiraca",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 570,
                              "nome": "219 - Atendente de telemarketing MSS",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 549,
                              "nome": "115 - Atendente de telemarketing MOC",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmBanco": [
                            {
                              "totalRegistros": 4444,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosPrestesPerder": [
                            {
                              "totalRegistros": 2,
                              "nome": "Banco a expirar em 30 dias",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosInscritosNaPlataforma": [
                            {
                              "totalRegistros": 17007,
                              "nome": "Total Candidatos Inscritos na Plataforma",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosNaoAvaliados": [
                            {
                              "totalRegistros": 2004,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ]
                        },
                        "semestre": {
                          "tipoPeriodo": "Semestre",
                          "vagasStatusAbertaFechada": [
                            {
                              "totalRegistros": 813,
                              "nome": "Finalizada",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 87,
                              "nome": "Publicada",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 60,
                              "nome": "EmAndamento",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmVagasFinalizadas": [
                            {
                                "totalRegistros": 0,
                                "nome": "Candidatos em vagas Finalizadas",
                                "mes": 0,
                                "ano": 0
                            }
                        ],
                          "candidatosNaoAvaliados": [
                            {
                              "totalRegistros": 1401,
                              "nome": "984 - ATENDENTE TELEMARKETING - ARAPIRACA CLARO BRASIL",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1256,
                              "nome": "787 - ATENDENTE DE TELEMARKETING MSS",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1246,
                              "nome": "1008 - ATENDENTE TELEMARKETING - JUAZEIRO DO NORTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1017,
                              "nome": "1445 - AGENTE ESPECIALISTA - BANCO ORIGINAL",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 972,
                              "nome": "1399 - PAGBANK - SP",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 907,
                              "nome": "1258 - ATENDENTE TELEMARKETING - SKILLS JUNHO",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 890,
                              "nome": "1959 - AGENTE ESPECIALISTA - SEGMENTO BANCÁRIO",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 709,
                              "nome": "782 - ATENDENTE C6 CHAT PADRÃO CG ",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 676,
                              "nome": "899 - ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 669,
                              "nome": "570 - ATENDENTE - VIVO TELEVENDAS",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasComCandidatosEmBancoPorCidadeSite": [
                            {
                              "totalRegistros": 2172,
                              "cidade": "São Paulo",
                              "site": "14 - Edifício Paramount - SP",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2020,
                              "cidade": "João Pessoa",
                              "site": "10 - João Pessoa - PB",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1715,
                              "cidade": "Belo Horizonte",
                              "site": "2 - Orion - BH - MG",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 966,
                              "cidade": "Campina Grande",
                              "site": "9 - Campina Grande - Paraíba",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 810,
                              "cidade": "Montes Claros",
                              "site": "7 - MOC1 e 2 - Montes Claros",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 646,
                              "cidade": "Rio de Janeiro",
                              "site": "15 - Rio de Janeiro",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 507,
                              "cidade": "Mossoró",
                              "site": "16 - Mossoró",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 430,
                              "cidade": "Arapiraca",
                              "site": "17 - Arapiraca",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 277,
                              "cidade": "Juazeiro do Norte",
                              "site": "1 - Ceará - Juazeiro do Norte",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 33,
                              "cidade": "Governador Valadares",
                              "site": "6 - GV1 - MG",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 42318,
                              "nome": "197 - Atendente de Telemarketing SP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 11901,
                              "nome": "127 - Atendente de Telemarketing",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 11522,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 10931,
                              "nome": "161 - Atendente de telemarketing RJ",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 10048,
                              "nome": "220 - Atendente de telemarketing JP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 9286,
                              "nome": "218 - Atendente de telemarketing CG",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 5276,
                              "nome": "115 - Atendente de telemarketing MOC",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 3078,
                              "nome": "219 - Atendente de telemarketing MSS",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2243,
                              "nome": "439 - Atendente de Telemarketing Arapiraca",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1551,
                              "nome": "442 - Atendente de Telemarketing GV",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmBanco": [
                            {
                              "totalRegistros": 9582,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosPrestesPerder": [
                            {
                              "totalRegistros": 2,
                              "nome": "Banco a expirar em 30 dias",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosInscritosNaPlataforma": [
                            {
                              "totalRegistros": 126981,
                              "nome": "Total Candidatos Inscritos na Plataforma",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosNaoAvaliados": [
                            {
                              "totalRegistros": 12124,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ]
                        },
                        "ano": {
                          "tipoPeriodo": "Ano",
                          "vagasStatusAbertaFechada": [
                            {
                              "totalRegistros": 1879,
                              "nome": "Finalizada",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 103,
                              "nome": "Publicada",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 67,
                              "nome": "EmAndamento",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmVagasFinalizadas": [
                            {
                                "totalRegistros": 1000,
                                "nome": "Candidatos em vagas Finalizadas",
                                "mes": 0,
                                "ano": 0
                            }
                        ],
                          "candidatosNaoAvaliados": [
                            {
                              "totalRegistros": 1401,
                              "nome": "984 - ATENDENTE TELEMARKETING - ARAPIRACA CLARO BRASIL",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1256,
                              "nome": "787 - ATENDENTE DE TELEMARKETING MSS",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1246,
                              "nome": "1008 - ATENDENTE TELEMARKETING - JUAZEIRO DO NORTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1017,
                              "nome": "1445 - AGENTE ESPECIALISTA - BANCO ORIGINAL",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 972,
                              "nome": "1399 - PAGBANK - SP",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 907,
                              "nome": "1258 - ATENDENTE TELEMARKETING - SKILLS JUNHO",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 890,
                              "nome": "1959 - AGENTE ESPECIALISTA - SEGMENTO BANCÁRIO",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 709,
                              "nome": "782 - ATENDENTE C6 CHAT PADRÃO CG ",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 676,
                              "nome": "899 - ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 669,
                              "nome": "570 - ATENDENTE - VIVO TELEVENDAS",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasComCandidatosEmBancoPorCidadeSite": [
                            {
                              "totalRegistros": 2172,
                              "cidade": "São Paulo",
                              "site": "14 - Edifício Paramount - SP",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 2020,
                              "cidade": "João Pessoa",
                              "site": "10 - João Pessoa - PB",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1715,
                              "cidade": "Belo Horizonte",
                              "site": "2 - Orion - BH - MG",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 1000,
                              "cidade": "Belo Horizonte",
                              "site": "Espirito Santo  I - BH - MG",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 966,
                              "cidade": "Campina Grande",
                              "site": "9 - Campina Grande - Paraíba",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 810,
                              "cidade": "Montes Claros",
                              "site": "7 - MOC1 e 2 - Montes Claros",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 646,
                              "cidade": "Rio de Janeiro",
                              "site": "15 - Rio de Janeiro",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 507,
                              "cidade": "Mossoró",
                              "site": "16 - Mossoró",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 430,
                              "cidade": "Arapiraca",
                              "site": "17 - Arapiraca",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 277,
                              "cidade": "Juazeiro do Norte",
                              "site": "1 - Ceará - Juazeiro do Norte",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 33,
                              "cidade": "Governador Valadares",
                              "site": "6 - GV1 - MG",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "vagasComMaisCandidatos": [
                            {
                              "totalRegistros": 65559,
                              "nome": "197 - Atendente de Telemarketing SP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 29406,
                              "nome": "127 - Atendente de Telemarketing",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 19835,
                              "nome": "218 - Atendente de telemarketing CG",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 18198,
                              "nome": "221 - Atendente de telemarketing JN",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 17862,
                              "nome": "161 - Atendente de telemarketing RJ",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 17731,
                              "nome": "220 - Atendente de telemarketing JP",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 8737,
                              "nome": "219 - Atendente de telemarketing MSS",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 8722,
                              "nome": "439 - Atendente de Telemarketing Arapiraca",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 7955,
                              "nome": "115 - Atendente de telemarketing MOC",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            },
                            {
                              "totalRegistros": 3683,
                              "nome": "442 - Atendente de Telemarketing GV",
                              "area": "ATENDENTE",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosEmBanco": [
                            {
                              "totalRegistros": 9582,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosPrestesPerder": [
                            {
                              "totalRegistros": 2,
                              "nome": "Banco a expirar em 30 dias",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosInscritosNaPlataforma": [
                            {
                              "totalRegistros": 266600,
                              "nome": "Total Candidatos Inscritos na Plataforma",
                              "mes": 12,
                              "ano": 2021
                            }
                          ],
                          "totalCandidatosNaoAvaliados": [
                            {
                              "totalRegistros": 17763,
                              "nome": "Total de candidatos em banco",
                              "mes": 12,
                              "ano": 2021
                            }
                          ]
                        },
                        "tempoRestanteCacheMinutos": 1439.9999971533334,
                        "dateTimeExpiration": "2021-12-10T08:59:38.6769551-03:00"
                      }
                }

                if(~~view === 2){
                    json = {
                        "totalCandidatosEmBancoTalentosList": [
                          {
                            "totalRegistros": 9584,
                            "nome": "Candidatos gerais em banco de talentos",
                            "mes": 12,
                            "ano": 2021
                          }
                        ],
                        "totalCandidatosComBancoVencerProx30Dias": [
                          {
                            "totalRegistros": 2,
                            "nome": "Candidatos em banco de talentos a vencer nos proximos 30 dias",
                            "mes": 12,
                            "ano": 2021
                          }
                        ],
                        "totalCandidatoIncluidosEmBancoDeTalentosPorMes": [
                          {
                            "totalRegistros": 4424,
                            "nome": "outubro",
                            "mes": 9,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 19,
                            "nome": "novembro",
                            "mes": 10,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 1,
                            "nome": "dezembro",
                            "mes": 11,
                            "ano": 2021
                          }
                        ],
                        "candidatosEmBancoTalentosPorCargo": [
                          {
                            "totalRegistros": 4438,
                            "nome": "ATENDENTE",
                            "area": "ATENDENTE",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 4,
                            "nome": "INSTRUTOR DE TREINAMENTO",
                            "area": "ADMINISTRATIVO",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 1,
                            "nome": "COORDENADOR DE OPERACAO JUNIOR",
                            "area": "ADMINISTRATIVO",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 1,
                            "nome": "AGENTE DE CS",
                            "area": "ADMINISTRATIVO",
                            "mes": 12,
                            "ano": 2021
                          }
                        ],
                        "candidatosEmBancoTalentosPorCidadeSite": [
                          {
                            "totalRegistros": 964,
                            "cidade": "São Paulo",
                            "site": "Edifício Paramount - SP",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 919,
                            "cidade": "Belo Horizonte",
                            "site": "Orion - BH - MG",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 646,
                            "cidade": "João Pessoa",
                            "site": "João Pessoa - PB",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 541,
                            "cidade": "Campina Grande",
                            "site": "Campina Grande - Paraíba",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 312,
                            "cidade": "Mossoró",
                            "site": "Mossoró",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 268,
                            "cidade": "Rio de Janeiro",
                            "site": "Rio de Janeiro",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 264,
                            "cidade": "Juazeiro do Norte",
                            "site": "Ceará - Juazeiro do Norte",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 263,
                            "cidade": "Arapiraca",
                            "site": "Arapiraca",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 245,
                            "cidade": "Montes Claros",
                            "site": "MOC1 e 2 - Montes Claros",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 21,
                            "cidade": "Governador Valadares",
                            "site": "GV1 - MG",
                            "mes": 12,
                            "ano": 2021
                          }
                        ],
                        "candidatosRemovidosBancoTalentosPorMotivo": [
                          {
                            "totalRegistros": 1029,
                            "nome": "Abidicou Do Banco",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 2,
                            "nome": "Removido Do Banco Outros Motivos",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 1,
                            "nome": "Em Andamento",
                            "mes": 12,
                            "ano": 2021
                          }
                        ],
                        "candidatosRemovidosBancoTalentosPorMes": [
                          {
                            "totalRegistros": 1024,
                            "nome": "outubro",
                            "mes": 9,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 5,
                            "nome": "novembro",
                            "mes": 10,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 3,
                            "nome": "dezembro",
                            "mes": 11,
                            "ano": 2021
                          }
                        ],
                        "candidatosCandidatosEmBancoPorArea": [
                          {
                            "totalRegistros": 4438,
                            "nome": "ATENDENTE",
                            "mes": 12,
                            "ano": 2021
                          },
                          {
                            "totalRegistros": 6,
                            "nome": "ADMINISTRATIVO",
                            "mes": 12,
                            "ano": 2021
                          }
                        ],
                        "tempoRestanteCacheMinutos": 1439.9999831616667,
                        "dateTimeExpiration": "2021-12-11T14:22:49.2113523-03:00"
                      }
                }

                json = null;
            }
            
            if(json){
                let dataFilter = Object.keys(json).filter(item => ((/(mes|trimestre|semestre|ano)/.test(item))));
                const map_filter = dataFilter.map(item => ({label:json[item].tipoPeriodo, value: item}));
                setFilterList(map_filter);
                setDashBase(json);
            }else{
                setDashData({data: [], base: [], date: (json?.dateTimeExpiration ? json.dateTimeExpiration : null), lastupdate: (json?.tempoRestanteCacheMinutos ? ~~json?.tempoRestanteCacheMinutos: null)});
            }

    };

    const alterFilterTab = (_data) => {
        tabFilter.current =_data. id;
        getDataDash(dashData.base);
    }

    useEffect(()=> {
        
        getDataDash(dashBase);
        
    },[dashBase]);

    useEffect(()=> {
        
        tabFilter.current = 0;
        setDashData(null);
        setFilterList([]);
        dataDashGetData();

    },[view]);
    
    useEffect (() => {
        
        // getNotificaton({icon:'success', content:<>Você possui <strong>03 avaliações em atraso!</strong></>, action:{name:'Visualizar'}});
        // getNotificaton({icon:'warning', content:<>Você possui <strong>03 avaliações em atraso!</strong></>, action:{name:'Visualizar'}});
        // getNotificaton({icon:'danger', content:<>Você possui <strong>03 avaliações em atraso!</strong></>, action:{name:'Visualizar'}});

        dataDashGetData();

    }, []);

    return (
      <MainFrameDash key={`filter-${tabFilter.current}`}>
          <Header>
              Bem vindo, <strong>{name}</strong>
          </Header>
          <NotificationArea style={{display: notificationData ? 'flex' : 'none'}}>
              {notificationData}
          </NotificationArea>
          <Nav>
            {
                tabsView.current.map((item, _id) => {
                    return <ButtonNav onClick={()=> alterView(_id) } className={`${~~view === _id ? 'active' : ''}`} style={{minWidth: '100px'}}>
                            {item.label}
                        </ButtonNav>
                })
            }
              {/* <ButtonNav onClick={()=> alterView(0) } className={`${~~view === 0 ? 'active' : ''}`} style={{width: '100px'}}>
                  Geral
              </ButtonNav> */}
              {/* <ButtonNav onClick={()=> props.history.push(urls.VACANCY_DASHBOARD_EDIT, {type: 0, name:"Geral"})} style={{width: '30px', backgroundColor:"var(--orange)"}}>
                  <Icon icon={gear} />
              </ButtonNav> */}
              {/* <ButtonNav onClick={()=> alterView(1) } className={`${~~view === 1 ? 'active' : ''}`} style={{width: '100px'}}>
                  Seus dados
              </ButtonNav> */}
              {/* <ButtonNav onClick={()=> props.history.push(urls.VACANCY_DASHBOARD_EDIT, {type: 1, name:"Seus dados"})} style={{width: '30px', backgroundColor:"var(--orange)"}}>
                  <Icon icon={gear} />
              </ButtonNav> */}
          </Nav>
          <section className="area-section">
            <div className="filter">
                {
                    filterList?.length > 0 ?      
                        <div className="filter-title">
                            <strong>Filtro</strong>:
                        </div>
                    :
                        ''
                }
                {
                    filterList.map((item, id) => {
                        return <>
                            <div onClick={()=> alterFilterTab({...item, id: id}) } className={`button-filter ${~~tabFilter.current === id ? 'active' : ''}`}>
                                {item.label}
                            </div>
                        </>
                    })
                }
            </div>
            <DynamicDash data={dashData?.data} key={`tab-${~~view}-${dashData?.lastupdate}`} date={dashData?.date} time={dashData?.lastupdate} onUpdateData={dataDashGetData} />
          </section>
      </MainFrameDash>
    )
}


export default connect()(DashBoardTalentsMain);