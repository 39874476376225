import React, { useEffect } from "react";
import {
  ButtonSecondary,
  SelectFieldInput
} from "components/shared/CustomInputs";
import { FormattedMessage } from "react-intl";
import TimeLine from "components/Candidates/TimeLine";
import DefaultCard from "components/Candidates/DefaultCard";
import TestCard from "components/Candidates/TestCard";
import SimpleStageCard from "components/Candidates/SimpleStageCard";
import { calcAge } from "helpers/sharedHelpers";
import { connect } from "react-redux";
import { Creators as CandidateActions } from "store/ducks/candidates";
import {
  ContentRow,
  Actions,
  BoxInfoCadidate,
  NameLabel,
  TableBoxInfo,
  TableHeaderBoxInfo,
  TableBodyBoxInfo,
  ContentLegendStatus,
  LegendStatus,
  BoxInfo,
  ImgCandidate
} from "./CandidateStyle";
import Load from "components/Layout/Load";
import BreadCrumb from "components/Layout/breadcrumb";
import { Row, Col } from "react-bootstrap";

const Candidate = ({ match, requestCandidate, location, candidate }) => {
  useEffect(() => {
    const { params } = match;
    const { search } = location.location;
    if (params.id && search) {
      const vacancyId = search.split("=")[1];
      requestCandidate(params.id, vacancyId);
    }
  }, []);
  if (candidate.isLoading) return <Load isLoading={candidate.isLoading} />;

  return (
    <React.Fragment>
      <ContentRow>
        <Row>
          <Col>
            <BreadCrumb
              location={location.location}
              preTitle={
                <FormattedMessage id="vacancyShow.tableStatus.statusCandidate" />
              }
              title={candidate.code}
            />
          </Col>
          <Col>
            <Actions>
              <ButtonSecondary
                width="138px"
                ml="20px"
                name={<FormattedMessage id="sharedItems.historic" />}
              />
              <ButtonSecondary
                width="173px"
                ml="20px"
                name={<FormattedMessage id="sharedItems.sendMessage" />}
              />
              <SelectFieldInput ml="20px" width="240px" />
            </Actions>
          </Col>
        </Row>
      </ContentRow>
      <ContentRow mt="30px">
        <Row style={{ margin: "0px" }}>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                <ImgCandidate />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                style={{ padding: "0px" }}
              >
                <BoxInfoCadidate>
                  <NameLabel>{candidate.name}</NameLabel>
                  <div>
                    {calcAge(candidate.birthday)}
                    {`, ${candidate.address.city}. ${candidate.address.state}`}
                  </div>
                  <div>{candidate.job}</div>
                </BoxInfoCadidate>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            style={{ padding: "0px 0 0 15px" }}
          >
            <BoxInfo>
              <TableBoxInfo>
                <TableHeaderBoxInfo>
                  <React.Fragment>
                    <FormattedMessage id="sharedItems.candidateForVacancy" />
                  </React.Fragment>
                  <React.Fragment>
                    <FormattedMessage id="sharedItems.code" />
                  </React.Fragment>
                  <React.Fragment>
                    <FormattedMessage id="sharedItems.area" />
                  </React.Fragment>
                  <React.Fragment>
                    <FormattedMessage id="sharedItems.managerInfo" />
                  </React.Fragment>
                </TableHeaderBoxInfo>
                <TableBodyBoxInfo>
                  <div>{candidate.job}</div>
                  <div>{candidate.vacancyId}</div>
                  <div>{candidate.area}</div>
                  <div>N/A</div>
                </TableBodyBoxInfo>
              </TableBoxInfo>
              <ContentLegendStatus>
                <ContentLegendStatus>
                  <LegendStatus status={"approved"} />
                  <FormattedMessage id="sharedItems.evaluateApproved" />
                </ContentLegendStatus>
                <ContentLegendStatus>
                  <LegendStatus status={"reproved"} />
                  <FormattedMessage id="sharedItems.evaluateReproved" />
                </ContentLegendStatus>
                <ContentLegendStatus>
                  <LegendStatus status={"current"} />
                  <FormattedMessage id="sharedItems.evaluateCurrent" />
                </ContentLegendStatus>
              </ContentLegendStatus>
            </BoxInfo>
          </Col>
        </Row>
      </ContentRow>
      <TimeLine
        dots={[
          { position: 1, approved: 1, name: "Dados básicos" },
          ...candidate.stages.map((stage, index) => ({
            position: index + 2,
            approved: stage.status,
            name: stage.name
          }))
        ]}
      />
      <DefaultCard candidate={candidate} />
      {candidate.stages.map((stage, index) => {
        switch (stage.kindStage) {
          case 1:
            return <TestCard position={index + 2} stage={stage} />;
          default:
            return <SimpleStageCard position={index + 2} stage={stage} />;
        }
      })}
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  requestCandidate: (candidateId, vacancyId) => {
    dispatch(CandidateActions.candidateShowRequest(candidateId, vacancyId));
  }
});
const mapStateToProps = state => ({
  location: state.router,
  candidate: state.candidates.candidate
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Candidate);

Candidate.defaultProps = {
  candidate: {
    isLoading: true
  }
};
