import * as routes from "../../utils/contants/urls";
import * as urls from "../../utils/contants/urlsApi";

import {
  buildDate,
  buildErroHandleItems,
  buildPagination,
  formatAssociationAttributes
} from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/rails";
import { history } from "../../utils/routes";
import moment from "moment";
import { Types as vacancyAssociationTypes } from "../ducks/vacancyAssociation";

const requestRequest = (id, page) =>
  API.get(
    `${urls.GET_RAILS_TO_ASSOCIATE}/?idcargo=${id}&NumeroPagina=${page || 1}`
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* getRailsWithStages({ id }) {
  try {
    const { lista } = yield requestRequest(id);
    const rails = lista.map(item => ({
      createdAt: moment(item.dataCriacao).format("L"),
      id: item.id,
      createBy: item.usuarioQueCriou,
      stages: item.etapas.map(stage => ({
        avaliation: stage.avaliacao ? stage.avaliacao.nome : "",
        description: stage.descricao,
        level: stage.nivel ? stage.nivel.nome : "",
        howManyQuestions: stage.quantidadePerguntas,
        timeOfTest: stage.tempoDoTeste,
        kind: stage.tipo ? stage.tipo.nome : "",
        kindStage: stage.tipoEtapa,
        name: stage.titulo,
        id: stage.id
      }))
    }));
    yield put({
      type: Types.REQUEST_RAILS_WITH_STAGES_SUCCESS,
      railsWithStages: rails
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_RAILS_WITH_STAGES_FAIL, error });
  }
}

const requestCreateRailItem = id =>
  API.post(`${urls.CREATE_RAILS}?idcargo=${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* createRailItem({ payload }) {
  try {
    const { id } = yield requestCreateRailItem(payload.job);
    const rail = { id };


    yield put({ type: Types.REQUEST_CREATE_RAILS_SUCCESS, rail });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_CREATE_RAILS_FAIL, error });
  }
}

const requestAddStageToRail = body =>
  API.post(`${urls.ADD_STAGE_TO_RAILS}`, body)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* AddStageToRail({ payload, id }) {
  try {
    const body = {
      etapas: payload,
      idTrilha: id
    };
    yield requestAddStageToRail(body);
    yield put({ type: Types.REQUEST_ADD_STAGE_TO_RAILS_SUCCESS });
    yield put({ type: Types.REQUEST_SHOW_RAILS, id });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_CREATE_RAILS_FAIL, error });
  }
}

const requestListRails = body =>
  API.put(`${urls.GET_LIST_RAILS}`, body)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* getListRails({ filters }) {
  try {
    const buildFilter = {
      NumeroPagina: filters ? filters.page : 1,
      idCargo: filters ? filters.job : null,
      idArea: filters ? filters.area : null
    };
    const { lista, paginacao } = yield requestListRails(buildFilter);
    const stateRailsList = yield select(getStateRailsList);
    const rails = lista.map(item => ({
      id: item.id,
      status: item.ativa,
      role: item.cargo.funcao,
      area: item.cargo.area.descricao,
      createdAt: moment(item.dataCriacao).format("L"),
      createdBy: item.usuarioQueCriou,
      stages: item.etapas.map((e, index) => `${index + 1}. ${e}`)
    }));
    const pagination = buildPagination(paginacao, buildFilter, rails, stateRailsList);
    yield put({ type: Types.REQUEST_LIST_RAILS_SUCCESS, rails, pagination });
  } catch (error) {
    console.log(error);
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_LIST_RAILS_FAIL, error });
  }
}

const buildStageItems = stages =>
  stages.map(item => {
    switch (item.tipoEtapa) {
      case 1:
        return {
          kindTest: item.etapaTipoTeste.nome,
          levelTest: item.etapaNivelTeste.nome,
          evaliation: item.etapaAvaliacao.nome,
          questionAndTime: `${
            item.blocoPerguntasEtapaTeste.length
          }/${moment
            .utc(
              (item.tempoTesteSegundos * 1000) /
                item.blocoPerguntasEtapaTeste.length
            )
            .format("HH:mm:ss")}`,
          time: moment.utc(item.tempoTesteSegundos * 1000).format("HH:mm:ss"),
          description: item.descricao,
          name: item.nome,
          kind: item.tipoEtapa,
          id: item.id
        };
      case 2:
        return {
          description: item.descricao,
          name: item.nome,
          kind: item.tipoEtapa,
          kindItem: item.etapaTipoTreinamento.nome,
          id: item.id
        };
      default:
        return {
          description: item.descricao,
          name: item.nome,
          kind: item.tipoEtapa,
          id: item.id
        };
    }
  });

const requestShowRails = id =>
  API.get(`${urls.SHOW_LIST_RAILS}/${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* getShowRails({ id }) {
  try {
    const item = yield requestShowRails(id);
    const rail = {
      status: item.ativa,
      area: item.cargo.area.id,
      job: item.cargo.id,
      stages: buildStageItems(item.etapas),
      id: item.id
    };
    yield put({ type: Types.REQUEST_SHOW_RAILS_SUCCESS, rail });
    yield getListRails({});
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_SHOW_RAILS_FAIL, error });
  }
}

const requestDisableRails = id =>
  API.put(`${urls.DISABLE_RAILS}/${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* disableRails({ id }) {
  try {
    yield requestDisableRails(id);
    yield put({ type: Types.REQUEST_DISABLE_SUCCESS });
    yield getListRails({ page: 1 });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_DISABLE_FAIL, error });
  }
}

const requestPublishVacancy = (id, body) =>
  API.post(`${urls.REQUEST_ASSOCIATION_WITH_RAILS}/${id}`, body)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* insertAssociationAttributes({ payload }) {
  try {
    const body = formatAssociationAttributes(payload);
    yield requestPublishVacancy(payload.idVacancy, body);
    const isAssociating = false;
    yield put({
      type: vacancyAssociationTypes.SET_IS_ASSOCIATING_REQUEST,
      isAssociating
    });
    history.push(`${routes.VACANCY_EDITAR}${payload.idVacancy}`);
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_ASSOCIATION_ATTRIBUTES_RAILS_FAIL, error });
  }
}

const requestDeleteStageInRails = (id, idStage) =>
  API.delete(`${urls.REMOVE_STAGE_FROM_RAILS}/${id}?idetapa=${idStage}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* deleteStageInRails({ idStage, idRail }) {
  try {
    yield requestDeleteStageInRails(idRail, idStage);
    yield getShowRails({ id: idRail });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_ASSOCIATION_ATTRIBUTES_RAILS_FAIL, error });
  }
}

const requestEditRail = rail => {
  return API.put(`${urls.EDIT_RAILS}/${rail.id}`, buildEditRail(rail))
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const buildEditRail = rail => ({
  trilhaAtiva: rail.status,
  idCargo: rail.job,
  etapas: rail.stages.map(e => e.id)
});

function* editRailItem({ payload }) {
  try {
    yield requestEditRail(payload);
    yield put({ type: Types.REQUEST_EDIT_RAILS_SUCCESS });
    const messages = [{ type: "success", message: "Editado com sucesso!" }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.REQUEST_EDIT_RAILS_SUCCESS });
  }
}

const getStateRailsList = state => state.rails.rails;

export function* watcherSaga() {
  yield takeLatest(Types.REQUEST_RAILS_WITH_STAGES, getRailsWithStages);
  yield takeLatest(Types.REQUEST_CREATE_RAILS, createRailItem);
  yield takeLatest(Types.REQUEST_ADD_STAGE_TO_RAILS, AddStageToRail);
  yield takeLatest(Types.REQUEST_LIST_RAILS, getListRails);
  yield takeLatest(Types.REQUEST_SHOW_RAILS, getShowRails);
  yield takeLatest(Types.REQUEST_DISABLE_RAILS, disableRails);
  yield takeLatest(
    Types.REQUEST_ASSOCIATION_ATTRIBUTES_RAILS,
    insertAssociationAttributes
  );
  yield takeLatest(Types.REQUEST_DELETE_STAGE_IN_RAILS, deleteStageInRails);
  yield takeLatest(Types.REQUEST_EDIT_RAILS, editRailItem);
}
