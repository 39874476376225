import React from "react";
import theme from "../../components/shared/Theme";

export const approved = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <g fill="none" fillRule="evenodd" transform="translate(.5)">
      <circle cx="12" cy="12" r="12" fill="#8BC63F" />
      <path
        fill="#FFF"
        d="M10.327 16.75a.804.804 0 0 1-.563-.228l-3.53-3.445a.766.766 0 0 1-.001-1.102.81.81 0 0 1 1.127 0l2.967 2.896 7.063-6.893a.81.81 0 0 1 1.127 0 .766.766 0 0 1 0 1.102l-7.626 7.442a.804.804 0 0 1-.564.228"
      />
    </g>
  </svg>
);
export const IconUser = () => (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    xmlSpace="preserve"
    preserveAspectRatio="1"
  >
    <style type="text/css"></style>
    <rect
      x="0"
      y="0"
      class="st0"
      fill={theme.colors.defaultColor}
      width="1080"
      height="1080"
    />
    <g
      id="Camada_8"
      style={{ transform: "scale(2) translate(-285px, -248px)" }}
    >
      <path
        class="st1"
        fill="#FFFFFF"
        d="M631.5,561c-13.6-15.8-30.9-25.6-51.7-30.4c14.4-11.2,25.2-24,27.4-42.3c2.1-18.2-4-33.7-16.9-46.5
            c-22.7-22.4-58.1-21.3-80,2.7c-18.7,20.5-25,56.9,12.9,86c-44,11.2-71.3,43.4-74.4,87.4c-0.8,10.6,2.2,13.8,13,13.8h179.4
            c1.2,0,2.3,0,3.5,0c6.6-0.4,10-3.9,9.9-10.6C654,598.4,646.5,578.3,631.5,561z M504.7,481.2c0.5-26.3,21.7-46.6,48-46.1
            c25.8,0.5,46.2,21.8,45.7,47.4c-0.5,25.8-21.8,46.7-47,46.4C525.3,528.6,504.2,507.1,504.7,481.2z M640.1,622.5
            c-29.5-0.2-58.9-0.1-88.4-0.1c-29.6,0-59.3-0.1-88.9,0.1c-3.8,0-5-0.7-4.8-4.8c1.4-37.9,32-73.1,69.6-78.1
            c21.9-3,44.3-4.3,65.3,4.9c31.6,13.9,49.1,38.4,52.2,73C645.5,621.8,644.1,622.5,640.1,622.5z"
      />
    </g>
  </svg>
);
export const IconPassWord = () => (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1080 1080"
    xmlSpace="preserve"
    preserveAspectRatio="1"
  >
    <style type="text/css"></style>
    <rect
      x="0"
      y="0"
      class="st0"
      fill={theme.colors.defaultColor}
      width="1080"
      height="1080"
    />
    <g id="Camada_9">
      <g style={{ transform: "scale(2) translate(-275px, -248px)" }}>
        <path
          fill="#FFFF"
          d="M581,400.8c3.7,2.1,13.1,7.5,20.6,18.4c7,10.2,10.4,22.5,10.6,36.4c0.2,9.5,0,18.9,0,28.6
			c1.5,0.3,2.8,0.6,4.1,0.9c9.6,2.1,16,9.6,16.1,19.5c0.1,31,0.2,61.9,0,92.9c-0.1,11.1-7,17-17,20c-43,0-86,0-129,0
			c-13.7-5.2-17.1-10.1-17.2-25c0-28,0-55.9,0-83.9c0-14.7,5.1-21.1,20.2-24.4c0-6.4,0.1-13,0-19.6c-0.2-12.9,0.3-25.7,6.3-37.5
			c5-9.8,11.4-17.6,19.4-23.2c3.2-2.3,12.8-8.6,27-10.6C561.1,390.7,575.6,397.8,581,400.8z M551,494.6c-19.5,0-39,0-58.5,0
			c-9.8,0-13.3,3.5-13.3,13.2c0,28.8,0,57.7,0,86.5c0,9.4,3.6,13.1,12.9,13.1c39.2,0,78.3,0,117.5,0c9.1,0,12.8-3.7,12.8-12.7
			c0-29,0-58,0-87c0-9.6-3.6-13.1-13.4-13.1C589.7,494.6,570.3,494.6,551,494.6z M601.4,484.3c0-12.6,1-24.9-0.2-37.1
			c-2.5-24.6-23-43.1-47.6-44.5c-24.3-1.4-47.2,14.7-52,38.6c-2.6,12.9-1.6,26.5-2.1,39.7c0,1.1,0.3,2.2,0.5,3.2
			C534,484.3,567.8,484.3,601.4,484.3z"
        />
        <path
          fill="#FFFF"
          d="M550.1,525.5c16,0.1,25.7,17.4,18,30.7c-1.2,2-2,4.4-2.3,6.8c-1.1,8.3-7.3,14.1-15.4,14
			c-8.2-0.2-14.3-6.1-15.1-14.3c-0.2-2.2-1.1-4.4-2.1-6.4c-3.6-6.9-3.9-13.7,0-20.4C537.1,529,543.2,525.9,550.1,525.5z
			 M542.4,551.5c0.9,1.5,1.7,1.9,2.5,3.8c0.6,1.6,0.8,3.3,0.7,5.1c-0.1,3.4,1.6,6,5.2,6c3.6,0,5.3-2.7,5.1-6.1
			c-0.1-1.2,0-2.2,0.2-3.2c0.6-2.7,1.9-3.8,3-5.7c1.9-3.4,1.9-8.5-0.5-11.9c-2.1-3-5.6-3.7-5.9-3.8c-1.6-0.3-3.4-0.2-5.2,0.5
			c-0.7,0.2-2.4,0.9-3.9,2.3C540.5,541.8,540.2,547.8,542.4,551.5z"
        />
      </g>
    </g>
  </svg>
);
export const IconColigada = () => (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1080 1080"
    xmlSpace="preserve"
    preserveAspectRatio="1"
  >
    <style type="text/css"></style>
    <rect fill="#39A6D6" x="0" y="0" class="st0" width="1080" height="1080" />
    <g id="Camada_10">
      <g style={{ transform: "scale(2) translate(-285px, -248px)" }}>
        <path
          fill="#FFF"
          class="st1"
          d="M482.7,634.8c-2.5-2.2-5.1-4.2-7.5-6.5c-8.4-8.3-16.8-16.7-25.1-25.1c-4.4-4.4-4.4-7.2-0.2-11.4
			c9.4-9.5,18.8-18.9,28.3-28.3c4.1-4,7.8-4.5,10.8-1.4c2.9,3,2.3,6.7-1.8,10.8c-5.6,5.6-11.3,11.2-17.7,17.5
			c2.5,0.1,3.9,0.3,5.3,0.3c44,0,88,0,132,0c14.4,0,23.4-8.9,23.6-23.3c0.1-12.8,0-25.7,0.1-38.5c0-4.2,2.3-6.8,6.3-7
			c4.1-0.2,6.7,2.6,6.7,6.6c0,15,0.7,30.1-0.6,44.9c-1.6,18-16.3,30.3-34.4,30.3c-44,0-88,0-132,0c-1.8,0-3.7,0-6.9,0
			c2.9,2.9,4.9,5,7.1,7.2c3.5,3.5,7.1,7,10.6,10.6c5.3,5.4,5.2,8.1-0.4,13.2C485.4,634.8,484,634.8,482.7,634.8z"
        />
        <path
          fill="#FFF"
          class="st1"
          d="M607.7,409.8c2.5,2.2,5.1,4.2,7.5,6.5c8.4,8.3,16.8,16.7,25.1,25.1c4.4,4.4,4.4,7.2,0.2,11.4
			c-9.5,9.6-19.1,19.1-28.6,28.6c-3.8,3.8-7.5,4.1-10.5,1.1c-2.9-2.9-2.4-6.7,1.3-10.4c5.8-5.7,11.6-11.4,18.2-17.9
			c-2.6-0.2-4-0.4-5.3-0.4c-44,0-88,0-131.9,0c-14.2,0-23.1,8.8-23.3,22.9c-0.1,11.7,0,23.3,0,35c0,1.5,0.1,3-0.2,4.5
			c-0.7,3.6-3,5.7-6.6,5.5s-6.1-2.3-6.1-6c0-15.2-0.7-30.4,0.4-45.5c1.1-16.6,16.8-29.5,34.2-29.5c44-0.1,88,0,131.9,0
			c1.8,0,3.7,0,6.7,0c-3.3-3.4-6-6.2-8.7-8.9c-2.9-3-5.9-5.9-8.8-8.8c-5.3-5.4-5.2-8.1,0.4-13.2C605,409.8,606.4,409.8,607.7,409.8z
			"
        />
      </g>
    </g>
  </svg>
);
export const manualAprovation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <g fill="none" fillRule="evenodd" transform="translate(.5)">
      <circle cx="12" cy="12" r="12" fill="#edce60" />
      <g fill="#FFF" transform="translate(5 10)">
        <ellipse cx="1.631" cy="1.615" rx="1.631" ry="1.615" />
        <ellipse cx="7.015" cy="1.615" rx="1.631" ry="1.615" />
        <ellipse cx="12.4" cy="1.615" rx="1.631" ry="1.615" />
      </g>
    </g>
  </svg>
);
export const awaitAValiation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <g fill="none" fillRule="evenodd" transform="translate(.5)">
      <circle cx="12" cy="12" r="12" fill="#858585" />
      <g fill="#FFF" transform="translate(5 10)">
        <ellipse cx="1.631" cy="1.615" rx="1.631" ry="1.615" />
        <ellipse cx="7.015" cy="1.615" rx="1.631" ry="1.615" />
        <ellipse cx="12.4" cy="1.615" rx="1.631" ry="1.615" />
      </g>
    </g>
  </svg>
);

export const awaitStage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <g fill="none" fillRule="evenodd" transform="translate(.5)">
      <circle cx="12" cy="12" r="12" fill="#858585" />
      <g fill="#FFF" transform="translate(5 10)">
        <ellipse cx="1.631" cy="1.615" rx="1.631" ry="1.615" />
        <ellipse cx="7.015" cy="1.615" rx="1.631" ry="1.615" />
        <ellipse cx="12.4" cy="1.615" rx="1.631" ry="1.615" />
      </g>
    </g>
  </svg>
);

export const declined = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <g fill="none" fillRule="evenodd" transform="translate(.5)">
      <circle cx="12" cy="12" r="12" fill="#FF586D" />
      <path
        fill="#FFF"
        d="M13.146 13.613h-1.664L11.16 7h2.306l-.321 6.613zM12.314 17h-.015C11.526 17 11 16.43 11 15.672c0-.789.54-1.329 1.314-1.329s1.285.54 1.3 1.329c0 .759-.512 1.328-1.3 1.328z"
      />
    </g>
  </svg>
);

export const evaluate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <g fill="none" fillRule="evenodd" transform="translate(.5)">
      <circle cx="12" cy="12" r="12" fill="#005394" />
      <g fill="#FFF" transform="translate(5 10)">
        <ellipse cx="1.631" cy="1.615" rx="1.631" ry="1.615" />
        <ellipse cx="7.015" cy="1.615" rx="1.631" ry="1.615" />
        <ellipse cx="12.4" cy="1.615" rx="1.631" ry="1.615" />
      </g>
    </g>
  </svg>
);

export const arrowColapse = () => (
  <svg width="31px" height="30px" viewBox="0 0 31 30" version="1.1">
    <g
      id="[S5]-Gerenciar-candidatos"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="#5.2-Candidato" transform="translate(-1354.000000, -698.000000)">
        <g
          id="elements/number-card-copy-6"
          transform="translate(266.000000, 683.000000)"
        >
          <g id="Group-18" transform="translate(0.500000, 0.000000)">
            <g id="Group-20" transform="translate(1087.400000, 14.500000)">
              <rect
                id="Rectangle"
                fill="#FFFFFF"
                x="0.6"
                y="0.5"
                width="30"
                height="30"
              />
              <polyline
                id="Shape"
                stroke="#005394"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="6 11 15.6 20 25.2 11"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const aboutBtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="#17B3D2" fillRule="evenodd">
      <path d="M12.396 13.235c3.38 0 6.122-2.733 6.122-6.103 0-3.37-2.741-6.085-6.122-6.085-3.379 0-6.12 2.733-6.12 6.085 0 3.351 2.741 6.103 6.12 6.103zm0-10.596c2.48 0 4.507 2.022 4.507 4.493 0 2.471-2.028 4.493-4.507 4.493-2.478 0-4.506-2.022-4.506-4.493 0-2.471 2.028-4.493 4.506-4.493zM1.808 22.766H23.06a.8.8 0 0 0 .808-.805c0-4.213-3.436-7.658-7.68-7.658H8.68C4.454 14.303 1 17.73 1 21.961a.8.8 0 0 0 .808.805zm6.87-6.853h7.511a6.055 6.055 0 0 1 6.009 5.243H2.67a6.07 6.07 0 0 1 6.008-5.243z" />
    </g>
  </svg>
);

export const carrierBtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="#17B3D2" fillRule="nonzero">
      <path d="M17.315 1.412h5.315c.367 0 .664.297.664.664v19.267a.664.664 0 0 1-.664.664h-5.315a.664.664 0 0 1-.664-.664V2.076c0-.367.297-.664.664-.664zm.664 1.328v17.938h3.986V2.74H17.98zM9.343 5.398h5.314c.367 0 .665.297.665.664v15.28a.664.664 0 0 1-.665.665H9.343a.664.664 0 0 1-.665-.664V6.063c0-.368.298-.665.665-.665zm.664 1.329v13.951h3.986V6.727h-3.986zM5.388 12.996a3.322 3.322 0 0 1 1.961 3.032v5.315a.664.664 0 0 1-.664.664H1.37a.664.664 0 0 1-.664-.664v-5.315c0-1.35.805-2.512 1.961-3.032a2.657 2.657 0 1 1 2.72 0zm.633 7.682v-4.65a1.993 1.993 0 1 0-3.986 0v4.65H6.02zm-1.993-8.636a1.329 1.329 0 1 0 0-2.658 1.329 1.329 0 0 0 0 2.658z" />
    </g>
  </svg>
);

export const formationBtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#17B3D2"
      fillRule="nonzero"
      d="M16.235 15.248a8.081 8.081 0 0 1-3.882.987 8.081 8.081 0 0 1-3.882-.987v7.084l3.356-3.75a.706.706 0 0 1 1.052 0l3.356 3.75v-7.084zm1.412-.976v8.06a1.412 1.412 0 0 1-2.464.942l-2.83-3.163-2.83 3.163a1.412 1.412 0 0 1-2.464-.942v-8.06a8.118 8.118 0 1 1 10.588 0zm-5.294.552a6.706 6.706 0 1 0 0-13.412 6.706 6.706 0 0 0 0 13.412z"
    />
  </svg>
);

export const goalBtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#17B3D2"
      fillRule="nonzero"
      d="M5.783 5.291a10.292 10.292 0 0 1 7.185-2.91c5.703 0 10.326 4.623 10.326 10.326s-4.623 10.326-10.326 10.326c-5.702 0-10.325-4.623-10.325-10.326 0-1.459.303-2.876.882-4.182a.558.558 0 1 1 1.02.453 9.175 9.175 0 0 0-.786 3.73 9.21 9.21 0 1 0 9.21-9.21c-2.42 0-4.69.936-6.396 2.583L9.12 8.629a5.741 5.741 0 0 1 3.568-1.224c3.076 0 5.582 2.366 5.582 5.302 0 2.936-2.506 5.303-5.582 5.303-3.075 0-5.581-2.367-5.581-5.303a.558.558 0 1 1 1.116 0c0 2.305 1.992 4.186 4.465 4.186s4.465-1.881 4.465-4.186c0-2.304-1.992-4.186-4.465-4.186a4.634 4.634 0 0 0-2.771.904l2.482 2.483a1.118 1.118 0 0 1 1.406 1.078 1.116 1.116 0 1 1-2.195-.289L3.129 4.215h-.715a.558.558 0 0 1-.403-.171L.863 2.848a.558.558 0 0 1 .402-.945h.59v-.638c0-.51.63-.753.972-.375l1.084 1.196a.558.558 0 0 1 .145.375v1.103L5.783 5.29z"
    />
  </svg>
);

export const attachBtnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#17B3D2"
      fillRule="nonzero"
      stroke="#17B3D2"
      strokeWidth=".5"
      d="M22.642 2.955a4.567 4.567 0 0 0-6.53-.002L4.963 14.356a2.616 2.616 0 0 0 0 3.659 2.46 2.46 0 0 0 3.536.002l6.91-7.086a.419.419 0 0 0 0-.615c-.168-.171-.38-.171-.55.002l-6.96 7.027c-.647.659-1.741.659-2.39 0a1.714 1.714 0 0 1 0-2.373L16.658 3.57c1.464-1.49 3.919-1.49 5.382 0 1.452 1.478 1.453 3.94.001 5.416L10.891 20.39c-2.336 2.376-6.1 2.376-8.374 0-2.323-2.365-2.323-6.16 0-8.462L12.525 1.743a.419.419 0 0 0 0-.615c-.168-.171-.379-.17-.548.001L1.97 11.257c-2.627 2.673-2.627 7.074 0 9.747a6.675 6.675 0 0 0 9.522 0l11.15-11.346c1.81-1.843 1.81-4.86 0-6.703z"
    />
  </svg>
);

export const iconDoor = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="19"
    viewBox="0 0 12 19"
  >
    <path
      fill="#858585"
      fillRule="nonzero"
      d="M10.65.594H.6a.464.464 0 0 0-.469.469v16.875c0 .262.207.468.469.468h10.05a.464.464 0 0 0 .469-.468V1.063a.464.464 0 0 0-.469-.47zm-.525 16.8H2.437L6.112 15.5c.02 0 .02-.019.038-.019a.57.57 0 0 0 .15-.15l.056-.112c.019-.056.019-.094.019-.131V3.912a.498.498 0 0 0-.263-.432L2.42 1.606h7.687v15.788h.019zM4.781 9.8v-.619a.356.356 0 1 1 .713 0V9.8a.356.356 0 0 1-.356.356c-.207.019-.357-.15-.357-.356z"
    />
  </svg>
);

export const userGreenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
  >
    <g fill="#8BC63F" fillRule="evenodd">
      <path d="M9.324 10.01c2.766 0 5.009-2.236 5.009-4.993S12.09.038 9.324.038c-2.765 0-5.007 2.237-5.007 4.979s2.242 4.994 5.007 4.994zm0-8.67c2.029 0 3.688 1.655 3.688 3.677 0 2.022-1.66 3.676-3.688 3.676-2.027 0-3.686-1.654-3.686-3.676 0-2.022 1.659-3.676 3.686-3.676zM.66 17.809h17.39c.37 0 .66-.291.66-.66 0-3.446-2.81-6.265-6.283-6.265H6.283C2.826 10.884 0 13.688 0 17.15c0 .368.292.659.66.659zm5.623-5.607h6.144c2.52 0 4.594 1.869 4.916 4.29H1.367a4.967 4.967 0 0 1 4.916-4.29z" />
    </g>
  </svg>
);

export const plugAec = (props) => (
  <svg
    id="plugAec_Camada"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 248 248"
  >
    <style type="text/css">
      {`#plugAec_Camada .st0{fill:${theme.colors.defaultColor};stroke:${theme.colors.defaultColor};stroke-miterlimit:10;} #plugAec_Camada .st1{fill:white;}.st2{fill:#F2F6F9;}#plugAec_Camada .st3{fill:#white;}#plugAec_Camada .st4{fill:#FDFDFD;}#plugAec_Camada .st5{fill:#FFFFFF;}#plugAec_Camada .st6{fill:${theme.colors.defaultColor};}`}
    </style>
    <g>
      <g id="Camada_8">
        <ellipse class="st0" cx="124" cy="117.7" rx="71.5" ry="70.5" />
        <path
          class="st0"
          d="M195.3,155.4c-0.1,0.2-0.2,0.5-0.3,0.7c-1.1,1.9-2.1,3.8-3.2,5.6c-0.2,0.4-0.5,0.7-1,0.3
   c-0.4-0.2-0.6-0.6-0.4-1c0-0.1,0.1-0.1,0.1-0.2c1.1-1.9,2.1-3.7,3.2-5.6c0.2-0.3,0.4-0.7,0.8-0.4
   C194.8,155,195.1,155.2,195.3,155.4z"
        />
        <path
          class="st0"
          d="M197.3,150.5c-0.4-0.2-1.1-0.2-0.7-1.1c0.8-2.1,1.7-4.1,2.5-6.2c0.2-0.6,0.5-0.7,0.9-0.4s0.7,0.3,0.5,0.8
   c-0.9,2.2-1.7,4.4-2.6,6.6C197.8,150.3,197.7,150.5,197.3,150.5z"
        />
        <path
          class="st0"
          d="M202,138c-0.6-0.1-1.2-0.3-1-1c0.5-2.3,1.1-4.5,1.6-6.8c0.1-0.4,0.3-0.6,0.7-0.4c0.3,0.1,0.8,0,0.7,0.6
   c-0.5,2.4-1.1,4.8-1.7,7.1C202.3,137.7,202.2,138,202,138z"
        />
        <path
          class="st0"
          d="M203.1,102.1c0.1,0.2,0.1,0.4,0.2,0.7c0.5,2.1,1,4.1,1.5,6.2c0.1,0.6,0.1,0.9-0.5,1.1s-0.8-0.1-0.9-0.7
   c-0.5-2.1-1-4.2-1.5-6.3c-0.1-0.3-0.3-0.7,0.3-0.9C202.4,102,202.7,101.8,203.1,102.1z"
        />
        <path
          class="st0"
          d="M171.9,55.3c0.3-0.3,0.4-1.2,1.2-0.5l5.1,4.4c0.3,0.3,0.5,0.5,0.1,0.9s-0.5,0.7-0.9,0.4
   c-1.8-1.5-3.6-3.1-5.3-4.7C171.9,55.7,171.7,55.5,171.9,55.3z"
        />
        <path
          class="st0"
          d="M184.7,66.2c1,1.3,2.2,2.7,3.3,4.2c0.4,0.5,0.7,1.1,0,1.6s-0.8-0.3-1.1-0.7c-1.1-1.4-2.2-2.8-3.3-4.2
   c-0.3-0.4-1.3-0.6-0.5-1.4S184.1,65.6,184.7,66.2z"
        />
        <path
          class="st0"
          d="M198.5,89.5c0.4,0.8,0.8,1.7,1.1,2.6c0.5,1.3,1,2.7,1.5,4c0.1,0.4,0.3,0.8-0.4,1c-0.4,0.1-0.7,0.2-0.9-0.3
   c-0.9-2.2-1.8-4.3-2.7-6.5c-0.1-0.2-0.1-0.5,0.2-0.6c0.1,0,0.1-0.1,0.2-0.1C197.8,89.5,198.2,89.2,198.5,89.5z"
        />
        <path
          class="st0"
          d="M205.4,115.8c0.1,0.7,0.1,0.9,0.1,1.2c-0.1,2.2-0.3,4.4-0.5,6.6c0,0.5-0.2,0.8-0.8,0.7s-0.7-0.3-0.7-0.8
   c0.2-2.3,0.3-4.5,0.5-6.8c0-0.3,0.1-0.6,0.5-0.6C204.9,116,205.2,115.9,205.4,115.8z"
        />
        <path
          class="st0"
          d="M195.2,82.9c-0.6,0.5-0.9,0.3-1.2-0.3c-1-1.8-2.1-3.5-3-5.3c-0.2-0.4-0.6-0.8,0-1.3s0.9-0.1,1.2,0.5
   c1,1.8,2,3.5,3,5.3C195.4,82.1,195.7,82.5,195.2,82.9z"
        />
        <path
          class="st0"
          d="M56.1,160.7c0.2,0.3,0.2,0.5,0.4,0.7c1.2,1.8,2.4,3.6,3.6,5.4c0.2,0.4,0.5,0.7,1,0.3c0.4-0.2,0.5-0.7,0.3-1
   c0-0.1-0.1-0.1-0.1-0.2c-1.2-1.8-2.4-3.6-3.6-5.4c-0.2-0.3-0.4-0.7-0.8-0.4C56.5,160.3,56.4,160.5,56.1,160.7z"
        />
        <path
          class="st0"
          d="M53.8,155.9c0.3-0.2,1-0.3,0.7-1.1c-1-2-1.9-4-2.9-6.1c-0.2-0.5-0.5-0.6-1-0.4s-0.7,0.3-0.5,0.8
   c1,2.1,2,4.3,3,6.4C53.3,155.8,53.4,156,53.8,155.9z"
        />
        <path
          class="st0"
          d="M48.3,143.7c0.6-0.2,1.2-0.3,0.9-1c-0.7-2.2-1.4-4.4-2.1-6.6c-0.1-0.4-0.3-0.6-0.8-0.4
   c-0.3,0.2-0.8,0-0.6,0.6c0.7,2.3,1.4,4.7,2.2,7C48,143.6,48.1,143.8,48.3,143.7z"
        />
        <path
          class="st0"
          d="M43.5,108.1c0,0.2-0.1,0.4-0.1,0.7c-0.3,2.1-0.6,4.2-0.9,6.3c-0.1,0.6,0,0.9,0.7,1s0.8-0.2,0.9-0.8
   c0.3-2.2,0.6-4.3,0.9-6.5c0-0.3,0.2-0.8-0.4-0.8C44.3,107.9,43.9,107.7,43.5,108.1z"
        />
        <path
          class="st0"
          d="M71.9,57.8c-0.3-0.3-0.5-1.1-1.2-0.4l-4.8,4.8c-0.3,0.3-0.4,0.6,0,0.9s0.5,0.7,0.9,0.3c1.7-1.7,3.3-3.4,5-5.1
   C71.9,58.2,72.1,58,71.9,57.8z"
        />
        <path
          class="st0"
          d="M59.7,69.5c-0.9,1.3-2,2.8-3,4.4c-0.3,0.5-0.6,1.1,0.1,1.6s0.8-0.4,1.1-0.8c1-1.4,2-2.9,3-4.4
   c0.3-0.4,1.2-0.7,0.5-1.4S60.3,68.9,59.7,69.5z"
        />
        <path
          class="st0"
          d="M47.5,93.7c-0.3,0.9-0.7,1.7-0.9,2.6c-0.4,1.4-0.9,2.7-1.3,4.1c-0.1,0.4-0.2,0.8,0.4,1
   c0.4,0.1,0.7,0.1,0.9-0.3c0.8-2.2,1.5-4.5,2.2-6.7c0.1-0.2,0-0.5-0.2-0.7c0,0-0.1,0-0.1,0C48.2,93.6,47.9,93.3,47.5,93.7z"
        />
        <path
          class="st0"
          d="M43.1,121.9c0,0.4,0,0.8,0,1.2c0.3,2.2,0.7,4.4,1,6.5c0.1,0.5,0.2,0.8,0.9,0.7s0.8-0.3,0.7-0.9
   c-0.4-2.2-0.7-4.5-1-6.7c0-0.3-0.2-0.6-0.6-0.6C43.8,122,43.4,121.9,43.1,121.9z"
        />
        <path
          class="st0"
          d="M50.4,86.8c0.6,0.5,0.9,0.2,1.2-0.4c0.9-1.8,1.8-3.6,2.7-5.5c0.2-0.4,0.5-0.9-0.1-1.3s-0.9,0-1.2,0.6
   c-0.9,1.8-1.8,3.6-2.7,5.5C50.1,86.1,49.9,86.5,50.4,86.8z"
        />
      </g>
      <g id="Camada_9">
        <path
          class="st0"
          d="M151.9,31.7H90.3c-5.5,0-10,4.5-10,10v80c0,5.5,4.5,10,10,10h61.5c5.5,0,10-4.5,10-10v-80
   C161.9,36.2,157.4,31.7,151.9,31.7C151.9,31.7,151.9,31.7,151.9,31.7z M156.5,126.1c-1.2,0.7-2.6,1-3.9,0.8
   c-5.7-0.5-57.1-0.3-61.4-0.3c-1.5,0.1-3.1-0.3-4.3-1.2c-1.9-1.6-1.8-4.5-1.7-7.7c0.1-4.1-0.3-8.2,0-12.3c0.1-1.2,0-5.8,0-15.1
   c-0.1-15.7,0.9-31.5-0.1-47.2c-0.1-1.4-0.3-5.2,2-7c1.8-1.4,4.3-0.8,5.2-0.8c16.2,0.1,32.5,0.3,48.7,0.2c0.9,0,3.3-0.2,4.3,1.2
   c0.5,0.7,0.5,1.4,0.5,6.5c0,2.1,0.2,2.5,0.6,2.9c0.7,0.8,1.8,0.6,5.1,0.7c4.6,0.1,5.4,0.5,5.9,0.9c1.2,1.2,1,3.1,1,3.8
   c-0.5,6.3-0.1,54.4,0.1,68.9C158.4,121.5,158.5,124.6,156.5,126.1z"
        />
        <path class="st1" d="M84.8,93.8L84.8,93.8L84.8,93.8L84.8,93.8z" />
        <path class="st2" d="M85.1,92.3v0.1V92.3L85.1,92.3z" />
        <path
          class="st2"
          d="M156.5,126.1c-1.2,0.7-2.6,1-3.9,0.8c-5.7-0.5-57.1-0.3-61.4-0.3c-1.5,0.1-3.1-0.3-4.3-1.2
   c-1.9-1.6-1.8-4.5-1.7-7.7c0.1-4.1-0.3-8.2,0-12.3c0.1-1.2,0-5.8,0-15.1c-0.1-15.7,0.9-31.5-0.1-47.2c-0.1-1.4-0.3-5.2,2-7
   c1.8-1.4,4.3-0.8,5.2-0.8c16.2,0.1,32.5,0.3,48.7,0.2c0.9,0,3.3-0.2,4.3,1.2c0.5,0.7,0.5,1.4,0.5,6.5c0,2.1,0.2,2.5,0.6,2.9
   c0.7,0.8,1.8,0.6,5.1,0.7c4.6,0.1,5.4,0.5,5.9,0.9c1.2,1.2,1,3.1,1,3.8c-0.5,6.3-0.1,54.4,0.1,68.9
   C158.4,121.5,158.5,124.6,156.5,126.1z"
        />
        <path class="st3" d="M84.8,93.8L84.8,93.8L84.8,93.8L84.8,93.8z" />
        <path class="st4" d="M84.7,93.8v0.1V93.8L84.7,93.8z" />
        <path
          class="st0"
          d="M185.8,167.8c-6.3-10.9-12.6-21.8-18.9-32.7c-1.3-2.2-2-2.4-4.3-1c-3.9,2.2-7.7,4.5-11.5,6.7
   c-1.8,1-3.2,2-2,4.5c-1.9,0-3.5,0-5.1,0c-6.9-0.1-13.8-0.4-20.6-0.2c-5.7,0.1-11.1,1.4-14.5,6.7c-0.3,0.4-0.7,0.6-1.2,0.6
   c-3.4-1-6.5-2.6-9.3-4.8c1.8-3.8,1.8-3.9-1.7-5.8c-3.9-2.2-7.7-4.4-11.6-6.6c-1.6-1-2.7-0.6-3.6,1c-2,3.6-4.2,7.2-6.2,10.8
   l-12.9,22.3c-0.8,1.3-1,2.5,0.5,3.4c4.5,2.6,9.1,5.3,13.7,7.9c0.9,0.7,2.2,0.5,2.9-0.4c0.1-0.2,0.2-0.3,0.3-0.5
   c0.3-0.4,0.6-0.8,1-1.2c0.6,0.6,1,1.2,1.4,1.6c1.3,1.3,2.1,2.4,1.1,4.5c-1.2,2.8-0.2,6,2.5,7.6c1.4,0.7,2.8,1.2,4.3,1.6
   c0.1,5.6,2.4,7.8,8.3,8c1.5,6.1,2.3,6.8,8.9,7.4c0.1,0.6,0.2,1.1,0.3,1.7c1.1,4.9,7.1,7.1,10.8,3.8c2-1.8,3.9-2.4,6.4-1.7
   c0.8,0.2,1.6,0.3,2.4,0.4c6.3,0.8,7.8-0.1,9.7-5.5c2.7,0.9,5.6,0.2,7.6-1.8c1.6-1.7,2.4-4.1,2-6.5c2.1,0.5,4.3-0.1,6-1.5
   c1.9-1.8,2.6-4.4,1.8-6.8c0.1-0.1,0.3-0.1,0.4-0.1c4.7,0.5,5.4-0.7,6.6-1.5c2-1.3,2.4-4.6,2.2-6.6c-0.1-1.2-0.5-1.4-0.3-2.1
   c0.3-1.5,2-2.4,3.3-2.9c0.6-0.3,1.4-0.3,2,0c0.8,0.4,0.9,1.2,1.6,1.4c1,0.4,2.2-0.7,2.8-1.1c4-2.3,7.9-4.6,12-6.8
   C186.5,170.7,186.8,169.5,185.8,167.8z M78.3,175.2c-3.8-1.2-6.8-4.2-8-8l14-26c3.7-0.3,7.3,1.7,9,5c-2.5,5.2-5.2,10.6-8,16
   C83,166.7,80.7,171,78.3,175.2z M87.9,190.1c-0.5-0.3-0.9-0.7-1.2-1.2c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.2
   c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3
   c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.2,0.1-0.4c0.8-2.4,4.6-5.5,7.8-5.5
   c1.1,0.7,2,1.6,2.8,2.6c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4
   c0.1,1.3-0.4,2.5-1.3,3.4c-1,1.1-2.2,2-3.4,2.9C91.2,191.1,89.3,191.1,87.9,190.1z M97.3,199.2h-0.3h-0.4h-0.2
   c-0.5-0.1-0.9-0.3-1.3-0.6l-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-1.6-1.9-1.4-4.4,0.7-6.3c1.5-1.3,2.9-2.6,4.4-3.9s3-2.6,4.5-3.8
   c0.9-0.9,2.2-1.3,3.4-1.1c0.5,0.1,0.9,0.3,1.3,0.6l0.2,0.1c1.6,1.3,1.6,3.8,1.6,4.2c-0.2,0.9-0.4,1.8-0.8,2.7
   c-1.6,3.5-4.8,4.3-9.1,7.8C99.6,198.9,98.5,199.2,97.3,199.2z M109.4,205.2c-1,1-2.4,1.5-3.9,1.4c-0.3,0-0.5-0.1-0.8-0.2l0,0
   c-0.3-0.1-0.5-0.2-0.8-0.4c-0.3-0.2-0.5-0.4-0.7-0.6c-0.1-0.1-0.2-0.2-0.3-0.4c-1.7-1.9-1.4-4.4,0.9-6.4s4.7-4.1,7.1-6.1
   c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.2l0.2-0.1l0.1-0.1l0.2-0.1l0.3-0.1c0.2-0.1,0.5-0.2,0.7-0.2c0.1,0,0.2,0,0.2,0
   c0.2,0,0.4,0,0.7,0h0.3c0.4,0.1,0.9,0.3,1.2,0.6c0.8,0.7,1.5,1.5,2.1,2.4l0.1,0.1v0.1c0.3,0.5,0.4,1.2,0.2,1.8v0.1l0,0
   c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.4-0.5,0.8-0.7,1.2l-0.1,0.1c-0.2,0.4-0.5,0.8-0.8,1.1v0.1c-2.1,2-4.3,3.8-6.5,5.6L109.4,205.2z
    M122.3,208.5c-1.6,1.9-3.4,3.4-5.5,4.7c-1.9,1.1-4.3,0.6-5.4-1.3c-0.2-0.2-0.3-0.5-0.4-0.7c-0.2-0.6-0.3-1.2-0.3-1.8
   c0.1-0.7,0.3-1.5,0.7-2.1c0.2-0.3,0.4-0.6,0.7-0.9c0.2-0.3,0.5-0.6,0.8-0.9c2-2.1,4.9-3.8,6.7-3.6
   C122.6,202.3,124.3,206.1,122.3,208.5z M158,189c-0.2,0-0.4,0-0.7-0.1c-1.9-0.3-3.7-1-5.3-2c-4.9-3-9.7-6.2-14.7-9.2
   c-0.2-0.1-0.4-0.2-0.6-0.2l0,0h-0.2c-0.5,0-1.1,0-1.6,0c0.4,0.8,0.6,1.8,1.2,2.2c4.6,3.1,9.3,6,14,9c2.3,1.5,2.8,3.2,1.5,5.8
   c-0.7,1.5-2.1,2.6-3.7,2.8c-0.2,0-0.5,0-0.7,0h-0.3c-0.4-0.1-0.8-0.2-1.1-0.4c-2.2-1.1-4.3-2.4-6.4-3.6c-2.9-1.7-5.8-3.6-8.8-5.2
   c-0.2-0.1-0.4-0.2-0.6-0.2h-0.2c-0.5,0-1.2,0-1.7,0c0.4,0.8,0.7,1.9,1.3,2.3c4.1,2.6,8.2,5,12.4,7.4c2,1.1,2.2,2.6,1.9,4.5
   c-0.2,1.8-1.8,3.1-3.6,2.9c-0.4,0-0.9-0.1-1.3-0.1c-0.9-0.2-1.7-0.4-2.5-0.8c-0.6-0.3-1.2-0.6-1.8-0.8c-1.8-0.8-3.5-1.8-5.2-2.6
   l-0.1-0.1l-0.4-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4-0.1-0.8,0.1-1.1,0.8c-0.2,0.4,0.6,1.5,1.3,1.9
   c0,0,0.1,0.1,0.1,0.1c1.6,1,3.4,1.7,5.1,2.6c0.5,0.2,1,0.4,1.5,0.7l0,0c0.5,0.4,0.7,1,0.5,1.6c-0.4,1.9-1.1,3.6-3.3,3.6
   c-2,0-4-0.2-6.2-0.4h-0.1l-1.2-0.1c1.9-4.2,1.6-7.1-0.9-9.3l-0.5-0.4c-0.7-0.5-1.5-1-2.4-1.4l-0.6-0.3c0.8-6.7-0.2-8.3-6.4-10.3
   c0.3-3.3-0.5-6.2-3.7-7.8c-1.2-0.7-2.7-0.9-4.1-0.7c-0.4,0.1-0.8,0.2-1.2,0.3c-1.7,0.8-3.3,2-4.6,3.4c-0.1-0.5-0.2-0.9-0.4-1.4
   c-0.1-0.2-0.2-0.5-0.2-0.7l-0.1-0.2c-0.2-0.6-0.5-1.1-0.9-1.6l-0.3-0.4l-0.3-0.3c-0.3-0.3-0.6-0.6-1-0.8l-0.4-0.2l0,0
   c-0.6-0.3-1.2-0.5-1.8-0.7c-1.2-0.3-2.5-0.2-3.8,0.2c-0.3,0.1-0.5,0.2-0.8,0.3l0,0c-0.9,0.5-1.7,1.1-2.5,1.7l-0.2,0.1
   c-0.2,0.2-0.4,0.3-0.6,0.5l-0.6-0.6l-0.2-0.2c-1-1.1-2-2.2-2.9-3.4c-0.2-0.4-0.2-0.9,0-1.4c4.7-8.2,9.5-16.4,14.3-24.7l0.2-0.3
   l0,0l0,0l0.3,0.2l8.7,4.3l0.2,0.1l0.6,0.3c-1.3,1.7-2.6,3.4-3.8,5.1c-0.6,0.9-1,2-1.2,3.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6
   c0.2,1.7,1,3.2,2.4,4.3c3,2.6,7.4,2.6,10.4,0.1c2.2-2,4.3-4,6.3-6.2c3.5-3.6,4.3-4,8.7-1.4c4.7,2.8,9.2,5.9,13.7,9
   c4.8,3.3,9.4,6.7,14.1,10c2,1.2,3.1,3.6,2.6,5.9c-0.2,1.5-0.9,2.5-2,2.9c-0.3,0.1-0.5,0.1-0.8,0.1L158,189z M165.9,175.3
   c-1.2,1.5-2.5,2.9-3.9,4.2c-8.6-6-16.9-11.9-25.2-17.6c-0.6-0.4-1.1-0.7-1.7-1.1s-1.1-0.7-1.7-1l-1.8-1l-1.8-1
   c-3.2-1.7-5.4-1.4-8.1,1.1s-5,4.9-7.4,7.4c-0.5,0.5-1.1,1-1.8,1.3c-1.1,0.5-2.4,0.5-3.6,0.2l-0.5-0.1c-3.3-1-4.3-4-2.3-6.7
   s4.1-5.6,6.2-8.4c1.7-2.6,4.5-4.2,7.6-4.5c2.4-0.2,4.9-0.4,7.3-0.5c1.2-0.1,2.4-0.1,3.7-0.2s2.4-0.1,3.7-0.1
   c2.8,0,5.6,0.1,8.4,0.1h0.1c1.2,0,2.4,0.1,3.6,0.2l3.7,0.2c0.8,0.1,1.6,0.5,2.1,1.2c4.6,7.9,9.2,15.7,13.6,23.7
   C166.4,173.4,166.4,174.4,165.9,175.3L165.9,175.3z M184.7,169.3l-3.3,1.9l-9.9,5.8l-19.3-33.4l9.9-5.7l3.3-1.9
   c3.2,5.6,6.5,11.2,9.7,16.7L184.7,169.3L184.7,169.3z"
        />
        <path
          class="st5"
          d="M97,145.2c-3.1,4.8-6.2,9.9-9.3,15.2c-3.5,5.9-6.8,11.8-9.8,17.4L65.1,171l19.4-33.4c1,0.6,2.1,1.2,3.1,1.9
   C90.8,141.3,93.9,143.3,97,145.2z"
        />
        <path
          class="st5"
          d="M124.5,209.2c1.6-4.6,0.3-7.4-4.2-9.6c-0.4-0.2-0.7-0.7-0.6-1.2c0.6-5.8-0.4-7.4-5.6-9.2
   c-0.5-0.2-0.8-0.7-0.8-1.2c0.1-2.9-0.8-5.5-3.7-6.9c-1-0.5-2-0.8-3.2-0.8c-3.7,0.2-5.8,4.9-6.8,4.4c-0.4-0.2,0.1-0.8-0.3-2.4
   c-0.4-1.3-1.1-2.4-2.1-3.3l-0.1-0.1c-0.8-0.6-1.7-1-2.6-1.2c-0.7-0.2-1.5-0.2-2.2-0.2c-2.8,0.4-3.4,2.9-5.3,2.7
   c-1.1-0.2-2-0.9-2.6-1.8c-0.6-0.5-1-1.2-1.1-2c-0.2-0.4-0.2-0.9,0-1.4c4.6-8.1,9.2-16.1,13.8-24.2c0.3-0.5,1-0.7,1.5-0.5l7.6,3.8
   c0.6,0.3,0.8,1,0.5,1.6c0,0.1-0.1,0.1-0.1,0.2c-1,1.3-2,2.6-2.9,4c-2,2.7-1.4,6.5,1.2,8.5c3,2.6,7.3,2.6,10.3,0.1
   c2.2-2,4.3-4,6.3-6.2c3.5-3.7,4.3-4,8.7-1.4c4.7,2.8,9.1,5.9,13.6,9.1c4.7,3.3,9.4,6.7,14,10.1c1,0.7,1.8,1.7,2.3,2.8
   c0.4,1.1,0.5,2.3,0.2,3.4c-0.1,0.9-0.6,1.7-1.3,2.3c-0.7,0.4-1.5,0.5-2.3,0.3c-1.9-0.3-3.6-1-5.3-2c-4.9-3-9.7-6.2-14.6-9.3
   c0,0-0.1-0.1-0.2-0.1c-0.6-0.2-1.3,0-1.6,0.7c-0.2,0.5-0.1,1,0.3,1.3l0.2,0.1c4.6,3.1,9.3,6.1,13.9,9c2.3,1.5,2.8,3.2,1.5,5.8
   s-3.5,3.5-5.8,2.4s-4.2-2.4-6.3-3.7c-2.9-1.7-5.8-3.6-8.7-5.2c-0.1-0.1-0.2-0.1-0.4-0.1c-0.6-0.1-1.3,0.3-1.4,0.9
   c-0.1,0.3,0,0.6,0.2,0.9c0.1,0.2,0.3,0.3,0.4,0.4c4.1,2.6,8.1,5.1,12.3,7.4c2,1.1,2.2,2.6,1.9,4.5c-0.2,1.8-1.8,3.1-3.6,2.9
   c-1.3-0.1-2.6-0.4-3.8-0.9c-2.4-1.1-4.8-2.4-7.1-3.6c-0.9-0.5-2-1.3-2.6,0.1c-0.2,0.4,0.7,1.6,1.4,2c1.6,1,3.4,1.7,5.1,2.6
   c1,0.5,2.4,0.6,2,2.4s-1,3.6-3.2,3.6c-1.9,0-3.8-0.2-5.9-0.4c-0.7-0.1-1.1-0.6-1.1-1.3C124.4,209.4,124.4,209.3,124.5,209.2z"
        />
        <path
          class="st5"
          d="M162,179.5c-8.6-6-16.9-11.9-25.2-17.6c-2.2-1.5-4.5-2.8-6.9-4c-3.2-1.7-5.4-1.4-8.1,1.1s-5,4.9-7.4,7.4
   c-1.6,1.6-3.9,2.1-6,1.3c-3.3-1-4.3-4-2.3-6.7s4.1-5.6,6.2-8.4c1.7-2.6,4.5-4.2,7.6-4.5c4.9-0.4,9.7-0.7,14.6-0.8
   c5.2-0.1,10.5,0.2,15.8,0.5c0.8,0.1,1.6,0.5,2.1,1.2c4.6,7.9,9.2,15.7,13.6,23.7c0.4,0.9,0.3,1.9-0.1,2.7
   C164.6,176.8,163.4,178.2,162,179.5z"
        />
        <path
          class="st5"
          d="M171.6,176.9l-19.9-34.7l12.9-6.6c6.6,11.4,13.2,22.8,19.8,34.2L171.6,176.9z"
        />
        <path
          class="st5"
          d="M110.9,187.2c0.6,4.1-5.1,7.7-10.4,10.9c-1.1,0.9-2.6,1.2-4,1c-0.8-0.2-1.6-0.7-2.2-1.3
   c-1.6-1.9-1.4-4.4,0.7-6.3c2.9-2.6,5.9-5.1,8.9-7.7c0.9-0.9,2.2-1.3,3.4-1.1c0.5,0.1,0.9,0.3,1.3,0.6
   C109.9,184.2,110.7,185.6,110.9,187.2z"
        />
        <path
          class="st5"
          d="M116.1,199.4c-2,2.2-4.2,4.2-6.7,5.8c-2.4,1.9-4.8,1.9-6.5-0.1s-1.4-4.4,0.9-6.4s4.7-4.1,7.1-6.1
   c0.9-0.8,2.2-1.2,3.4-1c0.5,0.1,0.9,0.3,1.3,0.6c1.4,0.8,2.2,2.3,2.4,3.9C118,196.6,117.8,197.3,116.1,199.4z"
        />
        <path
          class="st5"
          d="M110.7,209.4c0-3,5.9-8,8.9-7.5s4.8,4.3,2.8,6.6c-1.6,1.9-3.4,3.4-5.5,4.7c-2,1.1-4.5,0.5-5.6-1.5
   C110.8,211,110.6,210.2,110.7,209.4z"
        />
        <path
          class="st5"
          d="M96.8,182.6c0.2,0.4,0.4,0.8,0.5,1.3c0.1,1.3-0.4,2.5-1.3,3.4c-1,1.1-2.2,2-3.4,2.9c-1.4,1.1-3.4,1.1-4.8,0
   c-0.7-0.4-1.2-1.1-1.5-1.8c-0.3-0.8-0.4-1.6-0.2-2.5c0.6-3.3,5.5-7.1,8.3-5.8C95.5,180.6,96.3,181.5,96.8,182.6z"
        />
        <path
          class="st6"
          d="M122.2,50.2h30.3c0.5,0,0.8,0.4,0.8,0.8v1.3c0,0.5-0.4,0.8-0.8,0.8h-30.3c-0.5,0-0.8-0.4-0.8-0.8V51
   C121.3,50.6,121.7,50.2,122.2,50.2z"
        />
        <path
          class="st6"
          d="M122.2,56.2h30.3c0.5,0,0.8,0.4,0.8,0.8v1.3c0,0.5-0.4,0.8-0.8,0.8h-30.3c-0.5,0-0.8-0.4-0.8-0.8V57
   C121.3,56.6,121.7,56.2,122.2,56.2z"
        />
        <path
          class="st6"
          d="M122.2,62.2h30.3c0.5,0,0.8,0.4,0.8,0.8v1.3c0,0.5-0.4,0.8-0.8,0.8h-30.3c-0.5,0-0.8-0.4-0.8-0.8V63
   C121.3,62.6,121.7,62.2,122.2,62.2z"
        />
        <path
          class="st6"
          d="M152.3,108v1.3c0,0.5-0.7,0.8-1.5,0.8H94.9c-0.8,0-1.5-0.4-1.5-0.8V108c0-0.5,0.7-0.8,1.5-0.8h55.9
   C151.7,107.2,152.3,107.6,152.3,108z"
        />
        <path
          class="st6"
          d="M152.3,114v1.3c0,0.5-0.7,0.8-1.5,0.8H94.9c-0.8,0-1.5-0.4-1.5-0.8V114c0-0.5,0.7-0.8,1.5-0.8h55.9
   C151.7,113.2,152.3,113.6,152.3,114z"
        />
        <path
          class="st6"
          d="M152.3,120v1.3c0,0.5-0.7,0.8-1.5,0.8H94.9c-0.8,0-1.5-0.4-1.5-0.8V120c0-0.5,0.7-0.8,1.5-0.8h55.9
   C151.7,119.2,152.3,119.6,152.3,120z"
        />
        <path
          class="st6"
          d="M94.2,81.2h57.3c0.5,0,0.8,0.4,0.8,0.8v1.3c0,0.5-0.4,0.8-0.8,0.8H94.2c-0.5,0-0.8-0.4-0.8-0.8V82
   C93.3,81.6,93.7,81.2,94.2,81.2z"
        />
        <path
          class="st6"
          d="M94.2,88.2h57.3c0.5,0,0.8,0.4,0.8,0.8v1.3c0,0.5-0.4,0.8-0.8,0.8H94.2c-0.5,0-0.8-0.4-0.8-0.8V89
   C93.3,88.6,93.7,88.2,94.2,88.2z"
        />
        <path
          class="st6"
          d="M94.2,96.2h57.3c0.5,0,0.8,0.4,0.8,0.8v1.3c0,0.5-0.4,0.8-0.8,0.8H94.2c-0.5,0-0.8-0.4-0.8-0.8V97
   C93.3,96.6,93.7,96.2,94.2,96.2z"
        />
        <path
          class="st6"
          d="M93.5,65.7c-0.2-1.7,0-3.4,0.7-5c2.2-5.4,8.4-7.9,13.7-5.6c3.2,1.3,5.5,4.1,6.2,7.5c0.1,1.1,0.2,2.1,0.2,3.2
   L93.5,65.7z M94.4,64.6h19c0-5.2-4.3-9.5-9.5-9.5S94.4,59.3,94.4,64.6z"
        />
        <path
          class="st6"
          d="M104.6,40.3c1.2,0.2,2.3,0.7,3.3,1.4c2.9,2.2,3.4,6.3,1.2,9.2c-1.3,1.7-3.3,2.6-5.4,2.6
   c-3.6-0.1-6.4-3.2-6.3-6.8c0.1-3.1,2.4-5.7,5.5-6.2c0.1,0,0.2-0.1,0.2-0.1L104.6,40.3z M104,41.3c-3.1,0-5.6,2.4-5.6,5.5
   c0,3.1,2.4,5.6,5.5,5.6s5.6-2.4,5.6-5.5c0,0,0,0,0-0.1C109.4,43.8,107,41.4,104,41.3L104,41.3z"
        />
        <path
          class="st0"
          d="M94.4,64.6c0-5.2,4.3-9.5,9.5-9.5s9.5,4.3,9.5,9.5H94.4z"
        />
        <path
          class="st5"
          d="M104,41.3c3.1,0,5.5,2.6,5.5,5.6c0,3.1-2.6,5.5-5.6,5.5c-3,0-5.5-2.5-5.5-5.5C98.4,43.8,100.9,41.3,104,41.3
   C104,41.3,104,41.3,104,41.3z"
        />
      </g>
    </g>
  </svg>
);
export const talentsAec = (props) => (
  <svg
    version="1.1"
    id="talentsAec_Camada"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 248 248"
  >
    <style type="text/css">
      {`#talentsAec_Camada .st0{fill:${theme.colors.defaultColor};} #talentsAec_Camada .st1{fill:#FFFFFF;} #talentsAec_Camada .st2{fill:#FFFFFF;stroke:${theme.colors.defaultColor};stroke-width:2;stroke-miterlimit:10;} #talentsAec_Camada .st3{fill:#FFFFFF;stroke:${theme.colors.defaultColor};stroke-miterlimit:10;}`}
    </style>
    <g id="Camada_1_1_">
      <g id="Camada_4">
        <path
          class="st0"
          d="M48.6,105.1c0.5-0.2,0.4-0.7,0.6-1.1s-0.1-1.2,0.8-0.9c0.7,0.2,0.8,0.7,0.7,1.3c-0.5,2.1-1,4.2-1.5,6.3
			c-0.3,1.2-1.1,0.4-1.7,0.4L48.6,105.1z"
        />
        <path
          class="st0"
          d="M189.7,64.7l0.5,0.4c1.6,1.4,3.2,2.9,4.8,4.3c0.4,0.4,0.6,0.7,0.1,1.2s-0.8,0.4-1.2-0.1
			c-1.6-1.5-3.3-3-4.9-4.4c-0.2-0.2-0.7-0.4-0.2-0.9C189,65,189.1,64.6,189.7,64.7z"
        />
        <path
          class="st0"
          d="M57.1,85.8c-0.5-0.2-1-0.4-0.5-1.2c1.1-2,2.3-3.9,3.4-5.9c0.3-0.5,0.5-0.6,1-0.2c0.3,0.2,0.7,0.4,0.4,0.9
			c-1.2,2.1-2.4,4.2-3.6,6.2C57.7,85.9,57.4,86,57.1,85.8C57.2,85.8,57.1,85.8,57.1,85.8z"
        />
        <path
          class="st0"
          d="M207.5,170c-0.2,0.3-0.2,0.5-0.4,0.7c-1.2,1.8-2.5,3.7-3.7,5.5c-0.3,0.4-0.5,0.7-1,0.3s-0.6-0.7-0.2-1.2
			c1.2-1.8,2.5-3.6,3.7-5.5c0.2-0.3,0.4-0.7,0.9-0.4C207,169.6,207.2,169.8,207.5,170z"
        />
        <path
          class="st0"
          d="M64.3,74.4c-0.3-0.3-1.2-0.4-0.6-1.2l4.3-5.5c0.3-0.3,0.5-0.5,0.9-0.1s0.7,0.5,0.4,0.9c-1.5,2-3,3.9-4.6,5.8
			C64.7,74.4,64.5,74.6,64.3,74.4z"
        />
        <path
          class="st0"
          d="M194,187.1c-0.2-0.2-0.5-0.4-0.7-0.6s-0.2-0.5,0-0.8l4.9-5.3c0.4-0.4,0.7,0,0.9,0.2s0.5,0.5,0.1,0.9
			c-1.6,1.7-3.1,3.5-4.7,5.2C194.4,186.8,194.2,187,194,187.1z"
        />
        <path
          class="st0"
          d="M209.8,165.2c-0.4-0.2-1.1-0.3-0.7-1.1c1-2,2-4.1,2.9-6.1c0.2-0.5,0.5-0.6,1-0.4c0.3,0.2,0.7,0.3,0.5,0.8
			c-1,2.2-2.1,4.3-3.1,6.5C210.3,165.1,210.2,165.3,209.8,165.2z"
        />
        <path
          class="st0"
          d="M154.8,46.6c1.6,0.5,3.4,1,5.2,1.6c0.6,0.2,1.2,0.5,0.9,1.3s-0.9,0.2-1.4,0.1c-1.8-0.5-3.5-1-5.3-1.5
			c-0.5-0.2-1.5,0.3-1.3-0.9S154,46.5,154.8,46.6z"
        />
        <path
          class="st0"
          d="M141.5,45.2c1.7,0.1,3.6,0.2,5.5,0.4c0.6,0.1,1.3,0.2,1.1,1.1s-0.9,0.4-1.4,0.4c-1.8-0.1-3.6-0.2-5.5-0.4
			c-0.5,0-1.4,0.6-1.4-0.6S140.7,45.2,141.5,45.2z"
        />
        <path
          class="st0"
          d="M178.6,56.8c0.8,0.5,1.6,1,2.4,1.5c1.2,0.8,2.4,1.6,3.6,2.5c0.4,0.3,0.7,0.5,0.2,1.1
			c-0.3,0.3-0.6,0.6-0.9,0.3c-2-1.3-4-2.6-6-3.9c-0.4-0.2-0.3-0.5-0.1-0.8S178.1,56.6,178.6,56.8z"
        />
        <path
          class="st0"
          d="M198.9,73.8c0.3,0.2,0.6,0.5,0.9,0.8c1.4,1.7,2.8,3.4,4.2,5.1c0.3,0.4,0.5,0.7-0.1,1.1s-0.8,0.3-1.1-0.1
			c-1.4-1.8-2.9-3.5-4.3-5.3c-0.2-0.3-0.3-0.5-0.1-0.8C198.6,74.3,198.8,74,198.9,73.8z"
        />
        <path
          class="st0"
          d="M51.3,96.8c0.7-1.8,1.5-3.9,2.3-6c0.2-0.4,0.5-0.8,1-0.4c0.4,0.2,0.6,0.6,0.4,1c0,0,0,0.1,0,0.1
			c-0.8,2.1-1.6,4.3-2.4,6.4c-0.2,0.5-0.5,0.5-0.9,0.3C51,98.1,51.1,97.5,51.3,96.8z"
        />
        <path
          class="st0"
          d="M215.4,152.8c-0.7-0.2-1.2-0.3-1-1c0.7-2.2,1.4-4.5,2.1-6.7c0.1-0.4,0.3-0.6,0.8-0.4c0.3,0.1,0.9,0,0.7,0.6
			c-0.7,2.4-1.4,4.7-2.1,7.1C215.8,152.6,215.6,152.9,215.4,152.8z"
        />
        <path
          class="st0"
          d="M65.4,178.6c0,0.4-0.4,0.5-0.6,0.6c-0.4,0.3-0.7,0.1-0.9-0.2c-1.2-1.9-2.5-3.8-3.7-5.7
			c-0.3-0.4-0.3-0.8,0.2-1.1c0.3-0.2,0.7-0.4,1,0l3.9,6C65.3,178.4,65.3,178.5,65.4,178.6z"
        />
        <path
          class="st0"
          d="M75.1,188.9l-0.6,0.7c-0.3,0.4-0.7,0.2-1-0.1c-1.5-1.7-3.1-3.5-4.6-5.2c-0.3-0.3-0.4-0.6,0.1-0.9
			c0.3-0.2,0.7-0.5,1-0.2c1.7,1.8,3.3,3.7,4.9,5.5C75,188.8,75,188.9,75.1,188.9z"
        />
        <path
          class="st0"
          d="M87.9,198.2c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.5-0.6,0.4-0.9,0.2l-5.8-3.9c-0.3-0.2-0.5-0.5-0.1-0.9
			s0.5-0.7,0.9-0.4c2.1,1.3,4.1,2.7,6.2,4.1L87.9,198.2z"
        />
        <path
          class="st0"
          d="M54.3,160.1c0.8-0.2,0.9,0.1,1.1,0.5c0.9,2,1.8,3.9,2.7,5.8c0.2,0.5,0.3,0.9-0.3,1.3s-0.9-0.1-1.1-0.6
			c-0.9-2-1.8-3.9-2.7-5.8C53.7,160.7,53.8,160.1,54.3,160.1z"
        />
        <path
          class="st0"
          d="M173.4,54.5c-0.2,0.8-0.6,0.8-1.3,0.5c-1.9-0.9-3.8-1.8-5.6-2.7c-0.4-0.2-1-0.4-0.7-1.1s0.8-0.6,1.4-0.3
			c1.9,0.9,3.8,1.8,5.6,2.6C173.2,53.8,173.6,53.9,173.4,54.5z"
        />
      </g>
      <ellipse class="st0" cx="135" cy="126" rx="72" ry="71" />
      <path class="st1" d="M193,199l-44-7l6-25h38V199z" />
      <path
        class="st0"
        d="M216.8,104.5c0-5.6-2-7.5-7.5-7.5h-51.1c-4.7,0-6.9,2.2-6.9,6.8c0,10.7,0,21.4,0,32.1c0,5.1,1.1,6.6,6.4,8
		c0,3.2-0.1,6.4,0,9.5c0.2,0.8,0.4,1.6,0.8,2.3c0.8-0.2,1.5-0.5,2.2-0.9c4-3.2,7.8-6.6,11.8-9.7c1.1-0.9,2.4-1.3,3.8-1.4
		c11.1-0.1,22.3-0.1,33.4-0.1c4.7,0,7-2.2,7-7C216.8,125.9,216.8,115.2,216.8,104.5z M214.8,136.5c0,3.8-1.2,5.1-5,5.1
		c-11.2,0-22.4,0-33.6,0c-1.7-0.1-3.4,0.6-4.6,1.7c-3.7,3.2-7.6,6.4-11.9,10c0-3.5-0.1-6.4,0-9.2c0.1-1.7-0.4-2.4-2.3-2.4
		c-2.6,0-4-1.4-4-4c0-11.5,0-23,0-34.5c0-2.6,1.6-4,4.4-4c3.5-0.1,7,0,10.6,0h41.2c4.2,0,5.4,1.2,5.4,5.4L214.8,136.5z"
      />
      <path
        class="st1"
        d="M209.5,99h-41.2c-3.5,0-7,0-10.6,0c-2.8,0-4.3,1.4-4.4,4c0,11.5,0,23,0,34.5c0,2.6,1.4,4.1,4,4
		c1.9,0,2.3,0.8,2.3,2.4c-0.1,2.9,0,5.7,0,9.2c4.3-3.6,8.1-6.7,11.9-10c1.3-1.2,2.9-1.8,4.6-1.7c11.2,0.1,22.4,0,33.6,0
		c3.8,0,5-1.3,5-5.1v-32.1C214.8,100.2,213.7,99,209.5,99z M189,121c-0.4,2.7-3,4.5-5.7,4.1c-2.4-0.4-4.2-2.4-4.2-4.9
		c0-2.7,2.2-5,4.9-5c2.7,0,5,2.2,5,4.9C189.1,120.5,189,120.7,189,121z M200,125.2c-2.8-0.2-4.8-2.7-4.6-5.4
		c0.2-2.8,2.7-4.8,5.4-4.6c2.6,0.2,4.6,2.4,4.6,5c0,2.8-2.2,5-5,5C200.3,125.2,200.2,125.2,200,125.2L200,125.2z"
      />
      <path
        class="st0"
        d="M200.4,115.3c-2.8,0-5,2.2-5,5c0,2.8,2.2,5,5,5s5-2.2,5-5c0,0,0-0.1,0-0.1
		C205.3,117.5,203.1,115.3,200.4,115.3z M203.4,120.5c0,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.4-0.7,0.7-1.1,0.9l-0.2,0.1c0,0-0.1,0-0.1,0c-0.3,0.1-0.5,0.1-0.8,0.1h-0.1c-0.1,0-0.3,0-0.4,0
		c-0.1,0-0.3-0.1-0.4-0.1l-0.4-0.2h-0.1l-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.2-0.3-0.4-0.3-0.6
		c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.3-0.1-0.4l0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3v-0.1c0-0.1,0-0.3,0-0.4
		c0-0.4,0.2-0.8,0.4-1.1c0.1-0.2,0.3-0.4,0.5-0.6c0.6-0.5,1.3-0.8,2.1-0.8h0.2l0.4,0.1l0.2,0.1l0.3,0.1c0.1,0,0.2,0.1,0.2,0.1
		l0.2,0.1c0.1,0,0.1,0.1,0.2,0.2l0,0l0.1,0.1l0.1,0.1l0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
		c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3C203.3,120.3,203.3,120.4,203.4,120.5z"
      />
      <path
        class="st0"
        d="M184.1,115.3c-2.7,0-4.9,2.3-4.9,5c0,2.7,2.3,4.9,5,4.9c0.5,0,0.9-0.1,1.4-0.2c1.8-0.6,3.2-2.1,3.5-4
		c0.4-2.7-1.4-5.2-4.1-5.6C184.6,115.3,184.3,115.3,184.1,115.3z M187.1,120.3c0,0.2,0,0.4,0,0.5c-0.2,0.9-0.7,1.6-1.6,2l-0.2,0.1
		c-0.1,0-0.2,0.1-0.3,0.1l-0.2,0.1h-0.2c-0.1,0-0.3,0-0.4,0l0,0c-1.6,0-2.8-1.3-2.9-2.9c0-0.8,0.3-1.6,0.9-2.1l0.2-0.2
		c0.3-0.3,0.6-0.4,1-0.6c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.4,0,0.6,0.1c0.5,0.1,1,0.3,1.4,0.7c0.1,0.1,0.1,0.1,0.2,0.2
		c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.2,0.6,0.2,0.9L187.1,120.3z"
      />
      <path
        class="st0"
        d="M169,115.1c-2.7,0-4.9,2.3-4.9,5c0,2.7,2.3,4.9,5,4.9c0.5,0,0.9-0.1,1.4-0.2c1.8-0.6,3.2-2.1,3.5-4
		c0.4-2.7-1.4-5.2-4.1-5.6C169.5,115.2,169.3,115.1,169,115.1z M172,120.1c0,0.2,0,0.4-0.1,0.5c-0.2,0.9-0.7,1.6-1.6,2l-0.2,0.1
		c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0.1h-0.2c-0.1,0-0.3,0-0.4,0l0,0c-1.6,0-2.8-1.3-2.9-2.9c0-0.8,0.3-1.5,0.9-2.1l0.2-0.2
		c0.3-0.3,0.6-0.4,1-0.6c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.4,0,0.6,0.1c0.5,0.1,1,0.3,1.4,0.7c0.1,0.1,0.1,0.1,0.2,0.2
		c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.2,0.6,0.2,0.9L172,120.1z"
      />
      <path
        class="st1"
        d="M203.4,120.5c-0.1,1.6-1.4,2.8-3,2.7c0,0,0,0,0,0c-1.6-0.1-2.8-1.5-2.7-3.1c0.1-1.6,1.5-2.8,3.1-2.7
		C202.3,117.5,203.5,118.8,203.4,120.5C203.4,120.4,203.4,120.4,203.4,120.5z"
      />
      <path
        class="st1"
        d="M187.1,120.2c0.1,1.6-1.2,2.9-2.8,3c-0.1,0-0.2,0-0.2,0c-1.6,0-2.9-1.4-2.8-3c0-1.6,1.4-2.9,3-2.8
		C185.8,117.4,187,118.7,187.1,120.2z"
      />
      <path
        class="st1"
        d="M172.1,120.2c0.1,1.6-1.2,2.9-2.8,3c-0.1,0-0.2,0-0.2,0c-1.6,0-2.9-1.4-2.8-3c0-1.6,1.4-2.9,3-2.8
		C170.8,117.4,172,118.7,172.1,120.2z"
      />
    </g>
    <g id="Camada_2_1_">
      <path
        class="st0"
        d="M137.1,79.3c-1-11.3-6.3-20.1-16-26.1c-4.3-2.6-9.1-4.2-14.2-4.5c-0.2,0-0.4-0.1-0.6-0.1h-2.5l-0.8,0.1
		c-1.8,0.2-3.6,0.3-5.3,0.7c-17.9,4-29.1,21.7-25.1,39.6c1,4.5,2.9,8.7,5.7,12.4c0.2,0.3,0.3,0.5-0.1,0.8c-1.5,1.5-3,2.9-4.5,4.5
		c-0.3,0.3-0.5,0.6-0.7,1c-0.6-0.2-1.4-0.4-2.1-0.7c-0.5-0.2-1-0.1-1.4,0.3c-9.8,9.6-19.7,19.2-29.5,28.8c-0.9,0.7-1.6,1.7-1.9,2.8
		v0.4c0.3,1.1,0.9,2.2,1.8,2.9c1.2,1.2,2.3,2.4,3.5,3.6c0.7,0.9,1.6,1.5,2.7,1.9h0.5c1.1-0.3,2-1,2.7-1.8c1.4-1.4,2.9-2.8,4.3-4.2
		L78.7,117c0.4-0.3,0.5-0.9,0.4-1.3c-0.3-0.8-0.4-1.6-0.6-2.3c0.4-0.2,0.7-0.4,1-0.7c1.5-1.5,3.1-3,4.6-4.5c0.4-0.4,0.6-0.4,1-0.1
		c7.1,5.4,16.1,7.5,24.9,6c14.6-2.5,25.7-14.4,27.1-29.1l0.2-2.1v-1.5C137.2,80.7,137.1,80,137.1,79.3z M77.4,116.6
		c-4.9,4.8-9.9,9.7-14.8,14.5l-14.8,14.5l-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.3-0.7,0.4-1.1,0.5c-0.2-0.1-0.4-0.2-0.6-0.3
		c-0.2-0.1-0.4-0.2-0.5-0.4l0,0c-0.9-0.9-1.8-1.8-2.6-2.7s-1.8-1.8-2.6-2.7c-0.1-0.1-0.2-0.2-0.2-0.3c-0.5-0.7-0.3-1.7,0.4-2.3
		c2.8-2.8,5.7-5.6,8.5-8.3l8.5-8.3l6.5-6.3l6.4-6.3c0.2-0.3,0.6-0.3,0.9-0.2c1.1,0.2,2,0.8,2.7,1.7c0.9,1,1.8,2,2.8,2.9
		c0.4,0.4,0.8,0.9,1.1,1.4c0.1,0.3,0.3,0.7,0.3,1c0,0.1,0,0.2,0.1,0.2C77.9,116,77.7,116.4,77.4,116.6z M119.8,109.3
		c-5,2.9-10.8,4.3-16.5,4c-6.6-0.2-13-2.5-18.2-6.6c-0.7-0.5-0.8-0.5-1.4,0.1l-4.6,4.5c-0.1,0.1-0.2,0.2-0.3,0.4l-4.3-4.3
		c0.1-0.2,0.3-0.3,0.4-0.4c1.5-1.5,3-3,4.6-4.4c0.5-0.5,0.5-0.7,0.1-1.3C68.9,87.5,71.3,67.7,85,57s33.5-8.3,44.2,5.4
		s8.3,33.5-5.4,44.2C122.6,107.6,121.2,108.5,119.8,109.3L119.8,109.3z"
      />
      <path
        class="st1"
        d="M135.2,75c-3.8-17-20.6-27.7-37.6-23.9c-17,3.8-27.7,20.6-23.9,37.6c1,4.6,3,8.9,5.9,12.6
		c0.4,0.6,0.4,0.8-0.1,1.3c-1.5,1.5-3.1,3-4.5,4.5c-0.1,0.1-0.3,0.3-0.4,0.4l4.3,4.3c0.1-0.1,0.2-0.3,0.3-0.4l4.6-4.5
		c0.6-0.6,0.7-0.6,1.4-0.1c5.2,4.1,11.6,6.4,18.2,6.6c5.8,0.3,11.5-1.1,16.5-4C132,102.5,138.2,88.6,135.2,75z M103.9,107.2
		C89.7,107,78.4,95.4,78.6,81.3c0.2-14.1,11.8-25.4,25.9-25.3c14.1,0.2,25.4,11.8,25.3,25.9c0,0.1,0,0.1,0,0.2
		c-0.2,14-11.7,25.3-25.8,25.1C104,107.2,103.9,107.2,103.9,107.2L103.9,107.2z"
      />
      <path
        class="st0"
        d="M46.2,146.5c-0.4-0.2-0.8-0.4-1.1-0.7c-1.8-1.8-3.5-3.6-5.2-5.4c-0.7-0.6-0.7-1.7-0.1-2.3
		c0.1-0.1,0.2-0.2,0.2-0.2l17-16.7c4.3-4.2,8.6-8.4,12.9-12.6c0.2-0.3,0.6-0.3,0.9-0.2c1.1,0.2,2,0.8,2.7,1.7c0.9,1,1.8,2,2.8,2.9
		c0.7,0.7,1.2,1.5,1.4,2.5c0.2,0.4,0,0.9-0.3,1.2l-29.7,29C47.3,146.1,46.8,146.4,46.2,146.5z M45,142.7c0.3,0.4,0.6,0.7,0.9,1
		c0.7,0.7,0.8,0.7,1.5,0l26.5-25.9c0.4-0.4,0.8-0.8,1.1-1.1c0.3-0.2,0.3-0.6,0.1-0.9c0,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.5-0.6-0.8-0.8
		c-0.2-0.3-0.6-0.3-0.9-0.1c0,0,0,0-0.1,0.1c-0.2,0.2-0.3,0.3-0.5,0.4L51,136.7c-1.8,1.8-3.6,3.5-5.5,5.3
		C45.3,142.2,45.2,142.5,45,142.7z"
      />
      <path
        class="st0"
        d="M104.8,56C90.7,55.6,79,66.8,78.6,80.9s10.8,25.9,24.9,26.2c14.1,0.4,25.9-10.8,26.2-24.9c0,0,0-0.1,0-0.1
		c0.4-14-10.7-25.7-24.7-26.1C105,56,104.9,56,104.8,56z M104,106c-13.5-0.1-24.3-11.1-24.2-24.5s11.1-24.3,24.5-24.2
		s24.3,11.1,24.2,24.5c0,0.1,0,0.1,0,0.2C128.4,95.4,117.4,106.1,104,106z"
      />
      <path
        class="st1"
        d="M128.6,82.1c-0.3,13.4-11.5,24-25,23.6c-13.5-0.3-24.1-11.5-23.8-24.9c0.3-13.3,11.4-23.9,24.8-23.7
		C118.1,57.5,128.8,68.6,128.6,82.1C128.6,82.1,128.6,82.1,128.6,82.1z"
      />
      <path
        class="st1"
        d="M45,142.7c0.2-0.3,0.3-0.5,0.5-0.7c1.8-1.8,3.6-3.6,5.5-5.3l21.9-21.4c0.2-0.2,0.3-0.3,0.5-0.4
		c0.2-0.3,0.6-0.3,0.9-0.1c0,0,0,0,0.1,0.1c0.3,0.3,0.5,0.6,0.8,0.8c0.3,0.2,0.3,0.6,0.1,0.9c0,0-0.1,0.1-0.1,0.1
		c-0.4,0.4-0.7,0.8-1.1,1.1l-26.5,25.9c-0.7,0.7-0.8,0.7-1.5,0C45.6,143.4,45.3,143.1,45,142.7z"
      />
      <path
        class="st0"
        d="M91.5,94.6c-0.3-2.1,0-4.3,0.9-6.2c2.7-6.6,10.3-9.8,17-7.1c4,1.6,6.9,5.1,7.8,9.3c0.2,1.3,0.3,2.6,0.2,3.9
		H91.5z M92.6,93.2h23.5c0-6.5-5.3-11.8-11.8-11.8C97.9,81.5,92.6,86.8,92.6,93.2z"
      />
      <path
        class="st0"
        d="M105.3,63.1c1.5,0.2,2.9,0.8,4.1,1.7c2.8,2.2,3.9,5.9,2.7,9.2c-1.5,4.2-6.1,6.5-10.3,5s-6.5-6.1-5-10.3
		c1-2.8,3.5-4.9,6.4-5.3c0.1,0,0.2-0.1,0.3-0.1L105.3,63.1z"
      />
      <path
        class="st0"
        d="M92.6,93.2c0-6.5,5.3-11.8,11.8-11.8c6.5,0,11.8,5.3,11.8,11.8H92.6z"
      />
    </g>
    <g id="Camada_3">
      <path
        class="st2"
        d="M167.8,204.4L167.8,204.4c-3.3,1.2-6.7,0.6-9.9,0.6c-5.1,0.1-10.3-0.1-15.5,0.1c-1.2,0.1-2.3-0.1-3.4-0.7
		c-0.9-0.5-1.2-1.1-2.2-2.4c-1.2-1.5-1.5-1.5-2.9-3.1c-2.7-3-3.7-5.1-4-5c-0.5,0.3,4.3,7.7,2.1,10.3c-0.9,1-2.4,0.8-4.1,0.7
		c-0.8,0-8.2,0.3-23,1c-1.3,0.1-4,0.2-5.2-1.4c-0.5-0.7-0.7-1.5-0.6-2.4c0.2-8.4,0-16.9,0.1-25.3c0.2-12,9-18.6,19.9-15
		c4.4,1.5,8.2,4.4,10.8,8.2c4.7,6.5,9.4,13,14,19.7c0.8,1.3,2.2,2.1,3.7,2c5.2-0.1,10.3,0,15.5,0c2.5-0.2,4.9,0.8,6.7,2.6l0.1,0.1
		C172.7,198.6,171.9,202.3,167.8,204.4z"
      />
      <path
        class="st3"
        d="M100,141.8c-0.1-8.2,6.5-14.9,14.7-15c0.2,0,0.4,0,0.6,0c8.3,0.1,14.9,7,14.7,15.3c0,0,0,0,0,0
		c-0.1,8.2-6.7,14.8-14.9,14.9c-8.3,0.1-15.1-6.6-15.1-14.9C100,142,100,141.9,100,141.8z"
      />
      <path
        class="st0"
        d="M185.8,195.2c2.4-7.9,3.8-16,5.7-24c0.5-2-0.6-2.9-2.9-2.9c-9.9,0-19.8,0-29.7,0c-1.2-0.1-2.4,0.3-3.4,1.1
		c-0.7,0.7-1.2,1.5-1.4,2.5c-1.4,5.8-4.2,19-4.2,19h-3c-0.7-0.2,1.1-7.1,2-12c0.1-0.7,0.8-3.3,2.2-8.6c0.1-0.5,0.4-1.1,0.6-1.6
		c0.1-0.2,0.2-0.4,0.4-0.6c1.4-1.8,3.7-2.9,6-2.8c10.4-0.1,20.9-0.1,31.3,0c3.8,0,5.9,2.4,5.2,5.9c-1.9,8.6-3.9,17.2-5.9,25.8
		c-0.2,0.8-0.5,1.6-1,2.2c-1.6,2.3-4.7,2.8-6.2,3c-1.5,0.2-3,0.2-4.5,0c-1.8,0-4.4-0.1-7.5,0c0.3-1.1,0.9-2.1,1.9-2.8
		c1-0.6,2.5-0.7,5.4-0.7c1.8,0,2-0.1,3.1,0C182.5,199.1,184.9,197.6,185.8,195.2z"
      />
      <path
        class="st0"
        d="M129.2,200.5H192c1.1,0,1.9,0.9,1.9,1.9v1.8c0,1.1-0.9,1.9-1.9,1.9h-62.8c-1.1,0-1.9-0.9-1.9-1.9v-1.8
		C127.2,201.3,128.1,200.5,129.2,200.5z"
      />
    </g>
  </svg>
);

export const configurations = (props) => (
  <svg
    version="1.1"
    id="Configuration_Camada"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 248 248"
  >
    <style type="text/css">
      {`#Configuration_Camada #configurationSVG{fill:${theme.colors.defaultColor} !important} #Configuration_Camada .st0{fill:${theme.colors.defaultColor};}#Configuration_Camada .st1{fill:#FFFFFF;stroke:${theme.colors.defaultColor};stroke-width:2;stroke-miterlimit:10;} #Configuration_Camada .st2{fill:#ffffff; stroke:${theme.colors.defaultColor};stroke-miterlimit:10;} #Configuration_Camada .st3{fill:${theme.colors.defaultColor};} #Camada_3 .st4{fill:${theme.colors.defaultColor};} #Configuration_Camada .st5{font-family:'MyriadPro-Bold';} #Configuration_Camada .st6{font-size:22.95px;}`}
    </style>
    <g id="Camada_8">
      <ellipse class="st0" cx="116.3" cy="117.6" rx="72" ry="71" />
      <path
        class="st0"
        d="M187.2,152.9c-0.1,0.3-0.2,0.5-0.4,0.7c-1.2,1.8-2.4,3.6-3.5,5.4c-0.2,0.4-0.5,0.7-1,0.3
		c-0.4-0.2-0.5-0.7-0.3-1c0-0.1,0.1-0.1,0.1-0.2c1.2-1.8,2.4-3.6,3.6-5.4c0.2-0.3,0.4-0.7,0.8-0.4
		C186.8,152.5,187,152.7,187.2,152.9z"
      />
      <path
        class="st0"
        d="M189.4,147c-0.4-0.1-1.1-0.1-0.8-0.8c0.7-1.9,1.4-3.7,2-5.6c0-0.3,0.4-0.6,0.7-0.5c0.1,0,0.1,0,0.2,0.1
		c0.3,0.1,0.7,0.1,0.6,0.6c-0.7,2-1.4,3.9-2.1,5.9C189.9,146.8,189.7,147,189.4,147z"
      />
      <path
        class="st0"
        d="M193.9,135.3c-0.6-0.1-1.2-0.2-1-0.9c0.4-2.3,0.9-4.5,1.4-6.8c0.1-0.5,0.3-0.7,0.7-0.4
		c0.3,0.1,0.8-0.1,0.7,0.6c-0.5,2.4-0.9,4.8-1.4,7.2C194.2,135.1,194.1,135.3,193.9,135.3z"
      />
      <path
        class="st0"
        d="M48.8,152.2c0,0.4-0.4,0.4-0.6,0.6s-0.6,0.1-0.8-0.4c-1-2-2-4-2.9-6c-0.2-0.4-0.2-0.8,0.3-1.1
		c0.3-0.1,0.7-0.4,0.9,0.1l3.1,6.3C48.8,152,48.8,152.1,48.8,152.2z"
      />
      <path
        class="st0"
        d="M40.3,131.1c0.8-0.1,0.8,0.3,0.9,0.7c0.4,2.1,0.9,4.1,1.3,6.2c0.1,0.6,0.1,1-0.5,1.2s-0.8-0.2-0.9-0.8
		c-0.5-2.1-0.9-4.1-1.3-6.2C39.6,131.5,39.8,131,40.3,131.1z"
      />
      <path
        class="st0"
        d="M39.6,108.4c-0.2,0.3-0.5,0.1-0.8,0.1c-0.4,0-0.5-0.4-0.5-0.8c0.3-2.2,0.6-4.4,0.9-6.7
		c0.1-0.5,0.3-0.8,0.8-0.7c0.3,0.1,0.7,0.1,0.7,0.7l-1,7C39.7,108.2,39.6,108.3,39.6,108.4z"
      />
      <path
        class="st0"
        d="M39.6,123.5l-0.8,0.1c-0.5,0-0.6-0.3-0.6-0.7c0-2.3,0.1-4.6,0.1-6.9c0-0.4,0.2-0.7,0.6-0.6
		c0.3,0.1,0.8,0.1,0.8,0.6c0,2.4-0.1,4.9-0.1,7.3C39.6,123.3,39.6,123.4,39.6,123.5z"
      />
      <path
        class="st0"
        d="M44.8,85.4c0.6,0.4,0.5,0.8,0.3,1.1c-0.8,2-1.6,3.9-2.4,5.9c-0.2,0.5-0.5,0.9-1,0.6s-0.5-0.7-0.3-1.2
		c0.8-2,1.6-3.9,2.4-5.9C44,85.3,44.4,85,44.8,85.4z"
      />
      <path
        class="st0"
        d="M150.2,42.2c0.3-0.3,0.2-1.2,1-0.8l5.8,3.4c0.4,0.2,0.6,0.4,0.2,0.9c-0.2,0.3-0.4,0.8-0.8,0.5
		c-2.1-1.2-4.1-2.4-6.1-3.6C150.3,42.5,150.1,42.4,150.2,42.2z"
      />
      <path
        class="st0"
        d="M165,50.3c1.2,1.1,2.7,2.2,4,3.4c0.5,0.4,0.9,0.9,0.3,1.5s-0.9-0.2-1.3-0.5c-1.4-1.1-2.7-2.3-4.1-3.4
		c-0.4-0.3-1.4-0.3-0.8-1.3S164.3,49.9,165,50.3z"
      />
      <path
        class="st0"
        d="M195.7,113.1c0.1,0.4,0.2,0.8,0.2,1.2c0.1,2.2,0.1,4.4,0.2,6.6c0,0.5-0.1,0.8-0.7,0.8s-0.8-0.2-0.8-0.8
		c-0.1-2.3-0.1-4.5-0.2-6.8c0-0.3,0-0.6,0.4-0.7C195.2,113.3,195.5,113.2,195.7,113.1z"
      />
      <path
        class="st0"
        d="M134.9,36.2c0.2,0,0.4,0.1,0.7,0.1c2.1,0.4,4.1,0.9,6.2,1.3c0.6,0.1,0.9,0.3,0.7,1s-0.4,0.7-1,0.5
		c-2.1-0.5-4.3-0.9-6.4-1.4c-0.3-0.1-0.8,0-0.7-0.6C134.5,36.8,134.4,36.4,134.9,36.2z"
      />
      <path
        class="st0"
        d="M121.6,34.9c0.9,0,1.9,0,2.8,0.1c1.4,0.1,2.8,0.2,4.3,0.3c0.4,0,0.9,0.1,0.8,0.8c-0.1,0.4-0.2,0.7-0.6,0.7
		l-7-0.4c-0.4,0-0.5-0.3-0.5-0.6S121.1,35,121.6,34.9z"
      />
      <path
        class="st0"
        d="M114.2,35c0.2,0.7-0.1,1-0.8,1c-2,0.1-4,0.3-6.1,0.5c-0.4,0-1,0.2-1.2-0.6s0.3-0.8,1-0.9c2-0.1,4-0.3,6.1-0.5
		C113.6,34.5,114,34.4,114.2,35z"
      />
    </g>
    <g id="Camada_11">
      <path
        class="st1"
        d="M95.6,154.2c-5.8,2.3-11.3,2.3-16.8-0.6c-1.3-0.7-2.9-0.7-4.2,0c-3.3,1.7-6.3,3.9-9,6.4
		c-4.5,4.4-7.2,10.4-7.5,16.6c-0.3,1.8,0.8,3.6,2.5,4.1c2.4,1,4.9,1.8,7.4,2.3c4.7,0.7,9.5,1,14.2,1.5c0.3,0,0.7-0.2,0.9-0.5
		c2.7-5.7,7-10.5,12.4-13.8c2.2-1.4,4.6-2.6,6.8-3.9C98.7,163.2,96.4,158.9,95.6,154.2z M128.8,166.4c1.5,0.7,3,1.3,4.4,2.1
		c6.7,3.8,12.2,8.6,15.5,15.7c0.3,0.6,0.6,0.6,1.2,0.6c5.3,0,10.5-0.5,15.7-1.6c2.1-0.5,4.1-1.3,5.9-2.3c1.2-0.6,2-1.9,1.8-3.3
		c-0.1-5.3-1.9-10.4-5.2-14.5c-3.2-3.9-7.2-7.1-11.7-9.3c-1.4-0.7-3.1-0.7-4.4,0.1c-3.3,1.8-7.1,2.5-10.9,1.9
		c-1.9-0.3-3.8-0.9-5.7-1.4C134.6,159.2,132.3,163.4,128.8,166.4z M71.7,138.9c-0.1,8.7,6.9,15.8,15.6,15.8c2.5,0,5.1-0.6,7.3-1.7
		c0.6-0.3,1-0.9,0.9-1.5c0.3-5.3,2.8-10.2,6.8-13.6c0.6-0.5,0.8-1.2,0.6-1.9c-1.6-8.1-9.1-13.7-17.4-12.9
		C77.6,124,71.6,130.8,71.7,138.9z M159.3,139.1c-0.1-1.2-0.3-2.3-0.5-3.5c-1.9-8.3-10.1-13.6-18.4-11.9
		c-6.1,1.2-10.9,5.8-12.4,11.8c-0.3,0.9,0,1.9,0.8,2.4c4.2,3.6,6.7,8.9,6.9,14.4c0.1,0.4,0.3,0.8,0.7,1c3,1.6,6.4,2.1,9.6,1.5
		C153.7,153.5,159.3,146.9,159.3,139.1z"
      />
      <path
        class="st1"
        d="M116.2,202.5c-5.2-0.3-10.5-0.4-15.7-0.9c-4.8-0.4-9.6-1.2-14.1-3.2c-0.9-0.3-1.6-0.8-2.3-1.4
		c-0.9-1.1-1.3-2.5-1.2-4c0-1.2,0.1-5.3,3.2-10.9c0.7-1.4,1.6-2.7,2.5-3.9c3.5-4.5,8.1-8.1,13.2-10.5c1.2-0.6,2.7-0.6,3.9,0.1
		c3.8,2,8.1,2.9,12.3,2.4c2.5-0.1,4.9-0.9,7.1-2.1c1.3-0.9,3-1,4.5-0.3c6.7,3.4,12.4,7.9,16.1,14.6c1.7,3,2.6,6.4,2.9,9.8
		c0,0.2,0,0.4,0.1,0.6c0.5,3.2-0.4,4.5-3.3,5.9c-2.9,1.3-6.1,2.1-9.2,2.4C129.5,202,122.9,202.4,116.2,202.5z"
      />
      <path
        class="st1"
        d="M115.6,171c-10.5,0-19-8.5-19-19s8.5-19,19-19l0,0c10.8,0,19.5,8.2,19.5,19.1S125.7,171.1,115.6,171z"
      />
      <path
        class="st2"
        d="M164.2,63.6h2.6l1.7,0.2c0.3-0.1,0.6,0.1,0.9,0c0.9,0.2,1.8,0.4,2.6,0.8c0.9,0.3,1.7,0.7,2.5,1.2
		c0.3,0.2,0.6,0.4,0.9,0.5c0.7,0.4,1.3,0.9,1.9,1.5c0.4,0.4,0.8,0.8,1.2,1.3c0.5,0.5,0.9,0.9,1.3,1.4c0.3,0.3,0.5,0.7,0.8,1.1
		c0.5,0.8,1,1.7,1.4,2.6c0.2,0.4,0.4,0.9,0.5,1.3c0.2,0.4,0.2,0.8,0.4,1.2s0.2,0.3,0.5,0.2s0.7-0.2,1-0.3c0.2-0.1,0.4-0.1,0.7-0.2
		c0.8-0.2,1.7-0.2,2.5-0.2c0.9,0,1.7,0.2,2.5,0.4l0.4,0.1c0.8,0.3,1.6,0.6,2.3,1c0.8,0.4,1.6,1,2.3,1.6c0.1,0.2,0.3,0.3,0.4,0.4
		c0.7,0.7,1.3,1.4,1.9,2.3c0.3,0.5,0.5,0.9,0.7,1.4c0.2,0.5,0.4,0.9,0.5,1.4c0.2,0.8,0.3,1.5,0.4,2.3c0.1,0.8,0,1.7-0.1,2.5
		c-0.1,0.8-0.3,1.5-0.6,2.2c-0.4,1.1-1,2.1-1.7,3.1c-0.4,0.5-0.8,0.9-1.2,1.3c-0.5,0.5-1,0.9-1.6,1.3c-0.3,0.2-0.6,0.4-1,0.6
		c-0.8,0.5-1.7,0.8-2.7,1.1c-1,0.2-2,0.3-3,0.4c-0.7,0-1.4,0-2.2,0c-0.9,0-1.7,0-2.6,0c-0.9,0-1.7,0-2.6,0s-1.7,0-2.6,0
		s-1.7,0-2.6,0s-1.7,0-2.6,0s-1.7,0-2.6,0s-1.7,0-2.5,0l-0.2-0.1c-0.2-0.2-0.2-0.5-0.2-0.8c0-2.1,0-4.1,0-6.2c0-0.3-0.1-0.7,0.2-0.8
		s0.4,0.3,0.6,0.5c0.1,0.2,0.3,0.5,0.5,0.7c0.3,0.4,0.9,0.4,1.3,0.1c0.4-0.3,0.4-0.8,0.2-1.2c-0.5-0.8-1.1-1.6-1.6-2.4
		c-0.4-0.6-0.8-1.3-1.3-1.9c-0.6-0.8-1.3-0.8-1.8,0c-0.9,1.4-1.9,2.8-2.8,4.2c-0.3,0.4-0.3,1,0.1,1.3c0,0,0,0,0,0
		c0.5,0.3,1.1,0.2,1.4-0.2c0,0,0,0,0-0.1c0.2-0.3,0.4-0.6,0.6-0.8c0.1-0.1,0.2-0.2,0.3-0.2s0.2,0.2,0.2,0.4c0,0.2,0,0.3,0,0.5v6
		c0.1,0.3,0,0.6-0.2,0.9c-0.1,0-0.1,0.1-0.2,0.1c-0.8,0-1.7,0-2.5,0c-0.9,0-1.7,0-2.6,0s-1.7,0-2.6,0s-1.7,0-2.6,0s-1.7,0-2.6,0
		s-1.7,0-2.6,0s-1.7,0-2.6,0c-1.7,0.1-3.4-0.1-5-0.4c-1.4-0.4-2.7-1-3.9-1.8c-0.5-0.4-1-0.8-1.5-1.2c-0.4-0.4-0.8-0.8-1.1-1.3
		c-0.6-0.8-1.1-1.7-1.6-2.7c-0.3-0.9-0.5-1.7-0.7-2.6c-0.2-0.8-0.2-1.6-0.1-2.5c0.1-0.8,0.2-1.6,0.4-2.3c0.1-0.5,0.3-0.9,0.5-1.4
		c0.2-0.5,0.4-1,0.7-1.4c0.6-0.9,1.2-1.7,2-2.5l0.3-0.3c1.3-1.2,2.9-2,4.6-2.5l0.4-0.1c0.8-0.2,1.7-0.3,2.5-0.4
		c0.8,0,1.7,0.1,2.5,0.2c0.5,0.1,1.1,0.2,1.6,0.4c0.4,0.2,0.6,0,0.7-0.4c0.1-0.3,0.2-0.7,0.3-1c0.2-0.4,0.3-0.9,0.5-1.3
		c0.4-0.9,0.9-1.8,1.4-2.6c0.3-0.4,0.5-0.8,0.8-1.1c0.4-0.5,0.8-1,1.3-1.5c0.4-0.4,0.8-0.8,1.2-1.2c0.6-0.5,1.3-1,2-1.4
		c0.3-0.2,0.6-0.4,0.9-0.5c0.8-0.5,1.6-0.9,2.4-1.2c0.8-0.3,1.6-0.6,2.5-0.8c0.3,0,0.7-0.1,1,0L164.2,63.6z"
      />
      <path
        class="st3"
        d="M173.6,94.5c-1.4,0-2.7,0-4,0c-0.4,0-0.5,0.1-0.5,0.4c0,2.9,0,5.7,0,8.6c0,0.3-0.1,0.4-0.5,0.4
		c-2.6,0-5.3,0-7.9,0c-0.4,0-0.5-0.1-0.5-0.5c0-2.8,0-5.7,0-8.5c0-0.4-0.1-0.5-0.5-0.5c-1.3,0-2.5,0-3.9,0c0.4-0.5,0.8-0.9,1.2-1.4
		c2.4-2.7,4.9-5.3,7.4-8c0.2-0.2,0.2-0.2,0.4,0L173.6,94.5z"
      />
      <circle class="st0" cx="146.5" cy="191.5" r="12.5" />
      <circle class="st2" cx="80.2" cy="64.2" r="26.8" />
      <text
        transform="matrix(0.99 0 0 1 139.5098 198.21)"
        id="configurationSVG"
        class="st4 st5 st6"
      >
        +
      </text>
      <path
        class="st4"
        id="configurationSVG"
        d="M84.6,67.4V59c0-1.6,0.9-2.3,2.7-2.3h1.8c1.6,0,2.5,0.8,2.5,2.2c0,2.1,0,4.2,0,6.3v10.6c0,1.6-0.9,2.4-2.8,2.4
		c-0.6,0-1.2,0-1.8,0c-1.6,0-2.5-0.8-2.5-2.2C84.6,73.2,84.6,70.3,84.6,67.4z"
      />
      <path
        class="st4"
        id="configurationSVG"
        d="M75.4,69.6c0-2.1,0-4.1,0-6.2c0-1.6,0.8-2.3,2.6-2.3c0.7,0,1.3,0,2,0c1.2-0.1,2.2,0.7,2.3,1.9
		c0.1,0.8,0,1.6,0,2.4v10.4c0,1.8-0.8,2.5-2.8,2.5c-0.5,0-1.1,0-1.7,0c-1.6,0-2.5-0.8-2.5-2.2C75.4,73.9,75.4,71.8,75.4,69.6z"
      />
      <path
        class="st4"
        id="configurationSVG"
        d="M63.9,61c0-1.3,0-2.6,0-3.8c0-0.2,0.4-0.5,0.7-0.7c3.5-1.8,6.9-3.5,10.4-5.3l8.1-4.2l-1.6-1.9l8.6,0.3
		l-3.6,6.4l-1.8-2L63.9,61z"
      />
      <path
        class="st4"
        id="configurationSVG"
        d="M66,72.1c0-1.3,0-2.7,0-4c0-1.7,1.1-2.7,3.1-2.7c0.5,0,1,0,1.6,0c1.7,0,2.8,0.9,2.9,2.4c0.1,2.8,0.1,5.7,0,8.6
		c0,1.5-1.2,2.4-2.9,2.4h-1.6c-1.8,0-2.9-1-2.9-2.6V72.1z"
      />
    </g>
  </svg>
);

export const deleteItem = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="8"
    viewBox="0 0 10 8"
  >
    <g fill="none" fillRule="evenodd" stroke="#000000" strokeLinecap="round">
      <path d="M1.286.308L8.978 8M8.714.308L1.022 8" />
    </g>
  </svg>
);

export const statusOkFill = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    style={{ display: "flex", alignSelf: "center" }}
  >
    <g fill="none" fillRule="evenodd" transform="translate(0 .5)">
      <circle cx="10" cy="10" r="10" fill="#BBB" />
      <path
        fill="#FFF"
        d="M8.606 13.958a.67.67 0 0 1-.469-.19l-2.942-2.87a.639.639 0 0 1 0-.919.675.675 0 0 1 .938 0l2.473 2.413 5.886-5.744a.675.675 0 0 1 .939 0 .639.639 0 0 1 0 .919l-6.355 6.202a.67.67 0 0 1-.47.19"
      />
    </g>
  </svg>
);

export const view = () => (
  <svg width="24" height="24" viewBox="0 0 16 16">
    <path d="M14 8l-.124.232c-.02.039-.518.962-1.484 1.887C11.106 11.35 9.592 12 8.012 12c-1.579 0-3.097-.65-4.39-1.88a8.385 8.385 0 0 1-1.496-1.886L2 8l.126-.234c.021-.039.524-.962 1.496-1.886C4.915 4.65 6.433 4 8.012 4c1.58 0 3.094.65 4.38 1.88a8.318 8.318 0 0 1 1.484 1.888L14 8zm-1.672.025L12.346 8a7.351 7.351 0 0 0-.936-1.093C10.38 5.92 9.213 5.42 8.012 5.42s-2.374.502-3.411 1.489A7.43 7.43 0 0 0 3.659 8l.015.022c.26.364.57.73.927 1.07 1.037.986 2.21 1.488 3.411 1.488s2.368-.501 3.398-1.486a7.35 7.35 0 0 0 .918-1.07zM8 6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0 1.42a.582.582 0 0 0-.58.58c0 .319.261.58.58.58.319 0 .58-.261.58-.58A.582.582 0 0 0 8 7.42z" />
  </svg>
);

export const statusFinishedFill = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    style={{ display: "flex", alignSelf: "center" }}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#8BC63F" />
      <g fill="#FFF" transform="translate(4.167 8.333)">
        <ellipse cx="1.359" cy="1.346" rx="1.359" ry="1.346" />
        <ellipse cx="5.846" cy="1.346" rx="1.359" ry="1.346" />
        <ellipse cx="10.333" cy="1.346" rx="1.359" ry="1.346" />
      </g>
    </g>
  </svg>
);

export const edit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
  >
    <g fillRule="evenodd" opacity=".7">
      <path d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12C23.992 5.376 18.624.008 12 0zm0 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12c-.006 5.796-4.704 10.494-10.5 10.5z" />
      <path
        fillRule="nonzero"
        d="M13.462 8.003l2.441 2.453-6.18 6.209-2.439-2.453 6.178-6.21zm4.293-.592l-1.088-1.094a1.077 1.077 0 0 0-1.526 0l-1.043 1.048 2.44 2.453 1.217-1.222a.84.84 0 0 0 0-1.185zM6.007 17.66c-.045.2.136.38.336.332l2.72-.663-2.44-2.453-.616 2.784z"
      />
    </g>
  </svg>
);

export const start = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="#000" fillRule="evenodd">
      <path
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12C23.992 5.376 18.624.008 12 0zm0 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12c-.006 5.796-4.704 10.494-10.5 10.5z"
        opacity=".7"
      />
      <path d="M9.96 16.724L6 12.763l1.68-1.681 2.28 2.28L16.32 7 18 8.68z" />
    </g>
  </svg>
);

export const choice = () => (
  <svg viewBox="0 0 120 120">
    <defs>
      <path id="a" d="M0 0h120v120H0z" />
    </defs>
    <g fillRule="evenodd">
      <path
        d="M119.793 11.882a1.914 1.914 0 0 0-2-1.027L107.57 12.43l1.575-10.224A1.916 1.916 0 0 0 105.9.56L94.03 12.43c-.288.287-.476.659-.539 1.061L91.613 25.68l-6.793 6.793c-9.359-8.688-21.473-13.453-34.328-13.453-13.498 0-26.18 5.249-35.712 14.78C5.248 43.33 0 56.011 0 69.508c0 13.498 5.248 26.18 14.78 35.712C24.312 114.75 36.994 120 50.49 120c13.498 0 26.18-5.248 35.712-14.78 9.53-9.532 14.779-22.214 14.779-35.71 0-10.737-3.317-20.986-9.596-29.638a1.915 1.915 0 0 0-3.099 2.25c5.8 7.994 8.865 17.464 8.865 27.388 0 25.728-20.932 46.66-46.66 46.66-25.73 0-46.661-20.932-46.661-46.66 0-25.73 20.932-46.661 46.66-46.661 11.834 0 22.987 4.367 31.62 12.333l-8.754 8.755C67.06 38.299 58.999 35.21 50.49 35.21c-18.912 0-34.298 15.386-34.298 34.299 0 18.912 15.386 34.298 34.298 34.298 18.913 0 34.3-15.386 34.3-34.298 0-6.312-1.726-12.481-4.991-17.838a1.915 1.915 0 1 0-3.272 1.993A30.421 30.421 0 0 1 80.96 69.51c0 16.8-13.668 30.47-30.469 30.47-16.8 0-30.468-13.67-30.468-30.47 0-16.8 13.667-30.47 30.468-30.47 7.488 0 14.586 2.691 20.154 7.608l-8.774 8.774a17.97 17.97 0 0 0-11.38-4.02c-9.984 0-18.106 8.124-18.106 18.108s8.122 18.106 18.106 18.106c9.985 0 18.107-8.122 18.107-18.106 0-1.778-.258-3.537-.765-5.225a1.915 1.915 0 0 0-3.668 1.102c.4 1.33.604 2.718.604 4.123 0 7.872-6.406 14.276-14.278 14.276-7.872 0-14.276-6.404-14.276-14.276s6.404-14.278 14.276-14.278c3.17 0 6.179 1.028 8.652 2.918L49.137 68.156a1.915 1.915 0 0 0 2.707 2.709l42.477-42.477 12.187-1.879a1.92 1.92 0 0 0 1.063-.538l11.867-11.869c.587-.587.73-1.482.355-2.22zm-22.612 2.812l7.288-7.286-.987 6.402-7.287 7.287.986-6.403zm8.125 8.125l-6.403.987 7.288-7.288 6.401-.987-7.286 7.288z"
        mask="url(#b)"
      />
    </g>
  </svg>
);

export const rails = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fillRule="evenodd">
      <path d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12C23.992 5.376 18.624.008 12 0zm0 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12c-.006 5.796-4.704 10.494-10.5 10.5z" />
      <path
        fillRule="nonzero"
        d="M13.107 15.492c.746.259 1.404.423 2.35.717-.364 4.646-4.705 2.801-2.35-.717zm3.876-4.268C16.934 9.83 16.691 8 14.757 8.286c-.906.24-1.576 1.247-1.891 2.986-.173.955-.073 2.297.132 3.101.186.567.123.532.323.64a92.71 92.71 0 0 1 2.324.572c.794-.568 1.446-3.58 1.338-4.36zm-5.968-.107c.204-.804.304-2.146.131-3.101-.314-1.739-.984-2.747-1.891-2.986-1.934-.287-2.177 1.543-2.226 2.938-.108.78.544 3.793 1.338 4.36.782-.199 1.55-.394 2.325-.571.2-.108.136-.073.323-.64zm-2.46 1.836c.364 4.646 4.705 2.801 2.35-.717-.745.259-1.404.423-2.35.717z"
      />
    </g>
  </svg>
);

export const trainerAec = () => (
  <svg viewBox="0 0 120 120">
    <g fillRule="evenodd">
      <path d="M114.967 73.927V6.879c0-1-.82-1.812-1.83-1.812H7.08A1.82 1.82 0 0 0 5.252 6.88v67.048c0 1 .818 1.812 1.828 1.812h25.667L5.6 112.728l2.96 2.13L37.267 75.74H58.28v38.054h3.657V75.739H82.95l28.709 39.12 2.958-2.131-27.145-36.989h25.665c1.01 0 1.83-.812 1.83-1.812zM8.909 72.115h102.4V8.692H8.91v63.423z" />
      <path d="M40.909 34.06c-3.535 0-6.4 2.84-6.4 6.343s2.865 6.342 6.4 6.342 6.4-2.839 6.4-6.342c0-3.503-2.865-6.343-6.4-6.343zm0 9.06c-1.515 0-2.743-1.216-2.743-2.717 0-1.5 1.228-2.718 2.743-2.718s2.743 1.217 2.743 2.718c0 1.5-1.228 2.718-2.743 2.718zM22.623 52.181c-3.535 0-6.4 2.84-6.4 6.343s2.865 6.342 6.4 6.342c3.536 0 6.4-2.839 6.4-6.342 0-3.503-2.864-6.343-6.4-6.343zm0 9.06c-1.515 0-2.742-1.216-2.742-2.717 0-1.5 1.227-2.718 2.742-2.718s2.743 1.217 2.743 2.718c0 1.5-1.228 2.718-2.743 2.718zM61.023 46.745c-3.534 0-6.4 2.84-6.4 6.343s2.866 6.342 6.4 6.342c3.535 0 6.4-2.84 6.4-6.342 0-3.503-2.865-6.343-6.4-6.343zm0 9.06c-1.514 0-2.743-1.216-2.743-2.717 0-1.502 1.229-2.718 2.743-2.718 1.515 0 2.743 1.216 2.743 2.718 0 1.5-1.228 2.718-2.743 2.718zM79.31 25c-3.535 0-6.401 2.84-6.401 6.343s2.866 6.342 6.4 6.342c3.535 0 6.4-2.84 6.4-6.342 0-3.503-2.865-6.343-6.4-6.343zm0 9.06c-1.515 0-2.744-1.217-2.744-2.717 0-1.501 1.229-2.719 2.743-2.719 1.515 0 2.742 1.218 2.742 2.719 0 1.5-1.227 2.717-2.742 2.717zM97.595 15.94c-3.535 0-6.4 2.84-6.4 6.342 0 3.503 2.865 6.342 6.4 6.342 3.534 0 6.4-2.839 6.4-6.342 0-3.503-2.866-6.343-6.4-6.343zm0 9.06c-1.515 0-2.744-1.217-2.744-2.718 0-1.5 1.23-2.718 2.744-2.718 1.514 0 2.742 1.217 2.742 2.718S99.11 25 97.595 25zM27.73 50.9l5.487-5.437 2.586 2.563-5.487 5.437zM66.073 44.25l5.994-7.13 2.809 2.32-5.994 7.13zM86.373 25.815l2.765-1.37 1.637 3.241-2.766 1.37zM48.282 46.93l2.014-3.025 4.47 2.922-2.015 3.025z" />
    </g>
  </svg>
);

export const onboard = () => (
  <svg viewBox="0 0 120 120">
    <g fillRule="evenodd">
      <path d="M21 81H5c-1.104 0-2 .871-2 1.946v33.107C3 117.13 3.895 118 4.998 118H21c1.105.001 2.001-.871 2.001-1.946V82.947C23 81.872 22.105 81 21 81zM7 114.106h12v-29.21H7v29.21zM92.002 66H76c-1.105-.001-2.001.878-2.001 1.961v49.077c0 1.084.895 1.962 2 1.962h16c1.104.001 2-.878 2-1.961V67.962C94 66.878 93.105 66 92.002 66zM78 115.075h12v-45.15H78v45.15zM68.002 76H52c-1.105-.001-2 .874-2.001 1.953v39.093c0 1.08.895 1.954 2 1.954h16c1.105.001 2-.874 2-1.953V77.954c.001-1.08-.895-1.954-1.998-1.954zM54 115.092h12V79.908H54v35.184zM45 52H29c-1.105 0-2 .882-2 1.969v63.061c-.001 1.088.895 1.97 1.998 1.97H45c1.105.001 2-.882 2.001-1.969V53.97c0-1.087-.895-1.97-2-1.97zm-14 63.06h12V55.942H31v59.118zM115 91H99c-1.104 0-2 .863-2 1.927v23.145c0 1.065.895 1.928 2 1.928h16c1.104.001 2-.863 2-1.927V92.928c0-1.064-.895-1.928-2-1.928zm-14 23.144h12V94.858h-12v19.286z" />
      <path d="M118.527 44.217a1.933 1.933 0 0 0-.43-.375l-7.867-5.07a1.962 1.962 0 0 0-2.717.59 1.97 1.97 0 0 0-.313 1.067v3.943h-6.814l-15.15-22.779a2.037 2.037 0 0 0-3.272 0L60.378 54.05 38.208 2.197A1.94 1.94 0 0 0 36.37 1a1.966 1.966 0 0 0-1.8 1.25L9.494 66.058H1V70h9.833a1.962 1.962 0 0 0 1.83-1.249L36.476 8.158l21.717 50.79a1.964 1.964 0 0 0 3.443.318L83.6 26.24l14.097 21.197c.365.548.978.878 1.636.878h7.867v3.943c0 1.088.88 1.971 1.965 1.971.47 0 .924-.168 1.282-.475l7.866-6.76c.824-.708.92-1.952.214-2.777zm-7.394 3.755v-3.93l2.615 1.684-2.615 2.246z" />
    </g>
  </svg>
);

export const logout = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={theme.colors.defaultColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.2"
    >
      <path d="M11 19H7.429C6.639 19 6 18.373 6 17.6V6.4C6 5.627 6.64 5 7.429 5H11M16 15l3-3-3-3M19 12h-9" />
    </g>
  </svg>
);
export const logoHeader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="240px"
    height="60px"
    viewBox="0 0 10000 1750"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "-webkit-optimize-contrast",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
  >
    <g>
      <path
        fill="#742b8f"
        d="M 6459.5,-0.5 C 6467.17,-0.5 6474.83,-0.5 6482.5,-0.5C 6521.05,7.55983 6542.89,31.2265 6548,70.5C 6550.12,116.7 6528.62,144.533 6483.5,154C 6456.82,158.91 6432.48,153.577 6410.5,138C 6386.1,114.228 6378.26,85.7278 6387,52.5C 6395.41,27.0954 6412.57,10.9288 6438.5,4C 6445.57,2.15469 6452.57,0.654689 6459.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 3852.5,18.5 C 3870.52,18.9465 3888.52,19.7798 3906.5,21C 3912.93,22.4269 3917.09,26.2602 3919,32.5C 3920.03,36.7727 3920.7,41.106 3921,45.5C 3921.17,119.737 3921.67,193.903 3922.5,268C 3925.08,269.665 3927.74,270.665 3930.5,271C 3947.02,260.905 3964.02,251.572 3981.5,243C 4031.91,222.639 4083.57,218.972 4136.5,232C 4201.67,247.837 4241.17,288.67 4255,354.5C 4259.14,373.629 4261.81,392.962 4263,412.5C 4264.54,542.163 4264.87,671.829 4264,801.5C 4263.93,807.994 4262.93,814.327 4261,820.5C 4257.02,826.075 4251.52,828.908 4244.5,829C 4227.49,829.291 4210.49,829.791 4193.5,830.5C 4175.51,829.783 4157.51,829.283 4139.5,829C 4128.14,828.638 4121.97,822.804 4121,811.5C 4120.67,693.167 4120.33,574.833 4120,456.5C 4119.6,438.049 4117.93,419.715 4115,401.5C 4105.15,360.152 4078.99,338.819 4036.5,337.5C 4001.1,338.571 3971.26,351.904 3947,377.5C 3932.65,394.884 3924.32,414.884 3922,437.5C 3921.67,560.5 3921.33,683.5 3921,806.5C 3921.98,820.105 3915.81,827.605 3902.5,829C 3865.13,831.267 3827.79,830.934 3790.5,828C 3781.77,825.077 3777.61,818.91 3778,809.5C 3777.33,553.833 3777.33,298.167 3778,42.5C 3778.03,28.1341 3785.2,20.6341 3799.5,20C 3817.34,19.8316 3835,19.3316 3852.5,18.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 5071.5,18.5 C 5098.88,18.2434 5126.21,19.0767 5153.5,21C 5161.09,22.921 5165.59,27.7543 5167,35.5C 5168.32,245.814 5168.99,456.147 5169,666.5C 5169.09,686.087 5174.75,703.754 5186,719.5C 5196.98,729.495 5209.98,734.162 5225,733.5C 5230.86,733.294 5236.69,732.794 5242.5,732C 5258.6,728.432 5274.77,725.265 5291,722.5C 5294.79,722.224 5298.29,723.057 5301.5,725C 5305.24,729.637 5308.07,734.804 5310,740.5C 5316,758.5 5322,776.5 5328,794.5C 5330.54,805.558 5327.37,814.392 5318.5,821C 5257.67,847.812 5194.67,854.812 5129.5,842C 5074.75,832.578 5041.58,800.745 5030,746.5C 5027.24,733.305 5025.57,719.972 5025,706.5C 5024.33,484.5 5024.33,262.5 5025,40.5C 5024.89,27.9416 5031.06,21.1083 5043.5,20C 5053.01,19.8299 5062.34,19.3299 5071.5,18.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 7342.5,18.5 C 7367.85,18.3891 7393.18,18.8891 7418.5,20C 7427.37,19.6774 7433.54,23.5107 7437,31.5C 7438.2,39.4385 7438.86,47.4385 7439,55.5C 7439.33,129.167 7439.67,202.833 7440,276.5C 7447.98,273.392 7455.82,269.892 7463.5,266C 7526.75,222.726 7595.08,212.726 7668.5,236C 7719.73,249.564 7752.9,281.73 7768,332.5C 7775.59,360.716 7780.59,389.383 7783,418.5C 7784.11,489.159 7784.61,559.826 7784.5,630.5C 7784.66,693.197 7784.16,755.864 7783,818.5C 7780.48,825.025 7775.65,828.525 7768.5,829C 7726.83,829.5 7685.17,829.667 7643.5,829.5C 7642.38,817.195 7641.55,804.861 7641,792.5C 7640.67,673.833 7640.33,555.167 7640,436.5C 7632.51,346.998 7586.68,318.164 7502.5,350C 7468.15,367.38 7447.98,395.214 7442,433.5C 7441.11,440.144 7440.45,446.811 7440,453.5C 7439.67,569.167 7439.33,684.833 7439,800.5C 7439.18,808.137 7437.85,815.47 7435,822.5C 7430.98,826.399 7426.15,828.566 7420.5,829C 7380.17,829.5 7339.83,829.667 7299.5,829.5C 7298.84,570.828 7298.18,312.162 7297.5,53.5C 7298.05,46.1292 7298.89,38.7958 7300,31.5C 7302.16,24.3484 7306.99,20.5151 7314.5,20C 7324.01,19.8299 7333.34,19.3299 7342.5,18.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M -0.5,470.5 C -0.5,454.167 -0.5,437.833 -0.5,421.5C 2.15155,357.42 15.9849,295.753 41,236.5C 87.3849,134.467 165.552,71.967 275.5,49C 342.378,34.5967 408.712,36.5967 474.5,55C 533.026,73.8403 582.526,106.34 623,152.5C 631.76,161.923 639.76,171.923 647,182.5C 651.337,195.014 647.837,204.848 636.5,212C 598.885,239.281 561.552,266.948 524.5,295C 515.766,300.94 507.099,300.94 498.5,295C 487.535,282.367 476.202,270.033 464.5,258C 439.788,235.143 410.788,220.477 377.5,214C 317.88,207.891 273.38,230.724 244,282.5C 225.707,319.339 215.707,358.339 214,399.5C 211.316,446.882 214.316,493.882 223,540.5C 229.031,574.212 242.364,604.545 263,631.5C 294.943,667.71 335.109,683.876 383.5,680C 401.288,678.636 418.288,674.303 434.5,667C 440.558,662.278 444.058,656.111 445,648.5C 445.333,581.833 445.667,515.167 446,448.5C 446.082,440.486 449.582,434.652 456.5,431C 461.453,430.005 466.453,429.338 471.5,429C 522.5,428.333 573.5,428.333 624.5,429C 640.881,427.72 649.381,435.22 650,451.5C 650.179,546.176 650.679,640.842 651.5,735.5C 651.926,756.828 642.926,772.661 624.5,783C 496.486,848.446 363.152,862.113 224.5,824C 151.448,801.271 95.9482,756.771 58,690.5C 31.7405,640.151 14.4072,586.818 6,530.5C 3.09388,510.426 0.92721,490.426 -0.5,470.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 3406.5,39.5 C 3517.77,35.0729 3608.27,75.7395 3678,161.5C 3680.42,165.344 3683.09,169.01 3686,172.5C 3691.08,182.122 3689.58,190.622 3681.5,198C 3656.5,216.333 3631.5,234.667 3606.5,253C 3597.77,259.323 3589.44,258.823 3581.5,251.5C 3562.43,229.584 3541.1,210.417 3517.5,194C 3453.94,156.701 3389.94,156.035 3325.5,192C 3289.12,215.725 3263.95,248.225 3250,289.5C 3214.37,392.62 3214.7,495.62 3251,598.5C 3278.95,673.442 3332.79,714.942 3412.5,723C 3460.18,724.44 3504.18,712.44 3544.5,687C 3567.33,672.833 3590.17,658.667 3613,644.5C 3636.95,674.242 3660.79,704.075 3684.5,734C 3671.04,751.727 3655.04,766.727 3636.5,779C 3565.14,827.595 3486.14,849.262 3399.5,844C 3342.98,842.537 3289.65,828.87 3239.5,803C 3174.33,764.81 3129.16,709.977 3104,638.5C 3067.31,523.801 3063.97,408.134 3094,291.5C 3130.06,160.44 3214.23,79.2735 3346.5,48C 3366.46,43.8405 3386.46,41.0071 3406.5,39.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 1051.5,214.5 C 1085.9,212.845 1119.23,218.011 1151.5,230C 1156.91,233.066 1160.41,237.566 1162,243.5C 1162.72,252.536 1162.38,261.536 1161,270.5C 1154.54,307.6 1147.54,344.6 1140,381.5C 1138.77,391.006 1133.44,396.006 1124,396.5C 1104.28,392.357 1084.45,388.857 1064.5,386C 1037.04,380.948 1012.7,387.281 991.5,405C 976.345,421.831 968.845,441.664 969,464.5C 968.173,578.181 968.339,691.848 969.5,805.5C 969.78,817.815 964.113,825.649 952.5,829C 894.789,829.984 837.122,829.651 779.5,828C 773.836,825.668 770.336,821.501 769,815.5C 768.859,666.163 768.193,516.83 767,367.5C 764.029,329.786 760.529,292.119 756.5,254.5C 755.265,241.239 761.265,234.405 774.5,234C 817.871,233.027 861.205,233.36 904.5,235C 910.641,237.141 914.807,241.307 917,247.5C 921.802,263.572 926.802,279.572 932,295.5C 942.045,285.057 951.379,274.057 960,262.5C 984.625,235.271 1015.13,219.271 1051.5,214.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 2065.5,214.5 C 2108.52,212.297 2151.19,215.131 2193.5,223C 2295.92,245.425 2358.42,307.925 2381,410.5C 2401.02,497.804 2398.69,584.471 2374,670.5C 2359.45,720.139 2332.95,761.972 2294.5,796C 2249.44,831.691 2197.94,849.524 2140,849.5C 2109.13,847.66 2078.46,844.326 2048,839.5C 2047.22,849.826 2046.55,860.159 2046,870.5C 2045.67,932.167 2045.33,993.833 2045,1055.5C 2043.69,1066.93 2037.19,1072.43 2025.5,1072C 1971.81,1072.97 1918.14,1072.64 1864.5,1071C 1854.8,1068.81 1849.97,1062.64 1850,1052.5C 1849,790.466 1849.34,528.466 1851,266.5C 1852.81,260.693 1856.64,256.859 1862.5,255C 1928.81,233.97 1996.48,220.47 2065.5,214.5 Z M 2094.5,364.5 C 2125.89,364.942 2150.06,378.275 2167,404.5C 2181.56,432.722 2190.22,462.722 2193,494.5C 2196.76,538.146 2193.1,581.146 2182,623.5C 2175.15,649.731 2160.99,670.898 2139.5,687C 2112.19,702.301 2084.86,702.301 2057.5,687C 2049.8,679.908 2045.96,671.074 2046,660.5C 2045.02,567.479 2045.36,474.479 2047,381.5C 2048.01,377.157 2050.51,373.99 2054.5,372C 2067.67,367.863 2081.01,365.363 2094.5,364.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 2714.5,214.5 C 2766.45,211.087 2816.45,219.254 2864.5,239C 2928.14,267.977 2969.64,316.144 2989,383.5C 3009.44,459.194 3012.44,535.527 2998,612.5C 2958.45,779.745 2852.62,858.245 2680.5,848C 2612.58,843.287 2556.08,815.787 2511,765.5C 2489.28,737.737 2473.62,706.737 2464,672.5C 2439.12,586.791 2438.12,500.791 2461,414.5C 2490.67,311.495 2557.5,247.328 2661.5,222C 2679.18,218.498 2696.84,215.998 2714.5,214.5 Z M 2723.5,357.5 C 2745.37,358.516 2762.21,368.182 2774,386.5C 2788.5,411.165 2796.5,437.831 2798,466.5C 2798.46,496.667 2798.96,526.834 2799.5,557C 2800.78,588.592 2796.61,619.425 2787,649.5C 2781.45,666.705 2771.62,680.872 2757.5,692C 2714.86,711.257 2684.36,699.423 2666,656.5C 2659.97,641.036 2655.97,625.036 2654,608.5C 2644.95,557.252 2644.28,505.919 2652,454.5C 2656.05,423.253 2668.55,396.086 2689.5,373C 2699.29,364.267 2710.63,359.1 2723.5,357.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 6962.5,223.5 C 7008.12,222.823 7051.12,232.99 7091.5,254C 7121.34,274.538 7141.17,302.371 7151,337.5C 7158.17,363.686 7162.17,390.352 7163,417.5C 7163.67,547.833 7163.67,678.167 7163,808.5C 7163.11,821.057 7156.94,827.891 7144.5,829C 7107.46,831.282 7070.46,830.949 7033.5,828C 7027.84,827.004 7024.01,823.838 7022,818.5C 7021.01,814.558 7020.34,810.558 7020,806.5C 7019.67,683.833 7019.33,561.167 7019,438.5C 7018.13,417.971 7014.47,397.971 7008,378.5C 6996.68,354.878 6977.84,341.712 6951.5,339C 6902.9,334.062 6865.06,351.562 6838,391.5C 6827.35,410.781 6821.68,431.448 6821,453.5C 6820.67,573.5 6820.33,693.5 6820,813.5C 6818.08,822.423 6812.58,827.59 6803.5,829C 6766.83,829.667 6730.17,829.667 6693.5,829C 6682.86,826.352 6677.36,819.519 6677,808.5C 6676.47,733.999 6676.64,659.499 6677.5,585C 6677.05,520.166 6676.55,455.333 6676,390.5C 6674.48,350.984 6672.65,311.484 6670.5,272C 6670.42,265.437 6670.92,258.937 6672,252.5C 6675.19,246.985 6680.02,244.152 6686.5,244C 6715.17,242.108 6743.84,242.108 6772.5,244C 6781.67,244.833 6787.5,249.667 6790,258.5C 6793.69,273.266 6797.69,287.932 6802,302.5C 6824.71,279.067 6850.55,259.9 6879.5,245C 6905.95,232.638 6933.62,225.471 6962.5,223.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 4646.5,225.5 C 4724.04,223.09 4799.04,235.59 4871.5,263C 4878.64,268.745 4882.14,276.245 4882,285.5C 4882.33,414.167 4882.67,542.833 4883,671.5C 4884.81,715.873 4887.31,760.206 4890.5,804.5C 4893.92,819.217 4888.25,827.383 4873.5,829C 4842.12,831.098 4810.79,830.764 4779.5,828C 4774.95,826.412 4772.11,823.245 4771,818.5C 4768,805.835 4764,793.501 4759,781.5C 4754.6,783.524 4750.1,785.358 4745.5,787C 4714.75,820.041 4676.75,838.708 4631.5,843C 4606.83,844.987 4582.17,844.987 4557.5,843C 4477.77,832.584 4423.27,790.084 4394,715.5C 4358.78,608.667 4360.11,502.334 4398,396.5C 4436.59,302.597 4506.09,247.097 4606.5,230C 4619.93,228.025 4633.27,226.525 4646.5,225.5 Z M 4648.5,329.5 C 4673.17,329.382 4697.51,332.215 4721.5,338C 4735.03,342.54 4741.53,352.04 4741,366.5C 4741.67,461.5 4741.67,556.5 4741,651.5C 4734.35,691.143 4712.51,718.643 4675.5,734C 4627.17,752.405 4585.34,742.905 4550,705.5C 4532.06,681.041 4520.73,653.708 4516,623.5C 4509.11,570.289 4510.44,517.289 4520,464.5C 4528.09,422.799 4547.26,387.299 4577.5,358C 4598.29,341.268 4621.96,331.768 4648.5,329.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 5617.5,225.5 C 5651.06,223.992 5684.06,227.492 5716.5,236C 5788.31,260.868 5823.98,311.535 5823.5,388C 5823.08,456.869 5793.08,508.202 5733.5,542C 5693.4,563.589 5650.73,577.589 5605.5,584C 5579.64,588.244 5553.64,591.244 5527.5,593C 5517.42,593.576 5508.59,597.076 5501,603.5C 5499.2,606.963 5498.03,610.63 5497.5,614.5C 5505.87,665.002 5532.54,702.169 5577.5,726C 5609.25,739.449 5641.91,742.116 5675.5,734C 5691.65,730.06 5707.32,724.726 5722.5,718C 5737.62,711.273 5752.62,704.273 5767.5,697C 5773.94,693.528 5780.6,692.861 5787.5,695C 5790.76,697.592 5793.26,700.759 5795,704.5C 5804,723.167 5813,741.833 5822,760.5C 5824.52,765.734 5826.02,771.234 5826.5,777C 5826.48,784.365 5823.14,789.698 5816.5,793C 5791.92,806.622 5766.59,818.622 5740.5,829C 5660.55,855.642 5580.88,854.975 5501.5,827C 5444.17,803.674 5403.34,763.507 5379,706.5C 5360.65,663.246 5351.82,618.079 5352.5,571C 5352.13,545.836 5352.63,520.67 5354,495.5C 5362.08,410.829 5397.58,340.329 5460.5,284C 5506.29,247.232 5558.63,227.732 5617.5,225.5 Z M 5620.5,327.5 C 5666.32,327.99 5691.32,350.99 5695.5,396.5C 5694.01,419.651 5684.01,438.151 5665.5,452C 5635.3,473.854 5601.63,487.521 5564.5,493C 5547.02,495.398 5529.52,497.565 5512,499.5C 5503.33,497.505 5497,492.505 5493,484.5C 5491.13,462.853 5494.8,442.186 5504,422.5C 5521.74,382.757 5550.24,353.59 5589.5,335C 5599.68,331.27 5610.01,328.77 5620.5,327.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 8146.5,225.5 C 8188.65,223.559 8229.65,229.393 8269.5,243C 8334.56,268.393 8377.06,314.56 8397,381.5C 8402.48,401.92 8406.82,422.586 8410,443.5C 8413.88,469.958 8417.38,496.458 8420.5,523C 8418.33,572.204 8410.16,620.371 8396,667.5C 8359.59,774.562 8285.09,834.396 8172.5,847C 8017.19,857.304 7925.36,786.137 7897,633.5C 7883.85,559.66 7886.18,486.326 7904,413.5C 7932.64,316.857 7995.81,256.357 8093.5,232C 8111.16,228.504 8128.82,226.338 8146.5,225.5 Z M 8150.5,330.5 C 8205.41,331.897 8239.91,359.23 8254,412.5C 8262.14,452.782 8268.97,493.282 8274.5,534C 8273.05,563.737 8269.55,593.237 8264,622.5C 8256.65,654.862 8242.65,683.862 8222,709.5C 8194.37,738.371 8161.21,747.538 8122.5,737C 8094.8,726.971 8074.3,708.804 8061,682.5C 8052.74,666.73 8047.08,650.063 8044,632.5C 8034.49,583.517 8031.83,534.183 8036,484.5C 8038.49,449.202 8047.82,415.869 8064,384.5C 8083.51,351.472 8112.35,333.472 8150.5,330.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 1237.5,233.5 C 1290.5,233.333 1343.5,233.5 1396.5,234C 1408.13,234.635 1413.97,240.802 1414,252.5C 1414.33,374.5 1414.67,496.5 1415,618.5C 1415.09,633.117 1417.09,647.451 1421,661.5C 1429.84,682.61 1445.84,693.61 1469,694.5C 1492.68,691.824 1511.01,680.49 1524,660.5C 1531.61,646.143 1535.27,630.809 1535,614.5C 1535.33,495.167 1535.67,375.833 1536,256.5C 1537.08,241.421 1545.25,233.921 1560.5,234C 1611.5,233.333 1662.5,233.333 1713.5,234C 1726.3,233.794 1733.47,239.961 1735,252.5C 1735.14,396.837 1735.8,541.17 1737,685.5C 1739.76,732.476 1741.92,779.476 1743.5,826.5C 1735.86,827.442 1728.19,828.275 1720.5,829C 1700.33,829.396 1680.16,829.896 1660,830.5C 1636.79,830.142 1613.63,829.309 1590.5,828C 1586.29,826.813 1583.12,824.313 1581,820.5C 1577.61,812.319 1574.61,803.986 1572,795.5C 1570.08,791.996 1567.92,788.663 1565.5,785.5C 1561.33,787.75 1557.33,790.25 1553.5,793C 1532.5,815.673 1506.83,830.339 1476.5,837C 1431.47,848.227 1386.47,848.227 1341.5,837C 1298.73,826.53 1265.9,802.697 1243,765.5C 1230.84,740.522 1223.17,714.189 1220,686.5C 1218.47,675.214 1217.47,663.88 1217,652.5C 1216.33,519.5 1216.33,386.5 1217,253.5C 1217.81,240.524 1224.64,233.857 1237.5,233.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 5897.5,242.5 C 6028.16,243.268 6158.83,243.768 6289.5,244C 6297.13,245.627 6302.3,250.127 6305,257.5C 6306.59,277.796 6306.93,298.129 6306,318.5C 6304.48,329.197 6300.48,338.864 6294,347.5C 6289.67,352.5 6285.33,357.5 6281,362.5C 6266.7,375.804 6252.7,389.471 6239,403.5C 6186,457.833 6133,512.167 6080,566.5C 6074.83,573.5 6069.67,580.5 6064.5,587.5C 6091.43,601.8 6118.77,615.3 6146.5,628C 6197.28,653.442 6238.78,689.608 6271,736.5C 6300.85,785.724 6312.85,839.057 6307,896.5C 6294.99,1044.51 6215.15,1125.68 6067.5,1140C 6010.24,1141.52 5957.58,1126.85 5909.5,1096C 5882.88,1074.61 5866.05,1047.11 5859,1013.5C 5838.53,922.502 5860.7,844.002 5925.5,778C 5945.5,760.667 5965.5,743.333 5985.5,726C 5996.56,717.942 6007.89,710.275 6019.5,703C 6012.5,698 6005.5,693 5998.5,688C 5977.4,677.785 5956.07,668.118 5934.5,659C 5923.96,652.256 5918.63,642.589 5918.5,630C 5920.93,617.837 5920.93,605.67 5918.5,593.5C 5919.09,573.657 5926.26,556.657 5940,542.5C 6002.11,484.227 6061.61,423.561 6118.5,360.5C 6108.58,358.488 6098.58,356.988 6088.5,356C 6020.17,355.667 5951.83,355.333 5883.5,355C 5874.56,354.719 5869.39,350.219 5868,341.5C 5865.78,312.786 5866.11,284.119 5869,255.5C 5870.01,251.157 5872.51,247.99 5876.5,246C 5883.54,244.252 5890.54,243.086 5897.5,242.5 Z M 6107.5,762.5 C 6113.67,762.057 6119.34,763.557 6124.5,767C 6155.57,793.968 6172.73,828.134 6176,869.5C 6178.6,901.955 6173.94,933.288 6162,963.5C 6147.02,996.482 6122.19,1017.98 6087.5,1028C 6010.03,1034.19 5975.19,998.356 5983,920.5C 5986.54,892.762 5996.21,867.428 6012,844.5C 6033.06,813.771 6059.56,788.937 6091.5,770C 6096.81,767.173 6102.15,764.673 6107.5,762.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 6438.5,242.5 C 6466.18,242.226 6493.85,242.726 6521.5,244C 6530.37,244.549 6535.87,249.049 6538,257.5C 6539.66,441.147 6539.99,624.814 6539,808.5C 6539.79,821.116 6533.96,827.949 6521.5,829C 6484.83,829.667 6448.17,829.667 6411.5,829C 6403,827.167 6397.83,822 6396,813.5C 6395.33,630.5 6395.33,447.5 6396,264.5C 6395.26,252.408 6400.76,245.575 6412.5,244C 6421.34,243.83 6430.01,243.33 6438.5,242.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 3741.5,922.5 C 3753.72,921.194 3762.22,926.194 3767,937.5C 3768.29,958.449 3768.95,979.449 3769,1000.5C 3767.87,1014.12 3774.03,1021.28 3787.5,1022C 3799.53,1023.25 3811.53,1023.91 3823.5,1024C 3843.42,1035.2 3843.42,1046.2 3823.5,1057C 3811.89,1058.25 3800.22,1058.91 3788.5,1059C 3774.48,1059.82 3767.98,1067.32 3769,1081.5C 3768.33,1121.17 3768.33,1160.83 3769,1200.5C 3769.17,1208.37 3770.51,1216.04 3773,1223.5C 3778.95,1235.31 3788.62,1240.98 3802,1240.5C 3810.86,1239.41 3819.69,1238.24 3828.5,1237C 3847.68,1241.86 3850.68,1251.86 3837.5,1267C 3821.16,1276.77 3803.5,1280.43 3784.5,1278C 3765.59,1276.79 3749.76,1269.29 3737,1255.5C 3732.67,1249.83 3729.67,1243.5 3728,1236.5C 3726.65,1162.69 3725.48,1088.86 3724.5,1015C 3724.67,993.5 3724.83,972 3725,950.5C 3725.18,937.787 3730.68,928.454 3741.5,922.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 8681.5,922.5 C 8693.03,922.549 8700.86,927.882 8705,938.5C 8706,958.51 8706.66,978.51 8707,998.5C 8707.62,1009.11 8713.12,1015.28 8723.5,1017C 8733.8,1018.1 8744.13,1018.77 8754.5,1019C 8762.47,1023.63 8768.3,1030.13 8772,1038.5C 8773.3,1046.39 8770.14,1051.56 8762.5,1054C 8750.13,1055.37 8737.8,1057.04 8725.5,1059C 8714.65,1060.51 8708.49,1066.68 8707,1077.5C 8705.18,1119.5 8705.18,1161.5 8707,1203.5C 8708.01,1228.52 8720.84,1240.02 8745.5,1238C 8754.5,1236.43 8763.5,1234.93 8772.5,1233.5C 8774.5,1233.83 8776.5,1234.17 8778.5,1234.5C 8781.3,1242.28 8782.46,1250.28 8782,1258.5C 8780.08,1263.89 8776.25,1267.06 8770.5,1268C 8745.47,1272.5 8720.47,1272.5 8695.5,1268C 8676.57,1257.32 8665.74,1241.15 8663,1219.5C 8661.02,1127.5 8661.02,1035.5 8663,943.5C 8664.6,932.213 8670.76,925.213 8681.5,922.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 3193.5,923.5 C 3210.92,920.769 3220.08,928.103 3221,945.5C 3221.1,966.196 3221.77,986.863 3223,1007.5C 3225.7,1017.18 3232.2,1022.01 3242.5,1022C 3252.54,1023.31 3262.54,1023.98 3272.5,1024C 3292.09,1034.61 3292.42,1045.61 3273.5,1057C 3263.23,1058.23 3252.89,1058.9 3242.5,1059C 3228.03,1059.47 3221.2,1066.97 3222,1081.5C 3221.33,1121.83 3221.33,1162.17 3222,1202.5C 3222.05,1212.1 3224.39,1221.1 3229,1229.5C 3236.55,1237.56 3245.88,1240.9 3257,1239.5C 3266.58,1238.91 3276.08,1238.08 3285.5,1237C 3290.38,1238.35 3294.55,1240.85 3298,1244.5C 3299.7,1253.74 3297.2,1261.57 3290.5,1268C 3273.48,1276.61 3255.48,1279.61 3236.5,1277C 3217.38,1275.43 3201.54,1267.59 3189,1253.5C 3182.08,1241.47 3178.41,1228.47 3178,1214.5C 3177.33,1123.83 3177.33,1033.17 3178,942.5C 3178.7,932.315 3183.87,925.982 3193.5,923.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 5385.5,923.5 C 5397.24,921.942 5405.07,926.608 5409,937.5C 5410.28,956.115 5410.95,974.782 5411,993.5C 5410.82,1001.14 5412.15,1008.47 5415,1015.5C 5417.1,1018.47 5419.93,1020.3 5423.5,1021C 5437.88,1022.5 5452.21,1024.17 5466.5,1026C 5479.34,1035.2 5479.67,1044.87 5467.5,1055C 5462.32,1056.8 5456.99,1057.8 5451.5,1058C 5443.17,1058.33 5434.83,1058.67 5426.5,1059C 5418.64,1060.86 5413.81,1065.69 5412,1073.5C 5410.07,1119.5 5410.07,1165.5 5412,1211.5C 5413.66,1231.01 5424.33,1240.68 5444,1240.5C 5455.37,1239.59 5466.54,1237.59 5477.5,1234.5C 5484.62,1241.73 5488.12,1250.4 5488,1260.5C 5487.22,1262.29 5486.05,1263.79 5484.5,1265C 5463.17,1275.13 5440.84,1278.8 5417.5,1276C 5389.96,1272.79 5373.79,1257.62 5369,1230.5C 5367.48,1184.03 5366.65,1137.53 5366.5,1091C 5367,1040.17 5367.5,989.333 5368,938.5C 5370.18,929.168 5376.02,924.168 5385.5,923.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 7317.5,923.5 C 7326.01,922.078 7333.17,924.411 7339,930.5C 7339.86,932.087 7340.53,933.754 7341,935.5C 7341.19,958.184 7341.86,980.851 7343,1003.5C 7343.97,1012.8 7349.13,1017.63 7358.5,1018C 7370.17,1018.33 7381.83,1018.67 7393.5,1019C 7405.45,1017.95 7411.12,1023.29 7410.5,1035C 7410.2,1041.22 7409.53,1047.39 7408.5,1053.5C 7387.84,1054.88 7367.17,1056.21 7346.5,1057.5C 7345.34,1062.82 7344.17,1068.15 7343,1073.5C 7341.04,1117.17 7341.04,1160.83 7343,1204.5C 7344.29,1225.46 7355.29,1236.79 7376,1238.5C 7384.92,1237.52 7393.75,1236.02 7402.5,1234C 7417.24,1232.73 7424.07,1239.57 7423,1254.5C 7421.2,1261.63 7416.7,1266.13 7409.5,1268C 7385.53,1270.75 7361.53,1271.09 7337.5,1269C 7318.28,1264.78 7306.78,1252.94 7303,1233.5C 7301.87,1224.88 7301.2,1216.21 7301,1207.5C 7300.33,1120.5 7300.33,1033.5 7301,946.5C 7299.86,933.836 7305.36,926.17 7317.5,923.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 4382.5,926.5 C 4405.46,926.293 4415.62,937.626 4413,960.5C 4407.24,976.967 4395.74,983.467 4378.5,980C 4360.49,971.153 4355.65,957.653 4364,939.5C 4368.71,932.931 4374.88,928.598 4382.5,926.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 6809.5,1011.5 C 6820.7,1010.9 6831.7,1012.07 6842.5,1015C 6853.26,1020.31 6856.76,1028.81 6853,1040.5C 6849.9,1050.48 6843.07,1055.82 6832.5,1056.5C 6819.83,1053.33 6807.16,1053.16 6794.5,1056C 6778.43,1065.08 6767.93,1078.58 6763,1096.5C 6761.52,1101.41 6760.52,1106.41 6760,1111.5C 6759.95,1158.52 6759.29,1205.52 6758,1252.5C 6754.67,1264.45 6746.84,1268.95 6734.5,1266C 6727.33,1264.17 6722.83,1259.67 6721,1252.5C 6720.67,1248.5 6720.33,1244.5 6720,1240.5C 6719.67,1194.83 6719.33,1149.17 6719,1103.5C 6718.95,1081.8 6718.12,1060.13 6716.5,1038.5C 6717.88,1023.97 6725.88,1017.81 6740.5,1020C 6747.22,1022.06 6752.89,1025.73 6757.5,1031C 6760.21,1032.07 6762.87,1033.24 6765.5,1034.5C 6777.74,1022.05 6792.41,1014.38 6809.5,1011.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 6966.5,1011.5 C 6990.69,1009.1 7013.02,1014.27 7033.5,1027C 7051.23,1042.83 7058.73,1062.66 7056,1086.5C 7053.73,1105.56 7044.89,1120.72 7029.5,1132C 7010.05,1144.22 6989.05,1152.89 6966.5,1158C 6952.35,1159.52 6938.35,1161.85 6924.5,1165C 6913.97,1168.56 6910.13,1175.73 6913,1186.5C 6927.03,1227.93 6955.53,1244.76 6998.5,1237C 7006.06,1234.55 7013.39,1231.55 7020.5,1228C 7033.13,1221.64 7043.97,1223.81 7053,1234.5C 7053.06,1241.98 7050.9,1248.81 7046.5,1255C 7033.24,1262.96 7019.24,1269.29 7004.5,1274C 6952.99,1284.84 6912.49,1268.67 6883,1225.5C 6875.2,1211.43 6870.2,1196.43 6868,1180.5C 6867.09,1157.47 6867.42,1134.47 6869,1111.5C 6878.83,1055.84 6911.33,1022.5 6966.5,1011.5 Z M 6969.5,1045.5 C 6984.37,1043.96 6996.87,1048.62 7007,1059.5C 7014.41,1070.93 7015.41,1082.93 7010,1095.5C 7006.83,1100 7003,1103.83 6998.5,1107C 6978.23,1117.04 6957.23,1125.2 6935.5,1131.5C 6912.41,1127.66 6905.91,1114.99 6916,1093.5C 6926.4,1069.26 6944.24,1053.26 6969.5,1045.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 7158.5,1011.5 C 7191.39,1008.74 7220.22,1018.07 7245,1039.5C 7249.23,1049.82 7246.73,1058.32 7237.5,1065C 7230.86,1068.27 7224.19,1068.27 7217.5,1065C 7205.35,1057.92 7192.68,1051.92 7179.5,1047C 7160.48,1043.92 7144.65,1049.75 7132,1064.5C 7121.96,1084.29 7126.8,1099.45 7146.5,1110C 7165.28,1119.04 7184.61,1126.7 7204.5,1133C 7248.14,1152.45 7261.98,1184.61 7246,1229.5C 7236.41,1249.09 7221.24,1262.26 7200.5,1269C 7187.98,1271.62 7175.31,1273.12 7162.5,1273.5C 7162.17,1275.17 7161.17,1276.17 7159.5,1276.5C 7137.7,1270.8 7116.03,1264.63 7094.5,1258C 7084.6,1253.52 7081.76,1246.36 7086,1236.5C 7098.6,1224.99 7112.1,1223.82 7126.5,1233C 7143.54,1238.3 7160.87,1239.96 7178.5,1238C 7202.11,1229.92 7211.61,1213.75 7207,1189.5C 7201.32,1178.83 7192.82,1171.33 7181.5,1167C 7164.55,1161.51 7147.55,1156.18 7130.5,1151C 7090.7,1132.24 7076.87,1101.74 7089,1059.5C 7104.09,1031.92 7127.26,1015.92 7158.5,1011.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 8235.5,1011.5 C 8257.69,1011.11 8279.69,1012.94 8301.5,1017C 8317.01,1020.84 8325.84,1030.67 8328,1046.5C 8328.97,1053.13 8329.64,1059.8 8330,1066.5C 8330.98,1120 8332.15,1173.5 8333.5,1227C 8334.5,1238.5 8332.33,1249.33 8327,1259.5C 8322.18,1264.86 8316.35,1266.03 8309.5,1263C 8301.2,1261.29 8293.54,1258.13 8286.5,1253.5C 8266.54,1267.74 8244.21,1274.08 8219.5,1272.5C 8178.84,1275.27 8151,1257.94 8136,1220.5C 8114.05,1162.65 8119.05,1107.31 8151,1054.5C 8163.14,1038.04 8178.97,1026.54 8198.5,1020C 8210.78,1016.32 8223.11,1013.48 8235.5,1011.5 Z M 8241.5,1046.5 C 8277.48,1045.15 8294.15,1062.48 8291.5,1098.5C 8290.75,1125.49 8290.25,1152.49 8290,1179.5C 8284.53,1225.96 8258.7,1245.46 8212.5,1238C 8187.32,1229.48 8173.15,1211.98 8170,1185.5C 8164.23,1156.79 8165.56,1128.46 8174,1100.5C 8185.4,1068.59 8207.9,1050.59 8241.5,1046.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 8905.5,1011.5 C 8926.68,1009.42 8946.68,1013.25 8965.5,1023C 8981.97,1034.1 8991.14,1049.6 8993,1069.5C 8996.33,1103.69 8982.5,1127.86 8951.5,1142C 8924.99,1153.54 8897.32,1160.54 8868.5,1163C 8859.56,1162.96 8853.73,1167.12 8851,1175.5C 8852.59,1203.11 8866.09,1222.61 8891.5,1234C 8909.84,1241.29 8928.17,1241.29 8946.5,1234C 8954.72,1230.06 8963.05,1226.39 8971.5,1223C 8984.54,1222.2 8991.54,1228.37 8992.5,1241.5C 8992.52,1243.96 8992.02,1246.3 8991,1248.5C 8973.75,1263.82 8953.59,1272.98 8930.5,1276C 8874.09,1279.89 8834.59,1256.05 8812,1204.5C 8806.15,1185.96 8803.65,1166.96 8804.5,1147.5C 8802.63,1111.96 8812.13,1079.96 8833,1051.5C 8852.38,1029.39 8876.55,1016.06 8905.5,1011.5 Z M 8909.5,1045.5 C 8933.83,1044.51 8948.5,1055.85 8953.5,1079.5C 8952.88,1086.87 8950.38,1093.54 8946,1099.5C 8919.57,1124.15 8888.74,1132.32 8853.5,1124C 8851.18,1093.97 8862.51,1070.64 8887.5,1054C 8894.61,1050.26 8901.95,1047.43 8909.5,1045.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 9189.5,1188.5 C 9189.5,1194.83 9189.5,1201.17 9189.5,1207.5C 9185,1240.99 9166.33,1261.82 9133.5,1270C 9122.56,1271.67 9111.56,1272.84 9100.5,1273.5C 9100.19,1274.31 9099.69,1274.97 9099,1275.5C 9080.75,1272.26 9062.58,1268.43 9044.5,1264C 9037.34,1262.17 9031.17,1258.67 9026,1253.5C 9020.41,1241.78 9023.25,1232.61 9034.5,1226C 9040.22,1225.28 9045.89,1225.62 9051.5,1227C 9069.29,1232.82 9087.46,1236.99 9106,1239.5C 9124.94,1237.38 9138.27,1227.71 9146,1210.5C 9149.47,1196.28 9145.64,1184.45 9134.5,1175C 9129.28,1171.22 9123.61,1168.22 9117.5,1166C 9103.01,1161.06 9088.34,1156.73 9073.5,1153C 9032.96,1138.42 9017.13,1109.92 9026,1067.5C 9031.04,1047.27 9042.87,1032.43 9061.5,1023C 9097.86,1006.74 9133.52,1008.07 9168.5,1027C 9174.96,1031.12 9180.46,1036.29 9185,1042.5C 9186.96,1056.74 9180.79,1065.41 9166.5,1068.5C 9152.4,1063.45 9138.73,1057.29 9125.5,1050C 9109.49,1042.76 9094.49,1044.42 9080.5,1055C 9064.21,1066.01 9060.71,1080.18 9070,1097.5C 9072.5,1101.33 9075.67,1104.5 9079.5,1107C 9086.77,1111.47 9094.44,1115.14 9102.5,1118C 9122.14,1122.99 9140.8,1130.32 9158.5,1140C 9176.01,1151.66 9186.35,1167.83 9189.5,1188.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 7545.5,1012.5 C 7576.57,1011.34 7606.91,1015.51 7636.5,1025C 7646.14,1030.19 7650.31,1038.36 7649,1049.5C 7649.48,1115.5 7649.81,1181.5 7650,1247.5C 7647.07,1265.05 7637.24,1270.88 7620.5,1265C 7615.33,1259.34 7609.67,1254.17 7603.5,1249.5C 7582.05,1269.37 7556.71,1277.2 7527.5,1273C 7482.87,1270.27 7455.7,1247.11 7446,1203.5C 7436.42,1161 7439.09,1119.34 7454,1078.5C 7472.75,1040.21 7503.25,1018.21 7545.5,1012.5 Z M 7552.5,1047.5 C 7567.66,1045.61 7581.99,1048.11 7595.5,1055C 7601.52,1060.05 7604.69,1066.55 7605,1074.5C 7605.17,1109.85 7605.17,1145.18 7605,1180.5C 7604.67,1210.69 7590.17,1230.19 7561.5,1239C 7545.11,1242.92 7529.44,1240.92 7514.5,1233C 7504.05,1225.6 7496.88,1215.77 7493,1203.5C 7475.07,1156.65 7479.73,1112.32 7507,1070.5C 7519.72,1057.89 7534.89,1050.22 7552.5,1047.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 8513.5,1012.5 C 8540.74,1009.51 8563.24,1018.18 8581,1038.5C 8589.44,1050.83 8594.11,1064.49 8595,1079.5C 8596.53,1125.82 8596.87,1172.16 8596,1218.5C 8595.94,1230.9 8595.27,1243.24 8594,1255.5C 8589.35,1263.75 8582.18,1267.75 8572.5,1267.5C 8562.93,1266.43 8557.43,1261.1 8556,1251.5C 8554.44,1225.01 8554.27,1198.51 8555.5,1172C 8555.13,1145.79 8553.97,1119.63 8552,1093.5C 8549.17,1064.17 8533,1049.17 8503.5,1048.5C 8477.36,1051.46 8458.53,1064.8 8447,1088.5C 8444.44,1094.94 8442.78,1101.61 8442,1108.5C 8441.67,1154.83 8441.33,1201.17 8441,1247.5C 8438.69,1265.85 8429.19,1271.35 8412.5,1264C 8405.59,1257.69 8401.76,1249.86 8401,1240.5C 8400.59,1173.31 8399.42,1106.15 8397.5,1039C 8398.41,1023.75 8406.07,1018.41 8420.5,1023C 8426.68,1026.84 8433.18,1030 8440,1032.5C 8454.95,1028.41 8469.78,1023.91 8484.5,1019C 8494.2,1016.32 8503.86,1014.16 8513.5,1012.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 3590.5,1013.5 C 3611.68,1011.42 3631.68,1015.25 3650.5,1025C 3665.96,1034.07 3674.79,1047.57 3677,1065.5C 3682.17,1105.01 3666.67,1132.85 3630.5,1149C 3618.56,1153.74 3606.22,1157.07 3593.5,1159C 3573.96,1162.35 3554.29,1164.52 3534.5,1165.5C 3536.73,1185.03 3543.56,1202.7 3555,1218.5C 3570.5,1236.34 3590,1243.5 3613.5,1240C 3627.07,1237.7 3639.74,1233.03 3651.5,1226C 3659.88,1225.14 3667.38,1227.31 3674,1232.5C 3677.66,1241.86 3675.83,1250.03 3668.5,1257C 3632.65,1280.17 3594.65,1284.5 3554.5,1270C 3527,1257.5 3508.17,1237 3498,1208.5C 3478.21,1153.38 3484.87,1101.71 3518,1053.5C 3537.02,1030.91 3561.19,1017.57 3590.5,1013.5 Z M 3591.5,1049.5 C 3611.73,1046.49 3626.56,1053.82 3636,1071.5C 3639.83,1081.54 3638.49,1090.87 3632,1099.5C 3616.04,1116.06 3596.54,1125.56 3573.5,1128C 3561.47,1128.81 3549.47,1128.47 3537.5,1127C 3536.09,1103.23 3543.42,1082.56 3559.5,1065C 3569.06,1057.39 3579.73,1052.22 3591.5,1049.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 3986.5,1279.5 C 3981.5,1279.5 3976.5,1279.5 3971.5,1279.5C 3949.24,1275.35 3928.24,1267.52 3908.5,1256C 3883.76,1238.38 3869.92,1214.21 3867,1183.5C 3865.67,1174.04 3864.84,1164.54 3864.5,1155C 3866.44,1133.17 3870.28,1111.67 3876,1090.5C 3893.25,1041.11 3928.08,1015.44 3980.5,1013.5C 4001.38,1012.63 4020.04,1018.46 4036.5,1031C 4059.71,1055.86 4063.21,1083.36 4047,1113.5C 4033.82,1134.18 4015.32,1147.68 3991.5,1154C 3972.19,1157.76 3952.86,1161.43 3933.5,1165C 3927.17,1166 3921.34,1168.16 3916,1171.5C 3914.44,1201.21 3926.94,1222.71 3953.5,1236C 3977.91,1244.82 4001.25,1242.49 4023.5,1229C 4034.87,1223.8 4044.7,1225.96 4053,1235.5C 4055.37,1242.86 4053.87,1249.36 4048.5,1255C 4029.46,1267.62 4008.79,1275.78 3986.5,1279.5 Z M 3974.5,1048.5 C 3985.07,1048.39 3994.73,1051.22 4003.5,1057C 4018.57,1074.24 4017.9,1090.91 4001.5,1107C 3976.19,1125.83 3947.86,1132.67 3916.5,1127.5C 3912.99,1102.2 3920.32,1080.7 3938.5,1063C 3949.29,1054.91 3961.29,1050.08 3974.5,1048.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 4587.5,1013.5 C 4598.67,1013.23 4609.34,1015.4 4619.5,1020C 4632.92,1028.13 4646.09,1036.63 4659,1045.5C 4673.93,1032.78 4690.77,1023.28 4709.5,1017C 4741.4,1008.85 4769.57,1015.69 4794,1037.5C 4804.78,1050.15 4811.11,1064.82 4813,1081.5C 4813.5,1143.17 4813.67,1204.83 4813.5,1266.5C 4801.31,1267.22 4789.15,1268.22 4777,1269.5C 4775.56,1269.44 4774.23,1269.11 4773,1268.5C 4772.4,1262.16 4771.73,1255.83 4771,1249.5C 4770.67,1200.83 4770.33,1152.17 4770,1103.5C 4767.88,1060.72 4746.05,1043.89 4704.5,1053C 4683,1062.47 4671.17,1078.97 4669,1102.5C 4668.67,1153.17 4668.33,1203.83 4668,1254.5C 4665.84,1264.32 4659.67,1269.49 4649.5,1270C 4634.82,1271.74 4626.66,1265.24 4625,1250.5C 4624.67,1200.5 4624.33,1150.5 4624,1100.5C 4619.71,1060.29 4597.88,1044.45 4558.5,1053C 4539.57,1060.61 4527.74,1074.44 4523,1094.5C 4520.58,1102.68 4518.91,1111.02 4518,1119.5C 4517.08,1166.53 4516.08,1213.53 4515,1260.5C 4511.08,1266.63 4505.41,1269.63 4498,1269.5C 4487.56,1269.97 4480.89,1265.3 4478,1255.5C 4476.69,1186.16 4475.19,1116.82 4473.5,1047.5C 4473.97,1025.6 4484.3,1018.77 4504.5,1027C 4510,1031.17 4515.5,1035.33 4521,1039.5C 4540.75,1024.42 4562.92,1015.75 4587.5,1013.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 4991.5,1279.5 C 4984.17,1279.5 4976.83,1279.5 4969.5,1279.5C 4944.27,1275.05 4921.6,1264.89 4901.5,1249C 4889,1236.18 4880.5,1221.01 4876,1203.5C 4869.82,1179.21 4867.15,1154.54 4868,1129.5C 4872.68,1105.8 4880.35,1083.13 4891,1061.5C 4913.11,1030.27 4943.45,1014.27 4982,1013.5C 5044.26,1016.07 5067.6,1047.4 5052,1107.5C 5041.21,1129.66 5024.04,1144.49 5000.5,1152C 4978.33,1158.69 4955.67,1162.36 4932.5,1163C 4921.02,1163.16 4914.68,1168.83 4913.5,1180C 4919.36,1213.52 4939.02,1233.52 4972.5,1240C 4992.45,1242.01 5011.11,1238.01 5028.5,1228C 5038.39,1223.46 5046.89,1225.3 5054,1233.5C 5056.54,1241.05 5055.38,1247.88 5050.5,1254C 5032.4,1266.39 5012.74,1274.89 4991.5,1279.5 Z M 4968.5,1049.5 C 4985.1,1046.43 4998.93,1051.1 5010,1063.5C 5019.24,1082.05 5015.74,1097.88 4999.5,1111C 4985.19,1121.22 4969.19,1126.88 4951.5,1128C 4939.81,1128.69 4928.15,1128.53 4916.5,1127.5C 4914.59,1100.84 4923.59,1078.67 4943.5,1061C 4951.17,1055.51 4959.5,1051.68 4968.5,1049.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 5218.5,1013.5 C 5276,1015.5 5302.83,1045.16 5299,1102.5C 5299.68,1156.84 5299.51,1211.17 5298.5,1265.5C 5288.86,1266.77 5279.2,1267.27 5269.5,1267C 5261.88,1264.3 5258.38,1258.8 5259,1250.5C 5258.15,1212.5 5257.48,1174.5 5257,1136.5C 5256.77,1120.44 5255.77,1104.44 5254,1088.5C 5246.45,1059.65 5227.95,1047.15 5198.5,1051C 5169.04,1059.79 5151.54,1079.29 5146,1109.5C 5145.67,1156.5 5145.33,1203.5 5145,1250.5C 5140.04,1267.57 5129.87,1271.73 5114.5,1263C 5110.48,1259.99 5107.65,1256.16 5106,1251.5C 5104.33,1182.17 5103.33,1112.84 5103,1043.5C 5105.39,1026.55 5114.55,1021.39 5130.5,1028C 5133.66,1028.94 5136.49,1030.44 5139,1032.5C 5141.01,1037.2 5143.01,1041.87 5145,1046.5C 5166.09,1027.7 5190.59,1016.7 5218.5,1013.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 5613.5,1013.5 C 5639.11,1012.23 5662.44,1018.73 5683.5,1033C 5697.09,1044.68 5706.26,1059.18 5711,1076.5C 5716.06,1096.14 5720.23,1115.97 5723.5,1136C 5720.87,1158.71 5717.04,1181.21 5712,1203.5C 5694.78,1250.2 5661.28,1275.2 5611.5,1278.5C 5579.51,1277.58 5553.01,1265.25 5532,1241.5C 5524.05,1231.01 5518.38,1219.35 5515,1206.5C 5504.09,1166.91 5503.76,1127.25 5514,1087.5C 5526.48,1048.69 5552.65,1024.86 5592.5,1016C 5599.65,1015.16 5606.65,1014.33 5613.5,1013.5 Z M 5605.5,1049.5 C 5633.04,1046.85 5652.87,1057.85 5665,1082.5C 5667.29,1088.07 5669.29,1093.74 5671,1099.5C 5674.54,1115.88 5677.37,1132.38 5679.5,1149C 5675.2,1173.23 5667.7,1196.39 5657,1218.5C 5641.36,1239.22 5620.86,1246.05 5595.5,1239C 5577.75,1232.74 5565.92,1220.57 5560,1202.5C 5546.87,1165.67 5546.21,1128.67 5558,1091.5C 5566.58,1069.07 5582.41,1055.07 5605.5,1049.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 8082.5,1013.5 C 8089.56,1013.21 8096.56,1013.71 8103.5,1015C 8111.44,1018.29 8115.44,1024.29 8115.5,1033C 8115.37,1042.61 8111.37,1049.95 8103.5,1055C 8100.85,1055.56 8098.18,1056.06 8095.5,1056.5C 8052.65,1046.68 8029.15,1063.68 8025,1107.5C 8024.67,1154.5 8024.33,1201.5 8024,1248.5C 8016.34,1263.42 8004.5,1268.59 7988.5,1264C 7986.62,1262.46 7985.12,1260.62 7984,1258.5C 7982.07,1252.33 7981.07,1245.99 7981,1239.5C 7980.81,1172.66 7980.31,1105.83 7979.5,1039C 7978.57,1027.99 7983.57,1022.16 7994.5,1021.5C 8005.51,1023.67 8016.17,1027 8026.5,1031.5C 8032.5,1027.16 8038.83,1023.33 8045.5,1020C 8057.82,1016.95 8070.16,1014.78 8082.5,1013.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 2773.5,1014.5 C 2798.16,1012.44 2821.5,1016.94 2843.5,1028C 2864.46,1043.5 2872.96,1064.33 2869,1090.5C 2863.74,1116.52 2848.91,1134.69 2824.5,1145C 2818.91,1147.2 2813.24,1149.2 2807.5,1151C 2786.41,1156.49 2765.07,1160.82 2743.5,1164C 2738.47,1165.23 2733.8,1167.23 2729.5,1170C 2727.96,1171.58 2726.79,1173.41 2726,1175.5C 2726.75,1202.5 2739.25,1222 2763.5,1234C 2789.43,1244.13 2814.43,1242.13 2838.5,1228C 2849.4,1224.51 2858.57,1227.01 2866,1235.5C 2867.2,1242.89 2865.37,1249.39 2860.5,1255C 2840.09,1265.25 2818.76,1272.91 2796.5,1278C 2737.41,1280.92 2700.25,1253.76 2685,1196.5C 2676.26,1159.31 2677.93,1122.64 2690,1086.5C 2704.56,1046.75 2732.4,1022.75 2773.5,1014.5 Z M 2780.5,1049.5 C 2796.28,1047.15 2809.78,1051.48 2821,1062.5C 2833.12,1086.15 2826.95,1103.31 2802.5,1114C 2784.47,1120.9 2766.3,1127.4 2748,1133.5C 2727.12,1130.24 2720.62,1118.74 2728.5,1099C 2737.32,1073.34 2754.66,1056.84 2780.5,1049.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 3018.5,1014.5 C 3043.39,1011.44 3066.05,1016.94 3086.5,1031C 3102.33,1044.84 3110.5,1062.34 3111,1083.5C 3111.32,1105.34 3111.82,1127.17 3112.5,1149C 3112.06,1185.54 3111.23,1222.04 3110,1258.5C 3105.69,1266.57 3098.86,1270.24 3089.5,1269.5C 3081.43,1269.89 3075.6,1266.56 3072,1259.5C 3070.69,1213.55 3070.02,1167.55 3070,1121.5C 3069.7,1111.1 3068.7,1100.76 3067,1090.5C 3058.95,1057.72 3038.45,1045.22 3005.5,1053C 2977.73,1061.77 2961.89,1080.61 2958,1109.5C 2956.33,1149.33 2956.16,1189.16 2957.5,1229C 2957.56,1238.87 2957.06,1248.7 2956,1258.5C 2952.49,1266.76 2946.16,1270.43 2937,1269.5C 2927.99,1270.29 2921.66,1266.62 2918,1258.5C 2916.81,1252.92 2916.15,1247.25 2916,1241.5C 2915.45,1177.83 2915.12,1114.16 2915,1050.5C 2915.1,1044.34 2916.1,1038.34 2918,1032.5C 2920.67,1026.49 2925.34,1023.15 2932,1022.5C 2941.64,1024.8 2949.81,1029.64 2956.5,1037C 2958.72,1037.69 2960.89,1038.53 2963,1039.5C 2971.89,1033.62 2981.06,1028.12 2990.5,1023C 2999.68,1019.22 3009.01,1016.38 3018.5,1014.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 3428.5,1014.5 C 3442.63,1013.2 3456.3,1015.04 3469.5,1020C 3476.09,1025.32 3478.25,1032.15 3476,1040.5C 3474.46,1047.45 3470.62,1052.61 3464.5,1056C 3451.19,1057.07 3437.85,1058.07 3424.5,1059C 3407.16,1062 3395.66,1071.84 3390,1088.5C 3387.8,1096.35 3386.47,1104.35 3386,1112.5C 3385.5,1164.17 3385.33,1215.83 3385.5,1267.5C 3371.83,1267.5 3358.17,1267.5 3344.5,1267.5C 3343.63,1260.18 3342.8,1252.85 3342,1245.5C 3341.33,1177.5 3341.33,1109.5 3342,1041.5C 3341.93,1035.58 3343.77,1030.41 3347.5,1026C 3349.58,1025.13 3351.75,1024.63 3354,1024.5C 3363.06,1026.19 3371.9,1028.69 3380.5,1032C 3383.83,1032.67 3387.17,1032.67 3390.5,1032C 3399.15,1027.37 3407.81,1022.7 3416.5,1018C 3420.62,1016.81 3424.62,1015.64 3428.5,1014.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 4223.5,1014.5 C 4259.39,1013.92 4282.89,1030.59 4294,1064.5C 4296.81,1075.66 4298.48,1086.99 4299,1098.5C 4299.67,1154.5 4299.67,1210.5 4299,1266.5C 4287.91,1267.71 4276.74,1268.71 4265.5,1269.5C 4263.57,1269.46 4261.74,1269.13 4260,1268.5C 4257.13,1254.66 4255.79,1240.66 4256,1226.5C 4255.19,1190.32 4255.36,1154.15 4256.5,1118C 4257.09,1100.12 4253.26,1083.29 4245,1067.5C 4236.15,1055.41 4224.15,1049.41 4209,1049.5C 4181.46,1051.42 4161.79,1064.76 4150,1089.5C 4148.05,1094.34 4146.72,1099.34 4146,1104.5C 4145.67,1115.17 4145.33,1125.83 4145,1136.5C 4145.21,1175.83 4145.21,1215.16 4145,1254.5C 4142.51,1265.64 4135.51,1270.64 4124,1269.5C 4112.3,1270.67 4104.97,1265.67 4102,1254.5C 4101.79,1187.66 4101.29,1120.83 4100.5,1054C 4098.73,1024.06 4111.07,1016.06 4137.5,1030C 4139.33,1031.83 4141.17,1033.67 4143,1035.5C 4160.41,1029.81 4177.91,1024.31 4195.5,1019C 4204.86,1016.72 4214.19,1015.22 4223.5,1014.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 7882.5,1018.5 C 7894.6,1017.97 7904.1,1022.64 7911,1032.5C 7911.94,1036.11 7912.61,1039.78 7913,1043.5C 7913.67,1103.83 7913.67,1164.17 7913,1224.5C 7912.67,1230.17 7912.33,1235.83 7912,1241.5C 7909.87,1248.89 7907.54,1256.23 7905,1263.5C 7903.61,1264.55 7902.11,1264.72 7900.5,1264C 7888.53,1259.73 7876.53,1255.57 7864.5,1251.5C 7848.87,1263.31 7831.2,1270.14 7811.5,1272C 7755.9,1281.03 7724.07,1257.86 7716,1202.5C 7714.49,1149.84 7714.16,1097.18 7715,1044.5C 7714.95,1037.7 7716.62,1031.37 7720,1025.5C 7725.67,1019.79 7732.5,1017.96 7740.5,1020C 7753.67,1025.67 7760,1035.67 7759.5,1050C 7758.39,1089.16 7758.23,1128.33 7759,1167.5C 7757.58,1185.33 7760.58,1202.33 7768,1218.5C 7776.55,1232.78 7789.21,1240.11 7806,1240.5C 7836.55,1237.4 7856.88,1221.4 7867,1192.5C 7867.96,1187.88 7868.62,1183.21 7869,1178.5C 7869.33,1131.83 7869.67,1085.17 7870,1038.5C 7869.93,1028.94 7874.09,1022.28 7882.5,1018.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 4383.5,1023.5 C 4399.4,1022.9 4407.23,1030.57 4407,1046.5C 4407.2,1113.84 4407.2,1181.18 4407,1248.5C 4406.68,1262.15 4399.68,1269.15 4386,1269.5C 4376.35,1269.36 4370.35,1264.69 4368,1255.5C 4367.33,1183.17 4367.33,1110.83 4368,1038.5C 4369.83,1029.84 4374.99,1024.84 4383.5,1023.5 Z"
      />
    </g>
    <g>
      <path
        fill="#742b8f"
        d="M 6519.5,1026.5 C 6530.95,1024.02 6537.79,1028.35 6540,1039.5C 6540.77,1043.59 6540.43,1047.59 6539,1051.5C 6527.82,1084.73 6508.66,1112.23 6481.5,1134C 6458.86,1149.32 6433.86,1158.32 6406.5,1161C 6376.47,1165 6346.47,1169.17 6316.5,1173.5C 6323.18,1214.35 6347.18,1236.85 6388.5,1241C 6420.8,1243.24 6446.97,1231.74 6467,1206.5C 6474.09,1195.99 6480.42,1184.99 6486,1173.5C 6494.96,1162.76 6506.46,1156.26 6520.5,1154C 6531.21,1151.4 6541.55,1152.4 6551.5,1157C 6557.56,1161.61 6559.06,1167.44 6556,1174.5C 6554.46,1176.38 6552.62,1177.88 6550.5,1179C 6537.62,1178.84 6527.12,1183.67 6519,1193.5C 6512.33,1204.17 6505.67,1214.83 6499,1225.5C 6475.17,1253 6445,1268.84 6408.5,1273C 6364.62,1278.82 6328.12,1265.32 6299,1232.5C 6280.92,1203.78 6273.92,1172.45 6278,1138.5C 6285.08,1084.42 6315.25,1051.92 6368.5,1041C 6388.84,1037.72 6407.51,1041.72 6424.5,1053C 6432.95,1060.89 6438.28,1070.56 6440.5,1082C 6441.72,1091.54 6443.22,1101.04 6445,1110.5C 6445.31,1111.31 6445.81,1111.97 6446.5,1112.5C 6465.55,1101.09 6480.05,1085.43 6490,1065.5C 6496.15,1054.87 6501.48,1043.87 6506,1032.5C 6510.12,1029.41 6514.62,1027.41 6519.5,1026.5 Z M 6373.5,1070.5 C 6391.17,1070.83 6400.83,1079.83 6402.5,1097.5C 6402.16,1103.36 6400.32,1108.7 6397,1113.5C 6374.12,1136.35 6346.95,1143.85 6315.5,1136C 6322.01,1102.67 6341.34,1080.83 6373.5,1070.5 Z"
      />
    </g>
  </svg>
);

export const talents = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="nonzero"
      d="M12.5 0c.206 0 .385.108.463.296l3.287 6.97 7.32 1.103a.532.532 0 0 1 .257.915l-5.291 5.408 1.258 7.642c.026.215-.051.43-.205.538a.479.479 0 0 1-.308.108c-.077 0-.154 0-.231-.054L12.5 19.32l-6.575 3.633a.551.551 0 0 1-.54-.054.599.599 0 0 1-.205-.538L6.44 14.72 1.149 9.31a.651.651 0 0 1-.13-.565.472.472 0 0 1 .412-.376l7.32-1.104L12.037.296 8.75 7.266 1.43 8.368a.472.472 0 0 0-.41.376.651.651 0 0 0 .128.565l5.29 5.409-1.258 7.642a.599.599 0 0 0 .206.538c.154.108.36.135.54.054L12.5 19.32l6.55 3.606c.077.054.154.054.23.054a.479.479 0 0 0 .309-.108c.154-.107.231-.323.205-.538l-1.258-7.642 5.29-5.409a.532.532 0 0 0-.256-.914l-7.32-1.104L12.963.296A.483.483 0 0 0 12.5 0zm6.062 15.367l1.221 7.407c.048.397-.103.798-.393 1a.946.946 0 0 1-.598.208c-.205 0-.331-.022-.455-.112L12 20.388l-6.396 3.526c-.328.147-.705.106-1.038-.13a1.119 1.119 0 0 1-.371-1.006l1.218-7.384L.26 10.13a1.157 1.157 0 0 1-.225-1.006c.099-.415.414-.7.808-.75l7.082-1.066 3.17-6.71c.155-.376.51-.599.905-.599s.75.223.893.575l3.182 6.734 7.08 1.065c.37.055.703.359.806.735.104.381-.01.799-.28 1.032l-5.12 5.226zm-1.367-.816L22 9.64l-6.65-1.01a.547.547 0 0 1-.389-.3L12 2 9.039 8.33a.502.502 0 0 1-.39.3L2 9.64l4.805 4.911c.13.137.182.328.156.491L5.818 22l5.948-3.274A.728.728 0 0 1 12 18.67c.078 0 .156 0 .234.055L18.182 22l-1.143-6.958a.567.567 0 0 1 .156-.49z"
    />
  </svg>
);

export const candidates = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fillRule="evenodd" strokeWidth=".5">
      <path d="M12.396 13.235c3.38 0 6.122-2.733 6.122-6.103 0-3.37-2.741-6.085-6.122-6.085-3.379 0-6.12 2.733-6.12 6.085 0 3.351 2.741 6.103 6.12 6.103zm0-10.596c2.48 0 4.507 2.022 4.507 4.493 0 2.471-2.028 4.493-4.507 4.493-2.478 0-4.506-2.022-4.506-4.493 0-2.471 2.028-4.493 4.506-4.493zM1.808 22.766H23.06a.8.8 0 0 0 .808-.805c0-4.213-3.436-7.658-7.68-7.658H8.68C4.454 14.303 1 17.73 1 21.961a.8.8 0 0 0 .808.805zm6.87-6.853h7.511a6.055 6.055 0 0 1 6.009 5.243H2.67a6.07 6.07 0 0 1 6.008-5.243z" />
    </g>
  </svg>
);

export const managers = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fillRule="evenodd" strokeWidth=".5">
      <path d="M12.396 13.235c3.38 0 6.122-2.733 6.122-6.103 0-3.37-2.741-6.085-6.122-6.085-3.379 0-6.12 2.733-6.12 6.085 0 3.351 2.741 6.103 6.12 6.103zm0-10.596c2.48 0 4.507 2.022 4.507 4.493 0 2.471-2.028 4.493-4.507 4.493-2.478 0-4.506-2.022-4.506-4.493 0-2.471 2.028-4.493 4.506-4.493zM1.808 22.766H23.06a.8.8 0 0 0 .808-.805c0-4.213-3.436-7.658-7.68-7.658H8.68C4.454 14.303 1 17.73 1 21.961a.8.8 0 0 0 .808.805zm6.87-6.853h7.511a6.055 6.055 0 0 1 6.009 5.243H2.67a6.07 6.07 0 0 1 6.008-5.243z" />
    </g>
  </svg>
);

export const trainner = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fillRule="evenodd">
      <g transform="translate(0 1)">
        <path
          d="M22.523 1.375H1.418a.087.087 0 0 0-.086.086v1.29c0 .047.038.085.086.085h21.105a.086.086 0 0 0 .086-.086V1.461a.086.086 0 0 0-.086-.086z"
          mask="url(#b)"
        />
        <path
          d="M22.523.037H1.418C.636.037 0 .676 0 1.46v1.29c0 .785.636 1.424 1.418 1.424h1.186v11.094c0 .786.636 1.425 1.418 1.425h7.282v3.168L4.79 21.615a.67.67 0 0 0 .344 1.292l6.81-1.832.027.001.027-.001 6.81 1.832a.664.664 0 0 0 .816-.473.67.67 0 0 0-.471-.82l-6.516-1.752v-3.168h7.282c.782 0 1.418-.639 1.418-1.425V4.175h1.187c.781 0 1.417-.64 1.417-1.425V1.461c0-.785-.636-1.424-1.417-1.424zm.086 2.713a.086.086 0 0 1-.086.086H1.418a.086.086 0 0 1-.086-.086V1.461c0-.047.038-.086.086-.086h21.105c.047 0 .086.039.086.086v1.29zm-2.605 12.52a.086.086 0 0 1-.086.086H4.022a.086.086 0 0 1-.086-.087V4.175h16.068v11.094z"
          mask="url(#b)"
        />
      </g>
      <path d="M5.63 15.445a.663.663 0 0 0 .441-.167l4.094-3.632 1.75 1.586c.26.236.658.229.91-.017l4.453-4.34a.671.671 0 0 0 .015-.947.664.664 0 0 0-.942-.014l-4.007 3.905-1.728-1.567a.664.664 0 0 0-.887-.004l-4.54 4.027a.671.671 0 0 0 .441 1.17zM18.31 7.579a.666.666 0 0 0 .666-.67.667.667 0 0 0-1.137-.473.674.674 0 0 0 0 .946.668.668 0 0 0 .472.197z" />
    </g>
  </svg>
);

export const steps = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fillRule="evenodd">
      <path d="M5.905 15.726H.499a.5.5 0 0 0-.499.499v5.406a.5.5 0 0 0 .5.499h5.405a.499.499 0 0 0 .498-.5v-5.405a.499.499 0 0 0-.498-.5zM14.564 15.726H9.158a.5.5 0 0 0-.499.499v5.406a.5.5 0 0 0 .5.499h5.405a.5.5 0 0 0 .5-.5v-5.405a.5.5 0 0 0-.5-.5zM23.395 15.726h-5.406a.5.5 0 0 0-.5.499v5.406a.5.5 0 0 0 .5.499h5.406a.5.5 0 0 0 .499-.5v-5.405a.5.5 0 0 0-.499-.5zM9.243 6.637h5.406c.277 0 .5-.223.5-.498V.733a.5.5 0 0 0-.5-.5H9.243a.499.499 0 0 0-.497.5V6.14c0 .275.222.498.497.498zM2.372 14h1.024a.499.499 0 0 0 .498-.5v-2.105h7.043v2.111c0 .275.223.498.498.498h1.024a.498.498 0 0 0 .498-.498v-2.111H20v2.108c0 .275.223.497.498.497h1.024a.497.497 0 0 0 .498-.497v-3.63a.498.498 0 0 0-.498-.499h-8.565v-.692a.5.5 0 0 0-.5-.499h-1.022a.499.499 0 0 0-.498.5v.691H2.372a.499.499 0 0 0-.498.499V13.5c0 .275.223.5.498.5z" />
    </g>
  </svg>
);

export const vaga = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="nonzero"
      d="M19.2.125H5.8a.619.619 0 0 0-.625.625v22.5c0 .35.275.625.625.625h13.4c.35 0 .625-.275.625-.625V.75A.619.619 0 0 0 19.2.125zm-.7 22.4H8.25L13.15 20c.025 0 .025-.025.05-.025a.76.76 0 0 0 .2-.2l.075-.15c.025-.075.025-.125.025-.175V4.55a.664.664 0 0 0-.35-.575l-4.925-2.5h10.25v21.05h.025zM11.375 12.4v-.825a.475.475 0 1 1 .95 0v.825a.475.475 0 0 1-.475.475c-.275.025-.475-.2-.475-.475z"
    />
  </svg>
);

export const home = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="nonzero"
      d="M2 13.111h8.889V2H2v11.111zM2 22h8.889v-6.667H2V22zm11.111 0H22V10.889h-8.889V22zm0-20v6.667H22V2h-8.889z"
    />
  </svg>
);

export const search = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="none"
      fillRule="nonzero"
      d="M6.39 8.41L2.128 3.799C1.838 3.486 2.074 3 2.513 3h10.974c.44 0 .675.486.386.798L9.61 8.411v4.106c0 .356-.395.59-.736.434L6.68 11.947a.48.48 0 0 1-.288-.433V8.41zM3.638 3.965l3.652 3.95c.081.088.126.2.126.317v2.981l1.17.535V8.231c0-.117.045-.229.126-.317l3.652-3.95H3.637z"
    />
  </svg>
);

export const statusOk = (props) => (
  <svg {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="table/tbody/col-4" transform="translate(-22.000000, -18.000000)">
        <g
          id="ico/alert/ic-status-check"
          transform="translate(22.000000, 18.000000)"
        >
          <g id="ic_checkmark-on">
            <circle id="Oval" fill="#269400" cx="10" cy="10" r="10" />
            <path
              d="M8.60623429,14.375 C8.43653002,14.375 8.26667818,14.3118268 8.1369651,14.1851919 L5.19488341,11.3138389 C4.93530966,11.060569 4.93501453,10.6496546 5.19414556,10.3959521 C5.4532766,10.1423938 5.87384804,10.1421053 6.13327421,10.3953751 L8.60623429,12.8087944 L14.4917258,7.06493453 C14.751152,6.81137624 15.1715758,6.81180893 15.4308544,7.06551146 C15.6899855,7.31921399 15.6896903,7.73012842 15.4301166,7.98339826 L9.07550347,14.1851919 C8.94579039,14.3118268 8.77593855,14.375 8.60623429,14.375"
              id="checkmark"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const statusAlert = (props) => (
  <svg {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="table/tbody-5/col-4"
        transform="translate(-802.000000, -19.000000)"
      >
        <g
          id="ico/alert/ic-status-alert"
          transform="translate(802.000000, 19.000000)"
        >
          <g id="ic_checkmark-on">
            <circle id="Oval" fill="#FF586D" cx="10" cy="10" r="10" />
            <path
              d="M10.9549878,11.3442822 L9.56812652,11.3442822 L9.30048662,5.83333333 L11.2226277,5.83333333 L10.9549878,11.3442822 Z M10.2615572,14.1666667 L10.2493917,14.1666667 C9.60462287,14.1666667 9.16666667,13.6922141 9.16666667,13.0596107 C9.16666667,12.4026764 9.61678832,11.9525547 10.2615572,11.9525547 C10.906326,11.9525547 11.3321168,12.4026764 11.3442822,13.0596107 C11.3442822,13.6922141 10.9184915,14.1666667 10.2615572,14.1666667 Z"
              id="!"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const statusWait = (props) => (
  <svg {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="table/tbody-3/col-4"
        transform="translate(-802.000000, -19.000000)"
      >
        <g
          id="ico/alert/ic-status-options"
          transform="translate(802.000000, 19.000000)"
        >
          <g id="ic_checkmark-on">
            <circle id="Oval" fill="#EDCE60" cx="10" cy="10" r="10" />
            <g
              id="Group"
              transform="translate(4.166667, 8.333333)"
              fill="#FFFFFF"
            >
              <ellipse
                id="Oval-2"
                cx="1.35877403"
                cy="1.34583334"
                rx="1.35877403"
                ry="1.34583334"
              />
              <ellipse
                id="Oval-2-Copy"
                cx="5.84595351"
                cy="1.34583334"
                rx="1.35877403"
                ry="1.34583334"
              />
              <ellipse
                id="Oval-2-Copy-2"
                cx="10.333133"
                cy="1.34583334"
                rx="1.35877403"
                ry="1.34583334"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const cancel = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="table/tbody/col-4"
        transform="translate(-997.000000, -12.000000)"
        fill="#4A4A4A"
      >
        <g id="Group" transform="translate(997.000000, 12.000000)">
          <g id="ico/ic-cancelar">
            <g id="ico/action/circle/gray" opacity="0.7">
              <path
                d="M12,0 C5.37248437,0 0,5.37248437 0,12 C0,18.6275156 5.37248437,24 12,24 C18.6275156,24 24,18.6275156 24,12 C23.992125,5.3758125 18.6241875,0.007875 12,0 Z M12,22.5 C6.20104688,22.5 1.5,17.7989531 1.5,12 C1.5,6.20104687 6.20104688,1.5 12,1.5 C17.7989531,1.5 22.5,6.20104687 22.5,12 C22.4937656,17.796375 17.796375,22.4937656 12,22.5 Z"
                id="Fill-1"
              />
            </g>
            <g
              id="ico/action/cancel/gray"
              transform="translate(4.000000, 4.000000)"
              opacity="0.696591332"
            >
              <path
                d="M13.7825095,2.22657358 C13.4923363,1.93680973 13.0220702,1.93680973 12.7320828,2.22657358 L8.00462892,6.95038311 L3.27717504,2.22657358 C2.99206255,1.93170662 2.5217964,1.92358804 2.22674838,2.20843435 C1.93165394,2.49314148 1.92352909,2.96304511 2.20859515,3.25786567 C2.21458433,3.2640358 2.2205735,3.27020592 2.22674838,3.27619048 L6.95420226,8 L2.22674838,12.7238095 C1.93165394,13.0085167 1.92352909,13.4784203 2.20859515,13.7734264 C2.49352193,14.0682934 2.96378808,14.076412 3.2588361,13.7915657 C3.26501098,13.7855811 3.27118587,13.7795965 3.27717504,13.7734264 L8.00462892,9.04961689 L12.7320828,13.7734264 C13.0271772,14.0581335 13.4974434,14.0499686 13.7823237,13.755148 C14.0603792,13.4675182 14.0603792,13.011393 13.7823237,12.7238095 L9.05505557,8 L13.7825095,3.27619048 C14.0724968,2.98642663 14.0724968,2.516523 13.7825095,2.22657358 Z"
                id="Fill-4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const stop = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="table/tbody/col-4"
        transform="translate(-927.000000, -12.000000)"
        fill="#4A4A4A"
      >
        <g id="ico/ic_paralizar" transform="translate(927.000000, 12.000000)">
          <g id="Group-2">
            <g id="ico/action/circle/gray" opacity="0.7">
              <path
                d="M12,0 C5.37248437,0 0,5.37248437 0,12 C0,18.6275156 5.37248437,24 12,24 C18.6275156,24 24,18.6275156 24,12 C23.992125,5.3758125 18.6241875,0.007875 12,0 Z M12,22.5 C6.20104688,22.5 1.5,17.7989531 1.5,12 C1.5,6.20104687 6.20104688,1.5 12,1.5 C17.7989531,1.5 22.5,6.20104687 22.5,12 C22.4937656,17.796375 17.796375,22.4937656 12,22.5 Z"
                id="Fill-1"
              />
            </g>
            <g
              id="ico/action/pause/gray"
              transform="translate(4.000000, 4.000000)"
              opacity="0.697786411"
              stroke="#4A4A4A"
            >
              <g
                id="equal-mathematical-sign"
                transform="translate(4.000000, 2.000000)"
              >
                <path
                  d="M-4.57062005,6.70921986 L5.98918291,6.70921986 C6.38694575,6.70921986 6.70921986,6.39135644 6.70921986,6 C6.70921986,5.60827973 6.38694575,5.29078014 5.98918291,5.29078014 L-4.57062005,5.29078014 C-4.96850604,5.29078014 -5.29078014,5.60827973 -5.29078014,6 C-5.29078014,6.39135644 -4.96850604,6.70921986 -4.57062005,6.70921986 Z"
                  id="Fill-3"
                  strokeWidth="0.5"
                  transform="translate(0.709220, 6.000000) rotate(-90.000000) translate(-0.709220, -6.000000) "
                />
                <path
                  d="M1.10313881,6.70921986 L11.6629418,6.70921986 C12.0607046,6.70921986 12.3829787,6.39135644 12.3829787,6 C12.3829787,5.60827973 12.0607046,5.29078014 11.6629418,5.29078014 L1.10313881,5.29078014 C0.705252827,5.29078014 0.382978723,5.60827973 0.382978723,6 C0.382978723,6.39135644 0.705252827,6.70921986 1.10313881,6.70921986 Z"
                  id="Fill-3-Copy"
                  strokeWidth="0.5"
                  transform="translate(6.382979, 6.000000) rotate(-90.000000) translate(-6.382979, -6.000000) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const trilha = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.7"
    >
      <g
        id="table/tbody/col-4"
        transform="translate(-857.000000, -12.000000)"
        fill="#4A4A4A"
      >
        <g id="ico/ic-trilha" transform="translate(857.000000, 12.000000)">
          <g id="ico/action/circle/gray" fillRule="evenodd">
            <path
              d="M12,0 C5.37248437,0 0,5.37248437 0,12 C0,18.6275156 5.37248437,24 12,24 C18.6275156,24 24,18.6275156 24,12 C23.992125,5.3758125 18.6241875,0.007875 12,0 Z M12,22.5 C6.20104688,22.5 1.5,17.7989531 1.5,12 C1.5,6.20104687 6.20104688,1.5 12,1.5 C17.7989531,1.5 22.5,6.20104687 22.5,12 C22.4937656,17.796375 17.796375,22.4937656 12,22.5 Z"
              id="Fill-1"
            />
          </g>
          <g
            id="ico/action/trilha/gray"
            transform="translate(4.000000, 4.000000)"
            fillRule="nonzero"
          >
            <path
              d="M9.10707775,11.4921589 C9.85252011,11.7505078 10.5107507,11.915062 11.4564879,12.2091705 C11.0931635,16.8551085 6.75206434,15.0100659 9.10707775,11.4921589 Z M12.9830027,7.22427519 C12.9340483,5.82929457 12.6912601,3.99939147 10.7573458,4.28612016 C9.85077748,4.52555814 9.18093834,5.53315116 8.86589812,7.27156589 C8.69286863,8.22739147 8.79348525,9.56903101 8.99758713,10.3732713 C9.18394102,10.9400271 9.120563,10.9051899 9.32085791,11.0128217 C10.0960858,11.1895581 10.8635389,11.3852054 11.645067,11.5847054 C12.439008,11.0172984 13.0910724,8.00493798 12.9830027,7.22427519 Z M7.01479893,7.11721318 C7.21873995,6.31286434 7.31935657,4.97122481 7.14640751,4.01550775 C6.83160858,2.27701163 6.16168901,1.26925581 5.25495979,1.02998062 C3.32104558,0.743251938 3.07825737,2.57312791 3.02930295,3.96818992 C2.92123324,4.74871705 3.57337802,7.76126744 4.36739946,8.32856589 C5.14887399,8.12906589 5.91621984,7.9335814 6.69168901,7.75668217 C6.89174263,7.64915891 6.82836461,7.68396899 7.01479893,7.11721318 Z M4.55573727,8.9531938 C4.91892761,13.5990775 9.26002681,11.7540349 6.90506702,8.23618217 C6.15962466,8.49455814 5.50144772,8.65908527 4.55573727,8.9531938 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const order = (props) => (
  <svg {...props} width="10px" height="17px" viewBox="0 0 10 7" version="1.1">
    <g
      id="Symbols"
      strokeWidth="1"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="ico/action/arrow-down/whitelabel" strokeWidth="2">
        <polyline id="Shape" points="2 2 5 5 8 2" />
      </g>
    </g>
  </svg>
);

export const previousPage = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" opacity=".7">
      <path
        fill="#4A4A4A"
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12C23.992 5.376 18.624.008 12 0zm0 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12c-.006 5.796-4.704 10.494-10.5 10.5z"
      />
      <path
        stroke="#4A4A4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 15l-3-3 3-3"
      />
    </g>
  </svg>
);

export const nextPage = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" opacity=".7">
      <path
        fill="#4A4A4A"
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12C23.992 5.376 18.624.008 12 0zm0 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12c-.006 5.796-4.704 10.494-10.5 10.5z"
      />
      <path
        stroke="#4A4A4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 15l3-3-3-3"
      />
    </g>
  </svg>
);

export const backTenPages = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" opacity=".7">
      <path
        fill="#4A4A4A"
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12C23.992 5.376 18.624.008 12 0zm0 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12c-.006 5.796-4.704 10.494-10.5 10.5z"
      />
      <path
        stroke="#4A4A4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 15l-3-3 3-3, M16 15l-3-3 3-3"
      />
    </g>
  </svg>
);

export const forwardTenPages = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" opacity=".7">
      <path
        fill="#4A4A4A"
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12C23.992 5.376 18.624.008 12 0zm0 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12c-.006 5.796-4.704 10.494-10.5 10.5z"
      />
      <path
        stroke="#4A4A4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 15l3-3-3-3, M14 15l3-3-3-3"
      />
    </g>
  </svg>
);

export const show = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#4A4A4A"
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12C23.992 5.376 18.624.008 12 0zm0 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12c-.006 5.796-4.704 10.494-10.5 10.5z"
        opacity=".7"
      />
      <path
        fill="#818181"
        fillRule="nonzero"
        d="M18 12l-.124.232c-.02.039-.518.962-1.484 1.887-1.286 1.23-2.8 1.881-4.38 1.881-1.579 0-3.097-.65-4.39-1.88a8.385 8.385 0 0 1-1.496-1.886L6 12l.126-.234c.021-.039.524-.962 1.496-1.886C8.915 8.65 10.433 8 12.012 8c1.58 0 3.094.65 4.38 1.88a8.318 8.318 0 0 1 1.484 1.888L18 12zm-1.672.025l.018-.025a7.351 7.351 0 0 0-.936-1.093c-1.03-.986-2.197-1.487-3.398-1.487s-2.374.502-3.411 1.489A7.43 7.43 0 0 0 7.659 12l.015.022c.26.364.57.73.927 1.07 1.037.986 2.21 1.488 3.411 1.488s2.368-.501 3.398-1.486a7.35 7.35 0 0 0 .918-1.07zM12 10c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0 1.42a.582.582 0 0 0-.58.58c0 .319.261.58.58.58.319 0 .58-.261.58-.58a.582.582 0 0 0-.58-.58z"
      />
    </g>
  </svg>
);

export const alert = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="8" fill="#CFCFCF" />
      <path
        fill="#FFF"
        d="M8.764 9.075h-1.11L7.44 4.667h1.538l-.214 4.408zm-.555 2.258H8.2c-.515 0-.866-.38-.866-.885 0-.526.36-.886.876-.886s.857.36.866.886c0 .506-.34.885-.866.885z"
      />
    </g>
  </svg>
);
export const graficoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="82"
    viewBox="0 0 82 82"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 2)">
        <circle cx="39" cy="39" r="39" stroke="#17B3D2" strokeWidth="2.5" />
        <path
          fill="#17B3D2"
          fillRule="nonzero"
          stroke="#F0F0F0"
          strokeWidth=".3"
          d="M46.897 22.323a1.28 1.28 0 0 0-1.137 1.285V53.26c0 .71.57 1.285 1.273 1.285h8.54c.458.007.885-.237 1.116-.637.232-.4.232-.896 0-1.296a1.271 1.271 0 0 0-1.119-.637h-7.114l-.15-.15V25.043l.15-.15h5.691l.15.15v22.48c-.006.464.235.895.632 1.128.396.234.886.234 1.282 0 .397-.233.638-.664.632-1.13V23.608c0-.71-.57-1.285-1.273-1.285h-8.545a1.261 1.261 0 0 0-.128 0zM34.092 28.54a1.28 1.28 0 0 0-1.137 1.285V53.26c0 .71.57 1.285 1.273 1.285h8.537c.702 0 1.273-.575 1.273-1.285V29.825c0-.71-.57-1.284-1.273-1.284H34.22a1.261 1.261 0 0 0-.128 0zm1.559 2.57h5.691l.15.15v20.565l-.15.15h-5.691l-.15-.15V31.26l.15-.15zm-9.96.778c-2.52 0-4.592 2.091-4.592 4.633 0 1.408.644 2.726 1.723 3.605l.163.133-.179.11a5.625 5.625 0 0 0-2.656 4.776v8.115c0 .71.57 1.285 1.273 1.285h8.537c.702 0 1.272-.575 1.272-1.285v-8.115a5.625 5.625 0 0 0-2.656-4.775l-.178-.111.163-.133a4.656 4.656 0 0 0 1.723-3.605c0-2.542-2.073-4.633-4.593-4.633zm0 2.57c1.148 0 2.047.907 2.047 2.063 0 1.156-.9 2.063-2.047 2.063-1.147 0-2.047-.907-2.047-2.063 0-1.156.9-2.063 2.047-2.063zm0 7.652c1.684 0 2.996 1.326 2.996 3.035v6.68l-.15.15h-5.691l-.15-.15v-6.68c0-1.709 1.311-3.035 2.995-3.035z"
        />
      </g>
      <g transform="translate(54 54)">
        <circle cx="13" cy="13" r="13" fill="#17B3D2" />
        <path
          fill="#FFF"
          d="M13.546 12.086h5.062c.216 0 .392.176.392.393v.93a.392.392 0 0 1-.392.392h-5.062v4.807a.392.392 0 0 1-.393.392h-.419a.392.392 0 0 1-.392-.392V13.8h-4.95A.392.392 0 0 1 7 13.409v-.93c0-.217.176-.393.392-.393h4.95V7.392c0-.216.175-.392.392-.392h.42c.216 0 .392.176.392.392v4.694z"
        />
      </g>
    </g>
  </svg>
);

export const paperIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79"
    height="79"
    viewBox="0 0 79 79"
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="39.5"
        cy="39.5"
        r="38.25"
        stroke="#17B3D2"
        strokeWidth="2.5"
      />
      <g fill="#17B3D2" fillRule="nonzero">
        <path d="M27 53c0 2.21 1.816 4 4.056 4h18.657c2.24 0 4.056-1.79 4.056-4V32.1a.993.993 0 0 0-.304-.7l-10.9-10.15a.971.971 0 0 0-.66-.25h-10.85C28.816 21 27 22.79 27 25v28zm15.92-28.7l7.3 6.8h-6.286a.97.97 0 0 1-.734-.277.943.943 0 0 1-.28-.723v-5.8zm-13.892.7c0-1.105.908-2 2.028-2h9.836v7.1c-.014.8.302 1.57.875 2.136a3.013 3.013 0 0 0 2.167.864h7.807V53c0 1.105-.908 2-2.028 2H31.056c-1.12 0-2.028-.895-2.028-2V25z" />
        <path d="M31.72 44.077h16.405c.568 0 1.029-.413 1.029-.923s-.46-.923-1.029-.923H31.721c-.568 0-1.029.413-1.029.923s.46.923 1.029.923zM31.752 38.538h8.957c.586 0 1.06-.413 1.06-.923s-.474-.923-1.06-.923h-8.957c-.585 0-1.06.414-1.06.923 0 .51.475.923 1.06.923zM31.72 49.615h16.405c.568 0 1.029-.413 1.029-.923s-.46-.923-1.029-.923H31.721c-.568 0-1.029.414-1.029.923 0 .51.46.923 1.029.923z" />
      </g>
      <g>
        <g transform="translate(52 52)">
          <circle cx="13" cy="13" r="13" fill="#17B3D2" />
          <path
            fill="#FFF"
            d="M13.546 12.086h5.062c.216 0 .392.176.392.393v.93a.392.392 0 0 1-.392.392h-5.062v4.807a.392.392 0 0 1-.393.392h-.419a.392.392 0 0 1-.392-.392V13.8h-4.95A.392.392 0 0 1 7 13.409v-.93c0-.217.176-.393.392-.393h4.95V7.392c0-.216.175-.392.392-.392h.42c.216 0 .392.176.392.392v4.694z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const avatarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79"
    height="79"
    viewBox="0 0 79 79"
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="39.5"
        cy="39.5"
        r="38.25"
        stroke="#17B3D2"
        strokeWidth="2.5"
      />
      <g fill="#17B3D2">
        <path d="M39.61 41.353c4.927 0 8.923-4.1 8.923-9.155s-3.996-9.128-8.922-9.128-8.921 4.1-8.921 9.128c0 5.027 3.995 9.155 8.92 9.155zm0-15.895c3.614 0 6.57 3.033 6.57 6.74 0 3.707-2.956 6.74-6.57 6.74-3.611 0-6.567-3.033-6.567-6.74 0-3.707 2.956-6.74 6.568-6.74zM24.177 55.65h30.978c.657 0 1.177-.534 1.177-1.21 0-6.318-5.008-11.485-11.193-11.485H34.192C28.035 42.955 23 48.095 23 54.44c0 .675.52 1.208 1.177 1.208zm10.015-10.28H45.14c4.488 0 8.183 3.427 8.757 7.864h-28.46c.575-4.41 4.27-7.864 8.756-7.864z" />
      </g>
      <g>
        <g transform="translate(52 52)">
          <circle id="teste" cx="13" cy="13" r="13" fill="#17B3D2" />
          <path
            fill="#FFF"
            d="M13.546 12.086h5.062c.216 0 .392.176.392.393v.93a.392.392 0 0 1-.392.392h-5.062v4.807a.392.392 0 0 1-.393.392h-.419a.392.392 0 0 1-.392-.392V13.8h-4.95A.392.392 0 0 1 7 13.409v-.93c0-.217.176-.393.392-.393h4.95V7.392c0-.216.175-.392.392-.392h.42c.216 0 .392.176.392.392v4.694z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const medalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="82"
    viewBox="0 0 82 82"
  >
    <g fill="none" fillRule="evenodd">
      <g stroke="#17B3D2" strokeWidth="2.5" transform="translate(2 2)">
        <circle cx="39" cy="39" r="39" />
        <g
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(27 20)"
        >
          <ellipse cx="11.688" cy="11.479" rx="11.164" ry="11.362" />
          <path d="M17.27 21.307v18.576l-5.582-5.68-5.583 5.68V21.307" />
        </g>
      </g>
      <g transform="translate(54 54)">
        <circle cx="13" cy="13" r="13" fill="#17B3D2" />
        <path
          fill="#FFF"
          d="M13.546 12.086h5.062c.216 0 .392.176.392.393v.93a.392.392 0 0 1-.392.392h-5.062v4.807a.392.392 0 0 1-.393.392h-.419a.392.392 0 0 1-.392-.392V13.8h-4.95A.392.392 0 0 1 7 13.409v-.93c0-.217.176-.393.392-.393h4.95V7.392c0-.216.175-.392.392-.392h.42c.216 0 .392.176.392.392v4.694z"
        />
      </g>
    </g>
  </svg>
);

export const arrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="82"
    viewBox="0 0 82 82"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 2)">
        <circle cx="39" cy="39" r="39" stroke="#17B3D2" strokeWidth="2.5" />
        <path
          fill="#17B3D2"
          fillRule="nonzero"
          stroke="#F0F0F0"
          strokeWidth="1.2"
          d="M53.523 26.655c7.43 7.534 7.43 19.803 0 27.382-7.43 7.578-19.533 7.533-27.008 0-6.23-6.316-7.252-15.924-3.026-23.322l3.16 3.203c-2.581 5.548-1.647 12.45 2.892 17.051 5.784 5.865 15.172 5.865 20.912 0 5.784-5.864 5.784-15.382 0-21.201a14.606 14.606 0 0 0-19.444-1.353l2.803 2.842c4.227-2.978 10.145-2.572 13.882 1.263 4.227 4.285 4.227 11.322 0 15.607-4.227 4.286-11.168 4.286-15.395 0-2.98-3.022-3.87-7.397-2.67-11.187l3.783 3.835a6.65 6.65 0 0 0 1.913 4.285 6.543 6.543 0 0 0 9.344 0c2.58-2.616 2.58-6.857 0-9.473a6.504 6.504 0 0 0-7.742-1.173l2.98 3.023c.757-.046 1.514.27 2.092.857a2.942 2.942 0 0 1 0 4.105 2.846 2.846 0 0 1-4.049 0c-.578-.587-.845-1.354-.845-2.12l-2.937-3.068-3.114-3.158-2.804-2.842-3.07-3.112-2.536-2.571-1.78.315-4.494-4.556 4.138-.767.712-4.15 4.494 4.556-.311 1.805 2.536 2.571c7.52-6.18 18.599-5.729 25.584 1.353z"
        />
      </g>
      <g transform="translate(54 54)">
        <circle cx="13" cy="13" r="13" fill="#17B3D2" />
        <path
          fill="#FFF"
          d="M13.546 12.086h5.062c.216 0 .392.176.392.393v.93a.392.392 0 0 1-.392.392h-5.062v4.807a.392.392 0 0 1-.393.392h-.419a.392.392 0 0 1-.392-.392V13.8h-4.95A.392.392 0 0 1 7 13.409v-.93c0-.217.176-.393.392-.393h4.95V7.392c0-.216.175-.392.392-.392h.42c.216 0 .392.176.392.392v4.694z"
        />
      </g>
    </g>
  </svg>
);

export const paper = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79"
    height="79"
    viewBox="0 0 79 79"
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="39.5"
        cy="39.5"
        r="38.25"
        stroke="#005394"
        strokeWidth="2.5"
      />
      <g fill="#005394" fillRule="nonzero">
        <path d="M27 53c0 2.21 1.816 4 4.056 4h18.657c2.24 0 4.056-1.79 4.056-4V32.1a.993.993 0 0 0-.304-.7l-10.9-10.15a.971.971 0 0 0-.66-.25h-10.85C28.816 21 27 22.79 27 25v28zm15.92-28.7l7.3 6.8h-6.286a.97.97 0 0 1-.734-.277.943.943 0 0 1-.28-.723v-5.8zm-13.892.7c0-1.105.908-2 2.028-2h9.836v7.1c-.014.8.302 1.57.875 2.136a3.013 3.013 0 0 0 2.167.864h7.807V53c0 1.105-.908 2-2.028 2H31.056c-1.12 0-2.028-.895-2.028-2V25z" />
        <path d="M31.72 44.077h16.405c.568 0 1.029-.413 1.029-.923s-.46-.923-1.029-.923H31.721c-.568 0-1.029.413-1.029.923s.46.923 1.029.923zM31.752 38.538h8.957c.586 0 1.06-.413 1.06-.923s-.474-.923-1.06-.923h-8.957c-.585 0-1.06.414-1.06.923 0 .51.475.923 1.06.923zM31.72 49.615h16.405c.568 0 1.029-.413 1.029-.923s-.46-.923-1.029-.923H31.721c-.568 0-1.029.414-1.029.923 0 .51.46.923 1.029.923z" />
      </g>
    </g>
  </svg>
);

export const filterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="nonzero"
      d="M6.39 8.41L2.128 3.799C1.838 3.486 2.074 3 2.513 3h10.974c.44 0 .675.486.386.798L9.61 8.411v4.106c0 .356-.395.59-.736.434L6.68 11.947a.48.48 0 0 1-.288-.433V8.41zM3.638 3.965l3.652 3.95c.081.088.126.2.126.317v2.981l1.17.535V8.231c0-.117.045-.229.126-.317l3.652-3.95H3.637z"
    />
  </svg>
);

export const carrier = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="82"
    viewBox="0 0 82 82"
  >
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <circle cx="39" cy="39" r="39" stroke="#005394" strokeWidth="2.5" />
      <path
        fill="#005394"
        fillRule="nonzero"
        stroke="#F0F0F0"
        strokeWidth=".3"
        d="M46.897 22.323a1.28 1.28 0 0 0-1.137 1.285V53.26c0 .71.57 1.285 1.273 1.285h8.54c.458.007.885-.237 1.116-.637.232-.4.232-.896 0-1.296a1.271 1.271 0 0 0-1.119-.637h-7.114l-.15-.15V25.043l.15-.15h5.691l.15.15v22.48c-.006.464.235.895.632 1.128.396.234.886.234 1.282 0 .397-.233.638-.664.632-1.13V23.608c0-.71-.57-1.285-1.273-1.285h-8.545a1.261 1.261 0 0 0-.128 0zM34.092 28.54a1.28 1.28 0 0 0-1.137 1.285V53.26c0 .71.57 1.285 1.273 1.285h8.537c.702 0 1.273-.575 1.273-1.285V29.825c0-.71-.57-1.284-1.273-1.284H34.22a1.261 1.261 0 0 0-.128 0zm1.559 2.57h5.691l.15.15v20.565l-.15.15h-5.691l-.15-.15V31.26l.15-.15zm-9.96.778c-2.52 0-4.592 2.091-4.592 4.633 0 1.408.644 2.726 1.723 3.605l.163.133-.179.11a5.625 5.625 0 0 0-2.656 4.776v8.115c0 .71.57 1.285 1.273 1.285h8.537c.702 0 1.272-.575 1.272-1.285v-8.115a5.625 5.625 0 0 0-2.656-4.775l-.178-.111.163-.133a4.656 4.656 0 0 0 1.723-3.605c0-2.542-2.073-4.633-4.593-4.633zm0 2.57c1.148 0 2.047.907 2.047 2.063 0 1.156-.9 2.063-2.047 2.063-1.147 0-2.047-.907-2.047-2.063 0-1.156.9-2.063 2.047-2.063zm0 7.652c1.684 0 2.996 1.326 2.996 3.035v6.68l-.15.15h-5.691l-.15-.15v-6.68c0-1.709 1.311-3.035 2.995-3.035z"
      />
    </g>
  </svg>
);

export const formation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="82"
    viewBox="0 0 82 82"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#005394"
      strokeWidth="2.5"
      transform="translate(2 2)"
    >
      <circle cx="39" cy="39" r="39" />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(27 20)"
      >
        <ellipse cx="11.688" cy="11.479" rx="11.164" ry="11.362" />
        <path d="M17.27 21.307v18.576l-5.582-5.68-5.583 5.68V21.307" />
      </g>
    </g>
  </svg>
);

export const arrowDown = () => (
  <svg
    width="8px"
    height="6px"
    viewBox="0 0 8 6"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Elementos"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="elements/header/user"
        transform="translate(-173.000000, -18.000000)"
        stroke="#17B3D2"
        strokeWidth="1.3"
      >
        <g
          id="ico/action/arrow-down/primary"
          transform="translate(172.000000, 16.000000)"
        >
          <polyline id="Shape" points="2 3.5 5 6.5 8 3.5" />
        </g>
      </g>
    </g>
  </svg>
);

export const goal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="85"
    viewBox="0 0 85 85"
  >
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <circle cx="40.5" cy="40.5" r="40.5" stroke="#005394" strokeWidth="2.5" />
      <path
        fill="#005394"
        fillRule="nonzero"
        stroke="#F0F0F0"
        strokeWidth="1.2"
        d="M55.582 27.68c7.716 7.824 7.716 20.565 0 28.435-7.717 7.87-20.285 7.823-28.047 0-6.469-6.558-7.532-16.536-3.142-24.218l3.28 3.326c-2.68 5.761-1.71 12.929 3.004 17.707 6.007 6.09 15.756 6.09 21.717 0 6.006-6.09 6.006-15.974 0-22.017a15.168 15.168 0 0 0-20.192-1.406l2.91 2.952c4.39-3.092 10.536-2.67 14.417 1.311 4.39 4.45 4.39 11.758 0 16.209-4.39 4.45-11.598 4.45-15.987 0-3.096-3.14-4.02-7.683-2.773-11.618l3.928 3.982a6.906 6.906 0 0 0 1.987 4.45 6.794 6.794 0 0 0 9.703 0c2.68-2.717 2.68-7.12 0-9.837a6.754 6.754 0 0 0-8.04-1.218l3.096 3.138c.785-.046 1.57.281 2.171.89a3.055 3.055 0 0 1 0 4.263 2.955 2.955 0 0 1-4.204 0c-.6-.609-.878-1.405-.878-2.201l-3.05-3.186-3.234-3.279-2.911-2.951-3.188-3.232-2.634-2.67-1.848.327L17 22.106l4.297-.796.74-4.31 4.666 4.731-.323 1.874 2.633 2.67c7.81-6.417 19.314-5.949 26.569 1.406z"
      />
    </g>
  </svg>
);

export const aboutYou = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "79"}
    height={props.height ? props.height : "79"}
    viewBox="0 0 79 79"
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="39.5"
        cy="39.5"
        r="38.25"
        stroke="#005394"
        strokeWidth="2.5"
      />
      <g fill="#005394">
        <path d="M39.61 41.353c4.927 0 8.923-4.1 8.923-9.155s-3.996-9.128-8.922-9.128-8.921 4.1-8.921 9.128c0 5.027 3.995 9.155 8.92 9.155zm0-15.895c3.614 0 6.57 3.033 6.57 6.74 0 3.707-2.956 6.74-6.57 6.74-3.611 0-6.567-3.033-6.567-6.74 0-3.707 2.956-6.74 6.568-6.74zM24.177 55.65h30.978c.657 0 1.177-.534 1.177-1.21 0-6.318-5.008-11.485-11.193-11.485H34.192C28.035 42.955 23 48.095 23 54.44c0 .675.52 1.208 1.177 1.208zm10.015-10.28H45.14c4.488 0 8.183 3.427 8.757 7.864h-28.46c.575-4.41 4.27-7.864 8.756-7.864z" />
      </g>
    </g>
  </svg>
);

export const LoadAnimation = () => (
  <svg
    className="lds-typing"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle cx="27.5" cy="39.2104" r="5" fill="#3A1078">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="62.5;37.5;62.5;62.5"
        keyTimes="0;0.25;0.5;1"
        dur="1s"
        begin="-0.5s"
      />
    </circle>
    <circle cx="42.5" cy="37.9772" r="5" fill="#4E31AA">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="62.5;37.5;62.5;62.5"
        keyTimes="0;0.25;0.5;1"
        dur="1s"
        begin="-0.375s"
      />
    </circle>
    <circle cx="57.5" cy="44.9219" r="5" fill="#4E31AA">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="62.5;37.5;62.5;62.5"
        keyTimes="0;0.25;0.5;1"
        dur="1s"
        begin="-0.25s"
      />
    </circle>
    <circle cx="72.5" cy="62.5" r="5" fill="#C68FE6">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="62.5;37.5;62.5;62.5"
        keyTimes="0;0.25;0.5;1"
        dur="1s"
        begin="-0.125s"
      />
    </circle>
  </svg>
);

export const disabled = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="8" fill="#858585" />
      <g fill="#FFF" transform="translate(3.333 6.667)">
        <ellipse cx="1.087" cy="1.077" rx="1.087" ry="1.077" />
        <ellipse cx="4.677" cy="1.077" rx="1.087" ry="1.077" />
        <ellipse cx="8.267" cy="1.077" rx="1.087" ry="1.077" />
      </g>
    </g>
  </svg>
);

export const editIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill-rule="nonzero"
        d="M11.193 3.004l3.661 3.68-9.268 9.313-3.66-3.68 9.267-9.313zm6.44-.887L16 .476a1.615 1.615 0 0 0-2.29 0l-1.563 1.571 3.661 3.68 1.825-1.833c.49-.492.49-1.286 0-1.777zM.01 17.49a.418.418 0 0 0 .504.498l4.08-.994-3.659-3.68L.01 17.49z"
        opacity=".7"
      />
    </svg>
  );
};

export const trashIcon = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="ico/ic_trash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group" transform="translate(1.000000, 0.000000)" fill="#858585" fill-rule="nonzero">
                <path d="M22,3.5801105 L15.7333333,3.5801105 L15.7333333,2.32044199 C15.7333333,1.03889727 14.6886644,0 13.4,0 L9.6,0 C8.31133558,0 7.26666667,1.03889727 7.26666667,2.32044199 L7.26666667,3.5801105 L1,3.5801105 C0.44771525,3.5801105 0,4.02535218 0,4.57458564 C0,5.12381909 0.44771525,5.56906077 1,5.56906077 L3.03333333,5.56906077 L3.03333333,20.3535912 C3.03333333,22.3674472 4.67495592,24 6.7,24 L16.3,24 C18.3250441,24 19.9666667,22.3674472 19.9666667,20.3535912 L19.9666667,5.56906077 L22,5.56906077 C22.5522847,5.56906077 23,5.12381909 23,4.57458564 C23,4.02535218 22.5522847,3.5801105 22,3.5801105 Z M9.26666667,2.32044199 C9.26666667,2.13736417 9.41590508,1.98895028 9.6,1.98895028 L13.4,1.98895028 C13.5840949,1.98895028 13.7333333,2.13736417 13.7333333,2.32044199 L13.7333333,3.5801105 L9.26666667,3.5801105 L9.26666667,2.32044199 Z M17.9333333,20.3867403 C17.9333333,21.3021294 17.1871412,22.0441989 16.2666667,22.0441989 L6.7,22.0441989 C5.77952542,22.0441989 5.03333333,21.3021294 5.03333333,20.3867403 L5.03333333,5.56906077 L17.9666667,5.56906077 L17.9333333,20.3867403 Z" id="Shape"></path>
                <path d="M14,20 C14.5522847,20 15,19.5510376 15,18.9972145 L15,9.00278552 C15,8.44896237 14.5522847,8 14,8 C13.4477153,8 13,8.44896237 13,9.00278552 L13,18.9972145 C13,19.5510376 13.4477153,20 14,20 L14,20 Z" id="Path"></path>
                <path d="M9,20 C9.55228475,20 10,19.5510376 10,18.9972145 L10,9.00278552 C10,8.44896237 9.55228475,8 9,8 C8.44771525,8 8,8.44896237 8,9.00278552 L8,18.9972145 C8,19.5510376 8.44771525,20 9,20 Z" id="Path"></path>
            </g>
        </g>
    </svg>
  )
};