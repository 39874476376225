import React from 'react';

import './barprogresscard.scss';

export const BarProgressCard = ({data}) => {
    
    const getValue = (_data) => {
        const total =  data?.data?.reduce((_c, _n)=> _c + parseFloat(_n.value) , 0);
        const percent = Math.round(parseFloat(_data) * 100 / total);
        return percent;
    }

    return (
        <div className="dash-card-barprogress">
            <div className="box">
                <div className="title" style={{'--title': data?.customStyle?.color}}>
                    {data?.title || 'No title'}
                </div>
                <div className="content">
                    {
                        data?.data ? 
                         <>
                            <div className="leggend">
                                {
                                    data?.data?.map(item => {
                                        return (<div style={{'--color': item?.color, '--val': `${getValue(item?.value)}%`}} value={`${item?.label ? item?.label + ' - ' : ''}${item?.value}`}></div>)
                                    })
                                }
                            </div>
                            <div className="bar">
                                {
                                    data?.data?.map(item => {
                                        return (<div style={{'--color': item?.color, '--val': `${getValue(item?.value)}%`}} value={`${item?.label ? item?.label + ' - ' : ''}${item?.value}`}></div>)
                                    })
                                }
                            </div>
                         </>
                        :
                        <>No content</>
                    }
                </div>
            </div>
        </div>
    )
}