import {
  HeaderRow,
  Label,
  Li,
  Nav,
  TableBody,
  Ul,
} from './TabStyle';
import React, { Fragment, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const Tab = ({ columns, children, registerTableSelect }) => {
  const [selectedItems, setItems] = useState(columns);
  const setFocus = (item, action) => {
    const newState = selectedItems.map((e) => {
      if (item.name === e.name) {
        e.focus = true;
      } else {
        e.focus = false;
      }
      return e;
    });
    if (registerTableSelect) {
      registerTableSelect(item.position);
    }
    setItems(newState);
    if (action) {
      action(item.filter);
    }
  };
  useEffect(() => {
    setItems(columns);
  }, columns);
  return (
    <Fragment>
      <HeaderRow>
        <Nav>
          <Ul>
            {selectedItems.map((item, key) => (
              <Li key={key} onClick={() => setFocus(item, item.action)} selected={item.focus}>
                <Label>
                  {item.name}
                  {item.value !== undefined && `    (${item.value})`}
                </Label>
              </Li>
            ))}
          </Ul>
        </Nav>
      </HeaderRow>
      <TableBody>{children}</TableBody>
    </Fragment>
  );
};
Tab.propTypes = {
  columns: PropTypes.array,
  registerTableSelect: PropTypes.func,
};
Tab.defaultProps = {
  columns: ['Abertas', 'Finalizadas'],
  registerTableSelect: () => {},
};

export default Tab;
