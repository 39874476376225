import { Body, BoxAction } from "./TestFormStyle";
import {
  ButtonDefault,
  ButtonSecondary,
  SelectFieldInput,
  TextAreaField,
  TextField,
  RichTextInput

} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { isAtimer, required } from "../../../helpers/validations";

import BreadCrumb from "components/Layout/breadcrumb";
import InputMask from "react-input-mask";
import { LabelH1 } from "components/shared/CustomComponents";
import Load from "../../Layout/Load";
import PropTypes from "prop-types";
import Question from "components/Question";
import { formatErrorsMessages } from "../../../helpers/sharedHelpers";

const TestForm = ({
  formOptions,
  stage,
  requestCreateStage,
  stageId,
  requestCreateQuestion,
  requestUpdateQuestion,
  requestDeleteQuestion,
  setShowJobAssociated,
  updateStage,
  location,
  publishStage,
  isLoading,
  questionLoading,
  goBackPreviousPage,
  messagePublication,
  intl,
  canEdit
}) => {
  const [formValues, setFormValues] = useState({});
  const [formErros, setFormErros] = useState([]);
  const [formValidators, setFormValidators] = useState();
  const [submited, setSubmited] = useState(false);
  const [questions, insertQuestion] = useState([]);
  const [questionValues, insertQuestionValues] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const publishTest = () => {
    setSubmited(true);
    if (Object.keys(formErros).length === 0) {
      if (stageId) {
        publishStage(stageId || stage.id);
      }
    } else {
      const messages = formatErrorsMessages(formErros);
      messagePublication(messages);
    }
  };

  const saveTest = () => {
    setSubmited(true);
    if (Object.keys(formErros).length === 0) {
      if (!stageId)
        requestCreateStage(
          Object.assign(formValues, { formKind: 1, testId: stageId })
        );
      else if (stage)
        updateStage(
          Object.assign(formValues, { formKind: 1, testId: stage.id })
        );
    } else {
      const messages = formatErrorsMessages(formErros);
      messagePublication(messages);
    }
  };

  useEffect(() => {
    setFormValues({ ...stage });
    const previousQuestion = stage.testQuestions.map((question, index) => (
      <Question
        key={index}
        question={question.description}
        position={index + 1}
        testId={stage.stageId}
        questionValues={questionValues}
        insertQuestionValues={insertQuestionValues}
        requestCreateQuestion={requestCreateQuestion}
        previousValues={question}
        questionId={question.id}
        formOptions={formOptions}
        deleteQuestionItem={removeQuestionItem}
        requestUpdateQuestion={requestUpdateQuestion}
        requestDeleteQuestion={requestDeleteQuestion}
      />
    ));
    insertQuestion(previousQuestion);
  }, [stage]);

  const insertQuestionItem = () => {
    const oldItems = questions;
    oldItems.push(
      <Question
        key={oldItems.length}
        position={oldItems.length + 1}
        questionValues={questionValues}
        insertQuestionValues={insertQuestionValues}
        requestCreateQuestion={requestCreateQuestion}
        deleteQuestionItem={removeQuestionItem}
        testId={stageId}
        formOptions={formOptions}
        requestDeleteQuestion={requestDeleteQuestion}
      />
    );
    insertQuestion(oldItems);
    setIsEditing(!isEditing);
  };

  const removeQuestionItem = index => {
    const oldItems = questions;
    oldItems.splice(index, 1);
    insertQuestion(oldItems);
    setIsEditing(false);
  };

  const renderButtonCreateQuestions = () => {
    return (
      <Row>
        <Col
          lg={3}
          style={{
            justifyContent: "flex-start",
            display: "flex",
            marginTop: "1em"
          }}
        >
          <ButtonSecondary
            onClick={() => insertQuestionItem()}
            width="301px"
            disabled={!stageId || !canEdit()}
            name={<FormattedMessage id="sharedItems.addQuestion" />}
          />
        </Col>
      </Row>
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={{ span: 5 }}>
          <BreadCrumb
            location={location}
            title={<FormattedMessage id="testForm.newTeste" />}
          />
        </Col>
        <Col lg={{ span: 3 }}>
          <ButtonSecondary
            onClick={() => setShowJobAssociated(true)}
            disabled={stageId === undefined || !canEdit()}
            name={<FormattedMessage id="newStageAboutYou.associationJob" />}
          />
        </Col>
        <Col lg={2}>
          <ButtonDefault
            disabled={(!canEdit())}
            onClick={() => saveTest()}
            width={"100%"}
            name={<FormattedMessage id="sharedItems.save" />}
          />
        </Col>
        <Col lg={2}>
          <ButtonDefault
            disabled={!stageId || !canEdit()}
            width={"100%"}
            onClick={() => publishTest()}
            name={<FormattedMessage id="sharedItems.finish" />}
          />
        </Col>
      </Row>
      <Body>
        <Row>
          <Col lg={8}>
            <TextField
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              validations={[required]}
              onChange={event =>
                setFormValues({ ...formValues, name: event.target.value })
              }
              label={<FormattedMessage id="sharedItems.name" />}
              type="text"
              name={intl.formatMessage({ id: "sharedItems.name" })}
              value={formValues.name}
            />
          </Col>
          <Col lg={2}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              validations={[required]}
              items={formOptions.kindTest}
              onChange={event =>
                setFormValues({ ...formValues, kind: event.target.value })
              }
              label={<FormattedMessage id="rails.kindTest" />}
              type="text"
              name={intl.formatMessage({ id: "rails.kindTest" })}
              value={formValues.kind}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              validations={[required]}
              items={formOptions.avaliation}
              onChange={event =>
                setFormValues({ ...formValues, avaliation: event.target.value })
              }
              label={<FormattedMessage id="sharedItems.avaliation" />}
              type="text"
              name={intl.formatMessage({ id: "sharedItems.avaliation" })}
              value={formValues.avaliation}
            />
          </Col>
          <Col lg={3}>
            <SelectFieldInput
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              validations={[required]}
              items={formOptions.levelTest}
              onChange={event =>
                setFormValues({ ...formValues, testLevel: event.target.value })
              }
              label={<FormattedMessage id="sharedItems.levelTest" />}
              type="text"
              name={intl.formatMessage({ id: "sharedItems.levelTest" })}
              value={formValues.testLevel}
            />
          </Col>
          <Col lg={3}>
            <InputMask
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              validations={[isAtimer]}
              maskChar={null}
              alwaysShowMask
              mask="99:99:99"
              onChange={event =>
                setFormValues({ ...formValues, testTime: event.target.value })
              }
              label={<FormattedMessage id="sharedItems.timeTest" />}
              type="text"
              name={intl.formatMessage({ id: "sharedItems.timeTest" })}
              value={formValues.testTime}
            >
              {inputProps => <TextField {...inputProps} />}
            </InputMask>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <RichTextInput
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              validations={[required]}
              onChange={event =>
                setFormValues({
                  ...formValues,
                  description: event
                })
              }
              label={<FormattedMessage id="sharedItems.description" />}
              name={intl.formatMessage({ id: "sharedItems.description" })}
              value={formValues.description}
            />
          </Col>
        </Row>
        {!isLoading && renderButtonCreateQuestions()}
        {!isLoading ? (
          questions.map(question => question)
        ) : (
          <Load isLoading={true} insideComponent />
        )}
      </Body>
    </React.Fragment>
  );
};
TestForm.propTypes = {
  insertQuestion: PropTypes.func,
  formOptions: PropTypes.objectOf({
    kindTest: [],
    avaliation: [],
    levelTest: [],
    weight: []
  }),
  requestCreateStage: PropTypes.func
};
TestForm.defaultProps = {
  insertQuestion: () => { },
  formOptions: {
    kindTest: [],
    avaliation: [],
    levelTest: []
  },
  requestCreateStage: () => { }
};

export default injectIntl(TestForm);
