import {
  ButtonDefault,
  ButtonSecondary,
  CheckBox,
} from "components/shared/CustomInputs";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import BreadCrumb from "components/Layout/breadcrumb";
import { FormattedMessage } from "react-intl";
import { LabelH1 } from "components/shared/CustomComponents";
import { Redirect } from "react-router-dom";
import { Body, RowActions } from "./CheckoutItemsStyle";
import {
  candidatesActions,
  pendingCandidateAction,
  bancoTalentosCandidateAction,
  contratarCandidateAction,
  returnForAnalysisAction,
} from "../../../locale";
import DialogConfirm from "../DialogConfirm";
import { getStageStatusName } from "../../../helpers/sharedHelpers";
import { order } from "../../../assets/icons/icons";

export default function CheckoutItems({
  candidates,
  kindOfAction,
  vacancy,
  stageId,
  requestManagerCandidates,
  goBack,
  stageType,
}) {
  const [showDialogConfirm, setshowDialogConfirm] = useState(false);
  const [contextCandidates, setContextCandidates] = useState([]);
  const [feedback, setFeedBackText] = useState("");

  const APPROVED = candidatesActions[0].id;
  const DECLINE = candidatesActions[1].id;
  const TO_REMOVE = candidatesActions[2].id;
  const PENDING = pendingCandidateAction.id;
  const BANKOFTALENTS = bancoTalentosCandidateAction.id;
  const HIRE = contratarCandidateAction.id;
  const RETURN_FOR_ANALYSIS = returnForAnalysisAction.id;

  const requestToRegisterActions = (enabledSendToCandidates) => {
    setshowDialogConfirm(!showDialogConfirm);
    requestManagerCandidates(
      contextCandidates.filter((e) => e.selected),
      kindOfAction,
      vacancy,
      enabledSendToCandidates,
      feedback,
      stageId
    );
  };

  const removeCandidateFromCheckout = (id) => {
    const dupContextList = contextCandidates.map((item) => {
      if (item.id === id) {
        item.selected = !item.selected;
      }
      return item;
    });
    setContextCandidates(dupContextList);
  };

  useEffect(() => {
    if (candidates.length) {
      setContextCandidates(candidates);
    } else {
      return <Redirect to="home" />;
    }
  }, [candidates]);

  const renderTitle = () => {
    if (kindOfAction == APPROVED)
      return <FormattedMessage id="sharedItems.confirmApprove" />;

    if (kindOfAction == DECLINE)
      return <FormattedMessage id="sharedItems.declineApprove" />;

    if (kindOfAction == PENDING)
      return <FormattedMessage id="sharedItems.pending" />;

    if (kindOfAction == BANKOFTALENTS)
      return <FormattedMessage id="sharedItems.bankofTalents" />;

    if (kindOfAction == HIRE) return <FormattedMessage id="sharedItems.hire" />;

    if (kindOfAction == TO_REMOVE)
      return <FormattedMessage id="sharedItems.confirmRemoval" />;

    if (kindOfAction == RETURN_FOR_ANALYSIS)
      return <FormattedMessage id="sharedItems.returnForAnalysis" />;

    return <FormattedMessage id="sharedItems.empty" />;
  };

  const renderTableItems = (candidate) =>
    candidate.map((candidate) => (
      <TableRow key={candidate.id}>
        <TableColumn ta="center">
          <CheckBox
            onChange={() => removeCandidateFromCheckout(candidate.id)}
            checked={candidate.selected}
          />
          <Label>{candidate.code}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.name}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.currentStage}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label
            color={candidate.resultStatus !== 2 ? "softGreen" : "errorColor"}
          >
            {candidate.result}
          </Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.applyedAt}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{getStageStatusName(candidate.status)}</Label>
        </TableColumn>
      </TableRow>
    ));
  return (
    <React.Fragment>
      <BreadCrumb />
      <RowActions>
        <LabelH1>{renderTitle()}</LabelH1>
        <div>
          <ButtonSecondary
            onClick={() => goBack()}
            name={<FormattedMessage id="sharedItems.cancel" />}
            width="223px"
            style={{ marginRight: "10px" }}
          />
          <ButtonDefault
            onClick={() => setshowDialogConfirm(!showDialogConfirm)}
            name={renderTitle()}
            width="223px"
          />
        </div>
      </RowActions>
      <Body>
        <Table>
          <TableBody>
            <TableRow noHover noBorder>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.code" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.name" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.current" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.result" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.applyAt" />
                </LabelHeader>
                {order()}
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.status" />
                </LabelHeader>
                {order()}
              </TableHead>
            </TableRow>
            {renderTableItems(contextCandidates)}
          </TableBody>
        </Table>
      </Body>
      <DialogConfirm
        requestToRegisterActions={(enabledSendToCandidates) =>
          requestToRegisterActions(enabledSendToCandidates)
        }
        closeDialog={setshowDialogConfirm}
        numberOfCandidates={candidates.length}
        kindOfAction={kindOfAction}
        show={showDialogConfirm}
        setFeedBackText={setFeedBackText}
        stageType={stageType}
      />
    </React.Fragment>
  );
}
