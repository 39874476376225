import * as urlsApi from "../../utils/contants/urlsApi";

import { buildErroHandleItems, buildPagination } from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/jobs";

function getJobApi(params) {
  return API.get(
    `${urlsApi.vacancyApi}?campo=${params.campo}&valor=${params.valor}&NumeroPagina=${params.NumeroPagina}
    &site=${params.site || ""}&responsavel=${params.responsavel || ""}&localTrabalho=${params.localTrabalho || ""}&cargo=${params.cargo || ""}
    &codigoVaga=${params.codigoVaga || ""}&nomeVaga=${params.nomeVaga || ""}&selecao=${params.selecao || ""}`

  )
    .then(res => res.data)
    .catch(error => { throw error });
}

function getJobSearchApi(params) {
  return API.get(
    `${urlsApi.vacancySearchApi}?status=0&valor=${params.valor}&NumeroPagina=${params.NumeroPagina}`
  )
    .then(res => res.data)
    .catch(error => { throw error });
}

function deleteJobApi(id) {
  return API.delete(`${urlsApi.DELETE_VACANCY}/${id}`)
  .then((res) => res.data)
  .catch((error) => { throw error })
}

function* getJobList({ payload }) {
  try {
    const { lista, paginacao } = yield getJobApi(payload);
    const stateJobList = yield select(getStateJobList);
    const itemsToPage = buildPagination(paginacao, payload, lista, stateJobList);
    yield put({
      type: Types.JOBS_SUCCESS,
      jobs: lista,
      pagination: itemsToPage
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.JOBS_FAIL, messages });
  }
}

function* getJobSearch({ term }) {
  try {
    const { lista, paginacao } = yield getJobSearchApi({
      valor: term.valor,
      NumeroPagina: term.NumeroPagina,
      campo: "busca"
    });
    yield put({ type: Types.JOBS_SUCCESS, jobs: lista, paginacao });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.JOBS_FAIL, error });
  }
}

function requestdeleteJob(body) {
  return API.put(
    `${urlsApi.CANCELAR_VAGA}`,
    body
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* deleteJob( payload) {
  try {
    yield requestdeleteJob(payload.id)
    let filter = payload.payload;

    const { lista, paginacao } = yield getJobApi(payload.payload);
    const stateJobList = yield select(getStateJobList);
    const itemsToPage = buildPagination(paginacao, payload.payload, lista, stateJobList);
    yield put({
      type: Types.JOBS_SUCCESS,
      jobs: lista,
      pagination: itemsToPage
    });
    
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.JOBS_DELETE_REQUEST_FAIL, error });
  }
}

const getStateJobList = state => state.jobs?.jobs;

export function* watcherSaga() {
  yield takeLatest(Types.JOBS_REQUEST, getJobList);
  yield takeLatest(Types.JOBS_SEARCH_REQUEST, getJobSearch);
  yield takeLatest(Types.JOBS_DELETE_REQUEST, deleteJob);
}
