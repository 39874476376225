import styled from 'styled-components';
import theme from 'components/shared/Theme';


export const FormBody = styled.div`
padding:20px
`;

export const QuestionsInfo = styled.span`
  font-family: Arial;
  text-align:right;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: gray
`;
export const OcupationInfo = styled(QuestionsInfo)`
  font-family: Arial;
  font-size: 13px;
  color: ${theme.colors.defaultColor}
`;
export const NumberItems = styled(QuestionsInfo)`
    font-size: 16px;
    color: ${props => (props.color ? theme.colors.defaultColor : 'gray')};
    margin-left:5px;
`;
