import styled from 'styled-components';
import theme from 'components/shared/Theme';

export const Body = styled.div`
  background-color: ${theme.colors.whiteDefault};
    padding: 20px 30px;
    box-shadow: 0 0 2px rgba(208,208,208,0.4);
    border-radius: 3px;
    border: 1px solid #eaeaea;
`;

export const ActionsRow = styled.div`
  margin-bottom: 20px;
`;
