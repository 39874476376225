import styled from "styled-components";
import theme from "../shared/Theme";

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Pages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;
export const Amount = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 15px;
    order: 1;
  }
`;
export const LabelAmout = styled.div`
  font-family: Arial;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.labelSecondary};
`;
export const LabelPages = styled(LabelAmout)`
  color: ${theme.colors.colorLabelDefault};
`;
export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 50px;
  @media (max-width: 768px) {
    margin-top: 15px;
    justify-content: flex-start;
    order: 2;
  }
`;
export const PageIcon = styled(LabelAmout)`
  width: 25px;
  text-align: center;
  border: ${(props) =>
    props.selected && `2px solid ${theme.colors.titleColor}`};
  border-radius: ${(props) => props.selected && "20px"};
  height: 24px;
  margin-left: 11px;
  margin-right: 11px;
  padding-right: 2px;
  padding-top: 1px;
  padding-left: 1px;
  &:hover {
    cursor: ${(props) => props.hasAction && "pointer"};
    border: ${(props) =>
      props.hasAction && `2px solid ${theme.colors.titleColor}`};
    border-radius: ${(props) => props.hasAction && "20px"};
  }
`;
export const Icons = styled.div`
  pointer-events: ${(props) => (props.noClick ? "none" : "")};
  &:hover {
    cursor: pointer;
  }
  margin-right: 21px;
  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;
