import {
  Body,
  CardBody,
  Icon,
  IconBox,
  IconLabel,
  RowCard,
} from './CardStyle';
import { Col, Row } from 'react-bootstrap';
import { Span, Text } from '../../shared/CustomComponents';
import {
  aboutYou,
  cancel,
  carrier,
  edit,
  formation,
  goal,
  paper,
} from '../../../assets/icons/icons';

import PropTypes from 'prop-types';
import React from 'react';

const renderIcon = (kind) => {
  switch (kind) {
    case 'about':
      return aboutYou();
    case 'formation':
      return formation();
    case 'carrier':
      return carrier();
    case 'goal':
      return goal();
    default:
      return paper();
  }
};
const renderDefaultCard = ({
  kind,
  title,
  numberOfQuestions,
  openEdit,
  renderDialogToDelete,
  questions,
  description,
  typeBlock,
}) => (
  <Body>
    <CardBody>
      <RowCard className="row">
        <Col lg={1}>{renderIcon(kind)}</Col>
        <Col lg={11}>
          <Row>
            <Col md={2} lg={6}>
              <Span fs="16px" bold>
                {title}
                <Span ml="15px" fs="14px" color="softGray">
                  Qtde de Perguntas:
                  <Span fs="16px" color="darkGray" ml="5px" lh="1.5">
                    {questions.length}
                  </Span>
                </Span>
                <Span ml="15px" fs="14px" color="softGray">
                  Tempo médio:
                  <Span fs="16px" color="darkGray" ml="5px" lh="1.5">
                    {numberOfQuestions}
                  </Span>
                </Span>
              </Span>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col lg={10}>
              <Text color="darkGray" bold>
                {description}
              </Text>
            </Col>
            {kind !== 'default' && (
              <React.Fragment>
                <Col lg={1}>
                  <IconBox onClick={() => openEdit(kind)}>
                    <Icon>{edit()}</Icon>
                    <IconLabel>Editar</IconLabel>
                  </IconBox>
                </Col>
                <Col onClick={() => renderDialogToDelete(typeBlock)} lg={1}>
                  <IconBox>
                    <Icon>{cancel()}</Icon>
                    <IconLabel>Excluir</IconLabel>
                  </IconBox>
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Col>
      </RowCard>
    </CardBody>
  </Body>
);
const Card = (props) => {
  const { kind } = props;
  const { questions } = props;
  if (questions.length || kind === 'default') {
    return renderDefaultCard(props);
  }
  return null;
};

Card.propTypes = {
  kind: PropTypes.string,
  description: PropTypes.string,
  numberOfQuestions: PropTypes.number,
  questions: PropTypes.array,
};

Card.defaultProps = {
  kind: 'default',
  description: '',
  numberOfQuestions: 0,
  questions: 0,
};
export default Card;
