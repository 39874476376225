import styled from 'styled-components';
import theme from '../../../../components/shared/Theme';

export const SubmitRow = styled.div`
  padding-left: 10px;
`;

export const FlexAction = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Span = styled.span`
  ${props => props.propStyle ? props.propStyle : null }
`;
export const Body = styled.div`
  background-color: ${theme.colors.whiteDefault};
  padding: 30px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;


