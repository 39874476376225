import { takeLatest, put } from "redux-saga/effects";
import { Types } from "../ducks/typeNeighborhood";
import { requestAPI } from "../../helpers/requestHelpers";
import { Types as AlertTypes } from "../ducks/messages";
import { buildErroHandleItems } from "../../helpers/sharedHelpers";

import {
  GET_ALL_TYPE_NEIGHBORHOOD
} from "../../utils/contants/urlsApi";
import { GET, PUT } from "../../utils/contants/verbs";

function* allTypeNeighborhoodRequest() {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: GET_ALL_TYPE_NEIGHBORHOOD,
      data: null
    });
    const optionsTypeNeighborhood = response.map(e => {
      return {
        value: e.id,
        label: e.descricao
      };
    });
    yield put({ type: Types.ALL_TYPE_NEIGHBORHOOD_REQUEST_SUCCESS, optionsTypeNeighborhood });
  } catch (error) {
    /* const messages = buildErroHandleItems(error.response);
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages }); */
    yield put({ type: Types.ALL_TYPE_NEIGHBORHOOD_REQUEST_FAIL, error });
  }
}




export function* watcherSaga() {
  yield takeLatest(Types.ALL_TYPE_NEIGHBORHOOD_REQUEST, allTypeNeighborhoodRequest);
}
