import styled from 'styled-components';

export const Body = styled.div`
  border-radius: 8px;
  padding: 20px;

  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 50px;
`;

export const SubmitRow = styled.div`
  padding-left: 10px;
`;

export const FlexAction = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Span = styled.span`
  ${props => props.propStyle ? props.propStyle : null }
`;
