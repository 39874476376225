import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import AuthReducer from "./auth";
import JobsReducer from "./jobs";
import VacancyReducer from "./vacancy";
import StageReducer from "./stages";
import StagesPosition from "./stagePositions";
import FormInputs from "./formInputs";
import Question from "./question";
import Rails from "./rails";
import VacancyAssociation from "./vacancyAssociation";
import Candidates from "./candidates";
import Messages from "./messages";
import Manager from "./manager";
import Documents from "./documents";
import Employee from "./employee";
import Contracts from "./contracts";
import BankOfTalents from "./bankOfTalents";
import CourseAndFormation from "./courseAndFormation";
import TypeNeighborhood from "./typeNeighborhood";
import TypeStreet from "./typeStreet";
import assinaturaAdministrativo from "./assinaturaAdministrativo"
import alerts from "./alert";
import contractDocuments from './contractDocuments';
import test from "./test";
import Doubts from "./doubts";
import enviarDocuments from "./enviarDocuments"
import userBenefits from "./userBenefits";
import otherPayments from "./otherPayments";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    login: AuthReducer,
    jobs: JobsReducer,
    vaga: VacancyReducer,
    stages: StageReducer,
    stagesPosition: StagesPosition,
    formInputs: FormInputs,
    questions: Question,
    rails: Rails,
    vacancyAssociation: VacancyAssociation,
    candidates: Candidates,
    messages: Messages,
    profiles: Manager,
    documents: Documents,
    employee: Employee,
    contracts: Contracts,
    bankOfTalents: BankOfTalents,
    formations: CourseAndFormation,
    typeNeighborhood: TypeNeighborhood,
    typeStreet: TypeStreet,
    assinaturaAdministrativo: assinaturaAdministrativo,
    alerts: alerts,
    contractDocuments: contractDocuments,
    test: test,
    doubts: Doubts,
    enviarDocuments: enviarDocuments,
    userBenefits: userBenefits,
    otherPayments: otherPayments,
  });
const Reducers = history => appReducer(history);

export default Reducers;
