import { BoxIcon, RowMultAnswer } from './UniqAnswerStyle';
import { CheckBox, TextField } from 'components/shared/CustomInputs';

import PropTypes from 'prop-types';
import React from 'react';
import { cancel } from '../../../assets/icons/icons';

export default function UniqAnswer({
  position, values, setFormValues, formValues,
}) {
  const setChecked = (kind) => {
    const values = formValues.answers;
    values.map(e => (e.checked = false));
    values[position].checked = !values[position].checked;
    setFormValues({ ...formValues, answers: values });
  };

  const setAnswers = (answer) => {
    const values = formValues.answers;
    values[position].answer = answer;
    setFormValues({ ...formValues, answers: values });
  };
  const deleteAnswer = (position) => {
    const deleteItem = formValues.answers;
    deleteItem.splice(position, 1);
    setFormValues({ ...formValues, answers: deleteItem });
  };
  return (
    <RowMultAnswer key={position}>
      <CheckBox
        radius
        onChange={() => setChecked()}
        checked={formValues.answers[position].checked}
      />
      <TextField
        width="100%"
        value={formValues.answers[position].answer}
        onChange={event => setAnswers(event.target.value)}
        name={`${position}-answer`}
      />
      {formValues.answers.filter(e => e !== null).length > 4 && (
        <BoxIcon onClick={() => deleteAnswer(position)}>{cancel()}</BoxIcon>
      )}
    </RowMultAnswer>
  );
}

UniqAnswer.propTypes = {
  deleteAnswer: PropTypes.func,
  position: PropTypes.number,
};

UniqAnswer.defaultProps = {
  deleteAnswer: () => {},
  position: 0,
};
