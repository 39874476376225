import React, { useEffect, useState } from "react";
import * as Permissions from "utils/PerfilAcesso";

import PropTypes from "prop-types";
import { STAGES } from "utils/contants/urls"
import StagePosition from "components/StagePosition/";
import TestForm from "components/Stage/TestForm";
import { connect } from "react-redux";
import { Creators as messageAction } from "store/ducks/messages";
import { push } from "connected-react-router"
import { Creators as questions } from "store/ducks/question";
import { Creators as stagePosition } from "store/ducks/stagePositions";
import { Creators as stages } from "store/ducks/stages";

const StageTest = ({
  formOptions,
  requestCreateStage,
  requestCreateQuestion,
  requestDeleteQuestion,
  stageId,
  stage,
  requestTestInfos,
  match,
  requestShowStage,
  requestUpdateQuestion,
  requestStagesPositions,
  positions,
  registerStagesPositions,
  updateStage,
  location,
  publishStage,
  isLoading,
  questionLoading,
  goBackPreviousPage,
  messagePublication,
  pagination,
}) => {
  useEffect(() => {
    requestTestInfos("testeInfo");
    if (match && match.params.id) {
      requestShowStage(match.params.id);
    }
    requestStagesPositions({ page: 1 });
  }, []);

  const [showJobAssociated, setShowJobAssociated] = useState(false);
  if (showJobAssociated) {
    return (
      <StagePosition
        registerStagesPositions={registerStagesPositions}
        stageId={stage.stageId}
        positions={positions}
        previousPosition={stage.positions}
        backCreate={() => setShowJobAssociated(!showJobAssociated)}
        requestStagesPositions={requestStagesPositions}
        pagination={pagination}
      />
    );
  }
  return (
    <React.Fragment>
      <TestForm
        requestCreateStage={requestCreateStage}
        requestCreateQuestion={requestCreateQuestion}
        requestDeleteQuestion={requestDeleteQuestion}
        formOptions={formOptions}
        stageId={stageId || stage.stageId}
        stage={stage}
        setShowJobAssociated={setShowJobAssociated}
        requestUpdateQuestion={requestUpdateQuestion}
        updateStage={updateStage}
        location={location}
        publishStage={publishStage}
        isLoading={isLoading}
        questionLoading={questionLoading}
        goBackPreviousPage={goBackPreviousPage}
        messagePublication={messagePublication}
        canEdit={() => Permissions.canEditEtapa()}
      />
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  requestTestInfos: formKind => dispatch(stages.stageInfosRequest(formKind)),
  updateStage: payload => dispatch(stages.stageUpdateRequest(payload)),
  requestCreateStage: payload => dispatch(stages.stageCreateRequest(payload)),
  requestShowStage: id => dispatch(stages.stageShowRequest(id)),
  requestStagesPositions: (filters) => dispatch(stagePosition.stagePositionRequest(filters)),
  registerStagesPositions: payload =>
    dispatch(stagePosition.stagePositionRegisterRequest(payload)),
  requestUpdateQuestion: payload =>
    dispatch(questions.questionTestUpdateRequest(payload)),
  requestCreateQuestion: payload =>
    dispatch(questions.questionTestCreateRequest(payload)),
  requestDeleteQuestion: (id, stageId) =>
    dispatch(questions.questionTestDeleteRequest(id, stageId)),
  publishStage: id => dispatch(stages.stagePublishRequest(id)),
  goBackPreviousPage: () => dispatch(push(STAGES)),
  messagePublication: messages =>
    dispatch(messageAction.messagePublication(messages)),
});
const mapStateToProps = state => ({
  formOptions: state.stages.options,
  stageId: state.stages.stageId,
  stage: state.stages.stage,
  positions: state.stagesPosition,
  isLoading: state.stages.isLoading,
  location: state.router.location,
  questionLoading: state.questions.isLoading,
  pagination: state.stagesPosition.pagination,
});
StageTest.propTypes = {
  formOptions: PropTypes.objectOf({
    kindTest: PropTypes.arrayOf({
      id: "",
      name: ""
    }),
    avaliation: PropTypes.arrayOf({
      id: "",
      name: ""
    }),
    levelTest: PropTypes.arrayOf({
      id: "",
      name: ""
    })
  }),
  stage: PropTypes.objectOf({
    name: ""
  })
};
StageTest.defaultProps = {
  formOptions: {
    kindTest: [],
    avaliation: [],
    levelTest: []
  },
  stage: {
    name: "",
    testQuestions: []
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StageTest);
