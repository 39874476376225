import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow

} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { view } from "assets/icons/icons";
import { StatusDocuments } from "utils/contants/enums";
import { Body } from "./ListUploadDocumentsStyle";
import BreadCrumb from "components/Layout/breadcrumb";
import { ENVIAR_DOCUMENTS_CANDIDATES } from "../../../../../utils/contants/urls";
import { push } from "connected-react-router";
import { SelectFieldInput, CheckBox, TextField, ButtonDefault, ButtonSecondary } from "components/shared/CustomInputs";

import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as documentsAction } from "store/ducks/enviarDocuments";



import { YesOrNoWithTextDialog } from "components/shared/CustomComponents";



const ListUploadDocuments = ({
  match,
  candidateFiles,
  filesCandidateSelected,
  isLoading,
  location,
  setStatusFileRequest,
  confirmAnalisys,
  incluseNewDoc,
  redirectToDocuments

}) => {
  useEffect(() => {

    const { params } = match;
    if (params.id) {
      candidateFiles({ id: params.id });

    }
  }, []);
  const [showDialogYesOrNo, setShowDialogYesOrNo] = useState(0);
  const token = localStorage.getItem("Authorization");
  const [idVaga, setidVaga] = useState('Indefinido');
  const canShowDialog = (idDoc) => {
    return (showDialogYesOrNo == idDoc);
  }

  const setColorStatus = (status) => {

    switch (status) {
      case 2:
        return ({ fontWeight: "bold", color: "#d01616" });
      case 3:
        return ({ fontWeight: "bold", color: "#32a34c" });
      case 7:
        return ({ fontWeight: "bold", color: "#E79110" });
      default:
        return ({});
    }
  }

  const [fileAditional, setfileAditional] = useState();

  const [exibirReprovados, setExibirReprovados] = useState(false);

  const renderFilesCandidatesTable = (candidateDocs, setStatusFile, token, canShowDialog, setShowDialogYesOrNo) => {
    return (
      <div>
        {fileAditional}
        <Row>

          <Col md={4}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>Nome:&nbsp;</label>
            <Label bold >{candidateDocs.nome}</Label>
          </Col>
          <Col md={4}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>CPF:&nbsp;</label>
            <Label bold >{candidateDocs.cpf}</Label>

          </Col>
          <Col md={2} style={{ marginBottom: "-17px" }}>
            <YesOrNoWithTextDialog
              style={{ marginBottom: "-17px" }}
              cancelAction={setfileAditional}
              show={fileAditional != null}
              showMessage={true}
              question={"Informe as instruções para o candidato."}
              confirmAction={confirmNewDocCandidate}
              idConfirm={{ fileId: fileAditional, IdCandidatoNaEtapa: candidateDocs.idCandidatoEtapa }}
            />

          </Col>



        </Row>

        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead style={{ width: "5px" }}>
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.columns.idDocument" />
                </LabelHeader>

              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.columns.typeDocument" />
                </LabelHeader>

              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.columns.document" />
                </LabelHeader>

              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  Mês de referência
                </LabelHeader>

              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  Visualizar
                </LabelHeader>

              </TableHead>


            </TableRow>
            {(candidateDocs.filesList && renderTableFilesItems(candidateDocs, setStatusFile, token, candidateDocs.podeEditar, canShowDialog, setShowDialogYesOrNo))}
          </TableBody>
        </Table>
      </div>
    )
  };
  const renderTableFilesItems = (
    candidates,
    setStatusFile,
    token,
    podeEditar,
    canShowDialog,
    setShowDialogYesOrNo
  ) =>
    candidates.filesList.map((file) => (
      <TableRow
        style={!exibirReprovados && file.status == 5 ? { display: "none" } : {}}
      >
        <TableColumn>
          <Label>{file.Id}</Label>
        </TableColumn>
        <TableColumn>
          <Label>{file.type}</Label>
        </TableColumn>
        <TableColumn>
          <Label>{file.nome}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{file.monthReference}</Label>
        </TableColumn>

        <TableColumn ta="center" action>
          {file.url && (
            <Actions
              onClick={() => window.open(`${file.url}?Authorization=${token}`, "_blank")}
              style={{ cursor: "progress" }}
            >
              <ActionItem>
                {view()}
                <LabelAction>
                  <FormattedMessage id="sharedItems.view" />
                </LabelAction>
              </ActionItem>
            </Actions>
          )}
        </TableColumn>


        <TableColumn
          style={file.status == 5 ? { color: "#adb5bd" } : { display: "none" }}
          ta="center"
          action
        >
          <Label bold>Reprovado</Label>
        </TableColumn>
        <YesOrNoWithTextDialog
          cancelAction={setShowDialogYesOrNo}
          show={canShowDialog(file.Id)}
          showMessage={true}
          question={"Informe as instruções para o candidato."}
          confirmAction={setStatusFile}
          idConfirm={{
            fileId: file.Id,
            status: "2",
            IdCandidatoNaEtapa: candidates.idCandidatoEtapa,
          }}
        />
      </TableRow>
    ));







  const confirmaAnaliseDocumentos = idcandidateEtapa => {
    confirmAnalisys({ id: idcandidateEtapa });
  };




  const setStatusFile = (fileData, message) => {
    if (fileData.status == "2" && (message == undefined || message == "")) {
      setShowDialogYesOrNo(fileData.fileId);
    } else {
      setStatusFileRequest({ idDocumento: fileData.fileId, idCandidatoEmEtapa: fileData.IdCandidatoNaEtapa, status: fileData.status, message: message });
    }
  };

  const confirmNewDocCandidate = (fileData, message) => {
    incluseNewDoc({  idDocumentoBase:  parseInt(fileData.fileId), idCandidatoEmEtapa: fileData.IdCandidatoNaEtapa, message: message });
    setfileAditional();

     console.log("test ", {  idDocumentoBase:  fileData.fileId, idCandidatoEmEtapa: fileData.IdCandidatoNaEtapa, message: message })
  };



  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={"Status documentação"}
      />
      <Body>
        <ButtonDefault
          onClick={() => redirectToDocuments()}
          width="230px"
          mr="10px"
          name={"Voltar"}
          style={{ marginBottom: '20px' }}
        />
        {(filesCandidateSelected.filesBaseList && renderFilesCandidatesTable(filesCandidateSelected, setStatusFile, token, canShowDialog, setShowDialogYesOrNo))}

        {/* <ButtonSecondary
          onClick={() => confirmaAnaliseDocumentos(filesCandidateSelected.idCandidatoEtapa)}
          width="133px"
          mr="18px"
          name={"Confirmar"}
          disabled={!filesCandidateSelected.podeConfirmar}
        /> */}

      </Body>
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  candidateFiles: id => dispatch(documentsAction.candidateAdmFilesRequest(id)),
  setStatusFileRequest: payload => dispatch(documentsAction.candidateSetStatusFileRequest(payload)),
  confirmAnalisys: payload => dispatch(documentsAction.candidateSetStatusStageRequest(payload)),
  incluseNewDoc: payload => dispatch(documentsAction.candidateIncludeNewDocRequest(payload)),
  redirectToDocuments: id => dispatch(push(`${ENVIAR_DOCUMENTS_CANDIDATES}`)),
});
const mapStateToProps = state => ({
  isLoading: state.enviarDocuments.isLoading,
  filesCandidateSelected: state.enviarDocuments.candidatesFiles
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListUploadDocuments);

ListUploadDocuments.propTypes = {
  filesCandidateSelected: PropTypes.func
};

ListUploadDocuments.defaultProps = {
  filesCandidateSelected: () => { }
};
