import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  FormStyle,
  FormGroupStyle,
  LabelFormHeader,
  RowImage,
  Label,
  ImageLoginMargin
} from "./FormStyle";
import {
  ButtonDefault,
  TextField,
  ButtonSecondary
} from "../shared/CustomInputs";
import { minLength, validPassword } from "../../helpers/validations";
import LogoDefault from "../../assets/images/logo/logo.svg";


const ChangePasswordForm = ({ submit, goBack }) => {
  const [loginValues, setLoginValues] = useState({});
  const [passwordMatch, setPasswordMatch] = useState(false);



  useEffect(() => {

    let valid = false;

    if (loginValues.newPassword && loginValues.password) {
      if (loginValues.confirmPassword === loginValues.newPassword &&
        validPassword(loginValues.newPassword)) {
        valid = true;
      }
    }

    setPasswordMatch(valid);

  }, [loginValues]);
  useEffect(() => {
    setPasswordMatch(false);
  }, [])
  return (<React.Fragment>
    <RowImage>
      <ImageLoginMargin alt="..." src={LogoDefault} />
    </RowImage>
    <FormStyle style={{height:"500px"}}>
      <FormGroupStyle >
        <Fragment>
          <LabelFormHeader>
            <FormattedMessage id="login.newPassword.rePassword" />
          </LabelFormHeader>
          <Label>
            <FormattedMessage id="login.newPassword.formatPassword"></FormattedMessage>
          </Label>
          <TextField
            label={<FormattedMessage id="login.newPassword.password" />}
            type="password"
            name="password"
            onChange={e =>
              setLoginValues({ ...loginValues, password: e.target.value })
            }
          />
          <TextField

            label={<FormattedMessage id="login.newPassword.newPassword" />}
            type="password"
            name="newPassword"
            onChange={e =>
              setLoginValues({ ...loginValues, newPassword: e.target.value })
            }
          />
          <TextField
            validate={minLength}
            label={
              <FormattedMessage id="login.newPassword.confirmPassword" />
            }
            type="password"
            name="confirmPassword"

            onChange={e =>
              setLoginValues({ ...loginValues, confirmPassword: e.target.value })
            }
          />
          <ButtonSecondary style={{marginBottom:"10px"}} name={<FormattedMessage id="login.back" />} 
            onClick={() => goBack()} 
          />
          <ButtonDefault
            disabled={!passwordMatch}
            onClick={() => submit(loginValues)}
            name={<FormattedMessage id="sharedItems.confirme" />}
          />
        </Fragment>
      </FormGroupStyle>
    </FormStyle>
  </React.Fragment>)
};
ChangePasswordForm.propTypes = {
  submit: PropTypes.func
};

ChangePasswordForm.defaultProps = {
  submit: () => { }
};

export default ChangePasswordForm;
