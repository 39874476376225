import { colorByStageStatus,colorBoderByStageStatus,colorTextByStageStatus } from "../../../helpers/sharedHelpers";
import styled from "styled-components";
import theme from "../Theme";

export const ButtonDefaultStyle = styled.button`
  white-space: nowrap;
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Arial", sans-serif;
  letter-spacing: -0.1px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s;
  border-radius: 3px;
  pointer-events: ${props => (!props.disabled ? "all" : "none")};
  background-color: ${props =>
    props.disabled ? "#e0e0e0" : theme.colors.defaultColor};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "40px")};
  font-size: 0.8em;
  text-shadow: 0px 1px 2px #2d2d2d4a;
  box-shadow: 0px 1px 5px #2d2d2d4f;
  border: none;
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  ${props => (props.styles ? props.styles : "")};
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.defaultColorHover};
    text-shadow: none;
    box-shadow: none;
  }
  &:focus {
    outline: transparent !important;
    span {
      outline: transparent !important;
    }
    svg {
      outline: transparent !important;
    }
  }
  margin-left: ${props => (props.ml ? props.ml : "0px")};
  margin-right: ${props => (props.mr ? props.mr : "0px")};
  svg {
    margin-top: -35px;
    fill: #ffffff;
    circle {
      fill: #ffffff;
    }
  }
`;
export const ButtonRedStyle = styled.button`
  white-space: nowrap;
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Arial", sans-serif;
  letter-spacing: -0.1px;
  font-weight: bold;
  color: ${props =>
    props.disabled ? "#FFFFFF" : "#000000"};
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s;
  border-radius: 3px;
  pointer-events: ${props => (!props.disabled ? "all" : "none")};
  background-color: ${props =>
    props.disabled ? "#e0e0e0" : "#FCCDB6"};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "40px")};
  font-size: 0.8em;
  text-shadow: 0px 1px 2px #2d2d2d4a;
  box-shadow: 0px 1px 5px #2d2d2d4f;
  border: none;
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  ${props => (props.styles ? props.styles : "")};
  &:hover {
    cursor: pointer;
    background-color: ${"#fcc4b6"};
    text-shadow: none;
    box-shadow: none;
  }
  &:focus {
    outline: transparent !important;
    span {
      outline: transparent !important;
    }
    svg {
      outline: transparent !important;
    }
  }
  margin-left: ${props => (props.ml ? props.ml : "0px")};
  margin-right: ${props => (props.mr ? props.mr : "0px")};
  svg {
    margin-top: -35px;
    fill: #ffffff;
    circle {
      fill: #ffffff;
    }
  }
`;

export const ButtonGreenStyle = styled.button`
  white-space: nowrap;
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Arial", sans-serif;
  letter-spacing: -0.1px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s;
  border-radius: 3px;
  pointer-events: ${props => (!props.disabled ? "all" : "none")};
  background-color: ${props =>
    props.disabled ? "#e0e0e0" : "#53cc7f"};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "40px")};
  font-size: 0.8em;
  text-shadow: 0px 1px 2px #2d2d2d4a;
  box-shadow: 0px 1px 5px #2d2d2d4f;
  border: none;
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  ${props => (props.styles ? props.styles : "")};
  &:hover {
    cursor: pointer;
    background-color: ${"#53ad7f"};
    text-shadow: none;
    box-shadow: none;
  }
  &:focus {
    outline: transparent !important;
    span {
      outline: transparent !important;
    }
    svg {
      outline: transparent !important;
    }
  }
  margin-left: ${props => (props.ml ? props.ml : "0px")};
  margin-right: ${props => (props.mr ? props.mr : "0px")};
  svg {
    margin-top: -35px;
    fill: #ffffff;
    circle {
      fill: #ffffff;
    }
  }
`;

export const ButtonMobileStyle = styled(ButtonDefaultStyle)`
  background-color: ${theme.colors.gray};
  color: ${theme.colors.defaultColor};
  text-shadow: none;
  border-radius: 0px;
  text-transform: none;
  height: ${props => (props.height ? props.height : "40px")};
  :hover {
    background-color: ${theme.colors.gray};
    color: ${theme.colors.titleColor};
    box-shadow: 0px 1px 5px #2d2d2d4f;
  }
  ${props => (props.mobileStyles ? props.mobileStyles : "")};
`;
export const MainHeaderButton = styled(ButtonDefaultStyle)`
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font-family: Arial;
  font-size: 1.5em;
  font-weight: bold;
  span {
    white-space: nowrap;
  }
  @media screen and (max-width: ${theme.size.mobileS}) {
    font-size: 1.2em;
  }
`;

export const ButtonSecondaryStyle = styled.button`
  border: 1px solid ${theme.colors.defaultColor};
  background-color: transparent;
  opacity: ${props => (props.disabled ? "0.3" : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  border-radius: 3px;
  width: ${props => (props.width ? props.width : "100%")};
  margin-left: ${props => (!props.ml ? "0px" : "20px")};
  margin-right: ${props => (!props.mr ? "0px" : "20px")};
  margin-bottom: ${props => (props.mb ? props.mb : "")};
  margin-top: ${props => (props.mt ? props.mt : "")};
  font-size: 0.8em;
  height: 40px;
  padding: 5px;
  letter-spacing: -0.1px;
  color: ${theme.colors.defaultColor};
  text-transform: uppercase;
  font-weight: 600;
  ${props => (props.inlineStyle ? props.inlineStyle : null)};
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.defaultColorHover};
    color: ${theme.colors.whiteDefault};
    text-shadow: none;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  svg {
    margin-top: -45px;
    fill: ${theme.colors.defaultColor};
    circle {
      fill: ${theme.colors.defaultColor};
    }
  }
`;
export const H1 = styled.h1`
  font-family: Arial;
  font-size: ${props => (props.size ? props.size : "2.5rem")};
  font-weight: ${props => (props.weight ? props.weight : 500)};
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${props =>
    props.color === "title"
      ? theme.colors.titleColor
      : props.color == "white" ? theme.colors.whiteDefault : theme.colors.defaultColor};
  :hover {
    color: ${props =>
    props.color === "white" ? theme.colors.white : theme.colors.defaultColorHover};
  }
  @media screen and (max-width: ${theme.size.mobileL}) {
    font-size: 1em !important;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
export const H4 = styled.h4`
  font-size: 24px;
  margin-bottom: 20px;
  color: ${theme.colors.softGray};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const H5 = styled.h4`
  font-size: 18px;
  margin-bottom: 20px;
  color: ${theme.colors.softGray};
`;

export const DotH3 = styled.h3`
  line-height: 32px;
  font-family: Arial;
  font-size: 28px;
  margin-left: 10px;
  color: ${props => colorByStageStatus(props.status)};
  margin-bottom: unset;
  display: flex;
  align-self: center;
`;

export const Li = styled.li`
  list-style: none;
  cursor: pointer;
  height: 4.375em;
  display: flex;
  align-items: center;
  padding-left: 1em;
  color: ${props =>
    props.active ? theme.colors.defaultColor : theme.colors.softGray};
  font-size: 1em;
  border-left: ${props =>
    props.active
      ? `5px solid ${theme.colors.defaultColor}`
      : "5px solid transparent"};

  a {
    text-decoration: none;
    :hover {
      text-decoration: none;
      color: ${theme.colors.defaultColor};
    }
  }
  svg {
    margin-right: ${theme.margin.internalForm};
  }
  svg path {
    fill: ${props =>
    props.active ? theme.colors.defaultColor : theme.colors.softGray};
  }
  :hover {
    border-left: 5px solid ${theme.colors.defaultColorHover};
    color: ${theme.colors.defaultColor} svg path {
      fill: ${theme.colors.defaultColor};
    }
  }
  transition: color 5 ease;
  @media screen and (max-width: ${theme.size.mobileL}) {
    display: table-cell;
    height: initial;
    padding-left: initial;
    border-left: none;
    border-bottom: 2px solid #cfcfcf;
    white-space: nowrap;
    padding: 10px;
    :hover {
      border-left: none;
      border-bottom: 2px solid ${theme.colors.defaultColorHover};
    }
  }
  @media screen and (max-width: ${theme.size.mobileL}) {
    border: unset;
    &:hover {
      border: unset;
    }
  }
`;

export const CardTitleStyle = styled.h4`
  font-family: Arial;
  font-size: ${props => (props.size ? props.size : "1.5em")};
  color: ${theme.colors.darkGray};
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
`;

export const DisclaimerStyle = styled.span`
  font-family: Arial;
  font-size: ${props => (props.size ? props.size : "0.8em")};
  line-height: 1.63;
  color: ${theme.colors.grayInfo};
  margin-bottom: ${props => props.mb};
  margin-top: ${props => props.mt};
`;

export const SpanStyle = styled(DisclaimerStyle)`
  color: ${props =>
    props.color ? theme.defineColor(props.color) : theme.colors.defaultColor};
  font-size: ${props => props.size};
  margin-left: ${props => props.ml};
  font-weight: ${props => props.weight};
  :hover {
    cursor: ${props => props.action && "pointer"};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-bottom: ${props => props.mb};
  margin-top: ${props => props.mt};
`;

export const BodyHeader = styled.div`
  width: 100%;
  height: 96.2px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  .container {
    width: 85%;
  }
  @media screen and (max-width: ${theme.size.tablet}) {
    position: relative;
    z-index: 99999;
    .container {
      width: 100%;
    }
  }
`;

export const MenuMobile = styled.div`
  position: absolute;
  width: 100%;
  z-index: 900;
  transform: ${props =>
    props.menuOpen ? "translateY(80%)" : "translateY(-230%)"};
  transition: transform 0.3s ease-in-out;
  @media screen and (max-width: ${theme.size.tablet}) {
    transform: ${props =>
    props.menuOpen
      ? props.isAuthenticated
        ? "translateY(53%)"
        : "translateY(80%)"
      : "translateY(-230%)"};
    }
  }
  @media screen and (max-width: ${theme.size.mobileL}) {
    transform: ${props =>
    props.menuOpen
      ? props.isAuthenticated
        ? "translateY(53%)"
        : "translateY(80%)"
      : "translateY(-230%)"};
    }
  }
`;

export const HamburgerMenuStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  div {
    span {
      position: initial !important;
      margin-top: 3.5px !important;
      :first-child {
        transform: translate3d(0px, 0px, 0px) rotate(45deg)
          ${props => (props.statusMenu ? "!important" : "")};
      }
      :last-child {
        margin-top: -21px !important;
      }
    }
  }
`;

const convertPXtoEM = ({ baseEM, size }) => {
  const base = baseEM ? baseEM : 16;
  const em = size / base;
  return `${em}em`;
};

export const LabelTableHead = styled.label`
  font-size: 1em;
  font-weight: bold;
  color: ${theme.colors.whiteDefault};
  padding: 5px 0 0 5px;
`;

export const Label = styled.label`
  font-size: ${props => convertPXtoEM(props)};
  color: ${theme.colors.softGray};
`;

export const LabelInfo = styled.label`
  font-family: Arial;
  font-size: 1em;
  color: ${theme.colors.softGray};
`;

export const DataInfo = styled.span`
  font-family: Arial;
  font-size: 1em;
  color: ${theme.colors.darkGray};
  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb};
`;
export const BodyCollapse = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-top: ${theme.margin.marginBody};
  svg {
    transform: ${props =>
    props.openCollapse ? "rotate(180deg)" : "rotate(0deg)"};
    transition: transform 0.4s;
  }
  ul,
  li {
    width: 100% !important;
  }
`;

export const HeaderCollapse = styled.div`
  height: 55px;
  border-bottom: solid 1px #ececec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${theme.size.mobileL}) {
    padding: 10px !important;
    svg {
      width: 15px !important;
      height: 15px !important;
    }
  }
`;
export const ContentCollapse = styled.div`
  min-height: ${props => (props.openCollapse ? "240px" : "0px")};
  transition: all 0.4s;
  opacity: ${props => (props.openCollapse ? "1" : "0")};
  padding: ${props => props.openCollapse && theme.margin.marginBody};
`;

export const TitleCollapse = styled.div`
  display: flex;
  align-items: baseline;
  max-width: 100%;
`;

export const CollapseDot = styled.div`
min-width: 24px;
width: 24px;
height: 24px;
  border-radius: 24px;
  background-color: ${props => {
    return colorByStageStatus(props.typeStatus);
  }};
  border: ${props => `2px solid ${colorBoderByStageStatus(props.typeStatus)}`};
  font-size: 1em;
  text-align: center;
  justify-items: center;
  color: ${props => {
    return colorTextByStageStatus(props.typeStatus);
  }};
  margin-right: 15px;

  @media screen and (max-width: ${theme.size.mobileL}) {
    font-size: 0.8em;
    margin-right: 10px;
    border: ${props => `3px solid ${colorBoderByStageStatus(props.typeStatus)}`};
  }
`;

export const CardBodyStyle = styled.div`
  width: 70%;
  padding-left: 5%;
  .row {
    margin: 0 div {
      padding: 0;
    }
  }
  .alingCenter {
    display: flex;
    align-items:center strong {
      margin-left: 10.8%;
    }
  }
  @media screen and (max-width: ${theme.size.tablet}) {
    width: 100%;
  }
`;

export const TestTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-top: ${props => (props.mt ? props.mt : "10px")};
`;

export const CardDefault = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : "0")};
  flex-flow: ${props => (props.flow ? props.flow : "column")};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "100%")};
  border-radius: ${props => (props.radius ? props.radius : "8px")};
  align-items: ${props => (props.alitems ? props.alitems : "center")};
  justify-content: ${props => (props.just ? props.just : "center")};
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1em 0em 1em 0em;
  padding: 1em;
`;

export const CardBodyInitalTest = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  color: #4a4a4a;
  font-family: Arial;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  padding: 1em;
`;

export const CardFooter = styled.div`
  display: flex;
  margin-top: ${props => (props.mt ? props.mt : "10px")};
`;

const colorLabel = type => {
  switch (type) {
    case "error":
      return theme.colors.errorColor;
      break;
    case "success":
      return theme.colors.softGreen;
      break;
  }
};

export const LabelValidateLabel = styled(LabelInfo)`
  color: ${props => colorLabel(props.type)};
  margin-top: 0.5em;
`;

export const Actions = styled.div`
  display: flex;
  width: auto;
  margin-left: ${props => props.ml};
  justify-content: ${props =>
    props.flexPosition ? props.flexPosition : "space-around"};
  align-items: baseline;
`;

export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-right: ${props => (props.mr ? props.mr : "")};
  fill: ${theme.colors.iconColor};
  g {
    fill: ${theme.colors.iconColor};
  }
  text-transform: lowercase;
  &:hover {
    cursor: pointer;
    g {
      fill: ${theme.colors.defaultColor};
    }
    label {
      color: ${theme.colors.defaultColor};
    }
    span {
      color: ${theme.colors.defaultColor};
    }
    fill: ${theme.colors.defaultColor};
  }
`;

export const LabelAction = styled.span`
  opacity: 0.7;
  font-size: ${props => (props.fz ? props.fz : "10px")};
  text-align: ${props => (props.ta ? props.ta : "center")};
  color: #4a4a4a;
  margin-top: ${props => (props.mt ? props.mt : "3px")};
  margin-left: ${props => (props.ml ? props.ml : "3px")};
  margin-right: ${props => (props.mr ? props.mr : "3px")};
  ${props => (props.style ? props.style : "")};
`;
