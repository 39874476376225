import styled from 'styled-components';
import theme from '../../../components/shared/Theme';

export const Body = styled.div`
  z-index: 50;
  width: 100%;
  height: ${props => (props.insideComponent ? "70%" : "100vh")};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  transition: opacity .3s;
  background-color:rgba(255,255,255,0.5);
  position: ${props => (props.insideComponent ? "relative" : "absolute")};
  z-index:999999999;
  top:0;
  left:0;
`;

export const LabelAnimation = styled.label`
    font-size: 14px;
    font-weight: lighter;
    color: ${theme.colors.softGray};
    clear: both;
    width: 100%;
    text-align: center;
    @keyframes fadeIn { 
      from { opacity: 0; } 
    }
    animation: fadeIn .4s infinite alternate;
`;

export const LoadContainer = styled.label`
  width: 100px;
`;