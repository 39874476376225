import "moment/locale/pt-br";

import * as routes from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";
import { put, select, takeLatest } from "redux-saga/effects";
import API from "../../utils/API";
import { Types } from "../ducks/enviarDocuments";
import moment from "moment";
import {
  buildPagination,
} from "../../helpers/sharedHelpers";

moment.locale("pt-BR");


const filterCandidates = payload => {
  const body = {
    nomeFuncionario: payload.nomeFuncionario,
    cpfList: payload.cpfList,
    idVagasList: payload.idVagasList,
    idLocaisList: payload.idLocaisList,
    dataInicioAtividade: payload.dateInitActivity,
    idStatusList: payload.idStatusList,
    numeroPagina: payload.NumeroPagina,
    quantidadeItens: payload.quantidadeItens,
    dataAdmissaoPrevista: payload.dataAdmissaoPrevista
  };

  return body;
};
function requestCandidadesFromVacancyList(body) {
  return API.put(
    `${urlsApi.REQUEST_CANDIDATESVACANCY_UPLOAD}`,
    body
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}
function* getCandidatesVacancyDocs({ payload }) {
  try {
    let actualPage;

    if (payload && payload.firstPage) {
      actualPage = 1;
    } else {
      actualPage = payload.NumeroPagina || (yield select(getActualPage));
    }

    let actualItens = payload && payload.recoveredFilter && payload.quantidadeItens ? payload.quantidadeItens : 50;
    payload.quantidadeItens = actualItens;
    payload.NumeroPagina = actualPage;

    const {lista, paginacao} = yield requestCandidadesFromVacancyList(filterCandidates(payload));

    const candidates = {
      candidatos: lista?.candidatosNaEtapaList.map(e => ({
        id: e.id,
        cpf: e.cpf,
        idCandidatoNaEtapa: e.idCandidatoNaEtapa,
        idVaga: e.idVaga,
        nome: e.nome,
        statusCandidatoNaEtapa: e.statusCandidatoNaEtapa,
        tituloVaga: e.tituloVaga,
        dataAdmissaoPrevista: e.dataAdmissaoPrevista !== null ? e.dataAdmissaoPrevista : "-"
      })),
      candidatosPorVaga: lista?.candidatosNaEtapaPorVagaList.map(e => ({
        idVaga: e.idVaga,
        nomeVaga: e.nomeVaga,
        candidatesByVacancyList: e.candidatosNaEtapaList.map(c => ({
          id: c.id,
          cpf: c.cpf,
          idCandidatoNaEtapa: c.idCandidatoNaEtapa,
          idVaga: c.idVaga,
          nome: c.nome,
          statusCandidatoNaEtapa: c.statusCandidatoNaEtapa,
          tituloVaga: c.tituloVaga,
          dataAdmissaoPrevista: e.dataAdmissaoPrevista !== null ? e.dataAdmissaoPrevista : "-"
        })),
      })),
    };

    let candidatos;
    let candidatosPorVaga;
    const stateCandidateList = yield select(getStateCandidatesList);

      candidatos = candidates.candidatos;
      candidatosPorVaga = candidates.candidatosPorVaga;

    const AllCandidates = { 
      candidatos: candidatos, 
      candidatosPorVaga: candidatosPorVaga
      }
  

    const paginacaoNumber = {...paginacao, page: payload.NumeroPagina}

    const pagination = buildPagination(
      paginacaoNumber,
      payload,
      AllCandidates.candidatos,
      stateCandidateList,
      50,
    )

    yield put({
      type: Types.ENVIAR_DOCS_EMPLOYEES_SUCCESS,
      candidates: AllCandidates,
      pagination,

    });
  } catch (error) {
    yield put({ type: Types.ENVIAR_DOCS_EMPLOYEES_FAIL, error });
  }
}

const getActualPage = state => state.documents?.page || 1;
const getStateCandidatesList = state => state.documents?.candidates;

function requestCandidadeAdmFilesList(id) {
  return API.get(
    `${urlsApi.REQUEST_CANDIDATES_ADM_FILES}?Id=${id}`

  )
    .then(res => res.data)
    .catch(error => { throw error });
}

function* getCandidateAdmFiles({ payload }) {
  try {
    const candidateDocs = yield requestCandidadeAdmFilesList(payload.id);


    if (candidateDocs && candidateDocs.documentosEnviados.lenght == 0) {
      window.location.href = `${routes.ENVIAR_DOCUMENTS_CANDIDATES}`;
    }
    
    const candidatesFiles =

    {
      nome: candidateDocs.nome,
      idCandidatoEtapa: candidateDocs.idCandidatoEmEtapa,
      statusEtapa: candidateDocs.statusEtapa,
      podeConfirmar: candidateDocs.podeConfirmar,
      podeEditar: candidateDocs.podeEditar,
      cpf: candidateDocs.cpf,
      idVaga :   candidateDocs.idVaga,
      nomeVaga :   candidateDocs.nomeVaga,

      filesList: candidateDocs.documentosEnviados ? candidateDocs.documentosEnviados.map(e => ({
        Id: e.id,
        url: e.url,
        nome: e.nome,
        status: e.status,
        instrucao: e.descricao,
        type: e.tipo,
        monthReference: moment(e.dataDocumentoRH).format("L")
      })) : [],
      filesBaseList: candidateDocs.documentosPossiveisDeEnvio ? candidateDocs.documentosPossiveisDeEnvio.map(e => ({
        Id: e.id,
        descricao: e.descricao,
        nome: e.nome,
        tipoDocumento: e.tipoDocumento
      })) : [],

    };
    yield put({
      type: Types.CANDIDATE_ADM_FILES_SUCCESS,
      candidatesFiles
    });
  } catch (error) {
    yield put({ type: Types.CANDIDATE_ADM_FILES_FAIL, error });
  }
}


function* releaseStageForCandidates({ payload }) {
  try {
  

    yield put({
      type: Types.RELEASE_STAGE_FOR_CANDIDATES_SUCCESS,
    });
  } catch (error) {
    yield put({ type: Types.RELEASE_STAGE_FOR_CANDIDATES_FAIL, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.ENVIAR_DOCS_EMPLOYEES_REQUEST, getCandidatesVacancyDocs);
  yield takeLatest(Types.CANDIDATE_ADM_FILES_REQUEST, getCandidateAdmFiles);

  yield takeLatest(Types.RELEASE_STAGE_FOR_CANDIDATES_REQUEST, releaseStageForCandidates);
}
