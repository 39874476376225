import * as urls from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";

import { GET, POST, PUT } from "../../utils/contants/verbs.js";
import {
  buildCode,
  buildDate,
  buildErroHandleItems,
  buildStartWorkDate,
  buildStartWorkDateNull,
  formatAssociationAttributes,
  getFormatMonthArray,
  getHoursFromDate,
  getMonthId
} from "../../helpers/sharedHelpers";
import { put, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/vacancy";
import { history } from "../../utils/routes";
import moment from "moment";
import requestAPI from "../../helpers/requestHelpers";

moment.locale("pt-br");
function getVacancyShowApi(id) {
  return API.get(`${urlsApi.vacancyShowApi}/${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function postVacancy(payload) {
  const form = buildFormToCreateVacancy(payload);
  return API.post(urlsApi.vacancyShowApi, form)
    .then(e => {
      history.push(urls.VACANCY);
    })
    .catch(e => {
      throw e;
    });
}

function updateVacancyRequest(payload) {
  const form = buildFormToCreateVacancy(payload);
  return API.put(urlsApi.vacancyShowApi, form).then(e => {
    history.push(urls.VACANCY);
  });
}

function* getVaga({ id, editing }) {
  try {
    const vacancy = yield getVacancyShowApi(id);
    const vacancyKind = parseVacancyFromAPI(vacancy);
    let formatedVacancy;
    formatedVacancy = parseShowVacancy(vacancy);
    yield put({
      type: Types.JOB_SHOW_SUCCESS,

      ...Object.assign(vacancyKind, formatedVacancy, { preview: "" })
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.JOB_SHOW_FAIL, error });
  }
}

function* createVacancy({ payload }) {
  try {
    yield postVacancy(payload);
    yield put({ type: Types.JOB_CREATE_SUCCESS });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.JOB_CREATE_FAIL, error });
  }
}

function* updateVacancy({ payload }) {
  try {
    yield updateVacancyRequest(payload);
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.JOB_UPDATE_FAIL, error });
  }
}

const parseShowVacancy = data => ({
  id: data.id,
  numberSMP: parseInt(data.numeroSMP),
  area: data.cargo.area,
  areaFormation: data.areaFormacao.map(e => ({
    value: e.id,
    label: e.descricao
  })),
  job: data.cargo,
  jorney: data.jornada.id,
  vacancyGroup: data.vacancyGroup?.id,
  placeOfWork: data.localTrabalho.id,
  description: data.descricao,
  schooling: data.escolaridade.id,
  tecnicKnowledge: data.conhecimentoTecnico.map(e => ({
    value: e.id,
    label: e.resumo
  })),
  timeOfWork: data.tempoEmpresa,
  lastPromotion: data.ultimaPromocao,
  eligibleJobs: data.cargosElegiveis.map(e => ({
    value: e.id,
    label: e.funcao
  })),
  city: data.cidadesElegiveis.map(e => ({ label: e.nome, value: e.id })),
  skills: data.habilidades,
  homeOffice: data.homeOffice,
  deficiency: data.aceitaPessoaComDeficiencia,
  banner: data.banner,
  benefits: data.beneficios.map(e => ({ value: e.id, label: e.nome })),
  vacancyReq: data.requisitos,
  vacancyTitle: data.titulo,
  competence: data.competencias.map(e => ({ label: e.nome, value: e.id })),
  salary: data.salario || 0,
  responsibility: data.responsabilidades,
  aboutWork: data.sobreOTrabalho,
  slug: data.slug,
  vacancys: data.quantidadeVaga,
  startWork: buildStartWorkDate(data.inicioAtividades),
  confidentialVacancy: data.vagaConfidencial,
  affiliate: data.centroResultado?.coligada?.id,
  resultCenter: data.centroResultado?.id,
  section: data.secao?.id,
  taker: data.tomador?.id,
  managerEmployeeCode: data.gestor?.managerCode,
  managerCode: data.gestor?.value,
  managerName: data.gestor
    ? { managerCode: data.gestor.managerCode, label: data.gestor.label, value: data.gestor.value }
    : undefined,
  funcName: data.colaborador
    ? { value: data.colaborador?.value, label: data.colaborador?.label,managerCode: data.colaborador?.managerCode }
    : undefined,
  funcCode: data.colaborador?.value,
  informeSalary: data.exibirFaixaSalario,
  showSalary: data.exibirSalario,
  informeSchedules: data.faixaHorario.length ? true : false,
  published: data.publicada,
  schedules: data.faixaHorario
    ? data.faixaHorario.map(e => ({
      start: getHoursFromDate(e.horarioInicio),
      end: getHoursFromDate(e.horarioFim),
      numberOfVacancy: e.quantidadeVaga
    }))
    : [],
  indicators: data.indicador
    ? data.indicador.map(e => ({
      id: e.id,
      months: getFormatMonthArray(e.meses),
      indicator: e.indicador.id,
      level: e.nivel.id,
      typeIndicator: e.tipo,
      numberDays: e.quantidadeDias
    }))
    : [],
  associetedRail: data.trilha ? {
    id: data?.trilha?.id,
    stages: data.trilha.etapas ?
      data.trilha.etapas.map((e) => ({
        id: e.id,
        name: e.titulo,
        description: e.descricao,
        avaliation: e.etapaAvaliacao ? e.etapaAvaliacao.nome : "",
        level: e.etapaNivelTeste ? e.etapaNivelTeste.nome : "",
        kindTest: e.etapaTipoTeste ? e.etapaTipoTeste.nome : "",
        periodeRealization: buildStartWorkDateNull(e.dataInicioRealizacao),
        testPeriodeRealizationUntil: buildStartWorkDateNull(e.dataFimRealizacao),
        periodeAvaliation: buildStartWorkDateNull(e.dataInicioAvaliacao),
        periodeAvaliationUntil: buildStartWorkDateNull(e.dataFimAvaliacao),
        kindStage: e.tipoEtapa,
        kind: e.tipoEtapa,
        percentAprovation: e.porcentagemParaAprovacao ? e.porcentagemParaAprovacao.toString() : undefined,
        placeOfExame: e.localExameMedico ? e.localExameMedico.id : undefined,
        showSieveApproval: e.mostrarCrivo ? e.mostrarCrivo : false,
        showCandidateScore: e.mostrarNota ? e.mostrarNota : false,
      })
      ) : [],
  } : undefined
});

const buildFormToCreateVacancy = data => {
  const { kindSelecao, kindVaga } = parseVacancyType(data);
  return {
    numeroSMP: data.area == 1 && kindVaga == 1 ? parseInt(data.numberSMP) : "",
    idCentroResultado: data.resultCenter,
    idGestor:
      typeof data.managerCode == "object"
        ? data.managerCode.value
        : data.managerCode,
    idColaborador:
      typeof data.funcName == "object" ? data.funcName.value : data.funcName,
    idColigada: data.affiliate,
    titulo: data.vacancyTitle,
    sobreOTrabalho: data.aboutWork,
    responsabilidades: data.responsibility,
    requisitos: data.vacancyReq,
    habilidades: data.skills,
    faixaHorarios: buildHours(data),
    exibirSalario: data.showSalary,
    faixaSalario: data.informeSalary,
    aceitaPessoaComDeficiencia: data.deficiency,
    inicioAtividades: buildDate(data.startWork),
    vagaConfidencial: data.confidentialVacancy ? data.confidentialVacancy : false,
    idArea: parseInt(data.area),
    idVaga: data.id,
    idCargo: parseInt(data.job),
    tipoSelecao: kindSelecao,
    tipoVaga: kindVaga,
    idLocalTrabalho: parseInt(data.placeOfWork),
    idJornada: parseInt(data.jorney),
    idGrupoVaga: data.vacancyGroup ? parseInt(data.vacancyGroup) : undefined,
    escolaridade: parseInt(data.schooling),
    cidadesElegiveis: data.city ? data.city.map(e => e.value || e) : [],
    areaFormacao: data.areaFormation
      ? data.areaFormation.map(e => e.value)
      : [],
    conhecimentoTecnico: data.tecnicKnowledge
      ? data.tecnicKnowledge.map(e => e.value || e)
      : [],
    tempoEmpresa: data.timeOfWork == "" ? 0 : data.timeOfWork,
    ultimaPromocao: data.lastPromotion ? data.lastPromotion : undefined,
    idSecao: data.section,
    codigoVaga: data.codigoVaga,
    salario:
      typeof data.salary !== "number"
        ? parseFloat(
          data.salary
            .replace("R$", "")
            .replace(".", "")
            .replace(",", "."),
          10
        )
        : data.salary,
    descricao: data.description,
    cargosElegiveis: data.eligibleJobs.map(e => e.value || e),
    quantidadeVaga: parseInt(data.vacancys || 1, 10),
    idTomador: data.taker,
    beneficios: data.benefits ? data.benefits.map(e => e.value) : [],
    competencias: data.competence ? data.competence.map(e => e.value) : [],
    indicadoresPerformance:
      data.indicators && data.indicators.length
        ? data.indicators.map(e => ({
          id:e.id? Number(e.id):0,
          indicadorPerformanceId: Number(e.indicator),
          nivelId: Number(e.level),
          meses: e.typeIndicator == 0 ? getMonthId(e.months) : null,
          tipo: e.typeIndicator,
          quantidadeDias: e.typeIndicator == 1 ? e.numberDays : null
        }))
        : [],
    exibirFaixasHorario: data.informeSchedules,
    faixaHorarios: data.schedules
      ? data.schedules.map(e => ({
        horarioInicio: moment(e.start, "HH:mm").format("YYYY-MM-DD HH:mm:ss"),
        horarioFim: moment(e.end, "HH:mm").format("YYYY-MM-DD HH:mm:ss"),
        quantidadeVagas: e.numberOfVacancy
      }))
      : []
  };
};

const buildHours = data => {
  const keys = Object.keys(data);
  const getKeys = keys.filter(e => e.match(/timer-[0-9]*/));
  const item = { horarioInicio: null, horarioFim: null };
  return getKeys.map(hour => {
    if (!item.horarioInicio) {
      item.horarioInicio = data[hour];
    } else if (!item.horarioFim) {
      item.horarioFim = data[hour];
    }
    return hour;
  });
};
const parseVacancyType = vacancy => {
  let kindVaga = "";
  let kindSelecao = "";
  switch (vacancy.kindVaga) {
    case "talents":
      kindVaga = 0;
      break;
    case "existJob":
      kindVaga = 1;
      break;
    case "promotion":
      kindVaga = 2;
      break;
    default:
      kindVaga = 0;
  }
  switch (vacancy.kindSelecao) {
    case "internal":
      kindSelecao = 0;
      break;
    case "external":
      kindSelecao = 1;
      break;
    default:
      kindSelecao = 0;
  }
  return { kindSelecao, kindVaga };
};

const parseVacancyFromAPI = vacancy => {
  let kindVaga = "";
  let kindSelecao = "";
  switch (vacancy.tipoVaga) {
    case 0:
      kindVaga = "talents";
      break;
    case 1:
      kindVaga = "existJob";
      break;
    case 2:
      kindVaga = "promotion";
      break;
    default:
      kindVaga = 0;
  }
  switch (vacancy.tipoSelecao) {
    case 0:
      kindSelecao = "internal";
      break;
    case 1:
      kindSelecao = "external";
      break;
    default:
      kindSelecao = 0;
  }
  return { kindSelecao, kindVaga };
};

function* bannerAssociation(id, banner) {
  try {
    yield requestAPI({
      verb: POST,
      endPoint: urlsApi.BANNER_ASSOCIATION,
      data: { idVaga: id, idBanner: banner.id }
    });
  } catch (err) {
    const messages = buildErroHandleItems(err.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: messages });
  }
}

function* requestBuildPreview({ id, banner }) {
  try {
    yield bannerAssociation(id, banner);
    const vaga = yield requestAPI({
      verb: GET,
      endPoint: urlsApi.VANCANCY_PREVIEW_REQUEST,
      data: null,
      id
    });
    const parsedVacancy = {
      id: vaga.id,
      code: buildCode(vaga.dataCriacao, "VA", vaga.id),
      createdAt: moment(vaga.dataCriacao).format("L"),
      publishedAt: moment(vaga.dataPublicacao).format("L"),
      title: vaga.titulo,
      placeOfWork: vaga.localTrabalho,
      howManyVacancies: vaga.quantidade,
      description: `${vaga.requisitos} ${vaga.responsabilidades} ${vaga.sobreOTrabalho}`,
      salarie: vaga.salario || 0,
      journey: vaga.jornada,
      idGroupVacancy: vaga.vacancyGroup,
      tags: vaga.tags.map(e => {
        return {
          name: e.nome,
          tags: e.tags
        };
      }),
      hourPeerWeek: vaga.jornada,
      benefits: vaga.beneficios.map(e => {
        return {
          id: e.id,
          title: e.titulo,
          url: e.url
        };
      }),
      area: vaga.area,
      schooling: vaga.escolaridade,
      experience: vaga.experiencia,
      googleJobPosting: vaga.googleJobPosting,
      city: vaga.enderecoLocalTrabalho.cidade.nome,
      banner: banner.url,
      idBanner: banner.id
    };
    yield put({
      type: Types.JOB_CREATE_PREVIEW_SUCCESS,
      preview: parsedVacancy
    });
  } catch (err) {
    const messages = buildErroHandleItems(err.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: messages });
  }
}

function* requestPublishVacancy({ id }) {
  try {
    yield requestAPI({
      verb: POST,
      endPoint: urlsApi.PUBLISH_VACANCY,
      data: null,
      id
    });
    yield put({ type: Types.JOB_RESET_PREVIEW });
    yield put({ type: Types.JOB_PUBLISH_SUCCESS });
  } catch (err) {
    const errors = buildErroHandleItems(err.response);
    yield put({
      type: MessageTypes.MESSAGE_PUBLICATION,
      messages: [...errors]
    });
    yield put({ type: Types.JOB_RESET_PREVIEW });
  }
}

function* startVacancyForm() {
  try {
    yield put({ type: Types.JOB_START_FORM_SUCCESS });
  } catch (err) {
    yield put({
      type: MessageTypes.JOB_START_FORM_FAIL
    });
  }
}

function requestEditAssociationAttributes(body) {
  return API.put(`${urlsApi.EDIT_ASSOCIATION_ATTRIBUTES}`, body)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

function* editAssociationAttributes({ payload }) {
  try {
    const body = formatAssociationAttributes(payload)
    yield requestEditAssociationAttributes(body);
    yield put({ type: Types.REQUEST_EDIT_ASSOCIATION_ATTRIBUTES_RAILS_SUCCESS });
    history.push(`${urls.VACANCY_EDITAR}${payload.idVacancy}`)
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.REQUEST_EDIT_ASSOCIATION_ATTRIBUTES_RAILS_FAIL, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.JOB_SHOW_REQUEST, getVaga);
  yield takeLatest(Types.JOB_CREATE_REQUEST, createVacancy);
  yield takeLatest(Types.JOB_UPDATE_REQUEST, updateVacancy);
  yield takeLatest(Types.JOB_CREATE_PREVIEW_REQUEST, requestBuildPreview);
  yield takeLatest(Types.JOB_PUBLISH_REQUEST, requestPublishVacancy);
  yield takeLatest(Types.JOB_START_FORM, startVacancyForm);
  yield takeLatest(
    Types.REQUEST_EDIT_ASSOCIATION_ATTRIBUTES_RAILS,
    editAssociationAttributes
  );
}
