import React, { useState } from 'react';
import { LabelH1 } from 'components/shared/CustomComponents';
import { ButtonSecondary } from 'components/shared/CustomInputs';
import { FormattedMessage } from 'react-intl';
import {
  attachBtnIcon,
  goalBtnIcon,
  formationBtnIcon,
  carrierBtnIcon,
  aboutBtnIcon,
  arrowColapse,
} from '../../../assets/icons/icons';
import {
  Body,
  RowCollapse,
  TitleBox,
  Number,
  BodyInfos,
  RowInfos,
  SpanItems,
  BoxInfo,
  SpanInfo,
  RowActions,
  IconArrow,
} from './DefaultCardStyle';

export default function DefaultCard({ candidate }) {
  const [showBodyInfos, setShowBodyInfos] = useState(false);
  return (
    <Body>
      <RowCollapse onClick={() => setShowBodyInfos(!showBodyInfos)}>
        <TitleBox>
          <Number>
            <LabelH1 fz="16px">1</LabelH1>
          </Number>
          <LabelH1 fz="20px">
            <FormattedMessage id="sharedItems.stagesTitle.default" />
          </LabelH1>
        </TitleBox>
        <IconArrow showBodyInfos={showBodyInfos}>{arrowColapse()}</IconArrow>
      </RowCollapse>
      <BodyInfos showBodyInfos={showBodyInfos}>
        {showBodyInfos && (
          <React.Fragment>
            <RowInfos>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.code" />
                </SpanItems>
                <SpanInfo>{candidate.code}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.CPF" />
                </SpanItems>
                <SpanInfo>{candidate.cpf}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.RG" />
                </SpanItems>
                <SpanInfo>{candidate.rg}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.birth" />
                </SpanItems>
                <SpanInfo>{candidate.birthday}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.phone" />
                </SpanItems>
                <SpanInfo>{candidate.phone1}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.phone" /> {'2'} 
                </SpanItems>
                <SpanInfo>{candidate.phone2 || '-'}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.email" />
                </SpanItems>
                <SpanInfo>{candidate.email}</SpanInfo>
              </BoxInfo>
            </RowInfos>
            <RowInfos mt="30px">
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.nationality" />
                </SpanItems>
                <SpanInfo>{candidate.address.nationality}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.state" />
                </SpanItems>
                <SpanInfo>{candidate.address.state}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.city" />
                </SpanItems>
                <SpanInfo>{candidate.address.city}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.neighborhood" />
                </SpanItems>
                <SpanInfo>{candidate.address.neighborhood}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.street" />
                </SpanItems>
                <SpanInfo>{candidate.address.street}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>Nº</SpanItems>
                <SpanInfo>{candidate.address.number}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.Complement" />
                </SpanItems>
                <SpanInfo>{candidate.address.attachment || '-'}</SpanInfo>
              </BoxInfo>
              <BoxInfo>
                <SpanItems>
                  <FormattedMessage id="sharedItems.zipCode" />
                </SpanItems>
                <SpanInfo>{candidate.address.zipCode}</SpanInfo>
              </BoxInfo>
            </RowInfos>
            <RowActions>
              <ButtonSecondary
                mr="20px"
                img={aboutBtnIcon}
                name={<FormattedMessage id="sharedItems.about" />}
                width="130px"
              />
              <ButtonSecondary
                img={carrierBtnIcon}
                name={<FormattedMessage id="sharedItems.carrier" />}
                width="130px"
              />
              <ButtonSecondary
                name={<FormattedMessage id="sharedItems.formation" />}
                img={formationBtnIcon}
                ml="20px"
                width="130px"
              />
              <ButtonSecondary
                ml="20px"
                img={goalBtnIcon}
                name={<FormattedMessage id="sharedItems.goal" />}
                width="130px"
              />
              <ButtonSecondary
                ml="20px"
                img={attachBtnIcon}
                name={<FormattedMessage id="sharedItems.attach" />}
                width="130px"
              />
            </RowActions>
          </React.Fragment>
        )}
      </BodyInfos>
    </Body>
  );
}
