
import {
  ButtonGreenStyle,
  ButtonRedStyle,
  BodyCollapse,
  ButtonDefaultStyle,
  ButtonMobileStyle,
  ButtonSecondaryStyle,
  CardTitleStyle,
  CollapseDot,
  ContentCollapse,
  DataInfo,
  DisclaimerStyle,
  DotH3,
  H1,
  H4,
  H5,
  HamburgerMenuStyle,
  HeaderCollapse,
  Label,
  LabelInfo,
  LabelTableHead,
  LabelValidateLabel,
  Li,
  MainHeaderButton,
  MenuMobile,
  Row,
  SlideContent,
  SliderContainer,
  SpanStyle,
  TestTitle,
  TitleCollapse
} from "./style";
import {
  DotBox,
  DotStage,
  LabelNumber
} from "../../../components/Estage/TimeLine/styles";
import React, { useEffect, useState } from "react";

import AboutYouIcon from "../../../assets/svg/aboutyou.svg";
import Arrow from "../../../assets/svg/arrow-colapse.svg";
import ArrowCollapse from "../../../assets/svg/arrow-colapse.svg";
import CareerIcon from "../../../assets/svg/iconsMenuProfile/career.svg";
import AvailabilityIcon from "../../../assets/svg/iconsMenuProfile/calendar.svg";
import CertificationsIcon from "../../../assets/svg/iconsMenuProfile/certificate.svg";
import { Col } from "react-bootstrap";
import FormationIcon from "../../../assets/svg/iconsMenuProfile/formation.svg";
import { FormattedMessage } from "react-intl";

import InterestsIcon from "../../../assets/svg/iconsMenuProfile/interests.svg";
import IsLoading from "../../../assets/svg/loading.svg";
import LinguagesIcon from "../../../assets/svg/iconsMenuProfile/linguages.svg";

import PasswordIcon from "../../../assets/svg/iconsMenuProfile/password.svg";
import PersonalInformationIcon from "../../../assets/svg/iconsMenuProfile/home.svg";
import PreadmissionIcon from "../../../assets/svg/iconsMenuProfile/preadmission.svg";

import SkillsIcon from "../../../assets/svg/iconsMenuProfile/skill.svg";
import StarIcon from "../../../assets/svg/iconsMenuProfile/star.svg";
import theme from "../Theme";

export const ButtonGreen = ({
  name,
  disabled,
  onClick,
  onKeyPress,
  onKeyDown,
  height,
  mt,
  ml,
  width,
  versionMobile,
  isLoading,
  styles,
  mobileStyles
}) => {
  return (
    <React.Fragment>
      {versionMobile ? (
        <ButtonMobileStyle
          disabled={disabled}
          width={width}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          mt={mt}
          ml={ml}
          height={height}
          mobileStyles={mobileStyles}
        >
          {name}
        </ButtonMobileStyle>
      ) : (
          <ButtonGreenStyle
            disabled={disabled}
            width={width}
            onClick={onClick}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            mt={mt}
            ml={ml}
            height={height}
            styles={styles}
          >
            {isLoading ? <IsLoading /> : name}
          </ButtonGreenStyle>
        )}
    </React.Fragment>
  );
};

export const ButtonRed = ({
  name,
  disabled,
  onClick,
  onKeyPress,
  onKeyDown,
  height,
  mt,
  ml,
  width,
  versionMobile,
  isLoading,
  styles,
  mobileStyles
}) => {
  return (
    <React.Fragment>
      {versionMobile ? (
        <ButtonMobileStyle
          disabled={disabled}
          width={width}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          mt={mt}
          ml={ml}
          height={height}
          mobileStyles={mobileStyles}
        >
          {name}
        </ButtonMobileStyle>
      ) : (
          <ButtonRedStyle
            disabled={disabled}
            width={width}
            onClick={onClick}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            mt={mt}
            ml={ml}
            height={height}
            styles={styles}
          >
            {isLoading ? <IsLoading /> : name}
          </ButtonRedStyle>
        )}
    </React.Fragment>
  );
};

export const ButtonDefault = ({
  name,
  disabled,
  onClick,
  onKeyPress,
  onKeyDown,
  height,
  mt,
  ml,
  width,
  versionMobile,
  isLoading,
  styles,
  mobileStyles
}) => {
  return (
    <React.Fragment>
      {versionMobile ? (
        <ButtonMobileStyle
          disabled={disabled}
          width={width}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          mt={mt}
          ml={ml}
          height={height}
          mobileStyles={mobileStyles}
        >
          {name}
        </ButtonMobileStyle>
      ) : (
          <ButtonDefaultStyle
            disabled={disabled}
            width={width}
            onClick={onClick}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            mt={mt}
            ml={ml}
            height={height}
            styles={styles}
          >
            {isLoading ? <IsLoading /> : name}
          </ButtonDefaultStyle>
        )}
    </React.Fragment>
  );
};

export const AlertValidateLabel = ({ type, msg }) => {
  return <LabelValidateLabel type={type}>{msg}</LabelValidateLabel>;
};

// ButtonMobile
export const ButtonMain = ({
  name,
  disabled,
  onClick,
  height,
  mt,
  width,
  versionMobile,
  isLoading
}) => {
  return (
    <MainHeaderButton
      disabled={disabled}
      onClick={onClick}
      width={width}
      height={height}

    >
     {isLoading ? <IsLoading /> : name}
    </MainHeaderButton>
  );
};

export const ButtonSecondary = ({
  name,
  disabled,
  onClick,
  mt,
  ml,
  width,
  height,
  isLoading,
  inlineStyle
}) => {
  return (
    <ButtonSecondaryStyle
      disabled={disabled}
      width={width}
      mt={mt}
      height={height}
      onClick={onClick}
      ml={ml}
      inlineStyle={inlineStyle}
    >
      {isLoading ? <IsLoading /> : name}
    </ButtonSecondaryStyle>
  );
};

export const Title = ({ title, color, size, weight }) => {
  return (
    <H1 weight={weight} color={color} size={size}>
      {title}
    </H1>
  );
};

export const TitleTopic = ({ title }) => {
  return <H5>{title}</H5>;
};

export const TitleDescriptionTopic = ({ title }) => {
  return <H4>{title}</H4>;
};

export const CardTitle = ({ title, mb }) => {
  return <CardTitleStyle mb={mb}>{title}</CardTitleStyle>;
};

export const TitleWithDot = ({ title, dot }) => {
  return (
    <TestTitle>
      <DotBox>
        <DotStage status={dot.selectedStage.stageStatus}>
          <LabelNumber>1</LabelNumber>
        </DotStage>
      </DotBox>
      <DotH3 status={dot.selectedStage.stageStatus}>{title}</DotH3>
    </TestTitle>
  );
};

export const Disclaimer = ({ size, text, mb, mt }) => {
  return (
    <DisclaimerStyle size={size} mt={mt} mb={mb}>
      {text}
    </DisclaimerStyle>
  );
};

export const Span = ({
  text,
  mb,
  mt,
  ml,
  size,
  action,
  onClick,
  weight,
  color
}) => {
  return (
    <SpanStyle
      onClick={onClick}
      action={action}
      size={size}
      weight={weight}
      mb={mb}
      mt={mt}
      ml={ml}
      mb={mb}
      color={color}
    >
      {text}
    </SpanStyle>
  );
};

export const RowTheme = ({ mt, mb, children }) => {
  return (
    <Row mt={mt} mb={mb}>
      {children}
    </Row>
  );
};

export const MenuResponsive = ({ options, menuOpen, isAuthenticated }) => {
  return (
    <MenuMobile menuOpen={menuOpen} isAuthenticated={isAuthenticated}>
      {options.map((k, index) => {
        return (
          <Col key={index} sm={12}>
            
          </Col>
        );
      })}
    </MenuMobile>
  );
};




export const InfoLabel = ({ text }) => {
  return <LabelInfo>{text}</LabelInfo>;
};

export const TableHeadLabel = ({ text }) => {
  return <LabelTableHead>{text}</LabelTableHead>;
};

export const LabelComponent = ({ text, size, baseEM }) => {
  return (
    <Label baseEM={baseEM} size={size}>
      {text}
    </Label>
  );
};

export const DataLabel = ({ text, ml, mb }) => {
  return (
    <DataInfo mb={mb} ml={ml}>
      {text}
    </DataInfo>
  );
};

export const Collapse = ({
  children,
  title,
  position,
  typeStatus,
  onclick
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  useEffect(() => {
    onclick && onclick();
  }, [openCollapse]);
  return (
    <BodyCollapse openCollapse={openCollapse}>
      <HeaderCollapse onClick={() => setOpenCollapse(!openCollapse)}>
        <TitleCollapse>
          <CollapseDot position={position} typeStatus={typeStatus}>
            {position}
          </CollapseDot>
          <H1 size="1.250em" color="title">
            {title}
          </H1>
        </TitleCollapse>
        <ArrowCollapse />
      </HeaderCollapse>
      <ContentCollapse openCollapse={openCollapse}>
        {openCollapse && children}
      </ContentCollapse>
    </BodyCollapse>
  );
};
export const CollapseMessage = ({
  children,
  title,
  typeStatus,
  onclick,
  backgroundColor,
}) => {
  const [openCollapse, setOpenCollapse] = useState(true);

  useEffect(() => {
    onclick && onclick();
  }, [openCollapse]);
  return (
    <BodyCollapse openCollapse={openCollapse}>
      <HeaderCollapse onClick={() => setOpenCollapse(!openCollapse)} style={{ backgroundColor }}>
        <TitleCollapse>
          <H1 size="1.250em" color="white">
            {title}
          </H1>
        </TitleCollapse>
        <ArrowCollapse />
      </HeaderCollapse>
      <ContentCollapse openCollapse={openCollapse}>
        {openCollapse && children}
      </ContentCollapse>
    </BodyCollapse>
  );
};
export const RoundedButton = ({
  disabled,
  onClick,
  height,
  mt,
  ml,
  width,
  isLeft
}) => {
  const buttonStyle = {
    width: "36px",
    height: "36px",
    objectFit: "contain",
    borderRadius: "50%",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.25)",
    transform: isLeft ? "rotate(-90deg)" : "rotate(90deg)",
    padding: "unset"
  };

  return (
    <ButtonDefaultStyle
      disabled={disabled}
      width={width}
      onClick={onClick}
      mt={mt}
      ml={ml}
      height={height}
      styles={buttonStyle}
    >
      <ArrowCollapse style={{ margin: "unset", stroke: "white" }} />
    </ButtonDefaultStyle>
  );
};

export const FormattedMessageWithStyle = props => (
  <FormattedMessage {...props}>
    {(txt) => (
      <span style={props.style}>
        {txt}
      </span>
    )}
  </FormattedMessage>
);
