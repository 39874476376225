import styled from "styled-components";
import theme from "../../../../../components/shared/Theme";

export const FormProcess = styled.div`
  z-index: 0;
`;
export const IconsAction = styled.div`
  :hover {
    cursor: pointer;
  }
  margin-left:-5%;
  color: ${theme.colors.defaultColor};
`;



export const CardBody = styled.div`


  height: auto;

    width: 100%;
    padding-left: 0%;

  .row {
    margin: 0;
  }
`;

export const Ballon = styled.span`
  height: 100%;
  border-radius: 15px;
  background-color: ${theme.colors.titleColor};
  margin-right: 6px;
  color: ${theme.colors.whiteDefault};
  text-align: center;
  margin-top: 0.5em;
  padding: 1em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  svg {
    fill: ${theme.colors.whiteDefault};
  }
`;

export const BoxBallons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;


export const Box = styled.div`
  display: flex;
  flex-flow: column;
  @media screen and (max-width: ${theme.size.tablet}) {
    margin-top: 1em;
  }
`;

export const ProfileInfos = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.125em;
  color: ${theme.colors.titleColor};
`;

export const FixedAction = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #ffffff 51%,
    #ffffff
  );
  color: white;
  text-align: center;
  padding: 30px;
  @media screen and (min-width: ${theme.size.tablet}) {
    display: none;
  }
`;

export const LabelSpan = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const RequiredSpan = styled.span`
  color: ${theme.colors.errorColor};
  font-size: 13px;
  line-height: 1.54;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-left: 5px;
  ::before {
    content: "*";
  }
`;



export const CardCommitmentTerm = styled.div`
  width: 100%;
  height: 8.42em;
  border-radius: 2px;
  border: solid 1px ${theme.colors.ligthGray};
  margin-top: ${theme.margin.marginBody};
  padding: ${theme.margin.internalForm};
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  @media screen and (max-width: ${theme.size.mobileL}) {
    height: 8.42em;
  }
  @media screen and (max-width: ${theme.size.laptop}) {
    height: 22.42em;
  }
`;

export const ContainerDoc = styled.div`

  width: 100 %;
  height: 0;
  padding-bottom: 56.25 %; //16 x 9
  position: relative;

`;

export const ComplementSlideBlockStyle = styled.div`
      .slider-list {
        height: auto!important;
      }

      .slider-slide {
        position: sticky!important;
      }
`;


export const RequiredFieldsDiv = styled.div`
  display: ${props => (props.required ? "flex" : "none")};
  color: ${theme.colors.errorColor};
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  ::before {
    content: "* Campos Obrigatórios";
  }
`;

export const ContractBody = styled.div`
max-height: 800px;
overflow-y: scroll;
overflow-x: hidden;
`;

