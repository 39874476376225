import { Form, Formik, withFormik } from "formik";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { minLength } from "../../helpers/validations";
import {
  ButtonDefault,
  ButtonSecondary,
  TextField,
} from "../shared/CustomInputs";
import {
  FormGroupStyle,
  FormRow,
  FormStyle,
  ImageLogin,
  LabelFormHeader,
  RowImage,
} from "./FormStyle";

const AuthFormNewPassword = withFormik({
  mapPropsToValues: () => ({
    newPassword: "",
    confirmPassword: "",
  }),
});

const NewPassword = (props) => (
  <React.Fragment>
    <RowImage>
      <ImageLogin alt="Logo chalezinho" src="./Logo/logoChale.png" />
    </RowImage>
    <FormStyle>
      <Formik
        onSubmit={(values) =>
          values.newPassword === values.confirmPassword && props.submit(values)
        }
      >
        <Form>
          <FormGroupStyle>
            <Fragment>
              <LabelFormHeader>
                <FormattedMessage id="login.newPassword.rePassword" />
              </LabelFormHeader>
              <TextField
                validate={minLength}
                label={<FormattedMessage id="login.newPassword.rePassword" />}
                type="password"
                name="password"
              />
              <TextField
                validate={minLength}
                label={
                  <FormattedMessage id="login.newPassword.confirmPassword" />
                }
                type="password"
                name="confirmPassword"
              />
            </Fragment>
          </FormGroupStyle>
          <FormRow itens>
            <ButtonSecondary name={<FormattedMessage id="login.back" />} />
            <ButtonDefault
              name={<FormattedMessage id="sharedItems.confirme" />}
            />
          </FormRow>
        </Form>
      </Formik>
    </FormStyle>
  </React.Fragment>
);
NewPassword.propTypes = {
  submit: PropTypes.func,
};

NewPassword.defaultProps = {
  submit: () => {},
};

export default AuthFormNewPassword(NewPassword);
