import styled from 'styled-components';
import theme from '../../shared/Theme';

export const Body = styled.div`
margin-top:20px
  height: 100%;
  border-radius: 8px;
  border: solid 1px ${theme.colors.borderRadius};
  padding: 30px;
  padding-bottom: 15px;
`;

export const SpanContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-flow: row wrap;
`;