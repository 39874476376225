import { Body, DeleteButton } from "./PerformanceStyle";
import {
  ButtonSecondary,
  MultSelect,
  SelectFieldInput,
  TextField
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { cancel } from "../../../assets/icons/icons";
import { connect } from "react-redux";
import { Creators as formInputsAction } from "../../../store/ducks/formInputs";
import { months } from "../../../locale";
import { required } from "../../../helpers/validations";
import { TypesIndicator } from "utils/contants/enums";

const PerformanceIndicator = ({
  performaceIndicatiorRequest,
  inputsItems,
  formValues,
  setFormValues,
  name,
  formErros,
  setFormErros,
  editing
}) => {
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    setIndicators(formValues.indicators ? formValues.indicators : []);
    performaceIndicatiorRequest();
    return () => {
      setValues([]);
    };
  }, []);

  const registerIndicator = (values, key, item) => {
    const actualIndicators = [...indicators];
    if (actualIndicators[key]) {
      if (Array.isArray(values)) {
        actualIndicators[key][item] = values.map(e => e.value);
      } else {
        actualIndicators[key][item] = values.target.value;
      }
      setValues(actualIndicators);
    }
  };

  const uptadeMonths = (values, key) => {
    const actualIndicators = [...indicators];
    if (actualIndicators[key]) {
      actualIndicators[key].months = values;
      setValues(actualIndicators);
    }
  };
  const uptadeNumerMonth = (values, key) => {
    const actualIndicators = [...indicators];
    if (actualIndicators[key]) {
      actualIndicators[key].numberDays = values;
      setValues(actualIndicators);
    }
  };

  const registerNewIndicator = () => {
    const newIndicator = {
      job: 0,
      level: 0,
      months: [],
      numberDays: 0,
      typeIndicator: null
    };
    const actualIndicators = [...indicators];
    actualIndicators.push(newIndicator);
    setValues(actualIndicators);
  };

  const removeIndicator = index => {
    const dupIndicators = [...indicators];
    dupIndicators.splice(index, 1);
    setValues(dupIndicators);
  };

  const setValues = dupIndicators => {
    setFormValues({ ...formValues, indicators: dupIndicators });
    setIndicators(dupIndicators);
  };

  return (
    <Body>
      {indicators.map((item, index) => (
        <Row key={index} className="d-flex align-items-start">
          <Col lg={{ span: 3 }}>
            <SelectFieldInput
              label={<FormattedMessage id="sharedItems.indicator" />}
              onChange={e => registerIndicator(e, index, "indicator")}
              value={item.indicator}
              validates={[required]}
              items={inputsItems.indicatorsperformaceIndicatior}
            />
          </Col>
          <Col lg={{ span: 2 }}>
            <SelectFieldInput
              label={<FormattedMessage id="sharedItems.level" />}
              onChange={e => registerIndicator(e, index, "level")}
              value={item.level}
              validates={[required]}
              items={inputsItems.level.map(e => ({
                id: e.id,
                name: e.descricao
              }))}
            />
          </Col>
          <Col lg={{ span: 2 }}>
            <SelectFieldInput
              label={<FormattedMessage id="sharedItems.kind" />}
              onChange={e => registerIndicator(e, index, "typeIndicator")}
              value={item.typeIndicator}
              validates={[required]}
              items={TypesIndicator.map(e => ({
                id: e.id,
                name: e.nome
              }))}
            />
          </Col>
          {(item.typeIndicator == 0 &&
            <Col lg={{ span: 4 }}>
              <MultSelect
                label={<FormattedMessage id="sharedItems.months" />}
                value={item.months}
                onChange={e => uptadeMonths(e, index)}
                options={months}
                setFormErros={setFormErros}
                formErr={formErros}
                name={name}
                isMulti
              />
            </Col>
          )}
          {(item.typeIndicator == 1 &&
            <Col lg={{ span: 4 }}>
              <TextField
                label={<FormattedMessage id="sharedItems.days" />}
                value={item.numberDays}
                onChange={e => uptadeNumerMonth(e.target.value, index)}
                setFormErros={setFormErros}
                formErr={formErros}
                name={name}
              />
            </Col>
          )}
          <Col onClick={() => removeIndicator(index)}>
            <DeleteButton>{cancel()}</DeleteButton>
          </Col>
        </Row>
      ))}
      <Row>
        <Col lg={{ span: 3 }}>
          <ButtonSecondary
            onClick={() => registerNewIndicator()}
            name={<FormattedMessage id="sharedItems.addIndicator" />}
          />
        </Col>
      </Row>
    </Body>
  );
};

const mapDispatchToProps = dispatch => ({
  performaceIndicatiorRequest: () =>
    dispatch(formInputsAction.performaceIndicatiorRequest())
});

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceIndicator);
