import React from 'react';
import { Col } from 'react-bootstrap';
import Pagination from 'components/Pagination';
import { FormattedMessage } from 'react-intl';
import {
  Body,
  LabelMain,
  LabelSecondary,
  LabelValues,
  BoxValues,
  RowValues,
  TrilhaRow,
  Row,
} from './VacancyStyle';
import {
  Table,
  TableRow,
  TableHead,
  TableColumn,
  Label,
  TableBody,
  LabelHeader,
  Actions,
} from '../shared/CustomComponentsStyle';
import {
  statusOk, statusWait, statusAlert, order,
} from '../../assets/icons/icons';
import { ButtonSecondary, ButtonDefault } from '../shared/CustomInputs';

const renderTableItens = itens => itens.map(candidate => (
  <TableRow key={candidate.code}>
    <TableColumn ta="start">
      <Label>{candidate.name}</Label>
    </TableColumn>
    <TableColumn ta="start">
      <Label>{candidate.timer}</Label>
    </TableColumn>
    <TableColumn ta="start">
      <Actions flexPosition="start">
        {candidate.status === 'enabled' && !candidate.bankofTalents  && (
        <React.Fragment>
          <Actions flexPosition="center">{statusOk({ width: '17px', height: '17px' })}</Actions>
          <Label>
            <FormattedMessage id="vacancyShow.statusApprove" />
          </Label>
        </React.Fragment>
        )}
        {candidate.status === 'cancel' && !candidate.bankofTalents && (
        <Actions flexPosition="start">
          {statusAlert({ width: '17px', height: '24px' })}
          <FormattedMessage id="vacancyShow.statusFinished" />
        </Actions>
        )}
        {candidate.status === 'await' && !candidate.bankofTalents  && (
        <Actions flexPosition="start">
          {statusWait({ width: '24px', height: '24px' })}
          <FormattedMessage id="vacancyShow.statusInProgress" />
        </Actions>
        )}
       {candidate.bankofTalents && (
        <Actions flexPosition="start">
          {statusWait({ width: '24px', height: '24px' })}
          <FormattedMessage id="vacancyShow.inBankOfTalent" />
        </Actions>
        )}
      </Actions>
    </TableColumn>
  </TableRow>
));
const Vacancy = props => (
  <Body>
    <Row className="row">
      <Col sm={{ span: 6 }}>
        <LabelMain>{props.vacancy.jobName}</LabelMain>
      </Col>
      <Col sm={{ span: 2, offset: 1 }}>
        <ButtonSecondary onClick={() => props.editVaga(props.vacancy.id)} name="Editar" />
      </Col>
      <Col sm={{ span: 3 }}>
        <ButtonDefault name={<FormattedMessage id="vacancyShow.addCandidate" />} />
      </Col>
    </Row>
    <Row className="row">
      <Col sm={{ span: 6 }}>
        <BoxValues>
          <RowValues>
            <LabelSecondary>
              <FormattedMessage id="vacancyList.columns.columns" />
            </LabelSecondary>
            <LabelValues>
              {props.vacancy.id}
|
            </LabelValues>
          </RowValues>
          <RowValues>
            <LabelSecondary>
              <FormattedMessage id="vacancyList.columns.area" />
            </LabelSecondary>
            <LabelValues>
              {props.vacancy.areaVaga.descricao}
              {' '}
|
            </LabelValues>
          </RowValues>
          <RowValues>
            <LabelSecondary>
              <FormattedMessage id="vacancyList.columns.manager" />
            </LabelSecondary>
            <LabelValues>
              {props.vacancy.gestor ? (
                props.vacancy.gestor.nome
              ) : (
                <FormattedMessage id="vacancyList.columns.noInformation" />
              )}
            </LabelValues>
          </RowValues>
        </BoxValues>
      </Col>
    </Row>
    <Row className="row">
      <Col sm={{ span: 6 }}>
        <BoxValues start>
          <RowValues>
            <LabelSecondary>
              <FormattedMessage id="vacancyList.columns.area" />
            </LabelSecondary>
            <LabelValues>
              {props.vacancy.jornada.nome}
|
            </LabelValues>
          </RowValues>
          <RowValues>
            <LabelSecondary>
              <FormattedMessage id="sharedItems.time" />
            </LabelSecondary>
            <LabelValues>
              {`${new Date(props.vacancy.jornada.horarioInicio).getHours()}:${new Date(
                props.vacancy.jornada.horarioInicio,
              ).getMinutes()}`}
              {' '}
              ás
              {' '}
              {`${new Date(props.vacancy.jornada.horarioFim).getHours()}:${new Date(
                props.vacancy.jornada.horarioFim,
              ).getMinutes()}`}
            </LabelValues>
          </RowValues>
        </BoxValues>
      </Col>
    </Row>
    <TrilhaRow color>
      <div>
        <LabelSecondary>
          <FormattedMessage id="vacancyShow.trackStage" />
        </LabelSecondary>
        <LabelValues>Teste de Portugês (de 25/04/2018 à 10/05/2018)</LabelValues>
      </div>
      <div>
        <ButtonDefault width="250px" name={<FormattedMessage id="vacancyShow.showTrack" />} />
      </div>
    </TrilhaRow>
    <Row className="row">
      <Col sm={{ span: 3 }}>
        <BoxValues start>
          <RowValues>
            <LabelSecondary>Status</LabelSecondary>
          </RowValues>
        </BoxValues>
      </Col>
    </Row>
    <Row className="row">
      <Col sm={{ span: 4 }}>
        <BoxValues start>
          <RowValues>
            <LabelValues>
              {props.vacancy.candidatos.length}
              <FormattedMessage id="vacancyShow.tableStatus.statusCandidate" />
            </LabelValues>
          </RowValues>
          <RowValues>
            <LabelValues>
              {props.vacancy.candidatos.filter(e => e.status === 0).length}
              <FormattedMessage id="vacancyShow.tableStatus.statusApprove" />
            </LabelValues>
          </RowValues>
          <RowValues>
            <LabelValues>
              {props.vacancy.candidatos.filter(e => e.status === 1).length}
              <FormattedMessage id="vacancyShow.tableStatus.statusDecline" />
            </LabelValues>
          </RowValues>
        </BoxValues>
      </Col>
      <Col sm={{ span: 2, offset: 6 }}>
        <ButtonSecondary name={<FormattedMessage id="vacancyShow.changeTime" />} />
      </Col>
    </Row>
    <Table>
      <TableBody>
        <TableRow noBorder>
          <TableHead ta="start">
            <LabelHeader>
              <FormattedMessage id="sharedItems.name" />
            </LabelHeader>
            {order()}
          </TableHead>
          <TableHead ta="start">
            <LabelHeader>
              <FormattedMessage id="sharedItems.time" />
            </LabelHeader>
            {order()}
          </TableHead>
          <TableHead ta="start">
            <LabelHeader>
              <FormattedMessage id="sharedItems.time" />
            </LabelHeader>
            {order()}
          </TableHead>
        </TableRow>
        {renderTableItens(props.vacancy.candidatos)}
      </TableBody>
    </Table>
    <Pagination />
  </Body>
);

export default Vacancy;
