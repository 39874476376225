import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonDefault, CustomFieldSearch } from "../../shared/CustomInputs";
import { HeaderRowTab } from "./HeaderStyle";
import { history } from "../../../utils/routes";
import * as urls from "../../../utils/contants/urls";

const Header = (props) => (
  <HeaderRowTab>
    <ButtonDefault
      ml
      onClick={() => props.ShowFilter()}
      name={"Filtrar"}
      width="179px"
      height="36px"
    />
    <ButtonDefault
      ml
      onClick={() => history.push(urls.VACANCY_NEW)}
      name={<FormattedMessage id="vacancyList.newVacancy" />}
      width="179px"
      height="36px"
    />
  </HeaderRowTab>
);

export default Header;
