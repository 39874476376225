import styled from "styled-components";
import theme from "../../shared/Theme";

export const LabelRoot = styled.span`
  color: ${theme.colors.defaultColor};
  font-family: Arial;
  font-size: 13px;
  font-weight: lighter;
`;
export const LabelBread = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: ${theme.colors.defaultColor};
  margin-right: 5px;
  text-transform: capitalize;
  div {
    :hover {
      cursor: pointer;
    }
  }
`;

export const BoxBreadItems = styled.div`
  display: flex;
  align-items: ${props => (props.align ? "baseline" : "center")};
  justify-content: start;
  flex-flow: ${props => (props.coloumn ? "column" : "row")};
  margin: 5px 0 10px 0;
  a {
    color: ${theme.colors.defaultColor};
  }
`;

export const Arrow = styled(LabelBread)`
  color: #9b9b9b;
  margin: 0 5px;
  font-weight: normal;
`;
export const BoxBreadCrumb = styled.div`
  display: flex;
  flex-flow: column;
  margin: 5px 0 10px 0;
`;
