import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  profilesRequest: ["payload"],
  profilesSuccess: ["profiles", "isLoading"],
  profilesFail: [],

  changeUserRequest: ["payload"],
  changeUserSuccess: [, "isLoading"],
  changeUserFail: [],

  requestUsersId: ["payload"],
  requestUsersIdSuccess: ["stages", "isLoading"],
  requestUsersIdFail: ["isLoading", "error"],

  profilesUsersRequest: ["payload"],
  profilesUsersSuccess: ["users", "isLoading"],
  profilesUsersFail: [],

  usersRequest: ["payload"],
  usersSuccess: [, "isLoading"],
  usersFail: [],

  profilesUserUpdateRequest: ["payload"],
  profilesUserUpdateSuccess: ["isLoading"],
  profilesUserUpdateFail: ["isLoading", "error"],

  profilesPermissionsRequest: ["payload"],
  profilesPermissionsSuccess: ["profilepermissionsSelected", "isLoading"],
  profilesPermissionsFail: [],

  profilesEditPermissionsRequest: ["payload"],
  profilesEditPermissionsSuccess: [, "isLoading"],
  profilesEditPermissionsFail: [],

  profilesCreateRequest: ["payload"],
  profilesCreateSuccess: [, "isLoading"],
  profilesCreateFail: [],

  profilesUpdateStageAccessRequest: ["payload"],
  profilesUpdateStageAccessSuccess: [, "isLoading"],
  profilesUpdateStageAccessFail: [],

  profilesEnableStageRequest: ["payload"],
  profilesEnableStageSuccess: [, "isLoading"],
  profilesEnableStageFail: [],

  profilesEnablePlugRequest: ["payload"],
  profilesEnablePlugSuccess: [, "isLoading"],
  profilesEnablePlugFail: [],

  profilesEnableTalentosRequest: ["payload"],
  profilesEnableTalentosSuccess: [, "isLoading"],
  profilesEnableTalentosFail: [],

  userDeleteRequest: ["id", "payload"],
  userDeleteSuccess: [],
  userDeleteFail: [],

  profilesEnableManagerRequest: ["payload"],
  profilesEnableManagerSuccess: [, "isLoading"],
  profilesEnableManagerFail: [],

  informationRmSecaoListRequest: ["payload"],
  informationRmSecaoListSuccess: ["candidate", "isLoading"],
  informationRmSecaoListFail: ["error"],

});


const INITIAL_STATE = {
  profiles: [],
  users: [],
  informationSecao: [],
  isLoading: true,
  profilepermissionsSelected: {}
};

const request = state => ({ ...state, isLoading: true });
const success = (state, payload) => ({

  profiles: payload.profiles,
  isLoading: false

});
const fail = (state, error) => ({ ...state, isLoading: false, ...error });

const userDeleteRequest = state => ({ ...state, isLoading: true });
const userDeleteSuccess = (state) => ({
  ...state,
  isLoading: false,
});
const userDeleteFail = (state, error) => ({ ...state, isLoading: false, ...error });


///REQUEST USERS

const changeUserRequest = (state) => ({ ...state, isLoading: true });
const changeUserSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const changeUserFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const requestUsersId = (state) => ({ ...state, isLoading: true });
const requestUsersIdSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const requestUsersIdFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const profilesUsersRequest = (state) => ({ ...state, isLoading: true });
const profilesUsersSuccess = (state, payload) => ({

  users: payload.users,
  isLoading: false

});
const profilesUsersFail = (state, error) => ({ ...state, isLoading: false, ...error });

const usersRequest = state => ({ ...state, isLoading: true });
const usersSuccess = (state, payload) => ({

  users: payload.users,
  isLoading: false

});
const usersFail = (state, error) => ({ ...state, isLoading: false, ...error });

///REQUEST UPDATE USERS
const profilesUserUpdateRequest = state => ({ ...state, isLoading: true });
const profilesUserUpdateSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const profilesUserUpdateFail = (state, error) => ({ ...state, isLoading: false, error });


///REQUEST PROFILE PERMISSIONS
const profilesPermissionsRequest = state => ({ ...state, isLoading: true });
const profilesPermissionsSuccess = (state, payload) => ({
  profilepermissionsSelected: payload.profilepermissionsSelected,
  isLoading: false
});
const profilesPermissionsFail = (state, error) => ({ ...state, isLoading: false, error });



///REQUEST PERMITION PROFILE
const profilesEditPermissionsRequest = state => ({ ...state, isLoading: true });
const profilesEditPermissionsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const profilesEditPermissionsFail = (state, error) => ({ ...state, isLoading: false, error });

///REQUEST CREATE PROFILE
const profilesCreateRequest = state => ({ ...state, isLoading: true });
const profilesCreateSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const profilesCreateFail = (state, error) => ({ ...state, isLoading: false, error });


///REQUEST UPDATE STAGE ACESS
const profilesUpdateStageAccessRequest = state => ({ ...state, isLoading: true });
const profilesUpdateStageAccessSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const profilesUpdateStageAccessFail = (state, error) => ({ ...state, isLoading: false, error });



///REQUEST ENABLE STAGE PROFILE
const profilesEnableStageRequest = state => ({ ...state, isLoading: true });
const profilesEnableStageSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const profilesEnableStageFail = (state, error) => ({ ...state, isLoading: false, error });

const profilesEnablePlugRequest = state => ({ ...state, isLoading: true });
const profilesEnablePlugSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const profilesEnablePlugFail = (state, error) => ({ ...state, isLoading: false, error });

const profilesEnableTalentosRequest = state => ({ ...state, isLoading: true });
const profilesEnableTalentosSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const profilesEnableTalentosFail = (state, error) => ({ ...state, isLoading: false, error });

const profilesEnableManagerRequest = state => ({ ...state, isLoading: true });
const profilesEnableManagerSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const profilesEnableManagerFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const informationRmSecaoListRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});

const informationRmSecaoListSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false,
});

const informationRmSecaoListFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.PROFILES_REQUEST]: request,
  [Types.PROFILES_SUCCESS]: success,
  [Types.PROFILES_FAIL]: fail,

  [Types.CHANGE_USER_REQUEST]: changeUserRequest,
  [Types.CHANGE_USER_SUCCESS]: changeUserSuccess,
  [Types.CHANGE_USER_FAIL]: changeUserFail,

  [Types.REQUEST_USERS_ID]: requestUsersId,
  [Types.REQUEST_USERS_ID_SUCCESS]: requestUsersIdSuccess,
  [Types.REQUEST_USERS_ID_FAIL]: requestUsersIdFail,

  [Types.PROFILES_USERS_REQUEST]: profilesUsersRequest,
  [Types.PROFILES_USERS_SUCCESS]: profilesUsersSuccess,
  [Types.PROFILES_USERS_FAIL]: profilesUsersFail,

  [Types.USERS_REQUEST]: usersRequest,
  [Types.USERS_SUCCESS]: usersSuccess,
  [Types.USERS_FAIL]: usersFail,

  [Types.PROFILES_USER_UPDATE_REQUEST]: profilesUserUpdateRequest,
  [Types.PROFILES_USER_UPDATE_SUCCESS]: profilesUserUpdateSuccess,
  [Types.PROFILES_USER_UPDATE_FAIL]: profilesUserUpdateFail,

  [Types.PROFILES_PERMISSIONS_REQUEST]: profilesPermissionsRequest,
  [Types.PROFILES_PERMISSIONS_SUCCESS]: profilesPermissionsSuccess,
  [Types.PROFILES_PERMISSIONS_FAIL]: profilesPermissionsFail,

  [Types.PROFILES_EDIT_PERMISSIONS_REQUEST]: profilesEditPermissionsRequest,
  [Types.PROFILES_EDIT_PERMISSIONS_SUCCESS]: profilesEditPermissionsSuccess,
  [Types.PROFILES_EDIT_PERMISSIONS_FAIL]: profilesEditPermissionsFail,

  [Types.PROFILES_CREATE_REQUEST]: profilesCreateRequest,
  [Types.PROFILES_CREATE_SUCCESS]: profilesCreateSuccess,
  [Types.PROFILES_CREATE_FAIL]: profilesCreateFail,

  [Types.PROFILES_UPDATE_STAGE_ACCESS_REQUEST]: profilesUpdateStageAccessRequest,
  [Types.PROFILES_UPDATE_STAGE_ACCESS_SUCCESS]: profilesUpdateStageAccessSuccess,
  [Types.PROFILES_UPDATE_STAGE_ACCESS_FAIL]: profilesUpdateStageAccessFail,

  [Types.PROFILES_ENABLE_STAGE_REQUEST]: profilesEnableStageRequest,
  [Types.PROFILES_ENABLE_STAGE_SUCCESS]: profilesEnableStageSuccess,
  [Types.PROFILES_ENABLE_STAGE_FAIL]: profilesEnableStageFail,

  [Types.PROFILES_ENABLE_PLUG_REQUEST]: profilesEnablePlugRequest,
  [Types.PROFILES_ENABLE_PLUG_SUCCESS]: profilesEnablePlugSuccess,
  [Types.PROFILES_ENABLE_PLUG_FAIL]: profilesEnablePlugFail,

  [Types.PROFILES_ENABLE_TALENTOS_REQUEST]: profilesEnableTalentosRequest,
  [Types.PROFILES_ENABLE_TALENTOS_SUCCESS]: profilesEnableTalentosSuccess,
  [Types.PROFILES_ENABLE_TALENTOS_FAIL]: profilesEnableTalentosFail,

  [Types.USER_DELETE_REQUEST]: userDeleteRequest,
  [Types.USER_DELETE_SUCCESS]: userDeleteSuccess,
  [Types.USER_DELETE_FAIL]: userDeleteFail,

  [Types.PROFILES_ENABLE_MANAGER_REQUEST]: profilesEnableManagerRequest,
  [Types.PROFILES_ENABLE_MANAGER_SUCCESS]: profilesEnableManagerSuccess,
  [Types.PROFILES_ENABLE_MANAGER_FAIL]: profilesEnableManagerFail,

  [Types.INFORMATION_RM_SECAO_LIST_REQUEST]: informationRmSecaoListRequest,
  [Types.INFORMATION_RM_SECAO_LIST_SUCCESS]: informationRmSecaoListSuccess,
  [Types.INFORMATION_RM_SECAO_LIST_FAIL]: informationRmSecaoListFail,
});



