import {
  Body,
  LabelH1,
  YesOrNoDialog
} from "components/shared/CustomComponents";

import * as Permissions from "../../utils/PerfilAcesso";
import {
  ButtonDefault,
  ButtonSecondary,
  TextAreaField,
  TextField
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import BreadCrumb from "components/Layout/breadcrumb";
import Card from "components/Stage/Cards";
import Dialog from "components/Stage/Modal";
import { FormBody } from "./NewStageStyle";
import { FormattedMessage } from "react-intl";
import ModalEditing from "../Stage/ModalEdition";
import PropTypes from "prop-types";
import Select from "components/Stage/Selection";
import StagePosition from "../StagePosition";
import { required } from "../../helpers/validations";

const NewStage = props => {
  const { registerStagesPositions } = props;
  const { location } = props;
  const { positions } = props;
  const { stage } = props;
  const { requestUpdateQuestion } = props;
  const { requestShowStage } = props;
  const { updateStage } = props;
  const { requestStagesPositions, pagination } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [dialogKind, setDialogKind] = useState("aboutYou");
  const { requestCreateStage, publishStage } = props;
  const { requestCreateQuestion } = props;
  const { requestDeleteQuestion } = props;
  const [description, setDescription] = useState(stage.description);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState();
  const [name, setName] = useState(stage.name);
  const stageId = props.stageId || stage.stageId;
  const [questions, registerQuestions] = useState({
    aboutYou: stage.aboutYou,
    carrier: stage.carrier,
    formation: stage.formation,
    goal: stage.goal
  });
  const [showPositions, setShowPositions] = useState(true);

  const renderDialog = kind => {
    setShowDialog(!showDialog);
    setDialogKind(kind);
  };

  const renderDialogToDelete = idItemToDelete => {
    setShowDialogDelete(true);
    setDeleteTarget(idItemToDelete);
  };

  useEffect(() => {
    setName(stage.name);
    setDescription(stage.description);
  }, [stage]);

  const cardItems = [
    {
      kind: "default",
      description: <FormattedMessage id="newStageAboutYou.items.default" />,
      questions: [],
      title: <FormattedMessage id="newStageAboutYou.stagesTitle.default" />,
      openEdit: { renderDialog }
    },
    {
      kind: "aboutYou",
      description: <FormattedMessage id="newStageAboutYou.items.default" />,
      questions: questions.aboutYou,
      title: <FormattedMessage id="newStageAboutYou.stagesTitle.aboutYou" />,
      openEdit: renderDialog,
      typeBlock: 0
    },
    {
      kind: "carrier",
      description: <FormattedMessage id="newStageAboutYou.items.default" />,
      questions: questions.carrier,
      title: <FormattedMessage id="newStageAboutYou.stagesTitle.carrier" />,
      openEdit: renderDialog,
      typeBlock: 1
    },
    {
      kind: "formation",
      description: <FormattedMessage id="newStageAboutYou.items.default" />,
      questions: questions.formation,
      title: <FormattedMessage id="newStageAboutYou.stagesTitle.formation" />,
      openEdit: renderDialog,
      typeBlock: 2
    },
    {
      kind: "goal",
      description: <FormattedMessage id="newStageAboutYou.items.default" />,
      questions: questions.goal,
      title: <FormattedMessage id="newStageAboutYou.stagesTitle.goal" />,
      openEdit: renderDialog,
      typeBlock: 3
    }
  ];

  useEffect(() => {
    registerQuestions({
      aboutYou: stage.aboutYou,
      carrier: stage.carrier,
      formation: stage.formation,
      goal: stage.goal
    });
  }, [props.stage, stage.aboutYou, stage.carrier, stage.formation, stage.goal]);

  const registerQuestionsToItem = (kind, items) => {
    const newItems = questions;
    switch (kind) {
      case "aboutYou":
        newItems.aboutYou.push(items);
        break;
      case "carrier":
        newItems.carrier.push(items);
        break;
      case "formation":
        newItems.formation.push(items);
        break;
      case "goal":
        newItems.goal.push(items);
        break;
      default:
        break;
    }
    registerQuestions(newItems);
  };

  const saveStage = (name, description) => {
    requestCreateStage({ name, description, formKind: 0 });
  };

  const updateActionStage = (name, description) => {
    updateStage({
      name,
      description,
      formKind: 0,
      stageId
    });
  };

  if (showPositions) {
    return (
      <React.Fragment>
        <Row>
          <BreadCrumb
            location={location}
            step={<FormattedMessage id="newStageAboutYou.breadCrumb" />}
          />
        </Row>
        <Row>
          <Col sm={3} md={3} lg={{ span: 5 }}>
            <LabelH1>
              <FormattedMessage id="defaultStage.stagesNames.newEstage" />
            </LabelH1>
          </Col>
          <Col sm={2} md={3} lg={{ span: 3 }}>
            <ButtonSecondary
              onClick={() => setShowPositions(!showPositions)}
              disabled={stageId === undefined || !Permissions.canEditEtapa()}
              ml={false}
              mr={false}
              name={<FormattedMessage id="newStageAboutYou.associationJob" />}
            />
          </Col>
          <Col sm={2} md={3} lg={{ span: 2 }}>
            <ButtonDefault
              onClick={
                stageId
                  ? () => updateActionStage(name, description)
                  : () => saveStage(name, description)
              }
              ml={false}
              mr={false}
              disabled={!Permissions.canEditEtapa()}
              name={<FormattedMessage id="sharedItems.save" />}
            />
          </Col>
          <Col lg={2}>
            <ButtonDefault
              disabled={!stageId}
              width={"100%"}
              onClick={() => publishStage(stageId)}
              disabled={!Permissions.canEditEtapa()}
              name={<FormattedMessage id="sharedItems.finish" />}
            />
          </Col>
        </Row>
        <Body>
          <FormBody>
            <Row>
              <Col sm={8} md={4} lg={8}>
                <TextField
                  value={name}
                  validations={[required]}
                  onChange={event => setName(event.target.value)}
                  label={<FormattedMessage id="sharedItems.name" />}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextAreaField
                  value={description}
                  validations={[required]}
                  onChange={event => setDescription(event.target.value)}
                  label={<FormattedMessage id="sharedItems.description" />}
                />
              </Col>
            </Row>
            <Select stageId={stageId} openDialog={renderDialog} />
          </FormBody>
          {cardItems.map(e => (
            <Card renderDialogToDelete={renderDialogToDelete} {...e} />
          ))}
        </Body>
        {stageId && (
          <Dialog
            registerQuestionsToItem={registerQuestionsToItem}
            dialogKind={dialogKind}
            closeDialog={setShowDialog}
            show={showDialog}
            questions={questions}
            stageId={stageId}
            requestCreateQuestion={requestCreateQuestion}
            requestUpdateQuestion={requestUpdateQuestion}
            requestDeleteQuestion={requestDeleteQuestion}
            requestShowStage={requestShowStage}
          />
        )}
        <YesOrNoDialog
          confirmation={<FormattedMessage id="sharedItems.removeQuestion" />}
          question={<FormattedMessage id="sharedItems.removeQuestionTitle" />}
          show={showDialogDelete}
          confirmAction={() => {
            props.requestDeleteQuestionBlock({
              stageId,
              typeBlock: deleteTarget
            });
            setShowDialogDelete(false);
          }}
          cancelAction={() => setShowDialogDelete(!showDialogDelete)}
        />
        <ModalEditing />
      </React.Fragment>
    );
  }
  return (
    <StagePosition
      previousPosition={stage.positions || []}
      stageId={stageId}
      positions={positions}
      backCreate={setShowPositions}
      registerStagesPositions={registerStagesPositions}
      requestStagesPositions={requestStagesPositions}
      pagination={pagination}
    />
  );
};
NewStage.propTypes = {
  stage: PropTypes.objectOf({
    name: "",
    positions: [],
    description: "",
    aboutYou: [],
    carrier: [],
    goal: [],
    formation: []
  }),
  requestCreateStage: PropTypes.func,
  requestCreateQuestion: PropTypes.func,
  updateStage: PropTypes.func,
  positions: []
};
NewStage.defaultProps = {
  stage: {
    name: "",
    description: "",
    aboutYou: [],
    carrier: [],
    goal: [],
    formation: []
  },
  positions: [],
  requestCreateStage: () => {},
  requestCreateQuestion: () => {},
  updateStage: () => {}
};
export default NewStage;
