import styled from 'styled-components';

export const SpanQuestion = styled.span`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #5f5f5f;
`;

export const BodyDialogInternal = styled.div`
  padding: 0px 30px 40px 30px;
`;

export const RowBnts = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
