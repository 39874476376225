import "moment/locale/pt-br";

import * as urlsApi from "../../utils/contants/urlsApi";

import { Types as MessageTypes } from "../ducks/messages";

import {
  buildCode,
  buildErroHandleItems,
  buildPagination,
  buildTimeFromSeconds,
  setHoursInDate
} from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";
import { history } from "../../utils/routes";
import { kindStages } from "../../locale/index";
import API from "../../utils/API";
import { Types } from "../ducks/doubts";
import moment from "moment";
import {
  TiposPrimitivoDeSolicitacao,
} from "utils/contants/enums";

moment.locale("pt-BR");

const filterduvidasList = payload => {

  const body = {
    idStatus: payload.idStatus,
    idStatusList: payload.idStatusList,
    cpfList: payload.cpfList,
    idMotivoList: payload.idMotivoList,
    numeroPagina: payload.NumeroPagina,
    cpf: payload.cpf,
    respostaAdm: payload.respostaAdm,
    candidato_Id: payload.candidato_Id,
  };

  return body;
};


function requestCandidadesDoubtsList(body) {

  return API.put(
    `${urlsApi.REQUEST_CANDIDATES_DOUBTS}`,
    body
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}
function requestCandidadesManagerDoubtsList(body) {

  return API.put(
    `${urlsApi.REQUEST_CANDIDATES_DOUBTS_MANAGER}`,
    body
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* getduvidasListVacancyDoubts({ payload }) {

  try {
    const buildFilter = { ...payload };
    let actualPage;

    if (buildFilter && buildFilter.firstPage) {
      actualPage = 1;
    } else {
      actualPage = buildFilter.NumeroPagina || (yield select(getActualPage));
    }

    buildFilter.NumeroPagina = actualPage;

    const { lista, paginacao } = yield requestCandidadesDoubtsList(filterduvidasList(buildFilter));

    const duvidasList = {
      duvidas: lista.map((e) => ({
        id: e.id,
        vaga_Id: e.vaga_Id,
        candidato_Id: e.candidato_Id,
        motivo: buildStringMotivoCompleto(e.motivoSolicitacao),
        assunto: e.mensagens[0]?.texto,
        descricao: e.motivoSolicitacao.descricao,
        statusDuvida: e.status,
        respostaAdm: e.mensagens,
        dataCriacao: converterDataParaBrasileiro(e.dataCriacao),
        cpf: e.cpf,
        motivoCompleto:
          e.motivoSolicitacao.descricao +
          " - " +
          buildStringMotivoCompleto(e.motivoSolicitacao),
      })),
    };

    let duvidas;
    const stateCandidateList = yield select(getStateduvidasList);
    duvidas = duvidasList.duvidas;
    const AllduvidasList = { duvidas: duvidas }
    const paginacaoNumber = { ...paginacao, page: buildFilter.numeroPagina }

    const pagination = buildPagination(
      paginacaoNumber,
      buildFilter,
      AllduvidasList.duvidas,
      stateCandidateList,
      20,
    );

    yield put({
      type: Types.DOUBTS_LIST_SUCCESS,
      duvidas: AllduvidasList.duvidas,
      duvidasList,
      pagination
    });
  } catch (error) {

    yield put({ type: Types.DOUBTS_LIST_FAIL, error });
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}


function* enviarDocumentos(payload, response) {
  try {
    let formData = new FormData();
    formData.append("arquivo", payload);
    formData.append("idSolicitacao", response);
    formData.append("NomeArquivo", payload.name);

    var response = yield API.post(`${urlsApi.ENVIAR_DOCUMENTO_SOLICITACAO}`, formData
    )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    yield put({ type: Types.DOUBTS_SEND_DOCUMENT_SUCCESS, response });

    const messages = [{ type: "info", message: "Documento enviado com sucesso." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });

  } catch (error) {

    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.DOUBTS_SEND_DOCUMENT_FAIL, messages });
  }
}


function* createSolicitationRequest({ payload }) {
  try {

    const doubts = {
      Tipo: payload?.tipoPrimitivoDeSolicitacao?.value,
      TipoLabel: payload?.tipoPrimitivoDeSolicitacao?.label,
      AssuntoLabel: payload?.fluxoContato?.label,
      Assunto: payload?.fluxoContato?.value,
      Texto: payload?.mensagem,
    };

    var response = yield API.post(`${urlsApi.REQUEST_CANDIDATES_ANSWERS}`, doubts)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
    });


    if(payload.arquivo)
       yield enviarDocumentos(payload.arquivo, response);

    yield put({ type: Types.DOUBTS_SEND_DOCUMENT_SUCCESS, response });

    const messages = [{ type: "info", message: "Sucesso ao criar solicitação." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });

    window.location.reload();
  } catch (error) {
    console.log("Erro? ", error);
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.DOUBTS_SEND_DOCUMENT_FAIL, error });
  }
}

function* doubtsAnswersManagerRequest({ payload }) {
  try {
    const buildFilter = { ...payload };
    let actualPage;

    if (buildFilter && buildFilter.firstPage) {
      actualPage = 1;
    } else {
      actualPage = buildFilter.NumeroPagina || (yield select(getActualPage));
    }

    buildFilter.NumeroPagina = actualPage;

    const { lista, paginacao } = yield requestCandidadesManagerDoubtsList(filterduvidasList(buildFilter));

    const duvidasList = {
      duvidas: lista.map((e) => ({
        id: e.id,
        vaga_Id: e.vaga_Id,
        administrador_Solicitante_Id: e.administrador_Solicitante_Id,
        motivo: buildStringMotivoCompleto(e.motivoSolicitacao),
        assunto: e.mensagens[0]?.texto,
        descricao: e.motivoSolicitacao.descricao,
        statusDuvida: e.status,
        respostaAdm: e.mensagens,
        dataCriacao: converterDataParaBrasileiro(e.dataCriacao),
        cpf: e.cpf,
        motivoCompleto:
          e.motivoSolicitacao.descricao +
          " - " +
          buildStringMotivoCompleto(e.motivoSolicitacao),
      })),
    };

    let duvidas;
    const stateCandidateList = yield select(getStateduvidasList);
    duvidas = duvidasList.duvidas;
    const AllduvidasList = { duvidas: duvidas }
    const paginacaoNumber = { ...paginacao, page: buildFilter.numeroPagina }

    const pagination = buildPagination(
      paginacaoNumber,
      buildFilter,
      AllduvidasList.duvidas,
      stateCandidateList,
      20,
    );

    yield put({
      type: Types.DOUBTS_ANSWERS_MANAGER_SUCCESS,
      duvidas: AllduvidasList.duvidas,
      duvidasList,
      pagination
    });

    const messages = [{ type: "info", message: "Sucesso ao Carregar as Solicitações." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {

    console.log("Erro? ", error);
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.DOUBTS_CREATE_MANAGER_FAIL, messages });
  }
}



function requestCandidadesAnswers(body) {
  return API.post(`${urlsApi.ENVIAR_MENSAGEM_SOLICITACAO}`, body)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* ResponderDuvida(payload) {
  try {

    const doubts = {
      SolicitacaoId: payload.payload.id,
      Texto: payload.payload.respostaAdm,
    };

    var response = yield requestCandidadesAnswers(doubts);

    if(payload.payload.arquivo)
      yield enviarDocumentos(payload.payload.arquivo, response);


    yield put({
      type: Types.DOUBTS_CREATE_MANAGER_SUCCESS,
      response
    });

    const messages = [{ type: "info", message: "Sucesso ao responder solicitacao." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });

    window.location.reload();

  } catch (error) {

    console.log("error: ", error);

    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.DOUBTS_CREATE_MANAGER_FAIL, messages });
  }
}


function* downloadDocumentsSolicitationFilesRequest({ payload }) {
  const idDocumentoCandidato = payload.documentoDigitalizado_Id;

  try {
    const response = API.post(
      `${urlsApi.DOWLOAD_DOCUMENTS_FILES}?idDocumentoCandidato=${idDocumentoCandidato}`,
      {},
      {
        headers: {
          "Content-Type": "application/zip",
        },
        responseType: "arraybuffer",
      }
    )
    .then(res => {
      const contentDisposition = res.headers['content-disposition'];
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      const filename = matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : 'arquivo';

      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => { throw error });

    yield put({
      type: Types.DOWNLOAD_DOCUMENTS_SOLICITATION_FILES_SUCCESS,
      response,
    });

    window.location.reload();
  } catch (error) {
    yield put({ type: Types.DOWNLOAD_DOCUMENTS_SOLICITATION_FILES_FAIL, error });
  }
}

const getActualPage = state => state.doubts?.page || 1;
const getStateduvidasList = state => state.doubts?.duvidas;

function converterDataParaBrasileiro(dataISO) {
  const dataInicial = dataISO.toString().split("T")[0];
  const [ano, mes, dia] = dataInicial.toString().split("-");
  return `${dia}/${mes}/${ano}`;
}

function buildStringMotivoCompleto(objMotivo) {
  return TiposPrimitivoDeSolicitacao.find(
    (motivo) => motivo.value == objMotivo?.tipo
  )?.label;
}

export function* watcherSaga() {
  yield takeLatest(Types.DOUBTS_CREATE_MANAGER, createSolicitationRequest);
  yield takeLatest(Types.DOUBTS_LIST_REQUEST, getduvidasListVacancyDoubts);
  yield takeLatest(Types.DOUBTS_ANSWERS_MANAGER_REQUEST, doubtsAnswersManagerRequest);
  yield takeLatest(Types.DOUBTS_ANSWERS_REQUEST, ResponderDuvida);

  yield takeLatest(Types.DOUBTS_SEND_DOCUMENT_REQUEST, enviarDocumentos);
  yield takeLatest(Types.DOWNLOAD_DOCUMENTS_SOLICITATION_FILES_REQUEST, downloadDocumentsSolicitationFilesRequest);
}



