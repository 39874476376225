import React from 'react';

import { Icon } from 'react-icons-kit';
import {image} from 'react-icons-kit/icomoon/image';

import * as listIcons from 'react-icons-kit/md';

import './iconcard.scss';

export const IconCard = ({data}) => {

    return (
        <div className="dash-card-iconcard">
            <div className={`box ${data?.style || ''}`}>
                <div className="icon">
                    <div className="circle" style={{'--title': data?.customStyle?.color}}>
                        {data?.customStyle?.icon ?  <Icon icon={listIcons[data?.customStyle?.icon]} /> : <Icon icon={image} />}
                    </div>
                </div>
                <div className="title" style={{'--title': data?.customStyle?.color}}>
                    {data?.data || 'No content'}   
                </div>
                <div className="content">
                    {data?.title || 'No title'}
                </div>
            </div>
        </div>
    )
}