import styled from 'styled-components';
import theme from '../shared/Theme';

export const TableBody = styled.div`
  background-color: ${theme.colors.whiteDefault};
  width: 100%;
  padding: 20px;
  box-shadow: 0px -1px 2px rgba(208,208,208,0.4);
`;

export const Nav = styled.nav`
  list-style: none;
  display: flex;
  flex: 1;
  width: 100%;
`;

export const Ul = styled.nav`
  display: flex;
  padding: 0px;
  margin: 0px;
  list-style: none;
  flex-flow: row;
  flex: 1;
  width: 100%;
`;

export const Li = styled.nav`
  list-style: none;
  background-color: ${props => (props.selected ? theme.colors.whiteDefault : '#f6f6f6')};
  height: ${props => (props.selected ? '50px' : '49px')};
  box-shadow: 0px -1px 2px rgba(208,208,208,0.4);
  transition: background-color 200ms linear;
  label {
    color: ${props => props.selected && theme.colors.defaultColor};
  }
  margin-right: 5px;
  border-radius: 6px 6px 0px 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex: 1;
  
  :hover {
    cursor: pointer;
  }
`;

export const Label = styled.label`
  font-family: Arial;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #858585;
  margin: 0;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
