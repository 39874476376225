import * as urls from "utils/contants/urls";

import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow
} from "components/shared/CustomComponentsStyle";
import { ActionsRow, Body } from "./RailsStyle";
import {
  ButtonDefault,
  SelectFieldInput
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ToolTip, YesOrNoDialog } from "components/shared/CustomComponents";
import {
  disabled,
  edit,
  filterIcon,
  order as orderIcon,
  start,
  statusOk,
  stop
} from "assets/icons/icons";

import BreadCrumb from "components/Layout/breadcrumb";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as formInputAction } from "store/ducks/formInputs";
import { orderList } from "helpers/sharedHelpers";
import { push } from "connected-react-router";
import { Creators as railsAction } from "store/ducks/rails";

const Rails = ({
  getIputValues,
  inputs,
  rails,
  requestShowList,
  requestList,
  pagination,
  requestDisableRail,
  location,
  requestJobsFromArea
}) => {
  const [filterItems, setFilterItems] = useState({ area: null, job: null });
  const [disabledInput, setDisabledInput] = useState(true);
  const [showDialogYesOrNo, setShowDialogYesOrNo] = useState(false);
  const [idItemToDesable, setIdItemToDesable] = useState("");
  const [railsList, setRailsList] = useState([]);
  const [order, setOrder] = useState(undefined);

  useEffect(() => {
    getIputValues(["area"]);
  }, [getIputValues]);

  useEffect(() => {
    requestList({ ...filterItems });
  }, []);

  useEffect(() => {
    requestList({ ...filterItems });
  }, [filterItems]);

  useEffect(() => {
    if (rails && rails.rails) {
      setRailsList(rails.rails);
    }
  }, [rails]);

  const alterValueInput = idArea => {
    setFilterItems({ ...filterItems, area: idArea, page: 1 });
    if (idArea) {
      requestJobsFromArea(idArea);
      setDisabledInput(false);
    } else {
      setDisabledInput(true);
    }
  };

  const requestPage = filter => {
    requestList({ ...filterItems, page: filter.NumeroPagina });
  };

  const disableRailAction = () => {
    requestDisableRail(idItemToDesable);
    setShowDialogYesOrNo(!showDialogYesOrNo);
  };

  const showDialogAndRegisterItem = id => {
    setIdItemToDesable(id);
    setShowDialogYesOrNo(!showDialogYesOrNo);
  };

  const orderBy = orderBy => {
    let response;
    switch (orderBy) {
      case "area":
        response = orderList("area", order, railsList);
        break;
      case "job":
        response = orderList("role", order, railsList);
        break;
      case "stage":
        response = orderList("stages", order, railsList);
        break;
      case "createdBy":
        response = orderList("createdBy", order, railsList);
        break;
      case "createdAt":
        response = orderList("createdAt", order, railsList);
        break;
      default:
        response = { list: railsList, order: undefined };
        break;
    }

    setRailsList(response.list);
    setOrder(response.order);
  };

  const renderIcon = key => {
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` }
    });
  };

  const renderTableItens = itens =>
    itens.map(rail => (
      <TableRow key={rail.id}>
        <TableColumn ta="center" onClick={() => requestShowList(rail.id)}>
          {rail.status
            ? statusOk({ width: "20px", height: "20px" })
            : disabled({ width: "20px", height: "20px" })}
        </TableColumn>
        <TableColumn ta="center" onClick={() => requestShowList(rail.id)}>
          <Label>{rail.area}</Label>
        </TableColumn>
        <TableColumn ta="center" onClick={() => requestShowList(rail.id)}>
          <Label>{rail.role}</Label>
        </TableColumn>
        <TableColumn onClick={() => requestShowList(rail.id)}>
          <Label
            data-for={`tooltip-${rail.id}`}
            data-tip="data-tip"
            color="default"
          >
            {rail.stages.length}
          </Label>
          <ToolTip id={`tooltip-${rail.id}`} message={rail.stages} />
        </TableColumn>
        <TableColumn onClick={() => requestShowList(rail.id)}>
          <Label>{rail.createdBy}</Label>
        </TableColumn>
        <TableColumn onClick={() => requestShowList(rail.id)}>
          <Label>{rail.createdAt}</Label>
        </TableColumn>
        <TableColumn action flexPosition="flex-end">
          <Actions>
            <ActionItem onClick={() => requestShowList(rail.id)}>
              {edit()}
              <LabelAction>
                <FormattedMessage id="sharedItems.edit" />
              </LabelAction>
            </ActionItem>
            <ActionItem onClick={() => showDialogAndRegisterItem(rail.id)}>
              {rail.status ? (
                <React.Fragment>
                  {stop()}
                  <LabelAction>
                    <FormattedMessage id="sharedItems.disabled" />
                  </LabelAction>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {start()}
                  <LabelAction>
                    <FormattedMessage id="sharedItems.enable" />
                  </LabelAction>
                </React.Fragment>
              )}
            </ActionItem>
          </Actions>
        </TableColumn>
      </TableRow>
    ));

  if (inputs.isLoading || rails.isLoading)
    return <Load isLoading={inputs.isLoading || rails.isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb />
      <Row style={{ marginBottom: "20px" }}>
        <Col lg={10}>
          <BreadCrumb
            location={location}
            title={<FormattedMessage id="rails.title" />}
          />
        </Col>
        <Col lg={2}>
          <Link to={urls.RAILS_NEW}>
            <ButtonDefault name={<FormattedMessage id="rails.newRails" />} />
          </Link>
        </Col>
      </Row>
      <Body>
        <ActionsRow>
          <Row>
            <div>{filterIcon()}</div>
            <Col lg={2}>
              <SelectFieldInput style = {{color:"hsl(0,0%,50%)"}}
                onChange={e => alterValueInput(e.target.value)}
                items={inputs.area}
                value={filterItems.area}
              />
            </Col>
            <Col lg={2}>
              {!disabledInput && (
                <SelectFieldInput
                  onChange={e =>
                    setFilterItems({
                      ...filterItems,
                      job: e.target.value,
                      page: 1
                    })
                  }
                  disabled={disabledInput}
                  items={inputs.jobs}
                  value={filterItems.job}
                />
              )}
            </Col>
          </Row>
        </ActionsRow>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead />
              <TableHead onClick={() => orderBy("area")}>
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.area" />
                </LabelHeader>
                {renderIcon("area")}
              </TableHead>
              <TableHead onClick={() => orderBy("job")}>
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.job" />
                </LabelHeader>
                {renderIcon("job")}
              </TableHead>
              <TableHead onClick={() => orderBy("stage")}>
                <LabelHeader>
                  <FormattedMessage id="menuTalentBar.stage" />
                </LabelHeader>
                {renderIcon("stage")}
              </TableHead>
              <TableHead onClick={() => orderBy("createdBy")}>
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.createdBy" />
                </LabelHeader>
                {renderIcon("createdBy")}
              </TableHead>
              <TableHead onClick={() => orderBy("createdAt")}>
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.createdAt" />
                </LabelHeader>
                {renderIcon("createdAt")}
              </TableHead>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="vacancyList.columns.actions" />
                </LabelHeader>
              </TableHead>
            </TableRow>
          </TableBody>
          {renderTableItens(railsList)}
        </Table>
        <Pagination getItemsPage={requestPage} {...pagination} />
      </Body>
      <YesOrNoDialog
        show={showDialogYesOrNo}
        cancelAction={() => setShowDialogYesOrNo(false)}
        confirmAction={() => disableRailAction()}
        question={<FormattedMessage id="rails.desableRails" />}
      />
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  getIputValues: kind => dispatch(formInputAction.formInputsRequest(kind)),
  requestList: filters => dispatch(railsAction.requestListRails(filters)),
  requestShowList: id => dispatch(push(`${urls.RAILS_SHOW}/${id}`)),
  requestDisableRail: id => dispatch(railsAction.requestDisableRails(id)),
  requestJobsFromArea: idArea =>
    dispatch(formInputAction.formInputsRequestJobFromArea(idArea))
});
const mapStateToProps = state => ({
  inputs: state.formInputs,
  rails: state.rails,
  pagination: state.rails.pagination
});
Rails.propTypes = {
  getIputValues: PropTypes.func,
  inputs: PropTypes.objectOf({
    area: []
  }),
  rails: PropTypes.arrayOf({}),
  requestRails: PropTypes.func
};

Rails.defaultProps = {
  getIputValues: () => {},
  requestRails: () => {},
  rails: [],
  inputs: {
    area: [],
    isLoading: true
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Rails);
