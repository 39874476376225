import * as routes from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";

import { put, takeLatest, select } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/auth";
import { buildErroHandleItems } from "../../helpers/sharedHelpers";
import { history } from "../../utils/routes";

function requestLogin(params) {
  return API.post(urlsApi.login, params)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error;
    });
}

function changePassword(params) {
  return API.post(urlsApi.CHANGE_PASSWORD_MANAGER, params)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw error;
    });
}

function* signInUser({ payload }) {
  try {
    const login = {
      email: payload.email,
      senha: payload.password
    };


    const { token, podeEditarEtapa, nome, podeEditarPerfil, podeAcessarPlug, podeAcessarTalentos, tipoPerfilUsuario } = yield requestLogin(login);
    localStorage.clear();

    localStorage.setItem("Authorization", token);
    localStorage.setItem("name", nome);
    localStorage.setItem("podeeditaretapa", podeEditarEtapa);
    localStorage.setItem("podeeditarperfil", podeEditarPerfil);
    localStorage.setItem("podeAcessarPlug", podeAcessarPlug);
    localStorage.setItem("tipoPerfilUsuario", tipoPerfilUsuario);
    localStorage.setItem("podeAcessarTalentos", podeAcessarTalentos);
    localStorage.setItem("email", payload.email);

    const userInformations = {
      token,
      podeEditarEtapa,
      nome,
      podeEditarPerfil,
      podeAcessarPlug,
      podeAcessarTalentos,
      tipoPerfilUsuario,
    };

    Object.assign(API.defaults, {
      headers: { Authorization: `Basic ${token}` }
    });

    yield put({ type: Types.AUTH_SUCCESS, userInformations });

    history.push(routes.APP);
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.AUTH_FAIL, errors: error.response.data });
  }
}

function* forgotPassword({ payload }) {
  try {
    history.push("/home");
    localStorage.setItem("email", payload.email);
    yield put({ type: Types.AUTH_FORGOT_SUCCESS, payload });
  } catch (error) {
    yield put({ type: Types.AUTH_FORGOT_FAIL, error });
  }
}

function* confirmeNewPassword({ payload }) {
  try {
    let obj = {
      senhaAntiga: payload.password,
      novaSenha: payload.newPassword,
      confirmacaoNovaSenha: payload.confirmPassword,
      email: localStorage.getItem("email")
    }
    yield changePassword(obj);
    history.push(routes.APP);
    yield put({ type: Types.AUTH_RECOVERY_SUCCESS, payload });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.AUTH_RECOVERY_FAIL, error });
  }
}

const getStateCandidatesList = (state) => state.login?.userInformations;

function* getUserAuthData() {
  try {

    const userInformations = yield select(getStateCandidatesList);

    yield put({ type: Types.GET_USER_AUTH_DATA_SUCCESS, userInformations });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.AUTH_RECOVERY_FAIL, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.AUTH_REQUEST, signInUser);
  yield takeLatest(Types.AUTH_FORGOT_REQUEST, forgotPassword);
  yield takeLatest(Types.AUTH_RECOVERY_REQUEST, confirmeNewPassword);

  yield takeLatest(Types.GET_USER_AUTH_DATA, getUserAuthData);
}
