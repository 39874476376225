import * as urls from "utils/contants/urls";
import * as Permissions from "utils/PerfilAcesso";
import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow
} from "components/shared/CustomComponentsStyle";
import { Col, Row } from "react-bootstrap";
import React, { PureComponent } from "react";
import { cancel, edit, order as orderIcon } from "assets/icons/icons";

import BreadCrumb from "components/Layout/breadcrumb";
import { ButtonDefault } from "components/shared/CustomInputs";
import FilterStage from "components/Forms/FilterStage";
import { FlexButton } from "./StageListStyle";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import Tab from "components/Tab";
import { YesOrNoDialog } from "components/shared/CustomComponents";
import { connect } from "react-redux";
import { orderList } from "helpers/sharedHelpers";
import { Creators as stageActions } from "store/ducks/stages";

const renderTableItens = (itens, showVaga, deleteJob) =>
  itens.map(job => (
    <TableRow key={job.id}>
      <TableColumn first ta="left" onClick={() => showVaga(job.id)}>
        <Label ta="left">{job.name}</Label>
      </TableColumn>
      <TableColumn ta="left" onClick={() => showVaga(job.id)}>
        <Label ta="left">
          {job.kindTest ? job.kindTest.nome : job.kind ? job.kind.nome : null}
        </Label>
      </TableColumn>
      {job.kindTest && Permissions.canEditEtapa() && (
        <React.Fragment>
          <TableColumn ta="left" onClick={() => showVaga(job.id)}>
            <Label ta="left">{job.level && job.level.nome}</Label>
          </TableColumn>
          <TableColumn ta="left" onClick={() => showVaga(job.id)}>
            <Label ta="left">{job.avaliation}</Label>
          </TableColumn>
          <TableColumn ta="left" onClick={() => showVaga(job.id)}>
            <Label ta="left">{job.timeTestMinutes}</Label>
          </TableColumn>
          <TableColumn ta="left" onClick={() => showVaga(job.id)}>
            <Label ta="left">{job.amountOfQuestions}</Label>
          </TableColumn>
        </React.Fragment>
      )}
      {Permissions.canEditEtapa() && (
      <TableColumn ta="right" >
        <Actions flexPosition="flex-end">
          <ActionItem onClick={() => showVaga(job.id)} mr="30px">
            {edit()}
            <LabelAction>
              <FormattedMessage id="sharedItems.edit" />
            </LabelAction>
          </ActionItem>
          <ActionItem onClick={() => deleteJob(job.id)}>
            {cancel()}
            <LabelAction>
              <FormattedMessage id="sharedItems.cancel" />
            </LabelAction>
          </ActionItem>
        </Actions>
      </TableColumn>
      )}
    </TableRow>
  ));

class StageList extends PureComponent {
  constructor() {
    super();
    this.state = {
      labelButton: <FormattedMessage id="defaultStage.stagesNames.newEstage" />,
      url: urls.STAGE_REGISTRATION_DATA_NEW,
      filter: localStorage.getItem("filter") || "baseInfos",
      targetFilter: localStorage.getItem("targetFilter") || "",
      showDialog: false,
      columns: [],
      stages: [],
      filters: { search: "", type: "", level: "" }
    };

    this.onChangeFilters = this.onChangeFilters.bind(this);
    this.requestNextPage = this.requestNextPage.bind(this);
  }

  componentDidMount() {
    this.renderFocusTabel(localStorage.getItem("renderFocusTabel"));
    const tab = localStorage.getItem("filter") || "baseInfos";
    this.props.requestStages(tab, 1);
    this.setState({
      columns: [
        {
          name: <FormattedMessage id="stageList.data" />,
          focus: this.state.filter === "baseInfos",
          action: () => {
            this.renderFocusTabel(urls.STAGE_REGISTRATION_DATA_NEW);
            localStorage.setItem("filter", "baseInfos");
            localStorage.setItem("targetFilter", "");
            localStorage.setItem(
              "renderFocusTabel",
              urls.STAGE_REGISTRATION_DATA_NEW
            );
          }
        },
        {
          name: <FormattedMessage id="stageList.test" />,
          focus: this.state.filter === "tests",
          action: () => {
            this.renderFocusTabel(urls.STAGES_TESTS);
            localStorage.setItem("filter", "tests");
            localStorage.setItem("targetFilter", "testeInfo");
            localStorage.setItem("renderFocusTabel", urls.STAGES_TESTS);
          }
        },
        {
          name: <FormattedMessage id="stageList.trainner" />,
          focus: this.state.filter === "trainner",
          action: () => {
            this.renderFocusTabel(urls.STAGES_TRAINNER);
            localStorage.setItem("filter", "trainner");
            localStorage.setItem("targetFilter", "trainnerInfo");
            localStorage.setItem("renderFocusTabel", urls.STAGES_TRAINNER);
          }
        },
        {
          name: <FormattedMessage id="stageList.assay" />,
          focus: this.state.filter === "assay",
          action: () => {
            this.renderFocusTabel(urls.STAGES_ASSAY);
            localStorage.setItem("filter", "assay");
            localStorage.setItem("targetFilter", "assayInfo");
            localStorage.setItem("renderFocusTabel", urls.STAGES_ASSAY);
          }
        },
        {
          name: <FormattedMessage id="stageList.medic" />,
          focus: this.state.filter === "medicExame",
          action: () => {
            this.renderFocusTabel(urls.STAGES_MEDIC_EXAME);
            localStorage.setItem("filter", "medicExame");
            localStorage.setItem("targetFilter", "medicExameInfo");
            localStorage.setItem("renderFocusTabel", urls.STAGES_MEDIC_EXAME);
          }
        },
        /*         {
          name: <FormattedMessage id="stageList.preAdmition" />,
          focus: this.state.filter === "preAdmition",
          action: () => {
            this.renderFocusTabel(urls.STAGES_PRE_ADMITION);
            localStorage.setItem("filter", "preAdmition");
            localStorage.setItem("targetFilter", "");
            localStorage.setItem("renderFocusTabel", urls.STAGES_PRE_ADMITION);
          }
        }, */
        {
          name: <FormattedMessage id="stageList.admition" />,
          focus: this.state.filter === "admission",
          action: () => {
            this.renderFocusTabel(urls.STAGES_ADMITION);
            localStorage.setItem("filter", "admission");
            localStorage.setItem("targetFilter", "");
            localStorage.setItem("renderFocusTabel", urls.STAGES_ADMITION);
          }
        },
        {
          name: <FormattedMessage id="stageList.ambietation" />,
          focus: this.state.filter === "ambientation",
          action: () => {
            this.renderFocusTabel(urls.STAGES_AMBIENTATION);
            localStorage.setItem("filter", "ambientation");
            localStorage.setItem("targetFilter", "ambientationInfo");
            localStorage.setItem("renderFocusTabel", urls.STAGES_AMBIENTATION);
          }
        },
        {
          name: <FormattedMessage id="stageList.feedback" />,
          focus: this.state.filter === "feedback",
          action: () => {
            this.renderFocusTabel(urls.STAGES_FEEDBACK);
            localStorage.setItem("filter", "feedback");
            localStorage.setItem("targetFilter", "");
            localStorage.setItem("renderFocusTabel", urls.STAGES_FEEDBACK);
          }
        },
        {
          name: <FormattedMessage id="stageList.contract" />,
          focus: this.state.filter === "contract",
          action: () => {
            this.renderFocusTabel(urls.STAGES_CONTRACT);
            localStorage.setItem("filter", "contract");
            localStorage.setItem("targetFilter", "");
            localStorage.setItem("renderFocusTabel", urls.STAGES_CONTRACT);
          }
        }
      ]
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (state.stages == props.stages) {
      return null;
    }
    const renderKindColumn = StageList.shouldRenderKindColumn(
      props.stages.stages
    );
    return {
      ...state,
      stages: props.stages.stages,
      ...renderKindColumn
    };
  }

  static shouldRenderKindColumn(stages) {
    let renderKindColumn = false;
    let columnName = "";
    let columnKey = "";
    if (stages.length) {
      renderKindColumn = stages[0].kindTest || stages[0].kind;
      if (stages[0].kindTest) {
        columnName = "stageList.table.kindTest";
        columnKey = "kindTest";
      } else if (stages[0].kind) {
        columnName = "stageList.table.kind";
        columnKey = "kind";
      }
    }
    return { renderKindColumn, columnName, columnKey };
  }

  onChangeFilters(filters) {
    this.setState(prev => ({
      ...prev,
      filters: filters
    }));
  }

  filterStages() {
    const { search, type, level } = this.state.filters;
    return this.state.stages.filter(e => {
      const stageType = e.kindTest ? e.kindTest : e.kind;
      try {
        if (type && level) {
          return (
            stageType.id == type &&
            e.level.id == level &&
            e.name.includes(search)
          );
        } else if (type) {
          return stageType.id == type && e.name.includes(search);
        } else if (level) {
          return e.level.id == level && e.name.includes(search);
        } else return e.name.includes(search);
      } catch (error) {
        return true;
      }
    });
  }

  orderBy = orderBy => {
    const stages = this.state.stages;
    const { order } = this.state;
    let response;
    switch (orderBy) {
      case "kind":
        response = orderList(this.state.columnKey, order, stages);
        break;
      case "name":
        response = orderList("name", order, stages);
        break;
      case "level":
        response = orderList("level", order, stages);
        break;
      case "avaliation":
        response = orderList("avaliation", order, stages);
        break;
      case "timeTestMinutes":
        response = orderList("timeTestMinutes", order, stages);
        break;
      case "amountOfQuestions":
        response = orderList("amountOfQuestions", order, stages);
        break;
      default:
        response = { list: stages, order: undefined };
        break;
    }
    this.setState({ stages: response.list, order: response.order });
  };

  renderIcon(key) {
    const { order } = this.state;
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` }
    });
  }

  renderFocusTabel = url => {
    localStorage.setItem("renderFocusTabel", url);
    switch (url) {
      case urls.STAGE_REGISTRATION_DATA_NEW:
        this.setState({
          labelButton: (
            <FormattedMessage id="defaultStage.stagesNames.newEstage" />
          ),
          url,
          filter: "baseInfos",
          targetFilter: ""
        });
        this.props.requestStages("baseInfos", 1);
        break;
      case urls.STAGES_TESTS:
        this.setState({
          labelButton: <FormattedMessage id="defaultStage.stagesNames.test" />,
          url,
          filter: "tests",
          targetFilter: "testeInfo"
        });
        this.props.requestStages("tests", 1);
        this.props.requestTestInfos(["testeInfo"]);
        break;
      case urls.STAGES_TRAINNER:
        this.setState({
          labelButton: (
            <FormattedMessage id="defaultStage.stagesNames.trainner" />
          ),
          url,
          filter: "trainner",
          targetFilter: "trainnerInfo"
        });
        this.props.requestStages("trainner", 1);
        this.props.requestTestInfos(["trainnerInfo"]);
        break;
      case urls.STAGES_ASSAY:
        this.setState({
          labelButton: <FormattedMessage id="defaultStage.stagesNames.assay" />,
          url,
          filter: "assay",
          targetFilter: "assayInfo"
        });
        this.props.requestStages("assay", 1);
        this.props.requestTestInfos(["assayInfo"]);
        break;
      case urls.STAGES_MEDIC_EXAME:
        this.setState({
          labelButton: (
            <FormattedMessage id="defaultStage.stagesNames.medicExame" />
          ),
          url,
          filter: "medicExame",
          targetFilter: "medicExameInfo"
        });
        this.props.requestStages("medicExame", 1);
        this.props.requestTestInfos(["medicExameInfo"]);
        break;
      case urls.STAGES_ADMITION:
        this.setState({
          labelButton: (
            <FormattedMessage id="defaultStage.stagesNames.admition" />
          ),
          url,
          filter: "admission",
          targetFilter: ""
        });
        this.props.requestStages("admission", 1);
        break;
      case urls.STAGES_AMBIENTATION:
        this.setState({
          labelButton: (
            <FormattedMessage id="defaultStage.stagesNames.ambietation" />
          ),
          url,
          filter: "ambientation",
          targetFilter: "ambientationInfo"
        });
        this.props.requestStages("ambientation", 1);
        this.props.requestTestInfos(["ambientationInfo"]);
        break;
      case urls.STAGES_FEEDBACK:
        this.setState({
          labelButton: (
            <FormattedMessage id="defaultStage.stagesNames.feedBack" />
          ),
          url,
          filter: "feedback",
          targetFilter: ""
        });
        this.props.requestStages("feedback", 1);
        break;
      case urls.STAGES_PRE_ADMITION:
        this.setState({
          labelButton: (
            <FormattedMessage id="defaultStage.stagesNames.preAdmition" />
          ),
          url,
          filter: "preAdmition",
          targetFilter: ""
        });
        this.props.requestStages("preAdmition", 1);
        break;
      case urls.STAGES_CONTRACT:
          this.setState({
            labelButton: (
              <FormattedMessage id="defaultStage.stagesNames.contract" />
            ),
            url,
            filter: "contract",
            targetFilter: ""
          });
          this.props.requestStages("contract", 1);
          break;
      default:
        break;
    }
  };

  requestNextPage(newPage) {
    this.props.requestStages(this.state.filter, newPage.NumeroPagina);
  }

  render() {
    const { stages } = this.props;
    const { pagination } = this.props;
    const { isLoading } = this.props;
    const { requestShowStage } = this.props;
    const { stageDeleteRequest } = this.props;
    const { location } = this.props;
    const deleteQuestion = () => {
      const { stageId, filter } = this.state;
      stageDeleteRequest({ stageId, filter });
      this.setState({ showDialog: !showDialog });
    };
    const showDialog = stageId => {
      const { showDialog } = this.state;
      this.setState({ showDialog: !showDialog, stageId: stageId });
    };
    if (isLoading) return <Load isLoading={isLoading} />;
    return (
      <React.Fragment>
        <Row style={{ marginBottom: "27px" }}>
          <Col>
            <BreadCrumb
              location={location}
              title={<FormattedMessage id="menuTalentBar.stage" />}
            />
          </Col>
          <Col lg={4}>
            <FlexButton>
              <Link to={this.state.url}>
                <ButtonDefault disabled={!Permissions.canEditEtapa()} width={"250px"} name={this.state.labelButton} />
              </Link>
            </FlexButton>
          </Col>
        </Row>
        <Tab columns={this.state.columns}>
          <FilterStage
            kindFilter={this.state.targetFilter}
            onChangeFilter={this.onChangeFilters}
            options={this.props.options}
          />
          <Table>
            <TableBody>
              <TableRow noHover noBorderTop>
                <TableHead
                  first
                  noBorderTop
                  ta="left"
                  onClick={() => this.orderBy("name")}
                >
                  <LabelHeader ta="left">
                    {<FormattedMessage id="stageList.table.name" />}
                    {this.renderIcon("name")}
                  </LabelHeader>
                </TableHead>
                {this.state.renderKindColumn && (
                  <TableHead
                    noBorderTop
                    ta="left"
                    onClick={() => this.orderBy("kind")}
                  >
                    <LabelHeader ta="left">
                      {<FormattedMessage id={this.state.columnName} />}
                      {this.renderIcon(this.state.columnKey)}
                    </LabelHeader>
                  </TableHead>
                )}
                {this.state.filter == "tests" ? (
                  <React.Fragment>
                    <TableHead
                      noBorderTop
                      ta="left"
                      onClick={() => this.orderBy("level")}
                    >
                      <LabelHeader ta="left">
                        {<FormattedMessage id="stageList.table.level" />}
                        {this.renderIcon("level")}
                      </LabelHeader>
                    </TableHead>
                    <TableHead
                      noBorderTop
                      ta="left"
                      onClick={() => this.orderBy("avaliation")}
                    >
                      <LabelHeader ta="left">
                        {<FormattedMessage id="stageList.table.avaliation" />}
                        {this.renderIcon("avaliation")}
                      </LabelHeader>
                    </TableHead>
                    <TableHead
                      noBorderTop
                      ta="left"
                      onClick={() => this.orderBy("timeTestMinutes")}
                    >
                      <LabelHeader ta="left">
                        {
                          <FormattedMessage id="stageList.table.questionsTime" />
                        }
                        {this.renderIcon("timeTestMinutes")}
                      </LabelHeader>
                    </TableHead>
                    <TableHead
                      noBorderTop
                      ta="left"
                      onClick={() => this.orderBy("amountOfQuestions")}
                    >
                      <LabelHeader ta="left">
                        {
                          <FormattedMessage id="stageList.table.amountOfQuestions" />
                        }
                        {this.renderIcon("amountOfQuestions")}
                      </LabelHeader>
                    </TableHead>
                    <TableHead noBorderTop ta="left" />
                  </React.Fragment>
                ) : null}
              </TableRow>
              {!stages.isLoading &&
                renderTableItens(
                  this.filterStages(),
                  requestShowStage,
                  showDialog
                )}
            </TableBody>
          </Table>
          <Pagination
            filter={this.state.filter}
            filterValue={this.state.filter}
            getItemsPage={this.requestNextPage}
            {...pagination}
          />
        </Tab>
        <YesOrNoDialog
          confirmation={
            <FormattedMessage id="newStageAboutYou.dialog.questionConfirmation" />
          }
          question={<FormattedMessage id="newStageAboutYou.dialog.question" />}
          show={this.state.showDialog}
          confirmAction={deleteQuestion}
          cancelAction={() => this.setState({ showDialog: !showDialog })}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  requestStages: (listKind, page) =>
    dispatch(stageActions.stageRequest(listKind, page)),
  requestShowStage: id => dispatch(stageActions.stageShowRequest(id)),
  stageDeleteRequest: stageId =>
    dispatch(stageActions.stageDeleteRequest(stageId)),
  requestTestInfos: formKind =>
    dispatch(stageActions.stageInfosRequest(formKind))
});

const mapStateToProps = state => ({
  stages: state.stages,
  isLoading: state.stages.isLoading,
  pagination: state.stages.pagination,
  options: state.stages.options
});

StageList.propTypes = {
  stages: PropTypes.array,
  pagination: PropTypes.object,
  requestStages: () => {}
};

StageList.defaultProps = {
  stages: {
    stages: []
  },
  pagination: {
    quantidade: 0
  },
  requestStages: () => {}
};
export default connect(mapStateToProps, mapDispatchToProps)(StageList);
