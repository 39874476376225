import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import pt from 'react-intl/locale-data/pt';
import locale from './locale';
import flattenMessages from './helpers/flattenMessages';
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';
import Routes from './utils/routes';

require('./index.css');

addLocaleData([...en, ...pt]);
const localeLg = "pt-BR";
ReactDOM.render(
  <body>
    <IntlProvider locale={localeLg} messages={flattenMessages(locale[localeLg])}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </IntlProvider>
  </body>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
