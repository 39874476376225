import * as routes from "utils/contants/urls";
import theme from "../../../../../components/shared/Theme";
import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { view } from "assets/icons/icons";
import { Body } from "./ListDoubtsCandidatesManagerStyle";
import BreadCrumb from "components/Layout/breadcrumb";

import { Button, Form, Modal, InputGroup } from "react-bootstrap";

import {
  FluxoValeTransporte,
  FluxoValeRefeicao,
  FluxoPlanoDeSaude,
  FluxoContraCheque,
  FluxoFerias,
  FluxoDemissao,
  StatusSolicitacaoCandidato,
  TiposPrimitivoDeSolicitacao,
} from "../../../../../utils/contants/enums";

import {
  MultSelectCreatable,
  MultSelect,
  CheckBox,
  ButtonSecondary,
  DatePicker,
  TextField,
  ButtonDefault,
} from "components/shared/CustomInputs";
import { Collapse, SelectInput } from "components/shared/CustomComponents";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as doubtsAction } from "store/ducks/doubts";
import Pagination from "components/Pagination";
import ButtonUpload from "../../../../../components/Layout/ButtonUpload/ButtonUpload";

import { Creators as messageAction } from "store/ducks/messages";

const RowColorStage = (status) => {
  let color;
  switch (status) {
    case 0:
      color = "	#FFD700";
      break;
    case 1:
      color = "	#F0E68C";
      break;
    case 2:
      color = "	#32CD32";
      break;
    case 3:
      color = "#A020F0";
      break;
    default:
      color = "#FFFFE0";
      break;
  }

  return {
    color,
    height: "18px",
    width: "18px",
    borderRadius: "50%",
    float: "left",
    display: "inline-block",
    backgroundColor: color,
  };
};

const redirect = (url) => {
  window.location.href = url;
};

const ListDoubtsCandidatesManager = ({
  doubtsListRequest,
  ListDoubts,
  duvidas,
  candidates,
  isLoading,
  pagination,
  location,
  messagePublicationRequest,
  hasMore,
  doubtsAnswersRequest,
  doubtsCreateManagerRequest,
  postRespostaDuvidas,
  downloadDocument,
}) => {
  const [clearFilter, setClearFilter] = useState(false);
  const [formValues, setFormValues] = useState({
    cpfs: [],
    idVagas: [],
    idLocations: [],
    dataAdmissao: "",
    nomeFuncionario: "",
    vaga_Id: "",
    motivo: "",
    assunto: "",
    descricao: "",
    statusDuvida: "",
    candidato_Id: "",
    respostaAdm: "",
    idStatus: [],
    idMotivoList: [],
    id: "",
    motivoContato: "",
    selectedOption: null,
    mensagem: "",
  });

  const formValuesRef = useRef([formValues]);

  const [erroMessage, setErroMessage] = useState({ message: "" });
  const [contextDuvidas, setContextDuvidas] = useState([]);

  const [showDoubtModal, setShowDoubtModal] = useState(false);
  const [showNewDoubtModal, setShowNewDoubtModal] = useState(false);
  const [selectedDoubt, setSelectedDoubt] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [selectedFile, setSelectedFile] = useState(null);

  // const [selectedMessage, setSelectedMessage] = useState(null);

  // useEffect(() => {

  //   if (selectedMessage != null) {
  //     downloadDocument(selectedMessage);
  //   }

  // }, [selectedMessage]);


  const handleShow = (duvida) => {
    formValuesRef.current = duvida;
    setShow(true);
  };

  const AplicaFiltro = (form, firstPage) => {
    localStorage.setItem(
      "filter_ListDoubtsCandidatesManager",
      JSON.stringify(form)
    );
    doubtsListRequest({
      cpfList: form.cpfs,
      idStatusList: form.idStatus,
      idMotivoList: form.idMotivoList,
      firstPage,
    });
    setFormValues(form);
  };

  useEffect(() => {
    if (candidates.candidatos != undefined && candidates.candidatos.length) {
      const dupContextList = candidates.candidatos.map((candidate) => {
        return { ...candidate, selected: false };
      });

      setContextDuvidas(dupContextList);
    }

    let filter_storaged = JSON.parse(
      localStorage.getItem("filter_ListDoubtsCandidatesManager")
    );
    if (filter_storaged != null && !clearFilter) {
      filter_storaged.quantidadeItens = candidates.candidatos
        ? candidates.candidatos.length
        : 20;
      localStorage.setItem(
        "filter_ListDoubtsCandidatesManager",
        JSON.stringify(filter_storaged)
      );
      setFormValues(filter_storaged);
    } else {
      setFormValues({
        cpfs: [],
        idStatus: [],
        idMotivoList: [],
      });
    }

    if (candidates.candidatos == undefined) {
      AplicaFiltro(formValues, true);
    }
  }, [candidates]);

  const Filtrar = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, true);
  };

  const getMotivoEtapaValues = () => {
    if (formValues.idMotivoList) {
      return TiposPrimitivoDeSolicitacao.filter(function (item) {
        return formValues.idMotivoList.indexOf(item.value) !== -1;
      });
    }
    return [];
  };

  const getStatusEtapaValues = () => {
    if (formValues.idStatus) {
      return StatusSolicitacaoCandidato.filter(function (item) {
        return formValues.idStatus.indexOf(item.value) !== -1;
      });
    }

    return [];
  };

  const LimparFiltro = () => {
    setClearFilter(true);
    localStorage.removeItem("filter_ListDoubtsCandidatesManager");
    setFormValues({});
    AplicaFiltro({}, true);
  };

  const TrataMultiInsert = (Insert) => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];
    Insert.map((item) => {
      let conc = item.value.split(/[\s,]+/);
      conc.map((filtItem) => {
        if (filtItem.length == 11) {
          result.push(filtItem);
        } else {
          messages.push({
            message: `motivo ${filtItem} com menos de 11 digitos. Não será considerado no filtro.`,
            type: "warning",
          });
        }
      });
    });
    messagePublicationRequest(messages);
    return result;
  };

  const requestPage = (filter) => {
    doubtsListRequest({ NumeroPagina: filter.NumeroPagina });
  };

  const renderTableItems = (duvidasList) =>
    duvidasList.map((duvida) => (
      <TableRow>
        <TableColumn>
          <span class="dot" style={RowColorStage(duvida.statusDuvida)} />
        </TableColumn>
        <TableColumn ta="center">
          <Label>{duvida.id}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>
            {
              StatusSolicitacaoCandidato.find(
                (motivo) => motivo.value == duvida.statusDuvida
              ).label
            }
          </Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{duvida.respostaAdm[0]?.remetente}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{duvida.dataCriacao}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{duvida.motivoCompleto}</Label>
        </TableColumn>
        <TableColumn ta="right" action>
          <Link onClick={() => handleShow(duvida)}>
            <Actions flexPosition="flex-start">
              <ActionItem>
                {view()}
                <LabelAction>
                  <FormattedMessage id="sharedItems.view" />
                </LabelAction>
              </ActionItem>
            </Actions>
          </Link>
        </TableColumn>
      </TableRow>
    ));

  const renderCandidatesTable = () => {
    return (
      <>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead>
                <LabelHeader>{""}</LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.id" />
                </LabelHeader>
              </TableHead>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.status" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.nomeCandidato" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.dataDoChamado" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.motivoDoContato" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>{""}</LabelHeader>
              </TableHead>
            </TableRow>
            {renderTableItems(duvidas)}
          </TableBody>
        </Table>
        <Pagination getItemsPage={requestPage} pageSize={20} {...pagination} />
      </>
    );
  };

  const handleMotivoChange = (selectedOption) => {
    setFormValues({
      ...formValues,
      motivoContato: selectedOption.value,
      tipoPrimitivoDeSolicitacao: selectedOption.value,
      fluxoContato: "",
      selectedOption: selectedOption,
    });
  };

  const handleFluxoChange = (selectedOption) => {
    setFormValues((prevState) => ({
      ...prevState,
      fluxoContato: selectedOption,
    }));
  };

  const hiddenFileInput = React.createRef();

  const handleNewDoubtSubmit = (event) => {
    event.preventDefault();

    if (formValues.selectedOption == null && formValues.mensagem == "") {
      window.alert(
        "Os valores de Motivo Contato e Mensagem não podem ser nulos ou vazios."
      );
    } else {
      const dataToSubmit = {
        tipoPrimitivoDeSolicitacao: formValues.selectedOption,
        fluxoContato: formValues.fluxoContato,
        mensagem: formValues.mensagem,
        arquivo: selectedFile,
      };

      doubtsCreateManagerRequest(dataToSubmit);
      setFormValues({});
      setSelectedFile(null);
      setShowNewDoubtModal(false);
    }
  };

  const handleSendMessage = (event) => {
    event.preventDefault();

    const payload = {
      respostaAdm: formValues.mensagem,
      solicitante: formValuesRef.current.administrador_Solicitante_Id,
      id: formValuesRef.current.id,
      arquivo: selectedFile,
    };

    doubtsAnswersRequest(payload);
    setSelectedFile(null);
    setFormValues({ mensagem: "" });

    setShow(false);
  };

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      mensagem: e.target.value,
    });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={<FormattedMessage id="Selecione os chamados gestor" />}
      />
      <Body>
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ display: "flex", justifyContent: "flex-start" }}>
            <Col md={4}>
              <MultSelectCreatable
                key={5}
                name={
                  <FormattedMessage id="documentsCandidateList.filterCpf" />
                }
                label={
                  <FormattedMessage id="documentsCandidateList.filterCpf" />
                }
                isMulti
                options={formValues.cpfs}
                value={
                  formValues.cpfs
                    ? formValues.cpfs.map((e) => ({
                        label: e,
                        value: e,
                      }))
                    : []
                }
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    cpfs: e ? TrataMultiInsert(e) : [],
                  })
                }
              />
            </Col>
            <Col md={4}>
              <MultSelect
                name={<FormattedMessage id="contracts.motivoDoContato" />}
                options={TiposPrimitivoDeSolicitacao}
                label={<FormattedMessage id="contracts.motivoDoContato" />}
                isMulti
                value={getMotivoEtapaValues()}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    idMotivoList: e
                      ? e.map((k) => {
                          return k.value;
                        })
                      : [],
                  })
                }
              />
            </Col>
            <Col md={4}>
              <MultSelect
                name={<FormattedMessage id="contracts.status" />}
                options={StatusSolicitacaoCandidato}
                label={<FormattedMessage id="contracts.status" />}
                isMulti
                value={getStatusEtapaValues()}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    idStatus: e
                      ? e.map((k) => {
                          return k.value;
                        })
                      : [],
                  })
                }
              />
            </Col>
          </Col>

          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md={3} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={() => Filtrar()}
                name={<FormattedMessage id="sharedItems.filter" />}
              />
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={() => LimparFiltro()}
                name={<FormattedMessage id="sharedItems.clearFilter" />}
              />
            </Col>
            <Col md={5} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={() => setShowNewDoubtModal(true)}
                name={<FormattedMessage id="sharedItems.createSolicitation" />}
              />
            </Col>
          </Col>
        </Row>

        {duvidas && renderCandidatesTable(duvidas)}
      </Body>

      <Modal show={show} onHide={() => handleClose()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Chat da Solicitação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Exibe o motivo e o assunto da dúvida */}
          <div
            style={{
              marginBottom: "10px",
              padding: "10px",
              borderBottom: "1px solid #ddd",
            }}
          >
            <p>
              <strong>Motivo: </strong>
              {formValuesRef.current.motivo || "N/A"}
            </p>
            <p>
              <strong>Assunto: </strong>
              {formValuesRef.current.descricao || "N/A"}
            </p>
          </div>

          {/* Caixa de mensagens */}
          <div
            className="chat-box"
            style={{
              maxHeight: "350px",
              overflowY: "auto",
              border: "1px solid #ddd",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            {formValuesRef.current.respostaAdm?.length > 0 ? (
              formValuesRef.current.respostaAdm.map((message, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: message.perfilRemetente === 1 ? "right" : "left",
                    lineHeight: "2rem",
                    marginBottom: "3px",
                    borderRadius: "5px",
                    background: "#f0e9f7",
                    padding: "7px",
                  }}
                >
                  <strong
                    style={{
                      color:
                        message.perfilRemetente === 1
                          ? theme.colors.darkGray
                          : theme.colors.colorLabelDefault,
                    }}
                  >
                    {message.perfilRemetente === 1 ? "Você" : message.remetente}
                    :
                  </strong>

                  {message.texto}

                  {message.caminhoArquivo && (
                    <div>
                      <div
                        onClick={() => {
                          downloadDocument(message);
                        }}
                        style={{
                          fontWeight: "600",
                          color: theme.colors.defaultColor,
                          cursor: "pointer",
                        }}
                      >
                        {message.nomeArquivo}
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>Nenhuma mensagem encontrada.</p>
            )}
          </div>

          {/* Campo para nova mensagem e upload de arquivos */}
          <Form>
            <Form.Group controlId="newMessage">
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Digite sua mensagem..."
                  value={formValues.mensagem || ""}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>

              <br />

              <section
                className="botoesGerenciarSolicitacao"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "50px",
                }}
              >
                <Button
                  type="button"
                  variant="primary"
                  className="mt-3"
                  onClick={handleSendMessage}
                >
                  {"Enviar"}
                </Button>

                <section
                  className="sendDocument"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Form.Label>Anexar Arquivo</Form.Label>

                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => hiddenFileInput.current.click()}
                    className="mt-2"
                  >
                    {selectedFile ? selectedFile.name : "Escolher Arquivo"}
                  </Button>
                </section>
              </section>
            </Form.Group>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Fechar
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={showNewDoubtModal}
        onHide={() => setShowNewDoubtModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Criar Solicitação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewDoubtSubmit}>
            <Form.Group controlId="motivoContato">
              <Form.Label>Motivo contato</Form.Label>{" "}
              <SelectInput
                isDisabled={false}
                mb="1"
                onChange={handleMotivoChange}
                value={TiposPrimitivoDeSolicitacao.find(
                  (e) => e.value === formValues.tipoPrimitivoDeSolicitacao
                )}
                options={TiposPrimitivoDeSolicitacao}
                placeholder="Selecione a natureza do contato"
                style={{ width: "100%" }}
              />
            </Form.Group>

            {formValues.tipoPrimitivoDeSolicitacao &&
              formValues.tipoPrimitivoDeSolicitacao !== 7 && (
                <Form.Group controlId="fluxoContato" className="mt-3">
                  <Form.Label>Assunto</Form.Label> <br />
                  <SelectInput
                    isDisabled={false}
                    onChange={handleFluxoChange}
                    value={formValues.fluxoContato}
                    options={
                      formValues.tipoPrimitivoDeSolicitacao === 1
                        ? FluxoValeTransporte
                        : formValues.tipoPrimitivoDeSolicitacao === 2
                        ? FluxoValeRefeicao
                        : formValues.tipoPrimitivoDeSolicitacao === 3
                        ? FluxoPlanoDeSaude
                        : formValues.tipoPrimitivoDeSolicitacao === 4
                        ? FluxoContraCheque
                        : formValues.tipoPrimitivoDeSolicitacao === 5
                        ? FluxoFerias
                        : formValues.tipoPrimitivoDeSolicitacao === 6
                        ? FluxoDemissao
                        : []
                    }
                    placeholder="Selecione uma opção"
                  />
                </Form.Group>
              )}

            <Form.Group controlId="mensagem" className="mt-3">
              <Form.Label>Mensagem</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Digite sua mensagem aqui"
                value={formValues.mensagem}
                onChange={(e) =>
                  setFormValues({ ...formValues, mensagem: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="doubtFile" className="mt-3">
              <section
                className="sendDocument"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <ButtonDefault
                  mt={"30px"}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={handleNewDoubtSubmit}
                  name={"Enviar"}
                  style={{ width: "30%" }}
                />

                <section
                  className="conteiner_envio_documento"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Form.Label>Anexar Arquivo</Form.Label>

                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => hiddenFileInput.current.click()}
                    className="mt-2"
                  >
                    {selectedFile ? selectedFile.name : "Escolher Arquivo"}
                  </Button>
                </section>
              </section>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doubtsCreateManagerRequest: (doubts) =>
    dispatch(doubtsAction.doubtsCreateManager(doubts)),

  doubtsListRequest: (payload) =>
    dispatch(doubtsAction.doubtsAnswersManagerRequest(payload)),

  doubtsAnswersRequest: (payload) =>
    dispatch(doubtsAction.doubtsAnswersRequest(payload)),

  messagePublicationRequest: (messages) =>
    dispatch(messageAction.messagePublication(messages)),

  downloadDocument: (payload) =>
    dispatch(doubtsAction.downloadDocumentsSolicitationFilesRequest(payload)),
});

const mapStateToProps = (state) => ({
  isLoading: state.doubts.isLoading,
  duvidas: state.doubts.duvidas,
  placesOfWork: state.formInputs.placeOfWork,
  pagination: state.doubts.pagination,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDoubtsCandidatesManager);

ListDoubtsCandidatesManager.propTypes = {
  candidates: PropTypes.func,
  hasMore: PropTypes.any,
  quantidadeItens: PropTypes.any,
};

ListDoubtsCandidatesManager.defaultProps = {
  candidates: () => {},
};
