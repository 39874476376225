import styled from 'styled-components';
import theme from '../../shared/Theme';

export const Title = styled.span`
  font-family: Arial;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.defaultColor};
`;

export const Disclaimer = styled.p`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.colors.darkGray}
`;

export const RowText = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const RowActions = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const QuestionCard = styled.div`
  border-radius: 3px;
  border: solid 1px ${theme.colors.ligthGray};
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 99%;
`;

export const QuestionTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.defaultColor};
`;

export const BoxQuestion = styled.div`
  max-height: 150px;
  overflow-y: auto;
`;

export const QuestionText = styled.div`
  display: flex;
  margin: 1.2em 0em 0em 0em;
  word-break: break-all;
  width: 100%;
`;