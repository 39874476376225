import axios from "axios";
import * as urlsApi from "../contants/urlsApi";




const ApiViaCEP = axios.create({

  baseURL: urlsApi.SEARCH_ADDRESS_BY_ZIP_CODE,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json; charset=UTF-8'
  }
});


export default ApiViaCEP;
