import { Body, Label } from "./SchedulesStyle";
import { ButtonSecondary, CheckBox, TextField } from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import { LabelAction } from "components/shared/CustomComponentsStyle";
import { cancel } from "../../../assets/icons/icons";
import { formatSchedule } from "../../../helpers/sharedHelpers";

const Schedule = ({ addSchudule, formSchedules, formValues, setFormValues }) => {
  const [schedules, setSchedules] = useState([
    {
      start: "",
      end: "",
      numberOfVacancy: 0,
    }
  ]);

  useEffect(() => {
    if (formSchedules && formSchedules !== schedules) {
      setSchedulesAndFormValues(formSchedules);
    }
  }, []);

  const setSchedulesAndFormValues = (newSchedules) => {
    setSchedules(newSchedules);
    addSchudule(newSchedules);
  }

  const addTimeRange = e => {
    const newSchedules = [...schedules];
    const newSchedule = {
      start: "",
      end: "",
      numberOfVacancy: 0
    };

    newSchedules.push(newSchedule);
    setSchedulesAndFormValues(newSchedules)
  };

  const deleteTimeRange = index => {
    const actualSchedules = [...schedules];
    actualSchedules.splice(index, 1);
    setSchedulesAndFormValues(actualSchedules)
  };

  const updateTime = (value, index, isStart) => {
    const actualSchedules = [...schedules];
    if (actualSchedules[index] && isStart) {
      actualSchedules[index].start = formatSchedule(value);
    } else if (actualSchedules[index] && !isStart) {
      actualSchedules[index].end = formatSchedule(value);
    }
    setSchedulesAndFormValues(actualSchedules)
  };

  const updateNumberOfVacancy = (value, index) => {
    const actualSchedules = [...schedules];
    if (actualSchedules[index]) {
      actualSchedules[index].numberOfVacancy = Number(value);
    }
    setSchedulesAndFormValues(actualSchedules)
  };

  return (
    <Body>
      <hr />
      {schedules.map((item, index) => (
        <Row key={index} className="d-flex align-items-center">
          <Col lg={2} md={3} sm={2}>
            <LabelAction>
              <FormattedMessage id="schedule.startHour" />
            </LabelAction>
            <InputMask
              width="100px"
              mask="99:99"
              value={item.start}
              onChange={e => updateTime(e.target.value, index, true)}
            >
              {inputProps => <TextField {...inputProps} />}
            </InputMask>
          </Col>
          <Col lg={2} md={3} sm={2}>
            <LabelAction>
              <FormattedMessage id="schedule.endHour" />
            </LabelAction>
            <InputMask
              width="100px"
              mask="99:99"
              value={item.end}
              onChange={e => updateTime(e.target.value, index, false)}
            >
              {inputProps => <TextField {...inputProps} />}
            </InputMask>
          </Col>
          <Col lg={3} md={3} sm={2}>
            <LabelAction>
              <FormattedMessage id="schedule.amountOfVacancies" />
            </LabelAction>
            <TextField
              type="number"
              value={item.numberOfVacancy}
              onChange={e => updateNumberOfVacancy(e.target.value, index)}
              min={0}
            />
          </Col>
          <Col onClick={() => deleteTimeRange(index)} sm={1}>
            {index !== 0 && cancel()}
          </Col>
          <Col>
            {index === schedules.length - 1 ? (
              <ButtonSecondary
                width="250px"
                onClick={() => addTimeRange()}
                name={<FormattedMessage id="schedule.addTimer" />}
              />
            ) : null}
          </Col>
        </Row>
      ))}
      <hr />
      <Row>
        <Col>
          <CheckBox
            type="checkbox"
            checked={formValues.informeSchedules}
            label={<FormattedMessage id="inputs.informeSchedules" />}
            onChange={() =>
              setFormValues({
                ...formValues,
                informeSchedules: !formValues.informeSchedules
              })
            }
          />
        </Col>
      </Row>
    </Body>
  );
};
export default Schedule;
