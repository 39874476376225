import React from 'react';
import {
  RowOptions, Disclaimer, Options, OptionBox, OptionsSpan,
} from './SelectionStyle';
import {
  graficoIcon, avatarIcon, medalIcon, arrowIcon,
} from '../../../assets/icons/icons';

export default function Selection(props) {
  const { openDialog } = props;
  const { stageId } = props;
  return (
    <RowOptions stageId={stageId}>
      <Disclaimer>
        Crie o formulário de Dados Cadastrais utilizando os Blocos de Perguntas abaixo. Clique em um
        dos Blocos de Pergunta para adicioná-lo à sua etapa:
      </Disclaimer>
      <Options>
        <OptionBox onClick={() => openDialog('aboutYou')}>
          {avatarIcon()}
          <OptionsSpan>Sobre você</OptionsSpan>
        </OptionBox>
        <OptionBox onClick={() => openDialog('carrier')}>
          {graficoIcon()}
          <OptionsSpan>Carreira</OptionsSpan>
        </OptionBox>
        <OptionBox onClick={() => openDialog('formation')}>
          {medalIcon()}
          <OptionsSpan>Formação</OptionsSpan>
        </OptionBox>
        <OptionBox onClick={() => openDialog('goal')}>
          {arrowIcon()}
          <OptionsSpan>Objetivos</OptionsSpan>
        </OptionBox>
      </Options>
    </RowOptions>
  );
}
