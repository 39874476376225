import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Vacancy from 'components/Vacancy';
import { Creators as vacancyAction } from 'store/ducks/vacancy';

class VacancyShow extends PureComponent {
  render() {
    const { vacancy, showVaga } = this.props;
    return <Vacancy editVaga={showVaga} vacancy={vacancy} />;
  }
}

const mapDispatchToProps = dispatch => ({
  showVaga: id => dispatch(vacancyAction.jobShowRequest(id, true)),
});
const mapStateToProps = state => ({
  vacancy: state.vaga,
});

VacancyShow.propTypes = {
  id: PropTypes.number,
};

VacancyShow.defaultProps = {
  id: 0,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VacancyShow);
