import styled from 'styled-components';
import theme from 'components/shared/Theme';


export const RowMultAnswer = styled.div`
    display:flex;
    width: 100%;
    align-items:center;
    margin-top: 10px;
`;

export const BoxIcon = styled.div`
    margin-left: 5px`;

export const BoxSwitch = styled.div`
    display: flex;
    align-items:center;
    
`;

export const Span = styled.div`
font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.colors.darkGray};
  margin-left: 5px;
`;
