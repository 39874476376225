import styled from 'styled-components';
import theme from "../../../../components/shared/Theme";

export const MainFrameDash = styled.div`
    width: 100%;
    font-size: 18px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap:10px;
    column-gap: 10px;

    @media screen and (max-width: 767px){
        grid-template-columns: 1fr;
    }

     .area-section{
        grid-column: 1 / span 2;
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        row-gap:15px;
        column-gap: 15px;

        @media screen and (max-width: 767px){
            grid-column: 1;
            grid-template-columns: 1fr;
        }

        .filter{
            display: flex;
            aling-items: center;
            font-size: 12px;
            background-color: #f5f4f436;
            border-radius: 5px;
            margin: 5px;
            box-sizing: border-box;
            padding: 10px;
            text-align: center;
            grid-column: 1 / span 2;

            &:empty{
                margin: 0;
                background-color: transparent;
                padding: 0;
            }

            .filter-title{
                display:flex;
                box-sizing: border-box;
                padding: 5px 10px;
                padding-left: 0;
                font-size: 1.2em;
            }

            .button-filter{
                font-size: 1em;
                cursor: pointer;
                box-sizing: border-box;
                padding: 5px 10px;

                &.active{
                    text-decoration: underline;
                }
            }

            .button-filter + .button-filter{
                border-left: 1px solid #eee;
                
            }
        }

    }
`;

export const NotificationArea = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;

    grid-column: 1 / span 2;

    @media screen and (max-width: 767px){
        grid-column: 1;
        grid-template-columns: 1fr;
    }

    &:not(div){
        padding: 0;
        margin: 0;
    }

    > div{
        width: 100%;
        box-sizing: border-box;
        padding: 10px 10px;
        color: #FFF;
        font-weight: 100;
        font-size: 0.8em;
        display: grid;
        align-items: center;
        grid-template-columns: 60px 1fr auto;
        border-radius: 5px;
        animation: show linear 0.5s 1;

        strong{
            font-weight: 600;
        }

        @keyframes show{
            0%{
                opacity: 0;
                transform: translateY(-5px);
            }

            100%{
                opacity: 1;
                transform: translateY(0px);
            }
        }

        .button-notification{
            display: flex;
            box-sizing: border-box;
            padding: 5px 0px;
            font-weight: 600;
            cursor: pointer;
            font-size: 0.8em;
            position: relative;

            &::before{
                content:'';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                transition: all ease 600ms;
                background-color: #FFF;
                transform-origin: center center;
            }
    
            &:hover{
                &::before{
                    width: 100%;
                    transition: all ease 300ms 200ms;
                }
    
            }

        }
    }

    .success{
        background-color: rgba(40, 167, 69, 0.1);
        color: var(--success);

        .button-notification{
            &::before{
                background-color: var(--success);
            }
        }
    }

    .warning{
        background-color: rgba(255, 193, 7, 0.1);
        color: var(--warning);

        .button-notification{
            &::before{
                background-color: var(--warning);
            }
        }
    }

    .danger{
        background-color: rgba(220, 53, 69, 0.1);
        color: var(--danger);

        .button-notification{
            &::before{
                background-color: var(--danger);
            }
        }
    }

`;

export const Nav = styled.nav`
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    align-items: cemter;
    justify-content: flex-end;
    width: 100%;
    margin: 15px auto;
    grid-column: 1 / span 2;
`;

export const ButtonNav = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 5px 10px;
    aling-items: center;
    justify-content: center;
    background-color: var(--secondary);
    font-size: 0.7em;
    color: #FFF;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: all ease 200ms 200ms;
    
    + div{
        margin-left: 5px;
    }
    
    &.active{
        background-color: ${theme.colors.defaultColor};
        transition: all ease 600ms;
    }
`;

export const Header = styled.header`
    font-size: 1.5em;
    color: ${theme.colors.defaultColor};
    font-weight: 200;
    grid-column: 1 / span 2;
`;

export const CardsArea = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    column-gap: 10px;
    grid-column: 1 / span 2;

    @media screen and (max-width: 767px){
        grid-column: 1;
        grid-template-columns: 1fr;
    }
`;

export const CardData = styled.div`
    box-sizing: border-box;
    padding: 20px 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px #CCC;
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 10px;
    align-items: center;
    justify-content: center;

    .icon{
        grid-row: 1 / span 2;

        > svg{
            display: block;
            width: 100%;
        }
    }

    .value{
        font-size: 1.2em;
    }

    .name{
        font-size: 0.7em;
        color: var(--gray);
    }

    &.success{

        .value{
            color: var(--success);
            font-weight: 600;
        }
    }

    &.warning{

        .value{
            color: var(--warning);
            font-weight: 600;
        }
    }

    &.danger{

        .value{
            color: var(--danger);
            font-weight: 600;
        }
    }

`;

export const GenericBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0 0 5px #CCC;
    border-radius: 5px;

    ul {
        margin: 10px 0;
        padding: 0;
        width: 100%;
        color: var(--cyan);
        font-size: 0.8em;

        li{
            width: 100%;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: 40px 1fr;
            column-gap: 2px;
            row-gap: 2px;
            align-items: center;
            justify-content: center;
            margin: 5px auto;
            padding: 2px 5px;

            &:nth-child(even){
                background-color: rgba(150, 150, 150, .05);
            }

            .icon{
                grid-row: 1 / span 2;
                max-width: 30px;

                > svg{
                    display: block;
                    width: 100%;
                    max-width: 30px;
                    margin: 0 auto;
                }
            }

            .title{
                font-weight: 600;
            }

            .content{
                font-weight: 200;
            }
        }
    }

    .button-more{
        color: var(--cyan);
        text-align: center;
        cursor: pointer;
        margin: 5px auto;
        font-weight: 800;
        position: relative;
        font-size: 0.8em;

        &::before{
            content:'';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            transition: all ease 600ms;
            background-color: var(--cyan);
            transform-origin: center center;
        }

        &:hover{
            &::before{
                width: 100%;
                transition: all ease 300ms 200ms;
            }

        }


    }
    
`;