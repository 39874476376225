import * as routes from "utils/contants/urls";

import {
  ButtonSecondary,
  CheckBox,
  SelectFieldInput,
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import { Filter, LabelH1, ToolTip } from "components/shared/CustomComponents";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import {
  StatusVacancy,
  candidatesActions,
  candidatesApproved,
  candidatesRefused,
  kindStages,
  pendingCandidateAction,
  renderNumberOfSelected,
  schedulingCandidateAction,
} from "locale";
import { iconDoor, order, userGreenIcon } from "assets/icons/icons";

import BreadCrumb from "components/Layout/breadcrumb";
import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PdcIcon from "assets/icons/iconpcd.svg";
import PropTypes from "prop-types";
import Tab from "components/Tab";
import { connect } from "react-redux";
import { getStageStatusName } from "helpers/sharedHelpers";
import { Creators as vacancyCandidatesActions } from "store/ducks/candidates";
import NewCandidateDialog from "../../../../../components/Candidates/NewCandidateDialog";
import {
  BoxInfo,
  ButtonContainer,
  ContantImg,
  IconPcd,
  ImagAvatar,
  InfoVacancy,
  RowActionsTable,
  RowHeaderTable,
  SelectRow,
  SelectsContainer,
  SpanDivisor,
  VacancyOpensSpan,
  VacancySpan,
} from "./ListCandidatesStyle";

export const ListCandidates = ({
  getVacanciesFromCandidates,
  isLoading,
  candidates,
  vacancy,
  match,
  requestManagerCandidates,
  filterItems,
  requestExportCandidates,
  requestAddCandidate,
  hasMore,
  page,
}) => {
  const [candidatesList, setCandidatesList] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedStage, setSelectedStage] = useState(0);
  const [selectedStageType, setSelectedStageType] = useState(0);
  const [statusVacancy, setStatusVacancy] = useState(0);
  const [filterOptions, setFiltersOptions] = useState({});
  const [showAddCandidate, setShowAddCandidate] = useState(false);

  const [optionsTab, setOptionsTab] = useState([
    candidatesActions,
    candidatesRefused,
    candidatesApproved,
  ]);

  useEffect(() => {
    const { params } = match;
    let selectedStageId_storaged = JSON.parse(
      localStorage.getItem("selectedStageId_ListCandidates")
    );

    if (selectedStageId_storaged != null) {
      setSelectedStage(selectedStageId_storaged);
      getVacanciesFromCandidates({
        vacancyId: params.id,
        stageId: selectedStageId_storaged,
        page: 1,
        firstPage: true,
      });
    } else {
      getVacanciesFromCandidates({
        vacancyId: params.id,
        page: 1,
        firstPage: true,
      });
    }
  }, []);

  useEffect(() => {
    if (
      vacancy &&
      vacancy.stages &&
      vacancy.selectedStageFilter != "99999999"
    ) {
      setSelectedStage(vacancy.selectedStageFilter.id);
      getSelectStageType(vacancy.selectedStageFilter.id);
    }
  }, [vacancy]);

  useEffect(() => {
    setCandidatesList(candidates);
  }, [candidates]);

  const closeDialog = () => {
    setShowAddCandidate(false);
  };

  const registerSelectedItems = (id) => {
    const updateCandidatesList = candidatesList.map((candidate) => {
      const newCandidateValue = candidate;
      if (newCandidateValue.id === id) {
        newCandidateValue.selected = !newCandidateValue.selected;
      }
      return newCandidateValue;
    });
    setCandidatesList(updateCandidatesList);
  };

  const getSelectStageType = (selectedStageId) => {
    const ANALYZE = kindStages[3].id;
    const MEDIC_EXAM = kindStages[4].id;
    const PRE_ADMISSION = kindStages[8].id;
    const newOptions = [...optionsTab];
    const actions = [...candidatesActions];
    const stages = vacancy.stages.filter((e) => e.id == selectedStageId);
    if (stages && stages.length) {
      const stageType = stages[0].type;
      if (stageType == PRE_ADMISSION) {
        actions.push(pendingCandidateAction);
      } else if (stageType == ANALYZE || stageType == MEDIC_EXAM) {
        actions.push(schedulingCandidateAction);
      }
      newOptions[0] = actions;
      setOptionsTab(newOptions);
      setSelectedStageType(stageType);
    }
  };

  const onSelectStage = (e) => {
    const selectedStageId = e.target.value;
    getSelectStageType(selectedStageId);
    setSelectedStage(selectedStageId);
    localStorage.setItem(
      "selectedStageId_ListCandidates",
      JSON.stringify(selectedStageId)
    );
    if (selectedStageId == "99999999") {
      requestFilterItems({
        selectedStage: selectedStageId,
        kindCandidate: StatusVacancy[1].id,
        page: 1,
        firstPage: true,
      });
    } else {
      requestFilterItems({
        selectedStage: selectedStageId,
        page: 1,
        firstPage: true,
      });
    }
  };

  const dispatchActionToManagerCandidates = (kindOfAction) => {
    const { params } = match;
    requestManagerCandidates(
      candidates.filter((e) => e.selected),
      kindOfAction,
      params.id,
      selectedStage,
      selectedStageType
    );
  };

  const dispatchActionToExportCandidates = () => {
    const { params } = match;
    requestExportCandidates(
      candidates.filter((e) => e.selected),
      params.id
    );
  };

  const dispatchActionToAddCandidate = (cpf, justification, matricula) => {
    const { params } = match;
    const candidate = {
      cpf: cpf,
      justification: justification,
      matricula: matricula,
    };
    requestAddCandidate(candidate, params.id);
    closeDialog();
  };

  const showNewCandidates = () => {
    setShowAddCandidate(true);
  };

  const selectAllItems = () => {
    const updateCandidatesList = candidatesList.map((candidate) => {
      const newCandidateValue = candidate;
      newCandidateValue.selected = !selectedAll;
      return newCandidateValue;
    });
    setCandidatesList(updateCandidatesList);
    setSelectedAll(!selectedAll);
  };

  const requestFilterItems = (filters) => {
    const { params } = match;
    const filterParams = {
      vacancyId: params.id,
      ...filterOptions,
      kindCandidate:
        typeof filters.kindCandidate == "undefined"
          ? statusVacancy
          : filters.kindCandidate,
      stageId:
        typeof filters.selectedStage != "undefined"
          ? filters.selectedStage
          : selectedStage,
      schedules:
        typeof filters.schedules == "undefined" ? false : filters.schedules,
      firstPage:
        typeof filters.firstPage == "undefined" ? false : filters.firstPage,
      page: typeof filters.page == "undefined" ? page : filters.page,
    };

    getVacanciesFromCandidates(filterParams);
  };

  const loadMore = () => {
    requestFilterItems({ firstPage: false, page });
  };

  const renderTableItems = (vancancies) =>
    vancancies.map((candidate, index) => (
      <TableRow key={index}>
        <TableColumn style={{ width: "12%" }}>
          <Row style={{ margin: "0px" }}>
            <Col style={{ padding: "0px" }} xs={4} sm={4} md={4} lg={4} xl={4}>
              <CheckBox
                checked={candidate.selected}
                onChange={() => registerSelectedItems(candidate.id)}
              />
            </Col>
            <Col style={{ padding: "0px" }} xs={5} sm={5} md={5} lg={5} xl={5}>
              <ContantImg>
                <ImagAvatar src={candidate.urlAvatar} />
              </ContantImg>
            </Col>
            {candidate.pcd && (
              <Col
                style={{ padding: "0px" }}
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
              >
                <IconPcd src={PdcIcon} />
              </Col>
            )}
          </Row>
        </TableColumn>
        <TableColumn ta="center">
          {/*  <Link
            to={`${routes.SHOW_CANDIDATE}/${candidate.id}?vaga=${vancancies[index].id}`}
          > */}
          <Label>{candidate.code}</Label>
          {/* </Link>*/}
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.name}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.currentStage}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label
            color={candidate.resultStatus !== 2 ? "softGreen" : "errorColor"}
          >
            {candidate.result}
          </Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.applyedAt}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{getStageStatusName(candidate.status)}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.cpf}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.enrollment}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.scheduleTime}</Label>
        </TableColumn>
        {tabSelected === 4 && (
          <>
            <TableColumn ta="left">
              <Label>{candidate.locality}</Label>
            </TableColumn>
            <TableColumn ta="left">
              <Label>{candidate.note}</Label>
            </TableColumn>
          </>
        )}
      </TableRow>
    ));

  if (!vacancy) return <Load isLoading={true} />;

  return (
    <React.Fragment>
      <RowHeaderTable>
        <BreadCrumb />
      </RowHeaderTable>
      <RowHeaderTable>
        <InfoVacancy>
          <LabelH1>{vacancy.name}</LabelH1>
          <BoxInfo>
            <SpanDivisor>|</SpanDivisor>
            <VacancySpan data-for="tooltip-candidates" data-tip="data-tip">
              {vacancy.allNumberOfCandidates}
            </VacancySpan>
            {userGreenIcon()}
            <VacancyOpensSpan
              data-for="tooltip-openVagas"
              data-tip="data-tip"
              style={{ marginLeft: "15px" }}
            >
              {vacancy.vacancies}
            </VacancyOpensSpan>
            {iconDoor()}
            <ToolTip
              id="tooltip-candidates"
              message={<FormattedMessage id="sharedItems.applyedCandidates" />}
            />
            <ToolTip
              id="tooltip-openVagas"
              message={<FormattedMessage id="sharedItems.openVagas" />}
            />
          </BoxInfo>
        </InfoVacancy>
      </RowHeaderTable>
      <SelectRow>
        <SelectsContainer
          style={
            tabSelected === 2 || tabSelected === 3 ? { display: "none" } : null
          }
        >
          <SelectFieldInput
            onChange={(e) => onSelectStage(e)}
            skipDefaultOption={false}
            value={selectedStage}
            items={vacancy.stages}
            width="300px"
            label={<FormattedMessage id={"sharedItems.selectStage"} />}
          />
        </SelectsContainer>
        <SelectsContainer
          style={tabSelected === 3 ? { display: "none" } : null}
        >
          <SelectFieldInput
            disabled={candidatesList.filter((e) => e.selected).length === 0}
            onChange={(e) => dispatchActionToManagerCandidates(e.target.value)}
            items={
              selectedStage == "99999999" && tabSelected === 0
                ? optionsTab[2]
                : optionsTab[0]
            }
            width="240px"
            label={<FormattedMessage id={"sharedItems.selectAction"} />}
          />
        </SelectsContainer>
        <ButtonContainer>
          <ButtonSecondary
            disabled={candidatesList.filter((e) => e.selected).length === 0}
            onClick={() => dispatchActionToExportCandidates()}
            width="130px"
            name={<FormattedMessage id={"sharedItems.exportCandidate"} />}
          />
        </ButtonContainer>
        <ButtonContainer>
          <ButtonSecondary
            onClick={() => showNewCandidates()}
            name={<FormattedMessage id={"Adicionar candidato"} />}
          />
        </ButtonContainer>
        <NewCandidateDialog
          confirmation={<FormattedMessage id="sharedItems.removeQuestion" />}
          question={<FormattedMessage id="sharedItems.removeQuestionTitle" />}
          show={showAddCandidate}
          closeDialog={closeDialog}
          action={dispatchActionToAddCandidate}
        />
      </SelectRow>
      <Tab
        registerTableSelect={(e) => setTabSelected(e)}
        columns={[
          {
            name: <FormattedMessage id="vacancyShow.statusInProgress" />,
            value: vacancy.candidatesInProgress,
            position: 0,
            action: () => {
              if (selectedStage === "99999999") {
                requestFilterItems({
                  kindCandidate: StatusVacancy[1].id,
                  page: 1,
                  firstPage: true,
                });
                setStatusVacancy(StatusVacancy[0].id);
              } else {
                requestFilterItems({
                  kindCandidate: StatusVacancy[0].id,
                  page: 1,
                  firstPage: true,
                });
                setStatusVacancy(StatusVacancy[0].id);
              }
            },
            focus: tabSelected === 0,
            filter: { campo: "status", valor: "0", NumeroPagina: 1 },
          },
          {
            name: <FormattedMessage id="vacancyShow.hired" />,
            focus: tabSelected === 2,
            action: () => {
              requestFilterItems({
                kindCandidate: StatusVacancy[3].id,
                page: 1,
                firstPage: true,
              });
              setStatusVacancy(StatusVacancy[3].id);
            },
            position: 2,
            value: vacancy.approvedCandidates,
            filter: { campo: "status", valor: "4", NumeroPagina: 1 },
          },
          {
            name: <FormattedMessage id="vacancyShow.declined" />,
            focus: tabSelected === 1,
            action: () => {
              requestFilterItems({
                kindCandidate: StatusVacancy[2].id,
                page: 1,
                firstPage: true,
              });
              setStatusVacancy(StatusVacancy[2].id);
            },
            position: 1,
            value: vacancy.disapproved,
            filter: { campo: "status", valor: "1", NumeroPagina: 1 },
          },
          {
            name: <FormattedMessage id="vacancyShow.inBankOfTalent" />,
            focus: tabSelected === 3,
            action: () => {
              requestFilterItems({
                kindCandidate: StatusVacancy[4].id,
                inBankOfTalent: true,
                page: 1,
                firstPage: true,
              });
              setStatusVacancy(StatusVacancy[4].id);
            },
            position: 3,
            value: vacancy.bankOfTalentsCandidates,
            filter: { campo: "status", valor: "5", NumeroPagina: 1 },
          },
          {
            name: <FormattedMessage id="vacancyShow.removed" />,
            focus: tabSelected === 5,
            action: () => {
              requestFilterItems({
                kindCandidate: StatusVacancy[5].id,
                page: 1,
                firstPage: true,
              });
              setStatusVacancy(StatusVacancy[5].id);
            },
            position: 5,
            value: vacancy.totalCandidatesRemovedVacancy,
            filter: { campo: "status", valor: "1", NumeroPagina: 1 },
          },
        ]}
      >
        <RowActionsTable>
          <CheckBox
            checked={
              candidatesList.filter((e) => e.selected === true).length ===
                candidatesList.length && candidatesList.length > 0
            }
            onChange={() => selectAllItems()}
            label={renderNumberOfSelected(
              candidatesList.filter((e) => e.selected).length
            )}
          />
          <SpanDivisor>|</SpanDivisor>
          <Filter
            filterItems={filterItems}
            onFilter={(filters) =>
              requestFilterItems({ ...filters, page: 1, firstPage: true })
            }
            kindFilter="candidates"
            numberOfItems={vacancy.candidatesNumber}
            passFilterToFather={(e) => setFiltersOptions(e)}
          />
        </RowActionsTable>
        {candidatesList.isLoading ? (
          <Load isLoading={candidatesList.isLoading} />
        ) : (
          <Table>
            <TableBody>
              <TableRow noHover noBorder>
                <TableHead />
                <TableHead>
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.code" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                <TableHead ta="left">
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.name" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                <TableHead ta="left">
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.current" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                <TableHead ta="left">
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.result" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                <TableHead ta="left">
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.applyAt" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                <TableHead ta="left">
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.status" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                <TableHead ta="left">
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.cpf" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                <TableHead ta="left">
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.enrollment" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                <TableHead ta="left">
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.scheduleTime" />
                  </LabelHeader>
                  {order()}
                </TableHead>
                {tabSelected === 4 && (
                  <>
                    <TableHead ta="left">
                      <LabelHeader>
                        <FormattedMessage id="vacancyList.columns.locality" />
                      </LabelHeader>
                      {order()}
                    </TableHead>
                    <TableHead ta="left">
                      <LabelHeader>
                        <FormattedMessage id="vacancyList.columns.note" />
                      </LabelHeader>
                      {order()}
                    </TableHead>
                  </>
                )}
              </TableRow>
              {isLoading ? (
                <Load isLoading={isLoading} />
              ) : (
                renderTableItems(candidatesList,selectedStageType)
              )}
            </TableBody>
          </Table>
        )}
      </Tab>

      <ButtonSecondary
        disabled={!hasMore}
        onClick={loadMore}
        isLoading={isLoading}
        name={<FormattedMessage id="sharedItems.loadMore" />}
      />
    </React.Fragment>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getVacanciesFromCandidates: (payload) =>
    dispatch(vacancyCandidatesActions.candidatesFromRequest(payload)),
  requestManagerCandidates: (
    candidates,
    kindOfAction,
    vancancyId,
    stageId,
    stageType
  ) =>
    dispatch(
      vacancyCandidatesActions.candidatesManagerRequest(
        candidates,
        kindOfAction,
        vancancyId,
        stageId,
        stageType
      )
    ),
  requestExportCandidates: (candidates, vacancyId) =>
    dispatch(
      vacancyCandidatesActions.candidatesExportRequest(candidates, vacancyId)
    ),
  requestAddCandidate: (candidate, vacancyId) =>
    dispatch(
      vacancyCandidatesActions.candidatesAddCandidateRequest(
        candidate,
        vacancyId
      )
    ),
});
const mapStateToProps = (state) => ({
  vacancy: state.candidates.vacancy,
  candidates: state.candidates.candidates,
  hasMore: state.candidates.hasMore,
  page: state.candidates.page,
  isLoading: state.candidates.isLoading,
  pagination: state.candidates.pagination,
  location: state.router.location,
});
export default connect(mapStateToProps, mapDispatchToProps)(ListCandidates);

ListCandidates.defaultProps = {
  getVacanciesFromCandidates: PropTypes.func,
  isLoading: PropTypes.bool,
  candidates: PropTypes.arrayOf({}),
  vacancy: PropTypes.arrayOf({}),
  hasMore: PropTypes.bool,
  match: PropTypes.objectOf({}),
  requestManagerCandidates: PropTypes.func,
  page: PropTypes.any,
  rail: [],
};

ListCandidates.defaultProps = {
  getVacanciesFromCandidates: () => {},
  requestManagerCandidates: () => {},
  isLoading: false,
  candidates: [],
  vacancy: [],
  match: {},
};
