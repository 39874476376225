import styled from "styled-components";
import theme from "../../shared/Theme";

export const Body = styled.div`
  border-radius: 16px;
  border: solid 1px ${theme.colors.borderRadius};
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const LabelInfo = styled.span`
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.darkGray};
  margin-right: 5px;
`;
export const LabelItem = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  margin-right: 5px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.softGray};
`;

export const List = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  grid-template-rows: 51% 50%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 0fr);
  grid-template-rows: 1fr;
  grid-row-gap: 0.4em;
`;

export const LabelActualRail = styled.span`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  margin-right: 5px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.colors.titleColor};
`;
