import styled from 'styled-components';
import theme from '../../../../../components/shared/Theme';

export const Body = styled.div`
  border-radius: 8px;
  padding: 20px;

  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 50px;
`;

export const LabelSpan = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const RequiredSpan = styled.span`
  color: ${theme.colors.errorColor};
  font-size: 17px;
  line-height: 1.54;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-left: 5px;
  ::before {
    content: '*'
  }
  
`;

export const SubmitRow = styled.div`
  padding-left: 10px;
`;

export const FlexAction = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Span = styled.span`
  ${props => props.propStyle ? props.propStyle : null }
`;

export const RequiredFieldsDiv = styled.div`
  display: ${props => props.required? 'flex': 'none'};
  color: red;
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  ::before {
    content: '* Campos Obrigatórios';
  }
`;

export const FixedAction = styled.div`
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #ffffff 51%,
    #ffffff
  );
  color: white;
  text-align: center;
  margin: 30px 0px;
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    padding-right: 0
  }
`;

export const H1 = styled.h1`
  font-family: Arial;
  font-size: ${props => (props.size ? props.size : "2.5rem")};
  font-weight: ${props => (props.weight ? props.weight : 500)};
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${props =>
    props.color };
  
  @media screen and (max-width: ${theme.size.mobileL}) {
    font-size: 1em !important;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
