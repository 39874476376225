import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  stageRequest: ["listKind", "page"],
  stageSuccess: ["stages", "pagination", "isLoading"],
  stageFail: ["isLoading", "error"],

  stageShowRequest: ["payload"],
  stageShowSuccess: ["stage", "isLoading"],
  stageShowFail: ["isLoading", "error"],

  stageCreateRequest: ["payload"],
  stageCreateSuccess: ["id", "isLoading"],
  stageCreateFail: ["isLoading", "error"],

  stageInfosRequest: ["formKind"],
  stageInfosSuccess: ["options", "isLoading"],
  stageInfosFail: ["isLoading", "error"],

  stageUpdateRequest: ["payload"],
  stageUpdateSuccess: ["isLoading"],
  stageUpdateFail: ["isLoading", "error"],

  stageDeleteRequest: ["payload"],
  stageDeleteSuccess: ["isLoading"],
  stageDeleteFail: ["isLoading", "error"],

  stageByRailsFilterRequest: ["payload"],
  stageByRailsFilterSuccess: ["isLoading"],
  stageByRailsFilterFail: ["isLoading", "error"],

  stagePublishRequest: ["id"],
  stagePublishSuccess: ["isLoading"],
  stagePublishFail: ["isLoading", "error"],

  stageDeleteQuestionBlockRequest: ["payload"],
  stageDeleteQuestionBlockSuccess: [""],
  stageDeleteQuestionBlockFail: ["error"],

  requestCleanStageState: [""]
});

export const INITIAL_STATE = {
  stages: [],
  isLoading: false,
  stage: {
    stageId: undefined,
    name: "",
    description: "",
    positions: [],
    kind: null,
    avaliation: null,
    testLevel: null,
    testTime: "00:00:00",
    optionSelected: null,
    testQuestions: [],
    aboutYou: [],
    carrier: [],
    formation: [],
    goal: [],
  }
};

const request = state => ({ ...state, isLoading: true });
const success = (state, stages) => ({
  ...state,
  stages: stages.stages,
  pagination: stages.pagination,
  isLoading: false
});
const fail = (state, error) => ({ ...INITIAL_STATE, isLoading: false, error });

const requestCreateStage = state => ({ ...state, isLoading: true });
const successCreateStage = (state, stageId) => ({
  ...state,
  stageId: stageId.id,
  isLoading: false
});
const failCreateStage = (state, error) => ({
  ...INITIAL_STATE,
  isLoading: false,
  error
});

const requestInfos = state => ({ ...state, isLoading: true });
const successInfos = (state, options) => ({
  ...state,
  ...options,
  isLoading: false
});
const failInfos = (state, error) => ({ ...state, isLoading: false, error });

const requestShow = state => ({ ...state, options: state.options, isLoading: true });
const successShow = (state, stage) => ({
  ...state,
  ...stage,
  isLoading: false
});
const failShow = (state, error) => ({ ...state, isLoading: false, error });

const requestUpdate = state => ({ ...state, isLoading: true });
const successUpdate = (state, stage) => ({
  ...state,
  ...stage,
  isLoading: false
});
const failUpdate = (state, error) => ({ ...state, isLoading: false, error });

const requestDelete = state => ({ ...INITIAL_STATE, isLoading: true });
const successDelete = (state, stage) => ({
  ...state,
  ...stage,
  isLoading: false
});
const failDelete = (state, error) => ({ ...state, isLoading: false, error });

const requestStageByRailsFilter = state => ({
  ...state,
  isLoading: true
});
const successStageByRailsFilter = (state, stage) => ({
  ...state,
  ...stage,
  isLoading: false
});
const failStageByRailsFilter = (state, error) => ({
  ...state,
  isLoading: false,
  error
});

const stagePublishRequest = state => ({ ...state, isLoading: true });
const stagePublishSuccess = (state, stage) => ({
  ...state,
  ...stage,
  isLoading: false
});
const stagePublishFail = (state, error) => ({
  ...state,
  isLoading: false,
  error
});

const stageDeleteQuestionBlockRequest = state => ({ ...state, isLoading: true });
const stageDeleteQuestionBlockSuccess = (state) => ({
  ...state,
  isLoading: false
});
const stageDeleteQuestionBlockFail = (state, error) => ({
  ...state,
  isLoading: false,
  error
});

const requestCleanStageState = () => ({
  ...INITIAL_STATE,
  isLoading: false
})

export default createReducer(INITIAL_STATE, {
  [Types.STAGE_REQUEST]: request,
  [Types.STAGE_SUCCESS]: success,
  [Types.STAGE_FAIL]: fail,

  [Types.STAGE_CREATE_REQUEST]: requestCreateStage,
  [Types.STAGE_CREATE_SUCCESS]: successCreateStage,
  [Types.STAGE_CREATE_FAIL]: failCreateStage,

  [Types.STAGE_INFOS_REQUEST]: requestInfos,
  [Types.STAGE_INFOS_SUCCESS]: successInfos,
  [Types.STAGE_INFOS_FAIL]: failInfos,

  [Types.STAGE_SHOW_REQUEST]: requestShow,
  [Types.STAGE_SHOW_SUCCESS]: successShow,
  [Types.STAGE_SHOW_FAIL]: failShow,

  [Types.STAGE_UPDATE_REQUEST]: requestUpdate,
  [Types.STAGE_UPDATE_SUCCESS]: successUpdate,
  [Types.STAGE_UPDATE_FAIL]: failUpdate,

  [Types.STAGE_DELETE_REQUEST]: requestDelete,
  [Types.STAGE_DELETE_SUCCESS]: successDelete,
  [Types.STAGE_DELETE_FAIL]: failDelete,

  [Types.STAGE_BY_RAILS_FILTER_REQUEST]: requestStageByRailsFilter,
  [Types.STAGE_BY_RAILS_FILTER_SUCCESS]: successStageByRailsFilter,
  [Types.STAGE_BY_RAILS_FILTER_FAIL]: failStageByRailsFilter,

  [Types.STAGE_PUBLISH_REQUEST]: stagePublishRequest,
  [Types.STAGE_PUBLISH_SUCCESS]: stagePublishSuccess,
  [Types.STAGE_PUBLISH_FAIL]: stagePublishFail,

  [Types.STAGE_DELETE_QUESTION_BLOCK_REQUEST]: stageDeleteQuestionBlockRequest,
  [Types.STAGE_DELETE_QUESTION_BLOCK_SUCCESS]: stageDeleteQuestionBlockSuccess,
  [Types.STAGE_DELETE_QUESTION_BLOCK_FAIL]: stageDeleteQuestionBlockFail,

  [Types.REQUEST_CLEAN_STAGE_STATE]: requestCleanStageState
});
