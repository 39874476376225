import {
  BoxQuestion,
  QuestionCard,
  QuestionText,
  QuestionTitle,
  RowActions,
  RowText,
  Title
} from "./DialogStyle";
import { ButtonDefault, ButtonSecondary } from "components/shared/CustomInputs";
import { Col, Modal, Row } from "react-bootstrap";
import React, { PureComponent } from "react";
import { deleteItem, view } from "../../../assets/icons/icons";

import { FormattedMessage } from 'react-intl'
import RichTextEditor from "react-rte";

export default class Dialog extends PureComponent {
  state = {
    value: RichTextEditor.createEmptyValue(),
    text: "",
    questions: {
      aboutYou: [],
      carrier: [],
      formation: [],
      goal: []
    },
    editing: false,
    index: 0
  };

  onChange = value => {
    this.setState({ value, text: value.toString("html") });
  };

  componentDidMount() {
    this.setState({ questions: this.props.questions });
  }

  componentDidUpdate() {
    this.setState({ questions: this.props.questions });
  }

  getKindId() {
    let kind = 0;
    switch (this.props.dialogKind) {
      case "aboutYou":
        kind = 0;
        break;
      case "carrier":
        kind = 1;
        break;
      case "formation":
        kind = 2;
        break;
      case "goal":
        kind = 3;
        break;
      default:
    }
    return kind;
  }

  registerQuestionsAndClose = () => {
    const kind = this.getKindId();
    this.props.requestCreateQuestion({
      description: this.state.text,
      kind,
      stageId: this.props.stageId,
      kindAnsewr: 4
    });
  };

  registerQuestion = () => {
    if (this.state.text && !this.state.editing) {
      const { questions } = this.state;
      questions[this.props.dialogKind].push(this.state.text);
      this.setState({ value: RichTextEditor.createEmptyValue(), questions });
      this.registerQuestionsAndClose();
    } else if (this.state.editing && this.state.text) {
      const questionsUpdate = this.state.questions;
      questionsUpdate[this.props.dialogKind][
        this.state.index
      ].question = this.state.text;
      const { requestUpdateQuestion, stageId } = this.props;
      const kind = this.getKindId();
      requestUpdateQuestion({
        id: questionsUpdate[this.props.dialogKind][this.state.index].id,
        testId: stageId,
        question: this.state.text,
        kindAnsewr: 4,
        kindBlock: kind
      });
      this.setState({
        value: RichTextEditor.createValueFromString("", "html"),
        editing: false,
        index: 0,
        questions: questionsUpdate
      });
    }
  };

  renderTitle = kind => {
    switch (kind) {
      case "aboutYou":
        return "Sobre Você";
      case "carrier":
        return "Carreira";
      case "formation":
        return "Formação";
      case "goal":
        return "Objetivos";
      default:
        return "";
    }
  };

  updateQuestion = (value, index) => {
    if (typeof value === "object") {
      this.setState({
        value: RichTextEditor.createValueFromString(value.question, "html"),
        editing: true,
        index
      });
    } else {
      this.setState({
        value: RichTextEditor.createValueFromString(value, "html"),
        editing: true,
        index
      });
    }
  };

  deleteQuestion(index) {
    const questionsUpdate = this.state.questions;
    this.props.requestDeleteQuestion(
      questionsUpdate[this.props.dialogKind][index].id,
      null
    );
    questionsUpdate[this.props.dialogKind].splice(index, 1);
    this.setState({ questions: questionsUpdate, editing: false, text: "" });
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Row style={{ width: "100%" }}>
            <Col lg={{ span: 9 }}>
              <Title>{this.renderTitle(this.props.dialogKind)}</Title>
            </Col>
            <Col lg={3}>
              <ButtonSecondary
                onClick={() => this.props.closeDialog()}
                name={<FormattedMessage id="sharedItems.close" />}
              />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <p>
            Este Bloco contem perguntas de carater pessoal e informativo. Busca
            saber dados pessoais e conhecer um pouco sobre a pessoa, suas
            habilidades, aspirações, conhecimentos, qualidades e defeitos.
          </p>
          <RowText className="row">
            <Col lg={{ span: 12 }}>
              <RichTextEditor
                editorStyle={{ height: "100px" }}
                onChange={this.onChange}
                value={this.state.value}
                setEd
              />
              <RowActions className="row">
                <Col lg={{ span: 3, offset: 3 }}>
                  <ButtonSecondary
                    onClick={() =>
                      this.setState({
                        value: RichTextEditor.createEmptyValue()
                      })
                    }
                    name={<FormattedMessage id="sharedItems.clearUper" />}
                  />
                </Col>
                <Col lg={{ span: 3 }}>
                  <ButtonDefault
                    onClick={() => this.registerQuestion()}
                    name={<FormattedMessage id="sharedItems.save" />}
                  />
                </Col>
              </RowActions>
            </Col>
          </RowText>
          <BoxQuestion>
            <RowText>
              {this.state.questions[this.props.dialogKind].map((k, index) => {
                return (
                  <QuestionCard key={index}>
                    <QuestionTitle>
                      <Row>
                        <Col lg={{ span: 7 }}>
                          <span>Pergunta {index + 1} </span>
                        </Col>
                        <Col
                          style={{ display: "flex" }}
                          className={"justify-content-md-end"}
                        >
                          <ButtonSecondary
                            onClick={e => this.updateQuestion(k, index)}
                            name={<FormattedMessage id="sharedItems.edit" />}
                          />
                        </Col>
                        <Col
                          style={{ display: "flex" }}
                          className={"justify-content-md-end"}
                        >
                          <ButtonSecondary
                            onClick={e => this.deleteQuestion(index)}
                            name={<FormattedMessage id="sharedItems.remove" />}
                          />
                        </Col>
                      </Row>
                    </QuestionTitle>
                    {
                      <QuestionText
                        dangerouslySetInnerHTML={{ __html: k.question || k }}
                      ></QuestionText>
                    }
                  </QuestionCard>
                );
              })}
            </RowText>
          </BoxQuestion>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  }
}
