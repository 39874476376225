import {
  ButtonDefault,
  ButtonSecondary,
  SelectFieldInput,
  TextAreaField,
  TextField
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { Body } from "./StageDefaultStyle";
import BreadCrumb from "components/Layout/breadcrumb";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { required } from "../../../helpers/validations";

const StageDefault = ({
  nameForm,
  requestCreateStage,
  options,
  stage,
  publishStage,
  kind,
  updateStage,
  showJobAssociation,
  location,
  goBackPreviousPage,
  canEdit
}) => {
  const [isEditing, setEditing] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErros, setFormErros] = useState([]);
  const [formValidators, setFormValidators] = useState();
  const [submited, setSubmited] = useState(false);
  const saveForm = () => {
    setSubmited(true);
    if (Object.keys(formErros).length === 0) {
      if (stage.stageId) {
        updateStage({
          ...formValues,
          formKind: kind,
          hasRedirect: { to: "rails", id: stage.hasRedirect }
        });
      } else {
        requestCreateStage({
          name: formValues.name,
          description: formValues.description,
          kind: formValues.kind,
          formKind: kind
        });
      }
    }
  };

  useEffect(() => {
    if (stage) {
      setFormValues(stage);
      setEditing(!isEditing);
    }
  }, [stage]);

  return (
    <React.Fragment>
      <Row style={{ marginTop: "0px" }}>
        <Col lg={7}>
          <BreadCrumb location={location} title={nameForm} />
        </Col>
        <Col lg={{ span: 3 }}>
          <ButtonSecondary
            onClick={() => showJobAssociation()}
            disabled={stage.stageId === undefined || !canEdit()}
            width="100%"
            name={<FormattedMessage id="newStageAboutYou.associationJob" />}
          />
        </Col>
        <Col lg={{ span: 2 }}>
          <ButtonDefault
          disabled = {!canEdit()}
            onClick={() => saveForm()}
            name={<FormattedMessage id={"sharedItems.save"} />}
          />
        </Col>
      </Row>
      <Body>
        <Row>
          <Col lg={8}>
            <TextField
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              validations={[required]}
              value={formValues.name}
              name={<FormattedMessage id="newStageAboutYou.associationJob" />}
              onChange={e =>
                setFormValues({ ...formValues, name: e.target.value })
              }
              label={<FormattedMessage id="sharedItems.name" />}
            />
          </Col>
          {kind !== 7 && kind !== 5 && kind !== 8 && (
            <Col lg={4}>
              <SelectFieldInput
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                formErr={formErros}
                touched={submited}
                validations={[required]}
                items={options}
                label={<FormattedMessage id="sharedItems.kind" />}
                name={<FormattedMessage id="newStageAboutYou.associationJob" />}
                onChange={e =>
                  setFormValues({ ...formValues, kind: e.target.value })
                }
                value={formValues.kind || formValues.optionSelected}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12}>
            <TextAreaField
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              touched={submited}
              onChange={e =>
                setFormValues({ ...formValues, description: e.target.value })
              }
              value={formValues.description}
              validations={[required]}
              label={<FormattedMessage id="sharedItems.description" />}
              name={<FormattedMessage id="newStageAboutYou.associationJob" />}
            />
          </Col>
        </Row>
      </Body>
    </React.Fragment>
  );
};
StageDefault.propTypes = {
  requestStagesPositions: PropTypes.func,
  nameForm: PropTypes.string,
  requestCreateStage: PropTypes.func
};
StageDefault.defaultProps = {
  requestStagesPositions: () => {},
  requestCreateStage: () => {},
  nameForm: ""
};
export default StageDefault;
