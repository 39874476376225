import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  formInputsRequest: ["payload"],
  formInputsSuccess: ["inputs", "isLoading"],
  formInputsFail: ["isLoading", "error"],

  formInputsCountriesRequest: ["payload"],
  formInputsCountriesSuccess: ["values", "isLoading"],
  formInputsCountriesFail: ["isLoading", "error"],

  formInputsAffiliateRequest: ["idAffiliate"],
  formInputsAffiliateSuccess: ["inputs", "isLoading"],
  formInputsAffiliateFail: ["isLoading", "error"],

  formInputsCenterSectionRequest: ["idCenter"],
  formInputsCenterSectionSuccess: ["inputs", "isLoading"],
  formInputsCenterSectionFail: ["isLoading", "error"],

  formInputsSearchByNameRequest: ["name"],
  formInputsSearchByNameSuccess: ["inputs", "isLoading"],
  formInputsSearchByNameFail: ["isLoading", "error"],


  formInputsRequestJobFromArea: ["idArea"],
  formInputsRequestJobFromAreaSuccess: ["inputs", "isLoading"],
  formInputsRequestJobFromAreaFail: ["isLoading", "error"],

  performaceIndicatiorRequest: ["payload"],
  performaceIndicatiorSuccess: ["payload", "isLoading"],
  performaceIndicatiorFail: ["isLoading", "error"],

  formCitiesRequest: ["payload"],
  formCitiesSuccess: ["cities", "state", "isLoading"],
  formCitiesFail: ["isLoading", "error"],

  formCitiesAddressRequest: ["payload"],
  formCitiesAddressSuccess: ["addressCities"],
  formCitiesAddressFail: ["isLoading", "error"],

  formStatesRequest: ["payload"],
  formStatesSuccess: ["payload"],
  formStatesFail: ["error", "isLoading"],

  formStatesAddressRequest: ["payload"],
  formStatesAddressSuccess: ["payload"],
  formStatesAddressFail: ["error", "isLoading"],

  formWorkplaceRequest: [],
  formWorkplaceSuccess: ['payload', 'isLoading'],
  formWorkplaceFail: ['error', 'isLoading'],

  searchAddressByZipCodeRequest: ["payload"],
  searchAddressByZipCodeSuccess: ["payload", "isLoading"],
  searchAddressByZipCodeFail: ["isLoading", "error"],
});
const INITIAL_STATE = {
  stages: [],
  isLoading: false,
  areaFormation: [],
  jobs: [],
  affiliate: [],
  jorney: [],
  tecnicKnowledge: [],
  city: [],
  escolaridade: [],
  level: [],
  area: [],
  placeOfWork: [],
  managerName:[],
  managers:[],
  eligibleJobs: [],
  benefits: [],
  competence: [],
  banners: [],
  schooling:[],
  indicatorsperformaceIndicatior: [],
  vacancyGroup:[],
  administrator:[],
  workplaces: [],
  issuingStateRG: [],
  countries: [],
  states: [],
  cities: [],
  addressStates: [],
  addressCities: [],
};

const request = state => ({ ...state, isLoading: true });
const success = (state, inputs) => ({ ...state, ...inputs, isLoading: false });
const fail = (state, error) => ({ ...state, isLoading: false, error });

const requestResultCenterByAffiliate = state => ({
  ...state,
  resultCenter: [],
  isLoading: true
});
const successResultCenterByAffiliate = (state, inputs) => ({
  ...state,
  ...inputs,
  isLoading: false
});
const failResultCenterByAffiliate = (state, error) => ({
  ...state,
  isLoading: false,
  error
});
const formInputsCountriesRequest = () => ({ ...INITIAL_STATE, isLoading: true });
const formInputsCountriesSuccess = (state, values) => ({
  ...state,
  ...values,
  isLoading: false
});
const formInputsCountriesFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});
const requestCenterSection = state => ({ ...INITIAL_STATE, isLoading: true });
const successCenterSection = (state, inputs) => ({
  ...state,
  ...inputs,
  isLoading: false
});
const failACenterSection = (state, error) => ({
  ...state,
  isLoading: false,
  error
});

const formInputsRequestJobFromArea = (state) => ({ ...state, isLoading: true });
const formInputsRequestJobFromAreaSuccess = (state, inputs) => {
  const todos = state;
  return {
    ...state,
    ...inputs,
    isLoading: false,
  };
};
const formInputsRequestJobFromAreaFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const formInputsSearchByNameRequest = (state) => ({
  ...state,
  isLoading: true,
});

function formInputsSearchByNameSuccess(state, inputs) {
  const todos = state;
  return {
    ...state,
    ...inputs,
    isLoading: false,
  };
}

const formInputsSearchByNameFail = (state, error) => ({
  ...state,
  isLoading: false,
  error,
});

const performaceIndicatiorRequest = state => ({
  ...state,
  isLoading: true
});
const performaceIndicatiorSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const performaceIndicatiorFail = (state, error) => ({
  ...state,
  isLoading: false,
  error
});

const formCitiesRequest = state => ({ ...state, isLoading: true });
const formCitiesSuccess = (state, cities) => ({
  ...state,
  ...cities,
  isLoading: false
});
const formCitiesFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const formCitiesAddressRequest = state => ({ ...state, isLoading: true });
const formCitiesAddressSuccess = (state, addressCities) => ({
  ...state,
  ...addressCities,
  isLoading: false
});
const formCitiesAddressFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const formStatesRequest = state => ({ ...state, isLoading: true });

const formStatesSuccess = (state, payload) => ({
  ...state,
  states: payload.states,
  isLoading: false
});
const formStatesFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const formStatesAddressRequest = state => ({ ...state, isLoading: true });
const formStatesAddressSuccess = (state, payload) => ({
  ...state,
  addressStates: payload.states,
  isLoading: false
});
const formStatesAddressFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const formWorkplaceRequest = (state) => ({
  ...state,
  isLoading: true,
})

const formWorkplaceSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
})

const formWorkplaceFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
})

const searchAddressByZipCodeRequest = state => ({
  ...state,
  isLoading: true
});

const searchAddressByZipCodeSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const searchAddressByZipCodeFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});

export default createReducer(INITIAL_STATE, {
  [Types.FORM_INPUTS_REQUEST]: request,
  [Types.FORM_INPUTS_SUCCESS]: success,
  [Types.FORM_INPUTS_FAIL]: fail,
  [Types.FORM_INPUTS_AFFILIATE_REQUEST]: requestResultCenterByAffiliate,
  [Types.FORM_INPUTS_AFFILIATE_SUCCESS]: successResultCenterByAffiliate,
  [Types.FORM_INPUTS_AFFILIATE_FAIL]: failResultCenterByAffiliate,
  [Types.FORM_INPUTS_CENTER_SECTION_REQUEST]: requestCenterSection,
  [Types.FORM_INPUTS_CENTER_SECTION_SUCCESS]: successCenterSection,
  [Types.FORM_INPUTS_CENTER_SECTION_FAIL]: failACenterSection,

  [Types.FORM_INPUTS_SEARCH_BY_NAME_REQUEST]: formInputsSearchByNameRequest,
  [Types.FORM_INPUTS_SEARCH_BY_NAME_SUCCESS]: formInputsSearchByNameSuccess,
  [Types.FORM_INPUTS_SEARCH_BY_NAME_FAIL]: formInputsSearchByNameFail,

  [Types.FORM_INPUTS_COUNTRIES_REQUEST]: formInputsCountriesRequest,
  [Types.FORM_INPUTS_COUNTRIES_SUCCESS]: formInputsCountriesSuccess,
  [Types.FORM_INPUTS_COUNTRIES_FAIL]: formInputsCountriesFail,

  [Types.FORM_INPUTS_REQUEST_JOB_FROM_AREA]: formInputsRequestJobFromArea,
  [Types.FORM_INPUTS_REQUEST_JOB_FROM_AREA_SUCCESS]: formInputsRequestJobFromAreaSuccess,
  [Types.FORM_INPUTS_REQUEST_JOB_FROM_AREA_FAIL]: formInputsRequestJobFromAreaFail,

  [Types.PERFORMACE_INDICATIOR_REQUEST]: performaceIndicatiorRequest,
  [Types.PERFORMACE_INDICATIOR_SUCCESS]: performaceIndicatiorSuccess,
  [Types.PERFORMACE_INDICATIOR_FAIL]: performaceIndicatiorFail,

  [Types.FORM_CITIES_REQUEST]: formCitiesRequest,
  [Types.FORM_CITIES_SUCCESS]: formCitiesSuccess,
  [Types.FORM_CITIES_FAIL]: formCitiesFail,

  [Types.FORM_CITIES_ADDRESS_REQUEST]: formCitiesAddressRequest,
  [Types.FORM_CITIES_ADDRESS_SUCCESS]: formCitiesAddressSuccess,
  [Types.FORM_CITIES_ADDRESS_FAIL]: formCitiesAddressFail,

  [Types.FORM_STATES_REQUEST]: formStatesRequest,
  [Types.FORM_STATES_SUCCESS]: formStatesSuccess,
  [Types.FORM_STATES_FAIL]: formStatesFail,

  [Types.FORM_STATES_ADDRESS_REQUEST]: formStatesAddressRequest,
  [Types.FORM_STATES_ADDRESS_SUCCESS]: formStatesAddressSuccess,
  [Types.FORM_STATES_ADDRESS_FAIL]: formStatesAddressFail,

  [Types.FORM_WORKPLACE_REQUEST]: formWorkplaceRequest,
  [Types.FORM_WORKPLACE_SUCCESS]: formWorkplaceSuccess,
  [Types.FORM_WORKPLACE_FAIL]: formWorkplaceFail,

  [Types.SEARCH_ADDRESS_BY_ZIP_CODE_REQUEST]: searchAddressByZipCodeRequest,
  [Types.SEARCH_ADDRESS_BY_ZIP_CODE_SUCCESS]: searchAddressByZipCodeSuccess,
  [Types.SEARCH_ADDRESS_BY_ZIP_CODE_FAIL]: searchAddressByZipCodeFail,
});
