import { createActions, createReducer } from 'reduxsauce'

export const { Creators, Types } = createActions({
    setAlertsRequest: ['messages'],
    setAlertsSuccess: ['alerts'],
    setAlertsFail: ['error'],

    setAlertLocale: ['locale'],

    popAlertsRequest: [],
    popAlertsSuccess: ['alerts'],
    popAlertsFail: ['error'],
});

const INITIAL_STATE = {
    alerts: [],
    alertLocale: 'topRight',
}

const setAlertsRequest = () => ({
    ...INITIAL_STATE,
})

const setAlertsSuccess = (state, alerts) => ({
    ...state,
    ...alerts,
})

const setAlertsFail = (state, error) => ({
    ...state,
    error: error,
    alerts: [],
})

const setAlertLocale = (state, locale) => ({
    ...state,
    alertLocale: locale,
})

const popAlertsRequest = () => ({
    ...INITIAL_STATE,
})

const popAlertsSuccess = (state, alerts) => ({
    ...state,
    ...alerts,
})

const popAlertsFail = (state, error) => ({
    ...state,
    error: error,
    alerts: [],
})

export default createReducer(INITIAL_STATE, {
    [Types.SET_ALERTS_REQUEST]: setAlertsRequest,
    [Types.SET_ALERTS_SUCCESS]: setAlertsSuccess,
    [Types.SET_ALERTS_FAIL]: setAlertsFail, 
    [Types.SET_ALERT_LOCALE]: setAlertLocale, 
    [Types.POP_ALERTS_REQUEST]: popAlertsRequest,
    [Types.POP_ALERTS_SUCCESS]: popAlertsSuccess,
    [Types.POP_ALERTS_FAIL]: popAlertsFail, 
});
