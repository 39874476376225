import { put, takeLatest } from "redux-saga/effects";
import * as routes from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";
import API from "../../utils/API";
import { Types } from "../ducks/courseAndFormation";

function* getEducationLevel() {
  try {
    const response = yield API.get(`${urlsApi.GET_EDUCATION_LEVEL}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
    });
    const educationLevel = response.map(education => {
      return {
        value: education.id,
        label: education.nome
      };
    });
    yield put({
      type: Types.GET_EDUCATION_LEVEL_SUCCESS,
      educationLevel
    });
  } catch (error) {
    yield put({ type: Types.GET_EDUCATION_LEVEL_FAIL, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_EDUCATION_LEVEL, getEducationLevel);
}
