import {
  ButtonDefault,
  ButtonSecondary,
  SelectFieldInput
} from "components/shared/CustomInputs";
import { Col, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { LabelH1 } from "components/shared/CustomComponents";
import PropTypes from "prop-types";
import { kindStages } from "../../../locale/index";

export default function DialogFilter({
  showDialog,
  closeDialog,
  testOptions,
  ambientationOptions,
  assayOptions,
  medicOptions,
  trainnerOptions,
  requestStagesByFilter,
  filter
}) {
  const INITIAL_STATE = { kindStage: -1 };
  const [filterValues, setFilterValues] = useState({ kindStage: -1 });
  const [stageList, setStageList] = useState([]);
  useEffect(() => {
    const newStageList = [...kindStages];
    setStageList(newStageList);
  }, []);
  useEffect(() => {
    filter(filterValues);
  }, [filterValues]);

  const renderTestFilters = () => (
    <Row>
      <Col lg={3}>
        <SelectFieldInput
          items={testOptions.tipoTeste}
          label={<FormattedMessage id="rails.kindTest" />}
          onChange={e =>
            setFilterValues({
              ...filterValues,
              kindTest: parseInt(e.target.value, 10)
            })
          }
        />
      </Col>
      <Col lg={2}>
        <SelectFieldInput
          items={testOptions.nivelTeste}
          label={<FormattedMessage id="rails.level" />}
          onChange={e =>
            setFilterValues({
              ...filterValues,
              level: parseInt(e.target.value, 10)
            })
          }
        />
      </Col>
      <Col lg={3}>
        <SelectFieldInput
          items={testOptions.avaliacao}
          label={<FormattedMessage id="rails.avaliation" />}
          onChange={e =>
            setFilterValues({
              ...filterValues,
              avaliation: parseInt(e.target.value, 10)
            })
          }
        />
      </Col>
      <Col lg={2}>
        <SelectFieldInput
          items={[
            { name: "10", id: 0 },
            { name: "20", id: 1 },
            { name: "30", id: 2 }
          ]}
          label={<FormattedMessage id="rails.numberOfQuestion" />}
          onChange={e =>
            setFilterValues({
              ...filterValues,
              numberOfQuestion: parseInt(e.target.value, 10)
            })
          }
        />
      </Col>
      <Col lg={2}>
        <SelectFieldInput
          items={[
            { name: "1h", id: 0 },
            { name: "2h", id: 1 },
            { name: "3h", id: 2 }
          ]}
          onChange={e =>
            setFilterValues({
              ...filterValues,
              maxTime: parseInt(e.target.value, 10)
            })
          }
          label={<FormattedMessage id="rails.maxTime" />}
        />
      </Col>
    </Row>
  );
  const renderFiltersDefault = ({ label }) => {
    let options;
    switch (label) {
      case "trainnerKind":
        options = trainnerOptions.tipoTreinamento;
        break;
      case "assayKind":
        options = assayOptions.tipoAnalise;
        break;
      case "exameKind":
        options = medicOptions.tipoAnalise;
        break;
      case "ambientationKind":
        options = ambientationOptions.tipoAnalise;
        break;
      default:
        options = [];
    }
    return (
      <Row>
        <Col lg={4}>
          <SelectFieldInput
            onChange={e =>
              setFilterValues({
                ...filterValues,
                optionsStage: parseInt(e.target.value, 10)
              })
            }
            items={options}
            label={<FormattedMessage id={`rails.${label}`} />}
          />
        </Col>
      </Row>
    );
  };
  const switchItemsToRenderFilters = kind => {
    if (kind === 1) return renderTestFilters();
    if (kind === 2) return renderFiltersDefault({ label: "trainnerKind" });
    if (kind === 3) return renderFiltersDefault({ label: "assayKind" });
    if (kind === 4) return renderFiltersDefault({ label: "exameKind" });
    if (kind === 5) return renderFiltersDefault({ label: "ambientationKind" });
  };

  const clearValuesAndCloseDialog = () => {
    setFilterValues(INITIAL_STATE);
    closeDialog(false);
  };

  return (
    <Modal size="lg" show={showDialog}>
      <Modal.Header>
        <Row>
          <Col>
            <LabelH1>
              <FormattedMessage id="rails.searchStage" />
            </LabelH1>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={3}>
            <SelectFieldInput
              value={filterValues.kindStage}
              items={stageList}
              label={<FormattedMessage id="rails.kindStage" />}
              onChange={e =>
                setFilterValues({ kindStage: parseInt(e.target.value, 10) })
              }
            />
          </Col>
        </Row>
        {switchItemsToRenderFilters(filterValues.kindStage)}
        <Row style={{ marginTop: "20px" }}>
          <Col lg={{ span: 3, offset: 6 }}>
            <ButtonSecondary
              onClick={() => clearValuesAndCloseDialog()}
              name={<FormattedMessage id="sharedItems.cancel" />}
            />
          </Col>
          <Col lg={3}>
            <ButtonDefault
              onClick={() => requestStagesByFilter(filterValues)}
              name={<FormattedMessage id="sharedItems.next" />}
            />
          </Col>
        </Row>
      </Modal.Body>
      <hr />
    </Modal>
  );
}
DialogFilter.propTypes = {
  showDialog: PropTypes.arrayOf({ id: 0, name: "" }),
  closeDialog: PropTypes.arrayOf({ id: 0, name: "" }),
  testOptions: PropTypes.arrayOf({ id: 0, name: "" }),
  ambientationOptions: PropTypes.arrayOf({ id: 0, name: "" }),
  assayOptions: PropTypes.arrayOf({ id: 0, name: "" }),
  medicOptions: PropTypes.arrayOf({ id: 0, name: "" }),
  trainnerOptions: PropTypes.arrayOf({ id: 0, name: "" })
};

DialogFilter.defaultProps = {
  showDialog: [],
  closeDialog: [],
  testOptions: [],
  ambientationOptions: [],
  assayOptions: [],
  medicOptions: [],
  trainnerOptions: []
};
