import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  allTypeStreetRequest: ["payload"],
  allTypeStreetRequestSuccess: ["payload", "isLoading"],
  allTypeStreetRequestFail: ["isLoading", "error"]
});

const INITIAL_STATE = {
  isLoading: true,
  optionsTypeStreet: []
};

const allTypeStreetRequest = payload => ({
  ...payload
});
const allTypeStreetRequestSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const allTypeStreetRequestFail = state => ({
  ...state,
  isLoading: false
});



export default createReducer(INITIAL_STATE, {
  [Types.ALL_TYPE_STREET_REQUEST]: allTypeStreetRequest,
  [Types.ALL_TYPE_STREET_REQUEST_SUCCESS]: allTypeStreetRequestSuccess,
  [Types.ALL_TYPE_STREET_REQUEST_FAIL]: allTypeStreetRequestFail
});
