import React from 'react';

import ContractForm from "components/Stage/ContractForm";

import { Creators as stagePosition } from "store/ducks/stagePositions";
import { Creators as stages } from "store/ducks/stages";

export const StageContract = (props) => {
    return (
        <div className="state-contract">
            <ContractForm location={props.location} />
        </div>
    )
}