import React, { useState } from 'react';
import { Modal,Col, Row  } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { LabelH1 } from 'components/shared/CustomComponents';
import {
  Label
} from "components/shared/CustomComponentsStyle";


import {  ButtonDefault, ButtonSecondary,SelectFieldInput,TextField } from 'components/shared/CustomInputs';

import PropTypes from 'prop-types';
import {

  OptionsRows,
  ActionRows,
} from './CandidatesInBankOfTalentsEditStyle';

export default function CandidatesInBankOfTalentsEdit({ show, action, closeDialog, situacao,doAction,dadosCandidato }) {
  const [selectOption, setSelectOption] = useState();
  const [motivo, setMotivo] = useState("");
  const [estagioConfirmacao, setEstagioConfirmacao] = useState(0);


  const Validacao = ()=>{
    if(estagioConfirmacao ===1 || situacao ===1)
    {
      doAction(selectOption,motivo); 
      closeDialog();
    }else{
      setEstagioConfirmacao(1);
    }


  }
  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <LabelH1 ml="15px" fz="24px">
          <FormattedMessage id="vacancyCreate.actionCandidate" />
        </LabelH1>
      </Modal.Header>
      <OptionsRows mt="15px" style={estagioConfirmacao ===1?{display:"none"}:  { marginRight: "30px"}}>
      <SelectFieldInput
          items={situacao ===0? 
            [{ name: "Aprovado ao Cargo", id: 0 },
            { name: "Candidato abdicou do Banco", id: 1 },
            { name: "Outros", id: 2 }]: 
           [{ name: "Retornar candidato para o Banco", id: 3 }]
          }

          onChange={e =>{  setSelectOption(e.target.value);   } }
        />

      </OptionsRows>

      <OptionsRows mt="15px" style={selectOption!=="2" || estagioConfirmacao ===1?{display:"none"}:{    marginRight: "30px"}}>
      <TextField
              name={"Motivo"}
              label={"Motivo"}
              value={motivo}
              type="text"
              onChange={e =>
                setMotivo(e.target.value)
              }
              style={ {width: "100%"}}
            />

   
      </OptionsRows>


      <OptionsRows mt="15px" style={estagioConfirmacao !==1?{display:"none"}:{ }}>
      <Label style={{fontSize: "medium",    fontWeight: "bold"}}>{"Tem Certeza que deseja"+ (selectOption ==="0"?" aprovar ":" remover") +dadosCandidato.nome+"  cpf  "+ dadosCandidato.cpf +"  do Banco de Talentos para a vaga id "+dadosCandidato.idVaga+" ? "} </Label>
   
      </OptionsRows>
      <OptionsRows mt="15px" style={estagioConfirmacao !==1?{display:"none"}:{justifyContent: "center" }}>
      <Label style={{fontSize: "medium"}}>{selectOption ==="0"?"Essa ação não poderá ser revertida e o candidato será informado sobre sua aprovação.":" Essa ação não poderá ser revertida e o candidato será informado do motivo da exclusão."} </Label>
   
      </OptionsRows>

      <ActionRows>
        <ButtonSecondary
          onClick={() => closeDialog()}
          mr
          name={<FormattedMessage id="sharedItems.cancel" />}
          width="180px"
        />
        <ButtonDefault
        disabled ={selectOption===undefined}
          name={<FormattedMessage id="sharedItems.confirme" />}
          width="180px"
          onClick={()=>  Validacao()}
        />
      </ActionRows>
    </Modal>
  );
}
CandidatesInBankOfTalentsEdit.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func,
  closeDialog: PropTypes.func,
};

CandidatesInBankOfTalentsEdit.defaultProps = {
  show: false,
  action: () => {},
  closeDialog: () => {},
};


