import styled from "styled-components";
import theme from "../../../../components/shared/Theme";

export const BoxAvatar = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-right: 32px;
  color: ${theme.colors.defaultColor};
  transition: all 0.2s;
  svg g {
    transition: all 0.2s;
    stroke: ${theme.colors.defaultColor};
  }
  &:hover {
    color: ${theme.colors.defaultColorHover};
    cursor: pointer;
    svg g {
      stroke: ${theme.colors.defaultColorHover};
    }
    img {
      box-shadow: 0px 0px 3px ${theme.colors.defaultColorHover};
    }
  }
`;

export const ImagAvatar = styled.img`
  border-radius: 150px;
  width: 40px;
  border: 1px solid #fff;
  height: 40px;
  box-shadow: 1px 1px 3px #c3c3c3;
  transition: all 0.2s;
`;

export const LabelAvatar = styled.span`
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  margin-left: 10px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 10px;
`;

export const MenuBody = styled.div`
  position: absolute;
  z-index: 9999;
  top: 3.2em;
  a {
    text-decoration: none;
    color: ${theme.colors.defaultColor};
  }
`;

export const MenuItem = styled.div`
  width: 237px;
  height: 50px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: ${theme.colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: ${theme.colors.defaultColor};
  :hover {
    cursor: pointer;
    background-color: ${theme.colors.defaultColor};
    color: ${theme.colors.gray};
  }
`;
