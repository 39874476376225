import React, { useEffect, useState } from "react";

import { Body } from "./LoginStyle";
import FormLogin from "components/Login/FormLogin";
import MessageHandler from "components/MessageHandler";
import NewPassword from "components/Login/NewPassword";
import PropTypes from "prop-types";
import { Creators as authActions } from "store/ducks/auth";
import { connect } from "react-redux";

const AuthLogin = props => {
  const { onRequestAuth } = props;
  const { onRequestForgotAuth } = props;
  const { onRequestRecoveryAuth } = props;
  const { location } = props;
  const { search } = location;
  const { error } = props;
  const [erroMessage, setErroMessage] = useState({ message: "" });
  
  useEffect(() => {
    if (error.errors.length) {
      setErroMessage({ message: error.errors[0], showErro: true });
      setTimeout(() => {
        setErroMessage({ message: "", showErro: false });
      }, 3000);
    }
  }, [error]);

  return (
    <Body>
      {search ? (
        <NewPassword submit={onRequestRecoveryAuth} />
      ) : (
        <FormLogin
          forgotAction={values => onRequestForgotAuth(values)}
          submit={values => onRequestAuth(values)}
        />
      )}
      Versão 1.0.0
      <MessageHandler message={erroMessage.message} />
    </Body>
  );
};

const mapDispatchToProps = dispatch => ({
  onRequestAuth: payload => dispatch(authActions.authRequest(payload)),
  onRequestForgotAuth: payload =>
    dispatch(authActions.authForgotRequest(payload)),
  onRequestRecoveryAuth: payload =>
    dispatch(authActions.authRecoveryRequest(payload))
});
const mapStateToProps = state => ({ error: state.login.errors });
AuthLogin.propTypes = {
  onRequestAuth: PropTypes.func,
  onRequestForgotAuth: PropTypes.func,
  onRequestRecoveryAuth: PropTypes.func,
  location: PropTypes.objectOf({})
};

AuthLogin.defaultProps = {
  onRequestAuth: () => {},
  onRequestForgotAuth: () => {},
  onRequestRecoveryAuth: () => {},
  location: {},
  error: {
    errors: []
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthLogin);
