import * as urlsApi from "../../utils/contants/urlsApi";

import {
  buildDate,
  buildErroHandleItems,
  minutesToSeconds
} from "../../helpers/sharedHelpers";
import { put, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/question";
import { Types as TypesStage } from "../ducks/stages";

function createQuestionRequest(params, id) {
  return API.post(`${urlsApi.questionCreateApi}`, params)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* insertQuestionToStage({ payload }) {
  try {
    const question = {
      descricao: payload.description,
      tipoBlocoPergunta: payload.kind,
      etapaId: payload.stageId,
      tipoResposta: payload.kindAnsewr
    };
    yield createQuestionRequest(question, payload.stageId);
    payload.id = question.etapaId;
    yield put({ type: TypesStage.STAGE_SHOW_REQUEST, payload });
  } catch (error) {
    yield put({ type: Types.AUTH_FAIL, error });
  }
}

function* insertQuestionToTest({ payload }) {
  try {
    const question = {
      descricao: payload.question,
      tipoBlocoPergunta: 1,
      etapaId: parseInt(payload.testId, 10),
      tipoResposta: parseInt(payload.kindAnsewr, 10),
      tempo: minutesToSeconds(payload.time),
      pesoId: parseInt(payload.weigth, 10),
      respostaNumero: parseInt(payload.answerNumber, 10),
      respostaData: buildDate(payload.answerDate),
      respostaObrigatoria: payload.mandatory || payload.answerText,
      opcoesSelecao: payload.answers.map(e => ({
        descricao: e.answer,
        respostaCorreta: e.checked
      }))
    };

    yield createQuestionRequest(question);
    yield put({ type: Types.QUESTION_TEST_CREATE_SUCCESS });
    if (payload && payload.testId) {
      payload.id = payload.testId;
      yield put({ type: TypesStage.STAGE_SHOW_REQUEST, payload });
    }
  } catch (error) {
    console.log(error);
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.QUESTION_TEST_CREATE_FAIL, error });
  }
}

function requestUpdateQuestion(body) {
  return API.put(`${urlsApi.QUESTION_UPDATE}/${body.id}`, body)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* updateQuestion({ payload }) {
  try {
    const question = {
      descricao: payload.question,
      id: payload.id,
      tipoBlocoPergunta: 1,
      etapaId: parseInt(payload.testId, 10),
      tipoResposta: parseInt(payload.kindAnsewr, 10),
      respostaNumero: parseInt(payload.answerNumber, 10),
      respostaData: buildDate(payload.answerDate),
      tempo: minutesToSeconds(payload.time),
      respostaObrigatoria: payload.mandatory,
      pesoId: parseInt(payload.weigth, 10),
      opcoesSelecao: payload.answers
        ? payload.answers
            .map(e => ({
              descricao: e.answer,
              respostaCorreta: e.checked
            }))
            .filter(e => e !== null)
        : []
    };

    yield requestUpdateQuestion(question);
    if (payload && payload.testId) {
      payload.id = payload.testId;
      yield put({ type: TypesStage.STAGE_SHOW_REQUEST, payload });
    }
  } catch (error) {
    console.log(error);
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.QUESTION_TEST_UPDATE_FAIL, error });
  }
}

function* deleteQuestion({ id, stageId }) {
  try {
    yield deleteQuestionRequest(id);
    yield put({ type: Types.QUESTION_TEST_DELETE_REQUEST_SUCCESS });
    if (stageId) {
      const payload = { id: stageId };
      yield put({ type: TypesStage.STAGE_SHOW_REQUEST, payload });
    }
  } catch (error) {
    const errors = buildErroHandleItems(error);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.QUESTION_TEST_DELETE_REQUEST_FAIL, error });
  }
}

function deleteQuestionRequest(id) {
  return API.delete(`${urlsApi.QUESTION_DELETE}/${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

export function* watcherSaga() {
  yield takeLatest(Types.QUESTION_CREATE_REQUEST, insertQuestionToStage);
  yield takeLatest(Types.QUESTION_TEST_CREATE_REQUEST, insertQuestionToTest);
  yield takeLatest(Types.QUESTION_TEST_UPDATE_REQUEST, updateQuestion);
  yield takeLatest(Types.QUESTION_TEST_DELETE_REQUEST, deleteQuestion);
}
