import styled from 'styled-components';
import theme from 'components/shared/Theme';

export const Body = styled.div`
  background-color: ${theme.colors.whiteDefault};
  padding: 25px;
  border: solid 1px ${theme.colors.ligthGray};
  border-radius: 5px;
`;

export const QuestionLabel = styled.span`
  font-size: 24px;
  font-family: Arial;
  color: ${theme.colors.darkGray};
  margin-left: ${props => (props.ml ? '5px' : '')};
`;

export const QuestionLabelDiscleimer = styled.span`
  font-size: 12px;
  font-family: Arial;
  color: ${theme.colors.darkGray};
  margin-left: ${props => (props.ml ? '5px' : '')};
  margin-right: 5px;
`;

export const SwitchSpan = styled.span`
  font-size: 14px;
  font-family: Arial;
  color: ${theme.colors.darkGray};
  margin-left: 10px;
`;

export const ColumnSpan = styled.div`
  display: flex;
  flex-flow: column;
`;

export const RowItems = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.mt};
`;

export const RowBoxAction = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${props => props.mt};
`;

export const ColSwitch = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
`;

export const Box = styled.div`
  padding-top: 10px;
  background-color: ${theme.colors.whiteDefault};
`;
