import React from 'react';
import PropTypes from 'prop-types';
import { Body, Label } from './FormGroupStyle';

const FormGroup = props => (
  <Body>
    <Label>{props.formName}</Label>
    {props.children}
  </Body>
);
FormGroup.propTypes = {
  formName: PropTypes.string,
};

FormGroup.defaultProps = {
  formName: '',
};
export default FormGroup;
