import React, { useEffect, useState } from "react";

import { Body } from "./LoginStyle";
import MessageHandler from "components/MessageHandler";
import PropTypes from "prop-types";
import { Creators as authActions } from "store/ducks/auth";
import { connect } from "react-redux";
import ChangePasswordForm from "../../components/Login/ChangePasswordForm";
import { goBack, push } from "connected-react-router";

const AuthLogin = props => {

  const { onRequestRecoveryAuth } = props;
  const { error } = props;
  const { voltar } = props;
  const [erroMessage, setErroMessage] = useState({ message: "" });
  useEffect(() => {
    if (error.errors.length) {
      setErroMessage({ message: error.errors[0], showErro: true });
      setTimeout(() => {
        setErroMessage({ message: "", showErro: false });
      }, 3000);
    }
  }, [error]);

  return (
    <Body>
      <ChangePasswordForm submit={values => onRequestRecoveryAuth(values)} goBack={() => voltar()} />
      Versão 1.0.0
      <MessageHandler message={erroMessage.message} />
    </Body>
  );
};

const mapDispatchToProps = dispatch => ({
  onRequestRecoveryAuth: payload =>
    dispatch(authActions.authRecoveryRequest(payload)),
  voltar: () => dispatch(goBack()),
});
const mapStateToProps = state => ({ error: state.login.errors });
AuthLogin.propTypes = {
  onRequestRecoveryAuth: PropTypes.func,
};

AuthLogin.defaultProps = {
  onRequestRecoveryAuth: () => { },
  location: {},
  error: {
    errors: []
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthLogin);
