import { createActions, createReducer } from "reduxsauce";

const INITIAL_STATE = {
  isLoading: false,
  answser: null
};

export const { Types, Creators } = createActions({
  initializeTestRequest: ["payload"],
  initializeTestRequestSuccess: ["payload", "isLoading"],
  initializeTestRequestFail: ["error", "isLoading"],

  responseRegistrationData: ["payload"],
  responseRegistrationDataSuccess: ["payload", "isLoading"],
  responseRegistrationDataFail: ["error", "isLoading"],

  finishRegistrationData: ["payload"],
  finishRegistrationDataSuccess: ["payload", "isLoading"],
  finishRegistrationDataFail: ["error", "isLoading"],


  finishStageUpload: ["payload"],
  finishStageUploadSuccess: ["payload", "isLoading"],
  finishStageUploadFail: ["error", "isLoading"],

  changeWatchVideoRequest: ["payload"],
  changeWatchVideoSuccess: ["payload", "isLoading"],
  changeWatchVideoFail: ["error", "isLoading"],

  setUserAnswser: ["payload"]
});

export const initializeTestRequest = state => ({
  ...state,
  isLoading: true
});

export const initializeTestRequestSuccess = (state, payload) => ({
  ...state,
  test: payload,
  isLoading: false
});

export const initializeTestRequestFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});

export const responseRegistrationData = state => ({
  ...state,
  isLoading: true
});

export const responseRegistrationDataSuccess = (state, payload) => ({
  ...state,
  test: payload,
  isLoading: false
});

export const responseRegistrationDataFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});

export const finishRegistrationData = state => ({
  ...state,
  isLoading: true
});

export const finishRegistrationDataSuccess = (state, payload) => ({
  ...state,
  test: payload,
  isLoading: false
});

export const finishRegistrationDataFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});



export const finishStageUpload = state => ({
  ...state,
  isLoading: true
});

export const finishStageUploadSuccess = (state, payload) => ({
  ...state,
  test: payload,
  isLoading: false
});

export const finishStageUploadFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});


export const changeWatchVideoRequest = state => ({
  ...state,
  isLoading: false
});

export const changeWatchVideoSuccess = (state, payload) => ({
  ...state,
  test: payload,
  isLoading: false
});

export const changeWatchVideoFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});



export const setUserAnswser = (state, payload) => ({
  ...state,
  answser: payload.payload
});

export default createReducer(INITIAL_STATE, {
  [Types.INITIALIZE_TEST_REQUEST]: initializeTestRequest,
  [Types.INITIALIZE_TEST_REQUEST_SUCCESS]: initializeTestRequestSuccess,
  [Types.INITIALIZE_TEST_REQUEST_FAIL]: initializeTestRequestFail,

  [Types.RESPONSE_REGISTRATION_DATA]: responseRegistrationData,
  [Types.RESPONSE_REGISTRATION_DATA_SUCCESS]: responseRegistrationDataSuccess,
  [Types.RESPONSE_REGISTRATION_DATA_FAIL]: responseRegistrationDataFail,

  [Types.FINISH_REGISTRATION_DATA]: finishRegistrationData,
  [Types.FINISH_REGISTRATION_DATA_SUCCESS]: finishRegistrationDataSuccess,
  [Types.FINISH_REGISTRATION_DATA_FAIL]: finishRegistrationDataFail,

  [Types.SET_USER_ANSWSER]: setUserAnswser,

  [Types.FINISH_STAGE_UPLOAD]: finishStageUpload,
  [Types.FINISH_STAGE_UPLOAD_SUCCESS]: finishStageUploadSuccess,
  [Types.FINISH_STAGE_UPLOAD_FAIL]: finishStageUploadFail,

  [Types.CHANGE_WATCH_VIDEO_REQUEST]: changeWatchVideoRequest,
  [Types.CHANGE_WATCH_VIDEO_SUCCESS]: changeWatchVideoSuccess,
  [Types.CHANGE_WATCH_VIDEO_FAIL]: changeWatchVideoFail



});
