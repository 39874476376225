import React, { useRef, useEffect } from 'react';

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { months } from '../../../../locale';

import './chartcard.scss';

export const ChartCard = ({data}) => {

    const myAvaliables = useRef();

    Chart.register(ChartDataLabels);

    
    useEffect(()=>{
        
        const config = data.data || {
            type: 'line',
            data: {
                labels: months.map(item => item.label),
                datasets: [{
                    label: 'Item 1',
                    data: months.map(item => Math.round(Math.random() * item.value * 100)),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 0.2)'
                    ],
                    borderWidth: 3
                },
                {
                    label: 'Item 2',
                    data: months.map(item => Math.round(Math.random() * item.value * 100)),
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)'
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 0.2)'
                    ],
                    borderWidth: 3
                }
            ]
            },
            options: {
                aspectRatio: 1,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        }
    
        new Chart(myAvaliables.current.getContext('2d'), config);
    },[data]);

    return (
        <div className="dash-card-chart">
            <div className="box">
                <div className="title" style={{'--title': data?.customStyle?.color}} >
                    {data?.title || 'Título'}
                </div>
                <div className="content" >
                    <canvas ref={myAvaliables} style={{...data?.customStyle}} />
                </div>
            </div>
        </div>
    )
}