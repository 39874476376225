import DefaultStage from "./StageBlocksStages/DefaultStage";
import PropTypes from "prop-types";
import React from "react";
import SimpleBlock from "./StageBlocksStages/SimpleBlock";
import TestStage from "./StageBlocksStages/TestStage";

export default function StageBlocks({
  showDialogYesOrNo,
  stages,
  railsAttributesToAssocation,
  setRailsAttributesToAssocation,
  isAssociating,
  requestShowStage,
  inputs,
  isEditingAssociation,
}) {
  return stages.map((stage, index) => {
    const kind = stage.kind || stage.kindStage;
    switch (kind) {
      case 1:
        return (
          <TestStage
            key={index}
            {...stage}
            railsAttributesToAssocation={railsAttributesToAssocation}
            setRailsAttributesToAssocation={setRailsAttributesToAssocation}
            isAssociating={isAssociating}
            position={index + 1}
            deleteItem={showDialogYesOrNo}
            requestShowStage={requestShowStage}
            inputs={inputs}
            isEditingAssociation={isEditingAssociation}
          />
        );
      case 2:
        return (
          <DefaultStage
            key={index}
            isAssociating={isAssociating}
            railsAttributesToAssocation={railsAttributesToAssocation}
            setRailsAttributesToAssocation={setRailsAttributesToAssocation}
            {...stage}
            position={index + 1}
            deleteItem={showDialogYesOrNo}
            requestShowStage={requestShowStage}
            inputs={inputs}
            isEditingAssociation={isEditingAssociation}
          />
        );
      default:
        return (
          <SimpleBlock
            key={index}
            isAssociating={isAssociating}
            railsAttributesToAssocation={railsAttributesToAssocation}
            setRailsAttributesToAssocation={setRailsAttributesToAssocation}
            {...stage}
            position={index + 1}
            deleteItem={showDialogYesOrNo}
            requestShowStage={requestShowStage}
            inputs={inputs}
            isEditingAssociation={isEditingAssociation}
          />
        );
    }
  });
}
StageBlocks.propTypes = {
  stages: PropTypes.arrayOf({})
};

StageBlocks.defaultProps = {
  stages: []
};
