import styled from 'styled-components';

export const RowAction = styled.div`
  margin-top: 20px;
`;

export const Body = styled.div`
  margin-top: 20px;
`;

export const DeleteButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 2em 0em 0em 0em;
  cursor: pointer;
`;
