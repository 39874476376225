import * as routes from "utils/contants/urls";

import {
  ButtonDefault,
  ButtonSecondary,
  SelectFieldInput
} from "components/shared/CustomInputs";
import { Col, Row } from "react-bootstrap";
import { LabelH1, YesOrNoDialog } from "components/shared/CustomComponents";
import React, { useEffect, useState } from "react";
import { goBack, push } from "connected-react-router";

import { Body } from "./NewRailsStyle";
import BreadCrumb from "components/Layout/breadcrumb";
import DialogFilter from "components/Rails/DialogFilter";
import DialogList from "components/Rails/DialogList";
import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import RailsAssociation from "../RailsAssociation/RailsAssociation";
import StageBlocks from "components/Rails/StageBlocks";
import { connect } from "react-redux";
import { Creators as formInputAction } from "store/ducks/formInputs";
import { Creators as railsAction } from "store/ducks/rails";
import { Creators as stages } from "store/ducks/stages";
import { Creators as vacancyAction } from "store/ducks/vacancy";
import { Creators as vacancyAssociationAction } from "store/ducks/vacancyAssociation";

const NewRails = ({
  requestTestInfos,
  options,
  isLoading,
  requestStagesByRailsFilter,
  rails,
  rail,
  pagination,
  getIputValues,
  inputs,
  requestCreateRails,
  stages,
  requestAddStageToRails,
  match,
  requestShow,
  association,
  requestAssociationAttributesRails,
  requestDeleteStageInRails,
  requestShowStage,
  requestJobsFromArea,
  setIsAssociatingRequest,
  backPreviousPage,
  requestEditRails,
  location,
  requestEditAssociationAttributesRails,
  redirect,
  pageLoading
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogFilter, setShowDialogFilter] = useState(false);
  const [waitingTrailCreation, setWaitingTrailCreation] = useState(false);
  const [stagesToAddInVacancy, setStagesToAddInVacancy] = useState([]);
  const [railsAttributes, setRailsAttributes] = useState({});
  const [showDialogYesOrNo, setShowDialogYesOrNo] = useState(false);
  const [kindStage, setKindOfStage] = useState(0);

  const [filter, setFilter] = useState({});
  const [
    railsAttributesToAssocation,
    setRailsAttributesToAssocation
  ] = useState({});
  const [idToDelete, setIdToDelete] = useState("");

  const handleAddNewStage = () =>{
    if (!rail.id) {
      saveRails(railsAttributes);
      setWaitingTrailCreation(true);
    } else {
      setShowDialog(!showDialog);
    }
  };

  useEffect(() => {
    if (rail.id && waitingTrailCreation) {
      setWaitingTrailCreation(false);
      setShowDialog(!showDialog);
    }
  }, [rail])

  const requestStagesByFilter = filters => {
    setShowDialog(!showDialog);
    setShowDialogFilter(!showDialogFilter);
    requestStagesByRailsFilter(Object.assign(filters, { id: rail.id }));
    setKindOfStage(filters.kindStage);
  };
  const requestStagesByFilterPage = filters => {
    requestStagesByRailsFilter(Object.assign(filters, { id: rail.id }));
    setKindOfStage(filters.kindStage);
  };

  const showDialogAndReturnFilter = () => {
    setShowDialogFilter(false);
    setShowDialog(false);
  };

  const registerIdAndShowDialog = id => {
    setIdToDelete(id);
    setShowDialogYesOrNo(!showDialogYesOrNo);
  };

  const confirmeDeleteItem = () => {
    requestDeleteStageInRails(idToDelete);
    setShowDialogYesOrNo(!showDialogYesOrNo);
  };

  const clearValuesIntialState = () => {
    setStagesToAddInVacancy([]);
    setShowDialogFilter(false);
  };

  const saveRails = values => {
    if (association.isEditing) {
      requestEditAssociationAttributesRails(railsAttributesToAssocation);
    } else if (association.isAssociating) {
      requestAssociationAttributesRails(railsAttributesToAssocation);
    } else if (rail && rail.id) {
      requestEditRails(values);
    } else {
      requestCreateRails(values);
    }
  };

  const insertStagesToRail = () => {
    requestAddStageToRails(stagesToAddInVacancy, rails.id || rail.id);
    setShowDialogFilter(false);
  };

  const deleteStageInRailsItem = () => {
    requestDeleteStageInRails(idToDelete, rail.id);
    const railsAttributes = { ...railsAttributesToAssocation };
    delete railsAttributes[idToDelete];
    setRailsAttributesToAssocation(railsAttributes);
    setShowDialogYesOrNo(!showDialogYesOrNo);
  };

  const requestShowStageFromRails = id => {
    requestShowStage(id, rail.id);
  };

  const jobsFromArea = area => {
    setRailsAttributes({ ...railsAttributes, area });
    requestJobsFromArea(area);
  };

  const [isAssocieting, setisAssocieting] = useState(false);

  useEffect(() => {
    if(isAssocieting){
      let timerId;
      if (isAssocieting) {
        timerId = setTimeout(() => {
          setisAssocieting(false)
        }, 5000);
      }
      return () => clearTimeout(timerId);
    }
    
  }, [isAssocieting]);


  useEffect(() => {
    const { params, path } = match;
    const targetPath = path.slice(0, -4);

    if (
      targetPath == routes.RAILS_CREATE_ASSOCIATION &&
      !association.isAssociating
    ) {
      redirect(routes.VACANCY);
    }

    requestTestInfos([
      "testeInfo",
      "assayInfo",
      "ambientationInfo",
      "medicExameInfo",
      "trainnerInfo"
    ]);
    getIputValues(["area", "placeOfMedicExame"]);

    if (params.id) {
      requestShow(params.id);
    }
    let dataRail = JSON.parse(sessionStorage.getItem("dataFormVacancy"));

    if (dataRail != null) {
      jobsFromArea(dataRail.area);
     }

  }, []);

  useEffect(() => {
    if (rail && rail.area) {

      let dataRail = JSON.parse(sessionStorage.getItem("dataFormVacancy"));
      if(rail.stages?.length>0 && !association?.isEditing){
       setIsAssociatingRequest(true);
      }

      setRailsAttributesToAssocation({
        ...railsAttributesToAssocation,
        idVacancy: dataRail.id,
        idRail: rail.id
      });

      setRailsAttributes({ area: rail.area, job: rail.job, idVacancy: dataRail.id, ...rail });
      requestJobsFromArea(rail.area);
    }
  }, [rail]);


  useEffect(() => {
    if (inputs && inputs.jobs && inputs.jobs.length > 0) {
      let dataRail = JSON.parse(sessionStorage.getItem("dataFormVacancy"));

      if (dataRail != null) {
        setRailsAttributes({ ...railsAttributes,area: dataRail.area,idVacancy:dataRail.id,job: dataRail.job })
      }
    }
  }, [inputs]);



  useEffect(() => {
    if (association) {
      if (association.isEditing) {
        association.stages.forEach(e => {
          railsAttributes[e.id] = { ...e };
        });
      }

      setRailsAttributesToAssocation(railsAttributes);
    }
  }, [association]);

  const cancelAssociating = () => {
    setIsAssociatingRequest(false);
    backPreviousPage();
  };

  const renderLabelMessages = () => {
    if ( association.isAssociating) return "rails.associationVacancy";
    else return "sharedItems.save";
  };

  const renderCancelButton = () => {
    if (association.isAssociating) {
      return (
        <ButtonSecondary
          onClick={() => cancelAssociating()}
          name={<FormattedMessage id="sharedItems.cancel" />}
          width="100%"
          style={{ marginRight: "10px" }}
        />
      );
    }
  };

  const renderSaveButton = () => {
    if (association.isAssociating) {
      return (
        <ButtonDefault
          onClick={() => {
            setisAssocieting(true);
            saveRails(railsAttributes)}
          }  
          disabled={!railsAttributes.job || isAssocieting }
          name={<FormattedMessage id={renderLabelMessages()} />}
        />
      );
    }
  };

  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <Row style={{ marginBottom: "20px" }}>
        <Col lg={7}>
          <BreadCrumb
            isAssociating={true}
            location={location}
            title={<FormattedMessage id={renderLabelMessages()} />}
          />
        </Col>
        <Col lg={2}>{renderCancelButton()}</Col>
        <Col lg={3}>{renderSaveButton()}</Col>
      </Row>
      <Body>
        <Row style={{ alignItems: "center" }}>
          <Col lg={4}>
            <SelectFieldInput
              onChange={e => jobsFromArea(e.target.value)}
              value={railsAttributes.area}
              items={inputs.area}
              label={<FormattedMessage id="inputs.area" />}
              disabled={association.isAssociating}
            />
          </Col>
          <Col lg={4}>
            <SelectFieldInput
              onChange={e =>
                setRailsAttributes({ ...railsAttributes, job: e.target.value })
              }
              disabled={inputs.jobs === undefined}
              value={railsAttributes.job}
              items={inputs.jobs}
              label={<FormattedMessage id="inputs.job" />}
              disabled={association.isAssociating}
            />
          </Col>
          <Col style={{ paddingTop: "10px" }} lg={4}>
            <ButtonSecondary
              width="255px"
              height="40px"
              onClick={() => handleAddNewStage()}
              name={<FormattedMessage id="rails.addStageTorails" />}
            />
          </Col>
        </Row>
        <StageBlocks
          isAssociating={association.isAssociating}
          isEditingAssociation={association.isEditing}
          setRailsAttributesToAssocation={setRailsAttributesToAssocation}
          railsAttributesToAssocation={railsAttributesToAssocation}
          stages={rail.stages}
          inputs={inputs}
          showDialogYesOrNo={registerIdAndShowDialog}
          requestDeleteStageInRails={confirmeDeleteItem}
          requestShowStage={id => requestShowStageFromRails(id)}
        />
      </Body>
      {showDialog && (
        <DialogFilter
          testOptions={options.testeInfo}
          ambientationOptions={options.ambientationInfo}
          assayOptions={options.assayInfo}
          medicOptions={options.medicExameInfo}
          trainnerOptions={options.trainnerInfo}
          showDialog={showDialog}
          closeDialog={setShowDialog}
          requestStagesByFilter={requestStagesByFilter}
          filter={setFilter}
        />
      )}

      <DialogList
        stagesToAddInVacancy={stagesToAddInVacancy}
        setStagesToAddInVacancy={setStagesToAddInVacancy}
        stages={stages.stages}
        showDialog={showDialogFilter}
        showDialogFilter={clearValuesIntialState}
        showDialogAndReturnFilter={showDialogAndReturnFilter}
        closeDialog={setShowDialogFilter}
        pagination={pagination}
        kindStage={kindStage}
        insertStagesToRail={insertStagesToRail}
        requestStagesByFilter={requestStagesByFilterPage}
        filter={filter}
      />
      <YesOrNoDialog
        show={showDialogYesOrNo}
        cancelAction={() => setShowDialogYesOrNo(false)}
        confirmAction={() => deleteStageInRailsItem()}
        question={<FormattedMessage id="newStageAboutYou.dialog.question" />}
      />
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  requestTestInfos: formKind => dispatch(stages.stageInfosRequest(formKind)),
  requestStagesByRailsFilter: filters =>
    dispatch(stages.stageByRailsFilterRequest(filters)),
  getIputValues: kind => dispatch(formInputAction.formInputsRequest(kind)),
  requestCreateRails: payload =>
    dispatch(railsAction.requestCreateRails(payload)),
  requestAddStageToRails: (payload, id) =>
    dispatch(railsAction.requestAddStageToRails(payload, id)),
  requestShow: id => dispatch(railsAction.requestShowRails(id)),
  requestAssociationAttributesRails: payload =>
    dispatch(railsAction.requestAssociationAttributesRails(payload)),
  requestDeleteStageInRails: (idStage, idRail) =>
    dispatch(railsAction.requestDeleteStageInRails(idStage, idRail)),
  requestShowStage: (id, idRail) =>
    dispatch(stages.stageShowRequest({ id, idRail })),
  requestJobsFromArea: idArea =>
    dispatch(formInputAction.formInputsRequestJobFromArea(idArea)),
  setIsAssociatingRequest: value =>
    dispatch(vacancyAssociationAction.setIsAssociatingRequest(value)),
  backPreviousPage: () => dispatch(goBack()),
  requestEditRails: payload => dispatch(railsAction.requestEditRails(payload)),
  requestEditAssociationAttributesRails: payload =>
    dispatch(vacancyAction.requestEditAssociationAttributesRails(payload)),
  redirect: route => dispatch(push(route))
});
const mapStateToProps = state => ({
  options: state.stages.options,
  isLoading: state.stages.isLoading,
  pageLoading: state.vaga.isLoading,
  rails: state.rails,
  location: state.router.location,
  pagination: state.stages.pagination,
  inputs: state.formInputs,
  stages: state.stages,
  rail: state.rails.rail,
  route: state.route,
  association: state.vacancyAssociation
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRails);

NewRails.propTypes = {
  stages: PropTypes.arrayOf({}),
  requestTestInfos: PropTypes.func,
  options: PropTypes.objectOf({
    testOptions: []
  }),
  isLoading: PropTypes.bool,
  rails: PropTypes.array,
  rail: PropTypes.objectOf({
    stages: []
  })
};

NewRails.defaultProps = {
  stages: [],
  options: {
    testeInfo: []
  },
  isLoading: true,
  requestTestInfos: () => { },
  rails: [],
  rail: {
    stages: []
  }
};
