import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  

  candidatesGetCandidatesListAssinatureRequest: ["payload"],
  candidatesGetCandidatesListAssinatureSuccess: ["payload", "isLoading"],
  candidatesGetCandidatesListAssinatureFail: ["error"],

  candidatesPasswordResetRequest: ["candidates", "vacancyId"],
  candidatesPasswordResetSuccess: ["payload"],
  candidatesPasswordResetFail: ["error"],

});

const INITIAL_STATE = {
  vacancies: [],
  isLoading: true,
  candidates: [],
  vacancy: {},
  hasMore: true,
  page: 1,
  tomador: [],
  tipoFuncionario: [],
  codSecao: [],
  funcao: [],
  horarios: [],
  planoDental: [],
  segurosDeVida: [],
  assistenciaMedica: [],
  assistenciaMedicaBradesco: [],
  assistenciaMedicaUnimed: [],
  planoDentalSulamerica: [],
  alimentacao: [],
  refeicao: [],
  registrationHierarchy: [],
  coligadaCandidato: [],
  motivoAdmissao: [],
  codeSindicato: [],
  requiredFields: true,
  updated: false,
};

const candidatesGetCandidatesListAssinatureRequest = state => ({ ...state, isLoading: true });
const candidatesGetCandidatesListAssinatureSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const candidatesGetCandidatesListAssinatureFail = (state, error) => ({ ...state, isLoading: false, error });


const candidatesPasswordResetRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesPasswordResetSuccess = (state, payload) => ({
  ...state,
  isLoading: false
})

const candidatesPasswordResetFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})


export default createReducer(INITIAL_STATE, {
  [Types.CANDIDATES_PASSWORD_RESET_REQUEST]: candidatesPasswordResetRequest,
  [Types.CANDIDATES_PASSWORD_RESET_SUCCESS]: candidatesPasswordResetSuccess,
  [Types.CANDIDATES_PASSWORD_RESET_FAIL]: candidatesPasswordResetFail,

  [Types.CANDIDATES_GET_CANDIDATES_LIST_ASSINATURE_REQUEST]: candidatesGetCandidatesListAssinatureRequest,
  [Types.CANDIDATES_GET_CANDIDATES_LIST_ASSINATURE_SUCCESS]: candidatesGetCandidatesListAssinatureSuccess,
  [Types.CANDIDATES_GET_CANDIDATES_LIST_ASSINATURE_FAIL]: candidatesGetCandidatesListAssinatureFail,

});
