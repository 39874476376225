import * as routes from "utils/contants/urls";

import React, { useEffect } from "react";
import {
  candidatesActions,
  kindStages,
  pendingCandidateAction,
  schedulingCandidateAction,
  bancoTalentosCandidateAction,
  contratarCandidateAction,
  returnForAnalysisAction,
} from "locale";

import CheckoutItems from "components/Candidates/CheckoutItems";
import CheckoutItemsCalendar from "components/Candidates/CheckoutItemsCalendar";
import { connect } from "react-redux";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import { goBack } from "connected-react-router";
import { history } from "utils/routes";
import { Creators as messageAction } from "store/ducks/messages";
import { Creators as vacancyCandidatesActions } from "store/ducks/candidates";
import { Creators as vacancyAction } from "store/ducks/vacancy";

const Checkout = ({
  candidates,
  vacancy,
  match,
  requestManagerCandidates,
  kindOfAction,
  requestInputValues,
  requestSaveSchedule,
  stageId,
  stageType,
  placeOfMedicExame,
  placesOfWork,
  goBack,
  messagePublication,
  requestVaga,
  vacancyObj,
  isLoading,
}) => {
  const MEDIC_EXAM = kindStages[4].id;
  const APPROVED = candidatesActions[0].id;
  const DECLINE = candidatesActions[1].id;
  const TO_REMOVE = candidatesActions[2].id;
  const SCHEDULING = schedulingCandidateAction.id;
  const PENDING = pendingCandidateAction.id;
  const BANKOFTALENTS = bancoTalentosCandidateAction.id;
  const HIRE = contratarCandidateAction.id;
  const RETURN_FOR_ANALYSIS = returnForAnalysisAction.id;

  useEffect(() => {
    if (vacancy) requestVaga(vacancy);
  }, [vacancy]);

  useEffect(() => {
    if (stageType == MEDIC_EXAM) {
      requestInputValues(["placeOfMedicExame"]);
    } else {
      requestInputValues(["placeOfWork"]);
    }
  }, []);

  if (candidates.length) {
    switch (parseInt(kindOfAction, 10)) {
      case APPROVED:
        return (
          <CheckoutItems
            requestManagerCandidates={requestManagerCandidates}
            kindOfAction={kindOfAction}
            match={match}
            candidates={candidates}
            vacancy={vacancy}
            stageId={stageId}
            goBack={goBack}
            stageType={stageType}
          />
        );
      case DECLINE:
        return (
          <CheckoutItems
            requestManagerCandidates={requestManagerCandidates}
            kindOfAction={kindOfAction}
            match={match}
            candidates={candidates}
            vacancy={vacancy}
            stageId={stageId}
            goBack={goBack}
            stageType={stageType}
          />
        );
      case TO_REMOVE:
        return (
          <CheckoutItems
            requestManagerCandidates={requestManagerCandidates}
            kindOfAction={kindOfAction}
            match={match}
            candidates={candidates}
            vacancy={vacancy}
            stageId={stageId}
            goBack={goBack}
            stageType={stageType}
          />
        );
      case SCHEDULING:
        return (
          <CheckoutItemsCalendar
            requestManagerCandidates={requestManagerCandidates}
            kindOfAction={kindOfAction}
            requestSaveSchedule={requestSaveSchedule}
            match={match}
            candidatesSelecteds={candidates}
            vacancyId={vacancy}
            placesOfWork={
              stageType == MEDIC_EXAM ? placeOfMedicExame : placesOfWork
            }
            stageId={stageId}
            stageType={stageType}
            goBack={goBack}
            messagePublication={messagePublication}
            vacancyObj={vacancyObj}
            isLoading={isLoading}
          />
        );
      case PENDING:
        return (
          <CheckoutItems
            requestManagerCandidates={requestManagerCandidates}
            kindOfAction={kindOfAction}
            match={match}
            candidates={candidates}
            vacancy={vacancy}
            stageId={stageId}
            goBack={goBack}
            stageType={stageType}
          />
        );
      case BANKOFTALENTS:
        return (
          <CheckoutItems
            requestManagerCandidates={requestManagerCandidates}
            kindOfAction={kindOfAction}
            match={match}
            candidates={candidates}
            vacancy={vacancy}
            stageId={stageId}
            goBack={goBack}
            stageType={stageType}
          />
        );

      case HIRE:
        return (
          <CheckoutItems
            requestManagerCandidates={requestManagerCandidates}
            kindOfAction={kindOfAction}
            match={match}
            candidates={candidates}
            vacancy={vacancy}
            stageId={stageId}
            goBack={goBack}
            stageType={stageType}
          />
        );

      case RETURN_FOR_ANALYSIS:
        return (
          <CheckoutItems
            requestManagerCandidates={requestManagerCandidates}
            kindOfAction={kindOfAction}
            match={match}
            candidates={candidates}
            vacancy={vacancy}
            stageId={stageId}
            goBack={goBack}
            stageType={stageType}
          />
        );

      default:
        return null;
    }
  }
  if (!candidates.length)
    history.push(`${routes.VACANCY_CANDIDATES}/${match.params.id}`);
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  requestVaga: (id) => dispatch(vacancyAction.jobShowRequest(id)),
  requestInputValues: (inputs) =>
    dispatch(formInputsActions.formInputsRequest(inputs)),
  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),
  requestSaveSchedule: (candidates, vancancy, kindOfAction, stageId) =>
    dispatch(
      vacancyCandidatesActions.candidatesScheduleEnterviewRequest(
        candidates,
        vancancy,
        kindOfAction,
        stageId
      )
    ),
  requestManagerCandidates: (
    candidates,
    kindOfAction,
    vancancyId,
    enabledSendToCandidates,
    feedback,
    stageId
  ) =>
    dispatch(
      vacancyCandidatesActions.candidatesDispatchManagerRequest(
        candidates,
        kindOfAction,
        vancancyId,
        enabledSendToCandidates,
        feedback,
        stageId
      )
    ),
  goBack: () => dispatch(goBack()),
});

const mapStateToProps = (state) => ({
  candidates: state.candidates.candidates,
  vacancy: state.candidates.vacancyId,
  kindOfAction: state.candidates.kindOfAction,
  stageId: state.candidates.stageId,
  stageType: state.candidates.stageType,
  placesOfWork: state.formInputs.placeOfWork,
  placeOfMedicExame: state.formInputs.placeOfMedicExame,
  vacancyObj: state.vaga,
  isLoading: state.vaga.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
