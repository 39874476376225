import * as urls from "utils/contants/urls";
import { Creators as formInputsActions } from "store/ducks/formInputs";

import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow
} from "components/shared/CustomComponentsStyle";
import { ButtonSecondary,TextField,SelectFieldInput,MultSelect } from "components/shared/CustomInputs";

import { Col, Row,Collapse } from "react-bootstrap";
import React, { PureComponent } from "react";
import {
  cancel,
  edit,
  order as orderIcon,
  statusAlert,
  statusOk,
  statusWait,
  statusOkFill,
} from "assets/icons/icons";

import BreadCrumb from "components/Layout/breadcrumb";
import { FormattedMessage } from "react-intl";
import Header from "components/Tab/Header";
import Load from "components/Layout/Load/Load";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import Tab from "components/Tab";
import { ToolTip } from "components/shared/CustomComponents";
import { YesOrNoWithTextDialog } from "components/shared/CustomComponents";
import { connect } from "react-redux";
import { Creators as jobsActions } from "store/ducks/jobs";
import { orderList, buildCode } from "helpers/sharedHelpers";
import { push } from "connected-react-router";

const renderDraftActions = (job, redirectToVacancy, deleteVacancy) => (
  <Actions>
    <ActionItem onClick={() => redirectToVacancy(job.id, true)}>
      {edit()}
      <LabelAction>
        <FormattedMessage id="sharedItems.edit" />
      </LabelAction>
    </ActionItem>
    <ActionItem onClick={() => deleteVacancy(job)}>
      {cancel()}
      <LabelAction>
        <FormattedMessage id="sharedItems.cancel" />
      </LabelAction>
    </ActionItem>
  </Actions>
);

const renderTip = status => {
  switch (status) {
    case 0:
      return (
        <ToolTip
          id="enabled"
          message={<FormattedMessage id="vacancyList.statusEnabledVacancy" />}
        />
      );
    case 1:
      return (
        <ToolTip
          id="awaiting"
          message={<FormattedMessage id="vacancyList.statuswaitingVacancy" />}
        />
      );
    case 2:
      return (
        <ToolTip
          id="canceled"
          message={<FormattedMessage id="vacancyList.statusCanceledVacancy" />}
        />
      );
      case 3:
        return (
          <ToolTip
            id="canceled"
            message={<FormattedMessage id="vacancyList.statusCanceledVacancy" />}
          />
        );
    default:
  }
};

const renderTableItens = (itens, redirectToVacancy, deleteVacancy) =>
  itens.map(job => (
    <TableRow ta="left" key={job.id}>
      <TableColumn>
        {job.status === 0 &&
          statusWait({
            "data-tip": "data-tip",
            "data-for": "awaiting",
            height: "24px",
            width: "24px"
          })}
        {job.status === 1 &&
          statusOk({
            "data-tip": "data-tip",
            "data-for": "enabled",
            height: "24px",
            width: "24px"
          })}
        {job.status === 2 &&
          statusAlert({
            "data-tip": "data-tip",
            "data-for": "canceled",
            height: "24px",
            width: "24px"
          })}
        {job.status === 3 &&
          statusOkFill({
            "data-tip": "data-tip",
            "data-for": "canceled",
            height: "24px",
            width: "24px"
          })}
      </TableColumn>
      <TableColumn onClick={() => redirectToVacancy(job.id, true)}>
        <Label>{buildCode(job.dataCriacao, "VA", job.id)}</Label>
        {renderTip(job.status)}
      </TableColumn>
      <TableColumn onClick={() => redirectToVacancy(job.id, true)}>
        <Label>{job.titulo}</Label>
      </TableColumn>
      <TableColumn onClick={() => redirectToVacancy(job.id, true)}>
        <Label>{job.cargo}</Label>
      </TableColumn>
      <TableColumn onClick={() => redirectToVacancy(job.id, true)}>
        <Label>{job.site}</Label>
      </TableColumn>
      <TableColumn onClick={() => redirectToVacancy(job.id, true)}>
        <Label>{job.usuarioQueCriou}</Label>
      </TableColumn>
      <TableColumn onClick={() => redirectToVacancy(job.id, true)}>
        <Label>{job.tipoSelecao}</Label>
      </TableColumn>
      
      <TableColumn flexPosition="flex-end">
      {(job.status === 0 || job.status === 1 || job.status === 3 )
         &&
          renderDraftActions(job, redirectToVacancy, deleteVacancy)}
      </TableColumn>
    </TableRow>
  ));

class JobList extends PureComponent {
  constructor() {
    super();
    this.state = {
      jobs: [],
      administrator: [],
      InputValues: {},
      typing: false,
      search: "",
      timer: 0,
      focus: 0,
      showDeleteDialog: false,
      columns: [],
      targetVacancy: 0,
      filter: { valor: 0, campo: "status", NumeroPagina: 1 },
      filter_cargo:"",
      filter_nomeVaga:"",
      filter_codigoVaga:"",
      filter_site:"",
      filter_selecaoVaga:"",
      filter_responsavel:"",
      showFilter :false

    };

    this.requestVacanciesAndSetTab = this.requestVacanciesAndSetTab.bind(this);
    this.deleteVacancy = this.deleteVacancy.bind(this);
  }

  componentDidMount() {
    const { getJobsList } = this.props;
    const { requestInputValues } = this.props;

    if(sessionStorage.getItem("filter_listJobs") != null)
    {

      let filter = JSON.parse(sessionStorage.getItem("filter_listJobs"));

      this.setState({
        filter_site: filter.filter_site,
        filter_responsavel: filter.filter_responsavel,
        filter_nomeVaga: filter.filter_nomeVaga,
        filter_selecaoVaga: filter.filter_selecaoVaga,
        filter_cargo: filter.filter_cargo});
    }



    getJobsList({ valor: 0, campo: "status", NumeroPagina: 1, site: "", responsavel: "", localTrabalho: ""
              , codigoVaga: "", nomeVaga: "", selecao: "", cargo: "" });


    requestInputValues(["placeOfWork", "eligibleJobs", "administrator"]);

    this.setState({
      columns: [
        {
          name: <FormattedMessage id="vacancyList.draft" />,
          focus: this.state.focus === 0,
          action: this.requestVacanciesAndSetTab,
          filter: { campo: "status", valor: "0", NumeroPagina: 1 }
        },
        {
          name: <FormattedMessage id="vacancyList.open" />,
          focus: this.state.focus === 1,
          action: this.requestVacanciesAndSetTab,
          filter: { campo: "status", valor: "1", NumeroPagina: 1 }
        },
        {
          name: <FormattedMessage id="vacancyList.inProgress" />,
          focus: this.state.focus === 3,
          action: this.requestVacanciesAndSetTab,
          filter: { campo: "status", valor: "3", NumeroPagina: 1 }
        },
        {
          name: <FormattedMessage id="vacancyList.finished" />,
          focus: this.state.focus === 2,
          action: this.requestVacanciesAndSetTab,
          filter: { campo: "status", valor: "2", NumeroPagina: 1 }
        }
      ]
    });


  }

  static getDerivedStateFromProps(nextProps, state) {

    const jobs = nextProps.jobs.jobs;
    if (state.jobs !== jobs) {
      const response = orderList(state.order, undefined, jobs);
      return { ...state, jobs: response.list, order: response.order };
    }

   
    const InputValues = nextProps.InputValues;
    if (state.InputValues !== InputValues) {
      return { ...state, InputValues: InputValues };
    }

    

    return null;
  }

  deleteVacancy(job) {
    this.setState({
      showDeleteDialog: !this.state.showDeleteDialog,
      targetVacancy: job
    });
  }

  requestVacanciesAndSetTab = filter => {
    this.props.getJobsList({ valor: filter.valor, campo: filter.campo, NumeroPagina: filter.NumeroPagina, site: this.state.filter_site?.value, responsavel:  this.state.filter_responsavel?.value, 
    cargo :  this.state.filter_cargo?.value, codigoVaga:  this.state.filter_codigoVaga, nomeVaga: this.state.filter_nomeVaga, selecao:  this.state.filter_selecaoVaga} );
   
    this.setState({ focus: parseInt(filter.valor), filter: filter });
  };


  orderBy = orderBy => {
    const jobsList = this.state.jobs;
    const { order } = this.state;
    let response;
    switch (orderBy) {
      case "code":
        response = orderList("id", order, jobsList);
        this.setState({ jobs: response.list, order: response.order });
        break;
      case "name":
        response = orderList("titulo", order, jobsList);
        this.setState({ jobs: response.list, order: response.order });
        break;
      case "job":
        response = orderList("cargo", order, jobsList);
        this.setState({ jobs: response.list, order: response.order });
        break;
      case "area":
        response = orderList("area", order, jobsList);
        this.setState({ jobs: response.list, order: response.order });
        break;
      case "manager":
        response = orderList("gestor", order, jobsList);
        this.setState({ jobs: response.list, order: response.order });
        break;
      default:
    }
  };

  search = term => {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    this.setState({
      search: term,
      typing: false,
      timer: setTimeout(() => {
        this.props.getSearchJob({
          valor: term,
          NumeroPagina: this.props.pagination.currentPage
        });
      }, 1000)
    });
  };

  renderIcon(key) {
    const { order } = this.state;
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` }
    });
  }

  render() {
    const { jobs } = this.props;
    const { administrator } = this.props;
    const { InputValues } = this.props;
    
    
    const { pagination } = this.props;
    const { location } = this.props;
    const { redirectToVacancy } = this.props;
    const { getJobsList } = this.props;
    const { deleteJobRequest } = this.props;

    const filtrar= () => {
      localStorage.setItem("filter_listJobs", JSON.stringify({ filter_site: this.state.filter_site.value | "", filter_responsavel:  this.state.filter_responsavel.value | "", filter_site:  this.state.filter_site.value,
        filter_cargo :  this.state.filter_cargo.value | "", filter_codigoVaga:  this.state.filter_codigoVaga, filter_nomeVaga: this.state.filter_nomeVaga, filter_selecaoVaga:  this.state.filter_selecaoVaga }));

      getJobsList({ valor: this.state.focus, campo: "status", NumeroPagina: 1, site: this.state.filter_site?.value, responsavel:  this.state.filter_responsavel?.value, 
                cargo :  this.state.filter_cargo?.value, codigoVaga:  this.state.filter_codigoVaga, nomeVaga: this.state.filter_nomeVaga, selecao:  this.state.filter_selecaoVaga} );
              
              
  
    }


    const Limparfiltro= () => {
      this.setState({
      filter_cargo:"",
      filter_nomeVaga:"",
      filter_codigoVaga:"",
      filter_site:"",
      filter_selecaoVaga:"",
      filter_responsavel:""});
      localStorage.setItem("filter_listJobs", JSON.stringify({ filter_site:  "", filter_responsavel:  "", filter_site:  "",
      filter_cargo :  "", filter_codigoVaga:  "", filter_nomeVaga: "", filter_selecaoVaga:  "" }));

    getJobsList({ valor: this.state.focus, campo: "status", NumeroPagina: 1, site: "", responsavel:  "", 
              cargo : "", codigoVaga:  "", nomeVaga: "", selecao:  ""} );
  
              
  
    }

    const confirmCancelVacancy = (fileData, message) => {
      this.setState({
        showDeleteDialog: !this.state.showDeleteDialog
      });
      deleteJobRequest({id: fileData.id,message: message}, fileData.filter);     
    };
  

    const ShowFilter= () => {
          this.setState({ showFilter: !this.state.showFilter });
  
  
    }

    if (jobs.isLoading) return <Load isLoading={jobs.isLoading} />;

    return (
      <React.Fragment>
        <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
          <Col lg={6}>
            <BreadCrumb
              location={location}
              title={<FormattedMessage id="menuTalentBar.vacancy" />}
            />
          </Col>
          <Col style={{ padding: "0px" }} lg={6}>
            <Header ShowFilter ={()=> ShowFilter()}  />
          </Col>
        </Row>
        <Row>
        <Collapse in={this.state.showFilter}>
          <Row style={{ marginBottom: "10px" }}>
          <Col md={4}>
            <TextField
                value={this.state.filter_codigoVaga}
                name={2}
                onChange={e =>
                  this.setState({ filter_codigoVaga: e.target.value })
                }
                label="Codigo" />
            </Col>

            <Col md={4}>
            <TextField
                value={this.state.filter_nomeVaga}
                name={2}
                onChange={e =>
                  this.setState({ filter_nomeVaga: e.target.value })
                }
                label="Nome vaga" />
            </Col>

            <Col md={4}>


    <MultSelect
              name="Cargo" 
              options={InputValues.eligibleJobs.map((e) => ({
                label: e.funcao,
                value: e.id,
              }))}
              label="Cargo"
              onChange={e =>
                this.setState({ filter_cargo: e })
              }
              value={this.state.filter_cargo}

            />
            </Col>
            <Col md={4}>
        <MultSelect
              name="Site" 
              options={InputValues.placeOfWork.map((e) => ({
                label: e.nome,
                value: e.id,
              }))}
              defaultValue={this.state.filter_site}

              label="Site"
              onChange={e =>
                
                this.setState({ filter_site: e })
              }

              value={this.state.filter_site}


            />
            </Col>
            <Col md={4}>
          <MultSelect
              name="Responsavel" 
              options={InputValues.administrator.map((e) => ({
                label: e.nome,
                value: e.id,
              }))}
              label="Responsavel"
              value={this.state.filter_responsavel}

              onChange={e =>
                this.setState({ filter_responsavel: e })
              }

            />
            </Col>

            <Col md={4}>
               
            <SelectFieldInput
              items={[
                { name: "Interno", id: 0 },
                { name: "Externo", id: 1 },
              ]}
              label="Tipo seleção"
              defaultValue={this.state.filter_selecaoVaga}
              value={this.state.filter_selecaoVaga}
              onChange={e =>
                this.setState({ filter_selecaoVaga: e.target.value })
              }
            />  
            </Col>

            
           
            <Col md={3} style={{ marginTop: "27px" }}>
              <ButtonSecondary
                name={<FormattedMessage id="sharedItems.filter" />}
                onClick={() => filtrar()}
              />
            </Col>
            <Col md={3} style={{ marginTop: "27px" }}>
              <ButtonSecondary
                onClick={() => Limparfiltro()}
                
                name={<FormattedMessage id="sharedItems.clearFilter" />}
              />
            </Col>
          </Row>
        </Collapse>



        </Row>
        <Tab columns={this.state.columns}>
          <Table>
            <TableBody>
              <TableRow noHover noBorder>
                <TableHead />
                <TableHead onClick={() => this.orderBy("code")}>
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.code" />
                  </LabelHeader>
                  {this.renderIcon("id")}
                </TableHead>
                <TableHead onClick={() => this.orderBy("name")}>
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.name" />
                  </LabelHeader>
                  {this.renderIcon("titulo")}
                </TableHead>
                <TableHead onClick={() => this.orderBy("job")}>
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.role" />
                  </LabelHeader>
                  {this.renderIcon("cargo")}
                </TableHead>
                <TableHead onClick={() => this.orderBy("area")}>
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.site" />
                  </LabelHeader>
                  {this.renderIcon("area")}
                </TableHead>
                <TableHead onClick={() => this.orderBy("area")}>
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.owner" />
                  </LabelHeader>
                  {this.renderIcon("area")}
                </TableHead>
                <TableHead onClick={() => this.orderBy("manager")}>
                  <LabelHeader>
                    <FormattedMessage id="vacancyList.columns.selection" />
                  </LabelHeader>
                  {this.renderIcon("gestor")}
                </TableHead>
                <TableHead />
              </TableRow>
              {renderTableItens(
                this.state.jobs,
                redirectToVacancy,
                this.deleteVacancy
              )}
            </TableBody>
          </Table>
          <Pagination getItemsPage={this.requestVacanciesAndSetTab} {...pagination} />
        </Tab>
            <YesOrNoWithTextDialog
          cancelAction={() =>
            this.setState({ showDeleteDialog: !this.state.showDeleteDialog })
                        }             
              show={this.state.showDeleteDialog}
              ShowNoUntilCancel={true}
              ShowYesUntilCancel={true}
              showMessage={true}
              question={"Confirma o cancelamento dessa vaga ?"}
              confirmAction={confirmCancelVacancy}
              idConfirm={{
                id: this.state.targetVacancy.id, filter: this.state.filter
              }}
            />

      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getJobsList: payload => dispatch(jobsActions.jobsRequest(payload)),
  getJobsListClosed: () => dispatch(jobsActions.jobsFinishedRequest()),
  getJobsDraft: page => dispatch(jobsActions.jobsDraftRequest(page)),
  redirectToVacancy: id => dispatch(push(`${urls.VACANCY_EDITAR}${id}`)),
  getSearchJob: term => dispatch(jobsActions.jobsSearchRequest(term)),
  deleteJobRequest: (id, payload) =>    dispatch(jobsActions.jobsDeleteRequest(id, payload)),
  requestInputValues: (inputs) => dispatch(formInputsActions.formInputsRequest(inputs))
});
const mapStateToProps = state => ({
  jobs: state.jobs,
  pagination: state.jobs.pagination,
  InputValues:state.formInputs,
  administrator: state.formInputs.administrator,
});

JobList.propTypes = {
  jobs: PropTypes.object,
  pagination: PropTypes.object,
  getJobsList: PropTypes.func,
  getJobsDraft: PropTypes.func,
  requestInputValues: PropTypes.func,
  InputValues: PropTypes.object
};

JobList.defaultProps = {
  jobs: {},
  InputValues:{},
  administrator: [],
  pagination: {
    currentPage: 1,
    lastPage: 11,
    sizeItens: 256
  },
  getJobsList: () => { },
  getJobsDraft: () => { },
  requestInputValues: () => { }
};
export default connect(mapStateToProps, mapDispatchToProps)(JobList);
